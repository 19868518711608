const audios = require.context("../../audios/words/level1", true)
const images = require.context("../../images/level1", true)

const levelData = {
	level: "easy",
	grid_width: 4,
	grid_height: 4,
	words: [
		{
			key: "pathsala",
			npword: "पाठशाला",
		},
		{
			key: "lichi",
			npword: "लिची",
		},
		{
			key: "lauka",
			npword: "लौका",
		},
		{
			key: "nariwol",
			npword: "नरिवल",
		},
		{
			key: "kafal",
			npword: "काफल",
		},
		{
			key: "kamila",
			npword: "कमिला",
		},
		{
			key: "haluwa",
			npword: "हलुवा",
		},
		{
			key: "ausadhi",
			npword: "औषधी",
		},
		{
			key: "wakil",
			npword: "वकिल",
		},
		{
			key: "taraju",
			npword: "तराजु",
		},
		{
			key: "methai",
			npword: "मिठाई",
		},
		{
			key: "bhakari",
			npword: "भकारी",
		},
		{
			key: "himal",
			npword: "हिमाल",
		},
		{
			key: "thadauro",
			npword: "ठडेउरो",
		},
		{
			key: "pipal",
			npword: "पिपल",
		},
		{
			key: "doko",
			npword: "डोको",
		},
	],
}

const levelWords = levelData.words.map((word) => {
	word = {
		...word,
		image: images(`./${word.key}.png`),
		audio: audios(`./${word.key}.ogg`),
	}
	return word
})

levelData.words = levelWords

export default levelData
