import React, { useState, useContext, useEffect } from "react"
import { Howl } from "howler"
import gsap from "gsap"
// styles
import "./phase3.styles.scss"

// Context
import { StateContext, DispatchContext } from "../../../DecodingGame"

// Component
import Phase3Event from "../../../components/Phase3Comps/Phase3Event"

const Phase3 = () => {
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)
	const detailedOptions = JSON.parse(JSON.stringify(state.levelData[state.stage].detailedOptions))

	const [currentEvent, setCurrentEvent] = useState(0)

	const NextEvent = () => {
		setCurrentEvent((prevCurrentEvent) => prevCurrentEvent + 1)
	}

	useEffect(() => {
		if (detailedOptions[currentEvent]) {
			const hintHowl = new Howl({
				src: [detailedOptions[currentEvent].audio],
			})
			hintHowl.play()
		}
	})
	useEffect(() => {
		if (currentEvent === 3) {
			NextEvent()
			let endAnimation = new gsap.timeline()
			endAnimation
				// .add(() => {
				// 	const hintHowl = new Howl({
				// 		src: [detailedOptions[0].audio],
				// 	})
				// 	hintHowl.play()
				// 	hintHowl.on("play", () => {
				// 		gsap.to("#noteBook-0", {
				// 			scale: 1.3,
				// 			duration: 1.5,
				// 		})
				// 		gsap.to("#noteBook-0", {
				// 			scale: 1,
				// 			duration: 1,
				// 		})
				// 	})
				// })
				// .add(() => {
				// 	const hintHowl = new Howl({
				// 		src: [detailedOptions[1].audio],
				// 	})
				// 	hintHowl.play()
				// 	hintHowl.on("play", () => {
				// 		gsap.to("#noteBook-1", {
				// 			scale: 1.3,
				// 			duration: 1.5,
				// 		})
				// 		gsap.to("#noteBook-1", {
				// 			scale: 1,
				// 			duration: 1,
				// 		})
				// 	})
				// }, ">1")
				.add(() => {
					const hintHowl = new Howl({
						src: [detailedOptions[2].audio],
					})
					hintHowl.play()
					hintHowl.once("play", () => {
						gsap.to("#noteBook-2", {
							scale: 1.2,
							duration: 0.8,
						})
						gsap.to("#noteBook-2", {
							scale: 1,
							delay: 1,
							duration: 0.8,
						})
					})
				}, ">1")
				.add(function () {
					dispatch({ type: "PHASE_COMPLETE", values: { phase: state.phase + 1, transition: true } })
				}, ">2.5")
		}
	}, [currentEvent, dispatch, detailedOptions, state.phase])
	return (
		<div className="phase3 row">
			{detailedOptions.map((data, index) => {
				return (
					<div className="p3-container col-4" key={data.key}>
						<Phase3Event phaseData={data} currentEvent={currentEvent} eventIndex={index} NextEvent={NextEvent} />
					</div>
				)
			})}
		</div>
	)
}

export default Phase3
