const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_green.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./grass.png"),
			class: "image-five grass",
			imgdiv: "imageCover zoomOutcrow",
		},
		{
			src: images("./speaking_crow3.gif"),
			class: "image-five speaking_crow",
			imgdiv: "imageCover zoomOutcrow",
		},
		{
			src: images("./roti_01.png"),
			class: "image-five roti_01",
			imgdiv: "imageCover zoomOutcrow",
		},
	],
	texts: [
		{
			textDivClass: "story-text page2Text text-center",
			textIndivData: [
				{
					textData: "रोटी देखेर काग करायो।",
					textClass: "word1",
					timestamps: [{ start: 0.4 }, { start: 1.1 }, { start: 2 }, { start: 2.8 }],
				},
				{
					textData: "का!",
					textClass: "word2",
					timestamps: [{ start: 0 }, { start: 0 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 6000,
		},
		{
			url: audios("./a2.ogg"),
			delay: 3400,
			isBg: true,
			loop: false,
			volume: 0.2,
		},
	],
}

export default content
