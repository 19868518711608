import React, { useContext, useEffect, useState } from "react"
import "./stage.styles.scss"
import { StateContext, DispatchContext } from "../../ShapeColorGame"
import { Howl } from "howler"
import Options from "../../components/Options/Options"
import Toolbar from "../../components/Toolbar/Toolbar"
import ModalComponent from "../../components/Modal/Modal"
import Scene from "../../components/Scene/SceneAll"
// import Scene from "../../components/Scene/scene1"
import bg_happy_place from "../../content/audios/sound_effects/happy_place.ogg"
import yo_awaj_kasko_hola from "../../content/chapters/assets/common/female.mp3"
import { sleep } from "../../utils"
import {getGameContent} from "../../ShapeColorGame"

let yoAwaj
let animalSound
const Stage = () => {
	const assets = require.context("./assets")
	const state = useContext(StateContext)
	const dispatch = React.useContext(DispatchContext)
	let wordRef = React.useRef(null)
	let stageData = state.levelData[state.stage]
	// const storyData = (getGameContent(match.params.gameKey))
	// console.log(state.stage);
	const initial = {
		audio: false,
		gif: false,
		clicked: false,
		clickable: false,
		demo: true,
		wrongAnswer: false,
		audioDemo: false,
		answerDemo: false,
		giveAnswer: false,
		openModal: true,
		allowAnswering: true,
		disable:true,
		// QuestionShow: false
	}
	const [stageState, setStageState] = useState(initial)

	const startGame = () => {
		if (yoAwaj) yoAwaj.unload()
		dispatch({ type: "SET", values: { playing: true } })
	}

	// Play music
	useEffect(() => {
		let currentLevel = localStorage.getItem("shapeSizeLevel")
		if (currentLevel > 0) startGame()
		// console.log(currentLevel);
		yoAwaj = new Howl({
			src: [yo_awaj_kasko_hola],
			autoplay: false,
			preload: true,
			loop: false,
		})

		if (state.stage > 0) dispatch({ type: "SET", values: { playing: true } })
		else yoAwaj.play()

		const bgMusic = new Howl({
			src: [stageData.bgMusic ? stageData.bgMusic : bg_happy_place],
			autoplay: false,
			preload: true,
			volume: 0.03,
			loop: true,
			onload: async () => {
				dispatch({ type: "LOADING_COMPLETE" })
			},
		})

		bgMusic.play()
		return () => {
			bgMusic.unload()
			if (yoAwaj) yoAwaj.unload()
			dispatch({ type: "SET", values: { playing: false } })
		}
	}, [state.level, state.stage, dispatch, stageData.bgMusic, stageData.id])

	useEffect(() => {
		return () => {
			if (yoAwaj) yoAwaj.unload()
			// if (animalSound) animalSound.unload()
			dispatch({ type: "SET", values: { playing: false } })
		}
	}, [])

	// const closeModal = () => {
	// 	setStageState({ ...stageState, levelComplete: false, wrongAnswer: false })
	// 	dispatch({ type: "MODAL_CLOSED" })
	// }

	return (
		<StateContext.Consumer>
			{(state) => {
				if (state === undefined) {
					throw new Error("StateConsumer must be used within a StateProvider")
				}
				return (
					<div
						className="game-stage"
						style={{
							position: "relative",
							backgroundImage: `url(${state.playing ? stageData.bg : assets(`./${state.bg}`)})`,
						}}
					>
						{!state.playing ? (
							<div className="start_game_button_container">
								<button onClick={startGame} title="खेलौँ">
									खेलौँ
								</button>
							</div>
						) : ''
						}
						{state.playing && (
							<div className="whose_voice w-100 h-100" key={stageData.storyKey}>
								{/* {stageData.id !== 10 && state.allowAnswering && (
									<div className="speaker" onClick={() => playAnimalSound(true)}>
										<img src={assets(`./${state.speaker}`)} className="speaker-button" alt="Button: Speaker" />
										{state.demo && stageState.audioDemo && (
											<img src={assets(`./${state.click}`)} className="click" alt="Click here demo" />
										)}
									</div>
								)} */}
								<Scene
								stageData= {stageData}
								assets= {assets}
								story={stageData.storyKey}
								key = {stageData.id}
								/>
								<Toolbar totalPage={state.levelData.length} currentPage={state.stage} />
								{/* {state.modalOpen && (
									<ModalComponent
										assets={assets}
										stageData={stageData}
										closeModal={closeModal}
										isOpen={state.modalOpen}
										currentPage={state.stage}
									/>
								)} */}
							</div>
						)}
					</div>
				)
			}}
		</StateContext.Consumer>
	)
}

export default Stage
