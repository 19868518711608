const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./dost_chatting.gif"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./leg.png"),
			class: "image-one goda",
			imgdiv: "imageCover",
		},
		{
			src: images("./horse_l.png"),
			class: "image-one goda1",
			imgdiv: "imageCover",
		},
		{
			src: images("./taato.png"),
			class: "image-one tato",
			imgdiv: "imageCover",
		},
		{
			src: images("./tato.png"),
			class: "image-one tato",
			imgdiv: "imageCover",
		},
		{
			src: images("./dhooka.png"),
			class: "image-one",
			imgdiv: "imageCover dhoka",
		},
		{
			src: images("./dhoka_l.png"),
			class: "image-one",
			imgdiv: "imageCover dhoka",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "एक जना गोडा भन्थ्यो",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 0 }, { start: 1.1 }, { start: 1.9 }, { start: 2.5 }],
				},
				{
					textData: "अर्को भन्थ्यो घोडा",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 3.8 }, { start: 4.6 }, { start: 5.4 }, { start: 6.9 }],
				},
				{
					textData: "तातो- टाटो, धोका- ढोका",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 7.7 }, { start: 9.1 }, { start: 10.1 }, { start: 11.1 }],
				},
				{
					textData: "सुन्‍ने कस्तो जोडा",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 12 }, { start: 12.9 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 1000,
		},
	],
}

export default content
