import React, { useContext, useEffect, useState, useRef } from "react"
import gsap from "gsap"
import { DispatchContext } from "../StoryContainer"

// components
import PrevBtn from "Components/PrevBtn"
// import NextBtn from 'Components/NextBtn';
import PageNumber from "Components/PageNumber"

// assets
import ReadIcon from "assets/icons/book.svg"
import audioIcon from "assets/icons/audio.svg"
import ReloadIcon from "assets/icons/reload.svg"
import NextIcon from "assets/icons/next.svg"

export default function ActivityControls({ currentPage, totalPages, audioMute, disabledNextBtn }) {
	const dispatch = useContext(DispatchContext)
	const nextBtnRef = useRef(null)

	const onResetClick = () => {
		dispatch({ type: "RESET" })
	}

	const [disabled, disableButton] = useState(true)

	useEffect(() => {
		//----------------------- NEXT ROUND DEDUGGING Helper -------------------------
		let map = { 16: false, 39: false, 37: false } //16: shift , 39: right arrow , 37: right arrow   <= keyCodes

		const keyDown = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = true
				if (map[16]) {
					if (map[39]) {
						dispatch({ type: "NEXT" })
					}
					if (map[37]) {
						dispatch({ type: "PREV" })
					}
				}
			}
		}
		window.addEventListener("keydown", keyDown)

		const keyUp = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = false
			}
		}
		window.addEventListener("keyup", keyUp)

		const timer = setTimeout(() => {
			disableButton(false)
		}, 500)
		if (!audioMute && !disabledNextBtn && currentPage !== totalPages) {
			nextBtnRef.current.classList.add("success")
			gsap.fromTo(
				nextBtnRef.current,
				0.8,
				{
					scale: 1,
				},
				{
					scale: 1.1,
					yoyo: true,
					repeat: -1,
				}
			)
		}
		return () => {
			clearTimeout(timer)
			window.removeEventListener("keydown", keyDown)
			window.removeEventListener("keyup", keyUp)
		}
	}, [disabled, audioMute, disabledNextBtn, currentPage, totalPages, dispatch])

	return (
		<div className="activity__nav">
			<div className="row align-items-center">
				<div className="col-6">
					<PageNumber totalPages={totalPages} currentPage={currentPage - 1} key={"pn" + currentPage} />
				</div>
				<div className="col-6 text-right">
					<div className="d-inline-block">
						{audioMute ? (
							<span
								className="btn-icon"
								onClick={() => {
									dispatch({ type: "LISTEN" })
								}}
							>
								<img className="img-fluid" src={audioIcon} alt="Listen and Read" />
							</span>
						) : (
							<span
								className="btn-icon"
								onClick={() => {
									dispatch({ type: "READ" })
								}}
							>
								<img className="img-fluid" src={ReadIcon} alt="Read" />
							</span>
						)}
					</div>
					<div className="pl-1 pl-md-3 d-inline-block">
						<span className={disabled ? "btn-icon disabled" : "btn-icon"}>
							<img
								className="img-fluid"
								src={ReloadIcon}
								alt="Reload"
								onClick={() => {
									if (disabled) {
										return
									} else {
										onResetClick()
										disableButton(true)
									}
								}}
							/>
						</span>
					</div>
					<div className="pl-1 pl-md-3 d-inline-block icon-btn-group">
						{true && (
							<React.Fragment>
								<PrevBtn
									disabled={currentPage === 1}
									prevBtnClick={() => {
										dispatch({ type: "PREV" })
									}}
								/>
								{currentPage === totalPages || disabledNextBtn ? (
									<span key="nxtBtn" className={"next__btn disabled"} ref={nextBtnRef}>
										<img className="img-fluid" src={NextIcon} alt="next" />
									</span>
								) : (
									<span
										key="nxtBtn"
										onClick={() => {
											dispatch({ type: "NEXT" })
										}}
										className={"next__btn"}
										ref={nextBtnRef}
									>
										<img className="img-fluid" src={NextIcon} alt="next" />
									</span>
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}
