import React from "react"
import _ from "underscore"
import "./hint.styles.scss"
import { Howl } from "howler"
import gsap from "gsap"
import volumeSvg from "../../content/images/gameAssets/volume.svg"

import { StateContext, DispatchContext } from "../../BalloonPopGame"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

const Hint = (props) => {
	const state = React.useContext(StateContext)
	const dispatch = React.useContext(DispatchContext)
	const assets = require.context("./assets")
	const hintCardRef = React.useRef(null)

	// console.log("-------");
	// console.log("hints");
	// console.log(props);
	// console.log(state);
	// console.log("-------");
	React.useEffect(() => {
		const hintAudio = new Howl({
			src: [props.audio],
			autoplay: false,
			loop: false,
		})
		let hintCard = hintCardRef.current
		if (state.intro) {
			gsap.set(hintCard.parentElement, {
				xPercent: 148,
				onComplete: () => {
					hintAudio.play()
				},
			})
			gsap.to(hintCard.parentElement, {
				delay: 1,
				duration: 1,
				ease: "power4.out",
				xPercent: 0,
				onComplete: () => {
					dispatch({ type: "START_PLAY" })
				},
			})
		}
		if (state.stageComplete && !state.rewardComplete) {
			gsap.to(hintCard.parentElement, {
				duration: 1,
				ease: "power4.out",
				xPercent: 148,
			})
			gsap.to(hintCard.querySelector(".hint-img"), {
				duration: 2,
				delay: -0.5,
				filter: "brightness(1)",
				onComplete: () => {
					hintAudio.play()
					hintAudio.on("end", function () {
						dispatch({ type: "REWARD_COMPLETE" })
					})
				},
			})
		}

		hintCard.addEventListener(
			"click",
			_.debounce(
				() => {
					hintAudio.play()
				},
				250,
				true
			)
		)

		return () => {
			hintCard.removeEventListener(
				"click",
				_.debounce(
					() => {
						hintAudio.play()
					},
					250,
					true
				)
			)
			hintAudio.unload()
			console.log("hint unload")
		}
	})

	return (
		<div className="hint m-2 w-25">
			<div
				className="align-items-center d-flex justify-content-center w-100 notepad-bg"
				style={{
					height: state.windowSize.width * 0.33,
				}}
			>
				<div
					className="card"
					ref={hintCardRef}
					style={{
						backgroundImage: `url('${assets("./note_pad_part.png")}')`,
					}}
				>
					<img className="hint-img img-fluid w-75" src={props.image} alt="hint" />
					<img className="" src={volumeSvg} alt="volume" />
				</div>
				{/* <img className="img-fluid w-100 postion-absolute" src={assets('./note_pad.png')} alt="hint note pad" />*/}
			</div>
		</div>
	)
}

export default Hint
