import React, { useEffect } from "react"

//components
import hammerPng from "./assets/images/hammer.png"

//styles
import "./hammer.styles.scss"

function Hammer() {
	useEffect(() => {
		let hammer = document.createElement("div")
		hammer.setAttribute("id", "cursor")

		let hammerImg = document.createElement("img")
		hammerImg.setAttribute("src", hammerPng)
		hammerImg.setAttribute("alt", "hammer")
		hammerImg.setAttribute("id", "hammer")

		hammer.appendChild(hammerImg)

		let activityContainer = document.getElementsByClassName("activity__container")[0]
		activityContainer.appendChild(hammer)

		return () => {
			activityContainer.removeChild(hammer)
		}
	}, [])

	return <></>
}

export default Hammer
