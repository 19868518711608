const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./6to10/pond-big-bg.svg"),
			imgdiv: "bg-full",
			removeDiv: true,
		},
		{
			src: images("./6to10/tortoise01.svg"),
			imgdiv: "turtle",
			removeDiv: true,
		},
		{
			src: images("./6to10/tortoise04.svg"),
			imgdiv: "turtle d-none",
			removeDiv: true,
		},
		{
			src: images("./6to10/duck01a.svg"),
			imgdiv: "duck-a",
			removeDiv: true,
		},
		{
			src: images("./6to10/duck01e.svg"),
			imgdiv: "duck-a d-none",
			removeDiv: true,
		},
		{
			src: images("./6to10/duck02c.svg"),
			imgdiv: "duck-b",
			removeDiv: true,
		},
		{
			src: images("./6to10/duck02d.svg"),
			imgdiv: "duck-b d-none",
			removeDiv: true,
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "त्यसैले पानीहाँसहरू र कछुवा बसेर छलफल गर्न थाले। एउटा पानीहाँसले खुसी हुँदै भन्यो- “बल्ल उपाय निस्क्यो!”",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.7 },
						{ start: 1.9 },
						{ start: 2.3 },
						{ start: 2.9 },
						{ start: 3.6 },
						{ start: 4.3 },
						{ start: 4.6 },
						{ start: 5.7 },
						{ start: 6.2 },
						{ start: 6.7 },
						{ start: 7.6 },
						{ start: 8.2 },
						{ start: 8.5 },
						{ start: 9.3 },
						{ start: 10.1 },
						{ start: 10.4 },
						{ start: 11 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p2_s3.ogg"),
			delay: 200,
		},
		{
			url: audios("./p2_s3a.ogg"),
			delay: 6000,
		},
		{
			url: audios("./p2_s3b.ogg"),
			delay: 9150,
		},
	],
}

export default content
