const imgDir = require.context("../images", true)
const images = (image) => {
	let imgUrl
	try {
		imgUrl = imgDir(image)
	} catch (error) {
		imgUrl = null
	}
	return imgUrl
}
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	images: [
		{
			src: images("./6-7/bg_06.png"),
			// imgdiv: 'imageCover zoomOutsis',
		},

		{
			src: images("./boy_dance_01.gif"),
			imgdiv: "imageCover boyFlyingPlay",
		},
		{
			src: images("./_.png"),
			imgdiv: "imageCover boyFlyingPlay d-none",
			styles: {
				background: `url(${images("./6-7/boy_dance.png")}) 0px center / cover no-repeat`,
			},
		},
		{
			src: images("./6-7/grass.png"),
		},
		{
			src: images("./_.png"),
			imgdiv: "imageCover sisWorkingSprite",
			styles: {
				background: `url(${images("./6-7/girl_cutting_grass_no_talk.png")}) 0px center / cover no-repeat`,
			},
		},
		{
			src: images("./6-7/girl_cutting_grass.png"),
			imgdiv: "d-none",
		},
		{
			src: images("./6-7/girl_cutting_grass_no_talk.png"),
			imgdiv: "d-none",
		},
		{
			src: images("./6-7/grass_bottom.png"),
			imgdiv: "imageCover grassMoveAnim",
			styles: {
				bottom: 0,
				top: "unset",
				width: "62%",
			},
		},
		{
			src: images("./6-7/cloud02.png"),
			imgdiv: "imageCover cloudsAnimB",
			styles: {
				width: "62%",
			},
		},
		{
			src: images("./6-7/cloud01.png"),
			imgdiv: "imageCover cloudsAnimA",
		},
		{
			src: images("./6-7/cloud03.png"),
			imgdiv: "imageCover cloudsAnimC",
			styles: {
				width: "62%",
			},
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page6sis topRightBubble text-center",
			textIndivData: [
				{
					textData: "तिमी त अब छिट्टै स्कूल जान थाल्नुपर्छ है।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.3 },
						{ start: 0.6 },
						{ start: 1.2 },
						{ start: 1.7 },
						{ start: 2.4 },
						{ start: 2.6 },
						{ start: 3.0 },
						{ start: 3.7 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p6.ogg"),
			delay: 4000,
		},
		{
			url: audios("./a5.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
