const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_green.png"),
			class: "image-one bg01",
			imgdiv: "imageCover",
		},
		{
			src: images("./grass.png"),
			class: "image-five grass",
			imgdiv: "imageCover",
		},
		{
			src: images("./crow_pick_roti_foot1.gif"),
			class: "image-five crow_roti_feet1 ",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page4Text text-center",
			textIndivData: [
				{
					textData: "कागले रोटीलाई खुट्टाले च्यापेर चुच्चाले तान्यो।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 2.3 }, { start: 3.1 }, { start: 4.2 }, { start: 5.2 }],
				},
				{
					textData: "फेरि पनि रोटी चुँडिएन।",
					textClass: "m-0",
					timestamps: [{ start: 6.3 }, { start: 7 }, { start: 7.7 }, { start: 8.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./4.ogg"),
			delay: 500,
		},
	],
}

export default content
