import { transformTo, appear, disappear } from "styledElements/keyframes"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					animation: `${transformTo("scale(5) translate(40%, 20%)")} linear 5s 2s forwards,
					${transformTo("scale(10) translate(40%, 14%)")} linear 2s 19s forwards
					`,
			}
		},
		{
			src: images("./imgs/talking_onehandhip.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "7%", top: "48%", left: "44%",opacity: "0",
				animation: `${appear} 0.01s 9s ease-out normal forwards,
				${disappear} 1s 19s ease-out normal forwards
				 
				`,
			}
		},
		{
			src: images("./imgs/kamal_onehandhip.svg"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "7%", top: "44%", left: "44%",opacity: "0",
				animation: `${appear} 1s 8s ease-out normal forwards,
				${disappear} 0.01s 9s ease-out normal forwards
				 
				`,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble1 page2Buble",
			textIndivData: [
				{
					textData: "ओहो यहाँ त कति लामो पुल रहेछ। हेर त, दोधारा चाँदनी पुल भनेको यहि त हो नि।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: "14%",
				left: "47%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 9s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 18s forwards
				`,
			}
		},
	],
	sounds: [
		{
			url: audios("./kanchanpur1.ogg"),
			delay: 9000,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 21000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
	],
}

export default content
