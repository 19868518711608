const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page_05_a.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./musical_nodes.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./page_05_leaf.png"),
			class: "image-8 leaf1",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideTextRight text-center",
			textIndivData: [
				{
					textData: "हेमन्त ऋतु",
					textClass: "fz-6 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 0.8 }],
				},
				{
					textData: "रूखका पातहरू",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 1.8 }, { start: 2.75 }],
				},
				{
					textData: "झर्न थालेछन्।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 3.65 }, { start: 4.15 }],
				},
				{
					textData: "पञ्चे बाजाको धुन",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 5.45 }, { start: 6 }, { start: 6.6 }],
				},
				{
					textData: "सुनिन थालेछन्।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 7.2 }, { start: 7.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6.ogg"),
			delay: 1200,
		},
		{
			url: audios("./a1.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
		{
			url: audios("./a3.ogg"),
			delay: 1200,
			isBg: true,
			volume: 0.1,
		},
	],
}

export default content
