const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./horse-playing-football.gif"),
			class: "image-five",
			imgdiv: "imageCover lightbluebg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center browntext",
			textIndivData: [
				{
					textData: "चौरमा गएर",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.8 }],
				},
				{
					textData: "भकुन्डो गुडायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 1.65 }, { start: 2.4 }, { start: 3.3 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./9.ogg"),
			delay: 500,
		},
		// {
		// 	url: audios("./a9.ogg"),
		// 	delay: 150,
		// 	loop: false,
		// 	volume: 0.3,
		// 	isBg: true,
		// },
	],
}

export default content
