import React, { useContext, useEffect, useState } from "react"
import "./stage.styles.scss"
import { StateContext, DispatchContext } from "../../WhoseVoiceGame"
import { Howl } from "howler"
import Options from "../../components/Options/Options"
import Toolbar from "../../components/Toolbar/Toolbar"
import ModalComponent from "../../components/Modal/Modal"
import bg_happy_place from "../../content/audios/sound_effects/happy_place.ogg"
import yo_awaj_kasko_hola from "../../content/chapters/assets/common/female.mp3"
import { sleep } from "../../utils"

let yoAwaj
let animalSound
const Stage = () => {
	const assets = require.context("./assets")
	const state = useContext(StateContext)
	const dispatch = React.useContext(DispatchContext)
	let wordRef = React.useRef(null)
	let stageData = state.levelData[state.stage]
	const initial = {
		audio: false,
		gif: false,
		clicked: false,
		clickable: false,
		demo: true,
		wrongAnswer: false,
		audioDemo: false,
		answerDemo: false,
		giveAnswer: false,
		openModal: true,
		allowAnswering: true,
	}
	const [stageState, setStageState] = useState(initial)

	const startGame = () => {
		if (yoAwaj) yoAwaj.unload()
		dispatch({ type: "SET", values: { playing: true } })
	}

	// Play music
	useEffect(() => {
		let currentLevel = localStorage.getItem("whoseVoiceLevel")
		if (currentLevel > 0) startGame()

		yoAwaj = new Howl({
			src: [yo_awaj_kasko_hola],
			autoplay: false,
			preload: true,
			loop: false,
		})

		if (state.stage > 0) dispatch({ type: "SET", values: { playing: true } })
		else yoAwaj.play()

		const bgMusic = new Howl({
			src: [stageData.bgMusic ? stageData.bgMusic : bg_happy_place],
			autoplay: false,
			preload: true,
			volume: 0.03,
			loop: true,
			onload: async () => {
				dispatch({ type: "LOADING_COMPLETE" })
			},
		})

		bgMusic.play()
		return () => {
			bgMusic.unload()
			if (yoAwaj) yoAwaj.unload()
		}
	}, [state.level, state.stage, dispatch, stageData.bgMusic, stageData.id])

	useEffect(() => {
		if (stageData.id !== 10 && state.playing) {
			;(async function () {
				try {
					const word = ["यो", "कसको", "आवाज", "होला ?"]
					let inputText = document.getElementById("whose-voice")
					let innerHTML = inputText.innerHTML
					let actualWord = "यो कसको आवाज होला ?"
					for (let i = 0; i < word.length + 1; i++) {
						if (i >= word.length) {
							inputText.innerHTML = `<span class="white">${actualWord}</span>`
						} else {
							let index = actualWord.indexOf(`${word[i]}`)
							if (index >= 0) {
								innerHTML =
									"<span class='hightlight'>" +
									actualWord.substring(0, index) +
									actualWord.substring(index, index + word[i].length) +
									"</span>" +
									actualWord.substring(index + word[i].length)
								inputText.innerHTML = innerHTML
							}
						}

						await sleep(400)
					}
				} catch {}
			})()
		}
	}, [state.playing])

	const answerSound = (isRight = false) => {
		if (state.stage === 0) setStageState({ ...stageState, wrongAnswer: true, levelComplete: false, answerDemo: true })
		new Howl({
			src: [isRight ? state.audios[1] : state.audios[0]],
			autoplay: true,
			loop: false,
			volume: 0.03,
		})
	}

	const animalClick = (e, index) => {
		// if (!stageState.giveAnswer) return
		if (!state.allowAnswering) {
			return
		} else {
			if (stageData.rightAnswer !== index) {
				answerSound(false)
				var node = document.createElement("img")
				node.src = assets(`./${state.wrong}`)
				node.className = "wrong-animal"
				document.getElementById(e.target.id).parentElement.appendChild(node)
			} else {
				answerSound(true)
				setStageState({ ...stageState, levelComplete: true, clicked: true, audioDemo: false, answerDemo: false })
				dispatch({ type: "RIGHT_ANSWER" })
			}
		}
	}

	const playAnimalSound = (clicked = false) => {
		if (stageState.demo && clicked) return
		if (!stageState.audio) {
			setStageState({ ...stageState, audio: true })
			animalSound = new Howl({
				src: [stageData.animalSound],
				autoplay: true,
				loop: false,
				onend: () =>
					setStageState({
						...stageState,
						audio: false,
						gif: true,
						clicked: clicked,
						demo: false,
						audioDemo: state.stage === 0 ? (clicked ? false : true) : false,
						giveAnswer: true,
					}),
			})
		}
	}

	useEffect(() => {
		return () => {
			if (yoAwaj) yoAwaj.unload()
			if (animalSound) animalSound.unload()
			dispatch({ type: "SET", values: { playing: false } })
		}
	}, [])

	const closeModal = () => {
		setStageState({ ...stageState, levelComplete: false, wrongAnswer: false })
		dispatch({ type: "MODAL_CLOSED" })
	}

	return (
		<StateContext.Consumer>
			{(state) => {
				if (state === undefined) {
					throw new Error("StateConsumer must be used within a StateProvider")
				}
				return (
					<div
						className="game-stage"
						style={{
							position: "relative",
							backgroundImage: `url(${state.playing ? stageData.bg : assets(`./${state.bg}`)})`,
						}}
					>
						{!state.playing ? (
							<div className="start_game_button_container">
								<button onClick={startGame} title="खेलौँ">
									खेलौँ
								</button>
							</div>
						) : (
							<>
								{stageData.id !== 10 && (
									<div
										className="game-title"
										id="whose-voice"
										style={{ backgroundColor: stageData.headerBG }}
										ref={wordRef}
									>
										यो कसको आवाज होला ?
									</div>
								)}
							</>
						)}
						{state.playing && (
							<div className="whose_voice w-100 h-100">
								{stageData.id !== 10 && state.allowAnswering && (
									<div className="speaker" onClick={() => playAnimalSound(true)}>
										<img src={assets(`./${state.speaker}`)} className="speaker-button" alt="Button: Speaker" />
										{state.demo && stageState.audioDemo && (
											<img src={assets(`./${state.click}`)} className="click" alt="Click here demo" />
										)}
									</div>
								)}
								<Options
									playAnimalSound={playAnimalSound}
									animalClick={animalClick}
									assets={assets}
									stageData={stageData}
									key={`options-${state.level}-${state.stage}`}
									showDemoAnswer={stageState.wrongAnswer}
									answerDemo={stageState.answerDemo}
								/>
								<Toolbar totalPage={state.levelData.length} currentPage={state.stage} />
								{state.modalOpen && (
									<ModalComponent
										assets={assets}
										stageData={stageData}
										closeModal={closeModal}
										isOpen={state.modalOpen}
										currentPage={state.stage}
									/>
								)}
							</div>
						)}
					</div>
				)
			}}
		</StateContext.Consumer>
	)
}

export default Stage
