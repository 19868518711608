import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	images: [
		{
			src: images("./kids/bg_page8.png"),
		},
		{
			src: images("./flag_long.gif"),
			styles: {
				width: "100%",
				top: "0%",
				left: "0%",
			},
		},
		{
			src: images("./boy_with_dog.gif"),
			class: "image-10 boywithdog12",
			imgdiv: "imageCover",
		},
		{
			src: images("./kids/page-8-girl01.svg"),
			toSvg: true,
			styles: {
				top: "41%",
				left: "27%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl1",
		},
		{
			src: images("./kids/page-8-boy.svg"),
			toSvg: true,
			styles: {
				top: "39.2%",
				left: "47%",
				width: "22%",
			},
			imgdiv: "imageCover page8boy",
		},

		{ 
			src: images("./kids/page-8-girl02.svg"),
			toSvg: true,
			styles: {
				top: "42%",
				left: '66.5%',
				width: "22%",
			},
			imgdiv: "imageCover page8girl2",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page11girl topBubble",
			styles:{
				width: '37% !important',
				top: '18% !important',
				left: '46% !important',
				animationDelay: '0.5s !important',
				padding: '3% 3% 10% 3.4% !important',
			},
			textIndivData: [
				{
					textData: "अस्ति म दिदीसँग खोलामा बन्दै गरेको पुल हेर्न गएकी थिएँ।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.6 },
						{ start: 2.3 },
						{ start: 2.8 },
						{ start: 3.2 },
						{ start: 3.7 },
						{ start: 3.9 },
						{ start: 4.3 },
						{ start: 4.8 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7.ogg"),
			delay: 500,
		},
	],
}

export default content
