import { keyframes } from "@emotion/core"
import { transformTo, appear, disappear } from "styledElements/keyframes"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const open_eye = keyframes`
	0%, 89% {
		opacity: 1;
	}
	90%, 100% {
		opacity: 0;
	}
`
const content = {
	imageContainerStyles: {
		transform: "translate(-29%,28%) scale(1.65)",
		transformOrigin: "center",
		transformBox: "fill-box",
		animation: `${transformTo("translate(25%,28%) scale(1.65)")} linear 7s 25s forwards,
		${keyframes({
			to: {
				transform: "translate(44%,21%) scale(2.5)",
			},
		})} 4s 59s linear forwards,
		${keyframes({
			to: {
				transform: "translate(25%,28%) scale(1.65)",
			},
		})} 4s 71s linear forwards`
	},
	images: [
		{
			src: images("./bg_03.jpg"),
		},
		{
			src: images("./banana_tree.gif"),
			styles: {
				top: "24%",
				width: "33%",
				left: "14%",
			},
		},
		{
			src: images("./singing_bird.gif"),
			styles: {
				left: "47.4%",
				width: "3%",
				top: "13%",
				img: {
					position: "absolute",
				},
			},
		},
		{
			src: images("./bg_cover01.png"),
			styles: {
				opacity: 0,
				animation: `${keyframes({
					from: {
						opacity: 0,
					},
					to: {
						opacity: 0.8,
					},
				})} 3s 59s linear forwards, ${keyframes({ to: { opacity: 0 } })} 3s linear 71s forwards`,
			},
		},
		{
			src: images("./triangle.svg"),
			toSvg: true,
			styles: {
				width: "14.2%",
				left: "66%",
				top: "8.3%",
				div: {
					div: {
						animation: `${transformTo("translateX(-285%)")} linear 13s 5s forwards,
						${keyframes({
							to: {
								transform: "translate(-300%,240%)",
							},
						})} linear 4s 59s forwards, ${disappear} 3s linear 71s forwards
						`,
					},
				},
				svg: {
					overflow: "visible",
					transformBox: "fill-box",
					transformOrigin: "center",
					animation: `${transformTo("rotate(-360deg)")} linear 25s 5s forwards,
					${keyframes({
						from: {
							transform: "rotate(-10deg)",
						},
						to: {
							transform: "rotate(10deg)",
						},
					})} ease-in-out 5s 16s 30 alternate`,
				},
				"#path_x5F_fill": {
					opacity: "0",
					transformBox: "fill-box",
					transformOrigin: "center",
					animation: `${appear} linear 0.02s 4.5s forwards`,
				},
				"#path": {
					strokeDasharray: "1000",
					strokeDashoffset: "1000",
					animation: `${keyframes`to {stroke-dashoffset: 0;}`} linear 2s 2.5s forwards`,
				},
			},
		},
		{
			src: images("./rectangle.svg"),
			toSvg: true,
			styles: {
				width: "3.2%",
				left: "71.5%",
				top: "19.7%",
				div: {
					div: {
						animation: `${transformTo("translate(-1200%, 13%)")} linear 10s 19.5s forwards,
						${keyframes({
							to: {
								transform: "translate(-1000%,161%) scale(1.5)",
							},
						})} linear 4s 59s forwards,
						${disappear} 3s linear 71s forwards
						`,
					},
				},

				svg: {
					overflow: "visible",
					transformBox: "fill-box",
					transformOrigin: "center",
					animation: `${transformTo("rotate(-210deg)")} linear 10s 19.5s forwards,
					 ${keyframes({
							from: {
								transform: "rotate(-190deg)",
							},
							to: {
								transform: "rotate(-210deg)",
							},
						})} ease-in-out 4s 19.5s 30 alternate`,
				},
				"#path_x5F_fill": {
					opacity: "0",
					transformBox: "fill-box",
					transformOrigin: "center",
					animation: `${appear} linear 0.02s 19.5s forwards`,
				},
				"#path": {
					strokeDasharray: "500",
					strokeDashoffset: "500",
					animation: `${keyframes`to {stroke-dashoffset: 0;}`} linear 2s 17.5s forwards`,
				},
			},
		},
		{
			src: images("./square.svg"),
			toSvg: true,
			styles: {
				width: "2.6%",
				left: "32.9%",
				top: "40%",
				div: {
					height: "100%",
					div: {
						height: "100%",
						animation: `${transformTo("translate(180%,-32%)")} linear 5s 35s forwards,
						${keyframes({
							to: {
								transform: "translateY(23%) scale(1.5)",
							},
						})} linear 4s 59s forwards,
						${disappear} 3s linear 71s forwards `,
					},
				},

				svg: {
					overflow: "visible",
					transformBox: "fill-box",
					transformOrigin: "center",
					animation: `${transformTo("rotate(-140deg)")} linear 5s 35s forwards,
					${keyframes({
						from: {
							transform: "rotate(-140deg)",
						},
						to: {
							transform: "rotate(-160deg)",
						},
					})} ease-in-out 3s 40s 29 alternate`,
				},
				"#path_x5F_fill": {
					opacity: "0",
					transformBox: "fill-box",
					transformOrigin: "center",
					animation: `${appear} linear 0.02s 35s forwards`,
				},
				"#path": {
					strokeDasharray: "500",
					strokeDashoffset: "500",
					animation: `${keyframes`to {stroke-dashoffset: 0;}`} linear 2s 33s forwards`,
				},
			},
		},
		{
			src: images("./circle.svg"),
			toSvg: true,
			styles: {
				width: "2.2%",
				left: "9%",
				top: "44.6%",

				div: {
					height: "100%",
					div: {
						height: "100%",
						animation: `${transformTo("translate(909%,-40%)")} linear 4s 54.5s forwards,
						${keyframes({
							to: {
								transform: "translate(1270%,55%) scale(2)",
							},
						})} linear 4s 59s forwards,${disappear} 3s linear 71s forwards`,
					},
				},
				svg: {
					overflow: "visible",
					transformBox: "fill-box",
					transformOrigin: "center",
				},
				"#path_x5F_fill": {
					opacity: "0",
					transformBox: "fill-box",
					transformOrigin: "center",
					animation: `${appear} linear 0.02s 54.5s forwards`,
				},
				"#path": {
					strokeDasharray: "200",
					strokeDashoffset: "200",
					animation: `${keyframes`to {stroke-dashoffset: 0;}`} linear 2s 52.5s forwards`,
				},
			},
		},
		{
			src: images("./grand_mum_walk_01.svg"),
			toSvg: true,
			styles: {
				width: "8.5%",
				top: "23%",
				left: "auto",
				right: "-6%",
				animation: `${transformTo("translateX(-1400%)")} ease-in 34s forwards, ${disappear} ease-in-out 1s  35s forwards`,
				svg: {
					overflow: "visible",
					transformBox: "fill-box",
					"#open_eye": {
						opacity: 1,
						animation: `${open_eye} 5.5s ease-out normal both infinite`,
					},
					"#gMom": {
						animation: `${keyframes({
							from: {
								transform: "translateY(2%)",
							},
							to: {
								transform: "translateY(0%)",
							},
						})} ease-in-out 1s infinite alternate`,
					},
					"#arm": {
						transformOrigin: "50% 0%",
						animation: `${keyframes({
							from: {
								transform: "rotate(5deg)",
							},
							to: {
								transform: "rotate(0deg)",
							},
						})} 2s ease-in-out infinite alternate`,
					},
				},
			},
		},

		{
			src: images("./grand_mum_standing.svg"),
			toSvg: true,
			styles: {
				width: "8.5%",
				top: "26%",
				left: '84%',
				transform: 'translateX(-955%)',
				opacity: 0,
				animation: `${appear} ease-in-out 1s 72s forwards`,
				svg: {
					"#open_eye": {
						opacity: 1,
						animation: `${open_eye} 5.5s ease-out normal both infinite`,
					},
				},
			},
		},
		{
			//front looking

			src: images("./zeya_walk_04.svg"),
			toSvg: true,
			styles: {
				width: "6%",
				top: "51%",
				left: "auto",
				right: "3%",
				animation: `${transformTo("translateX(-50%)")} linear 2s forwards, ${transformTo("translateX(-535%)")} linear 12s 5s forwards, ${disappear} linear 150ms 17s forwards, ${appear} linear 150ms 23s forwards,
					${keyframes({
						from:{
							transform: "translateX(-535%)",
						},
						to: {
							transform: "translateX(-740%)",
						},
					})} 6s 23s linear forwards,
					${keyframes({
						from:{
							transform: "translateX(-740%)",
						},
						to: {
							transform: "translateX(-1242%)",
						},
					})} 12s 40s linear forwards,
					${disappear} 150ms 56s linear forwards 

				  `,

				// animation: `${disappear} linear 150ms 12s forwards, ${appear} linear 150ms 14s forwards, ${disappear} linear 150ms 30s forwards,
				// 	${transformTo("translateX(-1200%)")} linear 34s forwards 1
				//  `,
				svg: {
					overflow: "visible",
					"#still_zeya": {
						opacity: 0,
						animation: `${appear} 455ms 2s linear forwards, ${disappear} 455ms 5s linear forwards, ${appear} 455ms 29s linear forwards, ${disappear} 455ms 40s linear forwards,${appear} 455ms 52s linear forwards`
					},
					"#head_front":{
						opacity: 0,
						animation: `${appear} 350ms 30s ease-in-out forwards, 
						${disappear} 350ms 35s linear forwards,
						${appear} 350ms 42s linear forwards,
						${disappear} 350ms 54s linear forwards`
					},
					"#head02":{
						animation: `${disappear} 350ms 30s ease-in-out forwards, 
						${appear} 350ms 35s linear forwards,
						${disappear} 350ms 42s linear forwards,
						${appear} 350ms 54s linear forwards`
					},
					"#zeya": {
						animation: `${keyframes({
							from: {
								transform: "translateY(-2%)",
							},
							to: {
								transform: "translateY(0%)",
							},
						})} ease-in-out 1s infinite alternate, ${disappear} 455ms 2s linear forwards, ${appear} 455ms 5s linear forwards, ${disappear} 455ms 29s linear forwards, ${appear} 455ms 40s linear forwards,${disappear} 455ms 52s linear forwards`,
					},
					"#open_eye": {
						opacity: 1,
						animation: `${open_eye} 3.5s ease-out normal both infinite`,
					},
					"#open_mouth": {
						opacity: 0,
						animation: `${appear} 3s ease-out 1s forwards`,
					},
					"#close_mouth": {
						opacity: 1,
						animation: `${disappear} 3s ease-out 1s forwards`,
					},
					"#hand02": {
						transformOrigin: "50% 50%",
						animation: `${keyframes({
							from: {
								transform: "rotate(-5deg)",
							},
							to: {
								transform: "rotate(5deg)",
							},
						})} 2s ease-in-out infinite alternate`,
					},
					"#hand01": {
						transformOrigin: "50% 50%",
						animation: `${keyframes({
							from: {
								transform: "rotate(10deg)",
							},
							to: {
								transform: "rotate(-10deg)",
							},
						})} 2s ease-in-out infinite alternate`,
					}
				},
			},
		},
		{
			//back looking
			src: images("./zeya_walk_02.svg"),
			toSvg: true,
			styles: {
				opacity: 0,
				width: "7%",
				top: "52%",
				left: "auto",
				right: "3%",
				animation: `${transformTo("translateX(-456%)")} linear 14s forwards,${appear} linear 150ms 17s forwards, ${disappear} linear 150ms 23s forwards,
					${keyframes({
						from:{
							transform: "translateX(-456%)",
						},
						to: {
							transform: "translateX(-632%)",
						},
					})} 6s 23s linear forwards,
					${keyframes({
						from:{
							transform: "translateX(-632%)",
						},
						to: {
							transform: "translateX(-1070%)",
						},
					})} 12s 34s linear forwards,
					${appear} 150ms 56s linear forwards 

				`,

				// animation: `${appear} linear 150ms 12s forwards, ${disappear} linear 150ms 30s forwards, ${appear} linear 150ms 30s forwards, ${transformTo("translateX(-920%)")} linear 30s forwards,
				//  ${transformTo("translateX(-980%)")} linear 3s 40s forwards
				//  `,

				svg: {
					"#zeya": {
						animation: `${keyframes({
							from: {
								transform: "translateY(-2%)",
							},
							to: {
								transform: "translateY(0%)",
							},
						})} ease-in-out 2s infinite alternate`,
					},
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 2.5s ease-out normal both infinite`,
					},
					"#open_mouth": {
						opacity: 0,
						animation: `${appear} 4s ease-out 33s forwards`,
					},
					"#close_mouth": {
						opacity: 1,
						animation: `${disappear} 4s ease-out 33s forwards`,
					},
					"#head": {
						transform: "rotate(9deg)",
						transformBox: "fill-box",
						transformOrigin: "37% 79%",
						animation: `${keyframes({
							from: {
								transform: "rotate(9deg)",
							},
							to: {
								transform: "rotate(0deg)",
							},
						})} ease-in-out 3s 2s infinite alternate`,
					},
				},
			},
		},
	],
	texts: [
		{
			textDivClass: "story-text page6Text text-center",
			textIndivData: [
				{
					textData: "",
					textClass: "m-0",
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./bg/se_page6.ogg"),
			volume: 0.5,
			delay: 1,
		},
	],
}

export default content
