const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_table.png"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./bunu_jump_run.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "टेबुलमाथि पनि रहेनछ।",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.65 }, { start: 2 }, { start: 2.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6.ogg"),
			delay: 4000,
		},
	],
}

export default content
