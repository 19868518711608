const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page11.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text shaking2 text-center",
			textIndivData: [
				{
					textData: "सबैभन्दा पहिला म डोमा हुँ।",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.2 }, { start: 2.05 }, { start: 2.3 }, { start: 2.75 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./12.ogg"),
			delay: 1000,
		},
	],
}

export default content
