const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background4.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./egg.png"),
			class: "image-one imageCenter element1 imgSize imgegg",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./head.png"),
			class: "image-one imageCenter element2 imgSize imghead",
			imgdiv: "imageCenterDiv h-65",
		},
		{
			src: images("./half_coco.png"),
			class: "image-one imageCenter element3 imgSize imghalf_coco",
			imgdiv: "imageCenterDiv h-65",
		},

		{
			src: images("./coco_un.png"),
			class: "image-one imageCenter element4 imgSize imgcoco_un",
			imgdiv: "imageCenterDiv h-65",
		},
		{
			src: images("./coconut.gif"),
			class: "image-one imageCenter element5 imgSize imgcoconut",
			imgdiv: "imageCenterDiv h-65",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "अण्डा जस्तै आकार छ मेरो।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.4 }, { start: 0.8 }, { start: 1.3 }, { start: 1.8, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मेरो जीउ भरि खैरो कपाल छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 9 },
						{ start: 9.3 },
						{ start: 9.8 },
						{ start: 10.4 },
						{ start: 10.8 },
						{ start: 11.2, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मेरो खैरो जिउभित्र सेतो भाग छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 20 },
						{ start: 20.2 },
						{ start: 20.6 },
						{ start: 21.5 },
						{ start: 22.1 },
						{ start: 22.4, last: true },
					],
				},
			],
		},

		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 31 },
						{ start: 31.2 },
						{ start: 31.5 },
						{ start: 32.1 },
						{ start: 32.4 },
						{ start: 32.5 },
						{ start: 32.5, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element5 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म नरिवल हुँ ।",
					textClass: "m-0",
					timestamps: [{ start: 43 }, { start: 43.1 }, { start: 43.5, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p4a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p4a2.wav"),
			delay: 13000,
		},
		{
			url: audios("./p4a3.wav"),
			delay: 24000,
		},
		{
			url: audios("./p4a4.wav"),
			delay: 35000,
		},
		{
			url: audios("./p4a5.wav"),
			delay: 47000,
		},
	],
}

export default content
