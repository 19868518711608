const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page10.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text shaking1 text-center bg-red",
			textIndivData: [
				{
					textData: "म को हुँ ?",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.65 }, { start: 0.95 }, { start: 0.1 }],
				},
				{
					textData: "म नातिनी, छोरी, बहिनी,",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 1.75 }, { start: 1.95 }, { start: 2.9 }, { start: 3.8 }],
				},
				{
					textData: "दिदी, साथी, भदैनी र",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 5.05 }, { start: 6 }, { start: 7.15 }, { start: 7.85 }],
				},
				{
					textData: "भान्जी सबै हुँ। तर...",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 8.05 }, { start: 8.7 }, { start: 9.25 }, { start: 10 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./11.ogg"),
			delay: 1000,
		},
	],
}

export default content
