// import { jsx } from "@emotion/core"
// import _, { delay, object } from "underscore"
import React, {useContext, useEffect, useState} from "react"
import {Howl, Howler} from "howler"
import { StateContext, DispatchContext } from "../../ShapeColorGame"
import "./scenecss/scene8.styles.scss"
// import { sleep } from "../../utils"
import ColorGame from "../Options/Colorgame"
import OptionsSecond from "../Options/ColorgameSec"
import gsap from "gsap"
import mainTextAnimation from "../../../Common/TextAnimations"
import Snap from 'snapsvg-cjs'
// import 'snapsvg-cjs'
// import { counter } from "@fortawesome/fontawesome-svg-core"
// import textHighLight from "../../../Common/TextHighLightAnime"
// import { faBorderNone } from "@fortawesome/free-solid-svg-icons"
// const images = require.context("../images", true)
const audios = require.context("./dailog/", true)

const Scene = (({stageData, assets, story})=>{
        const state = useContext(StateContext)                /* importing statecontext from shapecolorgame and initializiing at state */
        const dispatch = useContext(DispatchContext)         /* importing statecontext from shapecolorgame and initializiing at state */
        let girlRef = React.useRef(null);                    /* initializing girlrif as reference which directly change in file  */
        let robotRef = React.useRef(null);
        let daiRef = React.useRef(null);
        let speechboxdivRef = React.useRef(null);
        let speechboxRef = React.useRef([]);
        // console.log(story);
const initial = {
        pageup: 0,
        audio: false,
        //    QuestionShow: 0,
        QnAnsShow: false,
        secQNAnsShow: false,
        dailogCounter: 0,
        disable: true,
        count: 0,
        disableSec: false,
        disableThird: true
}

const [stageState, setStageState] = useState(initial);
//    const [data, setdata] = useState(stageData);
   var timestamp = gsap.timeline({});                           /* creating timeline for gsap animation */
//    var map = Snap('#svg_robort');
//    let bgMusic;
//    let dailogSound;
//    console.log(stageState)
   let content = {                                              /* all data are store in object which are used as par their requirement */
        texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 2% 15% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "ऊ त्यहाँ हेर त। एक जना दाइ घरमा रङ लगाउँदै हुनुहुन्छ । उहाँले सघाउनु हुन्छ कि ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.55 }, { start: 1.00 }, { start: 1.35 }, { start: 1.90 }, { start: 2.55 }, { start: 3.00 }, { start: 3.40 },{ start: 3.90 }, { start: 4.35 }, { start: 4.90 }, { start: 5.80 }, { start: 6.40 }, { start: 7.20 }, { start: 8.10 }, { start: 8.50 }, { start: 8.75 }],
                                        delayTime: 12000,
                                        hideTimer: 20000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "दाइ काम गर्दै हुनुहुन्छ । हाम्रो कुरा मान्‍नु होला त ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.75 }, { start: 1.10 }, { start: 1.60 },{ start: 2.00 }, { start: 2.35 }, { start: 2.60 }, { start: 2.90 }, { start: 3.15 }, { start: 3.50 }],
                                        delayTime: 21000,
                                        hideTimer: 25000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-3',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 12% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "सोधौँ न दाइलाई नै ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 },{ start: 0.95 }, { start: 1.40 }, { start: 1.70 }],
                                        delayTime: 26000,
                                        hideTimer: 30000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-4',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '2% 3% 10% 3%'
                        },
                        textIndivData: [
                                {
                                        textData: "हुन्छ !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }],
                                        delayTime: 31000,
                                        hideTimer: 33000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 2% 12% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "सुन्‍नुहोस् न दाइ, मेरो शरीरमा रङ लगाईदिन सक्‍नु हुन्छ ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }, { start: 0.80 },{ start: 1.40 }, { start: 2.20 }, { start: 2.80 },{ start: 3.30 }, { start: 3.90 }, { start: 4.35 },{ start: 4.70 }],
                                        delayTime: 34000,
                                        hideTimer: 40000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-6',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "रङ ? किन भाइ के भयो ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }, { start: 0.90 }, { start: 1.20 }, { start: 1.60 }, { start: 1.85 }, { start: 2.10 }],
                                        delayTime: 41000,
                                        hideTimer: 44000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-7',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 7,
                                        textData: "हिजोको ठुलो पानीले मेरो रङहरू सबै बगाएर लग्यो ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.35 }, { start: 0.80 }, { start: 1.35 }, { start: 1.65 }, { start: 2.05 }, { start: 2.45 },{ start: 2.90 }, { start: 3.25 }],
                                        delayTime: 44000,
                                        hideTimer: 49000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-8',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 8,
                                        textData: "उसका हराएका आकारहरू त हामीले भेट्टायौँ । तर रङ भेटिएन ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }, { start: 1.15 }, { start: 1.90 }, { start: 2.10 }, { start: 2.60 }, { start: 3.30 },{ start: 3.60 }, { start: 4.20 }, { start: 4.70 }, { start: 5.50 }],
                                        delayTime: 50000,
                                        hideTimer: 57000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-9',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "हुन्छ, म सहयोग गर्छु ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }, { start: 0.95 }, { start: 1.30 }, { start: 1.70 }],
                                        delayTime: 58000,
                                        hideTimer: 61000
                                }
                        ]
                },
                // dailog after quention option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-10',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 10,
                                        textData: "आऊ, अब फेरि रङ भरौँ है त ?",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6900000,
                                        hideTimer: 7000000
                                }
                        ]
                },
                // dailog after second option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-11',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 11,
                                        textData: "धन्यवाद दाइ ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 7100000,
                                        hideTimer: 7200000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-12',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "आज कस्तो रमाइलो भयो है समा ?",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 7100000,
                                        hideTimer: 7400000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-13',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हो फुर्बा धेरै रमाइलो भयो ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 7100000,
                                        hideTimer: 7400000,
                                }
                        ]
                }
        ],
        sounds: 
		[{
			url: audios("./scene8_sama1.wav"),
			delay: 12000,
		},{
                        url: audios("./scene8_furba1.wav"),
			delay: 21000,
                },{
			url: audios("./scene8_sama2.wav"),
			delay: 26000,
		},{
                        url: audios("./scene8_furba2.wav"),
			delay: 31000,
                },{
                        url: audios("./scene8_furba3.wav"),
			delay: 34000,
                },{
                        url: audios("./painter2.wav"),
			delay: 41000,
                },{
                        url: audios("./scene8_furba4.wav"),
			delay: 44000,
                },{
                        url: audios("./scene8_sama3.wav"),
			delay: 50000,
                },{
                        url: audios("./painter1.wav"),
			delay: 58000,
                }],
}
        let content1 = {
                texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-10',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "आऊ, अब फेरि रङ भरौँ है त ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.35 }, { start: 0.55 }, { start: 0.80 }, { start: 1.90 }, { start: 2.15 }, { start: 2.45 }, { start: 2.85 }],
                                        delayTime: 2000,
                                        hideTimer: 8000
                                }
                        ]
                },
                ],
                sounds:[
                        {
                        url: audios("./Aau aba feri ranga bharau hai ta.wav"),
			delay: 2000,
                        },
                ]
        }

        let content2 = {
                texts: [
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-11',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "धन्यवाद दाइ ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.55 }, { start: 1.10 }],
                                                delayTime: 1000,
                                                hideTimer: 3000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-12',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "आज कस्तो रमाइलो भयो है समा ?",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.35 }, { start: 0.70 }, { start: 1.15 }, { start: 1.40 }, { start: 1.55 }, { start: 1.90 }],
                                                delayTime: 4000,
                                                hideTimer: 8000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-13',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "हो फुर्बा धेरै रमाइलो भयो ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.90 }, { start: 1.45 }, { start: 2.00 }, { start: 2.40 }],
                                                delayTime: 9000,
                                                hideTimer: 13000,
                                        }
                                ]
                        },
                ],
                sounds:[
                        {
                                url: audios("./scene8_furba5.wav"),
			        delay: 1000,
                        },
                        {
                                url: audios("./scene8_furba6.wav"),
			        delay: 4000,
                        },
                        {
                                url: audios("./scene8_sama5.wav"),
			        delay: 9000,
                        }
                ]
        }
        // console.log(story);
//    useEffect(()=>{                                          /* these effect is use for gsp animation of each and every object */
// //    console.log(girlRef, girlRef.current.children);
//         if(stageState.dailogCounter !== 9 && stageState.dailogCounter !== 10){
//                 console.log(stageState.dailogCounter + " " + stageState.dailogCounter)
                
//                         return () => {
//                                 gsap.killTweensOf("*")
//                                 console.log("last return")
//                         }
//         }else{

//         }                
// }, [girlRef.current, robotRef.current, speechboxRef.current, stageState.dailogCounter, timestamp])

   useEffect(()=> {                                           /* this useEffect is uesd for background music play */
        if(!stageState.audio){
                        setStageState({...stageState, audio:true})
                        const bgMusic = new Howl({
                                src: [content.sounds[stageState.pageup].url],
                                autoplay: false,
                                preload: true,
                                volume: 0.07,
                                loop: true,
                                // onend: async () => {
                                //         // if (stageData.id === 10) {
                                //         //         setOptionState({ ...optionState, finalStageQ: stageData.questioner })
                                //         //         dispatch({ type: "LAST_PAGE_COMPLETE" })
                                //         // }
                                //         // await sleep(2000)
                                //         // await playAnimalSound()
                                // },
                        })
                        // console.log(content.sounds[stageState.pageup].url)
                        bgMusic.play()     
                        return () => {
                                Howler.unload()
                                bgMusic.unload()
                        }
                }      
        },[stageState.audio, stageData.bgMusic, content.sounds, setStageState]);

        let textList = content.texts;
        let textListB;
        let soundList;
        let textListC;
        let soundListC;
useEffect(()=>{                                      /* this useEffect is used for showing dailogbox highilighting text and play audio for each dailog*/
        let pagenumber  = 1;
        textList = content.texts;
        soundList = content.sounds;
        // console.log(stageData.disable)
        if(stageState.disable){
                let objCount = 1   
                let timeouts = []
                let timestams = []
                let textIndivData = []
                let delaytime = []
                let hideTime = []   
                let showdata;
                //    console.log(textList);
                timestamp.to(girlRef.current, {
                        duration: 9,
                        x: "200%",
                        ease: "power1.in",
                        delay: 1,
                        onComplete: ()=>{
                                girlRef.current.src = stageData.girlLooking
                        }
                        })
                        .to(robotRef.current, {
                                duration : 7,
                                // delay: 1,
                                x: "115%",
                                ease: "power1.in",
                                onComplete: ()=>{
                                        girlRef.current.style.transform = "translate(200%, 0%) rotateY(180deg)"
                                        robotRef.current.src = stageData.robotLookPng
                                }
                        }, "-=7")
                        .to(robotRef.current,{
                                delay: 1,
                                onComplete: () => {
                                        girlRef.current.src = stageData.girlTalkGif
                                        // robotRef.current.src = stageData.robotHandGif;
                                }
                        })
                if( textList?.length > 0){
                        textList.forEach((item) => {
                                textIndivData = [...textIndivData, ...item.textIndivData]
                        })
                        textIndivData.forEach((data) =>{
                                // console.log(data)
                                
                                if(data.timestamps){
                                        timestams = [...timestams, ...data.timestamps];
                                        // textHighLight(data.textdata, data.timestamps)
                                }else{
                                        timestams = null
                                }
                                
                                if(data.delayTime){
                                        delaytime.push(
                                                setTimeout(()=>{
                                                showdata  = document.getElementsByClassName('sp-'+ objCount);
                                                        // console.log(delaytime);
                                                        gsap.to(showdata, {
                                                                scale: 1,
                                                                ease: "power1.in",
                                                                display: "block",
                                                                onComplete: () => {
                                                                        if(objCount == 3){
                                                                                robotRef.current.src = stageData.robotHandGif;
                                                                                // daiRef.current.src = stageData.daiTalking;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                girlRef.current.style.transform = "translate(200%, 0%) rotateY(0deg)"
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 4){
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                girlRef.current.src = stageData.girlTalkGif;
                                                                                // daiRef.current.src = stageData.daiTalking;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 5){
                                                                                robotRef.current.src = stageData.robotHandGif;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                // daiRef.current.src = stageData.daiTalking;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 6){
                                                                                robotRef.current.src = stageData.robotHandGif;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiGivingHand;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 7){
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiTalking;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 8){
                                                                                robotRef.current.src = stageData.robotHandGif;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiGivingHand;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 9){
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                girlRef.current.src = stageData.girlTalkGif;
                                                                                daiRef.current.src = stageData.daiGivingHand;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 10){
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiTalking;
                                                                                console.log(objCount)
                                                                        }
                                                                }      
                                                        })
                                                
                                                        
                                                        let textAnimations;
                                                        import(`../../Animations`).then((customAnims) =>{
                                                                let animationObj = new customAnims.default()
                                                                textAnimations = animationObj.runTextAnimations
                                                                // textAnimations = mainTextAnimation
                                                                if(animationObj.runTextAnimations){
                                                                        animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        objCount ++ ;
                                                                        // animationObj.forEach(() =>{
                                                                        //         animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        // })
                                                                }
                                                                // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                        })
                                                        .catch((error) =>{
                                                                if(story.defaultTextAnimation){
                                                                        textAnimations = mainTextAnimation
                                                                }else{
                                                                        textAnimations = () => {}
                                                                }
                                                        })
                                                },data.delayTime)
                                        )
                                }else{
                                        delaytime = null
                                }
                                if(data.hideTimer){
                                        hideTime.push(
                                                setTimeout(() => {
                                                        showdata  = document.getElementsByClassName('sp-'+ (objCount-1));
                                                        // showdata[0].style.display = 'none';
                                                        gsap.to(showdata, {
                                                                scale: 0.1,
                                                                ease: "power1.in",
                                                                delay: 1,
                                                                display: "none",
                                                                onComplete: () => {
                                                                                // robotRef.current.src = stageData.robotLookPng;
                                                                                // girlRef.current.src = stageData.girlLooking;
                                                                        
                                                                }
                                                        })
                                                        if(objCount === 10){
                                                                        setStageState({...stageState, disable:false, secQNAnsShow:false, QnAnsShow:true});   
                                                                        // textIndivData = [textIndivData];
                                                                        // console.log(textIndivData);

                                                        }
                                                }, data.hideTimer)
                                        )
                                }
                        })
                }else{
                        timestams = null
                }
                if(soundList && soundList.length){
                        soundList.forEach((sound) => {
                                // console.log(sound)
                                let audio = new Howl({
                                        src: [sound.url],
                                        preload: true,
                                        autoplay:false,
                                        loop: sound.loop ?? false,
                                        volume: sound.volume ?? 0.7,
                                })
                                if(sound.delay){
                                        timeouts.push(
                                                setTimeout(()=>{
                                                        audio.play();
                                                        // content.texts.textMainDivClass.style.display = 'block'
                                                },sound.delay)
                                        )
                                }else{
                                        // audio.unload()
                                }
                        })
                }
                return () => {
                        // if(audio) audio.unload()
                        // Howler.unload()
                        timeouts.forEach((timeout) =>{
                                clearTimeout(timeout)
                                // Howler.unload()
                        })
                        delaytime.forEach((timeout) => {
                                clearTimeout(timeout);
                        })
                        gsap.killTweensOf("*")
                        // console.log("last return")
                }
        }
},[stageData.disable, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData])

useEffect(()=> {            /* this useeffect is used when user click the right answer*/
        if(stageState.dailogCounter === 9){
                if(stageState.disableSec){
                        textListB = content1.texts;
                        soundList = content1.sounds;
                        let textIndivData = []
                        let timestamsSec = []
                        let timeouts = []
                        let speechCount = 10;
                        let delaytime = []
                        let hideTime = []
                        let showdata;
                        // console.log(stageState.disable)
                        robotRef.current.classList.add("robot_afterAnsCorrect_p8");
                        girlRef.current.classList.add("girl_afterAnsCorrect_p8");
                        if(robotRef.current){
                                robotRef.current.src = stageData.robotColor  
                        }
                        if(girlRef.current){
                                girlRef.current.src = stageData.girlTalkGif
                        }
                        if( textListB?.length > 0){
                                textListB.forEach((item) => {
                                        textIndivData = [...textIndivData, ...item.textIndivData]
                                })
                                textIndivData.forEach((data) =>{
                                        
                                        if(data.timestamps){
                                                timestamsSec = [...timestamsSec, ...data.timestamps];
                                        }else{
                                                timestamsSec = null
                                        }
                                        
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('sp-'+ speechCount);
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCount === 10){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotColor;
                                                                                        daiRef.current.src = stageData.painterPng;
                                                                                        console.log(speechCount);
                                                                                }
                                                                        }      
                                                                });
                                                                let textAnimations1;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations1 = animationObj.runTextAnimations
                                                                        if(animationObj.runTextAnimations){
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCount-1], timestamsSec)
                                                                                speechCount ++ ;
                                                                        }
                                                                        // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations1 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations1 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('sp-'+ (speechCount-1));
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {                                
                                                                        }
                                                                })
                                                                if(speechCount == 11){
                                                                        setStageState({...stageState, dailogCounter:13, secQnAnsShow:true});
                                                                        speechboxdivRef.current.style.display = "none";
                                                                        showdata[0].style.display = 'none';
                                                                } 
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsSec = null;
                        }
                        if(soundList && soundList.length){
                                soundList.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                        return () => {
                                // if(audio) audio.unload()
                                // Howler.unload()
                                timeouts.forEach((timeout) =>{
                                        clearTimeout(timeout)
                                        // Howler.unload()
                                })
                                delaytime.forEach((timeout) => {
                                        clearTimeout(timeout);
                                })
                                gsap.killTweensOf("*")
                                // console.log("last return")
                        }
                }
        }
        if(stageState.dailogCounter === 14){
                if(stageState.disableThird){
                        speechboxdivRef.current.style.display = "block";
                        textListC = content2.texts;
                        soundListC = content2.sounds;
                        let textIndivDataC = [];
                        let timestamsThird = [];
                        let timeouts = [];
                        let speechCountThird = 11;
                        let delaytime = [];
                        let hideTime= [];
                        let showdata;
                        robotRef.current.style.transform = "translate(0%) rotateY(0deg)";
                        girlRef.current.style.transform = "rotateY(180deg)";
                        if( textListC?.length > 0){
                                textListC.forEach((item) => {
                                        textIndivDataC = [...textIndivDataC, ...item.textIndivData]
                                        // console.log(textIndivDataC)
                                })
                                textIndivDataC.forEach((data) =>{
                                        if(data.timestamps){
                                                timestamsThird = [...timestamsThird, ...data.timestamps];
                                        }else{
                                                timestamsThird = null
                                        }
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('sp-'+ (speechCountThird));
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCountThird === 12 || speechCountThird === 13) {
                                                                                        robotRef.current.src = stageData.robotColorTalk;
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        daiRef.current.src = stageData.painterPng;
                                                                                }
                                                                                // if(speechCountThird === 13){
                                                                                //         // girlRef.current.style.transform = "rotateY(0deg)";
                                                                                //         girlRef.current.src = stageData.girlTalkGif;
                                                                                //         robotRef.current.src = stageData.robotLookPng;
                                                                                //         daiRef.current.src = stageData.painterPng;
                                                                                // }
                                                                                if(speechCountThird === 14) {
                                                                                        robotRef.current.src = stageData.robotColor;
                                                                                        girlRef.current.src = stageData.girlTalkGif;
                                                                                        daiRef.current.src = stageData.painterPng;
                                                                                        setStageState({...stageState, disableThird:false})
                                                                                }
                                                                                
                                                                        }      
                                                                });

                                                                let textAnimations2;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations2 = animationObj.runTextAnimations
                                                                        
                                                                        if(animationObj.runTextAnimations){
                                                                                console.log(speechCountThird)
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCountThird-1], timestamsThird)
                                                                                speechCountThird ++ ;
                                                                                console.log(speechCountThird)
                                                                        }
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations2 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations2 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('sp-'+ (speechCountThird-1));
                                                                // showdata[0].style.display = 'none';
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {
                                                                                // if(speechCountThird === 15) {
                                                                                //         girlRef.current.style.transform = "translate(0%, 0%) rotateY(180deg)"
                                                                                // }    
                                                                        }
                                                                })
                                                                if(speechCountThird === 14){
                                                                        dispatch({type: "LAST_PAGE_COMPLETE"})
                                                                        setStageState({...stageState, disableThird:false})
                                                                        console.log(state.rewardComplete)
                                                                        console.log(stageState.disable+" "+stageState.disableSec+" "+ stageState.disableThird)
                                                                }
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsThird = null;
                        }
                        if(soundListC && soundListC.length){
                                soundListC.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                }
                
        }
},[stageData.disable,stageData.disableSec, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData, speechboxdivRef.current]);


const RobortImageClick = (e, index) => {
        const elem = e.target.parentNode;
        let mainPar = elem.parentNode;
        let mainParA = mainPar.parentNode;
        let child = e.target.firstChild;
        let f = e.target.firstElementChild
        let c = f.getAttribute("id");
        var RobotImg = e.target.className;
        var counter = 0;
        // console.log(f);

        let map = Snap("#"+c);
        Snap.load(stageData.robotOpt, function(data){
                if (map) {
                        map.append(data);
                        f.firstElementChild.remove();
                        // console.log(map); 
                        if(RobotImg.includes('blue')){
                                mainParA.classList.remove("blue");
                                counter = counter + 1 ;
                                        var a = data.select("#Blue").attr({class: "add_blue_color"});
                                        console.log(counter); 
                        }
                        if(RobotImg.includes('green')){
                                mainParA.classList.remove("green");
                                counter = counter + 1 ;
                                        var a = data.select("#Green").attr({class: "add_green_color"});
                                        console.log(counter); 
                        }
                        if(RobotImg.includes('yellow')){
                                mainParA.classList.remove("yellow");
                                counter = counter + 1 ;
                                        var a = data.select("#Yellow").attr({class: "add_yellow_color"});
                                        console.log(counter); 
                        }
                        if(RobotImg.includes('red')){
                                mainParA.classList.remove("red");
                                counter = counter + 1 ;
                                        var a = data.select("#Red").attr({class: "add_red_color"});
                                        console.log(counter); 
                        }
                        
                }
                if(counter == 4){
                        // console.log("a")
                        gsap.to("#"+c,2,{
                                // x:"-165%",
                                onComplete:() => {
                                        setStageState({...stageState, dailogCounter:9, QnAnsShow:false,disableSec:true});
                                }
                        })
                }
        })
        // const siblings = (elem) => {
        //         // create an empty array
        //         let siblings = [];
        
        //         // if no parent, return empty list
        //         if (!elem.parentNode) {
        //         return siblings;
        // }
        //         // first child of the parent node
        //         let sibling = elem.parentNode.firstElementChild;
                
        //         // loop through next siblings until `null`
        //         do {
        //             // push sibling to array
        //         if (sibling != elem) {
        //                 siblings.push(sibling);
                        
        //         }
        //         } while (sibling = sibling.nextElementSibling);
        //         // return siblings;
        //         var divA = siblings[0].id;
                
                
        //         var sel_svg = e.target.firstChild;
        //         var sel_svg1 = sel_svg.firstChild;
        //         var aa = sel_svg1.firstChild;
        //         console.log(aa);
        // };
        
        // siblings(elem)
}

const SecondanswerClick = (e, index) => {
        const check = e.target.className.split(" ")[2];
        var par = e.target.firstElementChild.className.split(" ")[1];
        var a= e.target;
        let parentDiv = a.parentNode;
        let parentDivChild = parentDiv.previousSibling;
        let childSel = parentDivChild.children;
        var count = 0;
        console.log(parentDiv)
        console.log(parentDivChild)
        console.log(childSel)
        if(check === "red"){
                // e.target.style.backgroundColor = "#f54e4e";
                gsap.to("."+par,1,{
                        opacity: 1,
                        scale:1,
                        onComplete:() => {
                                // count ++;
                                // console.log(count)
                                a.style.backgroundColor = "#f54e4e";
                                // check.pointerEvents = "none";
                        setStageState({...stageState, count:stageState.count + 1});
                        console.log(stageState.count)
                        }
                })
                childSel[3].style.opacity = 0;
        } 
        if(check === "green"){
                gsap.to("."+par,1,{
                        opacity: 1,
                        scale:1,
                        onComplete:() => {
                                // count ++;
                                // console.log(count)
                                a.style.backgroundColor = "#33c129";
                                // check.pointerEvents = "none";
                        //      setStageState({...stageState, dailogCounter:9, QnAnsShow:false});
                        setStageState({...stageState, count:stageState.count + 1});
                        console.log(stageState.count)
                        }
                })
                childSel[0].style.opacity = 0;
        } 
        if(check === "yellow"){
                gsap.to("."+par,1,{
                        opacity: 1,
                        scale:1,
                        onComplete:() => {
                                // count ++;
                                // console.log(count)
                                a.style.backgroundColor = "#eddc3f";
                                // check.pointerEvents = "none";
                        //      setStageState({...stageState, dailogCounter:9, QnAnsShow:false});
                        setStageState({...stageState, count:stageState.count + 1});
                        console.log(stageState.count)
                        }
                })
                childSel[2].style.opacity = 0;
        } 
        if(check === "blue"){
                gsap.to("."+par,1,{
                        opacity: 1,
                        scale:1,
                        onComplete:() => {
                                // count ++;
                                // console.log(count)
                                a.style.backgroundColor = "#3d63da";
                                // check.pointerEvents = "none";
                        //      setStageState({...stageState, dailogCounter:9, QnAnsShow:false});
                        setStageState({...stageState, count:stageState.count + 1});
                        console.log(stageState.count)
                        }
                })
                childSel[1].style.opacity = 0;
        }
        if(stageState.count === 3)
        {
                gsap.to("."+par,{
                        delay: 3,
                        onComplete:() => {
                                setStageState({...stageState, dailogCounter:14, QnAnsShow:false});
                        }
                })
                var parentMove = parentDiv.className.split(" ")[0];
                gsap.to("."+parentMove, {
                        x:"-56%",
                        duration:2,
                        delay:1
                })
                
        }
        
}

        return (
                <div className="imageContainer">
                {
                        // <OptionsSecond 
                        // assets={assets}
			// stageData={stageData}
                        // SecondanswerClick={SecondanswerClick}
                        // />
                        stageState.QnAnsShow === true ? (
			<ColorGame
			RobortImageClick={RobortImageClick}
			assets={assets}
			stageData={stageData}
			/>
			):  
                        <div className="speechContainer" ref={speechboxdivRef}>
                        <img src={stageData.girlMoveGif} className="girl_walk" ref={girlRef} 
                        />
                        <img src ={stageData.robotGif} className="robot" ref={robotRef} />
                        <img src ={stageData.painterPng} className="painter" ref={daiRef} />
                        <img src ={stageData.paint} className="paint" />

                                {textList.length ? (
                                        textList.map((textContent, index) => {
                                                const textStyles = textContent.styles ?? {}
                                                // console.log(textStyles);
                                                const maindiv = textContent.textMainDivClass;
                                                const className = `${textContent.textDivClass} textContentDiv`
                                                return(
                                                        <div key={index} className={`speechbox_p8 ${maindiv}`} ref={ref => {speechboxRef.current[index] = ref;}}> 
                                                        <img src ={stageData.bubble} className="box" /> 
                                                        <div className={className} style ={ textStyles }>
                                                                {textContent.textIndivData.map((text, index) => (
                                                                        <p key={index} id="dailog" className={text.textClass} >
                                                                                {text.textData}
                                                                        </p>
                                                                ))}
                                                        </div>
                                                        </div>
                                                )
                                        })
                                ) : ( 
                                        <div></div>
                                )
                        }

                        </div>
                }
                {stageState.dailogCounter === 13 && (
                        <OptionsSecond 
                        assets={assets}
			stageData={stageData}
                        SecondanswerClick={SecondanswerClick}
                        />
                        )
                }
                </div>        
        )
        
});

export default Scene;

