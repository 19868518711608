import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "कविको मयूर",
	key: "kabi-ko-mayur",
	coverImage: images("./cover_page.jpg"),
	credits: {
		writer: "दीपक शम्चु",
		illustrator: "सिमल",
		template: "default",
		bg: images("./cover_page.jpg"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
