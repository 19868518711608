import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "बुनुको चस्मा खै?",
	key: "bunuko-chasma",
	coverImage: images("./cover_page.png"),
	credits: {
		writer: "लोकेन्द्र शाक्य",
		illustrator: "नरेन्द्र अर्याल",
		template: "default",
		bg: images("./cover_page.png"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
