const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 bg-white",
	images: [
		{
			src: images("./4FriendsP4/fourFeet.svg"),
			class: "image-one imgbox-sm fourFeet-p4",
			imgdiv: "imageCover",
		},
		{
			src: images("./4FriendsP4/babyHand.svg"),
			class: "image-one imgbox-sm hands babyHand",
			imgdiv: "imageCover",
		},
		{
			src: images("./4FriendsP4/dollHand.svg"),
			class: "image-one imgbox-sm hands dollHand",
			imgdiv: "imageCover",
		},
		{
			src: images("./4FriendsP4/teddyHand.svg"),
			class: "image-one imgbox-sm hands teddyHand",
			imgdiv: "imageCover",
		},
		{
			src: images("./4FriendsP4/kopilaHand.svg"),
			class: "image-one imgbox-sm hands kopilaHand",
			imgdiv: "imageCover",
		},
		{
			src: images("./outline-p4-white3.png"),
			class: "image-one outline-p4",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center textbox textbox-ban",
			textIndivData: [
				{
					textData: '"मसँग रमाइलो किताब छ। आऊ पढौँ।"',
					textClass: "m-0 textStyles",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 1.9 }, { start: 2.35 }, { start: 3.15 }, { start: 3.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s4.ogg"),
			delay: 4100,
		},
	],
}

export default content
