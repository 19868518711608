const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	images: [
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-1",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-2",
		},
		{
			src: images("./13to24/flying.png"),
			imgdiv: "imageCover flying",
		},
		{
			src: images("./13to24/duckwithstick.png"),
			imgdiv: "imageCover flying d-none",
		},
		{
			src: images("./13to24/fall.gif"),
			imgdiv: "imageCover tortoise-2 d-none",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData:
						"लामो बाटो उड्दा बोल्न चाहिँ नपाइने भयो किनभने कछुवाले बोल्यो भने त उसको मुखबाट हाँगो छुट्‍न गई ऊ नै तल खस्‍ने भयो।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1 },
						{ start: 1.5 },
						{ start: 2.2 },
						{ start: 2.7 },
						{ start: 3.4 },
						{ start: 3.8 },
						{ start: 4.9 },
						{ start: 5.8 },
						{ start: 6.2 },
						{ start: 6.5 },
						{ start: 6.8 },
						{ start: 7.4 },
						{ start: 8.1 },
						{ start: 8.7 },
						{ start: 9.1 },
						{ start: 9.5 },
						{ start: 9.9 },
						{ start: 10 },
						{ start: 10.4 },
						{ start: 10.8 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p3_s2.ogg"),
			delay: 500,
		},
	],
}

export default content
