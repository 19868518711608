import { keyframes, css } from "@emotion/core"
import { appear, disappear } from "styledElements/keyframes"
const content = {
	images: [
		{
			src: "birds/bird_sitting_01_page11.png",
			styles: {
				bottom: 0,
				width: "40%",
				top: "auto",
				animation: `${disappear} 0.2s 1s ease-out normal forwards`,
			},
		},
		{
			src: "birds/bird_fly05.gif",
			styles: {
				width: "25%",
				top: "55%",
				left: "12%",
				animation: `${keyframes({
					to: {
						transform: "translate(315%, -181%)",
					},
				})} 2s 0.2s ease-out normal forwards`,
			},
		},

		{
			src: "city/bg_village.png",
			styles: css`
				width: 100%;
				top: 0;
				left: 0;
				transform: translate(-50%, -20%) scale(2);
				transform-box: fill-box;
				transform-origin: top left;
				transform-style: flat;
				height: fit-content;
				opacity: 0;
				animation: ${appear} 0.2s 1s ease-in normal forwards,
					${keyframes`
					to {
						transform: translate(-150%,-80%) scale(2.5);
					}`} 5s 1s linear normal forwards,
					${disappear} 0.2s 5s ease-out normal forwards;
			`,
		},
		{
			src: "birds/bird_fly01.gif",
			styles: css`
				width: 20%;
				top: 25%;
				left: 0;
				transform: translate(-100%, 0%);
				opacity: 1;
				animation: ${appear} 0.2s 1s ease-in normal forwards,
					${keyframes`
					to {
						transform: translate(480%,60%) rotate(35deg);
					}`} 5s 1s ease-out normal forwards;
			`,
		},
		{
			src: "city/house.png",
			styles: {
				width: "81%",
				opacity: 0,
				animation: `${appear} 0.5s 5.5s ease-out normal forwards`,
			},
		},
		{
			src: "birds/bird_sitting_page11.png",
			styles: {
				width: "30%",
				top: "34%",
				left: "auto",
				right: "0",
				opacity: 0,
				animation: `${appear} 1s 5s ease-out normal forwards`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: css`
				left: auto;
				right: 0;
				@media screen and (max-width: 1400px) {
					right: auto;
				}
				padding: 15px;
				background: rgba(255, 255, 255, 0.8);
				border-radius: 15px;
			`,
			textIndivData: [
				{
					textData: "नयाँ बासको खोजिमा",
					textClass: "m-0 p3-text_a",
				},
				{
					textData: "चरि पुगे गाउँमा",
					textClass: "m-0 p3-text_b",
				},
			],
		},
	],
	sounds: [
		{
			url: "p10.ogg",
			delay: 500,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			volume: 0.2,
			isBg: true,
		},
		{
			url: "kids.ogg",
			delay: 4000,
			volume: 0.1,
			isBg: true,
		},
	],
}

export default content
