const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page_01.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideTextRight text-center",
			textIndivData: [
				{
					textData: "वसन्त ऋतु",
					textClass: "fz-6 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 1.05 }],
				},
				{
					textData: "रूखमा रङ्गीचङ्गी",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 2.1 }, { start: 3.1 }],
				},
				{
					textData: "फूलहरू फुलेका छन्।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 4.2 }, { start: 5 }, { start: 5.6 }],
				},
				{
					textData: "रूखको फेदमा मानिसहरू",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 6.5 }, { start: 7.2 }, { start: 8 }],
				},
				{
					textData: "गफ गरिरहेका छन्।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 9.2 }, { start: 9.6 }, { start: 10.4 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 1000,
		},
		{
			url: audios("./a1.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
