const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./cow-mooing.gif"),
			class: "image-five",
			imgdiv: "imageCover purplebg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center",
			textIndivData: [
				{
					textData: "आइतबार आयो,",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 0 }, { start: 0.85 }, { start: 2 }],
				},
				{
					textData: "गाई त करायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2.3 }, { start: 2.55 }, { start: 3.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 1000,
		},
		{
			url: audios("./a2.ogg"),
			delay: 150,
			volume: 0.2,
			isBg: true,
		},
	],
}

export default content
