/* activityId => order of showing activity-needtoImplement
   activityName => Name of the activity that should be shown in Readlist page
   activityKey => uniquekey for each story
   activityPath => story path that should be inside containers
*/
const images = require.context("EGRContent/Story", true)
const activityList = {
	activity: [
		{
			activityId: "35",
			activityName: "सालक र बालक",
			activityKey: "salak",
			label: "hard",
			storyBanner: images("./Salak/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "32",
			activityName: "साम्ङ्ये छिरिङ",
			activityKey: "chhring",
			label: "hard",
			storyBanner: images("./ChhringLama/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "16",
			activityName: "म पनि सक्छु",
			activityKey: "ma-pani-sakchu",
			label: "hard",
			storyBanner: images("./MaPaniSakchu/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "31",
			activityName: "रमाइलो होली",
			activityKey: "ramailo-holi",
			label: "hard",
			storyBanner: images("./RamailoHoli/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "30",
			activityName: "पाहाको तेल",
			activityKey: "PahaKoTel",
			label: "hard",
			storyBanner: images("./PahaKoTel/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "37",
			activityName: "थारु टिका",
			activityKey: "tharu-tika",
			label: "hard",
			storyBanner: images("./TharuTika/images/tharu-tika-thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "39",
			activityName: "पेटेटे",
			activityKey: "petete",
			label: "hard",
			storyBanner: images("./Petete/images/petete-thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "28",
			activityName: "मने",
			activityKey: "Mane",
			label: "hard",
			storyBanner: images("./Mane/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "25",
			activityName: "फाटेको जुत्ता",
			activityKey: "FhatekoJutta",
			label: "hard",
			storyBanner: images("./FhatekoJutta/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "23",
			activityName: "बादल",
			activityKey: "Badal",
			label: "hard",
			storyBanner: images("./Badal/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "21",
			activityName: "लाले र काले",
			activityKey: "LaleyRaKaley",
			label: "hard",
			storyBanner: images("./LaleyRaKaley/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "18",
			activityName: "मीज़ान",
			activityKey: "meezan",
			label: "hard",
			storyBanner: images("./Meezan/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "16",
			activityName: "चार्ली चमेरो",
			activityKey: "charlie-chamero",
			label: "hard",
			storyBanner: images("./CharlieChamero/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "43",
			activityName: "युमा भाग १",
			activityKey: "yuma-part-one",
			label: "hard",
			storyBanner: images("./YumaWeavingPartOne/images/Thumbnail-yuma.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "42",
			activityName: "युमा भाग २",
			activityKey: "yuma-part-two",
			label: "hard",
			storyBanner: images("./YumaWeavingPartTwo/images/Thumbnail-yuma-part-two.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "41",
			activityName: "सृष्टिको गीत भाग १",
			activityKey: "shristi-ko-geet-part-one",
			label: "hard",
			storyBanner: images("./ShristiKoGeetPartOne/images/Thumbnail-shristi-ko-fool.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "40",
			activityName: "सृष्टिको गीत भाग २",
			activityKey: "shristi-ko-geet-part-two",
			label: "hard",
			storyBanner: images("./ShristiKoGeetPartTwo/images/Thumbnail-shristi-ko-geet-part-two.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "40",
			activityName: "जुनेली रात",
			activityKey: "juneli-raat",
			label: "hard",
			storyBanner: images("./JuneliRaat/images/thumbnail-juneli-raat.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "38",
			activityName: "म झुम्रा नाच नाच्छु",
			activityKey: "ma-jhumra-nach-nachchu",
			label: "hard",
			storyBanner: images("./MaJhumraNach/images/ma-jhumra-nach-nachchu-thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "36",
			activityName: "सेल्बोन २",
			activityKey: "selbon-part-two",
			label: "hard",
			storyBanner: images("./SelbonPartTwo/images/thumbnail-2.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "34",
			activityName: "सेल्बोन १",
			activityKey: "selbon",
			label: "hard",
			storyBanner: images("./Selbon/images/thumbnail-1.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "33",
			activityName: "म,बाजे,र भुत",
			activityKey: "ma-baje-bhoot",
			label: "hard",
			storyBanner: images("./MaBajeBhoot/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "29",
			activityName: "बाबरेको बाटो",
			activityKey: "BabreyKoBato",
			label: "hard",
			storyBanner: images("./BabreyKoBato/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "27",
			activityName: "मेरी बई",
			activityKey: "MeriBai",
			label: "hard",
			storyBanner: images("./MeriBai/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "26",
			activityName: "स्वच्छ पानी",
			activityKey: "SwacchaPaani",
			label: "hard",
			storyBanner: images("./SwacchaPaani/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "24",
			activityName: "एउटा बीउ",
			activityKey: "EutaBiu",
			label: "hard",
			storyBanner: images("./EutaBiu/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "22",
			activityName: "यो के हो ?",
			activityKey: "YoKHo",
			label: "hard",
			storyBanner: images("./YoKHo/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "20",
			activityName: "जुन",
			activityKey: "joon",
			label: "hard",
			storyBanner: images("./Joon/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "1000",
			activityName: "रंगीन टाटेपाटे",
			activityKey: "tateypatey",
			label: "hard",
			storyBanner: images("./Tateypatey/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		// {
		// 	activityId: "1001",
		// 	activityName: "म बनाउँछु ।",
		// 	activityKey: "banauchu",
		// 	label: "hard",
		// 	storyBanner: images("./Banauchu/images/thumbnail.png"),
		// 	storyBannerBtnColor: "pink",
		// 	disabled: false,
		// 	page: 1,
		// },
		{
			activityId: "19",
			activityName: "आमा चामाई",
			activityKey: "ama-chamai",
			label: "hard",
			storyBanner: images("./AmaChamai/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "13",
			activityName: "रमाइलो मेला",
			activityKey: "ramailo-mela",
			label: "easy",
			storyBanner: images("./RamailoMela/images/thumnail.png"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "11",
			activityName: "ब्याग भरिको रहर",
			activityKey: "bag-bhari-ko-rahar",
			label: "medium",
			storyBanner: images("./BagBhariKoRahar/images/thumbnail.png"),
			storyBannerBtnColor: "lightgreen",
			disabled: false,
			page: 1,
		},
		{
			activityId: "18",
			activityName: "पर्फेक्ट सर्कल",
			activityKey: "perfect-circle",
			label: "easy",
			storyBanner: images("./PerfectCircle/images/thumnail_perfect_circle.png"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "14",
			activityName: "लड्‍डु र पेडा",
			activityKey: "laddu-ra-peda",
			label: "easy",
			storyBanner: images("./LadduRaPeda/images/thumnail.png"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "1",
			activityName: "बाँदरको घर",
			activityKey: "badarko-ghar",
			label: "easy",
			storyBanner: images("./BadarkoGhar/images/story-banner.svg"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "19",
			activityName: "तिमी के खान्छौ?",
			activityKey: "timi-k-khanchau",
			label: "easy",
			storyBanner: images("./TimiKKhanchau/images/thumbnail.png"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "3",
			activityName: "रुखको रुप",
			activityKey: "rukh-ko-rup",
			label: "hard",
			storyBanner: images("./RukhKoRup/images/story-banner.svg"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "10",
			activityName: "म किन स्कूल जाने र?",
			activityKey: "ma-kina-school-jane-ra",
			label: "easy",
			storyBanner: images("./MaKinaSchoolJaneRa/images/thumbnail.png"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "17",
			activityName: "ल भन त, म हो हुँ?",
			activityKey: "bhana-ma-ko-hun",
			label: "easy",
			storyBanner: images("./BhanaMaKoHun/images/thumbnail_LaBhanaMaKoHun.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "2",
			activityName: "बुनुको चस्मा खै?",
			activityKey: "bunuko-chasma",
			label: "easy",
			storyBanner: images("./BunukoChasma/images/story-banner.svg"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "5",
			activityName: "रायोको साग",
			activityKey: "rayo-ko-saag",
			label: "easy",
			storyBanner: images("./RayoKoSaag/images/story-banner.svg"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "4",
			activityName: "म को हुँ?",
			activityKey: "ma-ko-hun",
			label: "easy",
			storyBanner: images("./MaKoHun/images/story-banner.png"),
			storyBannerBtnColor: "lightblue",
			disabled: false,
			page: 1,
		},
		{
			activityId: "12",
			activityName: "काग र रोटी",
			activityKey: "kaag-ra-roti",
			label: "medium",
			storyBanner: images("./KaagRaRoti/images/thumnails.png"),
			storyBannerBtnColor: "lightgreen",
			disabled: false,
			page: 1,
		},
		{
			activityId: "9",
			activityName: "असल साथी",
			activityKey: "asal-sathi",
			label: "hard",
			storyBanner: images("./AsalSathi/images/thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "7",
			activityName: "घोडा मेरो छँदै छ",
			activityKey: "ghoda-mero-chadai-cha",
			label: "hard",
			storyBanner: images("./GhodaMeroChadaiCha/images/story-banner.svg"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},
		{
			activityId: "8",
			activityName: "अचम्मका साथी",
			activityKey: "achamako-sathi",
			label: "hard",
			storyBanner: images("./AchamakoSathi/images/story_thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 1,
		},

		// page 2
		{
			activityId: "6",
			activityName: "शशीको घुम",
			activityKey: "sashi-ko-ghum",
			label: "hard",
			storyBanner: images("./SashiKoGhum/images/story-banner.svg"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 2,
		},
		{
			activityId: "15",
			activityName: "आमाको माया",
			activityKey: "aama-ko-maya",
			label: "easy",
			storyBanner: images("./AamaKoMaya/images/thumbnail.png"),
			storyBannerBtnColor: "lightgreen",
			disabled: false,
			page: 2,
		},
		{
			activityId: "15",
			activityName: "भिर गौँथली",
			activityKey: "bhir-gauthali",
			label: "hard",
			storyBanner: images("./BhirGauthali/images/story-thumbnail.png"),
			storyBannerBtnColor: "pink",
			disabled: false,
			page: 2,
		},
		{
			activityId: "17",
			activityName: "कविको मयूर",
			activityKey: "kabi-ko-mayur",
			label: "medium",
			storyBanner: images("./KabiKoMayur/images/thumbnail.png"),
			storyBannerBtnColor: "lightgreen",
			disabled: false,
			page: 2,
		},
	],
}
export default activityList
