import { appear, disappear } from "styledElements/keyframes"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./yello_band.png"),
			class: "image-one yello_band",
			imgdiv: "imageCover",
		},
		{
			src: images("./zera_putting_circle.gif"),
			class: "image-one zera_putting_circle",
			imgdiv: "imageCover",
		},
		{
			src: images("./zera_putting_circle.png"),
			class: "image-one zera_putting_circlepng",
			imgdiv: "imageCover",
		},
		{
			src: images("./circle.gif"),
			styles: {
				top: '23%',
				left: '45%',
				width: '20%',
				transform: "scale(1.3)",
				animation: `${disappear} 0.01s 7.8s ease-out normal both`,
			},
		},
		{
			src: images("./circle.png"),
			styles: {
				top: '23%',
				left: '45%',
				width: '20%',
				transform: "scale(1.3)",
				animation: `${appear} 0.01s 7.8s ease-out normal both`,
			},
		},
		{
			src: images("./grand_mum_talking_perfect.gif"),
			class: "image-one grand_mum_talking_perfect",
			imgdiv: "imageCover",
		},
		{
			src: images("./grand_mum_talking_perfect.png"),
			class: "image-one grand_mum_talking_perfectpng",
			imgdiv: "imageCover",
		},
		{
			src: images("./perfect.svg"),
			styles: {
				width: "20%",
				left: "45%",
				top: "15%",
				animation: `${appear} 0s 13.5s ease-out normal both`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center",

			styles: {
				fontSize: "4rem",
				top: "22%",
				left: "46%",
				transform: "rotate(-20deg)",
				color: "#53ccc6",
				animation: `${appear} 0.5s 13.5s ease-out normal both`,
			},

			textIndivData: [
				{
					textData: "पर्फेक्ट!",
					textClass: "m-0",
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./bg/se_page2to4.ogg"),
			volume: 0.4,
			delay: 200,
			isBg: true,
			loop: true,
		},
		{
			url: audios("./bg/insert.ogg"),
			delay: 1500,
			volume: 0.3,
		},
		{
			url: audios("./circle.ogg"),
			delay: 9000,
		},
		{
			url: audios("./perfect.ogg"),
			delay: 13500,
		},
	],
}

export default content
