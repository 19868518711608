const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page09.jpg"),
			class: "img-ten",
			imgdiv: "imageCover",
		},
		{
			src: images("./raining.gif"),
			class: "img-ten",
			imgdiv: "imageCover",
		},
		{
			src: images("./page09_front.gif"),
			class: "img-ten",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideleftbottom  text-center",
			textIndivData: [
				{
					textData: "पानी परिरहेको बेला शशीलाई घुम ओढ्न रहर लाग्यो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [
						{ start: 0.9 },
						{ start: 1.3 },
						{ start: 2.1 },
						{ start: 2.8 },
						{ start: 3.7 },
						{ start: 4.1 },
						{ start: 4.9 },
						{ start: 5.3 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s9.ogg"),
			delay: 2000,
		},
	],
}

export default content
