const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./bg_hen01.png"),
			class: "image-five",
			imgdiv: "imageCover greenbg",
		},
		{
			src: images("./cock-wearing-chasma.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center blacktext",
			textIndivData: [
				{
					textData: "कुखुराले घाममा",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.5 }],
				},
				{
					textData: "चस्मा लगायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2 }, { start: 3 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./18.ogg"),
			delay: 500,
		},
		{
			url: audios("./a18.ogg"),
			delay: 150,
			isBg: true,
			loop: true,
			volume: 0.5,
		},
	],
}

export default content
