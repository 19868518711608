const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./page07.jpg"),
			class: "image-five page10",
			imgdiv: "imageCover",
		},
		{
			src: images("./peda_back_view.gif"),
			class: "image-five pedasun1",
			imgdiv: "imageCover",
		},
		{
			src: images("./laddu_front_of_sun.gif"),
			class: "image-five laddusun1",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page10Text text-center",
			textIndivData: [
				{
					textData: "दुबैले बल्‍ल जेरीको कुरा बुझेछन्।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1.75 }, { start: 2.45 }, { start: 3.2 }, { start: 3.6 }],
				},
			],
		},
		{
			textDivClass: "story-text dialogueBubble db_textbox7 text_box-p10",
			textIndivData: [
				{
					textData: "हामी त घाम र जुन जस्ता पो रहेछौँ त!",
					textClass: "m-0",
					timestamps: [
						{ start: 4.1 },
						{ start: 4.5 },
						{ start: 5.1 },
						{ start: 5.6 },
						{ start: 6.2 },
						{ start: 6.6 },
						{ start: 7 },
						{ start: 7.4 },
						{ start: 7.8 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./10.ogg"),
			delay: 500,
		},
		{
			url: audios("./10a.ogg"),
			delay: 5200,
		},
	],
}

export default content
