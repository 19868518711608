const images = require.context("./images", true)
const movies = require.context("./movies", true)

let content = {
	name: "मीज़ान",
	key: "meezan",
	coverImage: images("./coverimage.png"),
	movie: movies("./Meezan Final.mp4"),
	movies: [
		movies("./Meezan Scene 1.m4v"),
		movies("./Meezan Scene 2.m4v"),
		movies("./Meezan Scene 3.webm"),
		movies("./Meezan Scene 4.m4v"),
		movies("./Meezan Scene 5.m4v"),
		movies("./Meezan Scene 6.m4v"),
		movies("./Meezan Scene 7.m4v"),
		movies("./Meezan Scene 8.m4v"),
		movies("./Meezan Scene 9.m4v"),
		movies("./Meezan Scene 10.m4v"),
		movies("./Meezan Scene 11.m4v"),
		movies("./Meezan Credit.m4v"),
	],
	timestamps: [],
	subtitles: [],
	content: "",
}

content.timestamps = content.timestamps.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})
content.subtitles = content.subtitles.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})
//
export default content
