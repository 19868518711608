import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",

	imageContainerStyles: {
		transformOrigin: "2% 62%",
	},
	images: [
		{
			src: images("./kids/bg_page8.png"),
			class: "bg_p8",
		},
		{
			src: images("./flag_long.gif"),
			styles: {
				width: "100%",
				top: "0%",
				left: "0%",
			},
		},
		{
			src: images("./boy_with_dog.gif"),
			class: "image-10 boywithdog15",
			imgdiv: "imageCover",
		},
		{
			src: images("./kids/page-8-girl01.svg"),
			toSvg: true,
			styles: {
				top: "41%",
				left: "34%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl1",
		},
		{
			src: images("./kids/page-8-boy.svg"),
			toSvg: true,
			styles: {
				top: "39.2%",
				left: "52%",
				width: "22%",
			},
			imgdiv: "imageCover page8boy",
		},

		{
			src: images("./kids/page-8-girl02.svg"),
			toSvg: true,
			styles: {
				top: "42%",
				left: "72.5%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl2",
		},

		{
			src: images("./three_kids_filled.png"),
			styles: {
				width: "20%",
				left: "2.8%",
				top: "48.4%",
				padding: "1%",
			},
			imgdiv: "imageCover three-kids bg-sky d-none",
		},
		{
			src: images("./Grass.gif"),
			styles: {
				top: "52%",
				left: "13%",
				width: "4.6%",
			},
			class: "image-10 ",
			imgdiv: "imageCover grass-p19-gif",
		},
		{
			src: images("./boy1.png"),
			styles: {
				top: "60%",
				left: "13%",
				width: "4.6%",
			},
			imgdiv: "imageCover boy-p-19-still",
		},


		{
			src: images("./boy_river.png"),
			class: "image-10 boy_river",
			imgdiv: "imageCover visible boy_river_div",
			styles:{
				width: '23%',
				top: '48%',
				left: '1%',
				bottom: "auto",
				right: "auto",
				opacity: 0,
			}
		},
		{
			src: images("./wave01.png"),
			class: "image-10 back-wave-p19 wave-p19",
			imgdiv: "imageCover",
		},
		{
			src: images("./wave02.png"),
			class: "image-10 mid-wave-p19 wave-p19",
			imgdiv: "imageCover",
		},
		{
			src: images("./wave03.png"),
			class: "image-10 front-wave-p19 wave-p19",
			imgdiv: "imageCover",
		},

		{
			src: images("./plane.svg"),
			class: "image-10 plane-p21",
			imgdiv: "imageCover ",
		},
		{
			src: images("./clouds01.png"),
			class: "image-10  ",
			imgdiv: "imageCover blue_clouds-p21 d-none",
		},

		{
			src: images("./clouds-tp.png"),
			styles: {
				transform: "scale(0)",
				transformOrigin: "9.5% 54.5%",
			},
			imgdiv: "imageCover thought-bubble d-none bg-sky",
		},
		{
			src: images("./clouds-tp-filled.png"),
			styles: {
				transform: "scale(0)",
				transformOrigin: "9.5% 54.5%",
			},
			imgdiv: "imageCover thought-bubble-filled",
		},
	],
	sounds: [
		{
			url: audios("./se_page11.ogg"),
			delay: 10,
		},
	],
}

export default content
