import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "घोडा मेरो छँदै छ",
	key: "ghoda-mero-chadai-cha",
	label: "easy",
	coverImage: images("./cover_page.png"),
	credits: {
		illustrator: "अनुश्री लामा",
		template: "default",
		bg: images("./cover_page.png"),
		titleAudio: audios("./title.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
