import { keyframes } from "@emotion/core"
import { appear, disappear } from "styledElements/keyframes"
import { seqAnimation } from "styledElements/helpers"
const images = require.context("../images", true)
const audios = require.context("../audios", true)

const close_eye = keyframes`
	0%, 89% {
		opacity: 0;
	}
	90%, 100% {
		opacity: 1;
	}
`
const open_eye = keyframes`
	0%, 89% {
		opacity: 1;
	}
	90%, 100% {
		opacity: 0;
	}
`
const content = {
	imageContainerClass: "bg-extended pos-1",
	imageContainerStyles: {
		animation: `${keyframes({
			from: {
				transform: "scale(1.5)",
			},
			to: {
				transform: "scale(1)",
			},
		})} 3s ease-out 2s both`,
	},
	images: [
		{
			src: images("./bg_page50.png"),
			class: "image-one bg_page50",
			imgdiv: "imageCover",
			styles: {
				animation: `${appear} 1s linear 8s both`,
			},
		},
		
		{
			src: images("./grandmomAnda.svg"),
			toSvg: true,

			styles: {
				top: '34%',
				left: '31%',
				width: '29%',
				zIndex: 2,
				opacity: 0,
				animation: `${appear} 350ms forwards 21s linear`,
				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 4.3s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 4.3s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lips",
						numberOfFrames: 5,
						totalDuration: 1,
						initialDelay: 23,
						repeat: 1,
						delays: [0.01, 0.2, 0.4, 0.6, 0.9],
					}),
				},
			},
		},
		{
			src: images("./muna_aunt.svg"),
			toSvg: true,
			styles: {    
				top: '42%',
				left: '67%',
				width: '27%',
				opacity: 0,
				animation: `${appear} 350ms forwards 15s linear`,
				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 3.5s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 3.5s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lip",
						numberOfFrames: 8,
						totalDuration: 2.5,
						initialDelay: 19,
						repeat: 1,
						delays: [0.1, 0.4, 0.8, 1.3, 1.8, 2.0, 2.3, 2.45],
					}),
				},
			},
		},
		{
			src: images("./zeya_nodding_head2.gif"),
			class: "image-one",
			imgdiv: "imageCover",
			styles: {
				opacity: 0,
				position: "absolute",    
				top: '55.5%',
				left: '54%',
				width: '19%',
				zIndex: 1,
				animation: `${appear} 450ms ease-in 24s forwards`,
			},
		},
		// scene 3 // k kane
		{
			src: images("./zeya_drawing.gif"),
			class: "image-one zeya_drawing-p9",
			styles: {
				opacity: 0,
				zIndex: 1,
				top: '55%',
				left: '53%',
				width: '20%',
				animation: `${appear} linear 350ms 11s forwards, ${disappear} 350ms linear 19.5s forwards `,
			},
			imgdiv: "imageCover",
		},
		{
			src: images("./zeya_drawing_02.png"),
			class: "image-one",
			styles: {
				opacity: 0,
				top: '57%',
				left: '54.2%',
				width: '18.8%',
				animation: `${appear} linear 350ms 19.5s forwards, ${disappear} 350ms linear 21s forwards `,
			},
			imgdiv: "imageCover",
		},
		{
			src: images("./zeya_drawing (1).svg"),
			toSvg: true,
			styles: {
				
				top: '58.6%',
				left: '54.4%',
				width: '17.8%',
				opacity:0,
				animation: `${appear} linear 350ms 21s forwards, ${disappear} 450ms ease-in 24s forwards`,
				svg: {
					"#head": {
						transform: "rotate(2deg)",
						transformBox: "fill-box",
						transformOrigin: "37% 79%",
						animation: `${keyframes({
							from: {
								transform: "rotate(2deg)",
							},
							to: {
								transform: "rotate(0deg)",
							},
						})} ease-in-out 3s 21s infinite alternate`,
					},
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 3.8s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 3.8s ease-out normal both infinite`,
					},
				},
			},
		},
		
		// //scene 2
		// ends at 16s
		{
			src: images("./muna_kaki_walk.gif"),
			class: "image-one muna_kaki_walk",
			imgdiv: "imageCover",
			styles: {

				position: 'absolute',
				width: '10%',
				left: '68%',
				transform: 'translate(0, -24%)',
				animation: `${keyframes({
					from: { 
						transform: 'translate(0, -24%)',
					},
					to: {
						transform: 'translate(0, 0)',
					}
				})} 8s linear 6s forwards, ${disappear} 150ms linear 14s forwards`
			}
		},
		{
			src: images("./muna_kaki_walk.png"),
			class: "image-one muna_kaki_walk_png",
			imgdiv: "imageCover",
			styles: {
				position: 'absolute',
				width: '10%',
				left: '68%',
				opacity: 0,
				animation: `${appear} 2ms linear 14s forwards, ${disappear} 1s linear 14s forwards`,
			}
		},

		{
			src: images("./zeya_drawing.gif"),
			class: "image-one",
			imgdiv: "imageCover",
			styles: {
				width: "20%",
				top: "55%",
				left: "53%",
				animation: `${appear} 1s linear 8s both,${disappear} 2s linear 14s forwards`,
			},
		},
		// {
		// 	src: images("./grand_mum_friend.svg"),
		// 	toSvg: true,

		// 	styles: {
		// 		top: "16%",
		// 		left: "-2%",
		// 		width: "31%",

		// 		svg: {
		// 			"#open_eyes": {
		// 				opacity: 1,
		// 				animation: `${open_eye} 3.8s ease-out normal both infinite`,
		// 			},
		// 			"#closed_eyes": {
		// 				opacity: 0,
		// 				animation: `${close_eye} 3.8s ease-out normal both infinite`,
		// 			},
		// 			...seqAnimation({
		// 				idName: "lips",
		// 				numberOfFrames: 11,
		// 				totalDuration: 2.2,
		// 				initialDelay: 52,
		// 				repeat: 1,
		// 				delays: [0.01, 0.2, 0.5, 0.8, 0.9, 1.1, 1.2, 1.5, 1.8, 2.0, 2.1],
		// 			}),
		// 		},
		// 	},
		// },
		{
			src: images("./grand_mum_friend.svg"),
			toSvg: true,

			styles: {
				top: "29%",
				left: "10%",
				width: "24%",
				animation: `${appear} 1s linear 3s both`,

				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 2s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 2s ease-out normal both infinite`,
					},
					...seqAnimation({
						idName: "lips",
						numberOfFrames: 11,
						totalDuration: 2.2,
						initialDelay: 6,
						repeat: 3,
						delays: [0.01, 0.2, 0.5, 0.8, 0.9, 1.1, 1.2, 1.5, 1.8, 2.0, 2.1],
					}),
				},
			},
		},

		
		
		{
			src: images("./grandmomlookAtFriend.svg"),
			toSvg: true,

			styles: {
				top: '30%',
				left: '31%',
				width: '25%',
				animation: `${appear} 1s linear 8s both, ${disappear} 350ms linear 21s forwards`,
				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 3s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 3s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lips",
						numberOfFrames: 8,
						totalDuration: 1.6,
						initialDelay: 8.5,
						repeat: 3,
						delays: [0.01, 0.2, 0.5, 0.8, 0.9, 1.1, 1.2, 1.5],
					}),
				},
			},
		},
		{
			src: images("./backLady.svg"),
			toSvg: true,

			styles: {
				top: "29%",
				left: "-19%",
				width: "58%",
				animation: `${appear} 1s linear 8s both`,
			},
		},
		//scene 1 
		// completes at 10s.
		{
			src: images("./bg_page45.png"),
			class: "image-one bg_page45",
			imgdiv: "imageCover",
			styles: {
				WebkitTransformStyle: "preserve-3d",
				WebkitBackfaceVisibility: "hidden",
				animation: `${disappear} 2s linear 8s forwards`,
			},
		},
		{
			src: images("./hand.png"),
			class: "image-one",
			imgdiv: "imageCover",
			styles: {
				width: "38%",
				top: "40%",
				left: "63%",
				transformOrigin: "100% 50%",
				WebkitTransformStyle: "preserve-3d",
				WebkitBackfaceVisibility: "hidden",
				animation: `${keyframes({
					from: {
						transform: "rotate(-5deg)",
					},
					to: { 
						transform: "rotate(5deg)",
					},
				})} 1s linear infinite alternate, ${disappear} 1s linear 8s forwards`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story-text page5Text text-center dialogueBubble textbox2",
			styles: {
				padding: '2% 1% 2% 3% !important',
				top: '23%',
				left: '79%',
				width: '16%',
				opacity: 0,
				animation: `${appear} 1s linear 19s forwards`
			},
			textIndivData: [
				{
					textData: "ज्हेया, तिमी के खाजा खाने?",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1 },
						{ start: 1.35 },
						{ start: 1.55 },
						{ start: 1.95 },
						{ start: 2.35 },
						{ start: 2.65 },
						{ start: 2.95 },
					],
				},
			],
		},
		{
			textDivClass: "story-text page5Text text-center dialogueBubble textbox1",
			styles: {
				top: '23%',
				left: '27%',
				width: '13%',
				opacity: 0,
				padding: "2% 6% 2% 2% !important",
				animation: `${appear} 1s 23s linear forwards`
			},
			textIndivData: [
				{
					textData: "अन्डा खान्छौ?",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.4 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./12.ogg"),
			delay: 19000,
		},
		{
			url: audios("./bg/drawing.ogg"),
			volume: 0.03,
			delay: 1,
		},
		{
			url: audios("./bg/se_page7.ogg"),
			volume: 0.4,
			delay: 1,
		},
		{
			url: audios("./bg/se_page7.ogg"),
			volume: 0.2,
			delay: 17500,
		},
		{
			url: audios("./13.ogg"),
			delay: 23000,
		},
		
	],
}

export default content
