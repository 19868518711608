const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./sparrow-eating-momo.gif"),
			class: "image-13",
			imgdiv: "imageCover yellowbg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center blacktext",
			textIndivData: [
				{
					textData: "अचारमा चोपेर",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.1 }],
				},
				{
					textData: "मःमः खायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2.2 }, { start: 2.7 }, { start: 3.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./14.ogg"),
			delay: 500,
		},
	],
}

export default content
