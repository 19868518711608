const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 bg-white",
	images: [
		{
			src: images("./bagItems.svg"),
			class: "image-one bagItems",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./bag.svg"),
			class: "image-one bag-p1",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center title text-p1",
			textIndivData: [
				{
					textData: "ब्याग भरिको रहर",
					textClass: "fz-6 m-0 one-line text-p1a",
					timestamps: [{ start: 0 }, { start: 1.05 }, { start: 1.85 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s1.ogg"),
			delay: 2500,
		},
	],
}

export default content
