const playList = {
	play: [
		"01Jungle.m4a",
		"Bhaisi song (1).m4a",
		"City music horse.m4a",
		"Horse song.m4a",
		"Jungle (1).m4a",
		"My Song 20.m4a",
		"My Song 43.m4a",
		"01Jungle.m4a",
		"Bhaisi song (1).m4a",
		"City music horse.m4a",
		"Horse song.m4a",
		"Jungle (1).m4a",
		"My Song 20.m4a",
		"01Jungle.m4a",	
	],
}
export default playList
