import React, { Component } from "react"
import { Link, NavLink } from "react-router-dom"
import shuffle from "shuffle-array"
import PlayConfig from "../Config/PlayConfig"
import ImgPlayEasy from "assets/top_images/kids_playing_blue.png"
import ImgPlayMedium from "assets/top_images/kids_playing_green.png"
import ImgPlayHard from "assets/top_images/kids_playing_yellow.png"
import Logo from "assets/ole_logo.png"
import IconHome from "assets/icons/home.svg"
// import ImgPlayHeader from "assets/play.svg";
import Footer from "Components/Layout/Footer"
// import ReactGA from 'react-ga';

const levelSettings = {
	easy: {
		difficulty: "easy",
		levelColor: "lightblue",
		levelImage: ImgPlayEasy,
	},
	medium: {
		difficulty: "medium",
		levelColor: "lightgreen",
		levelImage: ImgPlayMedium,
	},
	hard: { difficulty: "hard", levelColor: "pink", levelImage: ImgPlayHard },
}
class PlayMenu extends Component {
	state = {
		playConfig: shuffle(PlayConfig.play),
		headerImage: localStorage.getItem("egrSettings") ? JSON.parse(localStorage.getItem("egrSettings")).levelImage : ImgPlayEasy,
		difficulty: localStorage.getItem("egrSettings") ? JSON.parse(localStorage.getItem("egrSettings")).difficulty : "easy",
		levelColor: localStorage.getItem("egrSettings") ? JSON.parse(localStorage.getItem("egrSettings")).levelColor : "lightblue",
	}

	constructor() {
		super()

		if (!localStorage.getItem("egrSettings")) {
			localStorage.setItem("egrSettings", JSON.stringify({ ...levelSettings.easy }))
		}
	}

	componentDidMount() {
		document.title = "HRK :: games"
		// ReactGA.initialize('UA-172822248-1');
		// ReactGA.pageview(window.location.pathname + window.location.search);
	}

	handleDifficultyChange = (difficulty) => {
		let headerImage, levelColor
		switch (difficulty) {
			case "easy":
				levelColor = "lightblue"
				headerImage = ImgPlayEasy
				localStorage.setItem("egrSettings", JSON.stringify({ ...levelSettings.easy }))
				break

			case "medium":
				levelColor = "lightgreen"
				headerImage = ImgPlayMedium
				localStorage.setItem("egrSettings", JSON.stringify({ ...levelSettings.medium }))
				break

			case "hard":
				levelColor = "pink"
				headerImage = ImgPlayHard
				localStorage.setItem("egrSettings", JSON.stringify({ ...levelSettings.hard }))
				break

			default:
				levelColor = "lightblue"
				headerImage = ImgPlayEasy
				break
		}
		this.setState({
			// playConfig: PlayConfig.play,
			levelColor: levelColor,
			headerImage: headerImage,
			difficulty: difficulty,
		})
	}

	render() {
		const { playConfig } = this.state
		return (
			<div className="activity__page">
				<header className="inner__header">
					<div className="container">
						<div className="header-content">
							<div className="logo">
								<NavLink exact to="/">
									<img src={Logo} className="img-fluid" alt="OLE Nepal" />
								</NavLink>
							</div>
							{/* <strong className="page__heading">खेलौ</strong> */}
							<NavLink exact to="/">
								<img src={IconHome} className="img-fluid" alt="Home" />
							</NavLink>
						</div>
					</div>
				</header>
				<main className="main__content">
					<div className="container">
						<div className="activity-header">
							<div className="img-holder">
								<img src={this.state.headerImage} className="img-fluid" alt="read" />
								{/* <img src={ImgPlayHeader} className='img-fluid' alt='read' /> <span className='activity-text'>खेलौ</span>*/}
							</div>
						</div>
						<ul className="filter__list">
							<li>
								<button
									className={`${this.state.difficulty === "easy" ? "active" : ""} btn btn-round lightblue`}
									onClick={() => this.handleDifficultyChange("easy")}
								>
									तह १
								</button>
							</li>
							<li>
								<button
									className={`${this.state.difficulty === "medium" ? "active" : ""} btn btn-round lightgreen`}
									onClick={() => this.handleDifficultyChange("medium")}
								>
									तह २
								</button>
							</li>
							<li>
								<button
									className={`${this.state.difficulty === "hard" ? "active" : ""} btn btn-round pink`}
									onClick={() => this.handleDifficultyChange("hard")}
								>
									तह ३
								</button>
							</li>
						</ul>
						<div className="read__list">
							{playConfig.length > 0 && (
								<div className="row">
									{playConfig.map((playContent, index) => {
										if (playContent.disabled) {
											return null
										} else if (Object.keys(playContent.readyLevels).includes(this.state.difficulty)) {
											return (
												<div key={playContent.playKey} className="col-md-4">
													<div className="story__menu__item">
														<Link to={`/play/${playContent.playKey}?level=${this.state.difficulty}`}>
															<div className="img__square">
																<div className="img__wrap">
																	<img
																		className="img-fluid"
																		src={playContent.readyLevels[this.state.difficulty]}
																		alt={playContent.playName}
																	/>
																</div>
															</div>
														</Link>
														<Link
															className={`btn btn-round btn-block ${this.state.levelColor}`}
															to={`/play/${playContent.playKey}?level=${this.state.difficulty}`}
														>
															{playContent.playName}
														</Link>
													</div>
												</div>
											)
										} else {
											return null
										}
									})}
								</div>
							)}
						</div>
					</div>
				</main>
				<Footer />
			</div>
		)
	}
}

export default PlayMenu
