const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./sparrow-chirping.gif"),
			class: "image-13",
			imgdiv: "imageCover yellowbg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center blacktext",
			textIndivData: [
				{
					textData: "मङ्गलबार आयो,",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1 }],
				},
				{
					textData: "भँगेरा करायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2 }, { start: 2.65 }, { start: 3.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./12.ogg"),
			delay: 500,
		},
		{
			url: audios("./a12.ogg"),
			delay: 150,
			isBg: true,
		},
	],
}

export default content
