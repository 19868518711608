const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_page_06.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./egg_taking_home.png"),
			class: "image-one egg_taking_home",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page10Text text-center",
			textIndivData: [
				{
					textData: "“नाइँ म त खान्‍न।” कविले भने।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.65 }, { start: 0.85 }, { start: 1.0 }, { start: 2.0 }, { start: 2.55 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./10.ogg"),
			delay: 500,
		},
	],
}

export default content
