const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./page05.jpg"),
			class: "image-five page8",
			imgdiv: "imageCover",
		},
		{
			src: images("./peda_back_view.gif"),
			class: "image-five pedamoon",
			imgdiv: "imageCover",
		},
		{
			src: images("./laddu_front_of_sun.gif"),
			class: "image-five laddumoon",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page8Text text-left",
			textIndivData: [
				{
					textData: "भोलिपल्ट अँध्यारोमै उनीहरू घरबाट निस्केछन्।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1.9 }, { start: 3.2 }, { start: 4.2 }, { start: 5.2 }],
				},
				{
					textData: "लड्‍डुले अस्ताउन लागेको जुन देखेछ।",
					textClass: "m-0",
					timestamps: [{ start: 7.3 }, { start: 8.3 }, { start: 9 }, { start: 9.7 }, { start: 10.5 }],
				},
			],
		},
		{
			textDivClass: "story-text dialogueBubble db_textbox5 text_box-p8",
			textIndivData: [
				{
					textData: "तिमी त त्यो जुन जस्तै सेतो पो रहेछौ त पेडा!",
					textClass: "m-0",
					timestamps: [
						{ start: 12.1 },
						{ start: 12.5 },
						{ start: 12.7 },
						{ start: 13.0 },
						{ start: 13.3 },
						{ start: 13.7 },
						{ start: 14.1 },
						{ start: 14.3 },
						{ start: 14.7 },
						{ start: 15 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./8.ogg"),
			delay: 500,
		},
		{
			url: audios("./8b.ogg"),
			delay: 13000,
		},
	],
}

export default content
