import { fileCheck } from "helpers/files"
import { keyframes } from "@emotion/core"

const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "page11scene2",
	imageContainerStyles: {
		transform: 'scale(2) translate(25%,3%)',
		transformOrigin: "center",
		animation: `${keyframes({
			from:{
				transform: 'scale(2) translate(25%,3%)',
			},
			to: {
				transform: 'scale(2) translate(-25%,3%)',
			}
		})} 30s ease-in-out 5s forwards,
		${keyframes({
			from:{
				transform: 'scale(2) translate(-25%,3%)',
			},
			to: {
				transform: 'scale(1) translate(0%,0%)',
			}
		})} 8s ease-in-out 37s forwards
		`
		
	},
	images: [
		{
			src: images("./page10.jpg"),
			imgdiv: "imageCover bg-p11",
		},
		{
			src: images("./bg_9_and_10_sky.jpg"),
			imgdiv: "imageCover main-p11",
		},
		{
			src: images("./bg_9_and_10_front.png"),
			imgdiv: "imageCover main-p11",
		},
		{
			src: images("./rotaping.png"),
			imgdiv: "rotiping",
		},
		{
			src: images("./flag.gif"),
			imgdiv: "imageCover main-p11 flag",
		},
		{
			src: images("./nima-with-mum.png"),
			imgdiv: "imageCover main-p11 nima-png-p11",
		},
		{
			src: images("./balloon_green.png"),
			imgdiv: "main-p11 green-balloon-p11",
		},
		{
			src: images("./balloon01.png"),
			imgdiv: "main-p11 balloons-p11",
		},
		{
			src: images("./sky.png"),
			imgdiv: "imageCover vo-bg-p11",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center vo-textdiv-p11 ",
			textIndivData: [
				{
					textData: "केही दिन पछि...",
					textClass: "m-0 vo-text-p11",
					timestamps: [{ start: 0 }, { start: 0.4 }, { start: 0.75 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./kehi_din.ogg"),
			delay: 1000,
		},
		{
			url: audios("./page12a.ogg"),
			delay: 5000,
			volume: 0.8,
		},
		{
			url: audios("./blank.ogg"),
			delay: 50000,
			isBg: false,
			volume: 0,
		},
		{
			url: audios("./mela.ogg"),
			delay: 5000,
			isBg: true,
			volume: 0.2,
			loop: false,
		},
		{
			url: audios("./crowd.ogg"),
			delay: 9000,
			isBg: true,
			volume: 0.2,
			loop: true,
		},
		{
			url: audios("./worshipbell2.ogg"),
			delay: 21000,
			isBg: true,
			volume: 0.1,
			loop: false,
		},
		{
			url: audios("./panchebaja1.ogg"),
			delay: 23000,
			isBg: true,
			volume: 0.2,
			loop: true,
		},
	],
}

export default content
