const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./6to10/pond-big-bg.svg"),
			imgdiv: "imageCover",
			styles: { left: "35%", top: "-45%", transform: "scale(2)" },
		},
		{
			src: images("./6to10/tortoise01.svg"),
			imgdiv: "imageCover",
			styles: { left: "15%", top: "8%", transform: "scale(0.18)" },
		},
		{
			src: images("./6to10/tortoise04.svg"),
			imgdiv: "imageCover",
			styles: { left: "15%", top: "8%", transform: "scale(0.18)" },
		},
		{
			src: images("./6to10/duck01e.svg"),
			imgdiv: "imageCover",
			styles: { left: "-22%", top: "5%", transform: "scale(0.3)" },
		},
		{
			src: images("./6to10/duck02d.svg"),
			imgdiv: "imageCover",
			styles: { left: "30%", top: "5%", transform: "scale(0.3)" },
		},
	],
	texts: [
		{
			textDivClass: "story-text w-75 end-suspense ",
			textIndivData: [
				{
					textData: "उनीहरूले के उपाय निकालेका होलान्?",
					textClass: "fz-5 m-0",
					timestamps: [{ start: 0 }, { start: 1 }, { start: 1.5 }, { start: 1.9 }, { start: 2.5 }, { start: 3 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p2_s4.ogg"),
			delay: 1500,
		},
	],
}

export default content
