const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./goat-eating-saag.gif"),
			class: "image-five",
			imgdiv: "imageCover purbg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center blacktext",
			textIndivData: [
				{
					textData: "बाख्राले खायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.85 }, { start: 1.75 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./16.ogg"),
			delay: 500,
		},
		{
			url: audios("./a16.ogg"),
			delay: 150,
			isBg: true,
			loop: true,
			volume: 0.3,
		},
	],
}

export default content
