import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "काग र रोटी",
	key: "kaag-ra-roti",
	coverImage: images("./cover_page.jpg"),
	credits: {
		writer: "डा. कपिल लामिछाने",
		illustrator: "नरेन्द्र अर्याल",
		template: "default",
		bg: images("./cover_page.jpg"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
