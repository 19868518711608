const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_books.png"),
			class: "image-10",
			imgdiv: "imageCover",
		},
		{
			src: images("./between_the_book.gif"),
			class: "image-10",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "किताबहरूको बीचमा पनि छैन।",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 1.9 }, { start: 2.25 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./8.ogg"),
			delay: 500,
		},
	],
}

export default content
