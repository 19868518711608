import { fileCheck } from "helpers/files"
import { appear } from "styledElements/keyframes"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page6Scene",
	images: [
		{
			src: images("./page4_bg.jpg"),
			imgdiv: "imageCover bg-p6",
		},
		{
			src: images("./cobbler_dai.png"),
			imgdiv: "imageCover dai-p6",
		},
		{
			src: images("./nima_shoe_look.png"),
			imgdiv: "imageCover nima-look-p6",
			styles:{
				width: "24%",
				top:"28%",
				left: '23%',
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center dialogueBubble text-bubble-p9 bubble-text_box9",
			textIndivData: [
				{
					textData: "आहा कस्तो राम्रो जुत्ता!",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.15 },
						{ start: 1.8 },
						{ start: 2.3 },
						{ start: 2.8 },
					],
				},
			],
			styles:{
				top: '18%',
				left: '10%',
				width: '26%',
				padding: '1% 1% 3% 2% !important',
				opacity: 0,
				animation: `${appear} 1s 3s forwards linear`,
			}
		},
	],
	sounds: [
		{
			url: audios("./aaha_kasto_ramro_jutta.ogg"),
			delay: 3000,
		},
		{
			url: audios("./blank.ogg"),
			delay: 9000,
			isBg: false,
			volume: 0,
		},
		{
			url: audios("./busyMarket.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./cobler.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
	],
}

export default content
