const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./background_01.jpg"),
			class: "image-five page7",
			imgdiv: "imageCover",
		},
		{
			src: images("./mum_umbrella.png"),
			class: "image-five mum_umbrella",
			imgdiv: "imageCover",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./thunder_storm.gif"),
			class: "image-one thunderp7",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox7 text_box-p7",
			textIndivData: [
				{
					textData: "“पख! पख!  म नाना खोजी ल्याउँछु।” आमाले भनी।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.4 },
						{ start: 0.9 },
						{ start: 1.3 },
						{ start: 1.8 },
						{ start: 2.3 },
						{ start: 2.7 },
						{ start: 3.3 },
						{ start: 3.7 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7.ogg"),
			delay: 500,
		},
		{
			url: audios("./bg1.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
