import React from "react"

const SvgDefs = (props) => {
	return (
		<React.Fragment>
			<svg width="1" height="1" xmlns="http://www.w3.org/2000/svg">
				<defs>
					<g id="balloon_with_text" className="balloon_with_text">
						<text id="balloon_text" x="0.5" y="0.5" fontSize="24px" textAnchor="middle" fill="black"></text>
						<g id="balloon" className="balloon_main">
							<path
								className="color"
								d="M167.682,386.754c-2.096,0.098-4.156,0.073-6.129-0.08c-4.537-0.362-9.197-1.28-13.879-2.732 c-10.867-3.371-22.273-9.646-34.891-19.174c-7.584-5.737-14.672-11.962-19.996-16.74c-4.727-4.257-9.24-8.634-13.406-13.004 c-2.871-3.039-5.807-6.265-8.691-9.604c-6.689-7.736-13.227-16.12-19.977-25.615c-4.033-5.671-8.088-11.688-12.426-18.392 c-9.117-14.119-16.416-28.529-21.699-42.8c-6.568-17.743-10.309-36.046-11.105-54.406c-0.109-2.565-0.166-5.133-0.17-7.616 c-0.023-27.896,6.512-56.776,18.404-81.326c6.82-14.063,15.279-26.632,25.139-37.354c4.631-5.049,9.658-9.818,14.938-14.15 c7.438-6.11,15.521-11.5,24.041-16.036c12.41-6.587,25.865-11.423,39.988-14.36c6.619-1.386,13.465-2.361,20.33-2.9 c1.504-0.125,3.063-0.223,4.643-0.294l0.859-0.037c16.465-0.683,34.17,1.337,49.832,5.688c6.156,1.704,11.99,3.76,17.33,6.111 c3.422,1.495,6.785,3.136,9.994,4.877c10.873,5.86,21.059,13.284,30.264,22.062c10.52,10.013,19.797,21.782,27.574,34.983 c13.813,23.469,22.52,50.802,25.178,79.027c2.043,21.662,0.445,42.706-4.732,62.551c-5.578,21.415-14.645,42.72-26.941,63.321 c-5.436,9.095-11.516,18.068-18.086,26.685c-2.559,3.364-5.266,6.744-8.016,10.053c-7.293,8.766-15.205,17.222-23.496,25.151 c-6.367,6.087-13.027,11.931-19.795,17.362c-1.965,1.574-3.939,3.115-5.859,4.588c-9.359,7.126-22.854,12.428-35.223,13.837 C170.334,386.587,168.986,386.695,167.682,386.754L167.682,386.754z"
							/>
							<path
								className="color"
								d="M158.4,408.171c1.482,1.086,3.184,1.903,4.98,2.672c2.396,1.012,4.986,1.724,7.658,2.008      c1.787,0.187,3.609,0.184,5.42-0.046c5.994-0.77,1.396-7.489-1.162-9.879c-1.865-1.739-2.375-1.913-2.484-4.692      c-0.115-2.895-0.947-5.756-1.254-8.646c-0.107-1.034-0.225-1.921-0.492-2.624c-0.527-1.393-1.678-2.126-4.602-2.077      c-0.244-0.004-0.475,0.01-0.729,0.018c-3.078,0.128-4.115,0.218-4.727,3.227c-0.479,2.357,0.287,4.771,0.268,7.15      c-0.678,0.044-1.352,0.009-2.008-0.029c-1.574-0.087-3.063-0.167-4.398,0.969C151.01,399.479,155.424,405.976,158.4,408.171z"
							/>
							<ellipse cx="46" cy="212" rx="58.13" ry="93" transform="rotate(-55)" style={{ fill: "#fff", opacity: 0.25 }} />
							<g>
								<path
									fill="#FFFFFF"
									d="M33.613,94.578c17.568-55.74,86.188-75.682,86.188-75.682S56.201,46.246,33.613,94.578z"
								/>
								<path
									fill="#FFFFFF"
									d="M144.713,349.953c-71.646,1.895-85.422-76.507-85.422-76.507S83.994,342.256,144.713,349.953z"
								/>
								<path
									fill="#FFFFFF"
									d="M305.256,187.486c10.75,57.359-40.697,105.997-40.697,105.997S308.051,240.541,305.256,187.486z"
								/>
								<polygon
									fill="#FFFFFF"
									points="206.119,36.214 213.898,10.038 209.57,36.969 236.666,30.452 210.627,40.254 229.947,59.918       208.24,42.801 200.477,68.968 204.803,42.045 177.707,48.56 203.746,38.757 184.428,19.092     "
								/>
								<polygon
									fill="#FFFFFF"
									points="58.648,271.729 63.293,256.099 60.703,272.179 76.879,268.29 61.336,274.144 72.875,285.886       59.912,275.659 55.271,291.292 57.855,275.213 41.678,279.099 57.221,273.246 45.688,261.506     "
								/>
								<polygon
									fill="#FFFFFF"
									points="264.49,287.832 269.135,272.198 266.547,288.275 282.723,284.389 267.18,290.245       278.717,301.988 265.756,291.765 261.115,307.395 263.697,291.315 247.518,295.201 263.063,289.348 251.531,277.605     "
								/>
							</g>
						</g>
						<g id="pop2">
							<path
								className="color"
								d="M10.4,1.06s23.25-3.73,31.65,2.8S52,21,52,21s-3.73-11-9.33-10.5-11.47,4.89-11.47,4.89,5.65-11.52,0-11.52-16.55.63-16.55.63Z"
							/>
							<path className="color" d="M31.21,29.08s13,4.36,15.51,0,6.85,0,6.85,0L45.79,46.51S34.84,44.57,33,36.83Z" />
							<path
								className="color"
								d="M14.86,6.71l-4,15.14,5.3,3.83s-5.85,3.84-1.43,7.81,13,3.27,13,3.27S-.89,43.33,0,30.93,14.86,6.71,14.86,6.71Z"
							/>
						</g>
						<g id="pop">
							<g>
								<path
									className="color"
									d="M213.71,67.415l-0.513,0.005c1.921-0.588,3.718-1.639,5.781-1.681c0.025,0.953,0.06,1.902,0.105,2.85
                           c1.553,0.136,3.11,0.13,4.668,0.095c0.483,1.008,1.086,2.022,2.203,2.435c2.348,0.718,4.797,1.023,7.244,1.098
                           c0.432-0.614,0.916-1.199,1.245-1.879c-0.063-1.702-1.024-3.343-0.579-5.05c0.44-1.667,0.948-3.316,1.434-4.969
                           c0.072-0.192,0.211-0.579,0.283-0.772c0.01-0.076,0.022-0.23,0.033-0.306c0.19-2.002,0.627-4.229-0.58-6.021
                           c-1.404-2.197-3.097-4.667-5.754-5.357c-2.074,0.692-3.423,2.724-5.203,3.948c-2.019,1.246-3.771,3.928-6.437,3.32
                           c0.402,1.197,0.877,2.489,0.449,3.757c-0.76,1.69-2.471,2.628-4.105,3.305c-0.838,0.273-1.676,0.545-2.52,0.799
                           c-0.383,0.22-0.764,0.46-1.121,0.709c-1.078,0.368-2.258,1.079-2.457,2.291c-0.24,0.972,0.242,1.893,0.582,2.775
                           C210.343,68.932,212.138,68.421,213.71,67.415z"
								/>
								<path
									className="color"
									d="M265.142,92.578c-2.68-3.448-7.404-3.688-10.926-5.865c-1.879-1.13-2.98-3.109-3.982-4.988
                           c-0.19-0.124-0.578-0.377-0.774-0.503c-1.185-0.824-2.285-1.756-3.437-2.63c-0.6,1.915-1.158,3.844-1.998,5.669
                           c0.484,1.226,1.078,2.443,1.252,3.769c0.177,1.299-0.629,2.411-1.198,3.504c0.053,0.124,0.157,0.37,0.211,0.492
                           c0.086,0.193,0.26,0.581,0.34,0.771c1.477,0.96,2.713,2.335,4.471,2.791c1.988,1.163,3.244,3.246,3.81,5.439
                           c-0.054,1.775-0.91,3.454-0.728,5.257c0.162,2.118-0.102,4.217-0.701,6.258c2.822-1.336,5.181-3.464,7.687-5.298
                           c0.221,1.142,0.451,2.28,0.748,3.408l0.569-0.491c0.183-0.358,0.377-0.718,0.562-1.083c1.729-4.244,2.434-8.814,3.94-13.135
                           C265.206,94.863,266.05,93.556,265.142,92.578z"
								/>
								<path
									className="color"
									d="M230.183,108.956c-0.26,0.661-0.527,1.326-0.777,1.998c-2.725-0.123-3.741-3.455-3.133-5.726
                           c0.687-1.106,1.816-1.821,2.804-2.63c0.02-0.191,0.059-0.565,0.077-0.752c-1.52-0.435-3.016-1.039-4.6-1.146
                           c-2.113-0.22-3.643,1.822-5.756,1.625c-3.545,0.03-7.23,0.125-10.574-1.226c-4.006-1.556-7.633-4.068-10.527-7.249
                           c-1.475-1.623-2.112-3.818-3.641-5.405c-2.055-0.062-3.838-1.164-4.9-2.898c-3.227-0.087-6.469-0.067-9.649,0.518
                           c-2.119,0.513-4.01,1.684-5.697,3.036c-1.158,0.514-2.317,1.01-3.488,1.5c0.402,0.053,1.199,0.163,1.603,0.22
                           c-4.078,0.94-8.314,0.926-12.443,0.408c0.019-0.445,0.047-0.891,0.08-1.336c0.353-2.296,1.621-4.409,3.408-5.875
                           c1.584-1.253,3.357-2.251,5.045-3.363c-0.164-0.071-0.488-0.205-0.652-0.278l6.642-3.488c1.863-1.144,3.949-1.844,5.897-2.806
                           c0.043-0.164,0.125-0.481,0.168-0.636c0.406-1.473,0.574-3.008,0.422-4.526c-0.221-2.074,0.048-4.229-0.676-6.227
                           c-0.362-0.699-0.776-1.38-1.16-2.065c0.207-1.05,0.313-2.189,1.021-3.046c0.703-1.112,1.981-2.051,1.938-3.486
                           c-0.104-3.094-2.059-6.07-4.891-7.354c-3.365-1.005-6.838-1.596-10.068-3.028c-1.536-1.302-3.362-2.323-4.518-4.029
                           c-1.289-1.614-2.021-3.564-2.607-5.525c-0.021-0.876-0.069-1.749-0.151-2.621c0.096-2.623,0.422-5.223,1.188-7.733
                           c2.582-6.19,7.422-11.221,12.765-15.15c1.83-1.49,4.178-1.946,6.291-2.867c0.44,0.016,0.881,0.012,1.33-0.01
                           c-4.324-2.003-8.738-3.854-13.382-4.995c-4.737-0.949-9.582-0.848-14.383-0.752c-2.119,0.05-4.199,0.455-6.276,0.817
                           c0.358,0.055,1.071,0.165,1.428,0.223c-2.195-0.055-4.351,0.502-6.387,1.283c-2.439,1.082-5.056,1.672-7.705,1.93
                           c-4.867,0.935-9.636,2.684-14.646,2.655c-6.004-0.052-11.969,0.983-17.771,2.459l-0.283,0.21c-1.682,0.493-3.32,1.107-4.967,1.695
                           C93,12.93,89.512,14.76,86.287,17.013L86.201,17.2c-1.045,0.555-2.002,1.244-2.908,2.003c-1.902,1.413-3.689,2.966-5.418,4.585
                           c-0.433,0.382-0.857,0.761-1.291,1.145c-0.334,0.305-0.664,0.611-1,0.919c-0.857,1.05-1.781,2.042-2.842,2.893
                           c-2.031,2.311-4.355,4.38-6.162,6.873l-0.054,0.077l-0.08,0.037c-0.145,0.077-0.426,0.221-0.569,0.294
                           c-1.908,2.462-4.213,4.584-6.219,6.969c-1.863,1.979-3.789,3.905-5.574,5.96c-4.033,4.261-8.26,8.342-12.504,12.396
                           c-0.308,0.331-0.613,0.665-0.927,0.996c-0.313,0.296-0.637,0.593-0.946,0.896c-1.813,1.819-3.508,3.753-5.424,5.459
                           c-0.601,0.712-1.242,1.383-1.898,2.041c-2.084,2.228-4.293,4.34-6.244,6.696c-5.438,6.029-10.549,12.4-14.766,19.349
                           c-0.144,0.181-0.431,0.545-0.57,0.729c-1.102,2.226-2.611,4.271-3.453,6.615c-0.035,1.332-0.131,2.698-0.879,3.852
                           c-0.109-0.336-0.32-0.996-0.432-1.326c-4,8.902-6.385,18.607-6.385,28.401c-0.148,3.69,0.454,7.4,1.793,10.844
                           c2.178,5.72,5.432,11.001,7.143,16.903c1.227,3.815,1.637,7.873,3.234,11.572c0.577,1.302,1.221,2.577,1.764,3.904
                           c0.996,1.611,1.887,3.277,2.922,4.867c4.293,6.901,9.602,13.307,12.471,20.997c1.734,2.854,0.769,6.416,0.168,9.479
                           c-0.914,4.594-2.313,9.074-3.436,13.613l-0.008,0.605c0.387-0.07,1.158-0.217,1.547-0.287c0.326-0.291,0.588-0.632,0.795-1.021
                           c3.1-4.595,6.895-9.134,7.68-14.806c1.74,0.154,1.563,2.08,2.105,3.314c0.233,1.539,0.455,3.096,0.297,4.664
                           c-0.063,2.316-0.076,4.637-0.148,6.96c0.004,0.335,0.011,1.005,0.015,1.343c1.102,2.727,2.979,5.059,5.299,6.859
                           c0.686,0.25,1.361,0.523,2.033,0.824c1.319,0.848,2.721,1.572,4.244,1.994c-0.063-0.867-0.14-1.729-0.226-2.588
                           c-0.346-1.418-0.807-2.847-0.777-4.316c0.365-1.15,1.207-2.032,1.988-2.909c1.83-1.526,3.43-3.3,5.236-4.856
                           c3.385-1.855,7.322-1.213,11.012-1.467l0.301-0.26c0.638-0.689,1.644-0.461,2.474-0.393c1.223,0.191,2.391-0.316,3.537-0.666
                           c2.651-0.834,5.313-1.691,7.823-2.904c-6.569-0.42-13.172,0.004-19.75-0.217c-1.83-0.102-3.58-0.77-5.186-1.617
                           c1.723-1.279,3.574-2.391,5.133-3.876c0.762-0.7,1.494-1.494,2.5-1.834c2.771-1.007,5.678-1.827,8.141-3.488
                           c3.671-0.175,7.322,0.454,10.992,0.157c5.787-0.039,11.504-1.246,16.961-3.104c-0.01-0.529-0.016-1.045-0.016-1.563
                           c-3.684-0.785-7.537,0.849-11.197-0.229c-1.731-0.918-3.356-2.05-5.02-3.095c-1.349-0.769-1.271-2.799-2.793-3.336
                           c-3.092-1.229-6.211-2.558-8.73-4.799c0.021-0.931,0.033-1.858,0.029-2.779c1.518-1.864,3.324-3.488,5.397-4.709
                           c2.134-1.4,4.648-1.994,6.929-3.091l0.235,0.023c2.332-1.073,4.888-0.862,7.359-1.083c2.76-1.703,5.869-2.63,8.801-3.947
                           c0.537-0.086,1.063-0.229,1.592-0.361c1.888-0.728,3.839-1.326,5.837-1.663c2.768-0.34,5.571-0.098,8.354-0.052
                           c0.277-0.325,0.561-0.652,0.848-0.972c1.154-1.754,2.492-3.378,3.603-5.165c0.701-0.202,1.406-0.409,2.108-0.614
                           c0.322-0.354,0.646-0.709,0.976-1.059c0.541-0.652,1.076-1.313,1.613-1.97l-0.103-0.474c0.316-0.708,0.566-1.458,0.973-2.122
                           c4.429-0.5,8.873-1.063,13.337-1.06c-2.828,3.003-5.914,6.181-10.05,7.231c0.011,0.439,0.025,0.886,0.039,1.331
                           c1.072,0.721,2.979,1.591,2.224,3.215c-0.931,1.774-2.64,3.118-2.933,5.214c-0.364,2.333-0.004,4.844-1.188,6.984
                           c-2.246,2.665-6.107,2.57-8.801,4.538c-1.093,0.757-1.294,2.139-1.591,3.332c0.373,1.354,0.162,3.117,1.451,4.033
                           c1.445,0.152,2.898,0.014,4.351,0.022c-2.052,3.058-1.769,6.882-1.572,10.38c1.674,2.002,2.508,4.518,2.979,7.047
                           c0.691-0.037,1.382-0.086,2.07-0.136c1.262-2.748,2.545-5.514,4.23-8.034c2.463-3.257,6.243-5.025,9.86-6.685
                           c1.869-1.173,4.146-0.871,6.242-0.824c0.154,1.021,0.307,2.042,0.461,3.067c1.185-0.573,2.435-1.006,3.709-1.332
                           c-1.146,0.469-2.406,0.886-3.033,2.056c0.752-0.793,1.695-1.715,2.898-1.249c1.938,0.43,2.582,2.462,3.742,3.809
                           c0.412,0.686,1.336,0.604,1.819,0.033c2.761-2.986,3.302-7.164,4.609-10.854c1.777-4.347,4.734-8.163,8.242-11.252
                           c2.418-2.229,4.676-4.797,7.774-6.084c1.318-0.457,2.713,0.067,4.063,0.032c3.861-3.872,6.535-8.739,9.627-13.216
                           c1.039-1.593,2.885-2.263,4.521-3.06c1.908-0.878,3.492-2.286,5.152-3.546c0.094-0.542,0.188-1.079,0.287-1.615
                           c-2.713,1.786-5.961,2.337-9.156,2.316l-0.373,0.271c-8.641,0.258-17.811-0.37-25.377-4.973c-2.893-1.24-5.662-2.783-8.676-3.748
                           c-1.988-0.641-3.986-1.351-6.09-1.455c-2.846-0.627-5.797-0.083-8.639-0.739c0.25-0.699,0.504-1.399,0.713-2.108
                           c3.412-2.415,6.319-5.535,10.131-7.36c2.26-0.8,4.412-1.888,6.549-2.971c2.664-1.374,4.713-3.622,6.631-5.873
                           c1.455-1.956-0.174-4.316-0.68-6.319c-1.006-1.39-1.349-3.134-2.388-4.51c-0.329-0.911-1.116-1.515-1.944-1.951
                           c-0.039-0.124-0.115-0.382-0.154-0.511c-2.381-1.605-5.106-3.464-5.678-6.492c-0.312-2.229-0.123-4.485-0.232-6.726
                           c3.89,2.021,7.146,5.111,11.187,6.894c5.08,1.441,10.393,1.585,15.524,2.769c3.062,0.303,6.117,0.67,9.138,1.289l0.405,0.15
                           c2.095,0.563,4.246,0.97,6.254,1.824c4.234,1.812,8.537,3.709,12.064,6.741c2.064,1.885,4.316,3.897,4.986,6.732
                           c1.094,4.265-0.187,8.725-1.992,12.61c-0.877,1.74-0.963,4.013-2.775,5.127c0.359,2.199,0.676,4.407,1.229,6.564
                           c0.623-1.85,0.756-3.909,1.926-5.538c1.307-1.893,2.727-3.709,4.082-5.568c1.092-1.524,3.809-1.53,4.053-3.671
                           c0.211-1.744,0.006-3.501,0.043-5.246c-0.1-2.986,1.5-5.768,3.445-7.915c2.387-2.746,5.77-4.198,8.854-5.98
                           c1.908-1.13,4.289-0.435,6.234-1.5c2.146-0.999,5.061-2.131,5.26-4.868c-1.065-0.679-2.178-1.288-3.348-1.767
                           C238.327,108.255,234.134,108.166,230.183,108.956z M72.347,205.62c0.729-0.355,1.652-0.297,2.18,0.373
                           c-0.746,0.104-1.547,0.203-2.274-0.047L72.347,205.62z"
								/>
								<path
									className="color"
									d="M244.322,174.962c0.05,0.089,0.146,0.275,0.191,0.366c-0.072,0.126-0.211,0.379-0.283,0.503
                           c-0.746-0.411-1.483-0.952-2.369-0.961c-2.916-0.203-5.821,0.272-8.641,0.967c-3.047,0.775-6.219,0.753-9.338,0.79
                           c-2.146-0.367-4.283-0.795-6.42-1.212c0.276-0.207,0.561-0.411,0.848-0.618c-3.205-1.23-6.051-3.186-9.063-4.799
                           c-0.873-0.499-1.847-0.772-2.822-0.949c0.422,0.541,0.852,1.083,1.283,1.628c1.647,2.547,3.666,5.017,4.178,8.103
                           c1.035,3.375-0.191,6.833,0.033,10.272c-4.479,3.487-10.453,3.967-15.92,3.702c-2.556-0.498-4.984-1.53-6.924-3.301
                           c-0.23-0.015-0.691-0.037-0.921-0.052c0.38,0.521,1.193,0.908,1.072,1.652c-0.393,4.709-3.268,8.762-3.934,13.41l-0.287,0.014
                           c-0.562,3.781-0.383,7.955,1.395,11.357c1.554,0.861,3.369,0.947,5.103,0.828c2.938-0.211,5.979,0.057,8.789-1.012
                           c2.131-0.781,3.729-2.529,5.854-3.34c1.066-1.379,2.435-2.533,3.334-4.039c0.763-3.037-1.17-6.233-0.02-9.219
                           c1.658-2.826,5.223-3.535,8.153-4.316c3.136-1.572,6.642-2.096,10.115-1.824c-0.201-0.264-0.397-0.527-0.594-0.783
                           c2.859,0.029,5.803,0.293,8.572-0.607c3.492-1.193,6.074-3.946,8.651-6.461c0.162-3.024,0.9-5.986,1.021-9.02
                           C245.122,175.625,244.993,174.76,244.322,174.962z"
								/>
								<path
									className="color"
									d="M162.542,228.522c-2.414-0.293-4.801-0.969-6.842-2.322c-0.009-0.928-0.005-1.848,0.02-2.762l-0.201-0.191
                           c-0.334-0.756-0.756-1.477-1.197-2.17c-2.868-2.367-6.918-2.861-10.377-1.633c-2.28,0.848-4.106,2.5-6.125,3.797
                           c-0.043,0.111-0.123,0.332-0.166,0.441c-1.932,1.207-3.78,2.555-5.864,3.489c-3.076,1.361-6.259,2.815-8.547,5.365
                           c-0.529,0.752-1.031,1.735-2.076,1.796c-1.814,0.092-3.599-0.363-5.371-0.682c-1.683-0.094-3.367-0.202-4.996-0.65
                           c-0.484-0.521-0.959-1.044-1.424-1.576c-0.715-1.693-1.117-4.306-3.459-4.287c0.444,3.254,1.006,6.535,0.465,9.827
                           c-1.021,1.304-2.357,2.481-4.041,2.769c-2.726,0.406-5.605,0.145-8.033-1.241c-2.674-1.81-4.744-4.269-6.719-6.782
                           c-2.463-2.479-4.949-5.004-7.914-6.886c-0.824-0.532-1.318-1.397-1.883-2.161c-0.125,1.977,1.297,3.568,2.198,5.203
                           c2.822,4.441,7.498,8.352,7.455,14.029c0.472,5.174-2.727,9.729-6.18,13.242l0.617,0.039c0.656,0.004,1.318,0.01,1.979,0.004
                           c1.382-0.062,2.779-0.091,4.146,0.19c2.945,0.608,5.998,1.123,8.58,2.774c3.854,1.721,7.178,4.355,10.682,6.668
                           c1.82,1.299,4.613,1.388,6.258-0.248c2.064-1.789,3.961-3.762,5.937-5.647c2.635-2.396,1.375-6.632,3.731-9.188
                           c1.111-1.309,2.957-1.299,4.509-1.646c0.866-0.895,1.819-1.697,2.875-2.365c1.678-1.078,3.264-2.283,4.969-3.318
                           c0.182-0.395,0.373-0.784,0.565-1.172c2.313-1.893,4.029-4.369,5.908-6.666c1.406-1.721,2.441-3.887,4.439-5.014
                           c2.813,0.787,3.705,3.98,5.875,5.572c3.344,0.979,6.766-0.373,10.061-0.979c1.961-1.082,3.963-2.111,6.129-2.717
                           c1.615-0.445,3.162-1.154,4.381-2.332c-0.694-0.029-1.391-0.029-2.079-0.005C168.05,229.274,165.275,228.938,162.542,228.522z"
								/>
								<path
									className="color"
									d="M140.685,369.21l-0.057,0.629c-0.049-0.266-0.146-0.791-0.191-1.055c-0.608-1.051-0.949-2.213-1.346-3.346
                           c-0.422-1.265-0.854-2.522-1.184-3.813c-0.218-0.624-0.433-1.243-0.652-1.858c-0.23-0.58-0.461-1.154-0.687-1.729
                           c-0.221-0.15-0.659-0.447-0.881-0.594l-0.104-0.799c0.369,0,1.104-0.004,1.475-0.004c0.316-0.371,0.629-0.742,0.949-1.102
                           c0.022-0.743,0.053-1.477,0.076-2.206l-0.34-0.194c-0.58-0.336-1.154-0.67-1.722-1.017c-1.379,0.006-2.758,0.226-4.004,0.838
                           c-0.471,1.224-0.287,2.569,0.32,3.709l0.781-0.101c-0.105,0.263-0.316,0.789-0.422,1.055c-0.164,0.148-0.488,0.445-0.652,0.589
                           c-2.553,4.067-2.457,9.065-4.545,13.31l-0.398,0.088c-0.059,0.518-0.11,1.041-0.164,1.572c1.056,0.297,2.113,0.655,3.226,0.627
                           c3.813,0,7.758,0.504,11.395-0.936c-0.063-0.895-0.139-1.792-0.483-2.625C140.978,369.991,140.786,369.47,140.685,369.21z"
								/>
							</g>
							<path
								opacity="0.4"
								className="color"
								d="M302.695,94.725c-1.893-1.777-3.344-3.978-5.367-5.619c-2.547-3.667-6.127-6.713-7.508-11.102
                        c-0.402-0.953-0.76-1.917-1.135-2.876c-0.564-1.463-1.188-2.906-1.777-4.364c-0.805-1.932-1.641-3.852-2.367-5.812
                        c-1.941-4.403-3.887-8.863-6.871-12.673c-5.055-6.817-11.988-11.897-18.854-16.741c-2.027-1.5-4.193-2.794-6.34-4.115
                        c-0.703-0.422-1.402-0.849-2.098-1.28c-0.432-0.219-0.863-0.441-1.285-0.665c-0.963-0.623-1.963-1.187-2.998-1.677
                        c-1.117-0.61-2.242-1.189-3.355-1.792l-0.631,0.138c-0.557,0.251-1.154-0.128-1.318-0.673c-0.09-0.18-0.268-0.53-0.359-0.702
                        c-1.523-0.469-2.965-1.14-4.412-1.795c-0.916-0.389-1.85-0.726-2.76-1.118c-1.57-0.744-3.211-1.329-4.893-1.758
                        c-1.721-0.756-3.498-1.351-5.299-1.854c-0.85-0.317-1.715-0.595-2.588-0.809c-1.34-0.069-2.678-0.049-4.014-0.029
                        c0.412-0.14,0.824-0.284,1.24-0.428c-4.102-1.188-8.342-1.767-12.51-2.658c0.732,0.94,1.471,1.883,2.18,2.844
                        c2.852,3.299,4.096,7.678,4.145,11.976c0.076,3.512-0.063,7.29-2.133,10.286c-1.477,1.898-3.205,3.737-3.76,6.147
                        c-0.365,1.625-0.303,3.599,0.943,4.839c1.566,0.965,3.367,1.462,4.979,2.353c0.719,0.234,1.441,0.473,2.176,0.684l0.061,0.294
                        c0.303,0.12,0.906,0.348,1.207,0.465l0.633-0.006c1.414-0.112,2.971,0.17,4.283-0.465c3.004-2.228,5.746-4.79,8.572-7.231
                        c1.676,0.682,3.631,1.112,4.777,2.637c1.174,1.565,2.328,3.157,3.285,4.863c0.773,1.691,0.84,4.129-0.928,5.232
                        c-0.01,0.076-0.023,0.23-0.033,0.306c0.129,1.922-0.043,3.858-0.787,5.655c-1.115,2.267,1.414,4.677,0.207,6.875
                        c-0.6,0.081-1.203,0.13-1.803,0.14c-2.447-0.075-4.896-0.38-7.244-1.098c-0.967-0.035-1.93,0.033-2.889,0.08
                        c-0.781,0.184-1.566,0.332-2.354,0.475c-1.188,0.916-2.322,1.893-3.449,2.882c-1.695,2.676-2.813,6.04-1.643,9.15
                        c0.963,2.477,1.221,5.329,3.076,7.368c0.521,0.154,1.045,0.317,1.563,0.494c2.26,0.355,4.561,0.407,6.832,0.115
                        c0.877-1.676,0.857-3.589,1.029-5.419c1.309,0.049,2.596,0.287,3.857,0.643l-0.02,0.374c1.965,0.283,4.02,0.72,5.285,2.404
                        c0.053,1.314,0.1,2.632,0.135,3.945c1.355,0.086,2.734-0.039,3.967-0.666c-0.359-0.024-1.078-0.072-1.438-0.096
                        c1.164-0.954,2.563-1.625,3.617-2.718c0.924-1.249,0.186-2.826,0.014-4.192c-0.699-2.835,0.561-5.753,2.123-8.073
                        c2.094,0.239,3.66,1.786,4.879,3.384c-0.275-0.011-0.811-0.035-1.084-0.045c0.197,0.126,0.584,0.379,0.775,0.503
                        c0.58,0.135,1.16,0.265,1.75,0.385c0.762,2.319,2.635,4.027,4.949,4.747c3.156,1.008,6.314,2.333,8.777,4.618
                        c1.104,1.051,0.551,2.713,0.201,3.978c-1.184,3.593-1.844,7.325-2.941,10.942c-0.287,1.109-1.107,1.937-1.926,2.683
                        c-0.184,0.365-0.379,0.725-0.561,1.083c-0.729,1.63-0.902,3.418-0.988,5.175c-0.119,4.279-0.057,8.56,0.168,12.841
                        c0.225-0.187,0.68-0.56,0.906-0.747c0.066-0.265,0.205-0.796,0.273-1.061c2.371-3.799,4.158-7.924,6.477-11.752
                        c1.074-1.707,1.688-3.637,2.156-5.578c0.352-0.519,0.699-1.035,1.049-1.553c1.061-0.022,2.119,0.101,3.131,0.436
                        c0.061,0.342,0.176,1.026,0.238,1.371c1.145-0.647,1.807-1.815,2.67-2.758c1.527-1.673,2.947-3.446,4.426-5.162
                        c0.676-0.916,1.338-1.839,2.037-2.739c0.604-1.052,1.279-2.061,1.854-3.129c0.412-0.029,0.824-0.063,1.246-0.09l-0.037,0.458
                        c1.326,0.662,2.695,1.285,4.227,1.159c0.396-0.349,0.803-0.695,1.209-1.039c2.814-0.152,5.582,0.456,8.387,0.589
                        c-0.699,0.148-1.4,0.277-2.1,0.407c2.473-0.297,4.908,0.393,7.375,0.436C304.439,96.446,303.581,95.569,302.695,94.725z"
							/>
							<path
								opacity="0.4"
								className="color"
								d="M255.294,164.294c-1.279,4.135-2.918,8.15-4.744,12.079c-1.557,3.225-3.785,6.06-6.189,8.688
                        c-2.578,2.515-5.16,5.268-8.652,6.462c-2.77,0.9-5.713,0.637-8.572,0.607c0.195,0.255,0.393,0.52,0.594,0.783
                        c-3.475-0.271-6.98,0.252-10.115,1.824c-2.025,2.084-6.047,2.01-6.66,5.348c-0.564,3.139,0.322,6.367-0.402,9.52
                        c-1.307,1.141-2.727,2.186-4.406,2.707c-2.125,0.811-3.725,2.559-5.855,3.34c-2.809,1.068-5.852,0.801-8.789,1.012
                        c-1.732,0.119-3.549,0.033-5.102-0.828c-4.715-2.033-6.037-7.625-10.037-10.488c-1.203-0.822-2.689-0.95-4.021-1.439
                        c-1.85-0.345-3.77-0.172-5.369,0.877c-1.271,2.479-3.504,4.29-4.787,6.756c-0.475,1.424-0.297,2.984-0.781,4.418
                        c-0.455,1.231-0.576,2.539-0.537,3.854c0.791,0.482,1.586,0.96,2.396,1.423c1.385,0.703,2.803,1.354,4.25,1.93
                        c3.061,1.979,6.668,0.081,9.961,0.689c-0.082,1.353-0.68,2.583-1.529,3.612c-1.006,1.884-3.373,1.317-5.121,1.621
                        c0.689-0.025,1.385-0.025,2.08,0.004c0.607,0.23,1.207,0.498,1.811,0.768c-2.357,1.484-4.873,2.827-7.754,2.729
                        c-0.229,0.5-0.459,1.004-0.689,1.506c-1.293,0.01-2.588,0.004-3.877,0.047c-3.295,0.605-6.717,1.956-10.061,0.979
                        c-2.17-1.591-3.063-4.785-5.875-5.572c-1.998,1.127-3.033,3.293-4.439,5.014c-1.879,2.297-3.596,4.773-5.908,6.666
                        c-0.193,0.387-0.385,0.777-0.566,1.171c-1.705,1.035-3.291,2.241-4.969,3.318c-1.055,0.669-2.008,1.473-2.875,2.366
                        c-1.551,0.348-3.396,0.339-4.508,1.646c-2.357,2.558-1.098,6.794-3.732,9.189c-1.975,1.887-3.871,3.859-5.936,5.648
                        c-1.645,1.635-4.438,1.547-6.258,0.248c-3.504-2.313-6.828-4.948-10.682-6.668c-1.787-0.014-3.543-0.348-5.201-1.001
                        c-2.436-0.968-5.234-0.599-7.523-1.965c-0.66,0.005-1.322,0-1.979-0.005c-1.889,0.632-3.574,1.72-5.439,2.406
                        c-2.207,0.78-4.469,1.6-6.852,1.46c-0.383-0.479-0.76-0.959-1.115-1.452c-0.527-1.037-1.137-2.03-1.836-2.96
                        c-1.432-0.743-2.922-1.364-4.475-1.812c-2.701-0.613-4.785-2.535-7.139-3.867c-2.027-1.1-4.111-2.098-6.281-2.878
                        c-4.289-1.598-8.773-0.072-12.938,1.149c-1.82,0.484-3.588,1.134-5.375,1.729c-1.865,0.787-3.732,1.574-5.682,2.119
                        c1.291,0.238,2.566,0.566,3.865,0.78c2.123,0.513,4.197,1.183,6.258,1.903c0.785,0.328,1.576,0.646,2.371,0.957
                        c0.768,0.379,1.543,0.748,2.305,1.131c5.055,2.64,9.951,6.195,12.688,11.326c0.303,0.825,0.629,1.639,0.973,2.453
                        c0.068,0.254,0.201,0.762,0.268,1.016c0.863,4.896,0.877,9.891,1.523,14.816c0.197,2.257,0.539,4.494,0.826,6.74
                        c0.65,2.572,1.139,5.184,1.887,7.729c0.336,0.775,0.662,1.556,0.996,2.337c0.164,0.5,0.322,1.008,0.475,1.519
                        c0.686,1.214,1.361,2.429,2.07,3.632c2.535,3.973,5.779,7.471,8.025,11.647c0.148,0.179,0.445,0.532,0.594,0.713
                        c1.543,2.79,3.469,5.351,5.084,8.101c0.164,0.152,0.488,0.459,0.65,0.611c0.586,0.73,1.176,1.452,1.754,2.189
                        c0.178,0.16,0.533,0.476,0.709,0.633c1.375,1.539,2.973,2.872,4.662,4.049c0.969,0.27,1.932,0.576,2.842,1.016
                        c0.666,0.236,1.342,0.469,2.018,0.711c0.943,0.352,1.906,0.67,2.865,1.002c0.824,0.324,1.662,0.637,2.506,0.933l0.066,0.234
                        c1.242,0.552,2.42,1.222,3.551,1.97c0.715,0.229,1.432,0.461,2.16,0.66l0.039,0.297c1.207,0.217,2.41,0.48,3.574,0.888
                        c1.754,0.353,3.541,0.509,5.281,0.935c3.727,0.598,7.584,1.072,10.961,2.884c1.381,0.844,2.752,1.706,4.059,2.665
                        c0.713,0.441,1.441,0.867,2.156,1.322c0.135,0.022,0.398,0.08,0.533,0.104c0.123,0.178,0.373,0.521,0.496,0.696
                        c2.123,0.641,4.404,0.459,6.598,0.584c-0.105,0.176-0.314,0.53-0.42,0.707c1.246-0.612,2.625-0.832,4.004-0.838
                        c0.566,0.346,1.141,0.681,1.721,1.017c-0.057-0.308-0.168-0.919-0.221-1.228c1.457-0.09,2.934-0.281,4.236-0.992
                        c1.893-0.387,3.77-0.862,5.635-1.369c0.949-0.321,1.902-0.633,2.855-0.949c3.686-1.158,7.119-2.957,10.617-4.565
                        c0.494-0.331,0.998-0.642,1.51-0.936c2.693-1.364,5.15-3.118,7.643-4.809c1.814-1.175,3.516-2.516,5.232-3.831
                        c1.162-0.933,2.271-1.929,3.426-2.867c1.48-1.24,2.859-2.594,4.158-4.022c2.818-2.251,4.547-5.446,6.301-8.522
                        c0.703-2.543,2.146-4.787,3.66-6.918c0.168-0.154,0.504-0.47,0.67-0.623c1.572-1.66,3.221-3.244,4.842-4.859
                        c2.141-2.107,3.434-4.848,5.063-7.329c2.641-4.267,5.102-8.631,7.719-12.908c1.902-3.154,4.365-5.903,6.646-8.778
                        c1.053-1.174,2.16-2.311,3.004-3.646c2.15-1.102,3.986-2.727,5.801-4.302c2.402-2.037,3.078-5.271,3.795-8.181
                        c0.889-4.358,1.385-8.875,3.215-12.979c2.809-6.361,6.881-12.127,9.09-18.768c4.072-10.813,7.656-21.819,10.613-32.993
                        c2.73-10.98,2.869-22.395,2.66-33.644C255.7,163.508,255.495,163.902,255.294,164.294z"
							/>
							<path
								opacity="0.4"
								className="color"
								d="M29.148,212.622c-0.914,4.594-2.313,9.074-3.436,13.613l-0.008,0.606
                        c0.387-0.07,1.158-0.217,1.547-0.288c0.326-0.29,0.588-0.631,0.795-1.02c3.1-4.595,6.895-9.133,7.68-14.805
                        c1.74,0.154,1.563,2.079,2.105,3.314c0.234,1.539,0.455,3.096,0.297,4.664c-0.063,2.317-0.076,4.637-0.148,6.96
                        c0.004,0.335,0.01,1.005,0.014,1.343c1.102,2.726,2.98,5.059,5.299,6.859c0.686,0.25,1.361,0.523,2.033,0.824
                        c1.32,0.848,2.721,1.573,4.244,1.994c-0.063-0.867-0.139-1.729-0.225-2.588c-0.346-1.418-0.807-2.846-0.777-4.316
                        c0.365-1.15,1.207-2.032,1.988-2.909c1.83-1.527,3.43-3.3,5.236-4.856c3.385-1.856,7.322-1.213,11.012-1.467l0.301-0.26
                        c0.637-0.689,1.643-0.461,2.473-0.393c1.223,0.192,2.391-0.316,3.537-0.665c2.652-0.835,5.313-1.692,7.824-2.905
                        c-6.57-0.42-13.172,0.004-19.75-0.217c-1.83-0.102-3.58-0.77-5.186-1.617c-1.781-1.029-3.521-2.127-5.27-3.205
                        c-1.42-0.836-2.564-2.244-2.625-3.943c-0.188-3.416-0.217-6.952,1.105-10.168c0.416-2.036,1.672-3.684,2.74-5.408
                        c1.639-2.756,0.203-6.27,1.873-8.998c0.912-1.434,2.02-2.726,3.006-4.103c0.828-1.312,2.496-1.529,3.861-1.935
                        c2.631-0.617,5.104-1.786,7.383-3.22c4.217-2.677,4.533-8.72,8.889-11.254c1.945-1.255,4.35-1.185,6.541-0.763
                        c3.004-2.352,6.002-4.944,7.824-8.355c1.936-3.913,4.313-7.676,5.418-11.939c0.461-1.789,0.566-3.777,1.781-5.261
                        c1.885-2.285,4.563-3.79,6.326-6.188c0.723-0.842,0.666-2.519-0.594-2.788c-2.918-1.62-6.211-0.541-9.191,0.23
                        c0.742,0.289,1.49,0.575,2.238,0.863c0.057,0.799,0.115,1.596,0.172,2.395c-1.586-0.456-3.127-1.044-4.623-1.738
                        c0.652-0.432,1.783-0.451,1.949-1.372c-0.432-0.365-0.9-0.766-1.498-0.713c-2.109-0.005-4.223,0.253-6.33,0.048
                        c1.998-1.232,4.352-1.165,6.598-0.949c-0.771-0.58-1.49-1.569-2.568-1.422c-2.615,0.337-4.986,1.633-7.162,3.061
                        c-2.334,1.502-4.592,3.157-7.105,4.363c-2.699,1.296-5.08,3.188-7.883,4.271c-2.266,0.885-4.732,0.316-6.99-0.294
                        c-4.01-1.084-8.221-2.794-10.67-6.333c-0.225-0.717-0.752-1.498-0.277-2.22c1.244-2.337,2.855-4.469,4.398-6.617
                        c1.369-1.935,3.615-2.958,5.145-4.724c-0.762-1.758-1.484-3.559-1.336-5.525c0.01-1.719-0.326-3.583,0.426-5.194
                        c2.061-2.931,5.904-4.365,7.221-7.867c0.313-0.695,0.172-1.791,1.064-2.069c2.17-0.991,4.771-0.871,6.721-2.381
                        c1.707-1.342,3.627-2.438,5.16-3.981c1.15-1.174,0.738-3.096-0.244-4.232c-0.881-0.742-2.078-0.866-3.133-1.236
                        c-1.025-2.017-2.309-4.048-1.879-6.43c0.029-2.371,0.303-4.778,1.279-6.961c2.535-6.613,7.93-11.509,13.162-16.027
                        c1.85-1.821,4.25-2.876,6.498-4.102c2.957-1.606,6.213-2.509,9.414-3.487c2.496-2.142,5.951-2.276,9.057-2.719
                        c3.473-0.104,6.84-1.081,10.32-1.096l0.158-0.077c0.1-0.541,0.195-1.081,0.297-1.619c3.482-1.351,5.877-4.287,8.059-7.181
                        c1.096-2.004,1.777-4.293,1.906-6.576c-0.316-2.347-1.188-4.825-0.043-7.105c0.254-0.163,0.768-0.493,1.021-0.655
                        c0.027-0.029,0.082-0.092,0.109-0.12c1.141-3.053,3.709-5.181,5.408-7.886c1.453-2.513,4.381-3.395,6.814-4.654
                        c3.82-1.265,7.834-1.792,11.787-2.497c-0.197,0.413-0.393,0.831-0.586,1.24c3.121-0.249,6.23-0.574,9.344-0.889
                        c3.713,0.057,7.451-0.153,11.15,0.295c0.441,0.016,0.881,0.012,1.33-0.01c-4.324-2.003-8.738-3.854-13.381-4.995
                        c-4.738-0.949-9.582-0.848-14.383-0.752c-2.119,0.05-4.199,0.455-6.277,0.817c0.359,0.055,1.072,0.165,1.428,0.223
                        c-2.195-0.055-4.35,0.502-6.387,1.283c-2.439,1.082-5.055,1.672-7.705,1.93c-4.867,0.935-9.635,2.684-14.646,2.655
                        c-6.004-0.052-11.969,0.983-17.771,2.459l-0.283,0.21c-1.682,0.493-3.32,1.107-4.967,1.695c-3.58,1.625-7.068,3.455-10.293,5.708
                        l-0.086,0.187c-1.045,0.555-2.002,1.244-2.908,2.003c-1.902,1.413-3.689,2.966-5.418,4.585c-0.432,0.382-0.857,0.761-1.291,1.145
                        c-0.334,0.305-0.664,0.611-1,0.919c-0.857,1.05-1.781,2.042-2.842,2.893c-2.031,2.311-4.355,4.38-6.162,6.873l-0.053,0.077
                        l-0.08,0.037c-0.145,0.077-0.426,0.221-0.57,0.294c-1.908,2.462-4.213,4.584-6.219,6.969c-1.863,1.979-3.789,3.905-5.574,5.96
                        c-4.033,4.261-8.26,8.342-12.504,12.396c-0.307,0.331-0.613,0.665-0.926,0.996c-0.314,0.296-0.637,0.593-0.947,0.896
                        c-1.813,1.819-3.508,3.753-5.424,5.459c-0.6,0.712-1.242,1.383-1.898,2.041c-2.084,2.228-4.293,4.34-6.244,6.696
                        c-5.438,6.029-10.549,12.4-14.766,19.349c-0.143,0.181-0.43,0.545-0.57,0.729c-1.102,2.226-2.611,4.271-3.453,6.615
                        c-0.035,1.332-0.131,2.698-0.879,3.852c-0.109-0.336-0.32-0.996-0.432-1.326c-4,8.902-6.385,18.607-6.385,28.401
                        c-0.148,3.69,0.455,7.4,1.793,10.844c2.178,5.72,5.432,11.001,7.143,16.903c1.227,3.815,1.637,7.873,3.234,11.572
                        c0.578,1.302,1.221,2.577,1.764,3.904c0.996,1.611,1.887,3.277,2.922,4.867c4.293,6.901,9.602,13.307,12.471,20.997
                        C30.714,205.997,29.747,209.56,29.148,212.622z"
							/>
						</g>
						<g id="confetti_1">
							<polygon className="color" points="0 6.23 12.76 0 17.43 6.23 9.96 16.81 0 6.23" />
						</g>
						<g id="confetti_2">
							<polygon className="color" points="0 21.79 10.53 0 15.2 2.18 4.93 25.21 0 21.79" />
						</g>
						<g id="confetti_3">
							<polygon className="color" points="50 10 55 30 70 30 60 40 65 55 50 45 35 55 40 40 30 30 45 30" />
						</g>
						<g id="confetti_4">
							<polygon className="color" points="50 10 55 30 70 30 60 40 65 55 50 45 35 55 40 40 30 30 45 30" />
						</g>
					</g>
				</defs>
			</svg>
			<svg id="rootSvg" width="1" height="1"></svg>
		</React.Fragment>
	)
}

export default SvgDefs
