import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page10scene",
	images: [
		{
			src: images("./page8_bg.jpg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./nima-flower.png"),
			imgdiv: "imageCover nima_head_flower-p10",
		},
		{
			src: images("./nima_with_flower.png"),
			imgdiv: "imageCover nima_flower-p10",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center dialogueBubble bubble-sm-thin-left-o text-bubble-p10",
			textIndivData: [
				{
					textData: "धन्यवाद।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.15 },
						{ start: 2.2 },
						{ start: 2.6 },
						{ start: 3 },
						{ start: 3.5 },
					],
				},
			],
			styles:{
				width: '18%',
				top: '22%',
				left: '25%',
				padding: '1% 1% 5% 1%',
			}
		},
	],
	sounds: [
		{
			url: audios("./busyMarket.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.05,
			loop: true,
		},
		{
			url: audios("./dhanyebad.ogg"),
			delay: 8000,
		},
		{
			url: audios("./blank.ogg"),
			delay: 12000,
			isBg: false,
			volume: 0,
		},
	],
}

export default content
