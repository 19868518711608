import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "ब्याग भरिको रहर",
	key: "bag-bhari-ko-rahar",
	coverImage: images("./cover_page.svg"),
	credits: {
		writer: "कोपिला थिङ्ग",
		illustrator: "शुभिछा गुरूङ्ग",
		template: "default",
		bg: images("./bag_with_books.png"),
		titleAudio: audios("./s1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
