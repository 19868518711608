import { appear, disappear } from "styledElements/keyframes";
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const slideIn = keyframes`
0% {
	transform: translateX(110%) rotate(90deg);
}
100% {
	transform: translate(0) rotate(0);
}
`

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./imgs/foodl.png"),
			class: "image-one bg blur",
			imgdiv: "imageCover",
		},
		{
			src: images("./imgs/gohara_anda.png"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "58%", top: "12%", left: "37%", zIndex: "11",
				transform: 'translate(110%)',
				animation: `${slideIn} 2s 6s ease-out normal forwards`,
			}
		},
		{
			src: images("./imgs/kamal.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "30%", top: "42%", left: "0%",opacity: "1", zIndex: "11",
				animation: `${disappear} 0.01s 1s ease-out normal forwards`,
			}
		},
		{
			src: images("./imgs/talkingkamal.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "30%", top: "42%", left: "0%",opacity: "0", zIndex: "11",
				animation: `${appear} 0.01s 1s ease-out normal forwards, ${disappear} 0.01s 6s ease-out normal forwards `,
			}
		},
		{
			src: images("./imgs/girl04.png"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "30%", top: "42%", left: "0%",opacity: "0", zIndex: "11",
				animation: ` ${appear} 0.01s 6s ease-out normal forwards, ${disappear} 0.01s 9s ease-out normal forwards, ${appear} 0.01s 15s ease-out normal forwards`,
			}
		},
		{
			src: images("./imgs/talking_bothhandhip.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "30%", top: "42%", left: "0%",opacity: "0", zIndex: "11",
				animation: `${appear} 0.01s 9s ease-out normal forwards, ${disappear} 0.01s 15s ease-out normal forwards `,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text",
			styles: {
				height: "100%",
				width: "32%",
				background: "rgba(0,0,0,0.5)",
				padding: "2%",
				textAlign: "center",
				color: "white",
				fontSize: "4vmin",
			},
			textIndivData: [
				{
					textData: "",
					textClass: "txt2",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 0.95 }],
				},
			],
		},
		{
			textDivClass: "story-text",
			styles: {
				height: "100%",
				width: "32%",
				padding: "2%",
				textAlign: "center",
				color: "white",
				fontSize: "4vmin",
				animation: `${disappear} 1s linear 8s forwards`

			},
			textIndivData: [
				{
					textData: "सुर्खेतको कुसुन्डा समुदायमा पाइने यो खाना के रहेछ हेरौँ त।",
					textClass: "txt2",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 0.95 }],
				},
			],
		},
		{
			textDivClass: "story-text",
			styles: {
				height: "100%",
				width: "32%",
				padding: "2%",
				textAlign: "center",
				color: "white",
				fontSize: "4vmin",
				opacity: "0",
				animation: `${appear} 1s linear 9s forwards`
			},
			textIndivData: [
				{
					textData: "यहाँ त गोहराको अन्डा खाने चलन रहेछ, कस्तो रमाइलो।",
					textClass: "txt2",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 0.95 }],
				},
			],
		},

		
		{
			textDivClass: "story-text",
			styles: {
				width: "58%",
				textAlign: "center",
				fontSize: "8vmin",
				opacity: "0",
				top: "71%",
				left: "37%",
				zIndex: 12,
				animation: `${appear} 1s linear 15s forwards`
			},
			textIndivData: [
				{
					textData: "गोहराको अन्डा",
					textClass: "txt2",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 0.95 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./surkhet3.ogg"),
			delay: 500,
		},	
		{
			url: audios("./surkhet4.ogg"),
			delay: 9000,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 15000,
		},
		{
			url: audios("./sound_eff/jungle.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
	
	],
}

export default content
