const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover02.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./laddu_cover_page.gif"),
			class: "image-one laddu_cover_page",
			imgdiv: "imageCover",
		},
		{
			src: images("./peda_cover_page.gif"),
			class: "image-one peda_page_cover_page",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center",
			textIndivData: [
				{
					textData: "लड्‍डु",
					textClass: "page1a",
				},
				{
					textData: "र",
					textClass: "page1b",
				},
				{
					textData: "पेडा",
					textClass: "page1c",
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./1.ogg"),
			delay: 2200,
		},
	],
}

export default content
