import { appear } from "styledElements/keyframes"

const content = {
	images: [
		{
			src: "forest/bird_picking_sticks01.png",
			class: "sticks-p2",
			imgdiv: "imageCover",
		},
		{
			src: "birds/bird-picking-stick.gif",
			class: "bird-p2",
			imgdiv: "imageCover",
		},
		{
			src: "forest/bird_fly.gif",
			class: "bird_gif-p2",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text p2-text",
			styles: {
				animation: `${appear} 1s 1s ease-out normal both`,
			},

			textIndivData: [
				{
					textData: "रात र दिन नभनी",
					textClass: "m-0 p2-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "टुक्रा टाक्री बटुली",
					textClass: "m-0 p2-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p2.ogg",
			delay: 1500,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			isBg: true,
			volume: 0.3,
		},
	],
}

export default content
