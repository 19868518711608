const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./Page02_jerry_new.jpg"),
			class: "image-one page4",
			imgdiv: "imageCover",
		},
		{
			src: images("./peda_under_tree.gif"),
			class: "image-five page3peda",
			imgdiv: "imageCover",
		},
		{
			src: images("./laddu_under_tree.gif"),
			class: "image-five page3laddu",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerrya",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jerry1",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jerryb",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jerryc",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry2.png"),
			class: "image-five jerry2",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry3",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry4",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry5",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry6",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry7",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry8",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry9",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry10",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry11",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry12",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry13",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry14",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry15",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry16",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry17",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry18",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry19",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry20",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry21",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry22",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry23",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry24",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry25",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry26",
			imgdiv: "imageCover",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry27",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox2 text_box-p3",
			textIndivData: [
				{
					textData: "तिमीहरू त आकाशमा कहिले काहिँ मात्रै सँगै हुन्छौ। जहिले पनि लुकामारी खेल्छौ। आज सँगै देखेर खुसी लाग्यो।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.7 },
						{ start: 1.1 },
						{ start: 1.5 },
						{ start: 1.9 },
						{ start: 2.1 },
						{ start: 2.3 },
						{ start: 2.7 },
						{ start: 3.5 },
						{ start: 3.8 },
						{ start: 4.1 },
						{ start: 4.5 },
						{ start: 5.5 },
						{ start: 5.95 },
						{ start: 6.25 },
						{ start: 6.65 },
						{ start: 7.1 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./4.ogg"),
			delay: 500,
		},
	],
}

export default content
