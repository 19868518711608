import React from "react"

import BubbleImg from "./assests/circle.svg"

const Dream = (props) => {
	// const state = React.useContext(StateContext);

	return (
		<div className={`dream ${props.disappear ? "disappear-d2 " : ""}`}>
			<img src={BubbleImg} alt="sm-bubble" loading="lazy" className={`sm-bubbleImg ${props.disappear ? "disappear " : ""}`} />
			<img src={BubbleImg} alt="md-bubble" loading="lazy" className={`md-bubbleImg ${props.disappear ? "disappear " : ""}`} />
			<img src={props.DreamImg} alt="dream" loading="lazy" className="dreamImg" />
		</div>
	)
}

export default Dream
