const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./sheep-eating-rayo.gif"),
			class: "image-five",
			imgdiv: "imageCover bluebg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "भेडाले खायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6.ogg"),
			delay: 500,
		},
		{
			url: audios("./a6.ogg"),
			delay: 150,
			isBg: true,
		},
	],
}

export default content
