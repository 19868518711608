import React from "react"
import { Link } from "react-router-dom"
import Modal from "react-modal"
import IconHome from "assets/icons/home.svg"
import BlastBanner from "assets/banner-on-loop.gif"
import PlayImage from "assets/read-again.png"
const LastPage = (props) => {

	//Test for flutter communication 
	var isCallFromFlutter = false;
		isCallFromFlutter = props.isOpenInFlutter;
	//Test for flutter communication 

	return (
		<Modal
			isOpen={true}
			style={{
				overlay: {
					zIndex: "9999",
					backgroundColor: "rgba(0, 0, 0, 0.7)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				},
				content: {
					position: "static",
					backgroundColor: "transparent",
					border: "none",
				},
			}}
			ariaHideApp={false}
		>
			<div key="menuBtnNavBar" className="last__page__menu">
				<div className="menu__wrap">
					<div className="left-banner">
						<img className="img-fluid" src={BlastBanner} alt="banner" />
					</div>
					<div className="right-banner">
						<img className="img-fluid" src={BlastBanner} alt="banner" />
					</div>
					<div className="meta">
						<h2 className="text-center">समाप्त</h2>
					</div>
					{/* <Link className="home__icon" to="/play">
						<img src={IconHome} className="img-fluid" alt="Home" />
					</Link> */}
					{
						// console.log(isCallFromFlutter);
						isCallFromFlutter?
						(<button  
							className="home__icon"
							onClick={() => window.Print.postMessage("Call for back in Flutter")}
						>
							<img src={IconHome} className="img-fluid" alt="Home" />
							home
						</button>):
						<Link className="home__icon" to="/play">
							<img src={IconHome} className="img-fluid" alt="Home" />
						</Link>
					 }
					<ul className="menu__list">
						<li className="play-wrap">
							<div className="image-wrap">
								<img src={PlayImage} className="img-fluid" alt="Play" />
							</div>
							<span onClick={() => props.resetGame()} className="btn btn-round purple" key="replay">
								फेरी खेल
							</span>
						</li>
					</ul>
				</div>
			</div>
		</Modal>
	)
}

export default LastPage
