const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./cover_page.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text rkr-title",
			textIndivData: [
				{
					textData: "रुखको रुप",
					textClass: "one-line",
					timestamps: [{ start: 0 }, { start: 1.25 }, { start: 1.75 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./1.ogg"),
			delay: 500,
		},
	],
}

export default content
