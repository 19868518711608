import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const busToDailek = keyframes`
0% {
	transform: rotate(-19deg) translateX(0%);
}
100% {
	transform: rotate(-19deg) translateX(165%);
}
`
const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(4) translate(28%,20%)",
					animation: `${transformTo("scale(7) translate(25%,17%)")} 3s 30s forwards ease-out `
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '7%',
				top: '35%',
				left: '48%',
				transform: "scaleX(-1)",
				opacity: 0,
				zIndex: 3,
				animation: ` ${appear} 1s 17s ease-out normal forwards,
				${disappear} 1s 29s ease-out normal forwards	 
				`,
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "7%", 
				top: '43%',
				left: '35%',
				zIndex:2,
				animation: `${disappear} 1s 9s ease-out normal forwards`,
			}
		},
		{
			src: images("./imgs/bus4.svg"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '15%',
				top: '59%',
				left: '27%',
				opacity: "0",
				transform: "rotate(-19deg)",
				zIndex: 2,
				animation: `${appear} 1s 10s ease-out normal forwards,
				${busToDailek} 5s 11s ease-out normal forwards,
				${disappear} 1s 16s ease-out normal forwards
				`,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble2 page2Buble",
			textIndivData: [
				{
					textData: "अब यहाँबाट दैलेखमा राउटे समुदायला‌ई भेट्न जाऊँ है।",
					textClass: "txt1 preeti",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '22%',
				left: '0%',
				padding: '2% 0% 4.3% 0% !important',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 1000ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 8s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble1 page2Buble",
			textIndivData: [
				{
					textData: "राउटे जाति त जंगल भित्र बस्‍न रुचाउनु हुँदो रहेछ। यस समुदायमा के कस्तो खाना पाइन्छ हेरौँ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '0%',
				left: '51%',
				transformOrigin: "0% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 18s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 28s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromSurToDail",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '19%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '66%',
				position: 'absolute',
				left: "36%",  
				transform: 'rotate(-18deg) scaleX(0)',
				transformOrigin: "left",
				animation: `${transformTo(["rotate(-18deg) scaleX(1)"])} 4s 10.5s linear forwards, ${disappear} 0.01s 20s linear forwards`
			}
		},
	],
	sounds: [
		{
			url: audios("./dailekh1.ogg"),
			delay: 1000,
		},
		{
			url: audios("./dailekh2.ogg"),
			delay: 18000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/bus2.ogg"),
			delay: 12000,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 33000,
		},
	],
}

export default content
