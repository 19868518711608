// configs
// import gameContent from "../content/gameContent";
import Levels from "../content/words/index"
import shuffle from "shuffle-array"

export const getLevelDetails = (level = "easy") => {
	let data = Levels.filter((lvl) => lvl.level.toString().toLowerCase() === level.toString().toLowerCase())
	return data[0] ?? null
}

export const getLevelData = (levelDetail) => {
	let totalGridSlot = parseInt(levelDetail.grid_width) * parseInt(levelDetail.grid_height)
	let wordArray = levelDetail.words

	let wordDifference = totalGridSlot - wordArray.length

	let newLevelData
	if (totalGridSlot <= wordArray.length) {
		newLevelData = wordArray.slice(0, totalGridSlot)
	} else {
		let bonusWords = JSON.parse(JSON.stringify(wordArray)) //Cloning array of object.
		bonusWords = shuffle(bonusWords).slice(0, wordDifference)
		bonusWords = bonusWords.map((word) => {
			word.key = word.key + "BONUS"
			return word
		})
		newLevelData = [...wordArray, ...bonusWords]
	}

	return shuffle(newLevelData)
}

export const reducer = (state, action) => {
	let newState, levelDetail, levelData, speakerData
	switch (action.type) {
		case "SET":
			levelDetail = getLevelDetails(state.level)
			levelData = getLevelData(levelDetail)
			speakerData = shuffle(JSON.parse(JSON.stringify(levelData)))
			if (levelDetail.level === "easy") {
				newState = {
					...state,
					levelData: levelData,
					speakerData: speakerData,
					gridHeight: levelDetail.grid_height,
					gridWidth: levelDetail.grid_width,
					reset: false,
					choosenWord: speakerData[0],
				}
			} else if (levelDetail.level === "medium") {
				newState = {
					...state,
					levelData: speakerData.slice(0, 10),
					speakerData: speakerData.slice(0, 10),
					options: shuffle(levelDetail.options),
					reset: false,
					choosenWord: speakerData[0],
					optionsArray: shuffle([speakerData[0], ...shuffle(levelDetail.options)].slice(0, 5)),
				}
			} else if (levelDetail.level === "hard") {
				newState = {
					...state,
					levelData: speakerData.slice(0, 10),
					speakerData: speakerData.slice(0, 10),
					options: shuffle(levelDetail.options),
					reset: false,
					choosenWord: speakerData[0],
					optionsArray: shuffle([speakerData[0], ...shuffle(levelDetail.options)].slice(0, 5)),
				}
			} else {
				newState = {
					...state,
					levelData: levelData,
					speakerData: speakerData,
					gridHeight: levelDetail.grid_height,
					gridWidth: levelDetail.grid_width,
					reset: false,
					choosenWord: speakerData[0],
				}
			}

			break

		case "LOADING_COMPLETE":
			newState = {
				...state,
				loadingAssets: false,
			}
			break

		case "RESET":
			newState = {
				...state,
				intro: true,
				rewardComplete: false,
				levelComplete: false,
				stage: 0,
				loadingAssets: false,
				levelData: [],
				speakerData: [],
				reset: true,
			}
			break

		case "RESET_STAGE":
			newState = {
				...state,
				...action.values,
				intro: true,
				rewardComplete: false,
				levelComplete: false,
			}
			break

		case "START_GAME":
			newState = {
				...state,
				currentPage: "GAME_START",
			}
			break

		case "REWARD_COMPLETE":
			newState = {
				...state,
				rewardComplete: true,
			}
			break

		case "PREV_STAGE":
			newState = {
				...state,
				intro: true,
				rewardComplete: false,
				stage: state.stage - 1,
			}
			break

		case "NEXT_STAGE":
			// console.log("next stage", state.levelData.length, state.stage, state.levelData.length > state.stage);
			levelDetail = getLevelDetails(state.level)

			if (state.level === "easy") {
				newState =
					state.levelData.length > state.stage + 1
						? {
								...state,
								rewardComplete: false,
								stage: state.stage + 1,
								choosenWord: state.speakerData[state.stage + 1],
						  }
						: {
								...state,
								rewardComplete: true,
								levelComplete: true,
						  }
			} else if (state.level === "medium") {
				newState =
					state.levelData.length > state.stage + 1
						? {
								...state,
								rewardComplete: false,
								stage: state.stage + 1,
								choosenWord: state.speakerData[state.stage + 1],
								optionsArray: [state.speakerData[state.stage + 1], ...shuffle(levelDetail.options)].slice(0, 5),
						  }
						: {
								...state,
								rewardComplete: true,
								levelComplete: true,
						  }
			} else if (state.level === "hard") {
				newState =
					state.levelData.length > state.stage + 1
						? {
								...state,
								rewardComplete: false,
								stage: state.stage + 1,
								choosenWord: state.speakerData[state.stage + 1],
								optionsArray: [state.speakerData[state.stage + 1], ...shuffle(levelDetail.options)].slice(0, 5),
						  }
						: {
								...state,
								rewardComplete: true,
								levelComplete: true,
						  }
			} else {
				newState =
					state.levelData.length > state.stage + 1
						? {
								...state,
								rewardComplete: false,
								stage: state.stage + 1,
								choosenWord: state.speakerData[state.stage + 1],
						  }
						: {
								...state,
								rewardComplete: true,
								levelComplete: true,
						  }
			}

			break
		default:
			newState = state
	}
	return newState
}
