const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page07b.png"),
			class: "image-one imgbox-sm imgA",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./page07a.png"),
			class: "image-one imgbox-md imgB",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p8 textbox textbox-ban two-lines-texts",
			textIndivData: [
				{
					textData: "भुईँमा च्यात्तिएको झोला देख्‍नुभयो।",
					textClass: "m-0 one-line textStyles text-p8a",
					timestamps: [{ start: 0 }, { start: 1.4 }, { start: 2.4 }, { start: 2.8 }, { start: 4.5 }],
				},
				{
					textData: "अनि एउटा पुरानो ब्याग झिक्‍नुभयो।",
					textClass: "m-0 one-line textStyles text-p8b",
					timestamps: [{ start: 4.8 }, { start: 5.2 }, { start: 5.7 }, { start: 6.3 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s8.ogg"),
			delay: 500,
		},
	],
}

export default content
