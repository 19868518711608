const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./dost_chatting.gif"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./pattaune.gif"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./pataune.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./bhada.png"),
			class: "image-one bhaada",
			imgdiv: "imageCover",
		},
		{
			src: images("./bhaada.png"),
			class: "image-one bhaada",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "पत्याउने कुरा गर्दा",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.9 }, { start: 2.7 }],
				},
				{
					textData: "पट्याउने सुन्‍ने",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 3.6 }, { start: 5.1 }],
				},
				{
					textData: "भाँडा किन्‍न जाऔँ भन्दा",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 6.5 }, { start: 7.4 }, { start: 8.2 }, { start: 9 }],
				},
				{
					textData: "भाडा छैन भन्‍ने",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 9.8 }, { start: 10.7 }, { start: 11.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./4.ogg"),
			delay: 2000,
		},
	],
}

export default content
