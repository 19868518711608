const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./cow-in-cycle.gif"),
			class: "image-five moveRight",
			imgdiv: "imageCover purplebg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "कुप्रो परेर साइकल चलायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.75 }, { start: 1.5 }, { start: 2.05 }, { start: 3 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./4.ogg"),
			delay: 500,
		},
		{
			url: audios("./a4.ogg"),
			delay: 150,
			isBg: true,
		},
	],
}

export default content
