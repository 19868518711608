export const getQueryVariable = (variable) => {
	let validLvl = ["easy", "medium", "hard"]
	let query = window.location.search.substring(1)
	let pair = query.split("=")
	if (pair[0] === variable) {
		if (validLvl.includes(pair[1])) {
			return pair[1]
		}
		return "easy"
	}
	return false
}
