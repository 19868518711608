const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./cow-with-cap.gif"),
			class: "image-five",
			imgdiv: "imageCover purplebg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center",
			textIndivData: [
				{
					textData: "गाईले प्वाल परेको",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 1.7 }],
				},
				{
					textData: "टोपी लगायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2.45 }, { start: 2.8 }, { start: 3.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3.ogg"),
			delay: 1000,
		},
	],
}

export default content
