import React from "react"
import { Draggable } from "react-beautiful-dnd"
function getStyle(style, snapshot) {
	if (!snapshot.isDropAnimating) {
		return style
	}
	const { moveTo, curve, duration } = snapshot.dropAnimation
	const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`
	const rotate = "rotate(1turn)"

	return {
		...style,
		transform: `${translate} ${rotate}`,
		transition: `transform ${curve} ${duration + 1}s`,
	}
}
function EachDrag({ id, index, dragItems, selectAudio }) {
	return (
		<Draggable draggableId={id} index={index}>
			{(provided, snapshot) => (
				<div
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
					// isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
					style={getStyle(provided.draggableProps.style, snapshot)}
					className={dragItems.dragClass}
					onClick={() => selectAudio(dragItems.url)}
				>
					<h3 className={dragItems.font}>{dragItems.name}</h3>
				</div>
			)}
		</Draggable>
	)
}

export default EachDrag
