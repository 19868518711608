import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	imageContainerStyles: {
		transformOrigin: "2% 62%",
		transform: "scale(1)",
	},
	images: [
		{
			src: images("./clouds01.png"),
			class: "image-five cloud1",
			imgdiv: "imageCover",
		},
		{
			src: images("./aeroplane_blue.png"),
			class: "image-five plane1",
			imgdiv: "imageCover moveRight",
		},
		{
			src: images("./aeroplane_pink.png"),
			class: "image-five plane2",
			imgdiv: "imageCover moveLeft",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page15boy topRightBubble  text-center",
			textIndivData: [
				{
					textData: "त्यहाँ पुग्‍न त हवाईजहाजमा २० घण्टा उड्‍न पर्छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.3 },
						{ start: 0.7 },
						{ start: 1.1 },
						{ start: 2.1 },
						{ start: 2.5 },
						{ start: 2.7 },
						{ start: 3.2 },
						{ start: 3.4 },
					],
				},
			],
		},
		{
			textDivClass: "story-text dialogueBubble topBubble text-center",
			styles:{
				left: '61%',
				width: '32%',
				top: '66%',
				padding: '1.9% 2% 6% 2% !important',
				animationDelay: '9s',
			},
			textIndivData: [
				{
					textData: "बीचमा दुईवटा हवाईजहाज फेर्न पर्छ रे।",
					textClass: "m-0",
					timestamps: [
						{ start: 9 }, 
						{ start: 9.7 }, 
						{ start: 10.3 }, 
						{ start: 11.0 }, 
						{ start: 11.3 }, 
						{ start: 11.6 }
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./b3.ogg"),
			delay: 500,
		},
		{
			url: audios("./a11.ogg"),
			delay: 500,
		},
		{
			url: audios("./b4.ogg"),
			delay: 9000,
		},
		{
			url: audios("./a11.ogg"),
			delay: 12000,
		},
	],
}

export default content
