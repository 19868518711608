const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./page04.jpg"),
			class: "image-one page7",
			imgdiv: "imageCover",
		},
		{
			src: images("./laddu_under_tree.gif"),
			class: "image-one ladduwithdad",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox4a text_box-p7a",
			textIndivData: [
				{
					textData: "बा, मलाई आकाशमा कहिले लगेको?",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.2 }, { start: 1.7 }, { start: 2.1 }, { start: 2.5 }],
				},
			],
		},
		{
			textDivClass: "story-text dialogueBubble db_textbox4b text_box-p7b",
			textIndivData: [
				{
					textData: "खै हो मैले त बिर्सेँ।",
					textClass: "m-0",
					timestamps: [{ start: 4.1 }, { start: 4.5 }, { start: 5 }, { start: 5.2 }, { start: 5.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7a.ogg"),
			delay: 500,
		},
		{
			url: audios("./7b.ogg"),
			delay: 4000,
		},
	],
}

export default content
