import React, { useContext, useEffect } from "react"
import { Howl } from "howler"
import { StateContext } from "../../ShapeColorGame"
import "./colorgame.styles.scss"
import { sleep } from "../../utils"
import yo_awaj_kasko_hola from "../../content/chapters/assets/common/wrong.m4a"
// import gsap from "gsap"
import Snap from 'snapsvg-cjs'

// gsap.ticker.lagSmoothing(false)

const MultipleOption = ({ stageData,RobortImageClick, assets , map}) => {
	const state = useContext(StateContext)
	let animalRef = React.useRef(null)
	let fstOptRef = React.useRef(null)
	let optRobertRef = React.useRef(null)
	let OptRef = React.useRef(null)
	// let AnsOptRef =  React.useRef(null)
	let optImgDifRef = React.useRef(null);
	let optionImages = stageData.optionImg1;
	// let otherAnimalsRef = React.useRef(null)
	// var timelap = gsap.timeline({})
	let colorQn
	let initial = {
		// img: stageData.id !== 10 && stageData.animals[stageData.rightAnswer].image,
		animalWalked: false,
		bubble: false,
		letsWalkTogether: false,
		questionerRequest: false,
		resizeGif: false,
		finalStageQ: stageData.id === 10 && stageData.questionerTalk,
		replace: false,
	}

	// const dispatch = useContext(DispatchContext)
	// const [optionState, setOptionState] = useState(initial)

	useEffect(() => { 								/* this useeffect is for scene 6 second option */
		
		
		map = Snap('#svg_robort');
    		Snap.load(stageData.robotOpt, function(data){
				if (map) {
					map.append(data); 
				}
    })
	// 	const bgMusic = new Howl({
	// 	src: [yo_awaj_kasko_hola],
	// 	autoplay: false,
	// 	loop: false,
	// 	onend: async () => {
	// 		if (stageData.id === 10) {
	// 			// setOptionState({ ...optionState, finalStageQ: stageData.questioner })
	// 			// dispatch({ type: "LAST_PAGE_COMPLETE" })
	// 		}
	// 		await sleep(5000)
	// 		// await playAnimalSound()
	// 	},
	// })
	// bgMusic.play()
	if (stageData.id === 8 && state.playing) {
		;(async function () {
			try {
				colorQn = new Howl({
					src: [stageData.fstQnAudio],
					autoplay: true,
					loop: false,
					// onend: async () => {
					// },
				})
				const word = ["रङ", "भरौँ ।"]
				let inputText = document.getElementById("whose-voice")
				let innerHTML = inputText.innerHTML
				let actualWord = "रङ भरौँ ।"	
				for (let i = 0; i < word.length + 1; i++) {
					if (i >= word.length) {
						inputText.innerHTML = `<span class="white">${actualWord}</span>`
					} else {
						let index = actualWord.indexOf(`${word[i]}`)
						if (index >= 0) {
							innerHTML =
								"<span class='hightlight'>" +
								actualWord.substring(0, index) +
								actualWord.substring(index, index + word[i].length) +
								"</span>" +
								actualWord.substring(index + word[i].length)
							inputText.innerHTML = innerHTML
						}
					}

					await sleep(400)
				}
			} catch {}
		})()
		// AnsOptRef.current.innerHTML = stageData.OptionThree;
		}
		return() =>{
			colorQn.unload();
		}
	}, [state.playing, stageData.id])


		const colorClick = (e, index) => {
			// var a = animalRef.current.classList[2];
			var b = ["green","red","blue","yellow"];
			// optRobertRef.current.style.pointerEvents = "none";
			optRobertRef.current.style.pointerEvents = "auto";
			// var par = e.target.parentNode;
			// var child = par.getAllSiblings;
			// console.log(child)
			var color =optRobertRef.current;
			var cursorimg = animalRef.current;
			if(e.target.id === "green"){
				animalRef.current.classList.add(b[0]);
				optRobertRef.current.classList.add(b[0]);
				console.log("aaaaaaaaaa")
				if(color.classList.contains("blue") && cursorimg.classList.contains("blue")){
					color.classList.remove("blue");
					cursorimg.classList.remove("blue");
				}
				else if(color.classList.contains("red") && cursorimg.classList.contains("red")){
					color.classList.remove("red");
					cursorimg.classList.remove("red");
				}
				else if(color.classList.contains("yellow") && cursorimg.classList.contains("yellow")){
					color.classList.remove("yellow");
					cursorimg.classList.remove("yellow");
				}
			}
			if(e.target.id === "blue"){
				animalRef.current.classList.add(b[2]);
				optRobertRef.current.classList.add(b[2]);

				if(color.classList.contains("green") && cursorimg.classList.contains("green")){
					color.classList.remove("green");
					cursorimg.classList.remove("green");
				}
				else if(color.classList.contains("red") && cursorimg.classList.contains("red")){
					color.classList.remove("red");
					cursorimg.classList.remove("red");
				}
				else if(color.classList.contains("yellow") && cursorimg.classList.contains("yellow")){
					color.classList.remove("yellow");
					cursorimg.classList.remove("yellow");
				}
			}
			if(e.target.id === "red"){
				animalRef.current.classList.add(b[1]);
				optRobertRef.current.classList.add(b[1]);

				if(color.classList.contains("blue") && cursorimg.classList.contains("blue")){
					color.classList.remove("blue");
					cursorimg.classList.remove("blue");
				}
				else if(color.classList.contains("green") && cursorimg.classList.contains("green")){
					color.classList.remove("green");
					cursorimg.classList.remove("green");
				}
				else if(color.classList.contains("yellow") && cursorimg.classList.contains("yellow")){
					color.classList.remove("yellow");
					cursorimg.classList.remove("yellow");
				}
			}
			if(e.target.id === "yellow"){
				animalRef.current.classList.add(b[3]);
				optRobertRef.current.classList.add(b[3]);

				if(color.classList.contains("blue") && cursorimg.classList.contains("blue")){
					color.classList.remove("blue");
					cursorimg.classList.remove("blue");
				}
				else if(color.classList.contains("red") && cursorimg.classList.contains("red")){
					color.classList.remove("red");
					cursorimg.classList.remove("red");
				}
				else if(color.classList.contains("green") && cursorimg.classList.contains("green")){
					color.classList.remove("green");
					cursorimg.classList.remove("green");
				}
			}
		}
	return (
		<div className="game-options w-100" style={{ height: `${stageData.frameHeight ? stageData.frameHeight : "100%"}` }}>
			<div
				className="options-listA h-100"
				style={{ width: stageData.animalContainerWidth ? stageData.animalContainerWidth : "100%" }}
				ref={animalRef}
			>
				<div className="game-title" id="whose-voice"></div>

				<div className="optionMain_divA" ref={OptRef}>
				{optionImages.map((item, index) => {
					// console.log(item)
						return(
							<div key={index} className="gameoptionSub_divA option_fst" id={item.itemName} onClick={(e) => colorClick(e)} ref={fstOptRef}>
								<img src={item.imgName} id={item.itemName} className="Secopt_img" alt="option_img1" />
								{/* <img src = {item.rightWrongImg} className="rightwrong" alt="correct_incorrect_img"/> */}
							</div>
						)
					})
				}
					<div className="opt_img" ref= {optImgDifRef}>
						{/* <img src={stageData.robotOpt}  className="opt_robort" alt="option_img1" onClick={(e) => RobortImageClick(e)} ref={optRobertRef} /> */}
						<div  className="opt_robort" onClick={(e) => RobortImageClick(e)} ref={optRobertRef}>
						<div id="svg_robort" className="svg_robort"></div>
						</div>
					</div>
				</div>

			</div>
		</div>
	)
}

export default MultipleOption
