const images = require.context("./images", true)
const movies = require.context("./movies", true)

let content = {
	name: "म पनि सक्छु",
	key: "ma-pani-sakchu",
	coverImage: images("./cover-05.png"),
	movie: movies("./Head To Toe Final Compressed Version m4v.mp4"),
	movies: [
		movies("./Intro.webm"),
		movies("./Rhino.webm"),
		movies("./Rabbit.webm"),
		movies("./Salak.webm"),
		movies("./Elephant.webm"),
		movies("./Leopard.webm"),
		movies("./Cat.webm"),
		movies("./Panda.webm"),
		movies("./Tiger.webm"),
		movies("./Horse.webm"),
		movies("./Owl.webm"),
		movies("./Wolf.webm"),
		movies("./Lion.webm"),
		movies("./Hyena.webm"),
		movies("./Okapi.webm"),
		movies("./Spider.webm"),
		movies("./Duck.webm"),
		movies("./Monkey.webm"),
		movies("./Yak.webm"),
		movies("./Deer.webm"),
		movies("./Buff.webm"),
		movies("./Crock.webm"),
		movies("./Bear.webm"),
		movies("./All Animal Video Version 1.webm"),
		movies("./Credit Video Version 1.webm"),
	],
	timestamps: [],
	subtitles: [],
	content: "",
}

content.timestamps = content.timestamps.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})
content.subtitles = content.subtitles.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})

export default content
