const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./sparrow-with-kalli.gif"),
			class: "image-13",
			imgdiv: "imageCover yellowbg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center blacktext",
			textIndivData: [
				{
					textData: "भँगेराले खुट्टामा",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.1 }],
				},
				{
					textData: "कल्‍ली लगायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2.5 }, { start: 3.2 }, { start: 4.3 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./13.ogg"),
			delay: 500,
		},
		{
			url: audios("./a13.ogg"),
			delay: 150,
			isBg: true,
		},
	],
}

export default content
