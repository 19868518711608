const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./13to24/page03bg.png"),
			imgdiv: "imageCover",
		},
		{
			src: images("./13to24/tortle_body.svg"),
			imgdiv: "imageCover ",
			styles: { width: "60%", top: "30%", left: "50%", transform: "translateX(-50%)" },
		},
		{
			src: images("./13to24/tortle_head.png"),
			imgdiv: "imageCover ",
			styles: { width: "53%", top: "34%", left: "50%", transform: "translateX(-50%)" },
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-top-center textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "भोलिपल्ट बिहान कछुवाले जङ्गलबाट एउटा लामो र मसिनो हाँगो खोजेर ल्यायो। उसले त्यस हाँगालाई दाँतले च्याप्यो।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.78 },
						{ start: 1.6 },
						{ start: 2.4 },
						{ start: 3.5 },
						{ start: 4 },
						{ start: 4.7 },
						{ start: 4.9 },
						{ start: 5.5 },
						{ start: 5.9 },
						{ start: 6.4 },
						{ start: 7 },
						{ start: 7.7 },
						{ start: 8 },
						{ start: 8.7 },
						{ start: 9.3 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p4_s0.ogg"),
			delay: 1500,
		},
	],
}

export default content
