import words from "./words"
import shuffle from "shuffle-array"

const audio_words = require.context("../audios/words", true)
const images = require.context("../images/hints", true)

let finalObject = words
	.map((level_words, index) => {
		let shuffledWords = shuffle(level_words)

		return shuffledWords.map((word, word_index) => {
			let questionIndex = word.question.position
			let questionImageIdx
			if (index === 3) {
				questionImageIdx = Math.ceil(Math.random() * 3)
			} else {
				questionImageIdx = Math.ceil(Math.random() * 4)
			}
			let audioHint
			try {
				if (index === 3) {
					audioHint = audio_words(`./level2/${word.key}/3.ogg`)
				} else {
					audioHint = audio_words(`./${word.key}/3.ogg`)
				}
			} catch (error) {
				console.log(`not found: audio_words: ${word.key}.ogg`)
				audioHint = null
			}

			let question = {
				letter: word.question.letter,
				audioHint: audioHint,
			}
			question.questionImageIdx = questionImageIdx
			question.questionIndex = questionIndex
			const dragItems = word.options.map((option, idx) => {
				let image
				if (idx === 0) {
					if (index === 3) {
						image = `./wood${questionImageIdx}_${option.position}`
					} else {
						image = `./stone${questionImageIdx}_${option.position}`
					}
				} else {
					if (index === 3) {
						image = `./wood${Math.ceil(Math.random() * 3)}_${option.position}`
					} else {
						image = `./stone${Math.ceil(Math.random() * 4)}_${option.position}`
					}
				}

				let audioHint
				try {
					if (index === 3) {
						audioHint = audio_words(`./level2/${word.key}/${idx}.ogg`)
					} else {
						audioHint = audio_words(`./${word.key}/${idx}.ogg`)
					}
				} catch (error) {
					console.log(`not found: audio_words: ${word.key}.ogg`)
					audioHint = null
				}

				return {
					id: idx + 1,
					name: option,
					dragClass: " dragFlexContent",
					match: `drop${idx + 1}`,
					// url: letterToSound[letter],
					font: "content0",
					image: image,
					audioHint: audioHint,
					isSinking: false,
				}
			})

			const dropItems = {
				id: 1,
				match: "drop1",
				name: "",
				dropClass: `dropFlexContent letter-${word.options[0].letter}`,
				enable: "",
				font: "content0",
			}
			let incentiveImageSrc, audioHintSrc
			if (index === 3) {
				incentiveImageSrc = images(`./level2/${word.key}.png`)
				audioHintSrc = audio_words(`./level2/${word.key}/hint.ogg`)
			} else {
				incentiveImageSrc = images(`./${word.key}.png`)
				audioHintSrc = audio_words(`./${word.key}/hint.ogg`)
			}
			return {
				level: index - 1,
				word: word.word,
				dragClass: "dragFlex",
				dropClass: "dropFlex",
				incentiveImage: [
					{
						src: incentiveImageSrc,
						// incentivesrc: images(`./${word.key}.gif`),
						incentivesrc: incentiveImageSrc,
						class: "incentiveImg",
						imgdiv: "imageCover incentiveDiv",
						text: word.word,
						textClass: "fz-5 incentiveText",
					},
					{
						src: images("./volume.svg"),
						incentivesrc: images("./volume.svg"),
						imgdiv: "audioImg imageCover",
						text: word.word,
						textClass: "fz-5 incentiveText",
						// url: audio_words(`./${word.key}.ogg`)
					},
				],
				imgClass: "dragImg",
				audioHint: audioHintSrc,
				dragItems: shuffle(dragItems),
				dropItems: dropItems,
				question: question,
			}
		})
	})
	.reduce((prev = [], current) => {
		return prev.concat(current)
	})

export default finalObject
