const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background6.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./bisc.png"),
			class: "image-one imageCenter element1 imgSize imgbisc",
			imgdiv: "imageCenterDiv _mt-6 _h-40",
		},
		{
			src: images("./leaf.png"),
			class: "image-one imageCenter element2 imgSize imgleaf",
			imgdiv: "imageCenterDiv",
		},

		{
			src: images("./mango_un.png"),
			class: "image-one imageCenter element3 imgSize imgmango_un",
			imgdiv: "imageCenterDiv h-65 _t-20",
		},
		{
			src: images("./mango.gif"),
			class: "image-one imageCenter element4 imgSize imgmango",
			imgdiv: "imageCenterDiv h-65 _t-20",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "बिस्कुट जस्तै मेरो बियाँ छ।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.4 }, { start: 0.8 }, { start: 1.4 }, { start: 1.9, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "पात जस्तै कहिले पहेँलो र कहिले हरियो देख्‍ने गर्छु ।",
					textClass: "m-0",
					timestamps: [
						{ start: 9 },
						{ start: 9.6 },
						{ start: 10.5 },
						{ start: 11.04 },
						{ start: 11.5 },
						{ start: 12 },
						{ start: 12.4 },
						{ start: 12.9 },
						{ start: 13.1, last: true },
					],
				},
			],
		},

		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 20 },
						{ start: 20.2 },
						{ start: 20.5 },
						{ start: 21.1 },
						{ start: 21.4 },
						{ start: 21.5 },
						{ start: 21.5, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म आँप हुँ।",
					textClass: "m-0",
					timestamps: [{ start: 31 }, { start: 31.4 }, { start: 31.9, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p6a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p6a2.ogg"),
			delay: 13000,
		},
		{
			url: audios("./p6a3.wav"),
			delay: 24000,
		},
		{
			url: audios("./p6a4.wav"),
			delay: 35000,
		},
	],
}

export default content
