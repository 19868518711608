import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	images: [
		{
			src: images("./6-7/bg_hill.png"),
		},
		{
			src: images("./boy_dance_01.gif"),
			class: "image-five boydancing ",
			imgdiv: "imageCover boy_dance",
		},
		{
			src: images("./boy_dance_nt.gif"),
			class: "image-five boydancing ",
			imgdiv: "imageCover boy_dance_nt",
		},
		{
			src: images("./6-7/bg_grass.png"),
		},
		// {
		// 	src: images('./_.png'),
		// 	imgdiv: 'imageCover boyDancingPlay d-none',
		// 	styles: {
		// 		background: `url(${images('./6-7/boy_dancing.png')}) 0px center / cover no-repeat`,
		// 	},
		// },
		{
			src: images("./6-7/page_7girl.png"),
			styles: {
				top: "51%",
				left: "74%",
				width: "22%",
			},
		},
		{
			src: images("./6-7/cloud03.png"),
			imgdiv: "imageCover cloudsAnimC",
			styles: {
				width: "62%",
			},
		},
		{
			src: images("./6-7/cloud04.png"),
			imgdiv: "imageCover cloudsAnimA",
		},
		{
			src: images("./plane.png"),
			class: "image-five plane",
			imgdiv: "imageCover moveright1",
		},
		{
			src: images("./6-7/cloud05.png"),
			imgdiv: "imageCover cloudsAnimB",
			styles: {
				width: "62%",
			},
		},

		{
			src: images("./6-7/page7-grass.png"),
			imgdiv: "imageCover grassMoveAnimB",
		},
	],
	texts: [
		{
			textDivClass: "story-text page7Text dialogueBubble topRightBubble text-center",
			textIndivData: [
				{
					textData: "म यहाँ भीर पाखाहरूमा कस्तो मज्‍जाले उड्‍न पाइरहेको छु।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.9 },
						{ start: 1.3 },
						{ start: 2.0 },
						{ start: 2.5 },
						{ start: 3.4 },
						{ start: 3.8 },
						{ start: 4.3 },
						{ start: 5.1 },
						{ start: 5.9 },
					],
				},
				{
					textData: "म किन स्कूल जाने र?",
					textClass: "m-0",
					timestamps: [{ start: 6.0 }, { start: 6.4 }, { start: 6.8 }, { start: 7.1 }, { start: 7.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6.ogg"),
			delay: 500,
		},
		{
			url: audios("./a6.ogg"),
			delay: 300,
			isBg: true,
			loop: false,
			volume: 0.5,
		},
	],
}

export default content
