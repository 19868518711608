import React from "react"

import normalIcon from "../icons/controls/pause_blue.svg"
import normalplayIcon from "../icons/controls/play_blue.svg"

const PlayPauseBtn = (props) => {
	return (
		<span key="PlayPauseBtn" onClick={props.playPauseBtnClick} className={"prev__btn"}>
			<img src={props.disabled ? normalplayIcon : normalIcon} className="img-fluid" alt="refresh" />
		</span>
	)
}

export default PlayPauseBtn
