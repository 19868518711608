const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./wavingBook/page18a.png"),
			class: "image-one background-p18 imgbox-lg",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./wavingBook/page18_kopila.png"),
			class: "image-one kopila-p18",
			imgdiv: "imageCover",
		},
		{
			src: images("./wavingBook/page18_hand.png"),
			class: "image-one hand-p18",
			imgdiv: "imageCover",
		},
		{
			src: images("./wavingBook/t_shirt.png"),
			class: "image-one tshirt-p18",
			imgdiv: "imageCover",
		},
		{
			src: images("./star.png"),
			class: "image-one star star1",
			imgdiv: "imageCover",
		},
		{
			src: images("./star.png"),
			class: "image-one star star2",
			imgdiv: "imageCover",
		},
		{
			src: images("./star.png"),
			class: "image-one star star3",
			imgdiv: "imageCover",
		},
		{
			src: images("./outline-lg-white.png"),
			class: "image-one outline-p18",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p18 textbox textbox-ban",
			textIndivData: [
				{
					textData: '"हेर त आमा, अब त म पनि स्कूल जाने।"',
					textClass: "fz-5 m-0 one-line text-p18a textStyles",
					timestamps: [
						{ start: 0 },
						{ start: 0.85 },
						{ start: 0.95 },
						{ start: 1.75 },
						{ start: 2 },
						{ start: 2.1 },
						{ start: 2.3 },
						{ start: 2.6 },
						{ start: 3 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./soundEffect/pianoMonkey.ogg"),
			isBg: true,
			loop: false,
			volume: 0.5,
		},
		{
			url: audios("./s18.ogg"),
			delay: 3000,
		},
	],
}

export default content
