import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

//Title Page
const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./cover-page.jpg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./standing_nima01.png"),
			imgdiv: "imageCover standing_nima-p1",
		},
		{
			src: images("./ramaelomela_text.svg"),
			toSvg: true,
			imgdiv: "imageCover",
			styles: {
				width: "38%",
				left: "54%",
				top: "39%",
			},
		},
	],
	texts: [],
	sounds: [
		{
			url: audios("./title.ogg"),
			delay: 0,
		},
		{
			url: audios("./blank.ogg"),
			delay: 3500,
		},
	],
}

export default content
