const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky-yellow",
	images: [
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloud-1",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloud-2",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloud-4",
		},
		{
			src: images("./13to24/flying.png"),
			imgdiv: "imageCover",
			styles: { width: "75%", top: "20%", left: "50%", transform: "translateX(-50%)" },
		},
		{
			src: images("./13to24/duckwithstick.png"),
			imgdiv: "imageCover",
			styles: { width: "75%", top: "20%", left: "50%", transform: "translateX(-50%)" },
		},
		{
			src: images("./13to24/fall.gif"),
			imgdiv: "imageCover d-none",
			styles: { width: "10%", top: "22%", left: "50%", transform: "translateX(-50%)" },
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "त्यसरी कराउँदा उसको मुख हाँगाबाट छुट्‍यो। कछुवा तल खस्‍न थाल्यो।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.68 },
						{ start: 1.5 },
						{ start: 2.1 },
						{ start: 2.6 },
						{ start: 3.1 },
						{ start: 4.1 },
						{ start: 4.7 },
						{ start: 5.1 },
						{ start: 5.4 },
						{ start: 6 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p5_s0.ogg"),
			delay: 1500,
		},
	],
}

export default content
