import { useEffect, useContext } from "react"
import { DispatchContext } from "../../../../DecodingGame"
import { Howl } from "howler"
/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import gsap from "gsap"
//images
import Sabina1 from "./images/sabina_namastee.gif"
import Sabina1Png from "./images/sabina_namastee.png"
import Sabina2 from "./images/sabina_curios.png"

const Intro = () => {
	const dispatch = useContext(DispatchContext)

	useEffect(() => {
		const soundAssets = require.context("./sound")
		const NamasteHowl = new Howl({
			src: [soundAssets("./namaste.ogg")],
		})
		const KRaiXaHowl = new Howl({
			src: [soundAssets("./k_rahe_xa.ogg")],
		})

		NamasteHowl.play()
		NamasteHowl.on("end", () => {
			gsap.set("#sabina1Png", { opacity: 1 })
			gsap.set("#sabina1", { opacity: 0 })
			gsap.to("#sabina1Png", { opacity: 0, duration: 1 })
			gsap.to("#sabina2", {
				opacity: 1,
				duration: 1,
				onComplete: () => {
					KRaiXaHowl.play()
					KRaiXaHowl.on("end", () => {
						dispatch({
							type: "START_PLAY",
						})
					})
				},
			})
		})
	}, [dispatch])
	return (
		<div className="game-stage-dg" style={{ height: "91%", overflow: "hidden", position: "relative" }}>
			<div
				style={{
					backgroundImage: "linear-gradient(white, #DEF8C5)",
					width: "100%",
					height: "100%",
					overflow: "hidden",
				}}
				id="decoder-stage1"
				className="container-fluid"
			>
				<img
					src={Sabina1}
					id="sabina1"
					alt="sabina"
					css={css`
						position: absolute;
						width: 16%;
						top: 12%;
						left: 10%;
					`}
				/>
				<img
					src={Sabina1Png}
					id="sabina1Png"
					alt="sabina"
					css={css`
						position: absolute;
						width: 16%;
						top: 12%;
						left: 10%;
						opacity: 0;
					`}
				/>
				<img
					src={Sabina2}
					id="sabina2"
					alt="sabina Namaste"
					css={css`
						position: absolute;
						width: 19%;
						top: 11%;
						left: 72%;
						opacity: 0;
					`}
				/>
			</div>
		</div>
	)
}

export default Intro
