// import { jsx } from "@emotion/core"
// import _, { delay, object } from "underscore"
import React, {useContext, useEffect, useState} from "react"
import {Howl, Howler} from "howler"
import { StateContext, DispatchContext } from "../../ShapeColorGame"
import "./scenecss/scene3.styles.scss"
// import { sleep } from "../../utils"
import Options from "../Options/Options"
import OptionsSecond from "../Options/OptionsSecond"
import gsap from "gsap"
import mainTextAnimation from "../../../Common/TextAnimations"
// import { counter } from "@fortawesome/fontawesome-svg-core"
// import textHighLight from "../../../Common/TextHighLightAnime"
// import { faBorderNone } from "@fortawesome/free-solid-svg-icons"
// const images = require.context("../images", true)
const audios = require.context("./dailog/", true)

const Scene = (({stageData, assets, story})=>{
        const state = useContext(StateContext)                /* importing statecontext from shapecolorgame and initializiing at state */
        const dispatch = useContext(DispatchContext)         /* importing statecontext from shapecolorgame and initializiing at state */
        let girlRef = React.useRef(null);                    /* initializing girlrif as reference which directly change in file  */
        let robotRef = React.useRef(null);
        let daiRef = React.useRef(null);
        let speechboxdivRef = React.useRef(null);
        let gadaRef = React.useRef(null);
        let speechboxRef = React.useRef([]);
        // console.log(story);
const initial = {
        pageup: 0,
        audio: false,
        //    QuestionShow: 0,
        QnAnsShow: false,
        secQNAnsShow: false,
        dailogCounter: 0,
        disable: true,
        disableSec: false,
        disableThird: true
}

const [stageState, setStageState] = useState(initial);
//    const [data, setdata] = useState(stageData);
   var timestamp = gsap.timeline({});                           /* creating timeline for gsap animation */
//    let bgMusic;
//    let dailogSound;
//    console.log(stageState)
   let content = {                                              /* all data are store in object which are used as par their requirement */
        texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 2% 8% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "समा, हिजो राति यहि ठाउँमा मेरो हात हराएको थियो ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }, { start: 1.15 }, { start: 1.70 }, { start: 2.00 }, { start: 2.50 }, { start: 2.75 }, { start: 3.10 },{ start: 3.55 },{ start: 3.80 }],
                                        delayTime: 10000,
                                        hideTimer: 15000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "त्यसोभए उता हुनुभएको दाइले देख्नुभएको छ कि ? आऊ सोधौँ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.70 }, { start: 1.10 }, { start: 1.95 }, { start: 2.60 }, { start: 3.50 }, { start: 3.70 }, { start: 4.20 }, { start: 5.10 }, { start: 5.60 }, { start: 5.70 }],
                                        delayTime: 16000,
                                        hideTimer: 25000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-3',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 2% 12% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "दाइ !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 37000,
                                        hideTimer: 40000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-4',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 12% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हजुर !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 41000,
                                        hideTimer: 44000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हिजो राति मेरो यहाँ हात हराएको थियो । हजुरले देख्नुभएको छ कि ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.30 }, { start: 0.70 }, { start: 1.00 }, { start: 1.25 }, { start: 1.50 }, { start: 2.00 }, { start: 2.40 }, { start: 2.50 }, { start: 3.30 }, { start: 3.90 }, { start: 4.10 }, { start: 4.80 }],
                                        delayTime: 45000,
                                        hideTimer: 52000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-6',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हात ? कुन आकारको थियो भन त भाइ ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }, { start: 0.70 }, { start: 1.40 }, { start: 2.00 }, { start: 2.30 }, { start: 2.60 }, { start: 2.80 }, { start: 3.00 }],
                                        delayTime: 53000,
                                        hideTimer: 58000
                                }
                        ]
                },
                // dailog after quention option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-7',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "मेरो हात आयत आकार थियो दाइ ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6600000,
                                        hideTimer: 6100000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-8',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "अनि आयत आकार कस्तो हुन्छ होला ? भन त भाइ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.25 }, { start: 1.60 }, { start: 1.90 }, { start: 2.40 }, { start: 2.80 }],
                                        delayTime: 6600000,
                                        hideTimer: 6800000
                                }
                        ]
                },
                // dailog after second option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-9',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "ए ! आयत आकार यस्तो पो हुँदो रहेछ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.25 }, { start: 1.60 }, { start: 1.90 }, { start: 2.40 }, { start: 2.80 }],
                                        delayTime: 7100000,
                                        hideTimer: 7200000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-10',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "मैले हिजो यो आकारको एउटा वस्तु भेटेको थिएँ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }],
                                        delayTime: 7300000,
                                        hideTimer: 7400000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-11',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हो र दाइ ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }],
                                        delayTime: 7500000,
                                        hideTimer: 7600000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-12',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "यो आयत तिम्रो हात हो फुर्बा ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.50 }, { start: 2.90 }],
                                        delayTime: 7700000,
                                        hideTimer: 7800000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-13',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हो दाइ । यही मेरो हात हो ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.50 }, { start: 2.90 }],
                                        delayTime: 7900000,
                                        hideTimer: 8000000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-14',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "यो लिऊ तिम्रो हात ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 8100000,
                                        hideTimer: 8200000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-15',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "धन्यवाद दाइ । मैले अब दुवै हात चलाउन सक्छु ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.50 }, { start: 2.90 }],
                                        delayTime: 8300000,
                                        hideTimer: 8400000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-16',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "अब हामी तिम्रो खुट्टा खोज्न जाऔँ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 8500000,
                                        hideTimer: 8600000,
                                }
                        ]
                },
                
        ],
        sounds: 
		[{
			url: audios("./page3_furba1.wav"),
			delay: 10000,
		},{
                        url: audios("./page3_sama1.wav"),
			delay: 16000,
                },{
                        url: audios("./page3_sama2.wav"),
			delay: 37000,
                },{
                        url: audios("./fishseller1.wav"),
			delay: 41000,
                },{
                        url: audios("./page3_furba2.wav"),
			delay: 45000,
                },{
                        url: audios("./fishseller2.wav"),
			delay: 53000,
                }],
}
        let content1 = {
                texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-7',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "मेरो हात आयत आकार थियो दाइ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.80 }, { start: 1.40 }, { start: 2.00 }, { start: 2.25 }, { start: 2.60 }],
                                        delayTime: 1000,
                                        hideTimer: 5000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'scene3_sp-8',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 10,
                                        textData: "अनि आयत आकार कस्तो हुन्छ होला ? भन त भाइ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.30 }, { start: 0.60 }, { start: 1.00 }, { start: 1.40 }, { start: 1.70 }, { start: 2.30 }, { start: 2.40 }, { start: 2.85 }, { start: 3.10 }, { start: 3.30 }],
                                        delayTime: 6000,
                                        hideTimer: 11000
                                }
                        ]
                },
                ],
                sounds:[
                        {
                                url: audios("./page3_furba3.wav"),
			        delay: 1000,
                        },
                        {
                                url: audios("./fishseller3.wav"),
			        delay: 6000,
                        }
                ]
        }

        let content2 = {
                texts: [
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'scene3_sp-9',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "ए ! आयत आकार यस्तो पो हुँदो रहेछ ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 0.60 }, { start: 1.15 }, { start: 1.60 }, { start: 2.00 },{ start: 2.20 }, { start: 2.50 }, { start: 2.80 }],
                                                delayTime: 1000,
                                                hideTimer: 5000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'scene3_sp-10',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "मैले हिजो यो आकारको एउटा वस्तु भेटेको थिएँ ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 0.90 }, { start: 1.45 }, { start: 1.90 }, { start: 2.30 }, { start: 2.60 }, { start: 3.10 }, { start: 3.50 }],
                                                delayTime: 6000,
                                                hideTimer: 11000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'scene3_sp-11',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "हो र दाइ ?",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.25 }, { start: 0.40 }, { start: 0.70 }],
                                                delayTime: 12000,
                                                hideTimer: 15000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'scene3_sp-12',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "यो आयत तिम्रो हात हो फुर्बा ?",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.20 }, { start: 0.55 }, { start: 0.90 }, { start: 1.10 }, { start: 1.40 }, { start: 1.70 }],
                                                delayTime: 16000,
                                                hideTimer: 20000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'scene3_sp-13',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "हो दाइ । यही मेरो हात हो ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.25 }, { start: 0.70 }, { start: 0.80 }, { start: 1.30 }, { start: 1.60 }, { start: 2.00 }, { start: 2.30 }],
                                                delayTime: 21000,
                                                hideTimer: 25000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'scene3_sp-14',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "यो लिऊ तिम्रो हात । ",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.30 },{ start: 0.50 }, { start: 0.85 }, { start: 1.10}],
                                                delayTime: 26000,
                                                hideTimer: 29000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'scene3_sp-15',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "धन्यवाद दाइ । मैले अब दुवै हात चलाउन सक्छु ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.10 }, { start: 1.20 }, { start: 1.90 }, { start: 2.30 }, { start: 2.85 }, { start: 3.25 }, { start: 3.85 },{ start: 4.00 }],
                                                delayTime: 36000,
                                                hideTimer: 42000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'scene3_sp-16',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "अब हामी तिम्रो खुट्टा खोज्न जाऔँ ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.80 }, { start: 1.20 }, { start: 1.60 }, { start: 2.10 }, { start: 2.50 }],
                                                delayTime: 45000,
                                                hideTimer: 50000,
                                        }
                                ]
                        },
                ],
                sounds:[
                        {
                                url: audios("./fishseller4.wav"),
			        delay: 1000,
                        },
                        {
                                url: audios("./fishseller5.wav"),
			        delay: 6000,
                        },
                        {
                                url: audios("./page3_furba4.wav"),
			        delay: 12000,
                        },
                        {
                                url: audios("./fishseller6.wav"),
			        delay: 16000,
                        },
                        {
                                url: audios("./page3_furba5.wav"),
			        delay: 21000,
                        },
                        {
                                url: audios("./fishseller7.wav"),
			        delay: 26000,
                        },
                        {
                                url: audios("./page3_furba6.wav"),
			        delay: 36000,
                        },
                        {
                                url: audios("./page3_sama3.wav"),
			        delay: 45000,
                        },
                ]
        }
        // console.log(story);
   useEffect(()=>{                                          /* these effect is use for gsp animation of each and every object */
//    console.log(girlRef, girlRef.current.children);
        if(stageState.dailogCounter !== 9 && stageState.dailogCounter !== 13){
                console.log(stageState.dailogCounter + " " + stageState.dailogCounter)
                timestamp.to(girlRef.current, {
                        duration: 7,
                        x: "130%",
                        ease: "power1.in",
                        delay: 1,
                        onComplete: ()=>{
                                girlRef.current.src = stageData.girlLooking
                                
                        }
                        })
                        .to(robotRef.current, {
                                duration : 7,
                                // delay: 1,
                                x: "65%",
                                ease: "power1.in",
                                onComplete: ()=>{
                                        console.log("completed") ;
                                        girlRef.current.style.transform = "translate(130%, 0%) rotateY(180deg)"
                                        robotRef.current.src = stageData.robotTalkGif
                                }
                        }, "-=5")
                        .to(girlRef.current,{
                                delay: 15,
                                onComplete: ()=>{
                                        girlRef.current.style.transform = "translate(130%, 0%) rotateY(0deg)"
                                        girlRef.current.src = stageData.girlMoveGif
                                        robotRef.current.src = stageData.robotGif
                                }
                        })
                        .to(girlRef.current,{
                                duration: 9,
                                x: "315%",
                                onComplete: ()=>{
                                        // girlRef.current.style.transform = "translate(220%, 0%) rotateY(0deg)"
                                        girlRef.current.src = stageData.girlLooking
                                }
                        })
                        .to(robotRef.current,{
                                duration: 9,
                                x: "190%",
                                onComplete: ()=>{
                                        // girlRef.current.style.transform = "translate(220%, 0%) rotateY(0deg)"
                                        robotRef.current.src = stageData.robotTalkGif
                                }
                        },"-=8")
        }else{

        }                
}, [girlRef.current, robotRef.current, speechboxRef.current])

   useEffect(()=> {                                           /* this useEffect is uesd for background music play */
        if(!stageState.audio){
                        setStageState({...stageState, audio:true})
                        const bgMusic = new Howl({
                                src: [content.sounds[stageState.pageup].url],
                                autoplay: false,
                                preload: true,
                                volume: 0.07,
                                loop: true,
                                // onend: async () => {
                                //         // if (stageData.id === 10) {
                                //         //         setOptionState({ ...optionState, finalStageQ: stageData.questioner })
                                //         //         dispatch({ type: "LAST_PAGE_COMPLETE" })
                                //         // }
                                //         // await sleep(2000)
                                //         // await playAnimalSound()
                                // },
                        })
                        // console.log(content.sounds[stageState.pageup].url)
                        bgMusic.play()
                        return () => {
                                bgMusic.unload()
                                Howler.unload()
                        }
                }      
        },[stageState.audio, stageData.bgMusic, content.sounds]);

        let textList = content.texts;
        let textListB;
        let soundList;
        let textListC;
        let soundListC;
useEffect(()=>{                                      /* this useEffect is used for showing dailogbox highilighting text and play audio for each dailog*/
        let pagenumber  = 1;
        textList = content.texts;
        soundList = content.sounds;
        // console.log(stageData.disable)
        if(stageState.disable){
                let objCount = 1   
                let timeouts = []
                let timestams = []
                let textIndivData = []
                let delaytime = []
                let hideTime = []   
                let showdata;
                //    console.log(textList);
                if( textList?.length > 0){
                        textList.forEach((item) => {
                                textIndivData = [...textIndivData, ...item.textIndivData]
                        })
                        textIndivData.forEach((data) =>{
                                // console.log(data)
                                
                                if(data.timestamps){
                                        timestams = [...timestams, ...data.timestamps];
                                        // textHighLight(data.textdata, data.timestamps)
                                }else{
                                        timestams = null
                                }
                                
                                if(data.delayTime){
                                        delaytime.push(
                                                setTimeout(()=>{
                                                showdata  = document.getElementsByClassName('scene3_sp-'+ objCount);
                                                        // console.log(delaytime);
                                                        gsap.to(showdata, {
                                                                scale: 1,
                                                                ease: "power1.in",
                                                                display: "block",
                                                                onComplete: () => {
                                                                        if(objCount === 2) {
                                                                                // robotRef.current.src = stageData.robotTopiPng;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount === 3) {
                                                                                robotRef.current.src = stageData.robotTopiPng;
                                                                                girlRef.current.src = stageData.girlTalkGif;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount === 4) {
                                                                                robotRef.current.src = stageData.robotTopiPng;
                                                                                girlRef.current.src = stageData.girlTalkGif;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount === 5) {
                                                                                robotRef.current.src = stageData.robotTopiPng;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiTalking;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount === 6) {
                                                                                robotRef.current.src = stageData.robotTalkGif;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiPng;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount === 7) {
                                                                                robotRef.current.src = stageData.robotTopiPng;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiTalking;
                                                                                console.log(objCount)
                                                                        }
                                                                }      
                                                        })
                                                
                                                        
                                                        let textAnimations;
                                                        import(`../../Animations`).then((customAnims) =>{
                                                                let animationObj = new customAnims.default()
                                                                textAnimations = animationObj.runTextAnimations
                                                                // textAnimations = mainTextAnimation
                                                                if(animationObj.runTextAnimations){
                                                                        animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        objCount ++ ;
                                                                        // animationObj.forEach(() =>{
                                                                        //         animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        // })
                                                                }
                                                                // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                        })
                                                        .catch((error) =>{
                                                                if(story.defaultTextAnimation){
                                                                        textAnimations = mainTextAnimation
                                                                }else{
                                                                        textAnimations = () => {}
                                                                }
                                                        })
                                                },data.delayTime)
                                        )
                                }else{
                                        delaytime = null
                                }
                                if(data.hideTimer){
                                        hideTime.push(
                                                setTimeout(() => {
                                                        showdata  = document.getElementsByClassName('scene3_sp-'+ (objCount-1));
                                                        // showdata[0].style.display = 'none';
                                                        gsap.to(showdata, {
                                                                scale: 0.1,
                                                                ease: "power1.in",
                                                                delay: 1,
                                                                display: "none",
                                                                onComplete: () => {
                                                                                // robotRef.current.src = stageData.robotLookPng;
                                                                                // girlRef.current.src = stageData.girlLooking;
                                                                        
                                                                }
                                                        })
                                                        if(objCount === 7){
                                                                        setStageState({...stageState, disable:false, secQNAnsShow:false, QnAnsShow:true});   
                                                        }
                                                }, data.hideTimer)
                                        )
                                }
                        })
                }else{
                        timestams = null
                }
                if(soundList && soundList.length){
                        soundList.forEach((sound) => {
                                // console.log(sound)
                                let audio = new Howl({
                                        src: [sound.url],
                                        preload: true,
                                        autoplay:false,
                                        loop: sound.loop ?? false,
                                        volume: sound.volume ?? 0.7,
                                })
                                if(sound.delay){
                                        timeouts.push(
                                                setTimeout(()=>{
                                                        audio.play();
                                                        // content.texts.textMainDivClass.style.display = 'block'
                                                },sound.delay)
                                        )
                                }else{
                                        // audio.unload()
                                }
                        })
                }
                return () => {
                        // if(audio) audio.unload()
                        // Howler.unload()
                        timeouts.forEach((timeout) =>{
                                clearTimeout(timeout)
                                // Howler.unload()
                        })
                        delaytime.forEach((timeout) => {
                                clearTimeout(timeout);
                        })
                        gsap.killTweensOf("*")
                        // console.log("last return")
                }
        }
},[stageData.disable, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData])

useEffect(()=> {            /* this useeffect is used when user click the right answer*/
        if(stageState.dailogCounter === 9){
                if(stageState.disableSec){
                        textListB = content1.texts;
                        soundList = content1.sounds;
                        let textIndivData = []
                        let timestamsSec = []
                        let timeouts = []
                        let speechCount = 7;
                        let delaytime = []
                        let hideTime = []
                        let showdata;
                        // console.log(stageState.disable)
                        robotRef.current.classList.add("robot_afterAnsCorrect_scene3");
                        girlRef.current.classList.add("girl_afterAnsCorrect_scene3");
                        if(robotRef.current){
                                robotRef.current.src = stageData.robotTalkGif  
                        }
                        if(girlRef.current){
                                girlRef.current.src = stageData.girlLooking
                        }
                        if( textListB?.length > 0){
                                textListB.forEach((item) => {
                                        textIndivData = [...textIndivData, ...item.textIndivData]
                                })
                                textIndivData.forEach((data) =>{
                                        
                                        if(data.timestamps){
                                                timestamsSec = [...timestamsSec, ...data.timestamps];
                                        }else{
                                                timestamsSec = null
                                        }
                                        
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('scene3_sp-'+ speechCount);
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCount === 8){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotTalkGif;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 9){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotTopiPng;
                                                                                        daiRef.current.src = stageData.daiTalking;
                                                                                        console.log(speechCount);
                                                                                }
                                                                        }      
                                                                });
                                                                let textAnimations1;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations1 = animationObj.runTextAnimations
                                                                        if(animationObj.runTextAnimations){
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCount-1], timestamsSec)
                                                                                speechCount ++ ;
                                                                        }
                                                                        // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations1 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations1 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('scene3_sp-'+ (speechCount-1));
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {                                
                                                                        }
                                                                })
                                                                if(speechCount == 9){
                                                                        setStageState({...stageState, dailogCounter:13, secQnAnsShow:true});
                                                                        console.log(stageState.dailogCounter)
                                                                        speechboxdivRef.current.style.display = "none";
                                                                        showdata[0].style.display = 'none';
                                                                } 
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsSec = null;
                        }
                        if(soundList && soundList.length){
                                soundList.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                        return () => {
                                // if(audio) audio.unload()
                                // Howler.unload()
                                timeouts.forEach((timeout) =>{
                                        clearTimeout(timeout)
                                        // Howler.unload()
                                })
                                delaytime.forEach((timeout) => {
                                        clearTimeout(timeout);
                                })
                                gsap.killTweensOf("*")
                                // console.log("last return")
                        }
                }
        }
        if(stageState.dailogCounter === 14){
                if(stageState.disableThird){
                        speechboxdivRef.current.style.display = "block";
                        textListC = content2.texts;
                        soundListC = content2.sounds;
                        let textIndivDataC = [];
                        let timestamsThird = [];
                        let timeouts = [];
                        let speechCountThird = 9;
                        let delaytime = [];
                        let hideTime= [];
                        let showdata;
                        if( textListC?.length > 0){
                                textListC.forEach((item) => {
                                        textIndivDataC = [...textIndivDataC, ...item.textIndivData]
                                        // console.log(textIndivDataC)
                                })
                                textIndivDataC.forEach((data) =>{
                                        if(data.timestamps){
                                                timestamsThird = [...timestamsThird, ...data.timestamps];
                                        }else{
                                                timestamsThird = null
                                        }
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('scene3_sp-'+ (speechCountThird));
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCountThird === 12){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotTalkGif;
                                                                                        daiRef.current.src = stageData.daiPng;
                                                                                        console.log(speechCountThird);
                                                                                }
                                                                                else if(speechCountThird === 13){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotTopiPng;
                                                                                        daiRef.current.src = stageData.daiTalking;
                                                                                        console.log(speechCountThird);
                                                                                }
                                                                                else if(speechCountThird === 14){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotTalkGif;
                                                                                        daiRef.current.src = stageData.daiPng;
                                                                                        console.log(speechCountThird);
                                                                                }
                                                                                else if(speechCountThird == 15) {
                                                                                        robotRef.current.src = stageData.robotGif;
                                                                                        daiRef.current.src = stageData.daiGivingHand;
                                                                                        gadaRef.current.src = stageData.gada2;
                                                                                        timestamp.to(robotRef.current, {
                                                                                                delay:2,
                                                                                                duration: 5,
                                                                                                x:"120%",
                                                                                                ease: "none",
                                                                                                onComplete: ()=>{
                                                                                                        robotRef.current.src = stageData.robotTopiPng; 
                                                                                                        // daiRef.current.src = stageData.daiGivingHand;
                                                                                                        console.log("Aa")
                                                                                                }
                                                                                        })
                                                                                        .to(robotRef.current, {
                                                                                                delay: 0.8,
                                                                                                x:"125%",
                                                                                                ease: "none",
                                                                                                onComplete: ()=>{
                                                                                                        daiRef.current.src = stageData.daiPng;
                                                                                                        robotRef.current.src = stageData.robotHandGif;  
                                                                                                        console.log("bb") 
                                                                                                }
                                                                                        })
                                                                                        .to(robotRef.current, {
                                                                                                // scaleX: -1,
                                                                                                delay: 7,
                                                                                                ease: "none",
                                                                                                onComplete: ()=>{
                                                                                                        robotRef.current.style.transform = "translate(100%) rotateY(0deg)";
                                                                                                        // robotRef.current.src = stageData.robotHandWalk;
                                                                                                        console.log("cc") 
                                                                                                }
                                                                                        })
                                                                                        .to(robotRef.current,{
                                                                                                duration: 3,
                                                                                                left: '17%',
                                                                                                // scaleX: 1,
                                                                                                ease:"none",
                                                                                                onComplete: ()=>{
                                                                                                        // robotRef.current.style.transform = "translate(100%) rotateY(0deg) !important"
                                                                                                        console.log("dd") 
                                                                                                }
                                                                                        })
                                                                                }
                                                                                else if(speechCountThird === 16){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotHandGif;
                                                                                        daiRef.current.src = stageData.daiPng;
                                                                                        console.log(speechCountThird);
                                                                                }
                                                                                else if(speechCountThird === 17){
                                                                                        girlRef.current.src = stageData.girlTalkGif;
                                                                                        robotRef.current.src = stageData.robotHandPng;
                                                                                        daiRef.current.src = stageData.daiPng;
                                                                                        console.log(speechCountThird);
                                                                                        // setStageState({...stageState, disableThird:false})
                                                                                }
                                                                        }      
                                                                });

                                                                let textAnimations2;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations2 = animationObj.runTextAnimations
                                                                        
                                                                        if(animationObj.runTextAnimations){
                                                                                // console.log(speechCountThird)
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCountThird-1], timestamsThird)
                                                                                speechCountThird ++ ;
                                                                                // console.log(speechCountThird)
                                                                        }
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations2 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations2 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('scene3_sp-'+ (speechCountThird-1));
                                                                // showdata[0].style.display = 'none';
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {
                                                                                // if(speechCountThird == 16){
                                                                                //         console.log("16")
                                                                                // }
                                                                        }
                                                                })
                                                                if(speechCountThird === 17){
                                                                        dispatch({type: "LAST_PAGE_COMPLETE"})
                                                                        setStageState({...stageState, disableThird:false})
                                                                        console.log(state.rewardComplete)
                                                                        console.log(stageState.disable+" "+stageState.disableSec+" "+ stageState.disableThird)
                                                                }
                                                                
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsThird = null;
                        }
                        if(soundListC && soundListC.length){
                                soundListC.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                        return () => {
                                // if(audio) audio.unload()
                                // Howler.unload()
                                timeouts.forEach((timeout) =>{
                                        clearTimeout(timeout)
                                        // Howler.unload()
                                })
                                delaytime.forEach((timeout) => {
                                        clearTimeout(timeout);
                                })
                                gsap.killTweensOf("*")
                                // console.log("last return")
                        }
                }
        }
},[stageData.disable,stageData.disableSec, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData, speechboxdivRef.current]);

const answerSound = (isRight = false) => {
        new Howl({
                src: [isRight ? state.audios[1] : state.audios[0]],
                autoplay: true,
                loop: false,
                volume: 0.03,
        })
}

const answerClick = (e, index) => {
        const check = e.target.className.split(" ")[1];
        if(e.target.id === "rightAns"){
                answerSound(true);
                var parentDiv = e.target.parentNode;
                var previous = e.target.previousSibling;
                // var next = e.target.nextSibling;   
                //    console.log(e.target);
                if(previous){
                        // next.parentNode.removeChild(next);
                        previous.style.opacity = 0
                }
                if(parentDiv.lastElementChild){
                        // parentDiv.removeChild(parentDiv.lastChild);
                        parentDiv.lastChild.style.opacity = 0
                }
                
                e.target.style.backgroundColor = "#c1ebb3";
                gsap.to("#"+e.target.id,3,{
                        x:"0%",
                        scale: 1.1,
                        onComplete:() => {
                                setStageState({...stageState, dailogCounter:9, QnAnsShow:false, disableSec: true});
                        }
                })
        }
        else{
                e.target.style.backgroundColor = "#f4cccc";
                answerSound(false);
        }
        //    console.log(!parentDiv.firstChild)
}

const SecondanswerClick = (e, index) => {
        const check = e.target.className.split(" ")[2];
        if(e.target.id === "correct"){
                answerSound(true);
                var previous = e.target.nextSibling;
                var parentDiv = e.target.parentNode;
                var siblingDiv = parentDiv.nextSibling.id;
                // console.log(siblingDiv);
                if(previous) previous.style.opacity = 0
                if(parentDiv.lastElementChild) parentDiv.lastChild.style.opacity = 0
                e.target.style.backgroundColor = "#c1ebb3";
                gsap.to("#"+e.target.id,3,{
                        x:"135%",
                        onComplete:() => {
                                // check.pointerEvents = "none";
                        //      setStageState({...stageState, dailogCounter:9, QnAnsShow:false});
                        }
                })
                gsap.to('#'+siblingDiv, 3, {
                        delay: 3,
                        // display: "block",
                        css:{opacity:1},
                        onComplete: () => {
                                setStageState({...stageState, dailogCounter:14, secQnAnsShow:false});
                        }
                })
        }
        else{
                e.target.style.backgroundColor = "#f4cccc";
                answerSound(false);
        }
}

        return (
                <div className="imageContainer">
                {
                        stageState.QnAnsShow === true ? (
			<Options
			answerClick={answerClick}
			assets={assets}
			stageData={stageData}
			/>
			):  
                        <div className="speechContainer" ref={speechboxdivRef}>
                        <img src={stageData.girlMoveGif} className="girl_walk1" ref={girlRef} 
                        />
                        <img src ={stageData.robotGif} className="robot1" ref={robotRef} />
                        <img src ={stageData.daiPng} className="fishman" ref={daiRef} />
                        <img src ={stageData.gada} className="gada" ref={gadaRef} />

                                {textList.length ? (
                                        textList.map((textContent, index) => {
                                                const textStyles = textContent.styles ?? {}
                                                // console.log(textStyles);
                                                const maindiv = textContent.textMainDivClass;
                                                const className = `${textContent.textDivClass} textContentDiv`
                                                return(
                                                        <div key={index} className={`speechbox_p3 ${maindiv}`} ref={ref => {speechboxRef.current[index] = ref;}}> 
                                                        <img src ={stageData.bubble} className="box" /> 
                                                        <div className={className} style ={ textStyles }>
                                                                {textContent.textIndivData.map((text, index) => (
                                                                        <p key={index} id="dailog" className={text.textClass} >
                                                                                {text.textData}
                                                                        </p>
                                                                ))}
                                                        </div>
                                                        </div>
                                                )
                                        })
                                ) : ( 
                                        <div></div>
                                )
                        }

                        </div>
                }
                {stageState.dailogCounter === 13 && (
                        <OptionsSecond 
                        assets={assets}
			stageData={stageData}
                        SecondanswerClick={SecondanswerClick}
                        />
                        )
                        }
                </div>        
        )
        
});

export default Scene;

