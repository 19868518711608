import React, { useReducer, useEffect } from "react"
import _ from "underscore"

import Stage from "./pages/Stage/Stage"

// styles
import "./game.styles.scss"

// helpers
import { getRandomInt, reducer, getLevelData } from "./helpers/helpers"
import { getQueryVariable } from "../mainHelper"
import shuffle from "shuffle-array"

import loaderSvg from "../../../assets/ball-triangle.svg"
import bg_img_1 from "./pages/Stage/assets/bg01.png"
import bg_img_2 from "./pages/Stage/assets/bg02.png"
import bg_img_3 from "./pages/Stage/assets/bg03.png"
import bg_img_4 from "./pages/Stage/assets/bg04.png"
import bg_img_5 from "./pages/Stage/assets/bg05.png"

import bg_happy_place from "./content/audios/sound_effects/happy_place.ogg"
import { Howl } from "howler"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

export const DispatchContext = React.createContext(null)
export const StateContext = React.createContext(null)

const BalloonPop = (props) => {
	const settings = { audioHints: true }

	const useWindowSize = () => {
		const isClient = typeof window === "object"

		const getSize = () => {
			let width = window.innerWidth <= 1600 ? window.innerWidth : 1600
			let height = window.innerHeight
			let availableHeight = window.innerHeight - 56

			let adjustedHeight = availableHeight > (width * 9) / 16 ? (width * 9) / 16 : height - 56
			let adjustedwidth = (adjustedHeight * 16) / 9

			return {
				width: isClient ? Math.ceil(adjustedwidth) : undefined,
				height: isClient ? Math.ceil(adjustedHeight) : undefined,
			}
		}

		const windowSize = React.useRef(getSize())
		// resize hook
		React.useEffect(() => {
			const isClient = typeof window === "object"
			if (!isClient) {
				return false
			}

			const handleResize = _.debounce(() => {
				windowSize.current = getSize()
				dispatch({
					type: "RESET_STAGE",
					values: { windowSize: windowSize.current },
				})
			}, 500)

			window.addEventListener("resize", handleResize)
			return () => window.removeEventListener("resize", handleResize)
			// eslint-disable-next-line
		}, [])
		// Empty array ensures that effect is only run on mount and unmount

		return windowSize.current
	}

	// const levelData = getLevelData("Easy");X
	const [state, dispatch] = useReducer(reducer, {
		settings: settings,
		currentPage: "GAME_START",
		// currentPage: "START",
		level: getQueryVariable("level") || "Easy",
		stage: 0,
		levelData: [],
		loadingAssets: true,
		intro: true,
		playing: false,
		hoverHintPlaying: false,
		stageComplete: false,
		rewardComplete: false,
		levelComplete: false,
		bg: `bg0${getRandomInt(1, 5)}.png`,
		windowSize: useWindowSize(),
	})

	// console.log("state windowSize: ", state.windowSize);

	let windowSize = useWindowSize()
	useEffect(() => {
		const levelData = shuffle(getLevelData(state.level))
		dispatch({ type: "SET", values: { levelData: levelData } })
		// console.log(levelData, state);

		const bgMusic = new Howl({
			src: [bg_happy_place],
			autoplay: false,
			preload: true,
			volume: 0.15,
			loop: true,
			onload: () => {
				dispatch({ type: "LOADING_COMPLETE" })
				// console.log("audioLoaded");
			},
		})

		bgMusic.play()
		// console.log(props);

		return () => {
			bgMusic.unload()
		}
	}, [state.level])

	useEffect(() => {
		if (!localStorage.getItem("balloonPop")) {
			localStorage.setItem("balloonPop", JSON.stringify(state.settings))
		}

		const preloadImages = (urls, allImagesLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadImage = (url, anImageLoadedCallback) => {
				let img = new Image()
				img.onload = anImageLoadedCallback
				img.src = url
			}

			urls.forEach(function (url) {
				preloadImage(url, function () {
					loadedCounter++
					// console.log('Number of loaded images: ' + loadedCounter);
					if (loadedCounter === toBeLoadedNumber) {
						allImagesLoadedCallback()
					}
				})
			})
		}

		let images = [bg_img_1, bg_img_2, bg_img_3, bg_img_4, bg_img_5]
		if (state.levelData[state.stage] && state.levelData[state.stage + 1]) {
			images = images.concat([state.levelData[state.stage].hints.image, state.levelData[state.stage + 1].hints.image])
		}

		preloadImages(images, () => {
			// console.log("preloaded");
		})
	}, [state])

	return (
		<div
			className="game-container"
			id="game-container"
			key={windowSize.width}
			style={{
				width: `${windowSize.width}px`,
				height: `${windowSize.height}px`,
			}}
		>
			{state.loadingAssets ? (
				<div className="game-loading">
					<div className="loader-inner">
						<img src={loaderSvg} className="loader" alt="loader" />
						<span>Loading...</span>
					</div>
				</div>
			) : (
				<DispatchContext.Provider value={dispatch}>
					<StateContext.Provider value={state}>
						{state.levelData.length !== 0 && <Stage key={`stage-${state.level}-${state.stage}`} isOpenInFlutter={props.isOpenInFlutter} />}
						{(() => {
							// switch (state.currentPage) {
							// 	case "START":
							// 		return <StartPage />;
							// 	case "GAME_START":
							// 		return <Stage key={`stage-${state.level}-${state.stage}`} />;
							// 	default:
							// 		return null;
							// }
						})()}
					</StateContext.Provider>
				</DispatchContext.Provider>
			)}
		</div>
	)
}

BalloonPop.whyDidYouRender = true
export const BalloonPopMemo = React.memo(BalloonPop)
BalloonPopMemo.whyDidYouRender = true

export default BalloonPopMemo
// export default BalloonPop;
