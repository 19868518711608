import { disappear, appear, transformTo } from "styledElements/keyframes"
import { keyframes } from "@emotion/core"
import { seqAnimation } from "styledElements/helpers"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const close_eye = keyframes`
	0%, 89% {
		opacity: 0;
	}
	90%, 100% {
		opacity: 1;
	}
`
const open_eye = keyframes`
	0%, 89% {
		opacity: 1;
	}
	90%, 100% {
		opacity: 0;
	}
`
const content = {
	imageContainerClass: "bg-extended",
	imageContainerStyles: {
		transform: "scale(1.2)",
		transformOrigin: "92% 44%",
		animation: `${transformTo("scale(2) translate(2%,-12%)")} 3s 8s ease-in-out  forwards`,
	},
	images: [
		{
			src: images("./bg_cover01.png"),
		},
		{
			src: images("./bg_page50.png"),
			styles: {
				animation: `${disappear} 0.002s 10.5s forwards linear`,
			},
		},
		{
			src: images("./grand_mum_friend.svg"),
			toSvg: true,

			styles: {
				top: "16%",
				left: "-1%",
				width: "31%",
				animation: `${disappear} 1.5s 9s forwards linear`,

				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 2s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 2s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lips",
						numberOfFrames: 10,
						totalDuration: 1.9,
						initialDelay: 6,
						repeat: 1,
						delays: [0.01, 0.4, 0.5, 0.7, 1.0, 1.3, 1.5, 1.6, 1.7, 1.8],
					}),
				},
			},
		},
		{
			src: images("./grandmomKinaNakhako.svg"),
			toSvg: true,

			styles: {
				top: "16%",
				left: "27%",
				width: "39%",
				animation: `${disappear} 1.5s 9s forwards linear`,

				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 3.5s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 3.5s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lips",
						numberOfFrames: 9,
						totalDuration: 1.8,
						initialDelay: 3,
						repeat: 2,
						delays: [0.01, 0.4, 0.5, 0.7, 1.0, 1.3, 1.5, 1.6, 1.7],
					}),
				},
			},
		},
		{
			src: images("./zeya_folding_arm01.png"),
			styles: {
				top: "47%",
				left: "63%",
				width: "15%",
				animation: `${disappear} 0.15s 7s forwards linear`,
			},
		},
		{
			src: images("./zeya_cry01.png"),
			styles: {
				opacity: 0,
				top: "47%",
				left: "63%",
				width: "15%",
				animation: `${appear} 0.15s 7s forwards linear,
				${disappear} 0.002s 10.5s forwards linear
				`,
			},
		},
		{
			src: images("./drawing_copy.png"),
			class: "image-one",
			styles: {
				width: '13%',
				top: '82%',
				left: '49%',
				animation: `${disappear} 0.002s 10.5s forwards linear`,
			},
			imgDiv: "imageCover",
		},
		// {
		// 	src: images("./zeya_folding_arm02.png"),
		// 	styles: {
		// 		opacity: 0,
		// 		top: "47%",
		// 		left: "63%",
		// 		width: "15%",
		// 		animation: `${appear} 0.1s 2s forwards linear,
		// 		${disappear} 0.1s 4s forwards linear`,
		// 	},
		// },

		// {
		// 	src: images("./gifs/zeya_cry.gif"),
		// 	styles: {
		// 		opacity: 0,
		// 		top: "46%",
		// 		left: "62%",
		// 		width: "16%",
		// 		animation: `${appear} 0.1s 5.5s forwards linear,
		// 		${disappear} 0.1s 11s forwards linear`,
		// 	},
		// },
		// {
		// 	src: images("./zeya_cry.png"),
		// 	styles: {
		// 		opacity: 0,
		// 		top: "46%",
		// 		left: "62%",
		// 		width: "16%",
		// 		animation: `${appear} 0.1s 11s forwards linear`,
		// 	},
		// },
		{
			src: images("./muna_aunt.png"),
			styles: {
				top: "31%",
				left: "73%",
				width: "30%",
				animation: `${disappear} 1.5s 8s forwards linear`,
			},
		},
		{
			src: images("./sadZeya.png"),
			styles: {
				opacity: 0,
				animation: `${appear} 0.002s 10.5s forwards linear,
				${keyframes({
					from: {
						transform: "scale(1) translate(0%,0%) ",
					},
					to: {
						transform: " scale(0.3) translate(-110%, -35%) ",
					},
				})} 3s 10.8s forwards linear 
				`,
				top: "35%",
				left: "44%",
				width: "50%",
				overflow: "hidden",
				img: {
					animation: `${keyframes({
						from: {
							transform: "scale(1) translate(0%,0%)",
						},
						to: {
							transform: "scale(2) translate(-3%, 0%)",
						},
					})} 3s 10.8s forwards linear 
				`,
				},
			},
		},
		{
			src: images("./bg11s.png"),
			styles: {
				opacity: 0,
				animation: `${appear} 2s 14s forwards linear`,
				top: "42%",
				left: "61%",
				width: "14%",
			},
		},
		{
			src: images("./zeya_cry_02.gif"),
			styles: {
				opacity: 0,
				animation: `${appear} 2s 14s forwards linear, ${disappear} 2s 18s forwards linear`,
				top: "47%",
				left: "62.5%",
				width: "12%",
			},
		},
		{
			src: images("./zeya_cry02.png"),
			styles: {
				opacity: 0,
				animation: `${appear} 2s 16s forwards linear`,
				top: "47%",
				left: "62.5%",
				width: "12%",
			},
		},
		{
			src: images("./bg11s.png"),
			styles: {
				opacity: 0,
				animation: `${appear} 2s 16s forwards linear`,
				top: "38%",
				left: "76%",
				width: "17%",
			},
		},
		{
			src: images("./zeya_cry.gif"),
			styles: {
				opacity: 0,
				animation: `${appear} 2s 16s forwards linear, ${disappear} 2s 18s forwards linear`,
				top: "45%",
				left: "78%",
				width: "14%",
			},
		},
		{
			src: images("./zeya_cry.png"),
			styles: {
				opacity: 0,
				animation: `${appear} 2s 18s forwards linear`,
				top: "44%",
				left: "77%",
				width: "15%",
			},
		},
		{
			src: images("./egg_plate.png"),
			class: "image-one",
			styles: {
			    width: '10%',
				top: '81%',
				left: '66%',
				animation: `${disappear} 1.5s 9s forwards linear`,
			},
			imgDiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page5Text text-center dialogueBubble textbox2",
			styles: {
				top: '10%',
				left: '42%',
				width: '42%',
				opacity: 0,
				padding: '2% 2% 2% 6% !important',
				animation: `${appear} 500ms linear 3000ms forwards, ${disappear} 500ms linear 8s forwards`
			},
			textIndivData: [
				{
					textData: "किन नखाएको ज्हेया? के भयो? के मिलेन भन त।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.35 },
						{ start: 0.95 },
						{ start: 1.35 },
						{ start: 1.65 },
						{ start: 1.95 },
						{ start: 2.8 },
						{ start: 3.1 },
						{ start: 3.3 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./kinaNakhayeko zeya k vayo bhanata .ogg"),
			delay: 3000,
		},
		{
			url: audios("./bg/se_page10.ogg"),
			volume: 0.1,
			delay: 1,
			isBg: true,
		},
		{
			url: audios("./blank.ogg"),
			delay: 20000,
		},
	],
}

export default content
