const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./Page02_jerry_new.jpg"),
			class: "image-one page3",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./peda_under_tree.gif"),
			class: "image-five page3pedanew",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./laddu_under_tree.gif"),
			class: "image-five page3laddunew",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerya",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jery1",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jeryb",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jeryc",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry2.png"),
			class: "image-five jery2",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery3",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery4",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery5",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery6",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery7",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery8",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery9",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery10",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery11",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery12",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery13",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery14",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery15",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery16",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery17",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery18",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery19",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery20",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery21",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery22",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery23",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery24",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery25",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery26",
			imgdiv: "imageCover zoomouttree1",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jery27",
			imgdiv: "imageCover zoomouttree1",
		},
	],
	texts: [
		{
			textDivClass: "story-text page3text text-left",
			textIndivData: [
				{
					textData: "उनीहरू घुम्दै जेरीको बोटमुनी पुगेछन्। ",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1.5 }, { start: 2.4 }, { start: 3.0 }, { start: 3.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3.ogg"),
			delay: 3000,
		},
	],
}

export default content
