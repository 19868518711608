import React from "react"
import PrevIcon from "assets/icons/previous.svg"
const prevBtn = (props) => {
	return (
		<span
			key="prevBtn"
			onClick={
				props.disabled
					? () => {
							return
					  }
					: props.prevBtnClick
			}
			className={props.disabled ? "prev__btn disabled" : "prev__btn"}
		>
			<img src={PrevIcon} className="img-fluid" alt="previous" />
		</span>
	)
}

export default prevBtn
