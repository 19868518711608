const movies = require.context("../movies", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	movie: [
		{
			src: movies("./charlie-chamero-md.webm"),
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center title",
			textIndivData: [
				{
					textData: "चार्ली, तिमी आज पहिलो पटक हामीसित खानाको खोजिमा जान लागेका छौ।",
					textClass: "m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.5 }, { start: 0.9 }, { start: 1.45 }],
				},
			],
		},
	],
}

export default content
