import React, { useState, useContext } from "react"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import { Howl } from "howler"
import _ from "underscore"
import gsap from "gsap"

import { DispatchContext, StateContext } from "../../MatchTheWordGame"

import yahooAudio from "../../content/audios/sound_effects/minion-yahoo.ogg"
import sinkAudio from "../../content/audios/sound_effects/sinking1.ogg"
import tadaAudio from "../../content/audios/sound_effects/tada.ogg"
import tinkAudio from "../../content/audios/sound_effects/stoneClick.ogg"

import Option from "./Option"

import "./options.styles.scss"

function Options({ className, levelData }) {
	const assets = require.context("./assets")
	const [isWordComplete, setWordComplete] = useState(false)
	const [ansLetterObj, setAnsLetterObj] = useState({
		isComplete: false,
		ansLetter: "?",
	})
	const [IsDragDisabled, setIsDragDisabled] = useState(false)
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)

	let opts = JSON.parse(JSON.stringify(levelData.dragItems))

	let list = {
		options: opts.reduce((acc, current) => {
			return {
				...acc,
				[current.id]: current,
			}
		}, {}),
		optionsId: opts.map((option) => option.id),
	}

	const [optionsList, setOptionsList] = useState(list)
	let orderedOptions = optionsList.optionsId.map((id) => optionsList.options[id])

	function playHintAudio(audio) {
		const hintAudio = new Howl({
			src: [audio],
			loop: false,
			volume: 1,
		})
		hintAudio.play()
	}
	const yahooHowl = new Howl({
		src: yahooAudio,
		loop: false,
		volume: 0.5,
	})
	const tinkHowl = new Howl({
		src: tinkAudio,
		loop: false,
		volume: 1,
	})
	const sinkHowl = new Howl({
		src: sinkAudio,
		loop: false,
		volume: 0.5,
	})
	const tadaHowl = new Howl({
		src: tadaAudio,
		loop: false,
		volume: 0.5,
	})

	function onDragEnd(result) {
		const { destination, source } = result
		setIsDragDisabled(true)

		if (!destination) {
			setIsDragDisabled(false)
			return
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			setIsDragDisabled(false)
			return
		}

		const newOptionsId = Array.from(optionsList.optionsId)

		if (destination.droppableId === source.droppableId && destination.index !== source.index) {
			newOptionsId.splice(source.index, 1)
			newOptionsId.splice(destination.index, 0, optionsList.optionsId[source.index])
			setOptionsList({ options: optionsList.options, optionsId: newOptionsId })
			setIsDragDisabled(false)
			return
		}

		if (destination.droppableId !== source.droppableId) {
			let newOptionsOrder = JSON.parse(JSON.stringify(optionsList.optionsId))
			let newOptions = JSON.parse(JSON.stringify(optionsList.options))
			if (orderedOptions[source.index].match === levelData.dropItems.match) {
				let ansName = orderedOptions[source.index].name
				setAnsLetterObj({
					isComplete: true,
					ansLetter: ansName.letter,
				})
				if (ansName.position === 1) {
					gsap.to(".ansStone-1", {
						webkitFilter: "brightness(10)",
						filter: "brightness(10)",
						left: "-10%",
						duration: 0.8,
						onComplete: function () {
							tinkHowl.play()
							setWordComplete(true)
						},
					})
				} else if (ansName.position === 0) {
					gsap.to(".ansStone-0", {
						webkitFilter: "brightness(10)",
						filter: "brightness(10)",
						left: "8%",
						duration: 0.8,
						onComplete: function () {
							tinkHowl.play()
							setWordComplete(true)
						},
					})
				}
				dispatch({ type: "WORD_COMPLETE" })

				newOptionsOrder.splice(source.index, 1)
				Object.keys(newOptions).map((key) => {
					newOptions[key].isSinking = true
					newOptions[key].name.letter = ""
					return newOptions
				})
				setOptionsList({ options: newOptions, optionsId: newOptionsOrder })
				setTimeout(function () {
					setOptionsList({ options: optionsList.options, optionsId: [] })
				}, 2000)
				setTimeout(function () {
					yahooHowl.play()
				}, 1500)

				yahooHowl.on("end", function () {
					tadaHowl.play()
					gsap.to(".hint-mtw", {
						x: "37%",
						y: "29%",
						scale: 1.2,
						duration: 1,
						delay: 2,
						// onStart: function () {
						// 	tadaHowl.play();
						// }
						onComplete: function () {
							if (document.getElementById("hint-text")) {
								document.getElementById("hint-text").innerText = state.levelData[state.stage].word
								document.getElementById("hint-text").classList.add("appearAns")
							}
							const ansAudio = new Howl({
								src: [levelData.audioHint],
								loop: false,
								volume: 1,
							})
							ansAudio.play()
							ansAudio.on("end", () => {
								if (state.stage === 10) {
									dispatch({ type: "LASTSCENE" })
								} else {
									dispatch({ type: "REWARD_COMPLETE" })
								}
							})
						},
					})
				})

				setIsDragDisabled(false)
			} else {
				orderedOptions[source.index].isSinking = true
				orderedOptions[source.index].name.letter = ""
				setOptionsList({ options: optionsList.options, optionsId: newOptionsOrder })
				sinkHowl.play()
				setTimeout(function () {
					newOptionsOrder.splice(source.index, 1)
					setOptionsList({ options: optionsList.options, optionsId: newOptionsOrder })
					setIsDragDisabled(false)
				}, 4000)
			}
		}
	}

	return (
		<div className={"option-holder-mtw " + className}>
			<DragDropContext onDragStart={() => setIsDragDisabled(true)} onDragEnd={onDragEnd}>
				{isWordComplete ? (
					<div className={"dropZone-mtw stone"}>
						<img
							className={"stone-img"}
							alt="stoneComplete"
							src={assets(`./stone${levelData.question.questionImageIdx}.png`)}
						/>
						<div className="qFizzContainer">
							<div className="fizz questionFizz">
								<div className="fizzDrop"></div>
							</div>{" "}
						</div>
						<h3 className="stone-text ">{levelData.word}</h3>
					</div>
				) : (
					<Droppable droppableId="dropZone" direction="horizontal">
						{(provided) => (
							<div className="dropZone-mtw">
								{levelData.question.questionIndex ? (
									<>
										<div ref={provided.innerRef} {...provided.droppableProps} className="dropZone">
											{/* {provided.placeholder} */}
											<div
												className={
													levelData.dropItems.dropClass +
													" " +
													levelData.dropItems.enable +
													" stone shade-left-img ansStone-0"
												}
											>
												<img
													className="stone-img img-dark"
													alt="stone1"
													src={assets(`./stone${levelData.question.questionImageIdx}_0.png`)}
												/>
												<h2 className={levelData.dropItems.font + " stone-text qText-left"}>
													{ansLetterObj.ansLetter}
												</h2>
											</div>
										</div>
										<div
											className="stone q-right"
											onClick={_.debounce(() => playHintAudio(levelData.question.audioHint), 250)}
										>
											<img
												className="stone-img qImg-right"
												alt="stone2"
												src={assets(
													`./stone${levelData.question.questionImageIdx}_${levelData.question.questionIndex}.png`
												)}
											/>
											<div className="qRightFizzContainer">
												<div className="fizz questionRightFizz">
													<div className="fizzDrop"></div>
												</div>
											</div>
											<h3 className="stone-text qText-right">{levelData.question.letter}</h3>
										</div>
									</>
								) : (
									<>
										<div
											className={"stone q-left"}
											onClick={_.debounce(() => playHintAudio(levelData.question.audioHint), 250)}
										>
											<img
												className="stone-img "
												alt="stone3"
												src={assets(
													`./stone${levelData.question.questionImageIdx}_${levelData.question.questionIndex}.png`
												)}
											/>
											<div className="qLeftFizzContainer">
												<div className="fizz questionLeftFizz">
													<div className="fizzDrop"></div>
												</div>
											</div>
											<h3 className="stone-text qText-left">{levelData.question.letter}</h3>
										</div>
										<div
											ref={provided.innerRef}
											{...provided.droppableProps}
											className={`${state.stage === 9 ? "nut" : ""} dropZone`}
										>
											{/* {provided.placeholder} */}
											<div
												className={
													levelData.dropItems.dropClass +
													" " +
													levelData.dropItems.enable +
													" stone shade-right-img ansStone-1"
												}
											>
												<img
													className="stone-img img-dark"
													alt="stone4"
													src={assets(`./stone${levelData.question.questionImageIdx}_1.png`)}
												/>
												<h2 className={levelData.dropItems.font + " stone-text qText-right"}>
													{ansLetterObj.ansLetter}
												</h2>
											</div>
										</div>
									</>
								)}
							</div>
						)}
					</Droppable>
				)}

				{/* Options */}
				<Droppable droppableId="dragZone" direction="horizontal" isDropDisabled={true}>
					{(provided) => (
						<div {...provided.droppableProps} ref={provided.innerRef}>
							<div className="stones-list" style={{ marginTop: state.stage === 9 && "1%" }}>
								{orderedOptions.map((option, index) => {
									return <Option key={"key-" + index} option={option} index={index} isDraggable={IsDragDisabled} />
								})}
								{provided.placeholder}
							</div>
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	)
}

export default Options
