import React from "react"
import normalIcon from "../icons/controls/backward_blue.svg"
import disabledIcon from "../icons/controls/backward_grey.svg"
const prevBtn = (props) => {
	return (
		<span
			key="prevBtn"
			onClick={
				props.disabled
					? () => {
							return
					  }
					: props.prevBtnClick
			}
			className={props.disabled ? "prev__btn disabled" : "prev__btn"}
		>
			<img src={props.disabled ? disabledIcon : normalIcon} className="img-fluid" alt="previous" />
		</span>
	)
}

export default prevBtn
