import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const bicycleToSurket = keyframes`
0% {
	transform: rotate(2deg) translateX(0%);
}
100% {
	transform: rotate(3deg) translateX(254%);
}
`
const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(4) translate(35%,20%)",
					animation: `${transformTo("scale(7) translate(25%,10%)")} 4s 23s forwards ease-out `
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "7%", top: "38%", left: "26%",opacity: "1",transform: "scaleX(-1)",
				animation: `${appear} 0.01s 500ms ease-out normal forwards,
				${disappear} 1s 8s ease-out normal forwards
				 
				`,
			}
		},
		{
			src: images("./imgs/girl05.png"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "7%",
				top: '44%',
				left: '65%',
				opacity: "0",transform: "scaleX(-1)",zIndex:2,
				animation: `${appear} 1s 14s ease-out normal forwards, ${disappear} 1s 22s ease-out normal forwards
				`,
			}
		},
		{
			src: images("./imgs/girl_cycle.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "13%", top: "43%", left: "24%",opacity: "0",
				transform: "rotate(2deg)",
				zIndex: 2,
				animation: `${appear} 1s 8s ease-out normal forwards,
				${bicycleToSurket} 5s 9s ease-out normal forwards,
				${disappear} 1s 14s ease-out normal forwards
				`,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble1 page2Buble",
			textIndivData: [
				{
					textData: "यतातिरको बाटो कस्तो सिधा रहेछ, म त सुर्खेत सम्म साइकल मै जान्छु।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: "7%",
				left: "28%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 500ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 8s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble2 page2Buble",
			textIndivData: [
				{
					textData: "आहा कस्तो राम्रो ताल! यो बुलबुले तालको छेउमा त ढुंगे धारा पनि रहेछ हेर त।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{    
				top: '12%',
				left: '26%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 14s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 21s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromKanToSur",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '37%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '62%',
				position: 'absolute',
				left: '25.5%',
				transform: 'rotate(3deg) scaleX(0)',
				transformOrigin: "left",
				animation: `${transformTo(["rotate(3deg) scaleX(1)"])} 4s 9s linear forwards, ${disappear} 0.01s 22s linear forwards`
			}
		},
	],
	sounds: [
		{
			url: audios("./surkhet1.ogg"),
			delay: 1000,
		},
		{
			url: audios("./surkhet2.ogg"),
			delay: 14000,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 27000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
	],
}

export default content
