import React from "react"
import RecorderGame from "../../../Containers/Play/RecorderTemplate/RecorderGame"

const RecorderPlay = () => {
	const [resetKey, setresetKey] = React.useState(`pl-2-${Math.round(Math.random() * 999)}`)
	const resetGame = () => {
		setresetKey(`pl-2-${Math.round(Math.random() * 999)}`)
	}
	return <RecorderGame activityKey={`${resetKey}`} key={`${resetKey}`} activityName="रेकर्डर" resetGame={resetGame} />
}

export default RecorderPlay
