import wordsConfig from "./words"

let filterLevelData = (level) => {
	return wordsConfig.filter((data) => data.level === level)
}

// console.log("wordsConfig", wordsConfig);
const content = {
	// instructionurl: audios("./s0.ogg"),
	totalPage: wordsConfig.length,
	textDivClass: " text-center querytext",
	textIndivData: [
		{
			textData: "खाली ठाउँमा सही उत्तर तानेर राख।",
			textClass: "fz-5 m-0 one-line",
		},
	],
	contentList: wordsConfig,
	lastPageContent: [
		{
			img: "imgSrc",
		},
	],
	levels: [
		{
			label: "Easy",
			data: filterLevelData(1),
		},
		{
			label: "Medium",
			data: filterLevelData(2),
		},
		{
			label: "Hard",
			data: filterLevelData(3),
		},
	],
}
export default content
