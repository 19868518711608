import Page1 from "./Page1"
import Page2 from "./Page2"
import Page3 from "./Page3"
import Page4 from "./Page4"
import Page5 from "./Page5"
import Page6 from "./Page6"
import Page7 from "./Page7"
import Page8 from "./Page8"
import Page9 from "./Page9"
import Page10 from "./Page10"
import Page11 from "./Page11"
import Page12 from "./Page12"
import Page13 from "./Page13"
import Page14 from "./Page14"
import Page15 from "./Page15"
import Page16 from "./Page16"
import Page17 from "./Page17"
import Page18 from "./Page18"
import Page19 from "./Page19"
import Page20 from "./Page20"
import Page21 from "./Page21"
import Page22 from "./Page22"
import Page23 from "./Page23"
import Page24 from "./Page24"
import Page25 from "./Page25"

let content = [Page1, Page2, Page3, Page4, Page5, Page6, Page7, Page8, Page9, Page10,
        Page11,
        Page12,
        Page13,
        Page14,
        Page15,
        Page16,
        Page17,
        Page18,
        Page19,
        Page20,
        Page21,
        Page22,
        Page23,
        Page24,
        Page25,



]

export default content
