const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-blue-sky",
	images: [
		{
			src: images("./25to33/ducks.png"),
			imgdiv: "imageCover ducks-anim ducks",
		},
		{
			src: images("./25to33/tortile.png"),
			imgdiv: "imageCover falling d-none",
		},
		{
			src: images("./13to24/fall.gif"),
			imgdiv: "imageCover falling-gif ",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudc-3 cloudc-anim-3",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudc-4 cloudc-anim-4",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudc-5 cloudc-anim-5",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-top-center textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "खसिरहेको कछुवालाई आफ्‍नो शरीरमा पर्ने गरी एकआपसमा जोडिएर हावामा अडिए।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.9 },
						{ start: 1.8 },
						{ start: 2.3 },
						{ start: 3 },
						{ start: 3.3 },
						{ start: 3.95 },
						{ start: 4.95 },
						{ start: 5.9 },
						{ start: 6.5 },
						{ start: 7.5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p5_s2.ogg"),
			delay: 500,
		},
	],
}

export default content
