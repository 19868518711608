const audios = require.context("../audios", true)
const images = require.context("../images", true)

export const level1_words = [
	"रुख",
	"रङ",
	"खाट",
	"झोला",
	"धारा",
	"फूल",
	// "फेद",
	"फल",
	"पानी",
	"पिङ",
	"पात",
	"छाना",
	// "आरा",
	"बाजा",
	"धान",
	"घुम",
	"बाटो",
	"साथी",
	"पाथी",
	"पाठी",
	"गोडा",
	"घोडा",
	"ढोका",
	"हात",
	"टीका",
	"गाउँ",
	"पैसा",
	"राजा",
	"माछा",
	"भात",
	"खसी",
	"कान",
	"पाडो",
	"गाई",
	"भेडा",
	"हात्ती",
	"टोपी",
	"छाला",
	// "गाडा",
	"सुन",
	"मुख",
]

export const level1_hintImages = {
	रुख: [images("./rukh.png"), images("./rukh.png"), audios("./rukh.ogg")],
	रङ: [images("./rang.png"), images("./rang.png"), audios("./rang.ogg")],
	खाट: [images("./khat.png"), images("./khat.png"), audios("./khat.ogg")],
	झोला: [images("./jhola.png"), images("./jhola.png"), audios("./jhola.ogg")],
	धारा: [images("./dhara.png"), images("./dhara.png"), audios("./dhara.ogg")],
	फूल: [images("./ful.png"), images("./ful.png"), audios("./ful.ogg")],
	// फेद: [images("./fed.png"), images("./fed.png"), audios("./fed.ogg")],
	फल: [images("./fal.png"), images("./fal.png"), audios("./fal.ogg")],
	पानी: [images("./pani.png"), images("./pani.png"), audios("./pani.ogg")],
	पिङ: [images("./ping.png"), images("./ping.png"), audios("./ping.ogg")],
	पात: [images("./paat.png"), images("./paat.png"), audios("./paat.ogg")],
	छाना: [images("./chhana.png"), images("./chhana.png"), audios("./chhana.ogg")],
	// आरा: [images("./aara.png"), images("./aara.png"), audios("./aara.ogg")], image not ready
	बाजा: [images("./baja.png"), images("./baja.png"), audios("./baja.ogg")],
	धान: [images("./dhaan.png"), images("./dhaan.png"), audios("./dhaan.ogg")],
	घुम: [images("./ghum.png"), images("./ghum.png"), audios("./ghum.ogg")],
	बाटो: [images("./bato.png"), images("./bato.png"), audios("./bato.ogg")],
	साथी: [images("./sathi.png"), images("./sathi.png"), audios("./sathi.ogg")],
	पाथी: [images("./pathhi.png"), images("./pathhi.png"), audios("./pathhi.ogg")],
	पाठी: [images("./pathi.png"), images("./pathi.png"), audios("./pathi.ogg")],
	गोडा: [images("./goda.png"), images("./goda.png"), audios("./goda.ogg")],
	घोडा: [images("./ghoda.png"), images("./ghoda.png"), audios("./ghoda.ogg")],
	ढोका: [images("./dhoka.png"), images("./dhoka.png"), audios("./dhoka.ogg")],
	हात: [images("./haat.png"), images("./haat.png"), audios("./haat.ogg")],
	टीका: [images("./tauko.png"), images("./tauko.png"), audios("./tauko.ogg")],
	गाउँ: [images("./gaun.png"), images("./gaun.png"), audios("./gaun.ogg")],
	पैसा: [images("./paisa.png"), images("./paisa.png"), audios("./paisa.ogg")],
	राजा: [images("./raja.png"), images("./raja.png"), audios("./raja.ogg")],
	माछा: [images("./machha.png"), images("./machha.png"), audios("./machha.ogg")],
	भात: [images("./bhat.png"), images("./bhat.png"), audios("./bhat.ogg")],
	खसी: [images("./khasi.png"), images("./khasi.png"), audios("./khasi.ogg")],
	कान: [images("./kaan.png"), images("./kaan.png"), audios("./kaan.ogg")],
	पाडो: [images("./pado.png"), images("./pado.png"), audios("./pado.ogg")],
	गाई: [images("./gai.png"), images("./gai.png"), audios("./gai.ogg")],
	भेडा: [images("./veda.png"), images("./veda.png"), audios("./veda.ogg")],
	हात्ती: [images("./hatti.png"), images("./hatti.png"), audios("./hatti.ogg")],
	टोपी: [images("./topi.png"), images("./topi.png"), audios("./topi.ogg")],
	छाला: [images("./chhala.png"), images("./chhala.png"), audios("./chhala.ogg")],
	// गाडा: [images("./gada.png"), images("./gada.png"), audios("./gada.ogg")], audio
	सुन: [images("./soon.png"), images("./soon.png"), audios("./soon.ogg")],
	मुख: [images("./mukh.png"), images("./mukh.png"), audios("./mukh.ogg")],
}
