const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_page_12.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./circle.png"),
			class: "image-one circle",
			imgdiv: "imageCover",
		},
		{
			src: images("./mayur.png"),
			class: "image-one mayur",
			imgdiv: "imageCover",
		},
		{
			src: images("./peacock.png"),
			class: "image-one peacock",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page17Text text-center",
			textIndivData: [
				{
					textData: "अनि चल्ला त ठुलो भएर कति राम्रो मयूर पो भयो।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.7 },
						{ start: 1.1 },
						{ start: 1.3 },
						{ start: 1.6 },
						{ start: 2.1 },
						{ start: 2.3 },
						{ start: 2.9 },
						{ start: 3.45 },
						{ start: 3.6 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./17.ogg"),
			delay: 500,
		},
	],
}

export default content
