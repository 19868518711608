import React, { useState, useEffect, useContext } from "react"
/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import { Howl } from "howler"
import gsap from "gsap"

// image assets
import SaagImg from "../../content/images/level1/story/saag01.png"
import SpoonImg from "../../content/images/level1/story/spoon1.png"
import BhaatImg from "../../content/images/level1/story/plate_bhaat1.png"
import BaagGif from "../../content/images/level1/story/baag1.gif"
import KaagGif from "../../content/images/level1/story/kaag1.gif"
import { StateContext, DispatchContext } from "../../DecodingGame"

const SaagStory = ({ NextPhase }) => {
	const dispatch = useContext(DispatchContext)
	const state = useContext(StateContext)
	const [isFirstTime, setIsFirstTime] = useState(true)
	useEffect(() => {
		if (isFirstTime) {
			setIsFirstTime(false)
			const soundAsset = require.context("./")
			const hintHowl = new Howl({
				src: [soundAsset("./saagStory1.ogg")],
			})
			gsap.to("#DP4-baag", {
				delay: 0.3,
				scale: 1.2,
				duration: 0.8,
				onStart: () => {
					hintHowl.play()
				},
			})
			gsap.to("#DP4-baag", {
				delay: 0.7,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-kaag", {
				delay: 1,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-kaag", {
				delay: 1.6,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-saag", {
				delay: 4.2,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-saag", {
				delay: 4.6,
				duration: 0.8,
				scale: 1,
			})
			hintHowl.on("end", () => {
				NextPhase()
				// dispatch({ type: "PHASE_COMPLETE", values: { phase: state.phase + 1 } })
			})
		}
	}, [NextPhase, dispatch, state.phase, isFirstTime])

	return (
		<React.Fragment>
			<img
				src={KaagGif}
				id="DP4-kaag"
				css={css`
					position: absolute;
					width: 20%;
					left: 63%;
					top: 25%;
				`}
				alt="kaag moving its head"
			/>
			<img
				src={BaagGif}
				id="DP4-baag"
				css={css`
					width: 30%;
					position: absolute;
					top: 5%;
					left: 18%;
				`}
				alt="baag moving its head"
			/>

			<img
				src={BhaatImg}
				css={css`
					width: 50%;
					position: absolute;
					top: 64%;
					left: 28%;
				`}
				alt="saag"
			/>
			<img
				src={SpoonImg}
				css={css`
					width: 28%;
					position: absolute;
					top: 71%;
					left: 18%;
				`}
				alt="Spoon"
			/>
			<img
				src={SaagImg}
				id="DP4-saag"
				css={css`
					width: 28%;
					position: absolute;
					top: 49%;
					left: 52%;
				`}
				alt="saag"
			/>
		</React.Fragment>
	)
}

export default SaagStory
