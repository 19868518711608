import { appear, transformFrom } from "styledElements/keyframes"
import { css } from "@emotion/core"

const imgStyles = {
	height: "100%",
	objectFit: "cover",
	objectPosition: "top left",
}

const baseStyles = {
	width: "23%",
	height: "75%",
	border: "1px solid #9c835b",
	img: imgStyles,
}

const content = {
	images: [
		{
			src: "hill/page18a.png",
			styles: {
				...baseStyles,
				top: "30%",
				left: "25%",
				animation: `${appear} 0.05s 0.5s ease-out normal both, ${transformFrom("translateY(45%)")} 1s 0.5s ease-in normal forwards`,
			},
		},
		{
			src: "hill/page18b.png",
			styles: {
				...baseStyles,
				top: "15%",
				left: "50%",
				animation: `${appear} 0.5s 2.5s ease-out normal both, ${transformFrom("translateY(45%)")} 1s 2.5s ease-in normal forwards`,
			},
		},
		{
			src: "hill/page18c.png",
			styles: {
				...baseStyles,
				top: "0%",
				left: "75%",
				img: {
					objectFit: "fill",
					height: "100%",
					objectPosition: "top left",
				},
				animation: `${appear} 0.5s 4.5s ease-out normal both, ${transformFrom("translateY(45%)")} 1s 4.5s ease-in normal forwards`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: css`
				left: 5%;
				padding: 15px;
				top: 5%;
				animation: ${appear} 0.5s 0.5s ease-out normal both;
				background: rgba(255, 255, 255, 0.8);
				border-radius: 15px;
				@media screen and (max-width: 1920px) {
					font-size: 40px;
				}
				@media screen and (max-width: 900px) {
					font-size: 35px;
					padding: 10px;
				}
				@media screen and (max-width: 760px) {
					font-size: 30px;
					padding: 10px;
				}
				@media screen and (max-width: 530px) {
					left: 7%;
					font-size: 25px;
				}
				@media screen and (max-width: 380px) {
					font-size: 20px;
				}
			`,
			textIndivData: [
				{
					textData: "असिना बर्षा हावा हुरी",
					textClass: "m-0 ",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "कसैले भत्काउन नसक्‍ने गरि",
					textClass: "m-0 ",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p16.ogg",
			delay: 500,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			isBg: true,
			volume: 0.3,
		},
	],
}

export default content
