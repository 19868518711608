const audio_letters = require.context("../../audios/letters", true)

const letterMap = {
	aww: "aww",
	lo: "lo",
	wo: "wo",
	धा: "dha",
	le: "le",
	र: "ra",
	के: "k",
	क: "ka",
	का: "kaa",
	कु: "ku",
	ल: "la",
	ला: "laaa",
	ली: "li",
	मे: "may",
	मू: "mu",
	न: "na",
	रा: "raaa",
	रे: "re",
	रि: "ri",
	सु: "su",
	ते: "te",
	ति: "ti",
	व: "wa",
	उ: "wu",
	ट: "tapari_ta",
	फ: "pha",
	ह: "ha",
	मि: "me",
	इ: "e",
	गु: "gu",
	हि: "hii",
	ख: "kha",
	खु: "khu",
	ले: "ley",
	म: "ma",
	मा: "maaa",
	पा: "paaa",
	रु: "ru",
	स: "sa",
	सा: "saaa",
	ट्टे: "tey",
	थै: "thae",
	वा: "waaa",
	राँ: "raaan",
}

let letterToSound = {}

for (const letter of Object.keys(letterMap)) {
	if (letterMap[letter] !== null) {
		try {
			letterToSound[letter] = audio_letters(`./${letterMap[letter]}.ogg`)
		} catch (error) {
			console.log(`not found - letterToSound mapping for letter: '${letter}', sound: '${letterMap[letter]}.ogg'`)
			letterToSound[letter] = null
		}
	}
}

// console.log("letterToSound", letterToSound);

export default letterToSound
