export default [
	//Lvl2
	{
		key: "table",
		letters: ["टे", "बु", "ल"],
	},
	{
		key: "kitab",
		letters: ["कि", "ता", "ब"],
	},
	{
		key: "kapal",
		letters: ["क", "पा", "ल"],
	},
	{
		key: "manis",
		letters: ["मा", "नि", "स"],
	},
	{
		key: "kamila",
		letters: ["क", "मि", "ला"],
	},
	{
		key: "pahad",
		letters: ["प", "हा", "ड"],
	},
	{
		key: "achar",
		letters: ["अ", "चा", "र"],
	},
	{
		key: "kukhura",
		letters: ["कु", "खु", "रा"],
	},
	{
		key: "parewa",
		letters: ["प", "रे", "वा"],
	},
	{
		key: "biralo",
		letters: ["बि", "रा", "लो"],
	},
	{
		key: "ribbon",
		letters: ["रि", "ब", "न"],
	},
	// {
	//     key: "kukur",
	//     letters: ["कु", "कु", "र"]
	// },
	{
		key: "pokhari",
		letters: ["पो", "ख", "री"],
	},
	{
		key: "kachuwa",
		letters: ["क", "छु", "वा"],
	},
	{
		key: "sarir",
		letters: ["श", "री", "र"],
	},
	{
		key: "kagaj",
		letters: ["का", "ग", "ज"],
	},
]
