import React, { useContext, useEffect } from "react"
import { Howl } from "howler"
import { StateContext } from "../../ShapeColorGame"
import "./scene6Option.styles.scss"
import { sleep } from "../../utils"
import yo_awaj_kasko_hola from "../../content/chapters/assets/common/female.mp3"
// import gsap from "gsap"

// gsap.ticker.lagSmoothing(false)

const MultipleOption = ({ stageData,SecondanswerClick, assets }) => {
	const state = useContext(StateContext)
	let animalRef = React.useRef(null)
	let fstOptRef = React.useRef(null)
	// let secOptRef = React.useRef(null)
	// let thirdOptRef = React.useRef(null)
	let AnsOptRef =  React.useRef(null)
	let optionImages = stageData.optionImg;
	let qnAudio;
	// let otherAnimalsRef = React.useRef(null)
	// var timelap = gsap.timeline({})
	// let initial = {
	// 	// img: stageData.id !== 10 && stageData.animals[stageData.rightAnswer].image,
	// 	animalWalked: false,
	// 	bubble: false,
	// 	letsWalkTogether: false,
	// 	questionerRequest: false,
	// 	resizeGif: false,
	// 	finalStageQ: stageData.id === 10 && stageData.questionerTalk,
	// 	replace: false,
	// }

	// const dispatch = useContext(DispatchContext)
	// const [optionState, setOptionState] = useState(initial)

useEffect(() => { 								/* this useeffect is for scene 6 second option */
// 	const bgMusic = new Howl({
// 	src: [stageData.secHeadQn],
// 	autoplay: false,
// 	loop: false,
// 	onend: async () => {
// 		if (stageData.id === 10) {
// 			// setOptionState({ ...optionState, finalStageQ: stageData.questioner })
// 			// dispatch({ type: "LAST_PAGE_COMPLETE" })
// 		}
// 		await sleep(5000)
// 		// await playAnimalSound()
// 	},
// })
// bgMusic.play()
if (stageData.id === 6 && state.playing) {
	;(async function () {
		try {
			qnAudio = new Howl({
				src: [stageData.secHeadQn],
				autoplay: true,
				loop: false,
				onend: async () => {
					console.log("done")
					// await sleep(5000)
					// await playAnimalSound()
				},
			})
			const word = ["वृताआकारमा", "क्लिक", "गरौँ ।"]
			let inputText = document.getElementById("whose-voice")
			let innerHTML = inputText.innerHTML
			let actualWord = " वृताआकारमा क्लिक गरौँ ।"	
			for (let i = 0; i < word.length + 1; i++) {
				if (i >= word.length) {
					inputText.innerHTML = `<span class="white">${actualWord}</span>`
				} else {
					let index = actualWord.indexOf(`${word[i]}`)
					if (index >= 0) {
						innerHTML =
							"<span class='hightlight'>" +
							actualWord.substring(0, index) +
							actualWord.substring(index, index + word[i].length) +
							"</span>" +
							actualWord.substring(index + word[i].length)
						inputText.innerHTML = innerHTML
					}
				}

				await sleep(400)
			}
		} catch {}
	})()

	AnsOptRef.current.innerHTML = stageData.OptionThree;
}
return() =>{
	qnAudio.unload();
}
}, [state.playing, stageData.id, stageData.OptionThree])
	// useEffect(() => {
	// 	// Yo kasko awaj hola
	// 	const bgMusic = new Howl({
	// 		src: [stageData.questionerVoice],
	// 		autoplay: true,
	// 		loop: false,
	// 		onend: async () => {
	// 			if (stageData.id === 10) {
	// 				setOptionState({ ...optionState, finalStageQ: stageData.questioner })
	// 				dispatch({ type: "LAST_PAGE_COMPLETE" })
	// 			}
	// 			await sleep(2000)
	// 			await playAnimalSound()
	// 		},
	// 	})

	// 	if (stageData.id === 10) {
	// 		const lastIndex = async () => {
	// 			await sleep(800)
	// 			const word = ["हामीले", "कति", "धेरै", "जनावरहरूको", "आवाज", "सुन्यौँ।", "तिमीलाई", "कसको", "आवाज", "मनपर्‍यो ?"]

	// 			let inputText = document.getElementById("last-question")
	// 			let innerHTML = inputText.innerHTML
	// 			let actualWord = "हामीले कति धेरै<br/> जनावरहरूको आवाज सुन्यौँ।<br/> तिमीलाई कसको आवाज मनपर्‍यो ?"
	// 			for (let i = 0; i < word.length + 1; i++) {
	// 				if (i >= word.length) {
	// 					inputText.innerHTML = `<span class="lets-go-highlight">${actualWord}</span>`
	// 				} else {
	// 					let index = actualWord.indexOf(`${word[i]}`)
	// 					if (word[i] === "आवाज" && i === 8) index = 64
	// 					if (index >= 0) {
	// 						innerHTML =
	// 							"<span class='lets-go-highlight'>" +
	// 							actualWord.substring(0, index) +
	// 							actualWord.substring(index, index + word[i].length) +
	// 							"</span>" +
	// 							actualWord.substring(index + word[i].length)
	// 						inputText.innerHTML = innerHTML
	// 					}
	// 				}

	// 				await sleep(i === 5 ? 800 : 600)
	// 			}
	// 		}

	// 		lastIndex()
	// 	}
	// 	return () => {
	// 		bgMusic.unload()
	// 	}
	// }, [state.stage, dispatch])

	// useEffect(() => {
	// 	if (state.letsWalk) {
	// 		for (let i = 0; i < stageData.animals.length; i++) {
	// 			if (i !== stageData.rightAnswer) {
	// 				// Wrong answers are hidden from here
	// 				if (animalRef && animalRef.current.children[i])
	// 					gsap.to(animalRef.current.children[i], {
	// 						duration: 3,
	// 						scale: 0.3,
	// 						ease: "power1.in",
	// 						opacity: 0.2,
	// 						onComplete: () => {
	// 							if (animalRef && animalRef.current && animalRef.current.children[i])
	// 								animalRef.current.children[i].style.visibility = "hidden"
	// 						},
	// 					})
	// 			} else {
	// 				animalRef.current.children[i].children[0].className = ""
	// 				let oldImage = stageData.id === 4 ? stageData.animalWalk : optionState.img
	// 				setOptionState({ ...optionState, img: stageData.animalWalk })

	// 				// Right answer comes in middle of the frame
	// 				if (animalRef.current.children[i])
	// 					gsap.to(animalRef.current.children[i], {
	// 						x: stageData.rightAnswer === 0 ? windowSize.current.width / 3.5 : 0,
	// 						duration: 3,
	// 						ease: "power1.in",
	// 						onComplete: async () => {
	// 							setOptionState({ ...optionState, img: oldImage, questionerRequest: true, bubble: true })
	// 							dispatch({ type: "WALK_COMPLETE" })
	// 						},
	// 					})
	// 			}
	// 		}
	// 	}
	// }, [state.letsWalk])

	// const walkTogether = async () => {
	// 	setOptionState({ ...optionState, letsWalkTogether: true, bubble: false })
	// 	let currentStageObj = { animal: stageData.animalWalk, questioner: stageData.questionerWalk }

	// 	// Questioner walks to middle
	// 	timelap.to(questionerRef.current, {
	// 		duration: 3,
	// 		x: window.innerWidth / 3,
	// 		ease: "power1.in",
	// 		onComplete: () => {
	// 			setOptionState({ ...optionState, img: stageData.animalWalk, bubble: false, letsWalkTogether: true, resizeGif: true })
	// 		},
	// 	})

	// 	// Animal with right answer will come down (back to the questioner)
	// 	gsap.to(animalRef.current.children[stageData.rightAnswer], {
	// 		duration: 1,
	// 		x:
	// 			stageData.animalMeetsQuestionerX === "0"
	// 				? stageData.rightAnswer === 0
	// 					? windowSize.current.width / 3.5
	// 					: 0
	// 				: stageData.animalMeetsQuestionerX,
	// 		y: stageData.animalMeetsQuestionerY,
	// 		ease: "power1.in",
	// 		delay: 2,
	// 		onComplete: () => {
	// 			dispatch({ type: "REWARD_COMPLETE", values: currentStageObj })
	// 			// Other friends comes near to the frame
	// 			gsap.to(otherAnimalsRef.current, {
	// 				duration: 1,
	// 				x: stageData.oldFriendsComesNear ? stageData.oldFriendsComesNear : "15%",
	// 				ease: stageData.id === 4 ? "none" : "power1.in",
	// 			})
	// 			if (stageData.id === 7) animalRef.current.children[stageData.rightAnswer].style.height = "65%"
	// 			if (stageData.id === 8) animalRef.current.children[stageData.rightAnswer].style.height = "75%"
	// 		},
	// 	})

	// 	if (stageData.id === 4) {
	// 		// Owl flies to bottom left
	// 		gsap.to(animalRef.current.children[stageData.rightAnswer], {
	// 			duration: 3.5,
	// 			x: "-170",
	// 			y: "185%",
	// 			ease: "power1.in",
	// 			delay: 1,
	// 		})

	// 		// Owl fades out
	// 		gsap.to(animalRef.current.children[stageData.rightAnswer], {
	// 			duration: 1,
	// 			ease: "power1.in",
	// 			opacity: 0.2,
	// 			delay: 4,
	// 			onComplete: () => {
	// 				animalRef.current.children[stageData.rightAnswer].style.visibility = "hidden"
	// 			},
	// 		})
	// 	}

	// 	// Questioner again starts to walk
	// 	gsap.to(questionerRef.current, {
	// 		duration: stageData.animalWalkDuration - stageData.questionerWalkDuration,
	// 		x: windowSize.current.width + 50,
	// 		delay: 4,
	// 		ease: "none",
	// 	})

	// 	if (stageData.id !== 4)
	// 		// Animal also start to walk
	// 		gsap.to(animalRef.current.children[stageData.rightAnswer], {
	// 			duration: stageData.id === 4 ? 9.5 : stageData.animalWalkDuration,
	// 			x: stageData.animalMeetsQuestionerX.includes("%")
	// 				? "500%"
	// 				: stageData.animalGoesAaway
	// 				? stageData.animalGoesAaway
	// 				: windowSize.current.width + 5,
	// 			ease: "none",
	// 			delay: stageData.id === 4 ? 9 : 4,
	// 		})

	// 	await sleep(stageData.wait ? stageData.wait : 1000)
	// 	// Other friends also starts to walk
	// 	gsap.to(otherAnimalsRef.current, {
	// 		duration: stageData.animalWalkDuration + (stageData.oldFriends ? stageData.oldFriends : 2),
	// 		x: stageData.oldFriendsGoesTo ? stageData.oldFriendsGoesTo : "100%",
	// 		ease: "none",
	// 		delay: 4,
	// 	})
	// }

	// useEffect(() => {
	// 	let walkSound
	// 	// Au sangai ghumna jau
	// 	if (optionState.questionerRequest) {
	// 		;(async function () {
	// 			walkSound = new Howl({
	// 				src: [stageData.letsWalkTogether],
	// 				autoplay: true,
	// 				loop: false,
	// 				onend: async function () {
	// 					setOptionState({ ...optionState, replace: true })
	// 					await sleep(2000)
	// 					setOptionState({ ...optionState, bubble: false })
	// 					// await sleep(2000)
	// 					setOptionState({ ...optionState, letsWalkTogether: true })
	// 					walkTogether()
	// 				},
	// 			})
	// 			if (stageData.id !== 10) {
	// 				await sleep(200)
	// 				const word = ["आऊ,", "सँगै", "घुम्‍न", "जाऔँ ?"]
	// 				let inputText = document.getElementById("lets-go")
	// 				let innerHTML = inputText.innerHTML
	// 				let actualWord = "आऊ, सँगै घुम्‍न जाऔँ ।"
	// 				for (let i = 0; i < word.length + 1; i++) {
	// 					if (i >= word.length) {
	// 						inputText.innerHTML = `<span class="lets-go-highlight">${actualWord}</span>`
	// 					} else {
	// 						let index = actualWord.indexOf(`${word[i]}`)
	// 						if (index >= 0) {
	// 							innerHTML =
	// 								"<span class='lets-go-highlight'>" +
	// 								actualWord.substring(0, index) +
	// 								actualWord.substring(index, index + word[i].length) +
	// 								"</span>" +
	// 								actualWord.substring(index + word[i].length)
	// 							inputText.innerHTML = innerHTML
	// 						}
	// 					}

	// 					await sleep(400)
	// 				}
	// 			}
	// 		})()
	// 	}
	// 	return () => {
	// 		if (walkSound) walkSound.unload()
	// 	}
	// }, [optionState.questionerRequest])

	// const isClient = typeof window === "object"

	// const getSize = () => {
	// 	let width = window.innerWidth <= 1600 ? window.innerWidth : 1600
	// 	let height = window.innerHeight
	// 	let availableHeight = window.innerHeight - 56

	// 	let adjustedHeight = availableHeight > (width * 9) / 16 ? (width * 9) / 16 : height - 56
	// 	let adjustedwidth = (adjustedHeight * 16) / 9

	// 	return {
	// 		width: isClient ? Math.ceil(adjustedwidth) : undefined,
	// 		height: isClient ? Math.ceil(adjustedHeight) : undefined,
	// 	}
	// }

	// const windowSize = React.useRef(getSize())
	return (
		<div className="game-options w-100" style={{ height: `${stageData.frameHeight ? stageData.frameHeight : "100%"}` }}>
			<div
				className="options-list h-100"
				style={{ width: stageData.animalContainerWidth ? stageData.animalContainerWidth : "100%" }}
				ref={animalRef}
			>
				<div className="game-title" id="whose-voice">
				वृताआकारमा क्लिक गर्नुहोस् l
				</div>

				<div className="optionMain_div_p6">
				{optionImages.map((item, index) => {
					// console.log(item)
						return(
							<div key={index} className="optionSub_div option_fst" id={item.itemName} onClick={(e) => SecondanswerClick(e)} ref={fstOptRef}>
								<img src={item.imgName}  className="Secopt_img" alt="option_img1" />
								<img src = {item.rightWrongImg} className="rightwrong" alt="correct_incorrect_img"/>
							</div>
						)
					})
				}
					{/* <div className="optionSub_div option_fst" onClick={(e) => SecondanswerClick(e)} ref={fstOptRef}>
						<img src={stageData.optionImg}  className="Secopt_img" alt="option_img1" />
					</div>
					<div className="optionSub_div option_sec" onClick={(e) => SecondanswerClick(e)} ref={secOptRef}>
						<img src={stageData.optionImg}  className="Secopt_img" alt="option_img2" />
					</div>
					<div className="optionSub_div option_third correct" onClick={(e) => SecondanswerClick(e)} ref={thirdOptRef}>
						<img src={stageData.optionImg}  className="Secopt_img" alt="option_img3" />
					</div> */}
				</div>

				<div className="sec_option_txt" id = "rightAnsTxt">
						<p ref={AnsOptRef}>{stageData.OptionTwo}</p>
				</div>

			</div>
		</div>
	)
}

export default MultipleOption
