import { useEffect, useContext } from "react"
import { DispatchContext, StateContext } from "../../../DecodingGame"
/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import { Howl } from "howler"

// images
import Sabina from "./assets/sabina_clapping.png"

const Phase2 = () => {
	const dispatch = useContext(DispatchContext)
	const state = useContext(StateContext)

	useEffect(() => {
		const soundAssets = require.context("./assets/audios")
		const PraseHowl = new Howl({
			src: [soundAssets("./ekdam_ramro.ogg")],
		})
		setTimeout(() => {
			PraseHowl.play()
		}, 800)

		PraseHowl.on("end", () => {
			setTimeout(() => {
				dispatch({ type: "TRANSITION_COMPLETE" })
			}, 500)
		})
	}, [dispatch, state.phase])

	return (
		<div className="phase2">
			<img
				src={Sabina}
				id="sabina1"
				alt="sabina"
				css={css`
					position: absolute;
					width: 16%;
					top: 12%;
					left: 72%;
				`}
			/>
		</div>
	)
}

export default Phase2
