import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "बाँदरको घर",
	key: "badarko-ghar",
	coverImage: images("./cover_page.gif"),

	// Credits section to add information for credit page
	credits: {
		writer: "पुण्यशील गौतम", // Name of the writer | optional
		illustrator: "अनुश्री लामा", // Name of the illustrator | optional
		template: "ratoBangla", // template to be used: write one of 'ratoBangla' or 'default' | required
		bg: images("./cover_page.gif"), // background image for credit page and end modal page | required
		titleAudio: audios("./s0.ogg"), // audio for title vocal, can be usually found on page one, can be copied from there | required
	},
	bgColor: "white", // used to add a bg color to ending pages, writing 'white' adds the class 'bg-white' to credit and end pages | optional
	content: PageContent,
}

export default content
