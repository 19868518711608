import { keyframes } from "@emotion/core"

const appear = keyframes({
	from: {
		opacity: 0,
	},
	to: {
		opacity: 1,
	},
})

const disappear = keyframes({
	from: {
		opacity: 1,
	},
	to: {
		opacity: 0,
	},
})

const transformTo = (transform) => {
	return keyframes({
		to: {
			transform: transform,
		},
	})
}
const transformFrom = (transform) => {
	return keyframes({
		from: {
			transform: transform,
		},
	})
}

const transformFromTo = (transform) => {
	return keyframes({
		from: {
			transform: transform[0],
		},
		to: {
			transform: transform[1],
		},
	})
}

export { appear, disappear, transformTo, transformFrom, transformFromTo}
