// import shuffle from "shuffle-array";
import words from "./words"

const soundEffects = require.context("./audios/sound_effects", true)

// let getOptionsFromLevel = (level, requiredLength, dirtyWord) => {
// 	let levelData = words.filter(data => data.level === level);
// 	if (levelData.length < requiredLength + 1) {
// 		levelData = words;
// 	}

// 	return levelData
// 		.filter(data => data.word !== dirtyWord)
// 		.map(data => data.word)
// 		.slice(0, requiredLength);
// };

let filterLevelData = (level) => {
	return words.filter((data) => data.level === level)
}

const content = {
	sounds: {
		brust: soundEffects("./brust.ogg"),
		blast: soundEffects("./blast.ogg"),
	},
	levels: [
		{
			label: "Easy",
			data: filterLevelData(1),
		},
		{
			label: "Medium",
			data: filterLevelData(2),
		},
		{
			label: "Hard",
			data: filterLevelData(3),
		},
	],
}

// console.log("content", content);
export default content
