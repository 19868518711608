// Warn if overriding existing method
if (Array.prototype.equals)
	console.warn(
		"Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code."
	)
// attach the .equals method to Array's prototype to call it on any array
// eslint-disable-next-line
Array.prototype.equals = function (array) {
	// if the other array is a falsy value, return
	if (!array) return false

	// compare lengths - can save a lot of time
	if (this.length !== array.length) return false

	for (var i = 0, l = this.length; i < l; i++) {
		// Check if we have nested arrays
		if (this[i] instanceof Array && array[i] instanceof Array) {
			// recurse into the nested arrays
			if (!this[i].equals(array[i])) return false
		} else if (this[i] !== array[i]) {
			// Warning - two different object instances will never be equal: {x:20} != {x:20}
			return false
		}
	}
	return true
}
// Hide method from for-in loops
// eslint-disable-next-line
Object.defineProperty(Array.prototype, "equals", { enumerable: false })

export const getRandomInt = (min, max) => {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max + 1 - min)) + min //The maximum and the minimum are both inclusive
}

export const mergeArrays = (array1, array2) => {
	return array1.map((item, i) => {
		if (array2[i] && item.id === array2[i].id) {
			return array2[i]
		} else {
			return item
		}
	})
}
export const customIncludes = (haystack, needle) => {
	let includes = false
	haystack.forEach((elem) => {
		// console.log(elem, needle);
		if (elem.equals(needle)) {
			includes = true
		}
	})
	return includes
}

export const preloadImages = (urls, allImagesLoadedCallback) => {
	let loadedCounter = 0
	let toBeLoadedNumber = urls.length

	const preloadImage = (url, anImageLoadedCallback) => {
		let img = new Image()
		img.onload = anImageLoadedCallback
		img.src = url
	}

	urls.forEach(function (url) {
		preloadImage(url, function () {
			loadedCounter++
			// console.log('Number of loaded images: ' + loadedCounter);
			if (loadedCounter === toBeLoadedNumber) {
				allImagesLoadedCallback()
			}
		})
	})
}

const compareObjects = (o1, o2) => {
	for (let p in o1) {
		if (o1.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false
			}
		}
	}

	for (let p in o2) {
		if (o2.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false
			}
		}
	}
	return true
}

export const reducer = (state, action) => {
	let newState
	switch (action.type) {
		case "SET":
			newState = {
				...state,
				...action.values,
			}
			break

		case "RESET":
			window.location.reload(true)
			newState = {
				...state,
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				levelComplete: false,
				stage: 0,
			}
			break

		case "RESET_STAGE":
			newState = {
				...state,
				...action.values,
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				levelComplete: false,
			}
			break

		case "LOADING_COMPLETE":
			newState = {
				...state,
				loadingAssets: false,
			}
			break
		case "START_GAME":
			newState = {
				...state,
				currentPage: "GAME_START",
			}
			break

		case "START_PLAY":
			newState = {
				...state,
				intro: false,
				playing: true,
				stageComplete: false,
			}
			break

		case "STAGE_COMPLETE":
			newState = {
				...state,
				intro: false,
				playing: false,
				stageComplete: true,
			}
			break

		case "REWARD_COMPLETE":
			newState = {
				...state,
				rewardComplete: true,
			}
			break

		case "PREV_STAGE":
			newState = {
				...state,
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				stage: state.stage - 1,
			}
			break

		case "NEXT_STAGE":
			console.log("next stage", state.levelData.length, state.stage, state.levelData.length > state.stage)
			newState =
				state.levelData.length > state.stage + 1
					? {
							...state,
							intro: true,
							playing: false,
							stageComplete: false,
							rewardComplete: false,
							stage: state.stage + 1,
					  }
					: {
							...state,
							intro: false,
							playing: false,
							stageComplete: true,
							rewardComplete: true,
							levelComplete: true,
					  }
			break
		default:
			newState = state
	}
	if (compareObjects(newState, state)) {
		return state
	} else {
		return newState
	}
}
