const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page01.jpg"),
			class: "image-two",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center slideleftbottom",
			textIndivData: [
				{
					textData: "असारको बिदामा शशी मामाघर गएकी थिइन्।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 2.3 }, { start: 3.3 }, { start: 4 }, { start: 4.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s2.ogg"),
			delay: 2000,
		},
	],
}

export default content
