// tools
import { uuidv4Generator } from "../../utils/uuidv4Generator"
import history from "../../utils/history"

// configs
import { snakeToFullCamel } from "../../helpers/strings"

export const getPageContent = (pageNumber = 1, storyData = null) => {
	let imageList = storyData.content.filter((image, index) => pageNumber === index + 1)[0]
	let soundList = storyData.content.filter((sound, index) => pageNumber === index + 1)[0]
	let textList = storyData.content.filter((text, index) => pageNumber === index + 1)[0]
	return {
		pageNumber: pageNumber,
		imageContainerClass: imageList ? imageList.imageContainerClass : null,
		imageContainerStyles: imageList ? imageList.imageContainerStyles : null,
		imageList: imageList ? imageList.images : [],
		textList: textList ? textList.texts : null,
		soundList: soundList ? soundList.sounds : null,
	}
}

export const getStoryData = (storyKey) => {
	const storyContentLocation = `${snakeToFullCamel(storyKey)}/Content`
	let storyData = null
	storyData = require("../../EGRContent/Story/" + storyContentLocation).default
	console.log(require("../../EGRContent/Story/" + storyContentLocation).default)
	console.log(storyData)
	console.log(storyContentLocation)
	let data
	if (storyData.credits) {
		let creditText =
			storyData.credits.template === "default"
				? []
				: [
						{
							textData: "यो कथा रातो बङ्गला किताबद्वारा प्रकाशित सुकुली भुकुली कथा सङ्ग्रहबाट लिइएको हो। ",
							textClass: "fz-2 ratoB-disclaimer",
						},
				  ]

		let creditWriterIllustrator = storyData.credits.writerANDillustrator
			? [
					{
						textData: `लेखन तथा चित्राङ्कन: ${storyData.credits.writerANDillustrator}`,
						textClass: "one-line credit alone-credit",
					},
			  ]
			: []
		let creditWriter = storyData.credits.writer
			? [
					{
						textData: `लेखन: ${storyData.credits.writer}`,
						textClass: "one-line credit alone-credit",
					},
			  ]
			: []

		let creditIllustrator = storyData.credits.illustrator
			? [
					{
						textData: `चित्राङ्कन: ${storyData.credits.illustrator}`,
						textClass: "one-line credit alone-credit",
					},
			  ]
			: []
		let creditAudio = storyData.credits.audio
			? [
					{
						textData: `आवाज: ${storyData.credits.audio}`,
						textClass: "one-line credit alone-credit",
					},
			  ]
			: []
		let creditMusic = storyData.credits.music
			? [
					{
						textData: `संगीत: ${storyData.credits.music}`,
						textClass: "one-line credit alone-credit",
					},
			  ]
			: []

		data = {
			...storyData,
			content: [
				...storyData.content,
				{
					imageContainerClass: storyData.bgColor ? `bg-${storyData.bgColor}` : "bg-extended",
					images: [
						{
							src: storyData.credits.bg,
						},
					],
					texts: [
						{
							textDivClass: "story-text text-center end-credit",
							textIndivData: [
								{
									textData: storyData.name,
									textClass:
										storyData.credits.template === "default"
											? "ec-story-title alone-title one-line"
											: "ec-story-title one-line",
								},
								...creditWriter,
								...creditWriterIllustrator,
								...creditIllustrator,
								...creditAudio,
								...creditMusic,
								{
									textData:
										"बालबालिकाहरूले सानै देखि रमाउँदै पढ्न सिकुन् भन्‍ने उद्देश्य राखि साझा शिक्षा ई–पाटीले यी श्रव्य-दृश्य सहितका कथाहरू तयार पारेका हुन्। यस्ता रमाइला कथाहरूसितको अन्तर्क्रियाबाट बच्चाहरूमा पढ्न प्रतिको रूची जागुन् भन्‍ने आशा राखेका छौँ।",
									textClass: "fz-2 end-disclaimer",
								},
								...creditText,
							],
						},
					],
					sounds: [
						{
							url: storyData.credits.titleAudio,
							delay: 500,
						},
					],
				},
				{
					imageContainerClass: storyData.bgColor ? `bg-${storyData.bgColor}` : "bg-extended",
					images: [
						{
							src: storyData.credits.bg,
						},
					],
				},
			],
		}
	} else {
		data = storyData
	}

	if (data.content)
		data.content = data.content.map((contents) => {
			if (contents.imageContainerClass || !storyData.bgColor) {
				return contents
			} else {
				return {
					...contents,
					imageContainerClass: `bg-${storyData.bgColor}`,
				}
			}
		})

	let result = {
		...data,
		totalPages: data.content.length,
		containerClass: snakeToFullCamel(storyData.key) + "Container",
		name: storyData.name,
		key: storyData.key,
		label: storyData.label,
		coverImage: storyData.coverImage,
	}
	// console.log('result', result);
	return result
}

export const reducer = (state, action) => {
	switch (action.type) {
		case "SET":
			return {
				...state,
				...action.values,
				uniqueKey: uuidv4Generator(),
			}

		case "RESET":
			return {
				...state,
				uniqueKey: uuidv4Generator(),
			}

		case "START_READ":
			localStorage.setItem("audioMute", true)
			return {
				...state,
				currentPage: state.currentPage + 1,
				pageContent: getPageContent(state.currentPage + 1, getStoryData(state.storyData.key, true)),
				audioMute: true,
			}

		case "START_LISTEN":
			localStorage.setItem("audioMute", false)
			return {
				...state,
				currentPage: state.currentPage + 1,
				pageContent: getPageContent(state.currentPage + 1, getStoryData(state.storyData.key, true)),
				audioMute: false,
			}

		case "READ":
			localStorage.setItem("audioMute", true)
			return {
				...state,
				uniqueKey: uuidv4Generator(),
				audioMute: true,
			}

		case "LISTEN":
			localStorage.setItem("audioMute", false)

			return {
				...state,
				uniqueKey: uuidv4Generator(),
				audioMute: false,
			}
		case "NEXT":
			history.push(
				`${process.env.REACT_APP_URL ? process.env.REACT_APP_URL : ""}/story/${state.storyData.key}/${state.currentPage + 1}`
			)
			return {
				...state,
				currentPage: state.currentPage + 1,
				pageContent: getPageContent(state.currentPage + 1, getStoryData(state.storyData.key, true)),
			}

		case "PREV":
			history.push(
				`${process.env.REACT_APP_URL ? process.env.REACT_APP_URL : ""}/story/${state.storyData.key}/${state.currentPage - 1}`
			)
			return {
				...state,
				currentPage: state.currentPage - 1,
				pageContent: getPageContent(state.currentPage - 1, getStoryData(state.storyData.key, true)),
			}

		case "RESTART_READ":
			localStorage.setItem("audioMute", true)
			history.push(`${process.env.REACT_APP_URL ? process.env.REACT_APP_URL : ""}/story/${state.storyData.key}/1`)
			return {
				...state,
				currentPage: 1,
				pageContent: getPageContent(1, getStoryData(state.storyData.key, true)),
				audioMute: true,
			}

		case "RESTART_LISTEN":
			localStorage.setItem("audioMute", false)
			history.push(`${process.env.REACT_APP_URL ? process.env.REACT_APP_URL : ""}/story/${state.storyData.key}/1`)
			return {
				...state,
				currentPage: 1,
				pageContent: getPageContent(1, getStoryData(state.storyData.key, true)),
				audioMute: false,
			}

		default:
			return state
	}
}

export const preloadPagesImages = (pagesImages, allPagesImagesLoadedCallback) => {
	const preloadImages = (urls, allImagesLoadedCallback) => {
		let loadedCounter = 0
		let toBeLoadedNumber = urls.length

		const preloadImage = (url, anImageLoadedCallback) => {
			let img = new Image()
			img.onload = anImageLoadedCallback
			img.src = url
		}

		urls.forEach(function (url) {
			preloadImage(url, function () {
				loadedCounter++
				// console.log('Number of loaded images: ' + loadedCounter);
				if (loadedCounter === toBeLoadedNumber) {
					allImagesLoadedCallback()
				}
			})
		})
	}

	// console.log(pagesImages);
	let loadedCounter = 0
	let toBeLoadedNumber = pagesImages.length
	pagesImages.forEach(function (urls) {
		preloadImages(urls, () => {
			// console.log('Number of pages loaded : ' + loadedCounter);
			if (++loadedCounter === toBeLoadedNumber) {
				allPagesImagesLoadedCallback()
			}
		})
	})
}
