const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background5.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./lemon.png"),
			class: "image-one imageCenter element1 imgSize imglemon",
			imgdiv: "imageCenterDiv _mt-6 _h-40",
		},
		{
			src: images("./lasun.png"),
			class: "image-one imageCenter element2 imgSize imglasun",
			imgdiv: "imageCenterDiv _mt-6",
		},
		{
			src: images("./color.gif"),
			class: "image-one imageCenter element3 imgSize imgcolor",
			imgdiv: "imageCenterDiv _mt-5",
			gif: true,
			img: images("./Orange.png"),
			timesec: 3000,
		},

		{
			src: images("./orange_un.png"),
			class: "image-one imageCenter element4 imgSize imgorange_un",
			imgdiv: "imageCenterDiv _mt-6",
		},
		{
			src: images("./orange.gif"),
			class: "image-one imageCenter element5 imgSize imgcoconut",
			imgdiv: "imageCenterDiv _mt-6",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म कागती जस्तै डल्लो देखिन्छु।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 1 }, { start: 1.9 }, { start: 2.5, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "भित्रबाट म लसुन जस्तो देखिन्छु।",
					textClass: "m-0",
					timestamps: [{ start: 9 }, { start: 9.8 }, { start: 10.6 }, { start: 10.9 }, { start: 11.2, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "पहेँलोमा रातो रङ मिसायो भने मेरो रङ बन्छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 20 },
						{ start: 21 },
						{ start: 21.5 },
						{ start: 21.9 },
						{ start: 22.4 },
						{ start: 22.6 },
						{ start: 23 },
						{ start: 23.4, last: true },
					],
				},
			],
		},

		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 31 },
						{ start: 31.2 },
						{ start: 31.5 },
						{ start: 32.1 },
						{ start: 32.4 },
						{ start: 32.5 },
						{ start: 32.5, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element5 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म सुन्तला हुँ।",
					textClass: "m-0",
					timestamps: [{ start: 42 }, { start: 42.1 }, { start: 42.5, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p5a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p5a2.wav"),
			delay: 13000,
		},
		{
			url: audios("./p5a3.wav"),
			delay: 24000,
		},
		{
			url: audios("./p5a4.wav"),
			delay: 35000,
		},
		{
			url: audios("./p5a5.wav"),
			delay: 46000,
		},
	],
}

export default content
