const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky fullbgt",
	images: [
		{
			src: images("./13to24/spacer.svg"),
			imgdiv: "imageCover",
			styles: { background: "linear-gradient(to top, #fffe00, #ff931e)", opacity: 0 },
		},
		{
			src: images("./13to24/sun.png"),
			imgdiv: "imageCover sun ",
		},
		{
			src: images("./13to24/hill.svg"),
			imgdiv: "hill-anim hill",
			styles: { position: "absolute", bottom: 0 },
		},
		{
			src: images("./13to24/flying.gif"),
			imgdiv: "imageCover flying",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-anim-1 cloud-1",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-anim-2 cloud-2",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-anim-3 cloud-3",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-anim-4 cloud-4",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "त्यसैले पानीहाँसहरू थाके पनि मेहेनतका साथ अगाडि बढ्दै गए। सुर्य अस्ताउनै लागेको थियो।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.8 },
						{ start: 2 },
						{ start: 2.7 },
						{ start: 3.3 },
						{ start: 3.9 },
						{ start: 4.4 },
						{ start: 4.9 },
						{ start: 5.5 },
						{ start: 6 },
						{ start: 6.4 },
						{ start: 7 },
						{ start: 7.5 },
						{ start: 8 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p4_s3.ogg"),
			delay: 500,
		},
	],
}

export default content
