import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page3Scene",
	images: [
		{
			src: images("./page3_didi_bg.jpg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./nima_talking_dai.gif"),
			imgdiv: "imageCover nima_talk-gif-p3",
		},
		{
			src: images("./nima_walk.png"),
			imgdiv: "imageCover nima-p3",
		},
		{
			src: images("./leaf03.png"),
			imgdiv: "imageCover leaf3",
		},
		{
			src: images("./leaf01.png"),
			imgdiv: "imageCover leaf leaf1",
		},
		{
			src: images("./leaf02.png"),
			imgdiv: "imageCover leaf leaf2",
		},
		{
			src: images("./swipping_dai_new.gif"),
			imgdiv: "imageCover dai_swipping",
		},
		{
			src: images("./smoke_leaves.png"),
			imgdiv: "imageCover smoke_idle",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center dialogueBubble bubble-sm-left-o text-bubble-p3",
			textIndivData: [
				{
					textData: "हिजोको हावाहुरीले जताततै पातैपात भएको रहेछ। तपाईंले सफा गरिदिनु भएछ।",
					textClass: "m-0 ",
					timestamps: [
						{ start: 0 },
						{ start: 0.7 },
						{ start: 1.6 },
						{ start: 2.55 },
						{ start: 3.25 },
						{ start: 3.8 },
						{ start: 4.45 },
						{ start: 4.9 },
						{ start: 5.35 },
						{ start: 5.9 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./page3.ogg"),
			delay: 2500,
		},
		{
			url: audios("./sweeping.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./birds.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./blank.ogg"),
			delay: 16000,
		},
	],
}

export default content
