import React, { useState, useContext } from "react"
import { Howl } from "howler"
import CustomModal from "../../components/LevelCompleteModal/CustomModal"
// import Modal from 'react-modal';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import DeviceOrientation, { Orientation } from "react-screen-orientation"
import _ from "underscore"
import gsap from "gsap"

// assets
import "./stage.styles.scss"
import "./stage2.styles.scss"

// import PlayerL2 from "../../components/Player/PlayerL2"

import MobileImage from "./assets/mobile.png"
import Toolbar from "../../components/Toolbar/Toolbar"
import GameBgImg from "./assets/bg_tree2.png"
import NutsBranch from "./assets/level2/leaves/branch_for_nuts.png"
import Nuts from "./assets/level2/leaves/nuts.png"
import Rope from "./assets/level2/leaves/rope.png"
import Snake from "../../content/images/level2/snake_01.gif"
import Snake2 from "../../content/images/level2/snake2.gif"
import Butterfly from "../../content/images/level2/butterfly_01.gif"
import Dream from "../../components/Dream/Dream"

import Squirrel01 from "../../components/Player/assets/squirrel05.png"
import Squirrel02 from "../../components/Player/assets/squirrel_happy.png"
import Squirrel03 from "../../components/Player/assets/squirrel03.png"
import Squirrel03b from "../../components/Player/assets/squirrel03b.png"
import nuts3Squirrel from "./assets/squirrel_with_nuts.png"

import leaf1 from "./assets/level2/leaves/leaf01.png"
import leaf2 from "./assets/level2/leaves/leaf02.png"
import leaf3 from "./assets/level2/leaves/leaf03.png"
import leaf4 from "./assets/level2/leaves/leaf04.png"
import leaf5 from "./assets/level2/leaves/leaf05.png"
import leaf6 from "./assets/level2/leaves/leaf06.png"
import leaf9 from "./assets/level2/leaves/leaf09.png"

import DreamImg from "../../components/Dream/assests/ladder_clouds.png"
//audio
import tinkAudio from "../../content/audios/sound_effects/stoneClick.ogg"
import tadaAudio from "../../content/audios/sound_effects/tada.ogg"
import yahooAudio from "../../content/audios/sound_effects/minion-yahoo.ogg"
import popAudio from "../../content/audios/sound_effects/pop.ogg"
import woodBreakAudio from "../../content/audios/sound_effects/breakwood.ogg"

import { StateContext, DispatchContext } from "../../MatchTheWordGame"

const Stage = () => {
	const assets = require.context("../../content/images/level2")
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)

	const [isWordComplete, setWordComplete] = useState(false)
	const [SquirrelImg, setSquirrelImg] = useState(Squirrel01)
	const [isDragDisabled, setIsDragDisabled] = useState(false)

	// const [ansLetterObj, setAnsLetterObj] = useState({
	// 	isComplete: false,
	// 	ansLetter: "?",
	// })

	//audios

	console.log(state)

	const tinkHowl = new Howl({
		src: tinkAudio,
		loop: false,
		volume: 1,
	})

	const tadaHowl = new Howl({
		src: tadaAudio,
		loop: false,
		volume: 0.5,
	})
	const woodBreakHowl = new Howl({
		src: woodBreakAudio,
		loop: false,
		volume: 0.5,
	})
	const yahooHowl = new Howl({
		src: yahooAudio,
		loop: false,
		volume: 0.5,
	})
	const popHowl = new Howl({
		src: [popAudio],
		preload: true,
		volume: 0.5,
		loop: false,
	})
	let opts = JSON.parse(JSON.stringify(state.levelData[state.stage].dragItems))

	let list = {
		options: opts.reduce((acc, current) => {
			return {
				...acc,
				[current.id]: current,
			}
		}, {}),
		optionsId: opts.map((option) => option.id),
	}

	const [optionsList, setOptionsList] = useState(list)
	let orderedOptions = optionsList.optionsId.map((id) => optionsList.options[id])

	// const dispatch = useContext(DispatchContext);
	function playHintAudio(audio) {
		const hintAudio = new Howl({
			src: [audio],
			loop: false,
			volume: 1,
		})

		hintAudio.play()
	}

	React.useEffect(() => {
		const preloadImages = (urls, allImagesLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadImage = (url, anImageLoadedCallback) => {
				let img = new Image()
				img.src = url
				img.onload = anImageLoadedCallback
			}

			urls.forEach(function (url) {
				preloadImage(url, function () {
					loadedCounter++
					// console.log('Number of loaded images: ' + loadedCounter);
					if (loadedCounter === toBeLoadedNumber) {
						allImagesLoadedCallback()
					}
				})
			})
		}

		let images = []
		console.log(state)

		preloadImages(images, () => {})
	})

	React.useEffect(() => {
		if (state.stage === 0) {
			if (state.rewardComplete === false) {
				gsap.set(".dreamImg", {
					top: " 34%",
					left: "66%",
				})
				gsap.set(".md-bubbleImg", {
					top: " 61%",
					left: "56%",
					width: "4%",
				})
				gsap.set(".sm-bubbleImg", {
					top: " 70%",
					left: "50%",
					width: "3%",
				})

				let introTl = new gsap.timeline({
					onComplete: function () {
						dispatch({ type: "REWARD_COMPLETE" })
					},
				})
				gsap.set("#level2Stage-mtw", {
					scale: 2,
					rotation: 0.01,
					xPercent: 50,
					yPercent: 50,
				})
				introTl
					.to("#level2Stage-mtw", {
						scale: 2,
						rotation: 0.01,
						xPercent: 24,
						yPercent: 50,
						ease: "power1.easeInOut",
						delay: 2,
						duration: 2,
					})
					.to("#level2Stage-mtw", {
						scale: 2,
						rotation: 0.01,
						xPercent: 24,
						yPercent: -48,
						ease: "power1.easeInOut",
						duration: 4,
					})
					.to("#level2Stage-mtw", {
						scale: 2,
						rotation: 0.01,
						xPercent: 6,
						yPercent: -48,
						ease: "power1.easeInOut",
						duration: 1.5,
					})
					.to("#level2Stage-mtw", {
						scale: 1.8,
						rotation: 0.01,
						xPercent: -40,
						yPercent: -37,
						ease: "power1.easeInOut",
						duration: 2.5,
					})
					.to("#level2Stage-mtw", {
						scale: 1,
						rotation: 0.01,
						xPercent: 0,
						yPercent: 0,
						ease: "power1.easeInOut",
						duration: 2,
					})
					.to(".sm-bubbleImg", {
						scale: 1,
						opacity: 1,
						duration: 0.5,
						onComplete: function () {
							popHowl.play()
						},
					})
					.to(".md-bubbleImg", {
						scale: 1,
						opacity: 1,
						duration: 0.5,
						onComplete: function () {
							popHowl.play()
						},
					})
					.to(".dreamImg", {
						scale: 2.5,
						opacity: 1,
						duration: 1,
						onComplete: function () {
							popHowl.play()
						},
					})
			}
		}
	})
	React.useEffect(() => {
		if (state.isLastScene) {
			let endAnimation = new gsap.timeline()
			endAnimation
				.to(".hintHolder-mtw2", {
					scale: 0,
					duration: 0.5,
				})
				.to(".darkShade", {
					opacity: 0,
					duration: 0.2,
				})
				.add(() => {
					setSquirrelImg(Squirrel03)
				})
				.to(".pL2Squ-left", {
					xPercent: -135,
					yPercent: -22,
					duration: 1.6,
				})
				.to(".pL2Squ-right", {
					xPercent: -328,
					yPercent: -22,
					duration: 1.6,
				})
				.add(() => {
					dispatch({ type: "REWARD_COMPLETE" })
				})
		}
	})

	let columns = []
	for (let i = 1; i < 11; i++) {
		columns.unshift(
			<Droppable droppableId={`${i}`} key={`${i}`} isDropDisabled={i !== state.stage}>
				{(provided, snapshot) => (
					<div
						ref={provided.innerRef}
						{...provided.droppableProps}
						className={`plankHolder ${snapshot.isDraggingOver ? "plankDragOver" : ""}`}
						id={`plankHolder-${i}`}
					>
						<img
							src={assets(`./wood${state.levelData[i].question.questionImageIdx}.png`)}
							alt="plank"
							className={`plank ${i < state.stage || (isWordComplete && i === state.stage) ? "glow" : ""}`}
						/>

						{((isWordComplete && i === state.stage) || i < state.stage) && (
							<h3 className={state.levelData[state.stage].dropItems.font + " plank-text"}>{state.levelData[i].word}</h3>
						)}

						{state.stage === i && !isWordComplete && (
							<React.Fragment>
								{state.levelData[state.stage].question.questionIndex === 0 ? (
									<div
										className="ques_plank-div ques_plank0-div"
										onClick={_.debounce(() => {
											playHintAudio(state.levelData[state.stage].question.audioHint)
										}, 250)}
									>
										<img
											className="ques_plank ques_plank-0"
											alt="plank"
											src={assets(`./wood${state.levelData[state.stage].question.questionImageIdx}_0.png`)}
										/>
										<h3 className={state.levelData[state.stage].dropItems.font + " plank-text"}>
											{state.levelData[state.stage].question.letter}
										</h3>
									</div>
								) : (
									<div
										className="ques_plank-div ques_plank1-div"
										onClick={_.debounce(() => {
											playHintAudio(state.levelData[state.stage].question.audioHint)
										}, 250)}
									>
										<img
											className="ques_plank ques_plank-0"
											alt="plank"
											src={assets(`./wood${state.levelData[state.stage].question.questionImageIdx}_1.png`)}
										/>
										<h3 className={state.levelData[state.stage].dropItems.font + " plank-text"}>
											{state.levelData[state.stage].question.letter}
										</h3>
									</div>
								)}
							</React.Fragment>
						)}
					</div>
				)}
			</Droppable>
		)
	}
	const onDragEnd = (result) => {
		const { destination, source } = result
		setIsDragDisabled(true)

		if (!destination) {
			setIsDragDisabled(false)
			return
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			setIsDragDisabled(false)
			return
		}

		const newOptionsId = Array.from(optionsList.optionsId)
		if (destination.droppableId === source.droppableId && destination.index !== source.index) {
			newOptionsId.splice(source.index, 1)
			newOptionsId.splice(destination.index, 0, optionsList.optionsId[source.index])
			setOptionsList({ options: optionsList.options, optionsId: newOptionsId })
			setIsDragDisabled(false)
			return
		}
		if (destination.droppableId !== source.droppableId) {
			let newOptionsOrder = JSON.parse(JSON.stringify(optionsList.optionsId))
			let newOptions = JSON.parse(JSON.stringify(optionsList.options))
			if (orderedOptions[source.index].match === state.levelData[state.stage].dropItems.match) {
				// let ansName = orderedOptions[source.index].name
				// setAnsLetterObj({
				// 	isComplete: true,
				// 	ansLetter: ansName.letter,
				// })

				newOptionsOrder.splice(source.index, 1)
				tinkHowl.play()
				setWordComplete(true)
				Object.keys(newOptions).map((key) => {
					newOptions[key].isSinking = true
					newOptions[key].name.letter = ""
					return newOptions
				})
				setOptionsList({ options: newOptions, optionsId: newOptionsOrder })
				let jumpTl = new gsap.timeline()
				yahooHowl.play()
				if (state.stage === 1) {
					jumpTl
						.add(() => {
							setSquirrelImg(Squirrel03)
						})
						.to("#squirrelL2", { xPercent: -60, yPercent: -60, duration: 0.5 })
						.to("#squirrelL2", { xPercent: -86, yPercent: -48, duration: 0.5 })
						.add(() => {
							setSquirrelImg(Squirrel02)
						})
				} else {
					if (state.levelData[state.stage].question.questionIndex) {
						jumpTl
							.add(() => {
								setSquirrelImg(Squirrel03b)
							})
							.to("#squirrelL2", { xPercent: 92, yPercent: -70, duration: 0.8 })
							.to("#squirrelL2", { xPercent: 92, yPercent: -56, duration: 0.4 })
							.add(() => {
								setSquirrelImg(Squirrel02)
							})
					} else {
						jumpTl
							.add(() => {
								setSquirrelImg(Squirrel03)
							})
							.to("#squirrelL2", { xPercent: -75, yPercent: -70, duration: 0.8 })
							.to("#squirrelL2", { xPercent: -75, yPercent: -56, duration: 0.4 })
							.add(() => {
								setSquirrelImg(Squirrel02)
							})
					}
				}
				yahooHowl.on("end", function () {
					gsap.to(".hintHolder-mtw2", {
						x: "-85%",
						y: "32%",
						scale: 1.6,
						duration: 1,
						delay: 1,
						onStart: function () {
							tadaHowl.play()
							gsap.to(".darkShade", {
								opacity: 1,
								duration: 0.2,
							})
						},
						onComplete: function () {
							if (document.getElementById("hint-text")) {
								document.getElementById("hint-text").innerText = state.levelData[state.stage].word
								document.getElementById("hint-text").classList.add("appearAns")
							}
							playHintAudio(state.levelData[state.stage].audioHint)
							setOptionsList({ options: optionsList.options, optionsId: [] })

							if (state.stage === 10) {
								setTimeout(() => {
									dispatch({ type: "LASTSCENE" })
								}, 3000)
							} else {
								dispatch({ type: "REWARD_COMPLETE" })
							}
						},
					})
				})
				setIsDragDisabled(false)
			} else {
				woodBreakHowl.play()
				orderedOptions[source.index].isSinking = true
				orderedOptions[source.index].name.letter = ""
				setOptionsList({ options: optionsList.options, optionsId: newOptionsOrder })
				setTimeout(function () {
					newOptionsOrder.splice(source.index, 1)
					setOptionsList({ options: optionsList.options, optionsId: newOptionsOrder })
					setIsDragDisabled(false)
				}, 3000)
			}
		}
	}
	return (
		<StateContext.Consumer>
			{(state) => {
				if (state === undefined) {
					throw new Error("StateConsumer must be used within a StateProvider")
				}
				return (
					<div className="game-stage-mtw">
						{
							// ((state.stage !== 0) && state.playing) &&
							<React.Fragment>
								<div
									style={{
										backgroundImage: `url(${GameBgImg})`,
										width: "100%",
										height: "100%",
										backgroundSize: "95%",
									}}
									id="level2Stage-mtw"
								>
									<DeviceOrientation lockOrientation={"landscape"}>
										<Orientation orientation="portrait" alwaysRender={false}>
											<div className="rotateMobilePrompt">
												<div className="rotateMessageBox">
													<p className="rotateText-p">Please rotate your device</p>
													<img className="rotateImg" src={MobileImage} alt="rotate your phone" />
												</div>
											</div>
										</Orientation>
									</DeviceOrientation>

									<DeviceOrientation lockOrientation={"landscape"}>
										<Orientation orientation="landscape" alwaysRender={false}>
											<img className="rope" src={Rope} alt="Rope" />

											<DragDropContext
												// onBeforeCapture={onBeforeCapture}
												onDragStart={() => setIsDragDisabled(true)}
												onDragEnd={onDragEnd}
											>
												{state.stage !== 0 && <div className="ladderHolder">{columns}</div>}

												<img className="goal-nut-branch" src={NutsBranch} alt="nuts branch" />
												<img className="goal-nut" src={Nuts} alt="nuts" />

												<img className="leaf09" src={leaf9} alt="leaf9" />
												<img className="leaf01" src={leaf1} alt="leaf1" />
												<img className="leaf06" src={leaf6} alt="leaf6" />
												<img className="leaf04" src={leaf4} alt="leaf4" />
												<img className="leaf03" src={leaf3} alt="leaf3" />

												<img className="leaf02" src={leaf2} alt="leaf2" />
												<img className="leaf05" src={leaf5} alt="leaf5" />
												<img className="leaf08" src={leaf5} alt="leaf8" />

												<div className="optionsHolder">
													<Droppable droppableId="dragZone" direction="horizontal">
														{(provided) => (
															<div {...provided.droppableProps} ref={provided.innerRef}>
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	{orderedOptions.map((option, index) => (
																		<Draggable
																			key={`step${index}`}
																			draggableId={`drag-${index}`}
																			index={index}
																			isDragDisabled={isDragDisabled}
																		>
																			{(provided, snapshot) => (
																				<div
																					{...provided.draggableProps}
																					{...provided.dragHandleProps}
																					ref={provided.innerRef}
																					// isDragging={snapshot.isDragging}
																					// style={getStyle(provided.draggableProps.style, snapshot)}
																					id={`drag-${index}`}
																					// style={{width: "25%"}}
																					onClick={_.debounce(() => {
																						state.stage !== 0 && playHintAudio(option.audioHint)
																					}, 250)}
																				>
																					<div className={"optionStep"}>
																						{option.isSinking ? (
																							<img
																								className="paddle-img woodGif"
																								id={`plank-${index}`}
																								alt="plank5"
																								src={assets(
																									`./dwood-${option.image.charAt(
																										option.image.length - 1
																									)}.gif`
																								)}
																							/>
																						) : (
																							<>
																								<img
																									className="paddle-img"
																									id={`paddle-${index}`}
																									alt="paddle hing"
																									src={assets(`${option.image}.png`)}
																								/>
																								{state.stage !== 0 && (
																									<h3 className={"plank-text "}>
																										{option.name.letter}
																									</h3>
																								)}
																							</>
																						)}
																					</div>
																				</div>
																			)}
																		</Draggable>
																	))}
																	{provided.placeholder}
																</div>
															</div>
														)}
													</Droppable>
												</div>
											</DragDropContext>

											<div
												className={`playerL2Squ pL2Squ-${state.stage} pL2Squ-${
													state.levelData[state.stage].question.questionIndex ? "left" : "right"
												}`}
												id="squirrelL2"
											>
												<img id="squirrel" className="squirrel" src={SquirrelImg} alt="playerL2" />
											</div>

											<img className="snake2" src={Snake2} alt="snake" />
											<img className="snake1" src={Snake} alt="snake" />
											<img className="butterfly1" src={Butterfly} alt="Butterfly" />
											<img className="butterfly2" src={Butterfly} alt="Butterfly" />
											{isWordComplete && <div className="darkShade" />}
											{state.stage !== 0 && (
												<div
													className="hintHolder-mtw2"
													onClick={_.debounce(() => {
														playHintAudio(state.levelData[state.stage].audioHint)
													}, 250)}
												>
													<img
														src={state.levelData[state.stage].incentiveImage[0].src}
														alt="hint"
														style={{
															width: "80%",
															objectFit: "contain",
															cursor: "pointer",
															borderRadius: "8px",
														}}
													/>
													{
														<div className="hint-text hint-text2 " id="hint-text">
															{!isWordComplete && (
																<img
																	src={state.levelData[state.stage].incentiveImage[1].src}
																	alt="speaker"
																	style={{
																		width: "80%",
																	}}
																/>
															)}
														</div>
													}
												</div>
											)}
										</Orientation>
									</DeviceOrientation>
								</div>
							</React.Fragment>
						}
						{state.isLastScene && state.rewardComplete && (
							<div className={`endMsg-modal`}>
								<div className={`msg-modal ${state.isLastScene && state.rewardComplete ? "scaleModal" : ""} `}>
									<img
										src={nuts3Squirrel}
										className={`${state.isLastScene && state.rewardComplete ? "scaleModal jumpSqu" : ""} `}
										alt="happy squirrel"
									/>
								</div>
							</div>
						)}
						{state.stage === 0 && <Dream DreamImg={DreamImg} />}

						<Toolbar totalPage={state.levelData.length} currentPage={state.stage} />

						<CustomModal
							isOpen={state.levelComplete}
							// isOpen={state.stageRewardComplete}
							ariaHideApp={false}
						/>
					</div>
				)
			}}
		</StateContext.Consumer>
	)
}

// Stage.whyDidYouRender = true;
// const StageMemo = React.memo(Stage);
// StageMemo.whyDidYouRender = true;

export default Stage
