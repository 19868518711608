import React, { useReducer, useEffect } from "react"
import _ from "underscore"
// import {isMobile} from "react-device-detect";

import Stage1 from "./pages/Stage/Stage1"
import Stage2 from "./pages/Stage/Stage2"
import Stage3 from "./pages/Stage/Stage3"

// styles
import "./game.styles.scss"

// helpers
import { reducer } from "./helpers/helpers"
import gameContent from "./content/Content"
import { getQueryVariable } from "../mainHelper"
import shuffle from "shuffle-array"

import bg_img from "./pages/Stage/assets/bgl2.png"

import bg_happy_place from "./content/audios/sound_effects/happy_place.ogg"
import { Howl } from "howler"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

export const DispatchContext = React.createContext(null)
export const StateContext = React.createContext(null)

// console.log(gameContent);

const CrosswordPuzzle = (props) => {
	const settings = { audioHints: true }

	const useWindowSize = () => {
		const isClient = typeof window === "object"

		const getSize = () => {
			let width = window.innerWidth <= 1600 ? window.innerWidth : 1600
			let height = window.innerHeight
			let availableHeight = window.innerHeight - 56
			let adjustedHeight, adjustedwidth
			// console.log('wh: ', width, height);
			adjustedHeight = availableHeight > (width * 9) / 16 ? (width * 9) / 16 : height - 56
			adjustedwidth = (adjustedHeight * 16) / 9

			// console.log('awh: ', adjustedwidth, adjustedHeight);

			return {
				width: isClient ? Math.ceil(adjustedwidth) : undefined,
				height: isClient ? Math.ceil(adjustedHeight) : undefined,
			}
		}

		const windowSize = React.useRef(getSize())

		// const [windowSize, setWindowSize] = React.useState(getSize);

		// resize hook
		React.useEffect(() => {
			const isClient = typeof window === "object"
			if (!isClient) {
				return false
			}

			const handleResize = _.debounce(() => {
				windowSize.current = getSize()
				dispatch({
					type: "RESET",
					values: { windowSize: windowSize.current },
				})
			}, 500)

			window.addEventListener("resize", handleResize)
			return () => window.removeEventListener("resize", handleResize)
			// eslint-disable-next-line
		}, [])
		// Empty array ensures that effect is only run on mount and unmount

		return windowSize.current
	}

	// const levelData = getLevelData("Easy");
	const [state, dispatch] = useReducer(reducer, {
		settings: settings,
		currentPage: "GAME_START",
		// level: 'easy',
		level: getQueryVariable("level") || "easy",
		stage: 0,
		levelData: [],
		loadingAssets: true,
		intro: true,
		playing: false,
		stageComplete: false,
		rewardComplete: false,
		levelComplete: false,
		bg: bg_img,
		windowSize: useWindowSize(),
	})

	let windowSize = useWindowSize()
	useEffect(() => {
		// console.log(windowSize);
		const levelData = {
			contentWidth: gameContent[state.level].width,
			contentHeight: gameContent[state.level].height,
			isVerticalLayout: windowSize.width < 750 || windowSize.height < 490,
			words: shuffle(gameContent[state.level].words),
		}
		// console.log('levelData', levelData, );

		dispatch({ type: "SET", values: { levelData: levelData } })
		// console.log(levelData, state);

		const bgMusic = new Howl({
			src: [bg_happy_place],
			autoplay: false,
			preload: true,
			volume: 0.01,
			loop: true,
			onload: () => {
				dispatch({ type: "LOADING_COMPLETE" })
				// console.log("audioLoaded");
			},
		})

		// bgMusic.play();

		return () => {
			bgMusic.unload()
		}
	}, [windowSize, state.level])

	useEffect(() => {
		if (!localStorage.getItem("CrosswordPuzzle")) {
			localStorage.setItem("CrosswordPuzzle", JSON.stringify(state.settings))
		}

		const preloadImages = (urls, allImagesLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadImage = (url, anImageLoadedCallback) => {
				let img = new Image()
				img.onload = anImageLoadedCallback
				img.src = url
			}

			urls.forEach(function (url) {
				preloadImage(url, function () {
					loadedCounter++
					// console.log('Number of loaded images: ' + loadedCounter);
					if (loadedCounter === toBeLoadedNumber) {
						allImagesLoadedCallback()
					}
				})
			})
		}

		let images = [bg_img]
		preloadImages(images, () => {
			// console.log("preloaded");
		})
	}, [state])

	let Stage
	if (state.level === "easy") {
		Stage = <Stage1 isOpenInFlutter={props.isOpenInFlutter}/>
	} else if (state.level === "medium") {
		Stage = <Stage2 isOpenInFlutter={props.isOpenInFlutter}/>
	} else {
		Stage = <Stage3 isOpenInFlutter={props.isOpenInFlutter}/>
	}

	// console.log(state);
	return (
		<div
			className="game-container"
			id="game-container"
			key={windowSize.width}
			style={{
				width: `${windowSize.width}px`,
				height: `${windowSize.height}px`,
			}}
		>
			{state.loadingAssets ? (
				<div className="game-loading">
					<div className="loader-inner">
						<img src="/ball-triangle.svg" className="loader" alt="loader" />
						<span>Loading...</span>
					</div>
				</div>
			) : (
				<DispatchContext.Provider value={dispatch}>
					<StateContext.Provider value={state}>{state.levelData.length !== 0 && Stage}</StateContext.Provider>
				</DispatchContext.Provider>
			)}
		</div>
	)
}

CrosswordPuzzle.whyDidYouRender = true
export const CrosswordPuzzleMemo = React.memo(CrosswordPuzzle)
CrosswordPuzzleMemo.whyDidYouRender = true

export default CrosswordPuzzleMemo
// export default DragAndDrop;
