import { keyframes } from "@emotion/core"

const content = {
	images: [
		{
			src: "city/village_page12.png",
			styles: {
				animation: `${keyframes({
					to: {
						transform: "translate(-55%,0%) scale(2.5)",
					},
				})} 2.5s 4s ease-in normal forwards`,
			},
		},
	],

	texts: [
		{
			textDivClass: "story_gauthali-text ",
			styles: {
				left: "0",
				padding: "15px",
				background: "rgba(229, 246, 253, 0.8);",
				borderRadius: "15px",
			},
			textIndivData: [
				{
					textData: "घरको न्यानो कोठामा",
					textClass: "m-0 p13-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "गुँड बनाउने आशामा",
					textClass: "m-0 p13-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "pr11.ogg",
			delay: 500,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			isBg: true,
			volume: 0.3,
		},
	],
}

export default content
