import { keyframes } from "@emotion/core"
import { seqAnimation } from "styledElements/helpers"
const images = require.context("../images", true)
const audios = require.context("../audios", true)

const close_eye = keyframes`
	0%, 89% {
		opacity: 0;
	}
	90%, 100% {
		opacity: 1;
	}
`
const open_eye = keyframes`
	0%, 89% {
		opacity: 1;
	}
	90%, 100%  {
		opacity: 0;
	}
`

const content = {
	imageContainerClass: "bg-white",
	images: [
		{
			src: images("./grand_mum_talking.svg"),
			toSvg: true,

			styles: {
				width: "53%",
				top: "0",
				left: "auto",
				right: "5%",

				...seqAnimation({
					idName: "lips",
					numberOfFrames: 8,
					totalDuration: 1.2,
					repeat: 3,
					delays: [0.1, 0.25, 0.5, 0.75, 1.0, 1.2, 1.4],
				}),

				"#close_eye": {
					opacity: "0",
					animation: `${close_eye} 4.5s linear forwards`,
				},
				"#close_eye_1_": {
					opacity: "0",
					animation: `${close_eye} 4.5s linear forwards`,
				},
				"#open_eye": {
					opacity: "0",
					animation: `${open_eye} 4.5s linear forwards`,
				},
				"#open_x5F_eye": {
					opacity: "0",
					animation: `${open_eye} 4.5s linear forwards`,
				},
			},
		},
		
	],
	texts: [
		{
			textDivClass: "story-text page5Text text-center dialogueBubble textbox1",
			styles: {
				top: '22%',
				left: '6%',
				width: '46%',
			},
			textIndivData: [
				{
					textData: "ज्हेया, आज हामी मुना काकीलाई भेट्‍न जाने है।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1 },
						{ start: 1.35 },
						{ start: 1.55 },
						{ start: 1.95 },
						{ start: 2.35 },
						{ start: 2.65 },
						{ start: 2.95 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./5.ogg"),
			delay: 500,
		},
		{
			url: audios("./bg/se_page5.ogg"),
			volume: 0.2,
			delay: 200,
		},
	],
}

export default content
