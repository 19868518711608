import React from "react"

const DragDropIncentive = (props) => {
	return (
		<div key={props.currentPage + "incentive" + props.index} className={props.imgdivClass} onClick={props.onClickImage}>
			<img
				key={props.currentPage + "image" + props.index}
				src={`${props.src}?reload=${props.activityKey}`}
				className={`img-fluid ${props.class}`}
				alt=""
			/>
		</div>
	)
}
export default DragDropIncentive
