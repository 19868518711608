import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page5Scene",
	images: [
		{
			src: images("./page6_bg_tailor1.jpg"),
			imgdiv: "imageCover bg-p5",
		},
		{
			src: images("./tailor_dai_loop.gif"),
			imgdiv: "imageCover dai-gif-p5",
		},
		{
			src: images("./nima_talking_tailor_dai.png"),
			imgdiv: "imageCover nima-p5",
		},
		{
			src: images("./nima_talking_tailor_dai.gif"),
			imgdiv: "imageCover nima-gif-p5",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center dialogueBubble bubble-sm-md-left-o text-bubble-p5",
			textIndivData: [
				{
					textData: "मेरो लुगा कहिले तयार हुन्छ होला?",
					textClass: "m0",
					timestamps: [{ start: 0 }, { start: 0.7 }, { start: 1.1 }, { start: 1.6 }, { start: 1.95 }, { start: 2.35 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./page6.ogg"),
			delay: 4000,
		},
		{
			url: audios("./sewing1.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.2,
			loop: true,
		},
		{
			url: audios("./busyMarket.ogg"),
			delay: 1000,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./blank.ogg"),
			delay: 12000,
		},
	],
}

export default content
