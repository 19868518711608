import React from "react"
import donerIcon from "../../assets/ongd_hrk.png"

export default function Footer(props) {
	return !props.isHidden ? (
		<footer className="main__footer">
			<div className="container">
				<p className="m-0 text-center">
					<a href="http://olenepal.org/" target="_blank" rel="noopener noreferrer">
						साझा शिक्षा ई-पाटीद्वारा
					</a>{" "}
					निर्मित | प्रारम्भिक कक्षा पढाइ - V1.0.0
					{props.donner ? (
						(
							<>
							<span>, सहयोग: </span>
							<img className="footer_doner_icon" src={donerIcon} alt="footer_doner_icon" />
							</>
						)
					): (
						<></>
					)
					}
				</p>
			</div>
		</footer>
	) : null
}
