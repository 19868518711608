import React from "react"
const PageNumber = (props) => {
	let totalPage = props.totalPages
	let curPage = props.currentPage + 1
	let progressPercent = (curPage / totalPage) * 100
	return (
		<div className="page__progress">
			<div className="progress__indicator" style={{ width: `${100 - progressPercent}%` }} />
			<span>
				Progress: Page {curPage} of {totalPage}
			</span>
		</div>
	)
}

export default PageNumber
