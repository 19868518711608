const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./box_page02.png"),
			class: "image-one imgbox-lg",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./girl_page02.png"),
			class: "image-one girl-p3",
			imgdiv: "imageCover",
		},
		{
			src: images("./toy_page02.gif"),
			class: "image-one friend-p3",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center textbox textbox-ban",
			textIndivData: [
				{
					textData: "झोला तान्दै कोपिला साथीहरूसँग खेल्न गईन्।",
					textClass: " m-0 text-p3a textStyles",
					timestamps: [
						{ start: 0 },
						{ start: 0.85 },
						{ start: 1.35 },
						{ start: 2.15 },
						{ start: 3.15 },
						{ start: 3.4 },
						{ start: 3.85 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s3.ogg"),
			delay: 500,
		},
		{
			url: audios("./soundEffect/childrenPlaying.ogg"),
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
