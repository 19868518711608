import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	imageContainerStyles: {
		transformOrigin: "2% 62%",
		transform: "scale(1)",
	},
	images: [
		{
			src: images("./kids/bg_page8.png"),
		},
		{
			src: images("./flag_long.gif"),
			styles: {
				width: "100%",
				top: "0%",
				left: "0%",
			},
		},
		{
			src: images("./boy_with_dog.gif"),
			class: "image-10 boywithdog14",
			imgdiv: "imageCover",
		},
		{
			src: images("./kids/page-8-girl01.svg"),
			toSvg: true,
			styles: {
				top: "41%",
				left: "34%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl1",
		},
		{
			src: images("./kids/page-8-boy.svg"),
			toSvg: true,
			styles: {
				top: "39.2%",
				left: "52%",
				width: "22%",
			},
			imgdiv: "imageCover page8boy",
		},

		{
			src: images("./kids/page-8-girl02.svg"),
			toSvg: true,
			styles: {
				top: "42%",
				left: "72.5%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl2",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page17girl topRightBubble text-center",
			textIndivData: [
				{
					textData: "आहा!",
					textClass: "m-0",
					timestamps: [{ start: 0.8 }, { start: 1.2 }],
				},
			],
			styles:{
				left: '88% !important' ,
			},
		},
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page17girl2 topRightBubble text-center",
			textIndivData: [
				{
					textData: "कस्तो रमाइलो!",
					textClass: "m-0",
					timestamps: [{ start: 1.8 }, { start: 2.3 }],
				},
			],
			styles:{
				left: '54% !important' ,
			},
		},
	],
	sounds: [
		{
			url: audios("./aaha.ogg"),
			delay: 500,
		},
		{
			url: audios("./kasto_ramailo.ogg"),
			delay: 2000,
		},
	],
}

export default content
