const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_green.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./grass.png"),
			class: "image-five grass",
			imgdiv: "imageCover",
		},
		{
			src: images("./crow_try_to_pick_roti.gif"),
			class: "image-five crow_pick_roti ",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page3Text text-center",
			textIndivData: [
				{
					textData: "कागले रोटीलाई ठुँग्यो।",
					textClass: "page3a",
					timestamps: [{ start: 0 }, { start: 1.2 }, { start: 1.8 }],
				},
				{
					textData: "रोटीमा कागको चुच्चो पसेन।",
					textClass: "page3b",
					timestamps: [{ start: 3.3 }, { start: 4.4 }, { start: 4.9 }, { start: 5.5 }],
				},
				{
					textData: "रोटी बासी थियो।",
					textClass: "page3c",
					timestamps: [{ start: 7.1 }, { start: 7.9 }, { start: 8.5 }],
				},
				{
					textData: "टक्!",
					textClass: "word3",
					timestamps: [{ start: 0 }, { start: 0 }],
				},
				{
					textData: "टक्!",
					textClass: "word4",
					timestamps: [{ start: 0 }, { start: 0 }],
				},
				{
					textData: "टक्!",
					textClass: "word5",
					timestamps: [{ start: 0 }, { start: 0 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3a.ogg"),
			delay: 6850,
		},
		{
			url: audios("./a3.ogg"),
			delay: 1150,
			isBg: true,
			loop: false,
			volume: 0.2,
		},
	],
}

export default content
