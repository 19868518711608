const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page13.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./img08.png"),
			class: "image-one img08",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page12Text text-center",
			textIndivData: [
				{
					textData: "घरमा पुगे पछि रविले फुल उसिनेर खाए।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.85 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.0 },
						{ start: 2.4 },
						{ start: 2.8 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./12a.ogg"),
			delay: 500,
		},
	],
}

export default content
