const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		// {
		// 	src: images('./kopila_sleep_page06.gif'),
		// 	class: 'image-one imgbox-lg bedGirlCrying',
		// 	imgdiv: 'imageCover bg-white',
		// },
		{
			src: images("./kopila_on_bed.png"),
			class: "image-one bedGirlCrying",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./mum_sad.gif"),
			class: "image-one mumSadP7",
			imgdiv: "imageCover",
		},
		{
			src: images("./outline-lg-white.png"),
			class: "image-one outlineP7",
			imgdiv: "imageCover",
		},
		{
			src: images("./mum_hand.png"),
			class: "image-one mumHandP7",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p7 textbox textbox-ban",
			textIndivData: [
				{
					textData: "कोपिला दु:खी भएको देख्दा आमालाई चिन्ता लाग्यो।",
					textClass: "m-0 one-line textStyles text-p7a",
					timestamps: [
						{ start: 0 },
						{ start: 0.9 },
						{ start: 1.3 },
						{ start: 1.75 },
						{ start: 2.58 },
						{ start: 3.09 },
						{ start: 3.5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s7.ogg"),
			delay: 1000,
		},
	],
}

export default content
