const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page05.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./page05_man_boy.png"),
			class: "image-five ",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-green-light",
			textIndivData: [
				{
					textData: "“ऊ हेर! छिरिङ बाबुकी दिदी आइन्।”",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.5 }, { start: 1.4 }, { start: 1.85 }, { start: 2.45 }, { start: 2.7 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6.ogg"),
			delay: 1000,
		},
	],
}

export default content
