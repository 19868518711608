// import { jsx } from "@emotion/core"
// import _, { delay, object } from "underscore"
import React, {useContext, useEffect, useState} from "react"
import {Howl, Howler} from "howler"
import { StateContext, DispatchContext } from "../../ShapeColorGame"
import "./scenecss/scene6.styles.scss"
// import { sleep } from "../../utils"
import Options from "../Options/Scene6Option"
import OptionsSecond from "../Options/OptionsSecond"
import gsap from "gsap"
import mainTextAnimation from "../../../Common/TextAnimations"
// import { counter } from "@fortawesome/fontawesome-svg-core"
// import textHighLight from "../../../Common/TextHighLightAnime"
// import { faBorderNone } from "@fortawesome/free-solid-svg-icons"
// const images = require.context("../images", true)
const audios = require.context("./dailog/", true)

const Scene = (({stageData, assets, story})=>{
        const state = useContext(StateContext)                /* importing statecontext from shapecolorgame and initializiing at state */
        const dispatch = useContext(DispatchContext)         /* importing statecontext from shapecolorgame and initializiing at state */
        let girlRef = React.useRef(null);                    /* initializing girlrif as reference which directly change in file  */
        let robotRef = React.useRef(null);
        let daiRef = React.useRef(null);
        let speechboxdivRef = React.useRef(null);
        let speechboxRef = React.useRef([]);
        // console.log(story);
const initial = {
        pageup: 0,
        audio: false,
        //    QuestionShow: 0,
        QnAnsShow: false,
        secQNAnsShow: false,
        dailogCounter: 0,
        disable: true,
        disableSec:true,
        objCount:1
}
const [stageState, setStageState] = useState(initial);
//    const [data, setdata] = useState(stageData);
   var timestamp = gsap.timeline({});                           /* creating timeline for gsap animation */
//    let bgMusic;
//    let dailogSound;
//    console.log(stageState)
   let content = {                                              /* all data are store in object which are used as par their requirement */
        texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-1_p6',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '2% 2% 11% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "मेरो पसलमा वृत्ताआकारका अझै सामानहरु छन् यो हेर त ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.35 }, { start: 1.60 }, { start: 2.10 }, { start: 2.50 }, { start: 2.80 }, { start: 3.00 }, { start: 3.20 }],
                                        delayTime: 2000,
                                        hideTimer: 8000,
                                }
                        ]
                },
                // dailog after quention option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-2_p6',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 7,
                                        textData: "दिदिसँग त वृताआकारका धेरै सामानहरू पो रहेछन् ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6600000,
                                        hideTimer: 6100000
                                }
                        ]
                }
        ],
        sounds: 
		[{
			url: audios("./scene5_didi5.wav"),
			delay: 2000,
		}],
}
        let content1 = {
                texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-2_p6',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '2% 3% 15% 3%'
                        },
                        textIndivData: [
                                {
                                        id: 2,
                                        textData: "दिदिसँग त वृताआकारका धेरै सामानहरू पो रहेछन् ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.55 }, { start: 0.75 }, { start: 1.00 }, { start: 1.70 }, { start: 2.05 }, { start: 2.45 }, { start: 2.85 }],
                                        delayTime: 1000,
                                        hideTimer: 7000
                                }
                        ]
                },
                ],
                sounds:[
                        {
                                url: audios("./scene6_furba1.wav"),
			        delay: 1000,
                        }
                ]
        }

   useEffect(()=> {                                           /* this useEffect is uesd for background music play */
        if(!stageState.audio){
                        setStageState({...stageState, audio:true})
                        const bgMusic = new Howl({
                                src: [content.sounds[stageState.pageup].url],
                                autoplay: false,
                                preload: true,
                                volume: 0.07,
                                loop: true,
                                // onend: async () => {
                                //         // if (stageData.id === 10) {
                                //         //         setOptionState({ ...optionState, finalStageQ: stageData.questioner })
                                //         //         dispatch({ type: "LAST_PAGE_COMPLETE" })
                                //         // }
                                //         // await sleep(2000)
                                //         // await playAnimalSound()
                                // },
                        })
                        // console.log(content.sounds[stageState.pageup].url)
                        bgMusic.play()
                        return () => {
                                Howler.unload()
                                bgMusic.unload()
                        }
                }      
        },[stageState.audio, stageData.bgMusic, content.sounds]);

        let textList = content.texts;
        let textListB;
        let soundList;
useEffect(()=>{                                      /* this useEffect is used for showing dailogbox highilighting text and play audio for each dailog*/
        let pagenumber  = 1;
        textList = content.texts;
        soundList = content.sounds;
        // console.log(stageData.disable)
        if(stageState.disable){
                let objCount = 1   
                let timeouts = []
                let timestams = []
                let textIndivData = []
                let delaytime = []
                let hideTime = []   
                let showdata;
                //    console.log(textList);
                if( textList?.length > 0){
                        textList.forEach((item) => {
                                textIndivData = [...textIndivData, ...item.textIndivData]
                        })
                        textIndivData.forEach((data) =>{
                                // console.log(data)
                                
                                if(data.timestamps){
                                        timestams = [...timestams, ...data.timestamps];
                                        // textHighLight(data.textdata, data.timestamps)
                                }else{
                                        timestams = null
                                }
                                
                                if(data.delayTime){
                                        delaytime.push(
                                                setTimeout(()=>{
                                                showdata  = document.getElementsByClassName('sp-'+ objCount+'_p6');
                                                        // console.log(delaytime);
                                                        gsap.to(showdata, {
                                                                scale: 1,
                                                                ease: "power1.in",
                                                                display: "block",
                                                                onComplete: () => {
                                                                        if(objCount == 2){
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                daiRef.current.src = stageData.daiTalking;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                // console.log(objCount)
                                                                        }
                                                                }      
                                                        })
                                                
                                                        
                                                        let textAnimations;
                                                        import(`../../Animations`).then((customAnims) =>{
                                                                let animationObj = new customAnims.default()
                                                                textAnimations = animationObj.runTextAnimations
                                                                // textAnimations = mainTextAnimation
                                                                if(animationObj.runTextAnimations){
                                                                        animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        objCount ++ ;
                                                                        // animationObj.forEach(() =>{
                                                                        //         animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        // })
                                                                }
                                                                // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                        })
                                                        .catch((error) =>{
                                                                if(story.defaultTextAnimation){
                                                                        textAnimations = mainTextAnimation
                                                                }else{
                                                                        textAnimations = () => {}
                                                                }
                                                        })
                                                },data.delayTime)
                                        )
                                }else{
                                        delaytime = null
                                }
                                if(data.hideTimer){
                                        hideTime.push(
                                                setTimeout(() => {
                                                        showdata  = document.getElementsByClassName('sp-'+ (objCount-1)+'_p6');
                                                        // showdata[0].style.display = 'none';
                                                        gsap.to(showdata, {
                                                                scale: 0.1,
                                                                ease: "power1.in",
                                                                delay: 1,
                                                                display: "none",
                                                                onComplete: () => {
                                                                                // robotRef.current.src = stageData.robotLookPng;
                                                                                // girlRef.current.src = stageData.girlLooking;
                                                                        
                                                                }
                                                        })
                                                        if(objCount === 2){
                                                                        setStageState({...stageState, disable:false, secQNAnsShow:false, QnAnsShow:true});   
                                                                        // textIndivData = [textIndivData];
                                                                        // console.log(textIndivData);

                                                        }
                                                }, data.hideTimer)
                                        )
                                }
                        })
                }else{
                        timestams = null
                }
                if(soundList && soundList.length){
                        soundList.forEach((sound) => {
                                // console.log(sound)
                                let audio = new Howl({
                                        src: [sound.url],
                                        preload: true,
                                        autoplay:false,
                                        loop: sound.loop ?? false,
                                        volume: sound.volume ?? 0.7,
                                })
                                if(sound.delay){
                                        timeouts.push(
                                                setTimeout(()=>{
                                                        audio.play();
                                                        // content.texts.textMainDivClass.style.display = 'block'
                                                },sound.delay)
                                        )
                                }else{
                                        // audio.unload()
                                }
                        })
                }
                return () => {
                        // if(audio) audio.unload()
                        // Howler.unload()
                        timeouts.forEach((timeout) =>{
                                clearTimeout(timeout)
                                // Howler.unload()
                        })
                        delaytime.forEach((timeout) => {
                                clearTimeout(timeout);
                        })
                        gsap.killTweensOf("*")
                        // console.log("last return")
                }
        }
},[stageData.disable, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData])

useEffect(()=> {            /* this useeffect is used when user click the right answer*/
        if(stageState.dailogCounter === 9){
                if(stageState.disableSec){
                        textListB = content1.texts;
                        soundList = content1.sounds;
                        let textIndivData = []
                        let timestamsSec = []
                        let timeouts = []
                        let speechCount = 2;
                        let delaytime = []
                        let hideTime = []
                        let showdata;
                        // console.log(stageState.disable)
                        robotRef.current.classList.add("robot_afterAnsCorrect_p6");
                        girlRef.current.classList.add("girl_afterAnsCorrect_p6");
                        if(robotRef.current){
                                robotRef.current.src = stageData.robotLookPng  
                        }
                        if(girlRef.current){
                                girlRef.current.src = stageData.girlTalkGif
                        }
                        if( textListB?.length > 0){
                                textListB.forEach((item) => {
                                        textIndivData = [...textIndivData, ...item.textIndivData]
                                })
                                textIndivData.forEach((data) =>{
                                        
                                        if(data.timestamps){
                                                timestamsSec = [...timestamsSec, ...data.timestamps];
                                        }else{
                                                timestamsSec = null
                                        }
                                        
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('sp-'+ speechCount+'_p6');
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCount === 3){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotHandGif;
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                        console.log(speechCount);
                                                                                        // setStageState({...stageState, disableSec:false})
                                                                                }
                                                                        }      
                                                                });
                                                                let textAnimations1;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations1 = animationObj.runTextAnimations
                                                                        if(animationObj.runTextAnimations){
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCount-1], timestamsSec)
                                                                                speechCount ++ ;
                                                                        }
                                                                        // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations1 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations1 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('sp-'+ (speechCount-1)+'_p6');
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {                                
                                                                        }
                                                                })
                                                                // if(speechCount == 13){
                                                                //         setStageState({...stageState, dailogCounter:13, secQnAnsShow:true});
                                                                //         speechboxdivRef.current.style.display = "none";
                                                                //         showdata[0].style.display = 'none';
                                                                // }
                                                                if(speechCount === 3){
                                                                        dispatch({type: "LAST_PAGE_COMPLETE"})
                                                                        setStageState({...stageState, disableSec:false})
                                                                        console.log(state.rewardComplete)
                                                                        console.log(stageState.disable+" "+stageState.disableSec)
                                                                } 
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsSec = null;
                        }
                        if(soundList && soundList.length){
                                soundList.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                        return () => {
                                // if(audio) audio.unload()
                                // Howler.unload()
                                timeouts.forEach((timeout) =>{
                                        clearTimeout(timeout)
                                        // Howler.unload()
                                })
                                delaytime.forEach((timeout) => {
                                        clearTimeout(timeout);
                                })
                                gsap.killTweensOf("*")
                                // console.log("last return")
                        }
                }
        }
},[stageData.disable,stageData.disableSec, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData, speechboxdivRef.current]);

const answerSound = (isRight = false) => {
        new Howl({
                src: [isRight ? state.audios[1] : state.audios[0]],
                autoplay: true,
                loop: false,
                volume: 0.03,
        })
}

const SecondanswerClick = (e, index) => {
        const check = e.target.id.split(" ")[0]; 
        // console.log(check);
        // let optcount = 0;
              let siblingDiv = e.target.children[1];
        if(check === "ball" || check === "bottom"){
                // answerSound(true)
                // optcount = optcount + 1;
                // setStageState({...stageState, objCount: stageState.objCount+1})
                e.target.style.backgroundColor = "#c1ebb3";
                e.target.style.pointerEvents = "none";
                // console.log(optcount);
                siblingDiv.style.opacity = 1;

                if(check === "bottom"){
                        // optcount++;
                        // console.log(optcount)
                        setStageState({...stageState, objCount: stageState.objCount+1})
                        // e.target.style.backgroundColor = "#c1ebb3";
                        // e.target.style.pointerEvents = "none";
                        // siblingDiv.style.opacity = 1;
                        console.log(stageState.objCount)
                }
                if(check === "ball"){
                        // optcount++;
                        // console.log(optcount)
                        setStageState({...stageState, objCount: stageState.objCount+1})
                        console.log(stageState.objCount)
                }
        }
        
        else{
                e.target.style.backgroundColor = "#f4cccc";
                e.target.style.pointerEvents = "none";
                siblingDiv.style.opacity = 1;
                // answerSound(false)
        }
        if(stageState.objCount == 2){
                // console.log("Aa"+ optcount)
                gsap.to("."+check,2,{
                        onComplete:() => {
                                // check.pointerEvents = "none";
                        setStageState({...stageState, dailogCounter:9, QnAnsShow:false});
                        }
                })
        }
        
}

        return (
                <div className="imageContainer">
                {
                        stageState.QnAnsShow === true ? (
			<Options
			SecondanswerClick={SecondanswerClick}
			assets={assets}
			stageData={stageData}
			/>
			):  
                        <div className="speechContainer" ref={speechboxdivRef}>
                        <img src={stageData.girlLooking} className="girl_walk_p6" ref={girlRef} 
                        />
                        <img src ={stageData.robotLookPng} className="robot_p6" ref={robotRef} />
                        <img src ={stageData.didiPng} className="didi_p6" ref={daiRef} />

                                {textList.length ? (
                                        textList.map((textContent, index) => {
                                                const textStyles = textContent.styles ?? {}
                                                // console.log(textStyles);
                                                const maindiv = textContent.textMainDivClass;
                                                const className = `${textContent.textDivClass} textContentDiv`
                                                return(
                                                        <div key={index} className={`speechbox_p6 ${maindiv}`} ref={ref => {speechboxRef.current[index] = ref;}}> 
                                                        <img src ={stageData.bubble} className="box" /> 
                                                        <div className={className} style ={ textStyles }>
                                                                {textContent.textIndivData.map((text, index) => (
                                                                        <p id="dailog" className={text.textClass} key={index}>
                                                                                {text.textData}
                                                                        </p>
                                                                ))}
                                                        </div>
                                                        </div>
                                                )
                                        })
                                ) : ( 
                                        <div></div>
                                )
                        }

                        </div>
                }
                </div>        
        )
        
});

export default Scene;

