const words = [
	{
		key: "railgadi",
		word: "रेलगाडी",
		question: { letter: "गाडी", position: 1 },
		options: ["रेल", "नेल", "भेल"],
	},
	{
		key: "dumkal",
		word: "दमकल",
		question: { letter: "दम", position: 0 },
		options: ["कल", "हल", "छल"],
	},
	{
		key: "tarkari",
		word: "तरकारी",
		question: { letter: "तर", position: 0 },
		options: ["कारी", "सारी", "बारी"],
	},
	{
		key: "vayaguto",
		word: "भ्यागुतो",
		question: { letter: "गुतो", position: 1 },
		options: ["भ्या", "म्या", "ज्या"],
	},
	{
		key: "suchikar",
		word: "सूचीकार",
		question: { letter: "सूची", position: 0 },
		options: ["कार", "हार", "झार"],
	},
	{
		key: "kisan",
		word: "किसान",
		question: { letter: "कि", position: 0 },
		options: ["सान", "कान", "पान"],
	},
	{
		key: "durbin",
		word: "दूरबिन",
		question: { letter: "बिन", position: 1 },
		options: ["दूर", "सुर", "खुर"],
	},
	{
		key: "sikari",
		word: "सिकारी",
		question: { letter: "कारी", position: 1 },
		options: ["सि", "त्रि", "फि"],
	},
	{
		key: "paathsala",
		word: "पाठशाला",
		question: { letter: "पाठ", position: 0 },
		options: ["शाला", "माला", "गाला"],
	},
	{
		key: "nariwal",
		word: "नरिवल",
		question: { letter: "वल", position: 1 },
		options: ["नरि", "परी", "चरी"],
	},
	{
		key: "cycle",
		word: "साइकल",
		question: { letter: "कल", position: 1 },
		options: ["साइ", "दाइ", "भाइ"],
	},
	{
		key: "katahar",
		word: "कटहर",
		question: { letter: "हर", position: 1 },
		options: ["कट", "हट", "रट"],
	},
	{
		key: "headphone",
		word: "हेडफोन",
		question: { letter: "फोन", position: 1 },
		options: ["हेड", "छेड", "पेड"],
	},
	{
		key: "jacket",
		word: "ज्याकेट",
		question: { letter: "ज्या", position: 0 },
		options: ["केट", "सेट", "भेट"],
	},
	// {
	// 	key: "khachaya_kotha",
	// 	word: "कक्षाकोठा",
	// 	question: {letter:"कोठा", position: 1},
	// 	options: [ "कक्षा", "रक्षा", "दिक्षा"],
	// },
]

let level1 = words.map((word) => {
	let optionsObj = {}
	optionsObj = word.options.map((option, idx) => {
		let pos = null
		if (idx === 0) {
			pos = word.question.position === 0 ? 1 : 0
		} else {
			pos = Math.floor(Math.random() * 2)
		}
		return {
			...optionsObj,
			letter: option,
			position: pos,
		}
	})
	word.options = optionsObj
	return word
})

export default level1
