// import React, { Component } from "react"
// // import Content from "./Content";
// // import DragandDrop from "Containers/Play/DragandDropTemplate/DragandDrop";

// import DragAndDropGame from "Containers/Play/DragandDropTemplate/DragAndDrop/DragAndDropGame"

// class DragandDropPlay extends Component {

// 	state = {
// 		isCallFromFlutter : false
// 	}
	
// 	//for flutter communication 
// 	// const callFromFlutterFunc = () => {
// 	// console.log('hello from JS triggered by Flutter');
// 	// setisCallFromFlutter(true);
// 	// // isCallFromFlutter = true;
// 	// };
// 	// window.function = callFromFlutterFunc;
// 	//for flutter communication 

// 	render() {
// 		return <DragAndDropGame activityName="तानेर राख" />
// 	}
// }

// export default DragandDropPlay

/* 
	Test for function based component 
*/
import React, {useState} from "react"
import DragAndDropGame from "Containers/Play/DragandDropTemplate/DragAndDrop/DragAndDropGame"
// import Header from "Components/Layout/Header"

const DragandDropPlay = () => {

	//for flutter communication 
	const [isFromFlutter, setisFromFlutter] = useState(false);
	// var isCallFromFlutter = false;
		var callDragDropFromFlutter = () => {
			setisFromFlutter(true);
		};

		window.dragFunc = callDragDropFromFlutter;
		// console.log(window);
	//for flutter communication 

	return (
		<div className="activity__container">
			{/* <Header storyName="बेलुन फुटाऊ" isGame={true} isOpenInFlutter={isCallFromFlutter}/> */}
			<DragAndDropGame activityName="तानेर राख" isOpenInFlutter={isFromFlutter}/>
		</div>
	)
}

export default DragandDropPlay
