import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "शशीको घुम",
	key: "sashi-ko-ghum",
	coverImage: images("./cover_banner.png"),
	credits: {
		writer: "इन्दिरा भट्टराई",
		illustrator: "सिमल",
		template: "default",
		bg: images("./cover_banner.png"),
		titleAudio: audios("./s1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
