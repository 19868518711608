const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./horse-neighing.gif"),
			class: "image-8",
			imgdiv: "imageCover lightbluebg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center browntext",
			textIndivData: [
				{
					textData: "सोमबार आयो,",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.95 }, { start: 2.1 }],
				},
				{
					textData: "घोडा करायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2.75 }, { start: 3.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7.ogg"),
			delay: 500,
		},
		{
			url: audios("./a7.ogg"),
			delay: 4500,
			loop: true,
			volume: 0.3,
			isBg: true,
		},
	],
}

export default content
