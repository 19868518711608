const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./empty_plate.png"),
			class: "image-five",
			imgdiv: "imageCover yellowbg",
		},
		{
			src: images("./khai.gif"),
			class: "image-five",
			imgdiv: "imageCover ",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center bluetext",
			textIndivData: [
				{
					textData: "खोइ मेरो रायोको साग?",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.75 }, { start: 1.2 }, { start: 1.8 }, { start: 2.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./31.ogg"),
			delay: 500,
		},
	],
}

export default content
