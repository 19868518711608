import { appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core"

const content = {
	images: [
		{
			src: "city/room_page14.png",
		},
		{
			src: "birds/bird_fly.gif",
			styles: {
				width: "12%",
				left: "41%",
				top: "34%",
				animation: `${keyframes({
					to: {
						transform: "translate(242%, -29%)",
					},
				})} 2.5s ease-in normal forwards,
				${disappear} 0.05s 2.5s ease-out normal forwards
				`,
			},
		},
		{
			src: "birds/bird_page19.png",
			styles: {
				width: "4.6%",
				left: "77%",
				top: "16%",
				transform: "rotate(-30deg)",
				height: "15%",
				animation: `
					${appear} 0.05s 2.5s ease-out normal both ,
					${keyframes({
						to: {
							transform: "rotate(-40deg)",
						},
					})} 0.5s 7s ease-out alternate both 12
				`,
			},
			textIndivData: [
				// {
				// 	textData: 'मानिसले गुँड भत्काए',
				// 	textClass: 'm-0 p13-text_a',
				// 	// timestamps: [{ start: 1 }, { start: 2 }],
				// },
				// {
				// 	textData: 'चरि जोडिलाई धपाए',
				// 	textClass: 'm-0 p13-text_b',
				// 	// timestamps: [{ start: 1 }, { start: 2 }],
				// },
			],
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text ",
			styles: {
				bottom: "0",
				left: "32%",
				padding: "15px",
				background: "rgba(229, 246, 253, 0.8);",
				borderRadius: "15px",
			},
			textIndivData: [
				{
					textData: "झ्यालबाट छिरेर",
					textClass: "m-0 p13-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "बनाए गुँड रमाएर",
					textClass: "m-0 p13-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "pr12.ogg",
			delay: 500,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			isBg: true,
			volume: 0.3,
		},
	],
}

export default content
