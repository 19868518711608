import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const planeToChitwan = keyframes`
0% {
	transform: rotate(141deg) translateY(0%);
}
100% {
	transform: rotate(141deg) translateY(-12%);
}
`
const horseToAirport = keyframes`
0% {
	transform: scaleX(-1) rotate(12deg) translateX(0%);
}
100% {
	transform: scaleX(-1) rotate(12deg) translateX(145%);
}
`
const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(4) translate(12%,27%)",
					animation: `${transformTo("scale(2) translate(4%,8%)")} 4s 2s forwards ease-out,
								${transformTo("scale(4) translate(12%,23%)")} 4s 16s forwards ease-out,
								${transformTo("scale(2) translate(4%,8%)")} 4s 27s forwards ease-out,
								${transformTo("scale(4) translate(1%,-2%)")} 4s 39s forwards ease-out,
								${transformTo("scale(7) translate(-4%,-9%)")} 4s 51s forwards ease-out

								`
					
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "5%", 
				top: '16%',
				left: '36%',
				opacity: 0,
				transform: "scaleX(-1)",
				animation: ` ${appear} 1s 6s ease-out normal forwards,${disappear} 500ms 15s linear forwards				 
				`,
			}
		},
		{
			src: images("./imgs/kamal.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
			    width: '7%',
				top: '59%',
				left: '56%',
				opacity: 0,
				zIndex:2,
				animation: ` ${appear} 1s 44s ease-out normal forwards,
				${disappear} 1s 50s ease-out normal forwards				 
				`,
			}
		},
		{
			src: images("./imgs/aeroplane_top.png"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '10%',
				top: '-497%',
				left: '-79%',
				opacity: 0,
				transform: "rotate(141deg)",
				zIndex: 2,
				animation: `${appear} 1s 31s ease-out normal forwards,
				${planeToChitwan} 6s 32s ease-out normal forwards,
				${disappear} 1s 37s ease-out normal forwards
				`,
			}
		},
		{
			src: images("./imgs/horse.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "13%", 
				top: '43%',
				left: '57%',
				opacity: "0",
				transform: "scaleX(-1) rotate(12deg)",
				zIndex: 2,
				animation: `${appear} 1s 21s ease-out normal forwards,
				 	${horseToAirport} 4s 21s ease-out normal forwards,
					${disappear} 1s 25s ease-out normal forwards
				
				`,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble3 page2Buble",
			textIndivData: [
				{
					textData: "डोल्पाबाट चितवन त टाढा रहेछ, हामी फेरी हवाईजहाजमा उडेर जाऔँ है।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{    
				top: '2%',
				left: '43%',
				transformOrigin: "0% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 7000ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 15s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble2 page2Buble",
			textIndivData: [
				{
					textData: "चितवनमा वरिपरि थुप्रै चेपाङ समुदायहरू रहेछन्।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '33%',
				left: '18%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 45s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 50s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromDolToAirport",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '18%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '53%',
				position: 'absolute',
				left: '61%',
				transform: "rotate(172deg)scaleX(0)",
				transformOrigin: "left",
				animation: `${transformTo(["rotate(172deg) scaleX(1)"])} 3s 21s linear forwards, ${disappear} 500ms 27s linear forwards`
			}
		},
		{
			textDivClass: "lineFromAirportToChit",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '42%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '29%',
				position: 'absolute',
				left: '-12%',
				transform: "rotate(231deg) scaleX(0)",
				transformOrigin: "right",
				animation: `${transformTo(["rotate(231deg) scaleX(1)"])} 4.5s 32s linear forwards, ${disappear} 0.5s 39s linear forwards`
			}
		},
		
	],
	sounds: [
		{
			url: audios("./chitwan1.ogg"),
			delay: 7000,
		},
		{
			url: audios("./chitwan2.ogg"),
			delay: 45000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 55000,
		},
	],
}

export default content
