const words = [
	{
		key: "chiyakhura",
		word: "च्याखुरा",
		question: { letter: "च्या", position: 0 },
		options: ["खुरा ", "कुरा ", "चुरा"],
	},
	{
		key: "makhamali",
		word: "मखमली",
		question: { letter: "मख", position: 0 },
		options: ["मली", "अली", "कली"],
	},
	{
		key: "computer",
		word: "कम्प्यूटर",
		question: { letter: "कम्प्यू", position: 0 },
		options: ["टर", "भर", "गर"],
	},
	{
		key: "vayaguta",
		word: "भ्यागुता",
		question: { letter: "गुता", position: 1 },
		options: ["भ्या", "जुत्ता", "झुत्ता"],
	},
	{
		key: "laltin",
		word: "लालटिन",
		question: { letter: "लाल", position: 0 },
		options: ["टिन", "पिन", "मिन"],
	},
	{
		key: "jacket",
		word: "ज्याकेट ",
		question: { letter: "केट", position: 1 },
		options: ["ज्या", "सेट", "रेट"],
	},
	{
		key: "haatbajar",
		word: "हाटबजार",
		question: { letter: "हाट", position: 0 },
		options: ["बजार", "हजार", "तिहार"],
	},
	{
		key: "bhanchakotha",
		word: "भान्छाकोठा ",
		question: { letter: "कोठा", position: 1 },
		options: ["भान्छा", "मोटा", "लोटा"],
	},
	{
		key: "dhupbati",
		word: "धूपबत्ती",
		question: { letter: "बत्ती", position: 1 },
		options: ["धुप", "सुप", "हुप"],
	},
	{
		key: "gaijatra",
		word: "गाईजात्रा",
		question: { letter: "गाई", position: 0 },
		options: ["जात्रा", "मात्रा", "पात्र"],
	},
	{
		key: "nachgan",
		word: "नाचगान",
		question: { letter: "नाच", position: 0 },
		options: ["गान", "पान", "कान"],
	},
	{
		key: "sungabha",
		word: "सुनगाभा",
		question: { letter: "गाभा", position: 1 },
		options: ["सुन", "चुन", "गुन"],
	},
	{
		key: "bandagobi",
		word: "बन्दागोभी",
		question: { letter: "बन्दा", position: 0 },
		options: ["गोभी", "लोभी", "जोगी"],
	},
	{
		key: "prayogsala",
		word: "प्रयोगशाला",
		question: { letter: "प्रयोग", position: 0 },
		options: ["शाला", "गाला", "ताला"],
	},
	{
		key: "panchayebaja",
		word: "पञ्चेबाजा",
		question: { letter: "बाजा", position: 1 },
		options: ["पञ्चे", "रून्चे", "थुन्चे"],
	},
]

let level1 = words.map((word) => {
	let optionsObj = {}
	optionsObj = word.options.map((option, idx) => {
		let pos = null
		if (idx === 0) {
			pos = word.question.position === 0 ? 1 : 0
		} else {
			pos = Math.floor(Math.random() * 2)
		}
		return {
			...optionsObj,
			letter: option,
			position: pos,
		}
	})
	word.options = optionsObj
	return word
})

export default level1
