import React from "react"
import Header from "Components/Layout/Header"
import WhoseVoiceIsThis from "Containers/Play/WhoseVoiceIsThis/WhoseVoiceGame"

const bp = () => {
	return (
		<div className="activity__container">
			<Header storyName="यो कसको आवाज होला ?" isGame={true} />
			<WhoseVoiceIsThis />
		</div>
	)
}

export default bp
