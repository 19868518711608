import { appear, disappear } from "styledElements/keyframes";
import { keyframes } from "@emotion/core"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const popUp = keyframes`
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
`
const popDown = keyframes`
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
`

const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [

		{
			src: images("./imgs/girl05.png"),
			class: "image-five ",
			imgdiv: "imageCover",
			styles:{				
				top: '25.8%',
				width: '19.8%',
				left: '69.5%',
				animation: `${disappear} 0.01s 2s ease-out normal forwards, ${appear} 0.01s 11s ease-out normal forwards,
							${disappear} 0.01s 14s ease-out normal forwards `,
			}
		},
		{
			src: images("./imgs/kamallaugh.png"),
			class: "image-five ",
			imgdiv: "imageCover",
			styles:{				
				top: '25.8%',
				width: '19.8%',
				left: '69.5%',
				opacity: 0,
				animation: `${appear} 0.01s 18s ease-out normal forwards `,
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five ",
			imgdiv: "imageCover ",
			styles:{
				
				top: '25.8%',
				width: '19.8%',
				left: '69.5%',
				animation: `${appear} 0.01s 2s ease-out normal forwards, ${disappear} 0.01s 11s ease-out normal forwards,
							${appear} 0.01s 14s ease-out normal forwards, ${disappear} 0.01s 18s ease-out normal forwards  `,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble4 page2Buble",
			textIndivData: [
				{
					textData: "हामीले नेपालको धेरै ठाउँहरू घुमेर विभिन्‍न खानाका परिकारहरू देख्‍न पायौँ। हाम्रो देशमा अरु पनि धेरै परिकारहरू पाईन्छ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '14%',
				left: '32%',
				transformOrigin: "100% 80%",
				animation: `${popUp} 500ms 1s ease-out normal forwards, ${popDown} 500ms 13s ease-out normal forwards `,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble2 page4Buble",
			textIndivData: [
				{
					textData: "म तिम्रो घरमा आएँ भने मैले के के खान पाउँछु होला ? ",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '5%',
				left: '36%',
				transformOrigin: "100% 80%",
				animation: `${popUp} 500ms 14s ease-out normal forwards, ${popDown} 500ms 34s ease-out normal forwards `,
			}
		},
	],
	sounds: [
		{
			url: audios("./conclusion1.ogg"),
			delay: 1000,
		},
		{
			url: audios("./conclusion2.ogg"),
			delay: 14000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
	],
}

export default content
