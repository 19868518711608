const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./stich_page11.gif"),
			class: "image-one imgbox-sm petalGif",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./page11_stich.gif"),
			class: "image-one imgbox-sm petalPng",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p9 textbox textbox-ban two-lines-texts",
			textIndivData: [
				{
					textData: "आमाले फूलको बुट्टा भरेर ब्यागको प्वाल टाल्नुभयो।",
					textClass: "m-0 one-line textStyles text-p9a",
					timestamps: [
						{ start: 0 },
						{ start: 1.4 },
						{ start: 1.8 },
						{ start: 2.3 },
						{ start: 3.2 },
						{ start: 3.8 },
						{ start: 4.4 },
					],
				},
				{
					textData: "अरू चिटिक्क परेका बुट्टाहरू पनि सिउनुभयो।",
					textClass: "m-0 one-line textStyles text-p9b",
					timestamps: [{ start: 6.1 }, { start: 6.4 }, { start: 7 }, { start: 7.4 }, { start: 8 }, { start: 8.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s9.ogg"),
			delay: 500,
		},
	],
}

export default content
