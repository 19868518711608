import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const busToKhotang = keyframes`
0% {
	transform: rotate(44deg) translateX(0%);
}
100% {
	transform: rotate(44deg) translateX(168%);
}
`
const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(4) translate(-27%,-7%)",
					animation: `${transformTo("scale(9) translate(-27%,-16%)")} 4s 21s forwards ease-out`
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '6%',
				top: '22%',
				left: '41%',
				opacity: 1, 
				transform: 'scaleX(-1)',
				animation: `${disappear} 500ms 5s linear forwards				 
				`,
			}
		},
		{
			src: images("./imgs/talking_bothhandhip.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
			    width: '7%',
				top: '49%',
				left: '73%',
				opacity: 0,
				zIndex:2,
				transform: 'scaleX(-1)',
				animation: ` ${appear} 1s 15s ease-out normal forwards,
				${disappear} 1s 21s ease-out normal forwards			 
				`,
			}
		},
		{
			src: images("./imgs/bus3.svg"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				
				width: '17%',
				top: '24%',
				left: '24%',
				opacity:0,
				transform: "rotate(44deg)",
				zIndex: 2,
				animation: `${appear} 1s 7s ease-out normal forwards,
				${busToKhotang} 6s 8s ease-out normal forwards,
				${disappear} 1s 14s ease-out normal forwards
				`,
			}
		},
		
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble3 page2Buble",
			textIndivData: [
				{
					textData: "अब फेरि बस चढेर खोटाङ तिर लागौँ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '12%',
				left: '46%',
				transformOrigin: "0% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 1000ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 5s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble2 page2Buble",
			textIndivData: [
				{
					textData: "ओहो यहाँको गुफामा हलेसी महादेव को मन्दिर रहेछ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '23%',
				left: '35%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 16s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 21s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromDolakhaToKhotang",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '36.5%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '37%',
				position: 'absolute',
				left: '4%',
				transform: "rotate(224deg) scaleX(0)",
				transformOrigin: "right",
				animation: `${transformTo(["rotate(224deg) scaleX(1)"])} 5s 8s linear forwards, ${disappear} 1s 14s linear forwards`
			}
		},
		
		
	],
	sounds: [
		{
			url: audios("./khotang1.ogg"),
			delay: 1000,
		},
		{
			url: audios("./khotang2.ogg"),
			delay: 16000,
		},
		{
			url: audios("./sound_eff/bus.ogg"),
			delay: 6000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 22000,
		},
	],
}

export default content
