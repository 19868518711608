import { appear, disappear } from "styledElements/keyframes";
import { keyframes } from "@emotion/core"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const popUp = keyframes`
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
`
const popDown = keyframes`
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
`
const enterBus2 = keyframes`
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0%);
	}
`
const exitBus2 = keyframes`
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(100%);
	}
`

const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles:{
				opacity: 0,
				animation: `${appear} 2s 12s ease-out normal forwards, ${disappear} 1s 22s ease-out normal forwards `,
			}
		},
		{
			src: images("./imgs/bus2.svg"),
			toSvg: true,
			class: "image-five flip-h",
			imgdiv: "imageCover",
			styles:{
				transform: "translate(-100%)",
				width: "110%", top: "2%", left: "-6%", animation:`${enterBus2} 4s 27s forwards ease-out, ${exitBus2} 4s 34s forwards ease-in`,
				"#girl":{
					opacity:"0",
					animation: `${appear} 1s 32s forwards ease-in`
				}
			}
		},
		{
			src: images("./imgs/kamal.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "16%", top: "40%", left: "6%",opacity: "1",
				animation: `${disappear} 0.01s 2s ease-out normal forwards, ${appear} 0.01s 11s ease-out normal forwards, ${disappear} 1s 13s ease-out normal forwards, ${appear} 1s 21s ease-out normal forwards, ${disappear} 0.01s 24s ease-out normal forwards, ${appear} 0.01s 28s ease-in normal forwards, ${disappear} 1s 31s ease-out normal forwards `,
			}
		},
		{
			src: images("./imgs/talkingkamal.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "16%", top: "40%", left: "6%", opacity: "0",
				animation: `${appear} 0.01s 2s ease-out normal forwards, ${disappear} 0.01s 11s ease-out normal forwards, ${appear} 0.01s 24s ease-in normal forwards, ${disappear} 0.01s 28s ease-in normal forwards`,
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five flip-h ",
			imgdiv: "imageCover ",
			styles:{
				top: "40.8%", width: "15.8%", left: "7.5%", opacity: "0",
				animation: `${appear} 1s 13s ease-out normal forwards, ${disappear} 1s 21s ease-out normal forwards `,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble1 page2Buble",
			textIndivData: [
				{
					textData: "हेलो साथीहरू, मेरो नाम कमल। आऊ म सँग हाम्रो देशका विभिन्‍न समुदायहरूले के के खान्छन् हेर्न जाऔँ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				animation: `${popUp} 500ms 1s ease-out normal forwards, ${popDown} 500ms 13s ease-out normal forwards `,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble1 page2Buble",
			textIndivData: [
				{
					textData: "हरेक समुदायको रहनसहन सँगै आफ्नै विशेष खानेकुरा हुन्छ भनेर थाहा होला नि।",
					textClass: "txt1 preeti",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				animation: `${popUp} 500ms 14s ease-out normal forwards, ${popDown} 500ms 22s ease-out normal forwards `,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble1 page4Buble",
			textIndivData: [
				{
					textData: "ठाउँ ठाउँमा गएर मिठा मिठा परिकारहरू बारे सिकौँ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				animation: `${popUp} 500ms 24s ease-out normal forwards, ${popDown} 500ms 30s ease-out normal forwards `,
			}
		},
	],
	sounds: [
		{
			url: audios("./starting1.ogg"),
			delay: 1000,
		},
		{
			url: audios("./starting2.ogg"),
			delay: 13000,
		},
		{
			url: audios("./starting3.ogg"),
			delay: 24000,
		},
		{
			url: audios("./sound_eff/bus.ogg"),
			delay: 29000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
	],
}

export default content
