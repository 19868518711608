import React from "react"
import normalIcon from "../icons/controls/home_blue.svg"
const HomeBtn = (props) => {
	return (
		<span
			key="HomeBtn"
			onClick={
				props.disabled
					? () => {
							return
					  }
					: props.homeBtnClick
			}
			className={props.disabled ? "prev__btn disabled" : "prev__btn"}
		>
			<img src={normalIcon} className="img-fluid" alt="home" />
		</span>
	)
}

export default HomeBtn
