import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_05_clean.jpg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./stone01.png"),
			imgdiv: "imageCover d-none",
			styles: {
				top: "70%",
				left: "46.6%",
				width: "11%",
			},
		},
		{
			src: images("./dad_breaking_stone.gif"),
			styles: {
				top: '9%',
				width: '19%',
				left: '10.5%',
			}
		},
		{
			src: images("./stone01.gif"),
			imgdiv: "imageCover d-none",
			styles: {
				top: "70%",
				left: "46.6%",
				width: "11%",
			},
		},
		{
			src: images("./_.png"),
			imgdiv: "imageCover boyWithStoneSprite",
			styles: {
				background: `url(${images("./boy_play_with_stone.png")}) 0px center / cover no-repeat`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page5boy topRightBubble text-center",
			styles:{
				width: '38%',
				top: '24%',
				left: '49.6%',
			},
			textIndivData: [
				{
					textData: "मलाई यहिँ बाटो र पुलहरू बनाउन रमाइलो लाग्छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.8 },
						{ start: 1.3 },
						{ start: 1.7 },
						{ start: 2.1 },
						{ start: 2.7 },
						{ start: 3.2 },
						{ start: 3.9 },
						{ start: 4.5 },
					],
				},
				{
					textData: "म किन स्कूल जाने र?",
					textClass: "m-0",
					timestamps: [{ start: 4.7 }, { start: 5.1 }, { start: 5.5 }, { start: 5.7 }, { start: 6.0 }, { start: 6.4 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./4.ogg"),
			delay: 4000,
		},
		{
			url: audios("./a4.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
