import { appear, disappear } from "styledElements/keyframes"
const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./yello_band.png"),
			class: "image-one yello_bandp2",
			imgdiv: "imageCover",
		},
		{
			src: images("./zera_putting_triangle.gif"),
			class: "image-one zera_putting_triangle",
			imgdiv: "imageCover",
		},
		{
			src: images("./zera_putting_triangle.png"),
			class: "image-one zera_putting_trianglepng",
			imgdiv: "imageCover",
		},
		{
			src: images("./triangle.gif"),
			styles: {
				top: '33%',
				left: '52%',
				width: '27%',
				transform: "scale(1.4)",
				animation: `${disappear} 0.01s 7s ease-out normal both`,
			},
		},
		{
			src: images("./triangle.png"),
			styles: {
				top: '33%',
				left: '52%',
				width: '27%',
				transform: "scale(1.4)",
				animation: `${appear} 0.01s 7s ease-out normal both`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story-text page3Text text-center",
			textIndivData: [
				{
					textData: "",
					textClass: "m-0",
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./bg/se_page2to4.ogg"),
			volume: 0.4,
			delay: 200,
			isBg: true,
			loop: true,
		},
		{
			url: audios("./bg/insert.ogg"),
			delay: 1500,
			volume: 0.3,
		},
		{
			url: audios("./triangle.ogg"),
			delay: 7000,
		},
	],
}

export default content
