export default [
	{
		key: "aama",
		word: "आमा",
		options: ["मामा", "जामा", "तामा", "लामा"],
	},

	{
		key: "kaag",
		word: "काग",
		options: ["साग", "भाग", "नाग", "दाग"],
	},
	{
		key: "paat",
		word: "पात",
		options: ["भात", "हात", "सात", "रात"],
	},
	{
		key: "dhara",
		word: "धारा",
		options: ["रारा", "सारा", " तारा", "बारा"],
	},
	{
		key: "chata",
		word: "छाता",
		options: ["माता", "राता", "नाता", "पाता"],
	},
	{
		key: "maas",
		word: "मास",
		options: ["सास", "पास", "रास", "तास"],
	},
	{
		key: "haat",
		word: "हाट",
		options: ["बाट", "काट", "साट", "पाट"],
	},

	{
		key: "maam",
		word: "माम",
		options: ["काम", "ठाम", "लाम", "जाम"],
	},
	{
		key: "veda",
		word: "भेडा",
		options: ["गेडा", "पेडा", "घेरा", "फेरा"],
	},
	{
		key: "maina",
		word: "मैना",
		options: ["बैना", "लैना", "ऐना", "पैना"],
	},
	{
		key: "ghee",
		word: "घिउ",
		options: ["जिउ", "लिउ", "पिउ", "सिउ"],
	},
	{
		key: "mit",
		word: "मित",
		options: ["सित", "रित", "गीत", "हित"],
	},
	{
		key: "ghoda",
		word: "  घोडा",
		options: ["जोडा", "गोडा", "रोडा", "सोडा"],
	},
	{
		key: "jun",
		word: "जुन",
		options: ["नुन", "गुन", "घुन", "चुन"],
	},
	{
		key: "baaja",
		word: "बाजा",
		options: ["खाजा", "राजा", "ताजा", "गाजा"],
	},
	{
		key: "kachya",
		word: "कक्षा",
		options: ["रक्षा", "पक्ष", "दीक्षा", "भिक्षा"],
	},
	{
		key: "nani",
		word: "नानी",
		options: ["पानी", "सानी", "हानी", "खानी"],
	},

	// {
	// 	key: "class",
	// 	word: "कक्षा",
	// 	options: ["रक्षा", "पक्ष", "दीक्षा", "भिक्षा"]
	// },
	// {
	// 	key: "daal",
	// 	word: "मास",
	// 	options: ["सास", "तास", "पास", "रास"]
	// },
	// {
	// 	key: "ghee",
	// 	word: "घिउ",
	// 	options: ["जिउ", "लिउ", "पिउ", "सिउ"]
	// },
	// {
	// 	key: "horse",
	// 	word: "घोडा",
	// 	options: ["जोडा", "गोडा", "रोडा", "सोडा"]
	// },
	// {
	// 	key: "leaf",
	// 	word: "पात",
	// 	options: ["भात", "हात", "सात", "रात"]
	// },
	// {
	// 	key: "maina",
	// 	word: "मैना",
	// 	options: ["बैना", "लैना", "ऐना", "पैना"]
	// },
	// {
	// 	key: "meet",
	// 	word: "मित",
	// 	options: ["सित", "रित", "गीत", "हित"]
	// },
	// {
	// 	key: "rice",
	// 	word: "माम",
	// 	options: ["जाम", "काम", "ठाम", "लाम"]
	// },
	// {
	// 	key: "sheep",
	// 	word: "भेडा",
	// 	options: ["गेडा", "पेडा", "घेरा", "फेरा"]
	// },
	// {
	// 	key: "umbrella",
	// 	word: "छाता",
	// 	options: ["माता", "राता", "नाता", "पाता"]
	// },
	// {
	// 	key: "water_tap",
	// 	word: "धारा",
	// 	options: ["रारा", "सारा", "तारा", "बारा"]
	// }
]
