import React, { useReducer, useEffect } from "react"
import _ from "underscore"
import { Howl } from "howler"

import Stage1 from "./pages/Stage/Stage1"
import Stage2 from "./pages/Stage/Stage2"
import Stage3 from "./pages/Stage/Stage3"

// styles
import "./game.styles.scss"

import Toolbar from "./components/Toolbar/Toolbar"
import loaderSvg from "../../../assets/ball-triangle.svg"

// helpers
import { reducer } from "./helpers/helpers"
import { getQueryVariable } from "../mainHelper"

//assets
import bgImg from "./pages/Stage/assets/bg02.png"
import bgImg2 from "./pages/Stage/assets/bgLvl2.png"
import roofImg from "./components/Wall/assets/wall-roof.png"
import baseImg from "./components/Wall/assets/wall-base.png"
import SpeakerImg from "./content/images/gameAssets/speaker.png"
import hammerPng from "./components/Hammer/assets/images/hammer.png"
import stoneImg from "./components/Stone/assets/images/stone01.png"
import stoneBrakeGif from "./components/Stone/assets/images/breaking_stone2.gif"

import bg_infinite_door from "./content/audios/sound_effects/InfiniteDoors.ogg"
const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

export const DispatchContext = React.createContext(null)
export const StateContext = React.createContext(null)

const HitTheWall = () => {
	const settings = { audioHints: true }
	const useWindowSize = () => {
		const isClient = typeof window === "object"

		const getSize = () => {
			let width = window.innerWidth <= 1600 ? window.innerWidth : 1600
			let height = window.innerHeight
			let availableHeight = window.innerHeight - 56

			let adjustedHeight = availableHeight > (width * 9) / 16 ? (width * 9) / 16 : height - 56
			let adjustedwidth = (adjustedHeight * 16) / 9

			return {
				width: isClient ? Math.ceil(adjustedwidth) : undefined,
				height: isClient ? Math.ceil(adjustedHeight) : undefined,
			}
		}

		const windowSize = React.useRef(getSize())

		// resize hook
		React.useEffect(() => {
			const isClient = typeof window === "object"
			if (!isClient) {
				return false
			}

			const handleResize = _.debounce(() => {
				windowSize.current = getSize()
				dispatch({
					type: "RESET",
					values: { windowSize: windowSize.current },
				})
			}, 500)

			window.addEventListener("resize", handleResize)
			return () => window.removeEventListener("resize", handleResize)
			// eslint-disable-next-line
		}, [])

		return windowSize.current
	}

	const [state, dispatch] = useReducer(reducer, {
		settings: settings,
		currentPage: "GAME_START",
		// currentPage: "START",
		level: getQueryVariable("level") || "easy",
		stage: 0,
		levelData: [],
		speakerData: [],
		options: [],
		choosenWord: {},
		loadingAssets: true,
		rewardComplete: false,
		levelComplete: false,
		reset: false,
		windowSize: useWindowSize(),
	})

	useEffect(() => {
		const preloadImages = (urls, allImagesLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadImage = (url, anImageLoadedCallback) => {
				let img = new Image()
				img.onload = anImageLoadedCallback
				img.src = url
			}

			urls.forEach(function (url) {
				preloadImage(url, function () {
					loadedCounter++
					// console.log('Number of loaded images: ' + loadedCounter);
					if (loadedCounter === toBeLoadedNumber) {
						allImagesLoadedCallback()
					}
				})
			})
		}

		let images = [bgImg, bgImg2, roofImg, baseImg, SpeakerImg, hammerPng, stoneImg, stoneBrakeGif]

		preloadImages(images, () => {
			// console.log("preloaded");
			dispatch({ type: "LOADING_COMPLETE" })
		})
	}, [])

	let windowSize = useWindowSize()

	useEffect(() => {
		dispatch({ type: "SET" })
		const bgMusic = new Howl({
			src: [bg_infinite_door],
			autoplay: false,
			preload: true,
			volume: 0.12,
			loop: true,
		})
		bgMusic.play()
		return () => {
			bgMusic.unload()
		}
	}, [state.level])

	useEffect(() => {
		if (state.reset) {
			dispatch({ type: "SET" })
			const bgMusic = new Howl({
				src: [bg_infinite_door],
				autoplay: false,
				preload: true,
				volume: 0.15,
				loop: true,
			})
			bgMusic.play()
			return () => {
				bgMusic.unload()
			}
		}
	}, [state.reset])

	let Stage
	if (state.level === "easy") {
		Stage = <Stage1 />
	} else if (state.level === "medium") {
		Stage = <Stage2 />
	} else {
		Stage = <Stage3 />
	}

	return (
		<div
			className="game-container"
			id="game-container"
			key={windowSize.width}
			style={{
				width: `${windowSize.width}px`,
				height: `${windowSize.height}px`,
			}}
		>
			{state.loadingAssets ? (
				<div className="game-loading">
					<div className="loader-inner">
						<img src={loaderSvg} className="loader" alt="loader" />
						<span>Loading...</span>
					</div>
				</div>
			) : (
				<DispatchContext.Provider value={dispatch}>
					<StateContext.Provider value={state}>{state.levelData.length !== 0 && Stage}</StateContext.Provider>
				</DispatchContext.Provider>
			)}
			<Toolbar totalPage={state.levelData.length} currentPage={state.stage} />
		</div>
	)
}

// HitTheWall.whyDidYouRender = true;
// export const HitTheWallMemo = React.memo(HitTheWall);
// HitTheWallMemo.whyDidYouRender = true;

// export default HitTheWallMemo;
export default HitTheWall
