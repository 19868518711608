import React, { useContext, useEffect } from "react"
import Options from "../../components/Options/Options"

import { StateContext } from "../../HitTheWallGame"
// assets
import "./stage2.styles.scss"
import bgImg2 from "./assets/bgLvl2.png"

// Components
import Hammer from "../../components/Hammer/Hammer"
import Speaker from "../../components/Speaker/Speaker"
import CustomModal from "../../components/LevelCompleteModal/CustomModal"

function Stage3() {
	const state = useContext(StateContext)

	useEffect(() => {
		let cursor = document.getElementById("cursor")

		function handleMouseMove(e) {
			cursor.setAttribute("style", "top: " + (e.pageY - 50) + "px; left: " + (e.pageX + 10) + "px;")
		}

		document.addEventListener("mousemove", handleMouseMove)

		return () => document.removeEventListener("mousemove", handleMouseMove)
	}, [])

	const handleMouseLeave = () => {
		let cursor = document.getElementById("cursor")
		if (cursor) {
			cursor.classList.add("hideHammer")
		}
	}

	const handleMouseEnter = () => {
		let cursor = document.getElementById("cursor")
		if (cursor) {
			cursor.classList.remove("hideHammer")
		}
	}

	return (
		<div
			className="game-stage"
			id="level2Stage"
			style={{
				backgroundImage: `url(${bgImg2})`,
			}}
			onMouseLeave={handleMouseLeave}
			onMouseEnter={handleMouseEnter}
		>
			{!state.levelComplete && (
				<>
					<div className="stoneContainer">
						<Options key={`options-${state.stage}`} />
					</div>

					<Speaker />
					<Hammer />
				</>
			)}

			<CustomModal isOpen={state.levelComplete} ariaHideApp={false} />
		</div>
	)
}

export default Stage3
