import React, {useState} from "react"
import Header from "Components/Layout/Header"
import BalloonPop from "Containers/Play/BalloonPop/BalloonPopGame"

const Bp = () => {

	//for flutter communication 
	const [isCallFromFlutter, setisCallFromFlutter] = useState(false);
	// var isCallFromFlutter = false;
	
		var callFromFlutterFunc = () => {
			console.log('hello from JS triggered by Flutter in balloon');
			setisCallFromFlutter(true);
			// isCallFromFlutter = true;
		};
		window.balloonFunc = callFromFlutterFunc;
		console.log(window);
	//for flutter communication 

	return (
		<div className="activity__container">
			<Header storyName="बेलुन फुटाऊ" isGame={true} isOpenInFlutter={isCallFromFlutter}/>
			<BalloonPop isOpenInFlutter={isCallFromFlutter}/>
		</div>
	)
}

export default Bp
