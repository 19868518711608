const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./six_monkeys.gif"),
			class: "image-8",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeftText text-center",
			textIndivData: [
				{
					textData: "छओटा बाँदरले",
					textClass: "fz-5 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 1.6 }],
				},
				{
					textData: "भुइँ बनाए।",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 3.1 }, { start: 4.1 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s6.ogg"),
			delay: 3000,
		},
	],
}

export default content
