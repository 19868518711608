// import { jsx } from "@emotion/core"
// import _, { delay, object } from "underscore"
import React, {useContext, useEffect, useState} from "react"
import {Howl, Howler} from "howler"
import { StateContext, DispatchContext } from "../../ShapeColorGame"
import "./scenecss/scene5.styles.scss"
// import { sleep } from "../../utils"
import Options from "../Options/Options"
import OptionsSecond from "../Options/OptionsSecond"
import gsap from "gsap"
import mainTextAnimation from "../../../Common/TextAnimations"
// import { counter } from "@fortawesome/fontawesome-svg-core"
// import textHighLight from "../../../Common/TextHighLightAnime"
// import { faBorderNone } from "@fortawesome/free-solid-svg-icons"
// const images = require.context("../images", true)
const audios = require.context("./dailog/", true)

const Scene = (({stageData, assets, story})=>{
        const state = useContext(StateContext)                /* importing statecontext from shapecolorgame and initializiing at state */
        const dispatch = useContext(DispatchContext)         /* importing statecontext from shapecolorgame and initializiing at state */
        let girlRef = React.useRef(null);                    /* initializing girlrif as reference which directly change in file  */
        let robotRef = React.useRef(null);
        let daiRef = React.useRef(null);
        let speechboxdivRef = React.useRef(null);
        let speechboxRef = React.useRef([]);
        // console.log(story);
const initial = {
        pageup: 0,
        audio: false,
        //    QuestionShow: 0,
        QnAnsShow: false,
        secQNAnsShow: false,
        dailogCounter: 0,
        disable: true,
        disableSec: false,
        disableThird: true
}

const [stageState, setStageState] = useState(initial);
//    const [data, setdata] = useState(stageData);
   var timestamp = gsap.timeline({});                           /* creating timeline for gsap animation */
//    let bgMusic;
//    let dailogSound;
//    console.log(stageState)
   let content = {                                              /* all data are store in object which are used as par their requirement */
        texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-1_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 2% 8% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "यहि ठाउँमा हिजो मेरो आँखा झरेको थियो ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.35 }, { start: 1.60 }, { start: 2.10 }, { start: 2.50 }, { start: 2.80 }],
                                        delayTime: 12000,
                                        hideTimer: 18000,
                                }
                        ]
                },
                {  
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-2_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "यहाँ दिदि हुनुहुँदो रहेछ । उहाँलाई सोधौँ न एक पटक ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 0.90 }, { start: 1.70 }, { start: 2.30 },{ start: 2.70 }, { start: 3.60 }, { start: 4.10 }, { start: 4.25 }, { start: 4.50 }, { start: 4.90 }],
                                        delayTime: 19000,
                                        hideTimer: 26000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-3_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 12% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "दिदि ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 27000,
                                        hideTimer: 29000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-4_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '2% 3% 10% 3%'
                        },
                        textIndivData: [
                                {
                                        textData: "हजुर !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.55 }],
                                        delayTime: 30000,
                                        hideTimer: 33000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-5_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 2% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "तपाईंले मेरो आँखा देख्नुभएको छ ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.45 }, { start: 0.75 },{ start: 1.05 }, { start: 1.70 }, { start: 2.10 }],
                                        delayTime: 34000,
                                        hideTimer: 38000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-6_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "आँखा ! कुन आकारको थियो होला ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.70 }, { start: 0.90 }, { start: 1.25 }, { start: 1.80 }, { start: 2.15 }, { start: 2.50 }],
                                        delayTime: 39000,
                                        hideTimer: 43000
                                }
                        ]
                },
                // dailog after quention option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-7_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 7,
                                        textData: "यहि हो दिदि वृत्ताआकार र मेरो आखाँ पनि यहि हो ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6600000,
                                        hideTimer: 6100000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-8_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 8,
                                        textData: "ए ! मलाई थाहा भयो अब । यो लिऊ, तिम्रो आँखा ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6600000,
                                        hideTimer: 6800000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-9_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "धन्यवाद दिदि ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6800000,
                                        hideTimer: 6900000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-10_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 10,
                                        textData: "वृत्ताआकारका अरू केके वस्तु हुन्छन् होला है ?",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6900000,
                                        hideTimer: 7000000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-11_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 11,
                                        textData: "आऊ, मेरो पसलमा हेरौँ ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6900000,
                                        hideTimer: 7000000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-12_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 12,
                                        textData: "हुन्छ !",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6900000,
                                        hideTimer: 7000000
                                }
                        ]
                },
                // dailog after second option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-13_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 13,
                                        textData: "आहा ! वृत्ताआकारको बारेमा झनै राम्रोसँग बुझ्न पायौँ ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 7100000,
                                        hideTimer: 7200000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-14_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "कस्तो रमाइलो है फुर्बा ?",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 7100000,
                                        hideTimer: 7400000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-15_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हो त, साह्रै रमाइलो लाग्यो समा ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 7100000,
                                        hideTimer: 7400000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-16_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "धन्यवाद दिदि । हामीलाई सहयोग गर्नुभएकामा ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 7100000,
                                        hideTimer: 7400000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-17_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "केही छैन भाइबहिनी ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 7100000,
                                        hideTimer: 7400000,
                                }
                        ]
                }
        ],
        sounds: 
		[{
			url: audios("./scene5_furba1.wav"),
			delay: 12000,
		},{
                        url: audios("./scene5_sama1.wav"),
			delay: 19000,
                },{
			url: audios("./didi.wav"),
			delay: 27000,
		},{
                        url: audios("./Hajur.wav"), /*  didi sound should be here*/
			delay: 30000,
                },{
                        url: audios("./scene5_furba2.wav"),
			delay: 34000,
                },{
                        url: audios("./scene5_didi1.wav"),
			delay: 39000,
                }],
}
        let content1 = {
                texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-7_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "यहि हो दिदि वृत्ताआकार र मेरो आखाँ पनि यहि हो ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.35 }, { start: 0.55 }, { start: 0.80 }, { start: 1.90 }, { start: 2.15 }, { start: 2.45 }, { start: 2.85 }, { start: 3.35 }, { start: 3.75 }, { start: 4.10 }],
                                        delayTime: 1000,
                                        hideTimer: 6000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-8_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 8,
                                        textData: "ए ! मलाई थाहा भयो अब । यो लिऊ, तिम्रो आँखा ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.50 }, { start: 0.90 }, { start: 1.20 }, { start: 1.55 }, { start: 1.90 }, { start: 2.00 }, { start: 2.30 }, { start: 2.70 }, { start: 3.10 }, { start: 3.50 }],
                                        delayTime: 7000,
                                        hideTimer: 11000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-9_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "धन्यवाद दिदि ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.70 }, { start: 1.15 }],
                                        delayTime: 20000,
                                        hideTimer: 23000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-10_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 10,
                                        textData: "वृत्ताआकारका अरू केके वस्तु हुन्छन् होला है ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.90 }, { start: 1.25 }, { start: 1.60 }, { start: 2.10 }, { start: 2.40 }, { start: 2.70 }, { start: 3.50 }],
                                        delayTime: 33000,
                                        hideTimer: 38000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-11_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 11,
                                        textData: "आऊ, मेरो पसलमा हेरौँ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.50 }, { start: 2.50 }],
                                        delayTime: 39000,
                                        hideTimer: 42000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-12_p5',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 12,
                                        textData: "हुन्छ !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.70 }],
                                        delayTime: 43000,
                                        hideTimer: 46000
                                }
                        ]
                },
                ],
                sounds:[
                        {
                                url: audios("./scene5_furba3.wav"),
			        delay: 1000,
                        },
                        {
                                url: audios("./scene5_didi2.wav"),
			        delay: 7000,
                        },
                        {
                                url: audios("./dhanyabad didi.wav"),
			        delay: 20000,
                        },
                        {
                                url: audios("./scene5_furba4.wav"),
			        delay: 33000,
                        },
                        {
                                url: audios("./scene5_didi3.wav"),
			        delay: 39000,
                        },
                        {
                                url: audios("./scene5_sama2.wav"),
			        delay: 43000,
                        },
                ]
        }

        let content2 = {
                texts: [
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-13_p5',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "आहा ! वृत्ताआकारको बारेमा झनै राम्रोसँग बुझ्न पायौँ ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.65 }, { start: 2.90 }, { start: 3.10 }],
                                                delayTime: 1000,
                                                hideTimer: 6000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-14_p5',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "कस्तो रमाइलो है फुर्बा ?",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.10 }, { start: 1.50 }, { start: 1.70 }],
                                                delayTime: 7000,
                                                hideTimer: 12000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-15_p5',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "हो त, साह्रै रमाइलो लाग्यो समा ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.65 }],
                                                delayTime: 13000,
                                                hideTimer: 17000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-16_p5',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "धन्यवाद दिदि । हामीलाई सहयोग गर्नुभएकामा ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.65 }],
                                                delayTime: 18000,
                                                hideTimer: 22000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-17_p5',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "केही छैन भाइबहिनी ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }],
                                                delayTime: 23000,
                                                hideTimer: 25000,
                                        }
                                ]
                        },
                ],
                sounds:[
                        {
                                url: audios("./scene5_furba5.wav"),
			        delay: 1000,
                        },
                        {
                                url: audios("./scene5_sama3.wav"),
			        delay: 7000,
                        },
                        {
                                url: audios("./scene5_furba6.wav"),
			        delay: 13000,
                        },
                        {
                                url: audios("./scene5_furba7.wav"),
			        delay: 18000,
                        },
                        {
                                url: audios("./scene5_didi4.wav"),
			        delay: 23000,
                        }
                ]
        }
        // console.log(story);
   useEffect(()=>{                                          /* these effect is use for gsp animation of each and every object */
//    console.log(girlRef, girlRef.current.children);
        if(stageState.dailogCounter !== 9 && stageState.dailogCounter !== 13){
                console.log(stageState.dailogCounter + " " + stageState.dailogCounter)
                timestamp.to(girlRef.current, {
                        duration: 9,
                        x: "200%",
                        ease: "power1.in",
                        delay: 1,
                        onComplete: ()=>{
                                girlRef.current.src = stageData.girlLooking
                                
                        }
                        })
                        .to(robotRef.current, {
                                duration : 7,
                                // delay: 1,
                                x: "115%",
                                ease: "power1.in",
                                onComplete: ()=>{
                                        console.log("completed") ;
                                        girlRef.current.style.transform = "translate(200%, 0%) rotateY(180deg)"
                                        robotRef.current.src = stageData.robotLookPng
                                }
                        }, "-=7")
                        // .to(robotRef.current,{
                        //         delay: 2,
                        //         onComplete: () => {
                        //                 robotRef.current.src = stageData.robotBupGif
                        //         }
                        // })
                        .to(robotRef.current,{
                                delay: 1,
                                onComplete: () => {
                                        robotRef.current.src = stageData.robotHandGif;
                                }
                        })
        }else{

        }                
}, [girlRef.current, robotRef.current, speechboxRef.current])

   useEffect(()=> {                                           /* this useEffect is uesd for background music play */
        if(!stageState.audio){
                        setStageState({...stageState, audio:true})
                        const bgMusic = new Howl({
                                src: [content.sounds[stageState.pageup].url],
                                autoplay: false,
                                preload: true,
                                volume: 0.07,
                                loop: true,
                                // onend: async () => {
                                //         // if (stageData.id === 10) {
                                //         //         setOptionState({ ...optionState, finalStageQ: stageData.questioner })
                                //         //         dispatch({ type: "LAST_PAGE_COMPLETE" })
                                //         // }
                                //         // await sleep(2000)
                                //         // await playAnimalSound()
                                // },
                        })
                        // console.log(content.sounds[stageState.pageup].url)
                        bgMusic.play()
                        return () => {
                                bgMusic.unload()
                                Howler.unload()
                        }
                }      
        },[stageState.audio, stageData.bgMusic, content.sounds]);

        let textList = content.texts;
        let textListB;
        let soundList;
        let textListC;
        let soundListC;
useEffect(()=>{                                      /* this useEffect is used for showing dailogbox highilighting text and play audio for each dailog*/
        let pagenumber  = 1;
        textList = content.texts;
        soundList = content.sounds;
        // console.log(stageData.disable)
        if(stageState.disable){
                let objCount = 1   
                let timeouts = []
                let timestams = []
                let textIndivData = []
                let delaytime = []
                let hideTime = []   
                let showdata;
                //    console.log(textList);
                if( textList?.length > 0){
                        textList.forEach((item) => {
                                textIndivData = [...textIndivData, ...item.textIndivData]
                        })
                        textIndivData.forEach((data) =>{
                                // console.log(data)
                                
                                if(data.timestamps){
                                        timestams = [...timestams, ...data.timestamps];
                                        // textHighLight(data.textdata, data.timestamps)
                                }else{
                                        timestams = null
                                }
                                
                                if(data.delayTime){
                                        delaytime.push(
                                                setTimeout(()=>{
                                                showdata  = document.getElementsByClassName('sp-'+ objCount+'_p5');
                                                        // console.log(delaytime);
                                                        gsap.to(showdata, {
                                                                scale: 1,
                                                                ease: "power1.in",
                                                                display: "block",
                                                                onComplete: () => {
                                                                        if(objCount == 3 || objCount == 4){
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                // daiRef.current.src = stageData.daiTalking;
                                                                                girlRef.current.src = stageData.girlTalkGif;
                                                                                girlRef.current.style.transform = "translate(200%, 0%) rotateY(0deg)"
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 5){
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiTalking;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 6){
                                                                                robotRef.current.src = stageData.robotHandGif;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.didiPng;
                                                                                console.log(objCount)
                                                                        }
                                                                        else if(objCount == 7){
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                daiRef.current.src = stageData.daiTalking;
                                                                                console.log(objCount)
                                                                        }
                                                                }      
                                                        })
                                                
                                                        
                                                        let textAnimations;
                                                        import(`../../Animations`).then((customAnims) =>{
                                                                let animationObj = new customAnims.default()
                                                                textAnimations = animationObj.runTextAnimations
                                                                // textAnimations = mainTextAnimation
                                                                if(animationObj.runTextAnimations){
                                                                        animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        objCount ++ ;
                                                                        // animationObj.forEach(() =>{
                                                                        //         animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        // })
                                                                }
                                                                // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                        })
                                                        .catch((error) =>{
                                                                if(story.defaultTextAnimation){
                                                                        textAnimations = mainTextAnimation
                                                                }else{
                                                                        textAnimations = () => {}
                                                                }
                                                        })
                                                },data.delayTime)
                                        )
                                }else{
                                        delaytime = null
                                }
                                if(data.hideTimer){
                                        hideTime.push(
                                                setTimeout(() => {
                                                        showdata  = document.getElementsByClassName('sp-'+ (objCount-1)+'_p5');
                                                        // showdata[0].style.display = 'none';
                                                        gsap.to(showdata, {
                                                                scale: 0.1,
                                                                ease: "power1.in",
                                                                delay: 1,
                                                                display: "none",
                                                                onComplete: () => {
                                                                                // robotRef.current.src = stageData.robotLookPng;
                                                                                // girlRef.current.src = stageData.girlLooking;
                                                                        
                                                                }
                                                        })
                                                        if(objCount === 7){
                                                                        setStageState({...stageState, disable:false, secQNAnsShow:false, QnAnsShow:true});   
                                                                        // textIndivData = [textIndivData];
                                                                        // console.log(textIndivData);

                                                        }
                                                }, data.hideTimer)
                                        )
                                }
                        })
                }else{
                        timestams = null
                }
                if(soundList && soundList.length){
                        soundList.forEach((sound) => {
                                // console.log(sound)
                                let audio = new Howl({
                                        src: [sound.url],
                                        preload: true,
                                        autoplay:false,
                                        loop: sound.loop ?? false,
                                        volume: sound.volume ?? 0.7,
                                })
                                if(sound.delay){
                                        timeouts.push(
                                                setTimeout(()=>{
                                                        audio.play();
                                                        // content.texts.textMainDivClass.style.display = 'block'
                                                },sound.delay)
                                        )
                                }else{
                                        // audio.unload()
                                }
                        })
                }
                return () => {
                        // if(audio) audio.unload()
                        // Howler.unload()
                        timeouts.forEach((timeout) =>{
                                clearTimeout(timeout)
                                // Howler.unload()
                        })
                        delaytime.forEach((timeout) => {
                                clearTimeout(timeout);
                        })
                        gsap.killTweensOf("*")
                        // console.log("last return")
                }
        }
},[stageData.disable, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData])

useEffect(()=> {            /* this useeffect is used when user click the right answer*/
        if(stageState.dailogCounter === 9){
                if(stageState.disableSec){
                        textListB = content1.texts;
                        soundList = content1.sounds;
                        let textIndivData = []
                        let timestamsSec = []
                        let timeouts = []
                        let speechCount = 7;
                        let delaytime = []
                        let hideTime = []
                        let showdata;
                        // console.log(stageState.disable)
                        robotRef.current.classList.add("robot_afterAnsCorrect_p5");
                        girlRef.current.classList.add("girl_afterAnsCorrect_p5");
                        if(robotRef.current){
                                robotRef.current.src = stageData.robotLookPng  
                        }
                        if(girlRef.current){
                                girlRef.current.src = stageData.girlTalkGif
                        }
                        if( textListB?.length > 0){
                                textListB.forEach((item) => {
                                        textIndivData = [...textIndivData, ...item.textIndivData]
                                })
                                textIndivData.forEach((data) =>{
                                        
                                        if(data.timestamps){
                                                timestamsSec = [...timestamsSec, ...data.timestamps];
                                        }else{
                                                timestamsSec = null
                                        }
                                        
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('sp-'+ speechCount+'_p5');
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCount === 8){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotHandGif;
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 9){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        daiRef.current.src = stageData.daiTalking;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 10){
                                                                                        girlRef.current.src = stageData.girlTalkGif;
                                                                                        robotRef.current.src = stageData.robotEyeGif;
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 11){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotHandGif;
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 12){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        daiRef.current.src = stageData.daiTalking;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 13){
                                                                                        girlRef.current.src = stageData.girlTalkGif;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                        console.log(speechCount);
                                                                                }
                                                                        }      
                                                                });
                                                                let textAnimations1;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations1 = animationObj.runTextAnimations
                                                                        if(animationObj.runTextAnimations){
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCount-1], timestamsSec)
                                                                                speechCount ++ ;
                                                                        }
                                                                        // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations1 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations1 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('sp-'+ (speechCount-1)+'_p5');
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {                                
                                                                        }
                                                                })
                                                                if(speechCount == 9){
                                                                        robotRef.current.src = stageData.robotGif;
                                                                        daiRef.current.src = stageData.daiGivingHand;
                                                                        gsap.to(robotRef.current, {
                                                                                left: "65%",
                                                                                duration: 7,
                                                                                delay: 1,
                                                                                ease: "none",
                                                                                onComplete: () => {
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                        robotRef.current.src = stageData.robotEyeGif
                                                                                }
                                                                        })
                                                                }
                                                                if(speechCount == 10){
                                                                        robotRef.current.style.transform = "translate(-35%) rotateY(0deg)";
                                                                        robotRef.current.src = stageData.robotGif;
                                                                        girlRef.current.src = stageData.girlLooking;
                                                                        gsap.to(robotRef.current, {
                                                                                left: "9%",
                                                                                duration: 7,
                                                                                delay: 2,
                                                                                ease: "none",
                                                                                onComplete: () => {
                                                                                        robotRef.current.style.transform = "rotateY(180deg)";
                                                                                        robotRef.current.src = stageData.robotHandGif
                                                                                }
                                                                        })
                                                                }
                                                                if(speechCount == 13){
                                                                        setStageState({...stageState, dailogCounter:13, secQnAnsShow:true});
                                                                        speechboxdivRef.current.style.display = "none";
                                                                        showdata[0].style.display = 'none';
                                                                } 
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsSec = null;
                        }
                        if(soundList && soundList.length){
                                soundList.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                        return () => {
                                // if(audio) audio.unload()
                                // Howler.unload()
                                timeouts.forEach((timeout) =>{
                                        clearTimeout(timeout)
                                        // Howler.unload()
                                })
                                delaytime.forEach((timeout) => {
                                        clearTimeout(timeout);
                                })
                                gsap.killTweensOf("*")
                                // console.log("last return")
                        }
                }
        }
        if(stageState.dailogCounter === 14){
                if(stageState.disableThird){
                        speechboxdivRef.current.style.display = "block";
                        textListC = content2.texts;
                        soundListC = content2.sounds;
                        let textIndivDataC = [];
                        let timestamsThird = [];
                        let timeouts = [];
                        let speechCountThird = 13;
                        let delaytime = [];
                        let hideTime= [];
                        let showdata;
                        robotRef.current.style.transform = "translate(0%) rotateY(180deg)";
                        girlRef.current.style.transform = "rotateY(0deg)";
                        if( textListC?.length > 0){
                                textListC.forEach((item) => {
                                        textIndivDataC = [...textIndivDataC, ...item.textIndivData]
                                        // console.log(textIndivDataC)
                                })
                                textIndivDataC.forEach((data) =>{
                                        if(data.timestamps){
                                                timestamsThird = [...timestamsThird, ...data.timestamps];
                                        }else{
                                                timestamsThird = null
                                        }
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('sp-'+ (speechCountThird)+'_p5');
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCountThird === 14) {
                                                                                        robotRef.current.src = stageData.robotHandGif;
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                }
                                                                                if(speechCountThird === 15){
                                                                                        // girlRef.current.style.transform = "rotateY(0deg)";
                                                                                        girlRef.current.src = stageData.girlTalkGif;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                }
                                                                                if(speechCountThird === 16 || speechCountThird === 17) {
                                                                                        robotRef.current.src = stageData.robotHandGif;
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        daiRef.current.src = stageData.didiPng;
                                                                                }
                                                                                if(speechCountThird === 18){
                                                                                        // girlRef.current.style.transform = "rotateY(0deg)";
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        daiRef.current.src = stageData.daiTalking;
                                                                                        setStageState({...stageState, disableThird:false})
                                                                                }
                                                                                
                                                                        }      
                                                                });

                                                                let textAnimations2;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations2 = animationObj.runTextAnimations
                                                                        
                                                                        if(animationObj.runTextAnimations){
                                                                                console.log(speechCountThird)
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCountThird-1], timestamsThird)
                                                                                speechCountThird ++ ;
                                                                                console.log(speechCountThird)
                                                                        }
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations2 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations2 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('sp-'+ (speechCountThird-1)+'_p5');
                                                                // showdata[0].style.display = 'none';
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {
                                                                                // if(speechCountThird === 15) {
                                                                                //         girlRef.current.style.transform = "translate(0%, 0%) rotateY(180deg)"
                                                                                // }    
                                                                        }
                                                                })
                                                                if(speechCountThird === 18){
                                                                        dispatch({type: "LAST_PAGE_COMPLETE"})
                                                                        setStageState({...stageState, disableThird:false})
                                                                        console.log(state.rewardComplete)
                                                                        console.log(stageState.disable+" "+stageState.disableSec+" "+ stageState.disableThird)
                                                                }
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsThird = null;
                        }
                        if(soundListC && soundListC.length){
                                soundListC.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                }
                
        }
},[stageData.disable,stageData.disableSec, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData, speechboxdivRef.current]);

const answerSound = (isRight = false) => {
        new Howl({
                src: [isRight ? state.audios[1] : state.audios[0]],
                autoplay: true,
                loop: false,
                volume: 0.03,
        })
}
const answerClick = (e, index) => {
        const check = e.target.className.split(" ")[1];
        if(e.target.id === "rightAns"){
                answerSound(true)
                var parentDiv = e.target.parentNode;
                var previous = e.target.previousSibling;
                var next = e.target.nextSibling; 
                // var fstChild = e.target.firstChild;  
                //    console.log(e.target);
                if(previous){
                        // next.parentNode.removeChild(next);
                        previous.style.opacity = 0
                }
                if(parentDiv.firstElementChild){
                        // parentDiv.removeChild(parentDiv.lastChild);
                        parentDiv.firstChild.style.opacity = 0
                }
                
                e.target.style.backgroundColor = "#c1ebb3";
                gsap.to("#"+e.target.id,3,{
                        x:"-165%",
                        onComplete:() => {
                                setStageState({...stageState, dailogCounter:9, QnAnsShow:false, disableSec: true});
                        }
                })
        }
        else{
                e.target.style.backgroundColor = "#f4cccc";
                answerSound(false)
        }
        //    console.log(!parentDiv.firstChild)
}

const SecondanswerClick = (e, index) => {
        const check = e.target.className.split(" ")[2]; 
        if(check === "correct"){
                answerSound(true)
                var previous = e.target.previousSibling;
                var parentDiv = e.target.parentNode;
                var siblingDiv = parentDiv.nextSibling.id;
                // console.log(siblingDiv);
                if(previous) previous.style.opacity = 0
                if(parentDiv.firstElementChild) parentDiv.firstChild.style.opacity = 0
                e.target.style.backgroundColor = "#c1ebb3";
                gsap.to("."+check,3,{
                        x:"-132%",
                        onComplete:() => {
                                // check.pointerEvents = "none";
                        //      setStageState({...stageState, dailogCounter:9, QnAnsShow:false});
                        }
                })
                gsap.to('#'+siblingDiv, 3, {
                        delay: 3,
                        // display: "block",
                        css:{opacity:1},
                        onComplete: () => {
                                setStageState({...stageState, dailogCounter:14, secQnAnsShow:false});
                        }
                })
        }
        else{
                e.target.style.backgroundColor = "#f4cccc";
                answerSound(false)
        }
}

        return (
                <div className="imageContainer">
                {
                        // <OptionsSecond 
                        // assets={assets}
			// stageData={stageData}
                        // SecondanswerClick={SecondanswerClick}
                        // />
                        stageState.QnAnsShow === true ? (
			<Options
			answerClick={answerClick}
			assets={assets}
			stageData={stageData}
			/>
			):  
                        <div className="speechContainer" ref={speechboxdivRef}>
                        <img src={stageData.girlMoveGif} className="girl_walk_p5" ref={girlRef} 
                        />
                        <img src ={stageData.robotGif} className="robot_p5" ref={robotRef} />
                        <img src ={stageData.didiPng} className="didi_p5" ref={daiRef} />

                                {textList.length ? (
                                        textList.map((textContent, index) => {
                                                const textStyles = textContent.styles ?? {}
                                                // console.log(textStyles);
                                                const maindiv = textContent.textMainDivClass;
                                                const className = `${textContent.textDivClass} textContentDiv`
                                                return(
                                                        <div key={index} className={`speechbox_p5 ${maindiv}`} ref={ref => {speechboxRef.current[index] = ref;}}> 
                                                        <img src ={stageData.bubble} className="box" /> 
                                                        <div className={className} style ={ textStyles }>
                                                                {textContent.textIndivData.map((text, index) => (
                                                                        <p key={index} id="dailog" className={text.textClass} >
                                                                                {text.textData}
                                                                        </p>
                                                                ))}
                                                        </div>
                                                        </div>
                                                )
                                        })
                                ) : ( 
                                        <div></div>
                                )
                        }

                        </div>
                }
                {stageState.dailogCounter === 13 && (
                        <OptionsSecond 
                        assets={assets}
			stageData={stageData}
                        SecondanswerClick={SecondanswerClick}
                        />
                        )
                        }
                </div>        
        )
        
});

export default Scene;

