import { appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core"
import { seqAnimation } from "styledElements/helpers"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const close_eye = keyframes`
	0%, 89% {
		opacity: 0;
	}
	90%, 100% {
		opacity: 1;
	}
`
const open_eye = keyframes`
	0%, 89% {
		opacity: 1;
	}
	90%, 100% {
		opacity: 0;
	}
`
const content = {
	imageContainerClass: "bg-extended pos-1",
	imageContainerStyles: {
		animation: `${keyframes({
			from: {
				transform: "scale(1.5) translate(-6%, -9%)",
			},
			to: {
				transform: "scale(1) translate(0, 0)",
			},
		})} 3s ease-in-out 1.5s both, ${keyframes({
			from: {
				transform: "scale(1)",
			},
			to: {
				transform: "scale(1.3)",
			},
		})} 3s ease-in-out 17s forwards`,
	},
	images: [
		//scene 3
		{
			src: images("./bg_page72.png"),
			class: "image-one",
			styles: {
				animation: `${appear} 1s linear 8s both`,
			},
		},
		{
			src: images("./bg_page69.png"),
			class: "image-one",
			styles: {
				animation: `${appear} 1s linear 14s both, ${disappear} 1s linear 18.5s both`,
			},
		},
		{
			src: images("./egg_plate.png"),
			class: "image-one",
			imgdiv: "imageCover",
			styles: {
				width: "36%",
				top: "25%",
				left: "33%",
				animation: `${appear} 1s linear 10s both`,
			},
		},
		{
			src: images("./head.png"),
			class: "image-one",
			imgdiv: "imageCover",
			styles: {
				width: "30%",
				top: "63%",
				left: "37%",
				animation: `${appear} 1s linear 10s both, ${keyframes({
					from: {
						transform: "translateY(0)",
					},
					to: {
						transform: "translateY(90%)",
					},
				})} 3s linear 17s forwards`,
			},
		},

		//scene 2
		{
			src: images("./bg_page50.png"),
			class: "image-one",
			styles: {
				animation: `${appear} 1s linear 7s both, ${disappear} 1s linear 15.5s forwards`,
			},
		},

		{
			src: images("./grand_mum_friend.svg"),
			toSvg: true,

			styles: {
				top: "16%",
				left: "2%",
				width: "29%",
				transform: "scale(1)",

				animation: `${appear} 1s linear 7s both, ${disappear} 1s linear 15.5s forwards`,
				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 2s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 2s ease-out normal both infinite`,
					},
					...seqAnimation({
						idName: "lips",
						numberOfFrames: 11,
						totalDuration: 2.2,
						initialDelay: 9,
						repeat: 1,
						delays: [0.01, 0.2, 0.5, 0.8, 0.9, 1.1, 1.2, 1.5, 1.8, 2.0, 2.1],
					}),
				},
			},
		},
		{
			src: images("./grandmomlookAtFriend.svg"),
			toSvg: true,

			styles: {
				top: "19%",
				left: "27%",
				width: "30%",
				transform: "scale(1)",

				animation: `${appear} 1s linear 7s both, ${disappear} 1s linear 15.5s forwards`,
				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 3s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 3s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lips",
						numberOfFrames: 8,
						totalDuration: 1.6,
						initialDelay: 6,
						repeat: 1,
						delays: [0.01, 0.2, 0.5, 0.8, 0.9, 1.1, 1.2, 1.5],
					}),
				},
			},
		},
		{
			src: images("./backLady.svg"),
			toSvg: true,

			styles: {
				top: "20%",
				left: "-39%",
				width: "72%",
				animation: `${appear} 1s linear 7s both, ${disappear} 1s linear 15.5s forwards`,
				transform: "scale(1)",
			},
		},
		{
			src: images("./gifs/zeya_drawing_02.gif"),
			class: "image-one",
			imgdiv: "imageCover",
			styles: {
				width: "28%",
				top: "55%",
				left: "49%",
				animation: `${appear} 1s linear 7s both, ${disappear} 1s linear 15.5s forwards`,
			},
		},
		{
			src: images("./hand_and_egg.png"),
			class: "image-one",
			imgdiv: "imageCover",
			styles: {
				width: "50%",
				top: "72%",
				left: "100%",
				animation: `${appear} 1s linear 7s both, ${keyframes({
					from: {
						transform: "translate(0%, 0%)",
					},
					to: {
						transform: "translate(-97%, 3%)",
					},
				})} 5s ease-in-out 10s forwards, ${disappear} 1s ease-in-out 15.5s forwards`,
			},
		},
		//scene 1
		{
			src: images("./bg_page65_opt.png"),
			class: "image-one",
			styles: {
				animation: `${disappear} 2s ease-in-out 7s forwards`,
			},
		},
		{
			src: images("./hand_with_pencil.png"),
			class: "image-one",
			imgdiv: "imageCover",
			styles: {
				width: "44%",
				top: "38%",
				left: "60%",
				animation: `${disappear} 2s ease-in-out 7s forwards, ${keyframes({
					from: {
						transform: "rotate(360deg) translateX(6%) rotate(-360deg)",
					},
					to: {
						transform: "rotate(0deg) translateX(6%) rotate(0deg)",
					},
				})} 2s linear infinite`,
			},
		},
	],
	texts: [],
	sounds: [
		{
			url: audios("./bg/drawing.ogg"),
			volume: 0.03,
			delay: 1,
		},
		{
			url: audios("./bg/se_page8.ogg"),
			volume: 0.4,
			delay: 1,
		},
		{
			url: audios("./blank.ogg"),
			delay: 20000,
		},
	],
}

export default content
