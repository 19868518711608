const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./girl_cutting_paper.gif"),
			class: "image-8",
			imgdiv: "imageCover wallcolor",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "दाजुले मलाई कैँची दिए",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.5 }, { start: 2.2 }],
				},
				{
					textData: "कैँचीले मैले कागज काटेँ",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 3.0 }, { start: 3.7 }, { start: 4.4 }, { start: 5.1 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6.ogg"),
			delay: 500,
		},
	],
}

export default content
