const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./last_cover_page_ma_ko_hun.png"),
			class: "image-one",
			imgdiv: "imageCoverDiv",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 bhana-ma-ko-hun-page10 text-center",
			textIndivData: [
				{
					textData: "फलफूलहरूका बारेमा सिक्‍न रमाईलो भयो होला नि।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1 },
						{ start: 2 },
						{ start: 2.5 },
						{ start: 2.8 },
						{ start: 3.1 },
						{ start: 3.4, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 bhana-ma-ko-hun-page10 text-center",
			textIndivData: [
				{
					textData: "अहिलेको लागि बाई बाई !",
					textClass: "m-0",
					timestamps: [{ start: 9 }, { start: 9.6 }, { start: 10 }, { start: 10.5 }, { start: 10.7, last: true }],
					

				},
			],
		},
	],
	sounds: [
		{
			url: audios("./final1.ogg"),
			delay: 4000,
		},
		{
			url: audios("./final2.ogg"),
			delay: 13000,
		},
	],
}

export default content
