import { css, keyframes } from "@emotion/core"
import { appear } from "styledElements/keyframes"

const content = {
	images: [
		{
			src: "city/bg_page17.jpg",
			class: "man-p13",
			styles: {
				transform: "translate(50%, 50%) scale(2)",
				animation: `${keyframes({
					to: {
						transform: "translate(-50%, 50%) scale(2)",
					},
				})} 10s linear 4s forwards`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "birds/bird_fly05.gif",
			class: "bird1-p13",
			styles: {
				width: "20%",
				top: "36%",
				left: "-18%",
				transform: "translateX(0%) rotate(45deg)",
				animation: `
				
				${keyframes({
					to: {
						transform: "translateX(415%) rotate(45deg)",
					},
				})} 5s ease-in-out forwards,

				${keyframes({
					to: {
						transform: "translateX(610%) rotate(45deg)",
					},
				})} 2s 12s ease-in-out forwards
				
				`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "birds/bird_fly05.gif",
			class: "bird2-p13",
			styles: {
				width: "20%",
				top: "46%",
				left: "-30%",
				transform: "translateX(0%) rotate(45deg)",
				animation: `
				
				${keyframes({
					to: {
						transform: "translateX(415%) rotate(45deg)",
					},
				})} 5s ease-in-out forwards,

				${keyframes({
					to: {
						transform: "translateX(610%) rotate(45deg)",
					},
				})} 2s 12s ease-in-out forwards
				
				`,
			},
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text text-center",
			styles: css`
				top: 30%;
				left: 28%;
				padding: 15px;
				background: rgba(255, 255, 255, 0.8);
				border-radius: 15px;
				animation: ${appear} 0.5s 4s ease-in normal both;
				@media screen and (max-width: 1920px) {
					font-size: 40px;
				}
				@media screen and (max-width: 900px) {
					font-size: 35px;
					padding: 10px;
				}
				@media screen and (max-width: 760px) {
					font-size: 30px;
					padding: 10px;
				}
				@media screen and (max-width: 530px) {
					left: 7%;
					font-size: 25px;
				}
				@media screen and (max-width: 380px) {
					font-size: 20px;
				}
			`,

			textIndivData: [
				{
					textData: "अर्को बासको आशमा",
					textClass: "m-0 p13-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "घुम्दै उडे आकाशमा",
					textClass: "m-0 p13-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p13.ogg",
			delay: 4500,
		},
		{
			url: "blank.ogg",
			delay: 14000,
		},
		{
			url: "birdsChirping.ogg",
			delay: 0,
			volume: 1,
			loop: true,
			isBg: true,
		},
	],
}

export default content
