import React, { useContext, useState, useEffect } from "react"
// import { Droppable } from "@shopify/draggable";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import DeviceOrientation, { Orientation } from "react-screen-orientation"

import CustomModal from "../../components/LevelCompleteModal/CustomModal"
// import Modal from 'react-modal';

// assets
import "./stage.styles.scss"
import MobileImage from "./assets/mobile.png"
import waterDrop from "./assets/audio/waterdrop.ogg"

import { customIncludes } from "../../helpers/helpers"
import shuffle from "shuffle-array"
import { Howl } from "howler"
import gsap from "gsap"
import _ from "underscore"
import { StateContext } from "../../CrosswordPuzzle"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

function LetterList({ letters, isDragDisable }) {
	if (letters.length) {
		const onHintHover = _.debounce((letter) => {
			let sound = new Howl({
				src: [letter.audio],
			})
			if (!isDragDisable) {
				sound.play()
			}
		}, 300)
		return letters.map((letter, idx) => {
			if (letter.isPlaced) {
				return (
					<div
						key={`${letter.coordKey}`}
						className="dropzone optionPlaceholder active noShadow"
						id={`drag-placeholder-${letter.coordKey}`}
					></div>
				)
			} else {
				return (
					<div
						key={`${letter.coordKey}`}
						className="dropzone optionPlaceholder active noShadow"
						id={`drag-placeholder-${letter.coordKey}`}
					>
						<Draggable draggableId={`${letter.coordKey}`} key={`${letter.coordKey}`} index={idx} isDragDisabled={isDragDisable}>
							{(provided) => {
								return (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										className="dropzone optionDrag active noShadow"
										id={`drag-${letter.coordKey}`}
										onMouseEnter={() => onHintHover(letter)}
									>
										<div className="letterContainer">{`${letter.name}`}</div>
									</div>
								)
							}}
						</Draggable>
					</div>
				)
			}
		})
	} else {
		return null
	}
}

const Stage = (props) => {
	const state = useContext(StateContext)
	const hintModalImgRef = React.createRef(null)
	let isVerticalLayout = state.levelData.isVerticalLayout
	const levelWords = shuffle(state.levelData.words)
	const activeBoxes = []

	const [isDragDisable, setIsDragDisable] = useState(false)
	const [isDragging, setIsDragging] = useState(false)
	const [wordCompleteCount, setWordCompleteCount] = useState(0)

	levelWords.forEach((word, idx) => {
		activeBoxes.push(...word.boxes)
	})

	// const dispatch = useContext(DispatchContext);
	let allLetters = []
	let activeLetters = {}
	let hintsAndCell = {}
	let mapKeyToLetter = {}
	let wordList = {}
	let isHoverHintAudioPlaying = false

	levelWords.forEach((word) => {
		allLetters.push(...word.letters)

		let hintAndCell = word.hintAndCell
		for (let key in hintAndCell) {
			if (wordList[word.cName]) {
				wordList = {
					...wordList,
					[word.cName]: {
						isCompleted: false,
						isFirstTime: true,
						audio: word.audio,
						active_coord_key: [...wordList[word.cName].active_coord_key, key],
						imageName: word.cName,
						letters: word.letters,
					},
				}
			} else {
				wordList = {
					...wordList,
					[word.cName]: {
						isCompleted: false,
						isFirstTime: true,
						audio: word.audio,
						active_coord_key: [key],
						imageName: word.cName,
						letters: word.letters,
					},
				}
			}

			if (hintsAndCell[key]) {
				hintsAndCell = {
					...hintsAndCell,
					[key]: [...hintsAndCell[key], ...hintAndCell[key]],
				}
			} else {
				hintsAndCell = {
					...hintsAndCell,
					[key]: hintAndCell[key],
				}
			}
		}
	})

	allLetters.forEach((letter) => {
		activeLetters[`${letter.box[0]}-${letter.box[1]}`] = letter
		mapKeyToLetter[letter.coordKey] = letter.name
	})

	let allTheLetters = []
	Object.keys(activeLetters).forEach(function (letterCoord, idx) {
		allTheLetters.push(activeLetters[letterCoord])
	})
	allTheLetters = shuffle(allTheLetters)

	const [internalState, setState] = useState({
		gameCompleted: false,
		showModal: false,
		activeLetters: {},
		allTheLetters: allTheLetters,
		hintsAndCell: hintsAndCell,
		completeWordList: wordList,
	})

	let HintAudioPlayer
	let displayHint = (e) => {
		let cellId = e.target.id
		let hints = getHint(cellId)
		let highLightBoxList = []
		let completeWordList = internalState.completeWordList

		if (!isDragging && !isDragDisable) {
			HintAudioPlayer = function () {
				if (hints && hints.length === 1) {
					let hintSize = gsap.getProperty(`#${hints[0]}`, "scale")

					if (hintSize === 1) {
						if (isHoverHintAudioPlaying === false) {
							isHoverHintAudioPlaying = true
							let wordSound = new Howl({
								src: [completeWordList[hints[0]].audio],
							})
							wordSound.play()
							wordSound.on("end", function () {
								isHoverHintAudioPlaying = false
							})
						}
						gsap.ticker.remove(HintAudioPlayer)
					}
				}
			}
			gsap.ticker.add(HintAudioPlayer)
		}

		if (hints) {
			hints.forEach((hint) => {
				gsap.to(`#${hint}`, {
					opacity: 1,
					scale: 1,
				})
				highLightBoxList = [...highLightBoxList, ...completeWordList[hint].active_coord_key]
			})
		}
		highLightBoxList.forEach((boxId) => {
			let box = document.getElementById(boxId)
			gsap.to(box, { backgroundColor: "#FECA08" })
		})
	}

	let hideHint = (e) => {
		let cellId = e.target.id
		let hints = getHint(cellId)
		let highLightBoxList = []

		if (hints) {
			hints.forEach((hint) => {
				gsap.to(`#${hint}`, { opacity: 0, scale: 0 })
				highLightBoxList = [...highLightBoxList, ...internalState.completeWordList[hint].active_coord_key]
			})
		}
		highLightBoxList.forEach((boxId) => {
			let box = document.getElementById(boxId)
			gsap.to(box, { backgroundColor: "white" })
		})

		gsap.ticker.remove(HintAudioPlayer)
	}
	let getHint = (cellId) => {
		return internalState.hintsAndCell[cellId]
	}

	useEffect(() => {
		let completeWordList = internalState.completeWordList
		let masterLetterTl = new gsap.timeline()
		Object.keys(completeWordList).forEach((word) => {
			if (completeWordList[word].isCompleted && completeWordList[word].isFirstTime) {
				completeWordList[word].isFirstTime = false
				setIsDragDisable(true)

				gsap.to(".left", { backgroundColor: "rgba(232, 233, 231,0.7)", duration: 0.35 })
				gsap.to(".optionDrag", { backgroundColor: "rgba(106, 137, 124,0.7)", color: "rgba(255,255,255,0.7)", duration: 0.35 })
				masterLetterTl.add(animateWordCompleted(completeWordList[word]), ">0.5")
			}
		})

		if (internalState.gameCompleted && !internalState.showModal && wordCompleteCount === 10) {
			setTimeout(() => {
				setState({ ...internalState, showModal: true })
			}, 2500)
		}

		function animateWordCompleted(wordObj) {
			let completedWord = wordObj
			let letters = {}
			completedWord.letters.forEach((letter) => {
				letters = {
					...letters,
					[letter.coordKey]: [letter.audio],
				}
			})

			let wordSound = new Howl({
				src: [completedWord.audio],
			})

			let lettersTl = new gsap.timeline({
				onComplete: function () {
					wordSound.play()
				},
			})

			lettersTl.add(function () {
				//hide all images
				Object.keys(internalState.completeWordList).forEach((word) => {
					gsap.to(`#${internalState.completeWordList[word].imageName}`, { opacity: 0, scale: 0 })
				})
			})

			// Letter glow animation
			Object.keys(letters).forEach((coord) => {
				let letterDiv = document.getElementById(coord)
				lettersTl.to(letterDiv, {
					backgroundColor: "#9bff82",
					duration: 0.8,
				})
				lettersTl.add(function () {
					let letterSound = new Howl({
						src: letters[coord],
					})
					letterSound.play()
				}, "<")
				lettersTl.to(letterDiv, {
					backgroundColor: "#40d84d",
					duration: 0.8,
				})
			})

			let imageLeft = gsap.getProperty(`#${completedWord.imageName}`, "left", "%")
			let imageTop = gsap.getProperty(`#${completedWord.imageName}`, "top", "%")

			lettersTl.to(`#arrow-${completedWord.imageName}`, {
				opacity: 0,
				duration: 0.001,
			})
			lettersTl.to(`#${completedWord.imageName}`, {
				scale: 3,
				opacity: 1,
				left: "50%",
				top: "50%",
				xPercent: 50,
				yPercent: -50,
			})
			// lettersTl.add(function() {
			// 	gsap.set(`#${completedWord.imageName}`,{transform: 0});
			// });
			lettersTl.to(`#${completedWord.imageName}`, {
				scale: 1,
				opacity: 1,
				left: imageLeft,
				top: imageTop,
				xPercent: 0,
				yPercent: 0,
				delay: 1,
			})
			lettersTl.to(`#arrow-${completedWord.imageName}`, {
				opacity: 1,
				duration: 0.2,
			})
			lettersTl.add(function () {
				//show completed images
				Object.keys(internalState.completeWordList).forEach((hint) => {
					if (internalState.completeWordList[hint].isCompleted) {
						gsap.to(`#${hint}`, { opacity: 1, scale: 1 })
					}
				})
			})

			wordSound.on("end", function () {
				setIsDragDisable(false)
				setWordCompleteCount((prevState) => prevState + 1)

				gsap.to(".left", { backgroundColor: "#E8E9E7", duration: 0.35 })
				gsap.to(".optionDrag", { backgroundColor: "#6A897C", color: "#FFFFFF", duration: 0.35 })
			})

			return lettersTl
		}
	})
	let rows = []
	let columns
	for (let i = 0; i < state.levelData.contentHeight; i++) {
		columns = []
		for (let j = 0; j < state.levelData.contentWidth; j++) {
			let isActive = customIncludes(activeBoxes, [i, j])
			let activeLetter = internalState.activeLetters[`${i}-${j}`] ? internalState.activeLetters[`${i}-${j}`] : false

			let isFirstCoord = false
			let hintword = false
			levelWords.forEach((word) => {
				if (i + "-" + j === word.firstCoord) {
					isFirstCoord = true
					hintword = word
				}
			})
			columns.push(
				isActive && !internalState.activeLetters[`${i}-${j}`] ? (
					<Droppable droppableId={`${i}-${j}`} key={`${i}-${j}`}>
						{(provided, snapshot) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
								className={`dropzone active ${snapshot.isDraggingOver ? "dragOver" : ""}`}
								id={`${i}-${j}`}
								onMouseEnter={displayHint}
								onMouseLeave={hideHint}
							>
								{isFirstCoord && (
									<div
										className={`hint-image ${hintword.isAcross ? "across" : "down"}
												 
												${internalState.completeWordList[hintword.cName].isCompleted ? "" : "notCompleted-div"}`}
										id={hintword.cName}
									>
										<img
											alt="hint"
											className={`hint ${
												internalState.completeWordList[hintword.cName].isCompleted
													? "completed-img"
													: "notCompleted-img"
											}`}
											src={hintword.wordImage}
											id={`img-${hintword.cName}`}
										/>
										<img
											alt="arrow"
											src={hintword.arrowImage}
											className={hintword.arrowClass}
											id={`arrow-${hintword.cName}`}
										/>
									</div>
								)}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				) : (
					<div
						className={`dropzone 
						${isActive ? " correct active " : ""}`}
						key={`${i}-${j}`}
						id={`${i}-${j}`}
					>
						{isFirstCoord && (
							<div
								className={`hint-image ${hintword.isAcross ? "across" : "down"}
								
								${internalState.completeWordList[hintword.cName].isCompleted ? "" : "notCompleted-div"}`}
								id={hintword.cName}
							>
								<img alt="arrow" src={hintword.arrowImage} className={hintword.arrowClass} id={`arrow-${hintword.cName}`} />
								<img
									alt="hint"
									className={`hint ${
										internalState.completeWordList[hintword.cName].isCompleted ? "completed-img" : "notCompleted-img"
									}`}
									id={`img-${hintword.cName}`}
									src={hintword.wordImage}
								/>
							</div>
						)}
						{activeLetter && <span>{activeLetter.name}</span>}
					</div>
				)
			)
		}

		rows.push(
			<div className="row noFlexWrap" key={`row-${i}`}>
				{columns}
			</div>
		)
	}
	let onBeforeCapture = (result) => {
		gsap.set(`#drag-${result.draggableId}`, { rotation: 0 })
	}
	let onDragStart = (result) => {
		gsap.to(".left", { backgroundColor: "rgba(232, 233, 231,0.7)", duration: 0.35 })
		gsap.to(".optionDrag", { backgroundColor: "rgba(106, 137, 124,0.7)", color: "rgba(255,255,255,0.7)", duration: 0.35 })
		setIsDragging(true)
		gsap.to(`#drag-${result.draggableId}`, { backgroundColor: "rgb(106, 137, 124)", color: "#FFFFFF", duration: 0.35 })
	}
	let onDragEnd = (result) => {
		const { destination, source, draggableId } = result
		setIsDragging(false)
		gsap.to(".left", { backgroundColor: "rgb(232, 233, 231)", duration: 0.35 })
		gsap.to(".optionDrag", { backgroundColor: "rgb(106, 137, 124)", color: "#FFFFFF", duration: 0.35 })
		if (!destination) {
			return
		}

		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return
		}

		if (mapKeyToLetter[destination.droppableId] === mapKeyToLetter[draggableId]) {
			console.log("correct")
			let dropSound = new Howl({
				src: [waterDrop],
			})
			dropSound.play()

			let newCompleteWordList = internalState.completeWordList

			// Word complete check
			Object.keys(newCompleteWordList).forEach((key) => {
				newCompleteWordList[key].active_coord_key = newCompleteWordList[key].active_coord_key.filter(function (coord_key) {
					return coord_key !== destination.droppableId
				})
				newCompleteWordList[key].isCompleted = newCompleteWordList[key].active_coord_key.length === 0
			})

			if (source.droppableId === "dropzone-1") {
				let newArr = internalState.allTheLetters
				let allLetterArr = internalState.allTheLetters

				allLetterArr = allLetterArr.map((letter) => {
					if (letter.coordKey === destination.droppableId) {
						letter.isPlaced = true
						return letter
					} else {
						return letter
					}
				})
				let activeLetters = { ...internalState.activeLetters }
				activeLetters[destination.droppableId] = newArr.splice(source.index, 1)[0]

				setState({
					...internalState,
					activeLetters: activeLetters,
					gameCompleted: internalState.allTheLetters.length + 1 === Object.keys(activeLetters).length,
					allTheLetters: allLetterArr,
					completeWordList: newCompleteWordList,
				})
			}
			return
		}

		// const quotes = reorder(
		// 	state.quotes,
		// 	result.source.index,
		// 	result.destination.index
		// );

		// setState({ quotes });
	}

	return (
		<StateContext.Consumer>
			{(state) => {
				if (state === undefined) {
					throw new Error("StateConsumer must be used within a StateProvider")
				}
				return (
					<div
						className="game-stage_crossword"
						style={{
							backgroundImage: `url(${state.bg})`,
						}}
					>
						<DeviceOrientation lockOrientation={"landscape"}>
							<Orientation orientation="portrait" alwaysRender={false}>
								<div className="rotateMobilePrompt">
									<div className="rotateMessageBox">
										<p className="rotateText-p">Please rotate your device</p>
										<img className="rotateImg" src={MobileImage} alt="rotate your phone" />
									</div>
								</div>
							</Orientation>
						</DeviceOrientation>

						<DeviceOrientation lockOrientation={"landscape"}>
							<Orientation orientation="landscape" alwaysRender={false}>
								<DragDropContext onBeforeCapture={onBeforeCapture} onDragStart={onDragStart} onDragEnd={onDragEnd}>
									<div
										className={isVerticalLayout ? "dz-container " : "dz-container d-flex"}
										style={{
											outline: "none",
											// height: '100%',
											alignItems: "center",
										}}
									>
										{!isVerticalLayout && (
											<div
												className="container left"
												style={{
													margin: "3% 0 3% 3%",
												}}
											>
												<div
													className="hint-holder_crossword hint-holder-crossword_left"
													style={{ marginTop: "8%" }}
												>
													<Droppable droppableId={`dropzone-1`} direction="horizontal">
														{(provided) => (
															<div
																ref={provided.innerRef}
																{...provided.droppableProps}
																className="droppable-column"
															>
																<LetterList
																	letters={internalState.allTheLetters}
																	isDragDisable={isDragDisable}
																/>
																{provided.placeholder}
															</div>
														)}
													</Droppable>
												</div>
											</div>
										)}

										<div className="crossword-grid" style={isVerticalLayout ? { margin: "0", marginBottom: "2%" } : {}}>
											<div className="crossword">{rows}</div>
										</div>
										{isVerticalLayout && (
											<div
												className="container left"
												style={
													{
														// filter: `${isDragDisable?'brightness(0.8)':'brightness(1)'}`
													}
												}
											>
												<div
													className="hint-holder_crossword hint-holder-crossword_left"
													style={{ marginTop: "3%" }}
												>
													<Droppable droppableId={`dropzone-1`} direction="horizontal">
														{(provided) => (
															<div
																ref={provided.innerRef}
																{...provided.droppableProps}
																className="droppable-column"
															>
																<LetterList letters={internalState.allTheLetters} />
																{provided.placeholder}
															</div>
														)}
													</Droppable>
												</div>
											</div>
										)}
									</div>
								</DragDropContext>
								<CustomModal isOpen={internalState.showModal} ariaHideApp={false} isOpenInFlutter={props.isOpenInFlutter}/>
								<div className="hintImageModal">
									<img alt="" id="modal-image-puz" ref={hintModalImgRef} />
								</div>
							</Orientation>
						</DeviceOrientation>
					</div>
				)
			}}
		</StateContext.Consumer>
	)
}

Stage.whyDidYouRender = true
const StageMemo = React.memo(Stage)
StageMemo.whyDidYouRender = true

export default StageMemo
