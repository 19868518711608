import React from "react"

function PageNumberMobile(props) {
	const totalPage = props.totalPages
	const curPage = props.currentPage + 1

	return (
		<div
			className="text-center"
			style={{
				width: "61px",
				height: "auto",
				background: "#0e191f",
				color: "#fff",
				borderLeft: "1px solid #fff",
			}}
		>
			Page : <br /> {curPage} of {totalPage}
		</div>
	)
}

export default PageNumberMobile
