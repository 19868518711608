import React from "react"
import EachDrag from "./EachDrag"

class AllDragItems extends React.Component {
	render() {
		const { selectAudio, dragItems } = this.props

		return dragItems
			.filter((el) => el.name !== "")
			.map((eachDrag, index) => {
				return <EachDrag index={index} key={index} dragItems={eachDrag} id={`draggable-${index}`} selectAudio={selectAudio} />
			})
	}
}

export default AllDragItems
