const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page10_new.jpg"),
			class: "img-ten",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideleftbottom  text-center",
			textIndivData: [
				{
					textData: "अर्को दिन माइजूले बाँसकोे सानो घुम बनाइदिनुभयो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [
						{ start: 0.7 },
						{ start: 1.2 },
						{ start: 1.4 },
						{ start: 2 },
						{ start: 2.65 },
						{ start: 3.2 },
						{ start: 3.4 },
						{ start: 3.7 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s10.ogg"),
			delay: 2000,
		},
	],
}

export default content
