const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		// {
		//     src: images("./five_monkey.gif"),
		//     class: "image-five",
		//     imgdiv: "imageCover",
		// },
		{
			src: images("./bg03.png"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./two_monkey.png"),
			class: "image-five two_monkeyP6",
			imgdiv: "imageCover",
		},
		{
			src: images("./two_droppingdown01.png"),
			class: "image-five two_monkey_dropP6",
			imgdiv: "imageCover",
		},
		{
			src: images("./three_monkey_P4.png"),
			class: "image-five three_monkeyP6",
			imgdiv: "imageCover",
		},
		{
			src: images("./three_droppingdown.png"),
			class: "image-five three_monkey_dropP6",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideTopText text-center",
			textIndivData: [
				{
					textData: "पाँचओटा बाँदर मिली",
					textClass: "fz-5 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 2 }, { start: 2.8 }],
				},
				{
					textData: "फल्याक थुपारे।",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 3.9 }, { start: 5.1 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s5.ogg"),
			delay: 1200,
		},
	],
}

export default content
