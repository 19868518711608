const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page3scene",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./background_01.jpg"),
			class: "image-five page3",
			imgdiv: "imageCover",
		},
		{
			src: images("./page3_mum_kids.png"),
			class: "image-five mum",
			imgdiv: "imageCover",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rainnew",
			imgdiv: "imageCover",
		},
		{
			src: images("./thunder_storm.gif"),
			class: "image-one thunderp3a",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox2 text_box-p3",
			textIndivData: [
				{
					textData: " “पख! पख! म झासझुस लिएर आउँछु।” माउले भनी।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.4 },
						{ start: 0.8 },
						{ start: 1.3 },
						{ start: 1.9 },
						{ start: 2.3 },
						{ start: 2.8 },
						{ start: 4.2 },
						{ start: 4.6 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3.ogg"),
			delay: 3200,
		},
		{
			url: audios("./bg1.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
