import React, { useState } from "react"
import { ReactSVG } from "react-svg"
/**@jsx jsx */ import { jsx, css, keyframes } from "@emotion/core"
import _ from "underscore"
// image assets
import Crayon from "./crayon.svg"
import NotePad from "./note_pad.png"

const Phase3Event = ({ phaseData, currentEvent, eventIndex, NextEvent }) => {
	const activeEvent = currentEvent === eventIndex
	const [playCrayon, setPlayCrayon] = useState(false)
	const [showImage, setShowImage] = useState(false)
	const crayonGrowAnimation = () => {
		return activeEvent
			? css`
					cursor: pointer;
					animation: ${keyframes({
							from: {
								transform: "scale(1)",
							},
							to: {
								transform: "scale(1.3)",
							},
						})}
						1.5s ease-in-out infinite alternate;
			  `
			: ``
	}
	return (
		<React.Fragment>
			<div
				className="p3-ImageDiv"
				id={`noteBook-${eventIndex}`}
				css={css`
					height: 50%;
					margin-top: 20%;
				`}
			>
				<div
					css={css`
						width: 100%;
						height: 100%;
						background-image: url(${NotePad});
						background-size: contain;
						background-repeat: no-repeat;
						background-position: center;
						display: flex;
						flex-direction: column;
						align-items: center;
						padding-top: 10%;
					`}
				>
					<div
						css={css`
							max-width: 45%;
							max-height: 75%;
							flex: 4;
							display: flex;
							justify-content: center;
							align-items: center;
						`}
					>
						{playCrayon && (
							<img
								src={phaseData.crayonGif}
								alt="draw crayon"
								css={css`
									max-width: 100%;
									max-height: 100%;
									object-fit: contain;
									position: absolute;
									width: 46%;
								`}
							/>
						)}
						<img
							src={phaseData.image}
							alt="hint"
							id="DP3_Img"
							css={css`
								max-width: 100%;
								max-height: 100%;
								object-fit: contain;
								opacity: ${showImage ? 1 : 0};
							`}
						/>
					</div>
					<div
						css={css`
							flex: 1;
						`}
					>
						{(eventIndex < currentEvent || activeEvent) && (
							<h1
								css={css`
									font-weight: 800;
									color: ${phaseData.crayonColor.crayon};
								`}
								id="DP3_text"
							>
								{phaseData.word}
							</h1>
						)}
					</div>
				</div>
			</div>
			<ReactSVG
				src={Crayon}
				css={css`
					height: 20%;
					display: flex;
					justify-content: center;
					align-items: center;
					${crayonGrowAnimation()}
					> div {
						display: flex;
						justify-content: center;
						height: 100%;
					}
				`}
				loading={() => <span>Loading</span>}
				onClick={_.debounce(() => {
					if (activeEvent && !playCrayon) {
						setPlayCrayon(true)
						setTimeout(() => {
							setShowImage(true)
						}, 500)
						setTimeout(() => {
							setPlayCrayon(false)
							NextEvent()
						}, 2800)
					}
				}, 250)}
				beforeInjection={(svg) => {
					// Crayon
					svg.childNodes[1].setAttribute("style", `fill: ${phaseData.crayonColor.crayon}`)
					svg.childNodes[5].setAttribute("style", `fill: ${phaseData.crayonColor.crayon}`)
					svg.childNodes[7].setAttribute("style", `fill: ${phaseData.crayonColor.crayon}`)
					//Crayon Cover
					svg.childNodes[3].setAttribute("style", `fill: ${phaseData.crayonColor.cover}`)
					svg.classList.add("crayonClass")
				}}
			/>
		</React.Fragment>
	)
}

export default Phase3Event
