const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bunu_front_of_mirror.gif"),
			class: "image-13",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "“हे!  हे! चस्मा त टाउकोमै",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.4 }, { start: 0.4 }, { start: 1.2 }, { start: 1.75 }, { start: 1.95 }],
				},
				{
					textData: "पो रहेछ।” बुनु हाँसी।",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 2.7 }, { start: 2.85 }, { start: 3.9 }, { start: 4.25 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./13.ogg"),
			delay: 500,
		},
	],
}

export default content
