const assets = require.context("./assets")

const chapters = [
	{
		id: 1,
		storyKey: "page_1",
		girlMoveGif: assets("./slide1/samawalking.gif"),
		girlTalkGif: assets("./slide1/sama talking.gif"),
		girlLooking: assets("./slide1/sama looking.png"),
		robotGif: assets("./slide1/robot walkng.gif"),
		robotFallGif: assets("./slide1/robot fall.gif"),
		robotTalkGif: assets("./slide1/robot talking.gif"),
		robotLookPng: assets("./slide1/robot.png"),
		bubble: assets("./final/bubble.png"),
		bg: assets("./slide1/background.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
		disable: true,
	},
	{
		id: 2,
		storyKey: "page_2",
		frameHeight: "",
		girlMoveGif: assets("./slide1/samawalking.gif"),
		girlTalkGif: assets("./slide1/sama talking.gif"),
		girlLooking: assets("./slide1/sama looking.png"),
		robotGif: assets("./slide1/robot walking1.gif"),
		robotFallGif: assets("./slide1/robot fall.gif"),
		robotTalkGif: assets("./slide1/robottalking.gif"),
		robotLookPng: assets("./slide1/robot looking.png"),
		robotTopiPng: assets("./slide3/Robot still 1.gif"),
		robotTopiWalkingPng: assets("./slide1/rbwalking.gif"),
		robotTopiGif: assets("./slide1/robot hat talking.gif"),
		didiPng: assets("./slide2/didi looking.gif"),
		didiTalk: assets("./slide2/diditalking.gif"),
		didiGive: assets("./slide2/didigiving.gif"),
		bubble: assets("./final/3line.png"),
		bg: assets("./slide2/bg.png"),
		cycle: assets("./slide2/cycle.png"),
		optionImg: assets("./slide2/Polygon 1.png"),
		optionImg1: assets("./slide2/ball.png"),
		optionImg2: assets("./slide2/kite.png"),
		optionImg3: assets("./slide2/melon.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
		fstQnAudio: assets("./common/yo kun aakar hola.wav"),
		secHeadQn: assets("./common/trivuj aakr ko bastu.wav"),
		OptionOne: "त्रिभुज",
		OptionTwo: "वर्ग",
		OptionThree: "गोलाकार",
		rightAnswer: "त्रिभुज",
		disable: true,
	},
	{
		id: 3,
		storyKey: "page_3",
		frameHeight: "",
		girlMoveGif: assets("./slide1/samawalking.gif"),
		girlTalkGif: assets("./slide1/sama talking.gif"),
		girlLooking: assets("./slide1/sama looking.png"),
		robotGif: assets("./slide1/rbwalking.gif"),
		robotFallGif: assets("./slide1/robot fall.gif"),
		robotTalkGif: assets("./slide1/rb1talking.gif"),
		robotLookPng: assets("./slide1/robot looking.png"),
		robotTopiPng: assets("./slide3/Robot still 1.gif"),
		// robotTopiWalkingPng: assets("./slide1/rbwalking.gif"),
		robotTopiGif: assets("./slide1/robot hat talking.gif"),
		robotHandPng: assets("./slide1/rbstill.png"),
		robotHandGif: assets("./slide1/rbtalking.gif"),
		robotHandWalk: assets("./slide3/rbwalkk.gif"),
		daiPng: assets("./slide3/loking.gif"),
		daiTalking: assets("./slide3/talking.gif"),
		daiGivingHand: assets("./slide3/giving.gif"),
		bubble: assets("./final/3line.png"),
		bg: assets("./slide3/bg.png"),
		gada: assets("./slide3/gada 1.png"),
		gada2: assets("./slide3/gada2.png"),
		optionImg: assets("./slide2/Rectangle 8.png"),
		optionImg1: assets("./slide3/phone.png"),
		optionImg2: assets("./slide2/melon.png"),
		optionImg3: assets("./slide2/ball.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
		fstQnAudio: assets("./common/yo kun aakar hola.wav"),
		secHeadQn: assets("./common/aayat_aakar.wav"),
		OptionOne: "त्रिभुज",
		OptionTwo: "आयत",
		OptionThree: "चतुर्भुज",
		rightAnswer: "आयत",
		disable: true,
	},
	{
		id: 4,
		storyKey: "page_4",
		frameHeight: "",
		girlMoveGif: assets("./slide1/samawalking.gif"),
		girlTalkGif: assets("./slide1/sama talking.gif"),
		girlLooking: assets("./slide1/sama looking.png"),
		robotGif: assets("./slide4/walking.gif"),
		robotFallGif: assets("./slide4/fall.gif"),
		robotBupGif: assets("./slide4/up.gif"),
		// robotTalkGif: assets("./slide1/robottalking.gif"),
		robotLookPng: assets("./slide4/still.png"),
		robotFootPng: assets("./slide4/standfoot.png"),
		robotFootTalk: assets("./slide4/talkingfoot.gif"),
		// robotTopiGif: assets("./slide1/robot hat talking.gif"),
		robotHandGif: assets("./slide4/talking.gif"),
		daiPng: assets("./slide4/man looking.gif"),
		daiTalking: assets("./slide4/man talking.gif"),
		daiGivingHand: assets("./slide4/man giving.gif"),
		bubble: assets("./final/3line.png"),
		bg: assets("./slide4/bg.png"),
		plant: assets("./slide3/plant.png"),
		optionImg: assets("./slide3/Rectangle 7.png"),
		optionImg1: assets("./slide2/melon.png"),
		optionImg2: assets("./slide4/dice.png"),
		optionImg3: assets("./slide2/ball.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
		fstQnAudio: assets("./common/yo kun aakar hola.wav"),
		secHeadQn: assets("./common/warga aakar ma.wav"),
		OptionOne: "त्रिभुज",
		OptionTwo: "आयत",
		OptionThree: "वर्ग",
		rightAnswer: "वर्ग",
		disable: true,
	},
	{
		id: 5,
		storyKey: "page_5",
		frameHeight: "",
		girlMoveGif: assets("./slide1/samawalking.gif"),
		girlTalkGif: assets("./slide1/sama talking.gif"),
		girlLooking: assets("./slide1/sama looking.png"),
		robotGif: assets("./slide5/rbwalk.gif"),
		// robotFallGif: assets("./slide4/rbfall.gif"),
		// robotBupGif: assets("./slide4/rbup.gif"),
		// robotTalkGif: assets("./slide1/robottalking.gif"),
		robotLookPng: assets("./slide5/rblookk.png"),
		// robotFootPng: assets("./slide4/rblookingg.png"),
		// robotTopiWalkingPng: assets("./slide1/rbwalking.gif"),
		robotEyeGif: assets("./slide5/rb.png"),
		robotHandGif: assets("./slide5/rbtalkkk.gif"),
		didiPng: assets("./slide5/ddlooking.gif"),
		daiTalking: assets("./slide5/ddtalking.gif"),
		daiGivingHand: assets("./slide5/dd giving.gif"),
		bubble: assets("./final/3line.png"),
		bg: assets("./slide5/bg_5.png"),
		optionImg: assets("./slide5/Ellipse 5.png"),
		optionImg1: assets("./slide3/phone.png"),
		optionImg2: assets("./slide4/dice.png"),
		optionImg3: assets("./slide2/ball.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
		fstQnAudio: assets("./common/yo kun aakar hola.wav"),
		secHeadQn: assets("./common/britta aakar ma.wav"),
		OptionOne: "त्रिभुज",
		OptionTwo: "वर्ग",
		OptionThree: "वृत्ताआकार",
		rightAnswer: "वृत्ताआकार",
		disable: true,
	},
	{
		id: 6,
		storyKey: "page_6",
		frameHeight: "",
		girlMoveGif: assets("./slide1/samawalking.gif"),
		girlTalkGif: assets("./slide1/sama talking.gif"),
		girlLooking: assets("./slide1/sama looking.png"),
		robotGif: assets("./slide5/rbwalk.gif"),
		// robotFallGif: assets("./slide4/rbfall.gif"),
		// robotBupGif: assets("./slide4/rbup.gif"),
		// robotTalkGif: assets("./slide1/robottalking.gif"),
		robotLookPng: assets("./slide5/rblookk.png"),
		// robotFootPng: assets("./slide4/rblookingg.png"),
		// robotTopiWalkingPng: assets("./slide1/rbwalking.gif"),
		robotEyeGif: assets("./slide5/rb.png"),
		robotHandGif: assets("./slide5/rbtalkkk.gif"),
		didiPng: assets("./slide5/ddlooking.gif"),
		daiTalking: assets("./slide5/ddtalking.gif"),
		daiGivingHand: assets("./slide5/dd giving.gif"),
		bubble: assets("./final/3line.png"),
		bg: assets("./slide5/bg_5.png"),
		// optionImg: assets("./slide2/ball.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
		fstQnAudio: assets("./common/yo kun aakar hola.wav"),
		secHeadQn: assets("./common/britta aakar ma click garau.wav"),
		OptionOne: "त्रिभुज",
		OptionTwo: "वर्ग",
		OptionThree: "वृत्ताआकार",
		rightAnswer: "वृत्ताआकार",
		disable: true,
		optionImg:[
			{	
				itemName: "television",
				imgName: assets("./slide6/tv.png"),
				rightWrongImg: assets("./slide2/wrong.png")
			},
			{
				itemName: "ball",
				imgName: assets("./slide2/ball.png"),
				rightWrongImg: assets("./slide2/right.png")
			},
			{
				itemName: "hole",
				imgName: assets("./slide6/hut.png"),
				rightWrongImg: assets("./slide2/wrong.png")
			},
			{
				itemName: "clock",
				imgName: assets("./slide6/lock.png"),
				rightWrongImg: assets("./slide2/wrong.png")
			},
			{
				itemName: "dice",
				imgName: assets("./slide6/dicee.png"),
				rightWrongImg: assets("./slide2/wrong.png"),
			},
			{
				itemName: "vlc",
				imgName: assets("./slide6/cone.png"),
				rightWrongImg: assets("./slide2/wrong.png")
			},
			{
				itemName: "bottom",
				imgName: assets("./slide6/button.png"),
				rightWrongImg: assets("./slide2/right.png")
			},
			{
				itemName: "phone",
				imgName: assets("./slide3/phone.png"),
				rightWrongImg: assets("./slide2/wrong.png")
			}
		]
	},
	{
		id: 7,
		storyKey: "page_7",
		frameHeight: "",
		girlMoveGif: assets("./slide1/samawalking.gif"),
		girlTalkGif: assets("./slide1/sama talking.gif"),
		girlLooking: assets("./slide1/sama looking.png"),
		robotGif: assets("./slide5/rbwalk.gif"),
		// robotFallGif: assets("./slide4/rbfall.gif"),
		// robotBupGif: assets("./slide4/rbup.gif"),
		// robotTalkGif: assets("./slide1/robottalking.gif"),
		robotLookPng: assets("./slide5/rblookk.png"),
		// robotFootPng: assets("./slide4/rblookingg.png"),
		// robotTopiWalkingPng: assets("./slide1/rbwalking.gif"),
		robotEyeGif: assets("./slide5/rb.png"),
		robotHandGif: assets("./slide5/rbtalkkk.gif"),
		bubble: assets("./final/3line.png"),
		bg: assets("./slide1/background.png"),
		// optionImg: assets("./slide2/ball.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
		fstQnAudio: assets("./common/yo kun aakar hola.wav"),
		OptionOne: "त्रिभुज",
		OptionTwo: "वर्ग",
		OptionThree: "वृत्ताआकार",
		rightAnswer: "वृत्ताआकार",
		disable: true,
	},
	{
		id: 8,
		storyKey: "page_8",
		frameHeight: "",
		girlMoveGif: assets("./slide1/samawalking.gif"),
		girlTalkGif: assets("./slide1/sama talking.gif"),
		girlLooking: assets("./slide1/sama looking.png"),
		robotGif: assets("./slide5/rbwalk.gif"),
		robotColor: assets("./slide8/crb.png"),
		robotColorTalk: assets("./slide8/colorrbt_talk.gif"),
		// robotFallGif: assets("./slide4/rbfall.gif"),
		// robotBupGif: assets("./slide4/rbup.gif"),
		// robotTalkGif: assets("./slide1/robottalking.gif"),
		robotLookPng: assets("./slide5/rblookk.png"),
		// robotFootPng: assets("./slide4/rblookingg.png"),
		// robotTopiWalkingPng: assets("./slide1/rbwalking.gif"),
		robotEyeGif: assets("./slide5/rb.png"),
		robotHandGif: assets("./slide5/rbtalkkk.gif"),
		painterPng: assets("./slide8/painter painting.gif"),
		daiTalking: assets("./slide8/painter talking.gif"),
		daiGivingHand: assets("./slide8/painter looking.gif"),
		bubble: assets("./final/3line.png"),
		paint: assets("./slide8/paint.png"),
		bg: assets("./slide8/background_8.png"),
		optionImg: assets("./slide2/ball.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
		fstQnAudio: assets("./common/rang bharau.wav"),
		secHeadQn: assets("./common/thik thau ma rang bharau.wav"),
		OptionOne: "त्रिभुज",
		OptionTwo: "वर्ग",
		OptionThree: "वृत्ताआकार",
		rightAnswer: "वृत्ताआकार",
		disable: true,
		robotOpt: assets("./slide8/Robot.svg"),
		poll: assets("./slide8/1.png"),
		optionImg1:[
			{	
				itemName: "red",
				imgName: assets("./slide8/red.png"),
				rightWrongImg: assets("./slide2/wrong.png")
			},
			{
				itemName: "yellow",
				imgName: assets("./slide8/yellow.png"),
				rightWrongImg: assets("./slide2/right.png")
			},
			{
				itemName: "blue",
				imgName: assets("./slide8/blue.png"),
				rightWrongImg: assets("./slide2/wrong.png")
			},
			{
				itemName: "green",
				imgName: assets("./slide8/green.png"),
				rightWrongImg: assets("./slide2/wrong.png")
			},
		],
		SecoptionImg:[
			{
				itemName: "green",
				NepaliName: "हरियो",
				imgName: assets("./slide8/greenn.png"),
				rightWrongImg: assets("./slide2/wrong.png"),
				hand: assets("./slide8/Group 50.png"),
				colorThrow: "green_throw",
				styles:{
					top: "63%"

				}
			},
			{
				itemName: "blue",
				NepaliName: "निलो",
				imgName: assets("./slide8/bluee.png"),
				rightWrongImg: assets("./slide2/wrong.png"),
				hand: assets("./slide8/Group 64.png"),
				colorThrow: "blue_throw",
				styles:{
					top: "13%"

				}
			},
			{
				itemName: "yellow",
				NepaliName: "पहेंलो",
				imgName: assets("./slide8/yelloww.png"),
				rightWrongImg: assets("./slide2/right.png"),
				hand: assets("./slide8/Group 53.png"),
				colorThrow: "tellow_throw",
				styles:{
					top: "63%"

				}
			},
			{	
				itemName: "red",
				NepaliName: "रातो",
				imgName: assets("./slide8/redd.png"),
				rightWrongImg: assets("./slide2/wrong.png"),
				hand: assets("./slide8/Group 56.png"),
				colorThrow: "red_throw",
				styles:{
					top: "13%"

				}
			},
		]
	}
]

export default chapters
