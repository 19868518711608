import React from "react"

const BrickSVG = ({ height, width, className, isDemolished, idKey }) => {
	return (
		<>
			<svg
				className="Bsvg"
				xmlns="http://www.w3.org/2000/svg"
				xmlnsXlink="http://www.w3.org/1999/xlink"
				width={width}
				height={height}
				viewBox="0 0 302.137 172.421"
				enableBackground="new 0 0 302.137 172.422"
				xmlSpace="preserve"
			>
				{!isDemolished && (
					<path
						id={`brick_${idKey}`}
						fill="#FC9F60"
						className="brickSvg"
						stroke="#892F07"
						strokeWidth="3"
						strokeMiterlimit="10"
						d="M1.5,1.5v169.421h299.137V1.5H1.5z"
					/>
				)}
				<g>
					{isDemolished && (
						<path
							id={`brokenBrick_${idKey}`}
							fill="#FC9F60"
							stroke="#892F07"
							strokeWidth="3"
							strokeMiterlimit="10"
							d="M1.5,1.5v169.422h299.137V1.5H1.5z
							M274.203,166.956c0,0-135.255-8.897-136.056-8.897c-0.752,0-69.498,7.641-78.21,8.608c-0.589,0.063-0.91,0.1-0.91,0.1
							l-44.811-26.068l-0.097-0.057l-2.406-60.205l0.373-9.229l0.429-10.648c0,0-6.218-10.285-7.564-14.691
							c-0.257-0.833-0.359-1.479-0.187-1.78c0.952-1.687,20.358-29.663,24.574-35.741c0.518-0.74,0.819-1.177,0.819-1.177l1.328,0.017
							l100.78,1.309l35.552,10.602l0.302-0.098l41.396-13.533l1.308,0.165l72.198,9.112l5.612,23.854l0.271,48.846l1.335,34.267
							l7.217,20.825L274.203,166.956z"
						/>
					)}
				</g>
			</svg>
		</>
	)
}

export default BrickSVG
