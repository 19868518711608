import React from "react"
import Header from "Components/Layout/Header"
import DecodingGame from "Containers/Play/Decoding/DecodingGame"

const Decoding = () => {
	return (
		<div className="activity__container">
			<Header storyName="Decoding" isGame={true} />
			<DecodingGame />
		</div>
	)
}

export default Decoding
