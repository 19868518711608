import { keyframes } from "@emotion/core"

export const seqAnimation = ({ idName, numberOfFrames, delays, totalDuration, initialDelay, repeat }) => {
	initialDelay = initialDelay ?? 0
	repeat = repeat ?? 1
	let data = {}
	let allSelector = ""
	for (let index = 1; index <= numberOfFrames; index++) {
		allSelector += `#${idName}${index}`
		allSelector += index === numberOfFrames ? "" : ", "
	}

	data[allSelector] = {
		opacity: 0,
	}

	data[`#${idName}1`] = {
		opacity: "1",
		animation: `${keyframes({
			"0%, 100% ": {
				opacity: 1,
			},
			"1%,99%": {
				opacity: 0,
			},
		})} ${totalDuration}s ${initialDelay}s linear both running ${repeat}`,
	}

	for (let index = 2; index <= numberOfFrames; index++) {
		let delayPercent = Math.ceil((delays[index - 2] / totalDuration) * 100)
		let nextDelayPercent = index < numberOfFrames ? Math.ceil((delays[index - 1] / totalDuration) * 100) : 100
		let animObj = {}

		animObj[`0%, ${delayPercent - 1}%, ${nextDelayPercent}%, 100%`] = {
			opacity: 0,
		}
		animObj[`${delayPercent}%, ${nextDelayPercent - 1}%`] = {
			opacity: 1,
		}

		data[`#${idName}${index}`] = {
			opacity: 0,
			animation: `${keyframes(animObj)} ${totalDuration}s ${initialDelay}s  linear both running ${repeat}`,
		}
	}

	console.log(data)
	return data
}
