import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "रुखको रुप",
	key: "rukh-ko-rup",
	label: "medium",
	coverImage: images("./cover_banner.jpg"),
	credits: {
		writer: "रिन्छेन्ला लामा",
		illustrator: "अर्हन्त श्रेष्ठ",
		template: "default",
		bg: images("./cover_banner.jpg"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
