import React, { Component } from "react"
import { Link } from "react-router-dom"
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import ConfigContent from "EGRContent/Play/DragandDropPlay/Content"
import { getQueryVariable } from "../../mainHelper"
import { getLevelData } from "../helper/helpers"
import IconHome from "assets/icons/home.svg"
import IconMenulist from "assets/icons/back_to_list.svg"
import Logo from "assets/ole_logo.png"
import LastPage from "../../LastPage"
import NextBtn from "../../../../Components/NextBtn"
import PrevBtn from "../../../../Components/PrevBtn"
import { Howl, Howler } from "howler"
import PageNumber from "Components/PageNumber"
import AllDragItems from "./AllDragItems"
import AllDropItems from "./AllDropItems"
import DragDropIncentive from "./DragDropIncentive"
import jingleSound from "../../../../EGRContent/Play/DragandDropPlay/audios/soundeffect/jingle-win.ogg"

const shuffle = require("shuffle-array")

export class DragAndDropGame extends Component {
	state = {
		// gameContent: shuffle(ConfigContent.contentList),
		level: getQueryVariable("level") || "Easy",
		levelData: [],
		currentPage: 0,
		showNextBtn: false,
		showPrevBtn: false,
		initPlayed: false,
		dropCount: 0,
		isLast: false,
		showIncentive: false,
	}

	componentDidMount() {
		let LevelContent = shuffle(getLevelData(this.state.level))

		this.setState(
			{
				totalPage: LevelContent.length,
				answerCount: LevelContent[this.state.currentPage].dropItems.length,
				// audioUrl: ConfigContent.instructionurl,
				showIncentive: false,
				levelData: LevelContent,
			},
			() => {
				this.randomizeDragItems()
				this.handlePlayAudio()
			}
		)
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.currentPage !== this.state.totalPage) {
			let soundurl = this.state.levelData[this.state.currentPage].correctAnsAudio

			if (!this.state.initPlayed) {
				if (soundurl) {
					let hintSound = new Howl({
						src: [soundurl],
						autoplay: true,
						volume: 1,
						onend: () => {
							this.setState({
								initPlayed: true,
							})
						},
					})
					hintSound.play()
				}
			}

			if (prevState.currentPage !== this.state.currentPage) {
				if (soundurl) {
					let hintSound = new Howl({
						src: [soundurl],
						autoplay: true,
						volume: 1,
					})
					hintSound.play()
				}
				if (this.state.currentPage < this.state.totalPage) {
					const answerCount = this.state.levelData[this.state.currentPage].dropItems.length
					this.setState(
						{
							answerCount: answerCount,
							showIncentive: false,
						},
						() => {
							this.randomizeDragItems()
						}
					)
				}
			}
		}

		if (prevState.level !== this.state.level) {
			let LevelContent = shuffle(getLevelData(this.state.level))
			this.setState(
				{
					totalPage: LevelContent.length,
					answerCount: LevelContent[this.state.currentPage].dropItems.length,
					showIncentive: false,
					levelData: LevelContent,
				},
				() => {
					this.randomizeDragItems()
					this.handlePlayAudio()
				}
			)
		}
	}

	randomizeDragItems = () => {
		let newGameContent = JSON.parse(JSON.stringify(this.state.levelData))
		let shuffledDragItems = JSON.parse(JSON.stringify(this.state.levelData[this.state.currentPage].dragItems))
		shuffle(shuffledDragItems)
		newGameContent[this.state.currentPage].dragItems = shuffledDragItems
		this.setState({ levelData: newGameContent })
	}

	onNextBtnClick = () => {
		Howler.unload()
		this.setState((prevState) => ({
			dropCount: 0,
			currentPage: prevState.currentPage + 1,
			showNextBtn: false,
			showPrevBtn: true,
			isLast: prevState.currentPage + 1 === prevState.totalPage ? true : false,
		}))
	}

	resetDragAndDropItems = (levelContent, page) => {
		let dragItems = levelContent[page].dragItems
		let dropItems = levelContent[page].dropItems

		for (let i = 0; i < dragItems.length; i++) {
			if (dragItems[i].name === "") {
				for (let j = 0; j < dropItems.length; j++) {
					if (dragItems[i].match === dropItems[j].match) {
						dragItems[i].name = dropItems[j].name
						dropItems[j].name = ""
						dropItems[j].enable = ""
						break
					}
				}
			}
		}

		levelContent[page].dragItems = dragItems
		levelContent[page].dropItems = dropItems

		return levelContent
	}

	onPrevBtnClick = () => {
		const { levelData, currentPage } = this.state
		let newGameContent = JSON.parse(JSON.stringify(levelData))

		newGameContent = this.resetDragAndDropItems(newGameContent, currentPage)

		let prevPage = currentPage - 1

		newGameContent = this.resetDragAndDropItems(newGameContent, prevPage)

		Howler.unload()
		this.setState((prevState) => ({
			currentPage: prevState.currentPage - 1,
			// gameContent: ConfigContent.contentList,
			levelData: newGameContent,
			showNextBtn: false,
			showPrevBtn: true,
			dropCount: 0,
		}))
	}
	selectAudio = (audioUrl) => {
		this.setState(
			{
				audioUrl: audioUrl,
			},
			() => {
				this.handlePlayAudio()
			}
		)
	}

	handlePlayAudio = () => {
		Howler.unload()
		const { audioUrl } = this.state
		let sound = new Howl({
			src: [audioUrl],
			autoplay: true,
		})
		sound.play()
	}

	openMenuModal = () => {
		this.setState((prevState) => ({ menuOpened: !prevState.menuOpened }))
	}

	resetGame = () => {
		console.log(this.state)
		let LevelContent = shuffle(getLevelData(this.state.level))

		this.setState({
			currentPage: 0,
			dropCount: 0,
			levelData: LevelContent,
		})
	}

	onDragEnd = (result) => {
		const { levelData, currentPage, answerCount } = this.state
		const { destination, source } = result

		if (!destination) {
			return
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return
		}
		if (destination.droppableId !== source.droppableId) {
			const newLevelData = JSON.parse(JSON.stringify(levelData))

			const newDragItems = newLevelData[currentPage].dragItems
			const newDropItems = newLevelData[currentPage].dropItems
			const draggedItemMatch = newDragItems[source.index].match
			const dropItemMatch = newDropItems[destination.droppableId].match

			if (draggedItemMatch === dropItemMatch) {
				let dropped = newDragItems.splice(source.index, 1)

				newDropItems[destination.droppableId].name = dropped[0].name
				newDropItems[destination.droppableId].enable = "disable"

				dropped[0].name = ""
				newDragItems.push(dropped[0])
				this.setState(
					(prevState) => ({
						dropCount: prevState.dropCount + 1,
						levelData: newLevelData,
					}),
					() => {
						if (answerCount === this.state.dropCount) {
							this.setState({
								showNextBtn: true,
								showIncentive: true,
								isDragging: false,
							})

							let jinglePlayer = new Howl({
								src: [jingleSound],
								autoplay: false,
								loop: false,
							})
							jinglePlayer.play()
							let soundurl = this.state.levelData[currentPage].correctAnsAudio
							let hintSound = new Howl({
								src: [soundurl],
								autoplay: false,
								volume: 1,
							})
							jinglePlayer.on("end", function () {
								hintSound.play()
							})
						}
					}
				)
			}
		}
		if (destination.droppableId === source.droppableId) {
			const newLevelData = Array.from(levelData)
			const newDragItems = newLevelData[currentPage].dragItems
			const draggedItem = newDragItems[source.index]
			newDragItems.splice(source.index, 1)
			newDragItems.splice(destination.index, 0, draggedItem)
			this.setState({
				levelData: newLevelData,
				isDragging: false,
			})
		}
	}

	render() {
		const { levelData, currentPage, totalPage } = this.state
		const game = ConfigContent
		console.log("Call from flutter flag: "+this.props.isOpenInFlutter);
		return (
			<div className="activity__container">
				<header className="inner__header">
					<div className="container">
						<div className="header-content">
							<div className="logo">
								{!this.props.isOpenInFlutter && (<Link to="/">
									<img src={Logo} className="img-fluid" alt="OLE Nepal" />
								</Link>)}
							</div>
							{!this.props.isOpenInFlutter && (<div className="menuIcon">
								<Link to="/play">
									<img src={IconMenulist} className="img-fluids" alt="Home" />
								</Link>
							</div>)}
							<strong className="page__heading">{this.props.activityName}</strong>
							<div className="menu-right">
								{!this.props.isOpenInFlutter && (<a href="/" className="menu-item">
									<img src={IconHome} className="img-fluids" alt="Home" />
								</a>)}
							</div>
						</div>
					</div>
				</header>
				<main className="main__content">
					<div className="draganddropplay-container">
						<div id="activityBox" className="activity__frame">
							{currentPage < totalPage ? (
								<div className={"activity_wrapDnD " + levelData[currentPage].background}>
									<div className="gamecontent draganddrop">
										<div key={currentPage + "div"} className={game.textDivClass}>
											{game.textIndivData.map((text, index) => (
												<p key={"p" + index} className={text.textClass}>
													{text.textData}
												</p>
											))}
										</div>

										<DragDropContext onDragEnd={this.onDragEnd}>
											<Droppable droppableId="dragBox" direction="horizontal">
												{(provided) => (
													<div {...provided.droppableProps} ref={provided.innerRef}>
														<div className={levelData[currentPage].dragClass}>
															<AllDragItems
																dragItems={levelData[currentPage].dragItems}
																selectAudio={this.selectAudio}
															/>
															{provided.placeholder}
														</div>
													</div>
												)}
											</Droppable>

											<div className="row align-items-center dropbox">
												<div className="col-6">
													<div className={levelData[currentPage].dropClass}>
														<AllDropItems levelData={levelData} currentPage={currentPage} />
													</div>
												</div>
											</div>
										</DragDropContext>

										{levelData[currentPage].incentiveImage.map((images, index) => (
											<React.Fragment key={currentPage + "_" + index}>
												<DragDropIncentive
													key={index}
													src={this.state.showIncentive ? images.incentivesrc : images.src}
													class={images.class}
													imgdivClass={images.imgdiv}
													currentPage={currentPage}
													index={index}
													text={this.state.showIncentive ? images.text : ""}
													activityKey={this.state.activityKey}
													onClickImage={() => this.selectAudio(images.url)}
												/>
												<span key={currentPage + images.text + index} className={images.textClass}>
													{this.state.showIncentive ? images.text : ""}
												</span>
											</React.Fragment>
										))}
									</div>
								</div>
							) : (
								<LastPage resetGame={this.resetGame} openMenuModal={this.openMenuModal} isOpenInFlutter={this.props.isCallFromFlutter} />
							)}
							<div className="gameactivity__nav">
								<div className="row align-items-center">
									<div className="col-6">
										<PageNumber totalPages={this.state.totalPage} currentPage={this.state.currentPage} />
									</div>
									<div className="col-6 text-right">
										<PrevBtn
											disabled={this.state.currentPage === 0}
											prevBtnClick={() => this.onPrevBtnClick(this.state.totalPage)}
										/>

										{/* <NextBtn disabled={false} nextBtnClick={() => this.onNextBtnClick(this.state.totalPage)} /> */}
										<NextBtn
											disabled={!this.state.showNextBtn}
											nextBtnClick={() => this.onNextBtnClick(this.state.totalPage)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		)
	}
}

export default DragAndDropGame
