const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_green.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./cover_page_new01.gif"),
			class: "image-one coverpage1",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center",
			textIndivData: [
				{
					textData: "काग",
					textClass: "page1a",
				},
				{
					textData: "र",
					textClass: "page1b",
				},
				{
					textData: "रोटी",
					textClass: "page1c",
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./1.ogg"),
			delay: 500,
		},
	],
}

export default content
