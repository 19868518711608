import React, { useEffect } from "react"

import PrevBtn from "Components/PrevBtn"
import PageNumber from "Components/PageNumber"

import "./toolbar.styles.scss"

import { StateContext, DispatchContext } from "../../MatchTheWordGame"

import NextIcon from "assets/icons/next.svg"
import gsap from "gsap"

export const NextBtn = (props) => {
	const nxtBtn = React.useRef(null)

	React.useEffect(() => {
		if (!props.disabled) {
			nxtBtn.current.className += " success"
			gsap.fromTo(
				nxtBtn.current,
				{
					scale: 1,
				},
				{
					scale: 1.2,
					duration: 0.5,
					yoyo: true,
					ease: "power1.in",
					repeat: -1,
				}
			)
		}
	})

	return props.disabled ? (
		<span ref={nxtBtn} key="nxtBtn" className="next__btn disabled">
			<img className="img-fluid" src={NextIcon} alt="next" />
		</span>
	) : (
		<span ref={nxtBtn} key="nxtBtn" onClick={props.nextBtnClick} className="next__btn">
			<img className="img-fluid" src={NextIcon} alt="next" />
		</span>
	)
}

const Toolbar = ({ totalPage, currentPage }) => {
	const state = React.useContext(StateContext)
	const dispatch = React.useContext(DispatchContext)

	//----------------------- NEXT ROUND DEDUGGING Helper -------------------------
	var map = { 16: false, 39: false } //16: shift , 39: right arrow   <= keyCodes

	useEffect(() => {
		const keyDown = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = true
				if (map[16] && map[39]) {
					// FIRE EVENT
					dispatch({ type: "NEXT_STAGE" })
				}
			}
		}
		window.addEventListener("keydown", keyDown)

		const keyUp = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = false
			}
		}
		window.addEventListener("keyup", keyUp)
		return () => {
			window.removeEventListener("keydown", keyDown)
			window.removeEventListener("keyup", keyUp)
		}
	})

	// -----------------------END NEXT ROUND -------------------------------

	return (
		<div className="gameactivity__nav ">
			<div className="container">
				<div className="row align-items-center ">
					<div className="col-6">
						<PageNumber totalPages={totalPage} currentPage={currentPage} />
					</div>
					<div className="col-6 text-right">
						<PrevBtn disabled={state.stage === 0} prevBtnClick={() => dispatch({ type: "PREV_STAGE" })} />
						<NextBtn disabled={!state.rewardComplete} nextBtnClick={() => dispatch({ type: "NEXT_STAGE" })} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Toolbar
