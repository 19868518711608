import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "अचम्मका साथी",
	key: "achamako-sathi",
	coverImage: images("./bg.jpg"),
	credits: {
		writer: "टिकाराम शर्मा पौडेल",
		illustrator: "अनुश्री लामा, नरेन्द्र अर्याल",
		template: "default",
		bg: images("./bg.jpg"),
		titleAudio: audios("./0.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
