const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background7.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./ballon.png"),
			class: "image-one imageCenter element1 imgSize imgballon",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./section.png"),
			class: "image-one imageCenter element2 imgSize imgsection",
			imgdiv: "imageCenterDiv _mt-6",
		},

		{
			src: images("./melon_un.png"),
			class: "image-one imageCenter element3 imgSize imgmelon_un",
			imgdiv: "imageCenterDiv _h-75",
		},
		{
			src: images("./watermelon.gif"),
			class: "image-one imageCenter element4 imgSize imgwatermelon",
			imgdiv: "imageCenterDiv _h-75",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मेरो आकार बेलुन जस्तै छ।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.4 }, { start: 0.8 }, { start: 1.4 }, { start: 1.9, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म भित्रबाट रातो र रसिलो छु।",
					textClass: "m-0",
					timestamps: [
						{ start: 9 },
						{ start: 9.3 },
						{ start: 10.3 },
						{ start: 11.04 },
						{ start: 11.56 },
						{ start: 12, last: true },
					],
				},
			],
		},

		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 20 },
						{ start: 20.2 },
						{ start: 20.5 },
						{ start: 21.1 },
						{ start: 21.4 },
						{ start: 21.5 },
						{ start: 21.5, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म तर्बुजा हुँ।",
					textClass: "m-0",
					timestamps: [{ start: 31 }, { start: 31.4 }, { start: 31.9, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p7a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p7a2.wav"),
			delay: 13000,
		},
		{
			url: audios("./p7a3.wav"),
			delay: 24000,
		},
		{
			url: audios("./p7a4.wav"),
			delay: 35000,
		},
	],
}

export default content
