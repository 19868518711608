const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./dost_chatting.gif"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./tokanu.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./toknu.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./haat.png"),
			class: "image-one hat",
			imgdiv: "imageCover",
		},
		{
			src: images("./hat.png"),
			class: "image-one hat",
			imgdiv: "imageCover",
		},
		{
			src: images("./sing.png"),
			class: "image-one gaau",
			imgdiv: "imageCover",
		},
		{
			src: images("./village_l.png"),
			class: "image-one gaau",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "तोक्नु भन्दा टोक्नु सुन्‍ने,",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 0 }, { start: 1.0 }, { start: 2 }, { start: 2.6 }],
				},
				{
					textData: "हात भन्दा हाट",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 3.6 }, { start: 4.6 }, { start: 5.5 }],
				},
				{
					textData: "गीत गाऊ भन्यो भने",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 7.7 }, { start: 8.6 }, { start: 9.3 }, { start: 10.2 }],
				},
				{
					textData: "गाउँ जाने बात",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 11.2 }, { start: 12.2 }, { start: 13 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3.ogg"),
			delay: 500,
		},
	],
}

export default content
