import React, { useContext, useEffect } from "react"
import { Howl, Howler } from "howler"	
import { StateContext } from "../../ShapeColorGame"
import "./options.styles.scss"
import { sleep } from "../../utils"
import yo_awaj_kasko_hola from "../../content/chapters/assets/common/female.mp3"
// import gsap from "gsap"

// gsap.ticker.lagSmoothing(false)

const Options = ({ stageData,answerClick, assets }) => {
	const state = useContext(StateContext)
	let animalRef = React.useRef(null)
	let fstOptRef = React.useRef(null)
	let secOptRef = React.useRef(null)
	const audio = stageData.fstQnAudio;
	let thirdOptRef = React.useRef(null)
	let FstQuestionAdio;
		// let questionerRef = React.useRef(null)
	// let otherAnimalsRef = React.useRef(null)
	// var timelap = gsap.timeline({})
	let initial = {
		// img: stageData.id !== 10 && stageData.animals[stageData.rightAnswer].image,
		animalWalked: false,
		bubble: false,
		letsWalkTogether: false,
		questionerRequest: false,
		resizeGif: false,
		finalStageQ: stageData.id === 10 && stageData.questionerTalk,
		replace: false,
	}

	// const dispatch = useContext(DispatchContext)
	// const [optionState, setOptionState] = useState(initial)
	// let FstQuestionAdio = new Howl({
	// 	src: [stageData.fstQnAudio],
	// 	autoplay: true,
	// 	loop: false,
	// 	volume: 1,
	// 	preload:true,
	// 	onend: ()=>{
	// 		console.log("done");
	// 	}
	// })
	// FstQuestionAdio.play();
	// let FstQuestionAdio = new Howl({
	// 	src: [stageData.fstQnAudio],
	// 	autoplay: true,
	// 	loop: false,
	// 	onend: ()=>{
	// 		console.log("done");
	// 	}
	// })
	// FstQuestionAdio.play();

	useEffect(() => {
		// FstQuestionAdio.play();
		if(stageData.id === 2){
			fstOptRef.current.id = "rightAns";
		}
		if(stageData.id === 3){
			secOptRef.current.id = "rightAns";
		}
		if(stageData.id === 4){
			thirdOptRef.current.id = "rightAns";
		}
		if(stageData.id === 5){
			thirdOptRef.current.id = "rightAns";
		}
		if (state.playing) {
			console.log(state.playing, ">>>>", audio)
			;(async function () {
				try {
					// console.log()
					FstQuestionAdio = new Howl({
						src: [audio],
						autoplay: true,
						loop: false,
						onend: ()=>{
							console.log("done");
						}
					})
					// FstQuestionAdio.play();
					console.log(stageData.fstQnAudio);
					const word = ["यो", "कुन", "आकार", "होला", " ?"]
					let inputText = document.getElementById("whose-voice")
					let innerHTML = inputText.innerHTML
					let actualWord = "यो कुन आकार होला ?"
					for (let i = 0; i < word.length + 1; i++) {
						if (i >= word.length) {
							inputText.innerHTML = `<span class="white">${actualWord}</span>`
						} 
						else {
							let index = actualWord.indexOf(`${word[i]}`)
							// console.log(index)
							
							if (index >= 0) {
								innerHTML =
									"<span class='hightlight'>" +
									actualWord.substring(0, index) +
									actualWord.substring(index, index + word[i].length) +
									"</span>" +
									actualWord.substring(index + word[i].length)
								// console.log(innerHTML)
								inputText.innerHTML = innerHTML
							}
						}
						await sleep(400)
					}
				} catch {}
			})()
		}
		return() => {
			if(FstQuestionAdio) FstQuestionAdio.unload()
			Howler.unload()
			// console.log("play")
		}
	}, [state.playing, stageData.id, stageData.fstQnAudio])

	return (
		<div className="game-options w-100" style={{ height: `${stageData.frameHeight ? stageData.frameHeight : "100%"}` }}>
			<div
				className="options-list h-100"
				style={{ width: stageData.animalContainerWidth ? stageData.animalContainerWidth : "100%" }}
				ref={animalRef}
			>
				<div className="opt-game-title" id="whose-voice">
						{/* यो कुन आकार होला ? */}
				</div>
				<div className="optionImg_div_o1">
					<img src={stageData.optionImg}  className="opt_img1" alt="options_images" />
				</div>
				<div className="option_div">
					<div className="option_txt option_fst correct" onClick={(e) => answerClick(e)} ref={fstOptRef}>
						<p>{stageData.OptionOne}</p>
					</div>
					<div className="option_txt option_sec wrong" onClick={(e) => answerClick(e)} ref={secOptRef}>
						<p>{stageData.OptionTwo}</p>
					</div>
					<div className="option_txt option_third wrong" onClick={(e) => answerClick(e)} ref={thirdOptRef}>
						<p>{stageData.OptionThree}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Options
