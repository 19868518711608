import { appear, disappear, transformTo, transformFromTo } from "styledElements/keyframes"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended",
	imageContainerStyles: {
		background: "#ffe21a",
		transformOrigin: "50.5% 91%",
		animation: `${transformTo("scale(3.3)")} 4s 10s ease-in-out normal forwards,
		${transformTo("scale(1)")} 3s 10s ease-in-out normal forwards,
		${transformTo("scale(3.3)")} 4s 18s ease-in-out normal forwards,
		${transformTo("scale(1)")} 5ms 23s ease-in-out normal forwards,


		`,
	},
	images: [
		{
			src: images("./bg_page93.png"),
			styles: {
				opacity: "0",
				animation: `${appear} 2s 18s forwards linear`,
			},
		},
		{
			src: images("./bg_100.png"),
			styles: {
				opacity: "0",
				animation: `${appear} 2s 25s forwards linear`,
			},
		},
		{
			src: images("./zeya_page93.svg"),
			toSvg: true,
			styles: {
				overflow: "visible",
				opacity: "0",
				top: "15%",
				left: "35%",
				width: "33%",
				animation: `${appear} 2s 18s forwards linear, ${disappear} 1s 25s forwards linear`,
				svg: {
					overflow: "visible",
				},
				"#head": {
					transformOrigin: "40% 60%",

					animation: `${transformFromTo(["rotate(-3.5deg)", "rotate(3.5deg)"])} 1s 6s ease-in-out alternate infinite`,
				},

				"#open_eyes": {
					animation: `${disappear} 0.02s 10s forwards`,
				},
				"#closed_eyes": {
					opacity: 0,
					animation: `${appear} 0.02s 10s forwards`,
				},
			},
		},
		{
			src: images("./bg_page89.png"),
			styles: {
				animation: `${disappear} 2s 10s forwards linear`,
			},
		},
		{
			src: images("./hand_89.png"),
			styles: {
				top: "70%",
				left: "67%",
				width: "23%",
				transform: 'translate(50%,110%)',
				animation: `
				${transformTo("translate(20%, -68%)")} ease-in-out 2s normal 3s forwards,
				${transformTo("translate(20%, -50%)")} ease-in-out 1s 5s normal forwards,
				${transformTo("translate(20%, -68%)")} ease-in-out 1s 6s normal forwards, 
				${transformTo("translate(20%, -50%)")} ease-in-out 1s 7s normal forwards,
				${transformTo("translate(20%, -68%)")} ease-in-out 1s 8s normal forwards, 
				${transformTo("translate(50%,110%)")} ease-in-out 2s 9s normal forwards, 
				${disappear} 2s 10s forwards linear
				`,
			},
		},
	],
	texts: [],
	sounds: [
		{
			url: audios("./perfect-circle-annoyed.ogg"),
			delay: 6000,
		},
		{
			url: audios("./zeya_first_word.ogg"),
			delay: 12000,
		},
		{
			url: audios("./bg/se_page11.ogg"),
			volume: 0.4,
			delay: 1,
		},
	],
}

export default content
