import React, { useEffect } from "react"
/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import { Howl } from "howler"
import gsap from "gsap"

// image assets
import SaagImg from "../../content/images/level1/story/saag01.png"
import SpoonImg from "../../content/images/level1/story/spoon1.png"
import BhaatImg from "../../content/images/level1/story/plate_bhaat1.png"
import BaagGif from "../../content/images/level1/story/baag1.gif"
import KaagGif from "../../content/images/level1/story/kaag1.gif"
import AamaSvg from "../../content/images/level1/story/aama_standing.svg"
import TaraSvg from "../../content/images/level1/story/tara_with_jama.svg"
import JamaSvg from "../../content/images/level1/story/jama.svg"
import MamaSvg from "../../content/images/level1/story/mama_standing.svg"

const SaagStory = ({ NextPhase }) => {
	useEffect(() => {
		const soundAsset = require.context("./")
		const hintHowl = new Howl({
			src: [soundAsset("./aamaStory.ogg")],
		})

		gsap.to("#DP4-jama", {
			onStart: () => {
				hintHowl.play()
			},
		})
		hintHowl.on("play", () => {
			gsap.to("#DP4-jama", {
				delay: 1,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-jama", {
				delay: 1.45,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-aama", {
				delay: 2.9,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-aama", {
				delay: 3.2,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-mama", {
				delay: 3.9,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-mama", {
				delay: 4.3,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-kaag", {
				delay: 6.6,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-kaag", {
				delay: 6.9,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-baag", {
				delay: 7.25,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-baag", {
				delay: 7.5,
				duration: 0.8,
				scale: 1,
			})
		})

		hintHowl.on("end", () => {
			NextPhase()
		})
	}, [NextPhase])

	return (
		<React.Fragment>
			<img
				src={KaagGif}
				id="DP4-kaag"
				css={css`
					position: absolute;
					width: 10%;
					left: 63%;
					top: 55%;
				`}
				alt="kaag moving its head"
			/>
			<img
				src={BaagGif}
				id="DP4-baag"
				css={css`
					width: 13%;
					position: absolute;
					top: 47%;
					left: 45%;
				`}
				alt="baag moving its head"
			/>
			<img
				src={BhaatImg}
				css={css`
					width: 12%;
					position: absolute;
					top: 70%;
					left: 54%;
				`}
				alt="saag"
			/>
			<img
				src={SpoonImg}
				css={css`
					width: 6%;
					position: absolute;
					top: 72%;
					left: 52%;
				`}
				alt="Spoon"
			/>
			<img
				src={SaagImg}
				css={css`
					width: 6%;
					position: absolute;
					top: 67%;
					left: 60%;
				`}
				alt="Saag"
			/>
			<img
				src={AamaSvg}
				id="DP4-aama"
				css={css`
					width: 9%;
					position: absolute;
					top: 25%;
					left: 4%;
				`}
				alt="aama"
			/>
			<img
				src={MamaSvg}
				id="DP4-mama"
				css={css`
					width: 8%;
					position: absolute;
					top: 28%;
					left: 89%;
				`}
				alt="mama"
			/>

			<img
				src={TaraSvg}
				id="DP4-tara"
				css={css`
					width: 8%;
					position: absolute;
					top: 52%;
					left: 10%;
				`}
				alt="mama"
			/>
			<img
				src={JamaSvg}
				id="DP4-jama"
				css={css`
					width: 8%;
					position: absolute;
					top: 52%;
					left: 10%;
				`}
				alt="jama"
			/>
		</React.Fragment>
	)
}

export default SaagStory
