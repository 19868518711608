import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const busToKathmandu = keyframes`
0% {
	transform: rotate(-14deg) translateX(0%);
}
100% {
	transform: rotate(-10deg) translateX( 268%);
}
`
const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(4) translate(-6%,-6%)",
					animation: `${transformTo("scale(8) translate(-14.5%,-4%)")} 4s 24s forwards ease-out`
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '5%',
				top: '37%',
				left: '75%',
				opacity: 0,
				animation: ` ${appear} 1s 17s ease-out normal forwards,${disappear} 500ms 24s linear forwards				 
				`,
			}
		},
		{
			src: images("./imgs/kamal.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '7%',
				top: '43%',
				left: '28%',
				zIndex:2,
				animation: ` ${disappear} 1s 1s ease-out normal forwards				 
				`,
			}
		},
		{
			src: images("./imgs/talking_bothhandhip.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
			    width: '7%',
				top: '43%',
				left: '28%',
				opacity: 0,
				zIndex:2,
				transform: 'scaleX(-1)',
				animation: ` ${appear} 1s 1s ease-out normal forwards,
				${disappear} 1s 8s ease-out normal forwards			 
				`,
			}
		},
		{
			src: images("./imgs/bus.svg"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '21%',
				top: '54%',
				left: '21%',
				opacity:0,    
				transform: 'rotate(-14deg)',
    			zIndex: 2,
				animation: `${appear} 1s 9s ease-out normal forwards,
				${busToKathmandu} 8s 11s ease-out normal forwards,
				${disappear} 1s 16s ease-out normal forwards
				`,
			}
		},
		
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble1 page2Buble",
			textIndivData: [
				{
					textData: "अब हामी बस चढेर काठमाडौँ तिर लागौँ है।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '18%',
    			left: '27%',
				transformOrigin: "0% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 2000ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 8s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble2 page2Buble",
			textIndivData: [
				{
					textData: "यहाँ डाँडा माथि कस्तो राम्रो स्वयम्भूको स्तुपा रहेछ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '10%',
				left: '38%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 18s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 24s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromChitToKath",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '59.5%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '69%',
				position: 'absolute',
				left: '-33%',
				transform: "rotate(170deg) scaleX(0)",
				transformOrigin: "right",
				animation: `${transformTo(["rotate(170deg) scaleX(1)"])} 6s 11s linear forwards, ${disappear} 0.01s 17s linear forwards`
			}
		},
		
		
	],
	sounds: [
		{
			url: audios("./kathmandu1.ogg"),
			delay: 2000,
		},
		{
			url: audios("./kathmandu2.ogg"),
			delay: 18000,
		},
		{
			url: audios("./sound_eff/bus.ogg"),
			delay: 8000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 27000,
		},
	],
}

export default content
