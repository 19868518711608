const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./img_page09.png"),
			class: "image-one imgbox-sm imgOneEye",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./img_page09_b.png"),
			class: "image-one imgbox-sm imgBigEye",
			imgdiv: "imageCover",
		},
		{
			src: images("./img_page09_a.png"),
			class: "image-one imgbox-sm imgBagBed",
			imgdiv: "imageCover",
		},
		// {
		// 	src: images('./outline-sm-white.png'),
		// 	class: 'image-one',
		// 	imgdiv: 'imageCover',
		// },
		{
			src: images("./outline-p10-white.png"),
			class: "image-one outline-p10",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p10 textbox textbox-ban two-lines-texts",
			textIndivData: [
				{
					textData: "कोपिलाले बिस्तारै आँखा खोलिन् र",
					textClass: "m-0 one-line textStyles text-p10a",
					timestamps: [{ start: 0 }, { start: 1.1 }, { start: 2 }, { start: 2.4 }, { start: 3.2 }],
				},
				{
					textData: "अगाडि एउटा ब्याग देखिन्।",
					textClass: "m-0 one-line textStyles text-p10b",
					timestamps: [{ start: 3.5 }, { start: 4 }, { start: 4.8 }, { start: 5.2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./soundEffect/magicWand.ogg"),
			delay: 5100,
			isBg: true,
			volume: 0.1,
			loop: false,
		},
		{
			url: audios("./s10.ogg"),
			delay: 500,
		},
	],
}

export default content
