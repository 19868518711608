const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./bg_goat.png"),
			class: "image-five",
			imgdiv: "imageCover ",
		},
		{
			src: images("./goat-finding-its-way.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center bluetext",
			textIndivData: [
				{
					textData: "बाख्राले खाएर",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.2 }, { start: 2.4 }],
				},
				{
					textData: "वनतिर गयो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 3.4 }, { start: 4.2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./39.ogg"),
			delay: 500,
		},
	],
}

export default content
