export const imageCheck = (image) => {
	return (loc) => {
		let img = null
		try {
			img = image(loc)
		} catch (error) {
			console.log(`${loc} not found`)
		}
		return img
	}
}

export const fileCheck = (locator) => {
	return (location) => {
		let file = null
		try {
			file = locator(location)
		} catch (error) {
			console.log(`${location} not found`)
		}
		return file
	}
}

export const injectFiles = (images, audios, content) => {
	content.coverImage = images(`./${content.coverImage}`)
	content.credits.bg = images(`./${content.credits.bg}`)
	content.credits.titleAudio = audios(`./${content.credits.titleAudio}`)

	content.content = content.content.map((storyContents) => {
		if (storyContents.images) {
			storyContents.images = storyContents.images.map((image) => {
				return {
					...image,
					src: images(`./${image.src}`),
				}
			})
		}

		if (storyContents.sounds) {
			storyContents.sounds = storyContents.sounds.map((audio) => {
				return {
					...audio,
					url: audios(`./${audio.url}`),
				}
			})
		}

		return storyContents
	})

	return content
}
