const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./plate_with_rice.png"),
			class: "image-five",
			imgdiv: "imageCover greenbg",
		},
		{
			src: images("./kitty-cat.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center blacktext",
			textIndivData: [
				{
					textData: "बासी भात त्यत्तिकै",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.42 }, { start: 2.6 }],
				},
				{
					textData: "सुँघेर छाड्‍यो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 3.4 }, { start: 4.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./25.ogg"),
			delay: 500,
		},
		{
			url: audios("./a25.ogg"),
			delay: 150,
			isBg: true,
		},
	],
}

export default content
