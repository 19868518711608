import React, { useContext, useRef, useEffect } from "react"
import shuffle from "shuffle-array"
import gsap from "gsap"

import { StateContext, DispatchContext } from "../../HitTheWallGame"

import Stone from "../Stone/Stone"

function Options() {
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)
	const stoneRef = useRef()

	const optionsQueue = state.optionsArray
	let slots = [true, true, true, true, true]
	let StonePosition = [
		{
			top: "27%",
			left: "4%",
		},
		{
			top: "22%",
			left: "39%",
		},
		{
			top: "32%",
			left: "77%",
		},
		{
			top: "54%",
			left: "20%",
		},
		{
			top: "55%",
			left: "61%",
		},
	]
	let isCorrectClicked = false

	const animateStoneContainer = (stone) => {
		const getSlot = () => {
			let checkingArray = []
			slots.forEach((slot, i) => {
				if (slot) {
					checkingArray.push(i)
				}
			})
			if (checkingArray.length < 1) {
				checkingArray = [0, 1, 2, 3, 4]
				slots = [true, true, true, true, true]
			}

			const randomPick = shuffle(checkingArray)[0]
			slots[randomPick] = false

			return randomPick
		}

		const slot = getSlot()
		if (stoneRef.current) {
			gsap.set(stone, {
				transformOrigin: "50% 100%",
				left: StonePosition[slot].left,
				top: StonePosition[slot].top,
				scale: 0,
				opacity: 1,
				zIndex: 1,
			})

			if (slot === 3 || slot === 4) {
				gsap.set(stone, {
					zIndex: 10,
				})
			}

			let delay
			switch (slot) {
				case 0:
					// delay = 8;
					delay = gsap.utils.random(0, 6)
					break
				case 1:
					// delay = 6;
					delay = gsap.utils.random(4, 8)
					break
				case 2:
					delay = gsap.utils.random(0, 4)
					// delay = 4;
					break
				case 3:
					delay = gsap.utils.random(5, 10)
					// delay = 10;
					break
				case 4:
					delay = gsap.utils.random(0, 2)
					// delay = 2;
					break
				default:
					delay = 8
			}

			let tl = new gsap.timeline({ delay: delay })
			// let tl = new gsap.timeline({delay: gsap.utils.random(4,13)});
			tl.to(stone, { scale: 1, duration: 0.75 })
				.add(function () {
					if (isCorrectClicked) {
						tl.pause()
					}
				}, ">4")
				.to(stone, { scale: 0, duration: 0.75 })
				.add(function () {
					slots[slot] = true
				})
				.add(function () {
					animateStoneContainer(stone)
				}, ">3")
		}
	}

	useEffect(() => {
		let stones = stoneRef.current
		stones.querySelectorAll(".stones-htw").forEach((stone, idx) => {
			animateStoneContainer(stone)
		})
	})

	const onCorrectClick = (id) => {
		gsap.set(`#${id}`, { scale: 1 })
		isCorrectClicked = true
		gsap.to(".stones-htw", {
			scale: 0,
			duration: 1,
			delay: 4,
			onComplete: () => {
				dispatch({ type: "NEXT_STAGE" })
			},
		})
	}

	return (
		<div className="stonesContainer" ref={stoneRef}>
			{optionsQueue.map((option, index) => {
				return (
					<div key={`StoneDiv${index}`} id={`stone-${index}`} className="stones-htw" style={{ opacity: 0 }}>
						<Stone key={`stone-${index}`} id={index} value={option} onCorrectClick={onCorrectClick} />
					</div>
				)
			})}
		</div>
	)
}

export default Options
