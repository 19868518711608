import { keyframes } from "@emotion/core"
import { appear } from "styledElements/keyframes"

const content = {
	images: [
		{
			src: "forest/bg.png",
		},
		{
			src: "forest/bg_page3.png",
		},
		{
			src: "forest/bird_fly08.gif",
			styles: {
				width: "10%",
				transform: "translate(-65%, 30%)",
				animation: `${keyframes({
					to: {
						transform: "translate(760%, 7%)",
					},
				})} 7s ease-out forwards, disappear 150ms ease-out 7s forwards`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "forest/bird_fly_page3.png",
			class: "bird_a-p3",
			imgdiv: "imageCover",
		},
		{
			src: "forest/tree_page3.png",
		},
		{
			src: "forest/bird_sit_page3.png",
			class: "bird_b-p3",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: {
				top: "5%",
				left: "16%",
				padding: "15px",
				background: "rgba(255, 255, 255, 0.8);",
				borderRadius: "15px",
				animation: `${appear} 1s 3s ease-out normal both`,
			},
			textIndivData: [
				{
					textData: "हाँगामाथि लगेर",
					textClass: "m-0 p3-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "बनाए गुँड तयार",
					textClass: "m-0 p3-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p3.ogg",
			delay: 3000,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			volume: 1,
			loop: true,
			isBg: true,
		},
	],
}

export default content
