import React from "react"

const RecorderHint = (props) => {
	return (
		<React.Fragment>
			<span key={props.currentPage + "texthint" + props.index} className="hinttext fz-5">
				{props.queryText}
			</span>

			<div key={props.currentPage + "hint" + props.index} className={props.imgdivClass}>
				<img
					key={props.currentPage + "image" + props.index}
					src={`${props.src}?reload=${props.activityKey}`}
					className={`img-fluid ${props.class}`}
					alt=""
				/>
			</div>
		</React.Fragment>
	)
}
export default RecorderHint
