import { fileCheck } from "helpers/files"
import { appear } from "styledElements/keyframes"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page4scene",
	images: [
		{
			src: images("./bg_2.jpg"),
			imgdiv: "imageCover bg-p4",
		},
		{
			src: images("./nima_waving_hand.png"),
			imgdiv: "imageCover nima_look-p4",
		},
		{
			src: images("./nima_waving_hand.gif"),
			imgdiv: "imageCover nima_p4-gif",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center dialogueBubble bubble-text_box9 text-bubble-p4",
			textIndivData: [
				{
					textData: "छिट्टो खेल्न आऊ है। ",
					textClass: "m-0 ",
					timestamps: [
						{ start: 0 },
						{ start: 0.7 },
						{ start: 1.1 },
						{ start: 1.4 },
						{ start: 1.7 },
					],
				},
			],
			styles:{
				top: '37%',
				left: '5%',
				width: '32%',
				opacity: 0,
				animation: `${appear} 1s 3s forwards linear
				`,
			
			}
		},
	],
	sounds: [
		{
			url: audios("./dhobikhana1.ogg"),
			delay: 100,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./chito_khelna_aau.ogg"),
			delay: 3000,
			volume: 3,
		},
		{
			url: audios("./blank.ogg"),
			delay: 14400,
			volume: 0,
			loop: false,
			isBg: false,
		},
		{
			url: audios("./blank.ogg"),
			delay: 9000,
		},
	],
}

export default content
