import React from "react"
import _ from "underscore"
import "./hint.styles.scss"
import { Howl } from "howler"
// import gsap from "gsap";
// import volumeSvg from "../../content/images/gameAssets/volume.svg";

// import { StateContext, DispatchContext } from "../../MatchTheWordGame";

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

const Hint = (props) => {
	// const state = React.useContext(StateContext);
	// const dispatch = React.useContext(DispatchContext);
	const assets = require.context("./assets")
	// const hintCardRef = React.useRef(null);
	const playHintAudio = () => {
		const hintAudio = new Howl({
			src: [props.audio],
			loop: false,
			volume: 1,
		})

		hintAudio.play()
	}

	return (
		<div
			className="hint-mtw"
			style={{
				backgroundImage: `url('${assets("./board.png")}')`,
			}}
			onClick={_.debounce(() => playHintAudio(), 250)}
		>
			<div className="hintHolder-mtw">
				<img className="hint-img" src={props.image} alt="hint" />
			</div>
			{props.isWordCompleted && <div className="hint-text" id="hint-text"></div>}
		</div>
	)
}

export default Hint
