import { keyframes } from "@emotion/core"
import { appear, disappear } from "styledElements/keyframes.js"
import { fileCheck } from "helpers/files"

const images = fileCheck(require.context("../images", true))

const content = {
	images: [
		// {
		//     src: 'forest/branches_page5.png',
		//     class: "branches-p5",
		//     imgdiv: "imageCover",
		// },
		{
			src: "forest/bg_page4.jpg",
		},
		{
			src: "forest/nest_fall01.gif",
			// class: "nest-p5",
			styles: {
				width: "45%",
				top: "40%",
				left: "0%",
				animation: `${disappear} 0.001s linear 7s forwards `,
			},
			imgdiv: "imageCover",
		},
		{
			src: "forest/nest_fall01.png",
			// class: "nest-p5",
			styles: {
				width: "45%",
				top: "40%",
				left: "0%",
				opacity: 0,
				animation: `${appear} 0.001s linear 7s forwards`,
			},
			imgdiv: "imageCover",
		},
		// {
		// 	src: 'forest/bird_fly_page5.png',
		//     class: "bird-p5",
		//     imgdiv: "imageCover",
		// },
		{
			src: "birds/birds_fly_near_nest.gif",
			styles: {
				width: "150%",
			},
			imgdiv: "imageCover",
		},
		{
			src: "birds/bird_sitting.png",
			styles: {
				width: " 9%",
				top: "29%",
			},
			imgdiv: "imageCover",
		},
		{
			src: "forest/thunder_storm.gif",
			class: "thunder-p4",
			styles: {
				top: "-8%",
				left: "70%",
				opacity: 0,
				animation: `${keyframes({
					"0%": {
						opacity: 0,
					},
					"39%": {
						opacity: 0,
					},
					"50%": {
						opacity: 1,
					},
					"90%": {
						opacity: 1,
					},
				})} 3s linear 4 `,
			},
			imgdiv: "imageCover",
		},
		{
			src: "_.png",
			styles: {
				height: "200%",
				background: `url(${images("./forest/rain.png")}) 0px center / contain repeat`,
				backgroundPosition: "0% 0%",
				animation: `${keyframes({
					to: {
						backgroundPosition: "0% 100%",
					},
				})} 2s normal linear infinite`,
			},
		},
		{
			src: "_.png",
			styles: {
				height: "200%",
				background: `url(${images("./forest/hail.png")}) 0px center / contain repeat`,
				backgroundPosition: "0% 0%",
				animation: `${keyframes({
					to: {
						backgroundPosition: "0% 100%",
					},
				})} 1s normal linear infinite`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text text-center",
			styles: {
				top: "32%",
				left: "58%",
				padding: "15px",
				background: "rgba(255, 255, 255, 0.8);",
				borderRadius: "15px",
				animation: `${appear} 1s 3s ease-out normal both`,
			},
			textIndivData: [
				{
					textData: "असिना पानी बर्सेर",
					textClass: "m-0 p5-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "गुँडै गए झरेर",
					textClass: "m-0 p5-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p5.ogg",
			delay: 500,
		},
		{
			url: "rainStrom.ogg",
			delay: 0,
			volume: 0.3,
			loop: true,
			isBg: true,
		},
	],
}

export default content
