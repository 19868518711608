/*global require*/
const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = [
	// page 1
	{
		images: [
			{
				src: images("./coverpage.png"),
				imgdiv: "imageCover",
			},
		],
		texts: [
			{
				textDivClass: "story-text position-top-center text-center title",
				textIndivData: [
					{
						textData: "असल साथी",
						textClass: "story-title m-0",
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p1_s0.ogg"),
				delay: 500,
			},
		],
	},
	// page 2
	{
		images: [
			{
				src: images("./1to5/sathi-guff.png"),
				imgdiv: "imageCover",
			},
			{
				src: images("./1to5/bush01.png"),
			},
			{
				src: images("./1to5/bush02.png"),
				imgdiv: "imageCover",
			},
		],
		texts: [
			{
				textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75",
				textIndivData: [
					{
						textData: "एकादेशमा एउटा गाउँ थियो। त्यहाँको पोखरीमा एउटा कछुवा र दुईओटा पानीहाँसहरू बस्थे। उनीहरू असाध्यै मिल्थे।",
						textClass: "fz-5 m-0 ",
						timestamps: [
							{ start: 0 },
							{ start: 0.9 },
							{ start: 1.35 },
							{ start: 1.7 },
							{ start: 2.5 },
							{ start: 3.5 },
							{ start: 4.5 },
							{ start: 5.1 },
							{ start: 5.9 },
							{ start: 6.45 },
							{ start: 7.0 },
							{ start: 8.3 },
							{ start: 9.0 },
							{ start: 9.7 },
							{ start: 10.4 },
							{ start: 11 },
						],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p1_s1.ogg"),
				delay: 500,
			},
		],
	},
	// page 3
	{
		images: [
			{
				src: images("./1to5/bg.png"),
				imgdiv: "imageCover",
			},
		],
		texts: [
			{
				textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75",
				textIndivData: [
					{
						textData: "उनीहरू एकअर्कालाई माया गर्थे र एउटालाई दु:ख पर्दा अर्कोले सहयोग गर्थे।",
						textClass: "fz-5 m-0",
						timestamps: [
							{ start: 0 },
							{ start: 0.9 },
							{ start: 1.9 },
							{ start: 2.4 },
							{ start: 3.3 },
							{ start: 4 },
							{ start: 4.7 },
							{ start: 5.3 },
							{ start: 5.9 },
							{ start: 6.45 },
							{ start: 7.0 },
						],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p1_s2.ogg"),
				delay: 500,
			},
		],
	},
	// page 4
	{
		images: [
			{
				src: images("./1to5/sky-empty.png"),
				imgdiv: "imageCover",
			},
			{
				src: images("./1to5/cloud01.png"),
				imgdiv: "imageCover",
			},
			{
				src: images("./1to5/cloud02.png"),
				imgdiv: "imageCover",
			},
			{
				src: images("./1to5/cloud03.png"),
				imgdiv: "imageCover",
			},
		],
		texts: [
			{
				textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75",
				textIndivData: [
					{
						textData: "एक वर्ष त्यस गाउँमा पानी परेन।",
						textClass: "fz-5 m-0",
						timestamps: [
							{ start: 0.1 },
							{ start: 0.5 },
							{ start: 1 },
							{ start: 1.4 },
							{ start: 2 },
							{ start: 2.6 },
							{ start: 3.1 },
						],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p1_s3.ogg"),
				delay: 500,
			},
		],
	},
	// page 5
	{
		images: [
			{
				src: images("./1to5/pond-from-top.png"),
				imgdiv: "imageCover w-100",
				styles: {
					transform: "translate(0,0) scale(1)",
					left: "0",
					top: "0",
				},
			},
			{
				src: images("./1to5/sun.png"),
				imgdiv: "imageCover",
				styles: {
					transform: "translate(-50%, 0%)",
					transformOrigin: "center",
					width: "20%",
					// height: "35%",
					left: "50%",
					top: "165%",
				},
			},
			{
				src: images("./1to5/pond.svg"),
				imgdiv: "imageCover",
				styles: {
					// transform: "translate(-50%, 1120%) scale(0.8)",
					transform: "translate(-50%, 0%)",
					width: "80%",
					left: "50%",
					top: "268%",
				},
			},
		],
		texts: [
			{
				textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75",
				textIndivData: [
					{
						textData: "सधैँ चर्को घाम लागेको कारणले पोखरी सुक्दै गयो ।",
						textClass: "fz-5 m-0",
						timestamps: [
							{ start: 0 },
							{ start: 0.4 },
							{ start: 0.8 },
							{ start: 1.2 },
							{ start: 2.1 },
							{ start: 3.1 },
							{ start: 3.6 },
							{ start: 4.1 },
						],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p1_s4.ogg"),
				delay: 1500,
			},
		],
	},
	// page 6
	{
		images: [
			{
				src: images("./6to10/bg.png"),
				imgdiv: "imageCover",
			},
		],
		texts: [
			{
				textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75",
				textIndivData: [
					{
						textData: "पोखरी सुकेकाले पानीहाँस र कछुवालाई खानेकुरा नपाइने डरले आपत पर्‍यो।",
						textClass: "fz-5 m-0",
						timestamps: [
							{ start: 0 },
							{ start: 0.5 },
							{ start: 1.5 },
							{ start: 2.3 },
							{ start: 2.5 },
							{ start: 3.5 },
							{ start: 4.2 },
							{ start: 4.9 },
							{ start: 5.4 },
							{ start: 5.9 },
							{ start: 6.3 },
						],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p1_s6.ogg"),
				delay: 500,
			},
		],
	},
	// page 7
	{
		images: [
			{
				src: images("./6to10/bg.png"),
				imgdiv: "imageCover",
			},
		],
		texts: [
			{
				textDivClass: "story-text w-75 end-suspense",
				textIndivData: [
					{
						textData: "अब के हुन्छ होला?",
						textClass: "fz-5 m-0",
						timestamps: [{ start: 0 }, { start: 0.5 }, { start: 0.75 }, { start: 1.1 }, { start: 1.5 }],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p1_s7.ogg"),
				delay: 500,
			},
		],
	},

	// Section 2 starts
	// page 8
	{
		imageContainerClass: "bg-extended pos-1 ",
		images: [
			{
				src: images("./6to10/pond-big-bg.svg"),
				imgdiv: "imageCover bg-full",
				removeDiv: true,
			},
			{
				src: images("./6to10/tortoise01.svg"),
				imgdiv: "imageCover turtle",
				removeDiv: true,
				styles: {
					top: "unset",
				},
			},
			{
				src: images("./6to10/duck01a.png"),
				imgdiv: "imageCover duck-a",
				removeDiv: true,
				styles: {
					top: "unset",
				},
			},
			{
				src: images("./6to10/duck02b.png"),
				imgdiv: "imageCover duck-b",
				removeDiv: true,
				styles: {
					top: "unset",
				},
			},
		],
		texts: [
			{
				textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75 d-none",
				textIndivData: [
					{
						textData:
							"एक दिन कछुवाले साथीहरूलाई सोध्यो - “अब के गर्ने ?” पानीहाँसहरूले भने- “कुनै न कुनै उपाय त निकाल्नै पर्ला !”",
						textClass: "fz-5 m-0",
						timestamps: [
							{ start: 0 },
							{ start: 0.4 },
							{ start: 0.7 },
							{ start: 1.4 },
							{ start: 2 },
							{ start: 2.6 },
							{ start: 3.1 },
							{ start: 3.9 },
							{ start: 4.1 },
							{ start: 4.6 },
							{ start: 5 },
							{ start: 6 },
							{ start: 6.5 },
							{ start: 7.4 },
							{ start: 7.8 },
							{ start: 7.9 },
							{ start: 8.1 },
							{ start: 8.5 },
							{ start: 8.7 },
							{ start: 9.4 },
							{ start: 10 },
						],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p2_s0.ogg"),
				delay: 7000,
			},
		],
	},

	// page 9
	{
		imageContainerClass: "bg-extended pos-1 zoom-out-2",
		// imageContainerClass: "bg-extended pos-1 zoom-out-2",
		images: [
			{
				src: images("./6to10/pond-big-bg.svg"),
				imgdiv: "",
				removeDiv: true,
				class: "bg-full",
			},
			{
				src: images("./6to10/tortoise.svg"),
				imgdiv: "turtle",
				removeDiv: true,
				styles: {
					top: "unset",
				},
			},
			{
				src: images("./6to10/duck01a.svg"),
				imgdiv: "duck-a",
				removeDiv: true,
				styles: {
					top: "unset",
				},
			},
			{
				src: images("./6to10/duck02a.svg"),
				imgdiv: "duck-b",
				removeDiv: true,
				styles: {
					top: "unset",
				},
			},
		],
		texts: [
			{
				textDivClass: "story-text text-center bg-success h-auto position-top-center textContentDiv w-75",
				textIndivData: [
					{
						textData: "पानीहाँसहरूलाई धेरै टाढा अर्को गाउँमा पोखरी छ भन्‍ने कुरा थाहा थियो।",
						textClass: "fz-5 m-0",
						timestamps: [
							{ start: 0 },
							{ start: 1.3 },
							{ start: 2.05 },
							{ start: 2.7 },
							{ start: 3.1 },
							{ start: 3.9 },
							{ start: 4.5 },
							{ start: 4.9 },
							{ start: 5.3 },
							{ start: 5.6 },
							{ start: 6 },
							{ start: 6.5 },
						],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p2_s1.ogg"),
				delay: 1500,
			},
		],
	},

	// page 10
	{
		imageContainerClass: "bg-extended pos-2",
		images: [
			{
				src: images("./6to10/pond-big-bg.svg"),
				imgdiv: "imageCover",
				removeDiv: true,
			},
			{
				src: images("./6to10/tortoise.svg"),
				imgdiv: "turtle",
				removeDiv: true,
			},
			{
				src: images("./6to10/tortoise05.svg"),
				imgdiv: "turtle d-none",
				removeDiv: true,
			},
			{
				src: images("./6to10/duck01a.svg"),
				imgdiv: "duck-a d-none",
				removeDiv: true,
			},
			{
				src: images("./6to10/duck01b.svg"),
				imgdiv: "duck-a",
				removeDiv: true,
			},
			{
				src: images("./6to10/duck02a.svg"),
				imgdiv: "duck-b",
				removeDiv: true,
			},
			{
				src: images("./6to10/duck02c.svg"),
				imgdiv: "duck-b d-none",
				removeDiv: true,
			},
		],
		texts: [
			{
				textDivClass: "story-text text-center bg-success h-auto position-top-center textContentDiv w-75",
				textIndivData: [
					{
						textData:
							"“हामी त त्यहाँ उडेर जान सक्छौँ तर कछुवालाई त्यहाँसम्म कसरी लाने होला?” एउटा पानीहाँसले अर्को पानीहाँसलाई सोध्यो। उसले केही भन्‍न सकेन।",
						textClass: "fz-5 m-0",
						timestamps: [
							{ start: 0 },
							{ start: 0.45 },
							{ start: 0.7 },
							{ start: 1.2 },
							{ start: 1.7 },
							{ start: 2.1 },
							{ start: 3 },
							{ start: 3.6 },
							{ start: 4.5 },
							{ start: 4.9 },
							{ start: 5.5 },
							{ start: 5.9 },
							{ start: 6.4, end: 7 },
							{ start: 8 },
							{ start: 9 },
							{ start: 9.5 },
							{ start: 10.5 },
							{ start: 11.9 },
							{ start: 12.2 },
							{ start: 12.5 },
							{ start: 13.3 },
						],
					},
				],
			},
		],
		sounds: [
			{
				url: audios("./p2_s2.ogg"),
				delay: 1500,
			},
			{
				url: audios("./p2_s2a.ogg"),
				delay: 9000,
			},
		],
	},
]

export default content
