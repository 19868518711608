import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "रायोको साग",
	key: "rayo-ko-saag",
	label: "hard",
	coverImage: images("./cover_banner.png"),
	credits: {
		writer: "पुण्यशील गौतम",
		illustrator: "अनुश्री लामा",
		template: "ratoBangla",
		bg: images("./cover_banner.png"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
