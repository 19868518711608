const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./25to33/bg01.png"),
			imgdiv: "imageCover bg-full bg-tortoise",
		},
		{
			src: images("./25to33/sweat.svg"),
			imgdiv: "imageCover sweat-anim sweat",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "कछुवालाई आफूले गल्ती गरेकोमा ठुलो पछुतो भयो।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.8 },
						{ start: 1.4 },
						{ start: 1.8 },
						{ start: 2.7 },
						{ start: 3.2 },
						{ start: 3.7 },
						{ start: 4.5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p5_s4.ogg"),
			delay: 250,
		},
	],
}

export default content
