import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 bg-sky",
	images: [
		{
			src: images("./boy_thinking_no_talk.gif"),
			imgdiv: "imageCover boygrining1 thinking_no_talk",
		},
		{
			src: images("./boy_thinking_talk_only.gif"),
			imgdiv: "imageCover boygrining1 thinking_talk_only",
		},
		{
			src: images("./kids_in_clouds.png"),
			class: "image-10 kids_in_clouds",
			imgdiv: "imageCover think2",
		},
		{
			src: images("./engineer_in_clouds.png"),
			class: "image-10 engineer_in_clouds",
			imgdiv: "imageCover think3",
		},
		{
			src: images("./boy_in_clouds.png"),
			class: "image-10 boy_in_clouds",
			imgdiv: "imageCover think4",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page22boy rightMidBubble text-center",
			textIndivData: [
				{
					textData: "अब त, म पनि स्कुल जाने!!",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.5 }, { start: 1.1 }, { start: 1.5 },{ start: 1.7 }, { start: 2.0 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./aba_ta_ma_pani_school_jane.ogg"),
			delay: 19000,
		},
		{
			url: audios("./se_page2to4.ogg"),
			delay: 0,
			isBg: true,
			loop: false
		},
	],
}

export default content
