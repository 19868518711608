const audios = require.context("../audios", true)
const images = require.context("../images", true)

export const level2_words = [
	"टाउको",
	"टेबुल",
	"किताब",
	"कपाल",
	"मानिस",
	"कमिला",
	"पहाड",
	"अचार",
	"कुखुरा",
	"परेवा",
	"बिरालो",
	"रिबन",
	"कुकुर",
	"पोखरी",
	"कछुवा",
	"शरीर",
	"कागज",
]

export const level2_hintImages = {
	टाउको: [images("./tauko.png"), images("./tauko.png"), audios("./tauko.ogg")],
	टेबुल: [images("./table.png"), images("./table.png"), audios("./table.ogg")],
	किताब: [images("./kitab.png"), images("./kitab.png"), audios("./kitab.ogg")],
	कपाल: [images("./kapal.png"), images("./kapal.png"), audios("./kapal.ogg")],
	मानिस: [images("./manis.png"), images("./manis.png"), audios("./manis.ogg")],
	कमिला: [images("./kamila.png"), images("./kamila.png"), audios("./kamila.ogg")],
	पहाड: [images("./pahad.png"), images("./pahad.png"), audios("./pahad.ogg")],
	अचार: [images("./achar.png"), images("./achar.png"), audios("./achar.ogg")],
	कुखुरा: [images("./kukhura.png"), images("./kukhura.png"), audios("./kukhura.ogg")],
	परेवा: [images("./parewa.png"), images("./parewa.png"), audios("./parewa.ogg")],
	बिरालो: [images("./biralo.png"), images("./biralo.png"), audios("./biralo.ogg")],
	रिबन: [images("./ribbon.png"), images("./ribbon.png"), audios("./ribbon.ogg")],
	कुकुर: [images("./kukur.png"), images("./kukur.png"), audios("./kukur.ogg")],
	पोखरी: [images("./pokhari.png"), images("./pokhari.png"), audios("./pokhari.ogg")],
	कछुवा: [images("./kachuwa.png"), images("./kachuwa.png"), audios("./kachuwa.ogg")],
	शरीर: [images("./sarir.png"), images("./sarir.png"), audios("./sarir.ogg")],
	कागज: [images("./kagaj.png"), images("./kagaj.png"), audios("./kagaj.ogg")],
}
