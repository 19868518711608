const words = [
	[
		{
			key: "himal",
			row: 0,
			column: 3,
			isAcross: 0,
			arrow: "adl",
			letters: ["हि", "मा", "ल"],
		},
		{
			key: "cycle",
			row: 2,
			column: 0,
			isAcross: 1,
			arrow: "aur",
			letters: ["सा", "इ", "क", "ल"],
		},
		{
			key: "kamila",
			row: 2,
			column: 2,
			isAcross: 0,
			arrow: "adr",
			letters: ["क", "मि", "ला"],
		},
		{
			key: "lamkhutte",
			row: 4,
			column: 2,
			isAcross: 1,
			arrow: "aur",
			letters: ["ला", "म", "खु", "ट्टे"],
		},
		{
			key: "makmali",
			row: 4,
			column: 3,
			isAcross: 0,
			arrow: "adl",
			letters: ["म", "ख", "म", "ली"],
		},
		{
			key: "laliguras",
			row: 7,
			column: 2,
			isAcross: 1,
			arrow: "aur",
			letters: ["ला", "ली", "गु", "राँ", "स"],
		},
		{
			key: "kapas",
			row: 5,
			column: 6,
			isAcross: 0,
			arrow: "adl",
			letters: ["क", "पा", "स"],
		},
		{
			key: "karuwa",
			row: 5,
			column: 6,
			isAcross: 1,
			arrow: "aur",
			letters: ["क", "रु", "वा"],
		},
		{
			key: "guleli",
			row: 7,
			column: 4,
			isAcross: 0,
			arrow: "ad",
			letters: ["गु", "ले", "ली"],
		},
		{
			key: "thaili",
			row: 9,
			column: 3,
			isAcross: 1,
			arrow: "aur",
			letters: ["थै", "ली"],
		},
	],
]

export default words
