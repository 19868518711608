import React, { useContext, useEffect } from "react"
import _ from "underscore"
import { Howl } from "howler"
import gsap from "gsap"

import { DispatchContext, StateContext } from "../../HitTheWallGame"

import SpeakerImg from "../../content/images/gameAssets/speaker.png"

function Speaker() {
	const dispatch = useContext(DispatchContext)
	const state = useContext(StateContext)
	const choosenWord = state.choosenWord

	const hintAudio = new Howl({
		src: [choosenWord.audio],
		autoplay: false,
		loop: false,
		preload: true,
	})

	const onSpeakerClick = _.debounce(() => {
		hintAudio.play()
	}, 250)

	const hideHammer = () => {
		gsap.to("#cursor", { autoAlpha: 0 })
	}

	useEffect(() => {
		setTimeout(() => {
			hintAudio.play()
		}, 800)
	}, [hintAudio])

	//----------------------- NEXT ROUND DEDUGGING Helper -------------------------
	var map = { 16: false, 39: false } //16: shift , 39: right arrow   <= keyCodes

	useEffect(() => {
		const keyDown = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = true
				if (map[16] && map[39]) {
					// FIRE EVENT
					dispatch({ type: "NEXT_STAGE" })
				}
			}
		}
		window.addEventListener("keydown", keyDown)

		const keyUp = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = false
			}
		}
		window.addEventListener("keyup", keyUp)
		return () => {
			window.removeEventListener("keydown", keyDown)
			window.removeEventListener("keyup", keyUp)
		}
	})

	// -----------------------END NEXT ROUND -------------------------------

	return (
		<div className="speaker" style={{ zIndex: 4 }} onMouseOver={hideHammer}>
			<img src={SpeakerImg} alt="volume" onClick={onSpeakerClick} width="100%" height="100%" />
		</div>
	)
}

export default Speaker
