import PageContent from "./pages/all"

const images = require.context("./images", true)

const audios = require.context("./audios", true)
const content = {
	bg: "TimiKKhanchau",
	name: "तिमी के खान्छौ?",
	key: "timi-k-khanchau",
	label: "easy",
	coverImage: images("./coverimage.png"),
	credits: {
		writer: "साझा शिक्षा ई–पाटी",
		illustrator: "रोसेल तुलाधर",
		audio: "स्तुति शर्मा",
		template: "default",
		bg: images("./coverimage.png"),
		titleAudio: audios("./timi_k_khanchau.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
