const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_page_06.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./3_bird_fly.gif"),
			class: "image-one bird_fly",
			imgdiv: "imageCover",
		},
		{
			src: images("./holding_egg.gif"),
			class: "image-one img05",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page8Text text-center",
			textIndivData: [
				{
					textData: "उनीहरूले फुल हातमा लिए। फुल हेरेर दुबैजना खुशी भए।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1.1 },
						{ start: 1.35 },
						{ start: 1.6 },
						{ start: 2.9 },
						{ start: 3.3 },
						{ start: 3.6 },
						{ start: 4.2 },
						{ start: 4.65 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./8.ogg"),
			delay: 500,
		},
		{
			url: audios("./sunrise.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
