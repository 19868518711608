/** @jsx jsx */
import { jsx } from "@emotion/core"

import React, {
	useEffect,
	useRef,
	useState,
	//  useState
} from "react"
import ReactPlayer from "react-player"
import _ from "underscore"

import { isMobile } from "react-device-detect"

// custom components
import Header from "Components/Layout/Header"
import Footer from "Components/Layout/Footer"
import PageNumber from "Components/PageNumber"
import BackwardBtn from "./components/BackwardBtn"
import ForwardBtn from "./components/ForwardBtn"
import PrevBtn from "./components/PrevBtn"
import NextBtn from "./components/NextBtn"
import ResetBtn from "./components/ResetBtn"
import HomeBtn from "./components/HomeBtn"
import PlayPauseBtn from "./components/PlayPauseBtn"
import PageNumberMobile from "./components/PageNumberMobile"

// icons
import playButton from "./icons/start/play_video_nep.svg"
import readButton from "./icons/start/listen_story_nep.svg"

import { AwesomeButton } from "react-awesome-button"
import "react-awesome-button/dist/styles.css"
import "./btnStyles.css"

// helpers
import { getStoryData } from "../../ContainerHelpers"
import "./styles.scss"

export const DispatchContext = React.createContext(null)

const Container = () => {
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)

	const [playing, setPlaying] = useState(false)
	const [movieMode, setMovieMode] = useState(false)
	const [waitingInput, setWaitingInput] = useState(false)
	const [started, setStarted] = useState(false)
	const [subText, setSubText] = useState("")
	// const [subIndex, setSubIndex] = useState(0);
	const subsContainer = useRef(null)
	const movieHolder = useRef(null)
	const moviePlayer = useRef(null)
	const content = getStoryData("tateypatey")
	const storyname = content.name

	const isMobileLandscape = isMobile && window.innerWidth > window.innerHeight

	const landscapeSubStyles =
		isMobileLandscape && movieMode
			? {
					zIndex: "999",
					marginTop: "-5px",
					// borderLeft: '5px solid #f6e8cd',
					// borderRight: '5px solid #f6e8cd',
			  }
			: {}

	const subtitleStyles = {
		display: "none",
		fontWeight: "500",
		fontSize: "22px",
		background: "#0E191F",
		height: "0px", //default:56
		width: `${isMobileLandscape && movieMode ? width + 60 : width}px`,
		justifyContent: "center",
		alignItems: "center",
		display: "none",
		...landscapeSubStyles,
		p: {
			color: "#fff",
			"@media (max-width: 575px)": {
				fontSize: "1rem",
			},
			"@media (orientation: landscape)": {
				fontSize: "1rem",

				"@media (min-width: 1023px)": {
					fontSize: "1.5rem",
				},
			},
		},
	}

	let startStyles = {
		width: `${width}px`,
	}
	let thumbnail = {
		width: `100%`,
		height: `${height}px`,
		minHeight: `${height}px`,
		background: `url(${content.coverImage})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	}
	let buttonHolder = {
		width: `100%`,
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		background: "#123858",
		padding: "10px 0px",
		"@media (max-width: 575px)": {
			flexDirection: isMobileLandscape ? "row" : "column",
			justifyContent: isMobileLandscape ? "space-around" : "center",
			"button:nth-of-type(2)": {
				marginTop: isMobileLandscape ? "0px" : "20px",
			},
		},
	}

	const startButtonStyles = {
		borderRadius: "40px",
		cursor: "pointer",
		width: isMobileLandscape ? "125px" : "200px",
		height: isMobileLandscape ? "50px" : "80px",
		padding: "0",
		img: {
			width: "200px",
		},
	}

	const StartBtn = () => {
		return (
			<div css={startStyles}>
				<div css={thumbnail}></div>
				<div css={buttonHolder}>
					<AwesomeButton
						css={startButtonStyles}
						onPress={() => {
							setStarted(true)
							setPlaying(true)
							setMovieMode(true)
						}}
					>
						<img className="img-fluid" src={playButton} alt="play button" />
					</AwesomeButton>
					<AwesomeButton
						type="secondary"
						css={startButtonStyles}
						onPress={() => {
							setStarted(true)
							setPlaying(true)
							setMovieMode(false)
							setCurrentPage(0)
						}}
					>
						<img className="img-fluid" src={readButton} alt="read button" />
					</AwesomeButton>
				</div>
			</div>
		)
	}

	const nextBtnClick = () => {
		if (movieMode) {
			moviePlayer.current.seekTo(moviePlayer.current.getCurrentTime() + 5)
		} else {
			let timestamp = content.timestamps[currentPage]
			setCurrentPage(currentPage + 1)

			if (Math.abs(moviePlayer.current.getCurrentTime() - timestamp.totalTime) > 1) {
				moviePlayer.current.seekTo(timestamp.totalTime)
			}
			setWaitingInput(false)
			setPlaying(true)
		}
	}

	const prevBtnClick = () => {
		if (movieMode) {
			moviePlayer.current.seekTo(moviePlayer.current.getCurrentTime() - 5)
		} else {
			let timestamp = content.timestamps[currentPage - 2] || { totalTime: 0 }
			setCurrentPage(currentPage - 1)
			moviePlayer.current.seekTo(timestamp.totalTime)
			setWaitingInput(false)
			setPlaying(true)
		}
	}
	const playPauseBtnClick = () => {
		if (playing) {
			setPlaying(false)
		} else {
			setPlaying(true)
		}
	}
	const refreshBtnClick = () => {
		let timestamp = content.timestamps[currentPage - 1] || { totalTime: 0 }
		setCurrentPage(currentPage)
		moviePlayer.current.seekTo(timestamp.totalTime)
		setWaitingInput(false)
		setPlaying(true)
	}
	const homeBtnClick = () => {
		setStarted(false)
		setPlaying(false)
		// setSubIndex(0);
		setCurrentPage(0)
		setWaitingInput(false)
		moviePlayer.current.seekTo(0)
	}

	function ActivityControls({ currentPage, totalPages }) {
		// console.log(movieMode);
		// console.log(!movieMode && (!started || currentPage === totalPages || playing));
		return (
			<div
				className="activity__nav"
				css={{
					".movie__frame &.activity__nav": {
						width: `${width}px`,
					},
				}}
			>
				<div className="row align-items-center">
					<div className="col-6">
						{!movieMode && <PageNumber totalPages={totalPages + 1} currentPage={currentPage} key={"pn" + currentPage} />}
					</div>
					<div className="col-6 text-right">
						{movieMode && (
							<div className="pl-1 pl-md-3 d-inline-block icon-btn-group">
								<HomeBtn homeBtnClick={homeBtnClick} />
								<BackwardBtn disabled={false} prevBtnClick={prevBtnClick} />
								<PlayPauseBtn playPauseBtnClick={playPauseBtnClick} disabled={!playing} />
								<ForwardBtn
									disabled={!movieMode && (!started || currentPage === totalPages || playing)}
									success={waitingInput}
									nextBtnClick={nextBtnClick}
									className={waitingInput ? "next__btn waiting" : "next__btn"}
								/>
							</div>
						)}
						{!movieMode && (
							<div className="pl-1 pl-md-3 d-inline-block icon-btn-group">
								<HomeBtn homeBtnClick={homeBtnClick} />
								<ResetBtn refreshBtnClick={refreshBtnClick} />
								<PrevBtn disabled={currentPage < 1} prevBtnClick={prevBtnClick} />
								<NextBtn
									disabled={!movieMode && (!started || currentPage === totalPages || playing)}
									success={waitingInput}
									nextBtnClick={nextBtnClick}
									className={waitingInput ? "next__btn waiting" : "next__btn"}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}

	function ActivityControlsMobileLandscape({ currentPage, totalPages }) {
		return (
			<div
				className="activity__nav"
				css={{
					".movie__frame &.activity__nav": {
						width: "60px",
						padding: "0",
						justifyContent: "space-evenly",
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
					},
				}}
			>
				{movieMode && (
					<React.Fragment>
						<HomeBtn homeBtnClick={homeBtnClick} />
						<BackwardBtn disabled={false} prevBtnClick={prevBtnClick} />
						<PlayPauseBtn playPauseBtnClick={playPauseBtnClick} disabled={!playing} />
						<ForwardBtn
							disabled={!movieMode && (!started || currentPage === totalPages || playing)}
							success={waitingInput}
							nextBtnClick={nextBtnClick}
							className={waitingInput ? "next__btn waiting" : "next__btn"}
						/>
					</React.Fragment>
				)}
				{!movieMode && (
					<React.Fragment>
						<HomeBtn homeBtnClick={homeBtnClick} />
						<ResetBtn refreshBtnClick={refreshBtnClick} />
						<PrevBtn disabled={currentPage < 1} prevBtnClick={prevBtnClick} />
						<NextBtn
							disabled={!movieMode && (!started || currentPage === totalPages || playing)}
							success={waitingInput}
							nextBtnClick={nextBtnClick}
							className={waitingInput ? "next__btn waiting" : "next__btn"}
						/>
					</React.Fragment>
				)}
			</div>
		)
	}

	useEffect(() => {
		document.title = `HRK :: stories :: ${storyname}`
		return () => (document.title = `HRK `)
	}, [storyname])

	//----------------------- NEXT ROUND DEDUGGING Helper -------------------------
	let map = { 16: false, 39: false } //16: shift , 39: right arrow   <= keyCodes

	useEffect(() => {
		const keyDown = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = true
				if (map[16] && map[39]) {
					// FIRE EVENT
					nextBtnClick()
					// dispatch({ type: 'NEXT_STAGE' });
				}
			}
		}
		window.addEventListener("keydown", keyDown)

		const keyUp = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = false
			}
		}
		window.addEventListener("keyup", keyUp)
		return () => {
			window.removeEventListener("keydown", keyDown)
			window.removeEventListener("keyup", keyUp)
		}
	})

	useEffect(() => {
		const resizeHandler = () => {
			const excessHeight = isMobileLandscape ? 56 + 10 + 56 + 10 : 56 + 10 + 56 + 76 + 10
			let computedWidth = isMobileLandscape ? movieHolder.current.offsetWidth - 50 : movieHolder.current.offsetWidth
			let computedHeight = (computedWidth * 9) / 16
			let requiredFullHeight = computedHeight + excessHeight

			if (requiredFullHeight > window.innerHeight) {
				computedHeight = window.innerHeight - excessHeight
				computedWidth = (computedHeight * 16) / 9
			}

			console.log(computedWidth, movieHolder.current.offsetWidth, computedHeight)

			// if (isMobileLandscape) {
			// 	alert('Please use portrait mode for best experience');
			// }
			setWidth(computedWidth)
			setHeight(computedHeight)
		}

		resizeHandler()

		const handleResize = _.debounce(() => {
			resizeHandler()
		}, 500)

		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [movieHolder, isMobileLandscape])

	return (
		<div className="activity__container charlie-container">
			<Header storyName={content.name} />
			<main className="main__content justify-content-center">
				<div id="movieBox" className="movie__frame charlie-box row align-items-center charlie-chamero d-flex flex-column">
					<div
						className={`movie_holder w-100 ${content.key}`}
						ref={movieHolder}
						css={{
							maxWidth: "1200px",
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							margin: "auto",
						}}
					>
						<div className="movie_wrap w-100">
							<div
								className="movieContent w-100"
								key={content.key}
								onContextMenu={(e) => {
									e.preventDefault()
								}}
								css={{ position: "relative", display: "flex", justifyContent: "center" }}
							>
								{!started && <StartBtn />}
								{started && (
									<ReactPlayer
										className="chamero-player"
										url={content.movie}
										ref={moviePlayer}
										width={`${width}px`}
										height={`${height}px`}
										playing={playing}
										loop={false}
										controls={false}
										pip={true}
										style={{
											minHeight: `${height}px`,
										}}
										progressInterval={5}
										onStart={() => {
											setStarted(true)
										}}
										onEnded={() => {
											setStarted(false)
											setPlaying(false)
											if (moviePlayer.current) {
												moviePlayer.current.seekTo(0)
											}
										}}
										onProgress={(state) => {
											// console.log(state);
											let playedSeconds = state.playedSeconds - 0.5

											let sIdx = 0
											content.subtitles.forEach((sub, idx) => {
												if (sub.totalTime < playedSeconds) {
													sIdx = idx
												}
											})

											if (sIdx < content.subtitles.length) {
												if (playedSeconds >= content.subtitles[sIdx].totalTime) {
													setSubText(content.subtitles[sIdx].text)
												}
											}

											if (!movieMode) {
												if (currentPage < content.timestamps.length) {
													let timestamp = content.timestamps[currentPage]

													if (playedSeconds >= timestamp.totalTime) {
														setWaitingInput(true)
														setPlaying(false)
													}
												}
											}
										}}
									/>
								)}

								{started && isMobileLandscape && (
									<ActivityControlsMobileLandscape currentPage={currentPage} totalPages={content.timestamps.length} />
								)}
							</div>
						</div>
						{started && (
							<React.Fragment>
								{!movieMode && (
									<div css={{ display: "flex" }}>
										<div ref={subsContainer} css={subtitleStyles}>
											<div className="text-center">
												<p className="m-0 p-3">{subText}</p>
											</div>
										</div>

										{isMobileLandscape && (
											<PageNumberMobile currentPage={currentPage} totalPages={content.timestamps.length} />
										)}
									</div>
								)}
								{movieMode && (
									<React.Fragment>
										<div ref={subsContainer} css={subtitleStyles}>
											<div className="text-center">
												<p className="m-0 p-3">{subText}</p>
											</div>
										</div>
									</React.Fragment>
								)}

								{!isMobileLandscape && (
									<ActivityControls currentPage={currentPage} totalPages={content.timestamps.length} />
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</main>
			{!started && !isMobileLandscape && <Footer donner="true" />}
		</div>
	)
}

export default Container
