import React from "react"
// import PrevIcon from 'assets/icons/previous.svg';
const RecorderButtons = (props) => {
	return (
		<div
			key={props.currentpage + "btns" + props.index}
			onClick={props.onClickEvent}
			className={`recorder-buttons ${props.btnClass} ${props.isRecording ? "recording" : "not-recording"}`}
		>
			<img src={props.src} className="img-fluid" alt="previous" />
			<strong className={"btnText"}>{props.btntext}</strong>
		</div>
	)
}

export default RecorderButtons
