// configs
import shuffle from "shuffle-array"

import { level1_words, level1_hintImages } from "../../../../EGRContent/Play/RecorderPlay/levels/1"
import { level2_words, level2_hintImages } from "../../../../EGRContent/Play/RecorderPlay/levels/2"
import { level3_words, level3_hintImages } from "../../../../EGRContent/Play/RecorderPlay/levels/3"

export const getLevelWords = (level = "easy") => {
	let isEasy = level.toString().toLowerCase() === "easy"
	let isMedium = level.toString().toLowerCase() === "medium"
	let isHard = level.toString().toLowerCase() === "hard"

	if (isHard) {
		return shuffle(level3_words).splice(0, 10)
	}
	if (isMedium) {
		return shuffle(level2_words).splice(0, 10)
	}
	if (isEasy) {
		return shuffle(level1_words).splice(0, 10)
	}
}

export const getLevelHintImages = (level = "easy") => {
	let isEasy = level.toString().toLowerCase() === "easy"
	let isMedium = level.toString().toLowerCase() === "medium"
	let isHard = level.toString().toLowerCase() === "hard"

	if (isHard) {
		return level3_hintImages
	}
	if (isMedium) {
		return level2_hintImages
	}
	if (isEasy) {
		return level1_hintImages
	}
}
