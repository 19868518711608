const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg01.png"),
			class: "image-five bg01",
			imgdiv: "imageCover zoominroti",
		},
		{
			src: images("./water_wave01.png"),
			class: "image-five water_wave01",
			imgdiv: "imageCover zoominroti",
		},
		{
			src: images("./water_wave02.png"),
			class: "image-five water_wave02",
			imgdiv: "imageCover zoominroti",
		},
		{
			src: images("./water_wave03.png"),
			class: "image-five water_wave03",
			imgdiv: "imageCover zoominroti",
		},
		{
			src: images("./crow_dropping_roti_land.png"),
			class: "image-five wetroti",
			imgdiv: "imageCover zoominroti",
		},
		{
			src: images("./stone.png"),
			class: "image-five stone",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page9Text text-center",
			textIndivData: [
				{
					textData: "पानीमा भिजेर रोटी कमलो भयो।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1.2 }, { start: 2.1 }, { start: 3 }, { start: 3.75 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./9.ogg"),
			delay: 1000,
		},
		{
			url: audios("./a6.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
