const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page7.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./wow_eggs.gif"),
			class: "image-one wow_eggs",
			imgdiv: "imageCover",
		},
		{
			src: images("./boy_on_cycle.png"),
			class: "image-one cycle",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page7Text text-center",
			textIndivData: [
				{
					textData: "‘आहा! फुल!!’",
					textClass: "word7",
					timestamps: [{ start: 0 }, { start: 0.7 }],
				},
				{
					textData: "दुबै रमाए।",
					textClass: "m-0",
					timestamps: [{ start: 2.0 }, { start: 2.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7.ogg"),
			delay: 500,
		},
		{
			url: audios("./7a.ogg"),
			delay: 2500,
		},
		{
			url: audios("./bell.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
			loop: false,
		},
	],
}

export default content
