import React, { useState, useContext } from "react"
import gsap from "gsap"
import { Howl } from "howler"
import _ from "underscore"
//COntext
import { StateContext } from "../../HitTheWallGame"

//assets
import stoneImg from "./assets/images/stone01.png"
import stoneBrakeGif from "./assets/images/breaking_stone2.gif"
import "./stone.styles.scss"
import boomSound from "../../content/audios/sound_effects/boom.ogg"
import tinkSound from "../../content/audios/sound_effects/metal_tink.ogg"
import jingleSound from "../../content/audios/sound_effects/jingle-win.ogg"

function Stone({ id, value, onCorrectClick }) {
	const [isBroken, setIsBroken] = useState(false)
	const [isFirstCorrectClick, setIsFirstCorrectClick] = useState(true)
	const state = useContext(StateContext)

	const hintAudio = new Howl({
		src: [value.audio],
		autoplay: false,
		loop: false,
	})

	const jingleAudio = new Howl({
		src: [jingleSound],
		autoplay: false,
		loop: false,
	})

	const tinkPlayer = new Howl({
		src: [tinkSound],
		autoplay: false,
		loop: false,
	})

	const boomPlayer = new Howl({
		src: [boomSound],
		autoplay: false,
		loop: false,
	})

	const stoneIsClicked = _.debounce(() => {
		// console.log(gsap.getProperty(`#stone-${id}`,"scale"), `stone-${id}`);
		let isShrinked = gsap.getProperty(`#stone-${id}`, "scale") < 1
		// console.log(gsap.getProperty(`#stone-${id}`,"scale"));
		if (isShrinked) {
			console.log("Cant touch this.")
			return
		}
		gsap.set("#cursor", { transformOrigin: "50% 100%" })
		let HammerTl = gsap.timeline()

		HammerTl.to("#cursor", { rotate: "60%", duration: 0.2 })
			.to("#cursor", { rotate: "-80%", duration: 0.1 })
			.to("#cursor", { rotate: "0%", duration: 0.2 })
			.add(function () {
				if (isFirstCorrectClick && state.choosenWord.npword === value.npword) {
					setIsFirstCorrectClick(false)
					boomPlayer.play()

					gsap.set(`#imgHolder-${id}`, { transformOrigin: "50% 100%" })

					let imageAnimateTL = gsap.timeline({ delay: 0.5 })
					imageAnimateTL.to(`#imgHolder-${id}`, { opacity: 1, y: -40, scale: 1.5, duration: 1 })

					onCorrectClick(`stone-${id}`)
					boomPlayer.on("end", function () {
						jingleAudio.play()
						jingleAudio.on("end", function () {
							hintAudio.play()
						})
					})

					setIsBroken(true)
				} else {
					tinkPlayer.play()
					gsap.set(`#imgHolder-${id}`, { transformOrigin: "50% 100%" })
					let stoneShakeAnimateTL = gsap.timeline()
					stoneShakeAnimateTL
						.to(`#stone-${id}`, { rotate: "2%", duration: 0.2 })
						.to(`#stone-${id}`, { rotate: "-2%", duration: 0.2 })
						.to(`#stone-${id}`, { rotate: "2%", duration: 0.2 })
						.to(`#stone-${id}`, { rotate: "-2%", duration: 0.2 })
						.to(`#stone-${id}`, { rotate: "0", duration: 0.1 })
				}
			}, "-=0.3")
	})

	// const handleHammerRise = _.debounce(() => {
	//     gsap.set("#cursor", { transformOrigin: "50% 100%"});
	//     let HammerTl = gsap.timeline();

	//     HammerTl
	//     .to("#cursor", {rotate: "40%", duration: 0.2});
	//     // gsap.set("#cursor", {rotate: "40%"});

	// }, 750);

	// const handleHammerRiseHold = () => {
	//     gsap.set("#cursor", { transformOrigin: "50% 100%", rotate: "40%"});
	// }

	// const handleHammerDown = () => {
	//     gsap.set("#cursor", { transformOrigin: "50% 100%"});
	//     let HammerTl = gsap.timeline();

	//     HammerTl
	//     .to("#cursor", {rotate: "0%", duration: 0.2});
	// }
	return (
		// <div onClick={stoneIsClicked} className='stone' id={`stone-${id}`} onMouseEnter={handleHammerRise} onMouseMove={handleHammerRiseHold} onMouseLeave={handleHammerDown}>
		<div onClick={stoneIsClicked} className="stone-htw" id={`stone-${id}`}>
			{isBroken ? (
				<img src={stoneBrakeGif} width="100%" height="100%" alt="stonebreak" />
			) : (
				<img src={stoneImg} width="100%" height="100%" alt="stone" className="stoneImg" />
			)}
			<div className="hintHolder-htw">
				{!isBroken && <span className="stone-text-htw">{value.npword}</span>}
				<div
					className="imageHolder-htw"
					id={`imgHolder-${id}`}
					style={{
						opacity: 0,
						top: "60%",
						transform: "scale(0)",
					}}
				>
					<img src={value.image} alt={value.key} className="img" id="img" width="100%" height="100%" />
				</div>
			</div>
		</div>
	)
}

export default Stone
