const audios = require.context("../audios", true)
const images = require.context("../images", true)

export const level3_words = [
	"बाँदर",
	"दाँत",
	"हाँगा",
	"ताँती",
	"बाँस",
	"हिउँ",
	"भाँडा",
	"औँठी",
	"चाँदी",
	"कैँची",
	"गैँडा",
	"साइकल",
	"हेडफोन",
	"बाख्रा",
	"उपियाँ",
	"भँगेरा",
	"पानीहाँस",
	"खानेकुरा",
	"सूर्य",
]

export const level3_hintImages = {
	बाँदर: [images("./badar.png"), images("./badar.png"), audios("./badar.ogg")],
	दाँत: [images("./daat.png"), images("./daat.png"), audios("./daat.ogg")],
	हाँगा: [images("./haga.png"), images("./haga.png"), audios("./haga.ogg")],
	ताँती: [images("./tati.png"), images("./tati.png"), audios("./tati.ogg")],
	बाँस: [images("./baas.png"), images("./baas.png"), audios("./baas.ogg")],
	हिउँ: [images("./hiu.png"), images("./hiu.png"), audios("./hiu.ogg")],
	भाँडा: [images("./veda.png"), images("./veda.png"), audios("./veda.ogg")],
	औँठी: [images("./authi.png"), images("./authi.png"), audios("./authi.ogg")],
	चाँदी: [images("./chadi.png"), images("./chadi.png"), audios("./chadi.ogg")],
	कैँची: [images("./kaichi.png"), images("./kaichi.png"), audios("./kaichi.ogg")],
	गैँडा: [images("./gaida.png"), images("./gaida.png"), audios("./gaida.ogg")],
	साइकल: [images("./cycle.png"), images("./cycle.png"), audios("./cycle.ogg")],
	हेडफोन: [images("./headphone.png"), images("./headphone.png"), audios("./headphone.ogg")],
	बाख्रा: [images("./bakhra.png"), images("./bakhra.png"), audios("./bakhra.ogg")],
	उपियाँ: [images("./upiya.png"), images("./upiya.png"), audios("./upiya.ogg")],
	भँगेरा: [images("./vangera.png"), images("./vangera.png"), audios("./vangera.ogg")],
	पानीहाँस: [images("./panihaas.png"), images("./panihaas.png"), audios("./panihaas.ogg")],
	खानेकुरा: [images("./khanekura.png"), images("./khanekura.png"), audios("./khanekura.ogg")],
	सूर्य: [images("./surya.png"), images("./surya.png"), audios("./surya.ogg")],
}
