const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./pigeon-eating-saag.gif"),
			class: "image-five",
			imgdiv: "imageCover lightbluebg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center purpletext",
			textIndivData: [
				{
					textData: "परेवाले खायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./22.ogg"),
			delay: 500,
		},
		{
			url: audios("./a22.ogg"),
			delay: 150,
			isBg: true,
		},
	],
}

export default content
