import { css } from "@emotion/core"
import { appear, disappear } from "styledElements/keyframes"

const content = {
	imageContainerClass: "position-absolute",
	imageContainerStyles: {
		backgroundColor: "#dbc6a2",
	},
	images: [
		{
			src: "city/man_breaking_bird_home3.gif",
			class: "man-p13",
			styles: {
				left: "5%",
				top: "0",
				height: "100%",
				width: "33%",
				transform: "scale(1.18)",
				transformOrigin: "top",
				animation: `${disappear} 0.000001s linear 13.4s forwards`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "city/man_breaking_bird_home.png",
			class: "man-p13",
			styles: {
				left: "5%",
				top: "0",
				height: "100%",
				width: "33%",
				transform: "scale(1.18)",
				transformOrigin: "top",
				opacity: "0",
				animation: `${appear} 0.000001s linear 13.4s forwards`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "city/broom.png",
			styles: {
				width: "12%",
				top: "auto",
				left: "auto",
			},
		},
		{
			src: "city/birds_fly_from-window.gif",
			styles: {
				width: "33%",
				top: "16%",
				left: "auto",
				right: "0",
				animation: `${disappear} 0.0001s linear 4.5s forwards`,
			},
		},
		{
			src: "city/birds_fly_from-window.png",
			styles: {
				width: "33%",
				top: "16%",
				left: "auto",
				right: "0",
				opacity: "0",
				animation: `${appear} 0.0001s linear 4.5s forwards`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: css`
				top: 18%;
				left: 36%;
				background: rgba(255, 255, 255, 0.8);
				border-radius: 15px;
				font-size: 40px;

				@media screen and (max-width: 1920px) {
					font-size: 40px;
				}
				@media screen and (max-width: 900px) {
					font-size: 35px;
					padding: 10px;
				}
				@media screen and (max-width: 760px) {
					font-size: 30px;
					padding: 10px;
				}
				@media screen and (max-width: 530px) {
					font-size: 25px;
				}
				@media screen and (max-width: 380px) {
					font-size: 20px;
				}
			`,
			textIndivData: [
				{
					textData: "मानिसले गुँड भत्काए",
					textClass: "m-0 p3-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "चरि जोडिलाई धपाए",
					textClass: "m-0 p3-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p11.ogg",
			delay: 500,
		},
	],
}

export default content
