const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 bg-white",
	images: [
		{
			src: images("./FriendsP5/kopila_sad_girl.gif"),
			class: "image-one kopila_sad_girl-p5",
			imgdiv: "imageCover",
		},

		{
			src: images("./FriendsP5/frontGirl.gif"),
			class: "image-one FrontGirl-gif-p5",
			imgdiv: "imageCover",
		},
		{
			src: images("./FriendsP5/frontGirl.png"),
			class: "image-one FrontGirl-png-p5",
			imgdiv: "imageCover",
		},
		{
			src: images("./FriendsP5/frontBoy.gif"),
			class: "image-one FrontBoy-gif-p5",
			imgdiv: "imageCover",
		},
		{
			src: images("./FriendsP5/frontBoy.png"),
			class: "image-one FrontBoy-png-p5",
			imgdiv: "imageCover",
		},

		// {
		// 	src: images('./outline-sm-white.png'),
		// 	class: 'image-one outline-p5',
		// 	imgdiv: 'imageCover',
		// },
		{
			src: images("./outline-sm-white.png"),
			class: "image-one outline-p52",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p5 textbox textbox-ban two-lines-texts",
			textIndivData: [
				{
					textData: '"पुतली खेल्न छाडेर को किताब पढ्छ?"',
					textClass: "m-0 text-p5a textStyles",
					timestamps: [
						{ start: 0 },
						{ start: 0.4 },
						{ start: 0.8 },
						{ start: 1.2 },
						{ start: 1.4 },
						{ start: 1.8 },
						{ start: 2.2 },
					],
				},
				{
					textData: '"जाऊ तिमी कतै गएर पढ।"',
					textClass: "m-0 one-line text-p5b textStyles",
					timestamps: [{ start: 3.5 }, { start: 4.0 }, { start: 4.3 }, { start: 4.6 }, { start: 5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s5.ogg"),
			delay: 4500,
		},
	],
}

export default content
