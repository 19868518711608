const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background1.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./boat.png"),
			class: "image-one imageCenter  element1 imgBoat",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./hand.png"),
			class: "image-one imageCenter element2 imgSize imghand",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./smile.png"),
			class: "image-one imageCenter element3 imgSize imghand",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./yellow.png"),
			class: "image-one imageCenter element4 imgSize imgyellow",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./banana_temp.png"),
			class: "image-one imageCenter element5 imgSize imgbananatemp",
			imgdiv: "imageCenterDiv h-65 _mt-1",
		},
		{
			src: images("./banana.gif"),
			class: "image-one imageCenter element6 imgSize imgbanana",
			imgdiv: "imageCenterDiv  h-65 _mt-1",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म एक्लै हुँदा डुँगा जस्तै देखिन्छु।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.4 }, { start: 0.8 }, { start: 1.4 }, { start: 1.9 }, { start: 2.2, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "साथीहरूसँग हुँदा हात जस्तै देखिन्छु।",
					textClass: "m-0",
					timestamps: [{ start: 8.8 }, { start: 9.6 }, { start: 10.7 }, { start: 11.06 }, { start: 11.37, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म कहिले काहिँ मुस्कान जस्तै देखिन्छु।",
					textClass: "m-0",
					timestamps: [
						{ start: 20 },
						{ start: 20.2 },
						{ start: 20.6 },
						{ start: 21.3 },
						{ start: 21.99 },
						{ start: 22.38, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मेरो रङ पहेँलो छ।",
					textClass: "m-0",
					timestamps: [{ start: 31 }, { start: 31.3 }, { start: 32.1 }, { start: 32.3, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element5 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 42 },
						{ start: 42.2 },
						{ start: 42.5 },
						{ start: 43.1 },
						{ start: 43.4 },
						{ start: 43.5 },
						{ start: 43.5, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element6 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म केरा हुँ।",
					textClass: "m-0",
					timestamps: [{ start: 50 }, { start: 51 }, { start: 51.4, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p1a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p1a2.wav"),
			delay: 13000,
		},
		{
			url: audios("./p1a3.wav"),
			delay: 24000,
		},
		{
			url: audios("./p1a4.wav"),
			delay: 35000,
		},
		{
			url: audios("./p1a5.wav"),
			delay: 46000,
		},
		{
			url: audios("./p1a6.wav"),
			delay: 55000,
		},
	],
}

export default content
