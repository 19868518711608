// configs
const compareObjects = (o1, o2) => {
	for (let p in o1) {
		if (o1.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false
			}
		}
	}

	for (let p in o2) {
		if (o2.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false
			}
		}
	}
	return true
}

export const reducer = (state, action) => {
	let newState
	switch (action.type) {
		case "SET":
			newState = {
				...state,
				...action.values,
			}
			break

		case "RESET":
			newState = {
				...state,
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				levelComplete: false,
				stage: 0,
			}
			break

		case "RESET_STAGE":
			newState = {
				...state,
				...action.values,
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				levelComplete: false,
			}
			break

		case "LOADING_COMPLETE":
			newState = {
				...state,
				loadingAssets: false,
			}
			break
		case "START_GAME":
			newState = {
				...state,
				currentPage: "GAME_START",
			}
			break

		case "START_PLAY":
			newState = {
				...state,
				intro: false,
				playing: true,
				stageComplete: false,
			}
			break

		case "STAGE_COMPLETE":
			newState = {
				...state,
				intro: false,
				playing: false,
				stageComplete: true,
			}
			break

		case "REWARD_COMPLETE":
			newState = {
				...state,
				rewardComplete: true,
				letsWalk: false,
				completedStages: [...state.completedStages, action.values],
			}
			break
		case "LAST_PAGE_COMPLETE":
			newState = {
				...state,
				rewardComplete: true,
			}
			break
		case "PREV_STAGE":
			localStorage.setItem("whoseVoiceLevel", state.stage - 1)

			newState = {
				...state,
				intro: true,
				playing: true,
				stageComplete: false,
				rewardComplete: false,
				stage: state.stage - 1,
				allowAnswering: true,
				letsWalk: false,
			}
			break

		case "NEXT_STAGE":
			localStorage.setItem("whoseVoiceLevel", state.stage + 1)

			newState = {
				...state,
				intro: true,
				playing: true,
				stageComplete: false,
				rewardComplete: false,
				stage: state.stage + 1,
				allowAnswering: true,
				letsWalk: false,
			}

			break
		case "POPUP_COMPLETE":
			newState = {
				...state,
				popUp: true,
			}
			break

		case "DEMO_COMPLETE":
			newState = {
				...state,
				demo: false,
			}
			break

		case "RIGHT_ANSWER":
			newState = {
				...state,
				modalOpen: true,
				allowAnswering: false,
			}
			break
		case "RESET_ALL":
			newState = {
				...state,
				letsWalk: false,
			}
			break
		case "MODAL_CLOSED":
			newState = {
				...state,
				modalOpen: false,
				letsWalk: true,
			}
			break
		case "MODAL_OPEN":
			newState = {
				...state,
				modalOpen: true,
			}
			break
		case "REFRESH_GAME":
			localStorage.setItem("whoseVoiceLevel", 0)
			newState = {
				...state,
				...action.values,
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				levelComplete: false,
				stage: 0,
				letsWalk: false,
				allowAnswering: true,
				modalOpen: false,
			}
			break
		default:
			newState = state
	}
	if (compareObjects(newState, state)) {
		return state
	} else {
		return newState
	}
}
