export default [
	{
		key: "baaj",
		word: "बाज",
		options: ["काज", "आज", "ताज", "लाज"],
	},
	{
		key: "mitra",
		word: "मित्र",
		options: ["पत्र", "चित्र", "भित्र", "तित्रा"],
	},
	{
		key: "chithi",
		word: "चिठी",
		options: ["मिठी", "ठिटी", "गिट्टी", "हिटी"],
	},
	{
		key: "chataki",
		word: "चटकी",
		options: ["मटकी", "भटकी", "चकटी", "लडकी"],
	},
	{
		key: "amba",
		word: "अम्बा",
		options: ["खम्बा", "रम्भा", "जुम्बा", "टिम्बा"],
	},
	{
		key: "aurja",
		word: "ऊर्जा",
		options: ["पुर्जा", "बुर्जा", "दर्जा", "कर्जा"],
	},
	{
		key: "dharko",
		word: "धर्का",
		options: ["मर्का", "लर्का", "पिर्का", "सिर्का"],
	},
	{
		key: "gatta",
		word: "गट्टा",
		options: ["भट्टा", "लट्टा", "जट्टा", "ठट्टा"],
	},
	{
		key: "ghaila",
		word: "घैला",
		options: ["मैला", "दैला", "खैला", "लैला"],
	},
	{
		key: "chungi",
		word: "चुङ्गी",
		options: ["मुंगी", "रङ्गी", "लुङ्गी", "ढुङ्गी"],
	},
	{
		key: "fohar",
		word: "फोहर",
		options: ["मोहर", "पोहोर", "सोहोर", "गोबर"],
	},
	{
		key: "jaal",
		word: "जाल",
		options: ["हाल", "ताल", "माल", "फाल"],
	},
	{
		key: "chang",
		word: "चाङ",
		options: ["दाङ", "भाङ", "आङ", "झाङ"],
	},
	{
		key: "saag",
		word: "साग",
		options: ["दाग", "दाद", "भाग", "माग"],
	},
	{
		key: "kachya",
		word: "कक्षा",
		options: ["रक्षा", "पक्ष", "दीक्षा", "भिक्षा"],
	},
	{
		key: "khuti",
		word: "कुटी",
		options: ["टुटी", "झुटी", "ठुटी", "फुटी"],
	},
]
