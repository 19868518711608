import { appear } from "styledElements/keyframes"
import { css } from "@emotion/core"

const content = {
	images: [
		{
			src: "hill/bg_page19.jpg",
		},
		{
			src: "birds/bird_making_home_02.gif",
			styles: {
				width: "5.5%",
				top: "21%",
				left: "86%",
				height: "18%",
			},
		},
		{
			src: "birds/bird_making_home_01.gif",
			styles: {
				width: "8%",
				height: "18%",
				top: "18%",
				left: "92%",
			},
		},

		{
			src: "hill/nest.png",
			styles: {
				width: "8%",
				height: "18%",
				top: "25%",
				left: "89%",
			},
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text p10-text",
			styles: css`
				left: 25%;
				padding: 15px;
				top: 25%;
				background: rgba(255, 255, 255, 0.8);
				border-radius: 15px;
				animation: ${appear} 0.5s ease-out normal both;
				@media screen and (max-width: 1920px) {
					font-size: 40px;
				}
				@media screen and (max-width: 900px) {
					font-size: 35px;
					padding: 10px;
				}
				@media screen and (max-width: 760px) {
					font-size: 30px;
					padding: 10px;
				}
				@media screen and (max-width: 530px) {
					left: 7%;
					font-size: 25px;
				}
				@media screen and (max-width: 380px) {
					font-size: 20px;
				}
			`,
			textIndivData: [
				{
					textData: "अग्लो भिरको कापमा",
					textClass: "m-0 p10-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "रमाए जोडी आपसमा",
					textClass: "m-0 p10-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p15.ogg",
			delay: 500,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			volume: 1,
			loop: true,
			isBg: true,
		},
	],
}

export default content
