const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_page_14.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./peacock_dancing-min.gif"),
			class: "image-one img14",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page18Text text-center",
			textIndivData: [
				{
					textData: "समय सँगै मयूूर नाच्‍न सक्‍ने भयो। मयूर नाचेको देखेेर सबै दङ्ग परे।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.75 },
						{ start: 1.2 },
						{ start: 1.55 },
						{ start: 1.95 },
						{ start: 2.35 },
						{ start: 3.5 },
						{ start: 3.85 },
						{ start: 4.3 },
						{ start: 5.1 },
						{ start: 5.5 },
						{ start: 5.95 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./18.ogg"),
			delay: 500,
		},
		{
			url: audios("./peacock.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.3,
		},
	],
}

export default content
