const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover zoomoutclouds",
		},
		{
			src: images("./bg_Page01.jpg"),
			class: "image-one page2",
			imgdiv: "imageCover zoomoutclouds",
		},
		{
			src: images("./mum-kids01.png"),
			class: "image-one mum-kids01",
			imgdiv: "imageCover zoomoutclouds",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain_new",
			imgdiv: "imageCover zoomoutclouds",
		},
		{
			src: images("./thunder_storm.gif"),
			class: "image-one thunder",
			imgdiv: "imageCover zoomoutclouds",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox2 text_box-p2",
			textIndivData: [
				{
					textData: "“आमा! आमा! जाडो भयो।” छोराछोरीहरू कराए।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1.0 }, { start: 1.7 }, { start: 2.3 }, { start: 3.3 }, { start: 4.1 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 9500,
		},
		{
			url: audios("./bg1.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
