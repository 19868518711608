import words from "./words"
import shuffle from "shuffle-array"
const audioHints = require.context("../audios/words", true)
const imageHints = require.context("../images/hints", true)

let finalObject = words
	.map((levelWords, idx) => {
		return levelWords.map((word) => {
			let imageHint, audioHint, options
			try {
				imageHint = imageHints(`./${word.key}.png`)
			} catch (error) {
				console.log(`not found: imageHint: ${word.key}.png`)
				imageHint = null
			}
			try {
				audioHint = audioHints(`./${word.key}/hint.ogg`)
			} catch (error) {
				console.log(`not found: audioHint: ${word.key}/hint.ogg`)
				audioHint = null
			}
			options = word.options.map((option, i) => {
				let optionAudioHint
				try {
					optionAudioHint = audioHints(`./${word.key}/option-${i + 1}.ogg`)
				} catch (error) {
					console.log(`not found: optionAudioHint: ${word.key}/option-${i + 1}.ogg`)
					optionAudioHint = null
				}
				return {
					word: option,
					audio: optionAudioHint,
				}
			})
			return {
				level: idx + 1,
				word: word.word,
				key: word.key,
				options: shuffle(options).slice(0, 3),
				hints: {
					image: imageHint,
					audio: audioHint,
				},
			}
		})
	})

	.reduce((prev = [], current) => {
		return prev.concat(current)
	})

// ...data,
// options,
// hints: {
//     image: imageHint,
//     audio: audioHint
// }

export default finalObject
