const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover zoomoutpage12",
		},
		{
			src: images("./background_02.jpg"),
			class: "image-five",
			imgdiv: "imageCover zoomoutpage12",
		},
		{
			src: images("./page12_mum_kids.png"),
			class: "image-five mum-kids05",
			imgdiv: "imageCover zoomoutpage12",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain12",
			imgdiv: "imageCover zoomoutpage12",
		},
		{
			src: images("./thunder_storm.gif"),
			class: "image-one thunderp12",
			imgdiv: "imageCover zoomoutpage12",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox12 text_box-p12",
			textIndivData: [
				{
					textData: "“आमा! आमा! फेरि जाडो भयो।” उनिहरू त रून पो थाले!",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.9 },
						{ start: 1.6 },
						{ start: 2.0 },
						{ start: 2.4 },
						{ start: 3.2 },
						{ start: 3.9 },
						{ start: 4.4 },
						{ start: 4.7 },
						{ start: 5.0 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./12.ogg"),
			delay: 500,
		},
		{
			url: audios("./bg1.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
		{
			url: audios("./rabbit-cry.ogg"),
			delay: 6500,
			isBg: true,
			loop: true,
			volume: 0.2,
		},
	],
}

export default content
