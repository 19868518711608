const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./in_the_bag.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "लौ! झोलाभित्र पनि छैन।",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 2.2 }, { start: 2.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./5.ogg"),
			delay: 500,
		},
	],
}

export default content
