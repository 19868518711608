import { keyframes } from "@emotion/core"

const content = {
	images: [
		{
			src: "forest/bg_page10.jpg",
		},
		{
			src: "forest/nest_fall_page9.gif",
			class: "nest-p9",
			imgdiv: "imageCover",
			styles: {
				width: "100%",
				animation: `${keyframes({
					from: {
						transform: "translate(0%,0%)",
					},
					to: {
						transform: "translate(-13%,62%)",
					},
				})} 4s ease-in 5s forwards`,
			},
		},
		// {
		//     src: 'forest/birds_page10.png',
		//     class: "birds-p9",
		//     imgdiv: "imageCover",
		// },
		{
			src: "birds/bird_fly_page9_01.gif",
			class: "birds-p9",
			imgdiv: "imageCover",
			styles: {
				width: "80%",
				left: "77%",
				top: "53%",
				animation: `${keyframes({
					from: {
						transform: "translate(0%, 0%)",
					},
					to: {
						transform: "translate(-350%,-150%)",
					},
				})} 7s ease-in 3s infinite`,
			},
		},
		{
			src: "birds/bird_fly_page9_02.gif",
			class: "birds-p9",
			imgdiv: "imageCover",
			styles: {
				width: "80%",
				left: "-49%",
				top: "-55%",
				animation: `${keyframes({
					from: {
						transform: "translate(0%, 0%)",
					},
					to: {
						transform: "translate(317%,128%)",
					},
				})} 8s ease-in 3s infinite`,
			},
		},
		// {
		// 	src: 'forest/leaf.png',
		// 	styles: {
		// 		width: "100%",
		// 		top: "10%",
		// 		left: "95%",
		// 	},
		// 	class: "leaf-p9",
		//     imgdiv: "imageCover",
		// },
		{
			src: "forest/leaf02.png",
			styles: {
				width: "100%",
				top: "54%",
				left: "32%",
				transform: "translateX(80%) rotate(45deg)",
				animation: `${keyframes({
					from: {
						transform: "translate(80%, -150%) rotate(20deg)",
					},
					to: {
						transform: "translate(-156%, 150%) rotate(20deg)",
					},
				})} 8s linear 1 `,
			},

			imgdiv: "imageCover",
		},
		{
			src: "forest/leaf01.png",
			styles: {
				width: "100%",
				top: "52%",
				left: "32%",
				transform: "translateX(20%) rotate(45deg)",
				animation: `${keyframes({
					from: {
						transform: "translate(80%, 20%) rotate(45deg)",
					},
					to: {
						transform: "translate(-156%, -200%) rotate(45deg)",
					},
				})} 6s linear infinite `,
			},

			imgdiv: "imageCover",
		},
		{
			src: "forest/storm_page10.png",
			class: "storm-p9",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: {
				padding: "15px",
				background: "rgba(255, 255, 255, 0.8);",
				borderRadius: "15px",
				top: "4%",
				left: "4%",
			},
			textIndivData: [
				{
					textData: "हावा हुरि हरर",
					textClass: "m-0 p3-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "गुँड उड्यो झरर",
					textClass: "m-0 p3-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p9.ogg",
			delay: 500,
		},
		{
			url: "wind.ogg",
			delay: 0,
			volume: 0.2,
			loop: true,
			isBg: true,
		},
	],
}

export default content
