import React, { useEffect, useReducer, useState } from "react"

// custom components
import Header from "Components/Layout/Header"
import Footer from "Components/Layout/Footer"
import CoverPage from "./Components/CoverPage"
import ActivityContent from "./Components/ActivityContent"
import LastPage from "./Components/LastPage"

// import { Howl } from "howler"

// tools
import { uuidv4Generator } from "../../utils/uuidv4Generator"

// helpers
import { getPageContent, getStoryData, reducer, preloadPagesImages } from "./ContainerHelpers"

export const DispatchContext = React.createContext(null)

export default function StoryContainerPlus(props) {
	const { match } = props
	// console.log(match);
	const uniqueKey = uuidv4Generator()
	const storyData = getStoryData(match.params.storyKey)
	const currentPage = match.params.page === "start" ? 0 : Number(match.params.page)
	const isCoverPage = currentPage === 0
	const isLastPage = Number(match.params.page) === Number(storyData.totalPages)
	const isContentPage = !isCoverPage

	const [isLoaded, setIsLoaded] = useState(currentPage !== 0)
	const [state, dispatch] = useReducer(reducer, {
		audioMute: localStorage.getItem("audioMute") === "true",
		currentPage: currentPage,
		uniqueKey: uniqueKey,
		isCoverPage: isCoverPage,
		isLastPage: isLastPage,
		isContentPage: isContentPage,
		pageContent: isContentPage ? getPageContent(Number(match.params.page), storyData) : {},
		storyData: storyData,
	})

	const loadAssets = (images, sounds) => {
		sounds.forEach((sound) => {
			// new Howl({
			// 	src: [sound],
			// 	preload: true,
			// 	autoplay: false,
			// })
		})

		// console.log(allImages);
		preloadPagesImages(images, () => {
			if (!isLoaded) {
				setIsLoaded(true)
			}
			// console.log("all page images loaded ----------");
		})
	}

	useEffect(() => {
		let timeout
		const numPreloadPages = state.currentPage === 0 ? 6 : 1
		if (state.currentPage >= 1) {
			document.title = `HRK ::  ${state.storyData.name} :: Page ${state.currentPage}`
		}
		if (state.currentPage <= Number(state.storyData.totalPages) - numPreloadPages) {
			// console.log('preload');
			let pageContent
			let allImages = []
			let allSounds = []
			for (let i = 0; i < numPreloadPages; i++) {
				pageContent = getPageContent(state.currentPage + i + 1, storyData)
				allImages[i] = pageContent.imageList ? pageContent.imageList.map((image) => image.src) : []
				if (pageContent.soundList && pageContent.soundList.length) {
					pageContent.soundList.forEach((sound) => {
						allSounds[i] = sound.url
					})
				}
			}

			if (state.currentPage === 0) {
				loadAssets(allImages, allSounds)
			} else {
				timeout = setTimeout(() => {
					loadAssets(allImages, allSounds)
				}, 1200)
			}
		}

		if (timeout) {
			return () => {
				clearTimeout(timeout)
			}
		}
	})

	return (
		<div className="activity__container">
			<Header storyName={state.storyData.name} />
			<main className="main__content">
				<div id="activityBox" className={`activity__frame ${match.params.storyKey}-box`}>
					<DispatchContext.Provider value={dispatch}>
						{state.isCoverPage && <CoverPage disabled={false} story={state.storyData} isLoaded={isLoaded} />}
						<ActivityContent
							key={state.uniqueKey}
							disabled={!state.isContentPage}
							pageContent={state.pageContent}
							story={state.storyData}
							currentPage={state.currentPage}
							audioMute={state.audioMute}
						/>

						{state.currentPage === Number(storyData.totalPages) && (
							<LastPage
								onLastPageReached={() => {
									console.log("Last page reached")
								}}
								onListenReadBtnClick={() => {
									dispatch({ type: "RESTART_LISTEN" })
								}}
								onReadBtnClick={() => {
									dispatch({ type: "RESTART_READ" })
								}}
							/>
						)}
					</DispatchContext.Provider>
				</div>
			</main>
			<Footer isHidden={!state.isCoverPage} donner="true" />
		</div>
	)
}
