import React, { useContext } from "react"

import CustomModal from "../../components/LevelCompleteModal/CustomModal"
// import Modal from 'react-modal';

// assets
import "./stage.styles.scss"
import Hint from "../../components/Hint/Hint"
import Options from "../../components/Options/Options"
import Toolbar from "../../components/Toolbar/Toolbar"

import { getLevelData } from "../../helpers/helpers"

import { StateContext } from "../../BalloonPopGame"

const Stage = (props) => {
	const assets = require.context("./assets")
	const state = useContext(StateContext)
	// const dispatch = useContext(DispatchContext);

	React.useEffect(() => {
		const preloadImages = (urls, allImagesLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadImage = (url, anImageLoadedCallback) => {
				let img = new Image()
				img.onload = anImageLoadedCallback
				img.src = url
			}

			urls.forEach(function (url) {
				preloadImage(url, function () {
					loadedCounter++
					// console.log('Number of loaded images: ' + loadedCounter);
					if (loadedCounter === toBeLoadedNumber) {
						allImagesLoadedCallback()
					}
				})
			})
		}

		let images = []
		if (state.levelData[state.stage].hints && state.stage < state.levelData.length - 1) {
			images = images.concat([state.levelData[state.stage].hints.image, state.levelData[state.stage + 1].hints.image])
		}

		preloadImages(images, () => {
			// console.log("preloaded");
		})
	})

	// console.log("stage Redraw reupdate");
	return (
		<StateContext.Consumer>
			{(state) => {
				if (state === undefined) {
					throw new Error("StateConsumer must be used within a StateProvider")
				}
				let stageData = state.levelData[state.stage]
				return (
					<div
						className="game-stage"
						style={{
							backgroundImage: `url(${assets(`./${state.bg}`)})`,
						}}
					>
						<Hint image={stageData.hints.image} audio={stageData.hints.audio} key={`hint-${state.level}-${state.stage}`} />
						{state.playing && <Options key={`options-${state.level}-${state.stage}`} />}
						<Toolbar totalPage={getLevelData(state.level).length} currentPage={state.stage} />

						<CustomModal
							isOpen={state.levelComplete}
							// isOpen={state.stageRewardComplete}
							ariaHideApp={false}
							isOpenInFlutter={props.isOpenedinFlutter}
						/>
					</div>
				)
			}}
		</StateContext.Consumer>
	)
}

// Stage.whyDidYouRender = true;
// const StageMemo = React.memo(Stage);
// StageMemo.whyDidYouRender = true;

export default Stage
