const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-long ",
	images: [
		{
			src: images("./13to24/bg32a.png"),
			imgdiv: "imageCover w-100 position-bottom-intact",
			styles: { top: "auto", zIndex: 10, transform: "translateY(50%)" },
		},
		{
			src: images("./13to24/stars.png"),
			imgdiv: "imageCover star star-1 ",
			styles: { opacity: 1 },
		},
	],
	texts: [
		{
			textDivClass: "story-text w-75 end-suspense",
			textIndivData: [
				{
					textData: "तिम्रो मिल्ने साथीसँग तिमी नबोली बस्‍न सक्छौ होला?",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.4 },
						{ start: 1 },
						{ start: 2 },
						{ start: 2.45 },
						{ start: 3 },
						{ start: 3.3 },
						{ start: 3.8 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p3_s4.ogg"),
			delay: 500,
		},
	],
}

export default content
