const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./goat-in-leather-jacket.gif"),
			class: "image-five",
			imgdiv: "imageCover lightgreenbg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center blacktext",
			textIndivData: [
				{
					textData: "खसीले छालाको",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.45 }, { start: 2.35 }],
				},
				{
					textData: "ज्याकेट लगायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 3.1 }, { start: 4 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./34.ogg"),
			delay: 500,
		},
	],
}

export default content
