import { abort } from "process";
import React from "react"

import normalIcon from "../icons/controls/next_blue.svg"
import successIcon from "../icons/controls/next_green.svg"
import disabledIcon from "../icons/controls/next_grey.svg"
import "../styles.scss";

import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

function ValueLabelComponent(props) {
	const { children, open, value } = props;
  
	return (
	  <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
		{children}
	  </Tooltip>
	);
}

const PrettoSlider = styled(Slider)({
	// color: '#52af77',
	height: 10,
	// width: `${props.currentMovie.progress+"%"}`,
	// background:"red", 
	position:"unset", 
	// height:"100%",
	'& .MuiSlider-track': {
	  border: 'none',
	},
	'& .MuiSlider-thumb': {
	  height: 20,
	  width: 20,
	  backgroundColor: '#cbcbcb',
	  border: '2px solid currentColor',
	  '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
		boxShadow: 'inherit',
	  },
	  '&:before': {
		display: 'none',
	  },
	},
	'& .MuiSlider-valueLabel': {
	  lineHeight: 1.2,
	  fontSize: 12,
	  background: 'unset',
	  padding: 0,
	  width: 32,
	  height: 32,
	  borderRadius: '50% 50% 50% 0',
	  backgroundColor: '#52af77',
	  transformOrigin: 'bottom left',
	  transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
	  '&:before': { display: 'none' },
	  '&.MuiSlider-valueLabelOpen': {
		transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
	  },
	  '& > *': {
		transform: 'rotate(45deg)',
	  },
	},
  });

const ProgressBar = (props) => {
	const className = props.className || "next__btn"
	return (
		// <span
		// 	key="nxtBtn"
		// 	onClick={
		// 		props.disabled
		// 			? () => {
		// 					return
		// 			  }
		// 			: props.ProgressBarClick
		// 	}
		// 	className={props.disabled ? `${className} disabled` : className}
		// >
		// 	<img className="img-fluid" src={props.success ? successIcon : props.disabled ? disabledIcon : normalIcon} alt="next" />
		
        // </span>
		<>
        <div className="progressline"
        value={props.progresslineClick}
		onAbort={
			props.disabled ? () => {
				return
			}
			: props.loadandclick
		}
		// onClick={props.checkWidth}
		// ref={props.progresslineClick}
        >
        {/* <div className={props.className}  style={{width: `${props.currentMovie.progress+"%"}`, background:"red", position:"absolute", height:"100%"}}> */}
		{/* <PrettoSlider
        	// valueLabelDisplay="auto"
        	// aria-label="pretto slider"
			min={0}
			max={100}
        	value={props.currentMovie.progress}
			// value = {props.played * 100}
			// ValueLabelComponent={(props) => (
			// 	<ValueLabelComponent {...props} value={props.elapsedTime}/>
			// )}
			// onChange = {props.onSeek}
			// onMouseDown = {props.onSeekMouseDown}
			// onChangeCommitted = {props.onSeekMouseUp}
      	/> */}
		{/* </div> */}
        </div>
		<PrettoSlider
		// valueLabelDisplay="auto"
		aria-label="pretto slider"
		min={0}
		max={100}
		defaultValue={(props.played) * 100}
		// value={props.currentMovie.progress}
		// value = {(props.played) * 100}
		// ValueLabelComponent= {ValueLabelComponent}
		// ValueLabelComponent={(props) => (
		// 	<ValueLabelComponent {...props} value={props.elapsedTime}/>
		// )}
		// onChange = {props.onSeek}
		onMouseDown = {props.onSeekMouseDown}
		onChangeCommitted = {props.onSeekMouseUp}
	  />
	  </>
	)
}

export default ProgressBar
