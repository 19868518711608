const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background9.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./ball.png"),
			class: "image-one imageCenter element1 imgSize imgball",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./sun.png"),
			class: "image-one imageCenter element2 imgSize imgsun",
			imgdiv: "imageCenterDiv",
		},

		{
			src: images("./pomello_un.png"),
			class: "image-one imageCenter element3 imgSize pomello_un",
			imgdiv: "imageCenterDiv _h-75 __mt-1",
		},
		{
			src: images("./pomello.gif"),
			class: "image-one imageCenter element4 imgSize imgwatermelon",
			imgdiv: "imageCenterDiv _h-85 __mt-5",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म भकुण्डो जस्तो गोलो देखिन्छु।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.4 }, { start: 0.8 }, { start: 1.4 }, { start: 1.9, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मेरो रङ सूर्य जस्तो पहेँलो छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 8.7 },
						{ start: 9.2 },
						{ start: 10.3 },
						{ start: 10.66 },
						{ start: 11 },
						{ start: 11.5, last: true },
					],
				},
			],
		},

		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 20 },
						{ start: 20.2 },
						{ start: 20.5 },
						{ start: 21.1 },
						{ start: 21.4 },
						{ start: 21.5 },
						{ start: 21.5, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म भोगटे हुँ।",
					textClass: "m-0",
					timestamps: [{ start: 31 }, { start: 31.1 }, { start: 31.5, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p9a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p9a2.wav"),
			delay: 13000,
		},
		{
			url: audios("./p9a3.wav"),
			delay: 24000,
		},
		{
			url: audios("./p9a4.wav"),
			delay: 35000,
		},
	],
}

export default content
