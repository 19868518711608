import React, { Component } from "react"
import { Link } from "react-router-dom"
import { getQueryVariable } from "../mainHelper"
import { getLevelWords, getLevelHintImages } from "./helper/helpers.js"
import ConfigContent from "EGRContent/Play/RecorderPlay/Content"
import IconHome from "assets/icons/home.svg"
import IconMenulist from "assets/icons/back_to_list.svg"
import Logo from "assets/ole_logo.png"
import LastPage from "../LastPage"
import gsap from "gsap"
import NextBtn from "../../../Components/NextBtn"
// import PrevBtn from "../../../Components/PrevBtn";
import { Howl, Howler } from "howler"
import PageNumber from "Components/PageNumber"
import RecorderButtons from "./RecorderButtons"
import RecorderHint from "./RecorderHint"
import AudioiconImage from "./AudioiconImage"
import Sound from "react-sound"
const shuffle = require("shuffle-array")
var audioType = "audio/webm"

export class RecorderGame extends Component {
	state = {
		gameContent: ConfigContent.contentList,
		level: getQueryVariable("level") || "Easy",
		levelWords: [],
		currentPage: 0,
		showNextBtn: false,
		showPrevBtn: false,
		isLast: false,
		activityKey: this.props.activityKey,
		// totalPage: ConfigContent.totalPage,
		totalPage: 1,
		audios: [],
		playSoundStatus: Sound.status.STOPPED,
		isRecording: false,
		// queryText: ConfigContent.wordBank,
		queryText: [],
		queryaudio: "",
	}

	constructor(props) {
		super(props)
		this.gameContentRef = React.createRef()
	}

	componentDidMount = () => {
		// this.initialize();
		let gameContentDiv = this.gameContentRef.current

		let playButton = gameContentDiv.querySelector(".play")
		if (!playButton.classList.contains("d-none")) {
			playButton.classList.add("d-none")
		}
		let levelWords = getLevelWords(this.state.level)
		this.setState((prevState) => ({
			currentPage: prevState.currentPage + 1,
			showNextBtn: false,
			gameContent: ConfigContent.contentList,
			correctanswer: "",
			// queryText: ConfigContent.wordBank,
			levelWords: levelWords,
			queryText: levelWords,
			totalPage: levelWords.length + 1,
			isLast: prevState.currentPage + 1 === this.state.totalPage ? true : false,
		}))
	}

	UNSAFE_componentWillMount = () => {
		// this.initialize();
	}
	componentDidUpdate(prevProps, prevState) {
		// console.log("logg", this.state);
		if (prevState.currentPage !== this.state.currentPage) {
			if (this.state.currentPage < this.state.totalPage) {
				let gameContentDiv = this.gameContentRef.current
				// console.log("updated state", this.state);
				let playButton = gameContentDiv.querySelector(".play")
				if (!playButton.classList.contains("d-none")) {
					playButton.classList.add("d-none")
				}

				this.setState(
					{
						gameContent: ConfigContent.contentList,

						queryText: this.state.levelWords.splice(this.state.levelWords.indexOf(prevState.queryText[0]), 1),
						levelWords: this.state.levelWords.filter((words) => words !== prevState.queryText[0]),
					},
					() => {
						// console.log(this.state,"cdu");
						this.initialize()
					}
				)
			}
		}

		if (prevState.level !== this.state.level) {
			let levelWords = getLevelWords(this.state.level)
			this.setState({
				currentPage: 0,
				showNextBtn: false,
				gameContent: ConfigContent.contentList,
				correctanswer: "",
				levelWords: levelWords,
				totalPage: levelWords.length + 1,
				showIncentive: false,
				isLast: prevState.currentPage + 1 === this.state.totalPage ? true : false,
			})
		}
	}
	initialize() {
		let query = this.randomizeOptions(this.state.queryText)

		let levelHintImages = getLevelHintImages(this.state.level)
		let levelHintImage = levelHintImages[query[0]][0]
		let levelHintAudio = levelHintImages[query[0]][2]
		let word = new Howl({
			src: [levelHintAudio],
		})

		word.play()
		this.setState({
			queryText: query,
			hintImage: levelHintImage,
			queryaudio: levelHintAudio,
		})
	}

	randomizeOptions = (query) => {
		let queryText = JSON.parse(JSON.stringify(query))
		shuffle(queryText)
		return queryText
	}
	onNextBtnClick = () => {
		Howler.unload()
		// this.initialize();

		let levelWords = getLevelWords(this.state.level)
		this.setState((prevState) => ({
			currentPage: prevState.currentPage + 1,
			showNextBtn: false,
			isLast: prevState.currentPage + 1 === this.state.totalPage ? true : false,
			gameContent: ConfigContent.contentList,
			correctanswer: "",
			queryText: levelWords,
		}))
	}

	onPrevBtnClick = () => {
		Howler.unload()
		let levelWords = getLevelWords(this.state.level)

		this.setState((prevState) => ({
			gameContent: ConfigContent.contentList,
			showIncentive: false,
			currentPage: prevState.currentPage - 1,
			queryText: levelWords,
		}))
	}

	handleClick = (btnid) => {
		// console.log("Clicked" + btnid);
		switch (btnid) {
			case "record":
				this.recordAudioHandler()
				break
			case "play":
				this.clickPlayerHandler()
				break
			case "stop":
				this.stopRecording()
				break

			default:
				break
		}
	}
	recordAudioHandler = (e) => {
		let gameContentDiv = this.gameContentRef.current
		let customProgressBar = document.createElement("div")
		customProgressBar.classList.add("recorder-progress-bar")
		// console.log("customProgressBar", customProgressBar);
		gameContentDiv.append(customProgressBar)

		if (navigator.mediaDevices) {
			navigator.mediaDevices
				.getUserMedia({ video: false, audio: true })
				.then((stream) => {
					let playButton = gameContentDiv.querySelector(".play")
					// console.log(playButton);
					playButton.classList.remove("d-none")

					gsap.to(customProgressBar, 5, {
						width: "100%",
						ease: "none",
					})
					this.mediaRecorder = new MediaRecorder(stream, {
						mimeType: audioType,
					})
					this.chunks = []

					this.mediaRecorder.ondataavailable = (e) => {
						if (e.data && e.data.size > 0) {
							this.chunks.push(e.data)
						}
					}
					this.mediaRecorder.start(10)
					this.setState({
						isRecording: true,
						timerTime: "5",
						curPage: 0,
						playAudio: true,
					})

					// timer to control the recording duration
					let counter = () => {
						this.setState((prevState) => ({
							timerTime: prevState.timerTime - 1,
						}))
						if (this.state.timerTime < 1) {
							this.stopRecording(e)
						}
					}
					this.timer = setInterval(counter, 1000)
					// timer portion ends
					// //////////////////////newly added finished//////////////////////
				})
				.catch((error) => {
					// console.log(this.state.hasMediaAccess);
					// console.log(error.message);
				})
		}
	}

	stopRecording = () => {
		// console.log("stop recording");

		let gameContentDiv = this.gameContentRef.current
		let playButton = gameContentDiv.querySelector(".play")
		// console.log(playButton);
		if (playButton) {
			playButton.classList.remove("d-none")
		}

		let customProgressBar = gameContentDiv.querySelector(".recorder-progress-bar")
		if (customProgressBar) {
			customProgressBar.remove()
		}
		clearInterval(this.timer)
		// stop the recorder
		if (this.state.isRecording) {
			this.mediaRecorder.stop()
			this.setState({
				isRecording: false,
				hasRecorded: true,
				playAudio: true,
			})
			// save the audio to memory
			// console.log('chunks on stop-->'+this.chunks)
			this.saveAudio()
		}
	}

	saveAudio() {
		// console.log("media stopped");
		// convert saved chunks to blob
		const blob = new Blob(this.chunks, { type: audioType })
		// generate audio url from blob
		const audioURL = window.URL.createObjectURL(blob)
		// append audioURL to list of saved audios for rendering
		let audios = this.state.audios.concat([audioURL])
		audios = audios.slice(-1)
		this.setState({ audios })
		// console.log("Saved audios-->" + audios);
	}

	clickPlayerHandler = () => {
		if (this.state.audios.length > 0) {
			// console.log("Sound  ");
			// console.log(this.state.audios);
			this.setState((prevState) => ({
				// ...prevState.playSoundStatus,
				playSoundStatus: Sound.status.PLAYING,
				playAudio: true,
				showNextBtn: true,
				showPrevBtn: prevState.currentPage > 1 ? true : false,
			}))
		}
	}

	HandleAudioFinished = () => {
		this.setState({
			playSoundStatus: Sound.status.STOPPED,
		})
	}

	handleError = (errorCode, description) => {
		console.log("erCode->" + errorCode + "description->" + description)
	}
	selectAudio = (audioUrl) => {
		this.setState(
			{
				audioUrl: audioUrl,
			},
			() => {
				this.handlePlayAudio()
			}
		)
	}

	handlePlayAudio = () => {
		Howler.unload()
		const { audioUrl } = this.state
		new Howl({
			src: [audioUrl],
			autoplay: true,
		})
	}

	openMenuModal = () => {
		this.setState((prevState) => ({ menuOpened: !prevState.menuOpened }))
	}

	resetGame = () => {
		let levelWords = getLevelWords(this.state.level)
		this.setState({
			currentPage: 1,
			showNextBtn: false,
			gameContent: ConfigContent.contentList,
			correctanswer: "",
			// queryText: ConfigContent.wordBank
			queryText: levelWords,
			levelWords: levelWords,
		})

		this.props.resetGame()
		window.location.reload(true)
	}

	render() {
		// console.log(this.state,"state");

		const { gameContent, currentPage, totalPage } = this.state
		const game = ConfigContent
		return (
			<div className="activity__container">
				<header className="inner__header">
					<div className="container">
						<div className="header-content">
							<div className="logo">
								<Link to="/">
									<img src={Logo} className="img-fluid" alt="OLE Nepal" />
								</Link>
							</div>
							<div className="menuIcon">
								<Link to="/play">
									<img src={IconMenulist} className="img-fluids" alt="Home" />
								</Link>
							</div>
							<strong className="page__heading">{this.props.activityName}</strong>
							<div className="menu-right">
								<a href="/" className="menu-item">
									<img src={IconHome} className="img-fluids" alt="Home" />
								</a>
							</div>
						</div>
					</div>
				</header>
				<main className="main__content">
					<div className="recorderplay-container">
						<div id="activityBox" className="activity__frame">
							{currentPage < totalPage ? (
								<div className={"activity_wrap " + gameContent[0].background}>
									<div className="gamecontent recorderplay" ref={this.gameContentRef}>
										<div key={currentPage + "div"} className={game.textDivClass}>
											{game.textIndivData.map((text, index) => (
												<p key={"p" + index} className={text.textClass}>
													{text.textData}
												</p>
											))}
										</div>
										<React.Fragment>
											{gameContent[0].hintImage.map((images, index) => (
												<RecorderHint
													key={index}
													src={this.state.hintImage}
													class={images.class}
													imgdivClass={images.imgdiv}
													currentPage={currentPage}
													index={index}
													activityKey={this.state.activityKey}
													queryText={this.state.queryText[0]}
												/>
											))}
											{gameContent[0].imageList.map((images, index) => (
												<RecorderButtons
													key={index}
													src={images.src}
													class={images.class}
													currentPage={currentPage}
													index={index}
													activityKey={this.state.activityKey}
													isRecording={this.state.isRecording}
													btntext={images.text}
													onClickEvent={() => this.handleClick(images.id)}
													btnClass={images.imgdiv}
												/>
											))}
											{gameContent[0].audioIcon.map((images, index) => (
												<AudioiconImage
													key={currentPage + index}
													currentPage={currentPage}
													index={currentPage}
													imgdivClass={images.imgdiv}
													onClickIcon={() => this.selectAudio(this.state.queryaudio)}
													activityKey={"recorderPlay"}
													class={images.class}
													src={images.src}
												/>
											))}
										</React.Fragment>
										{this.state.audios.map((audioURL, i) => (
											<Sound
												url={audioURL}
												playAudio={true}
												playStatus={this.state.playSoundStatus}
												onFinishedPlaying={() => this.HandleAudioFinished()}
												onError={(errorCode, description) => this.handleError((errorCode, description))}
												muted={"muted"}
												key={"audiokey" + i}
											/>
										))}
									</div>
								</div>
							) : (
								<LastPage resetGame={this.resetGame} openMenuModal={this.openMenuModal} />
							)}
							<div className="gameactivity__nav">
								<div className="row align-items-center">
									<div className="col-6">
										<PageNumber totalPages={this.state.totalPage - 1} currentPage={this.state.currentPage - 1} />
									</div>
									<div className="col-6 text-right">
										{/* <PrevBtn disabled={this.state.currentPage === 0 || !this.state.showNextBtn} prevBtnClick={() => this.onPrevBtnClick(this.state.totalPage)} /> */}
										<NextBtn
											disabled={!this.state.showNextBtn}
											nextBtnClick={() => this.onNextBtnClick(this.state.totalPage)}
										/>
										{/* for debugging  */}
										{/* <NextBtn
										disabled={false}
										nextBtnClick={() =>
											this.onNextBtnClick(this.state.totalPage)
										} /> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
			</div>
		)
	}
}

export default RecorderGame
