import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	images: [
		{
			src: images("./kids/bg_page8.png"),
		},
		{
			src: images("./flag_long.gif"),
			styles: {
				width: "100%",
				top: "0%",
				left: "0%",
			},
		},

		{
			src: images("./boy_with_dog.gif"),
			class: "image-10 boywithdog14",
			imgdiv: "imageCover",
		},
		{
			src: images("./kids/page-8-girl01.svg"),
			toSvg: true,
			styles: {
				top: "41%",
				left: "34%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl1",
		},
		{
			src: images("./kids/page-8-boy.svg"),
			toSvg: true,
			styles: {
				top: "39.2%",
				left: "52%",
				width: "22%",
			},
			imgdiv: "imageCover page8boy",
		},

		{
			src: images("./kids/page-8-girl02.svg"),
			toSvg: true,
			styles: {
				top: "42%",
				left: "72.5%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl2",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center dialogueBubble dialogueBubble--page13boy topBubble ",
			// textDivClass: 'story-text page10Text text-center',
			styles:{
				left: '32% !important' ,
			},
			textIndivData: [
				{
					textData: "मेरी दिदी एउटा निबन्ध प्रतियोगितामा प्रथम भएकी थिइन्।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.7 },
						{ start: 1.3 },
						{ start: 1.6 },
						{ start: 2.2 },
						{ start: 3.1 },
						{ start: 3.5 },
						{ start: 4.1 },
						{ start: 4.6 },
					],
				},
				{
					textData: " अब आर्मेनिया भन्‍ने देशमा युवा सम्मेलनका लागि छानिएकी छिन्।",
					textClass: "m-0",
					timestamps: [
						{ start: 5.0 },
						{ start: 5.6 },
						{ start: 6.1 },
						{ start: 6.5 },
						{ start: 6.9 },
						{ start: 7.4 },
						{ start: 8.4 },
						{ start: 9.0 },
						{ start: 9.6 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./b2.ogg"),
			delay: 500,
		},
	],
}

export default content
