const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./Page04.jpg"),
			class: "image-five page4",
			imgdiv: "imageCover",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox2 text_box-p4",
			textIndivData: [
				{
					textData: "आमाले झासझुस बटुली।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.9 }, { start: 1.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./4.ogg"),
			delay: 500,
		},
		{
			url: audios("./rain.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
