const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./dog-with-headphones.gif"),
			class: "image-five",
			imgdiv: "imageCover yellowbg ",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center browntext",
			textIndivData: [
				{
					textData: "कुकुरले कानमा",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.2 }],
				},
				{
					textData: "हेडफोन लगायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 1.9 }, { start: 2.55 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./29.ogg"),
			delay: 500,
		},
	],
}

export default content
