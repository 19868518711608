import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

//Title Page
const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./cover-page.jpg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./nima_moving_head_body.png"),
			imgdiv: "imageCover standing_nima_body-p2",
		},
		{
			src: images("./nima_moving_head_head.png"),
			imgdiv: "standing_nima_head-p2",
		},
	],

	texts: [
		{
			textDivClass: "story-text page1Text text-center text-box-p2",
			textIndivData: [
				{
					textData: "आज छुट्टिको दिन निमा दङ्‍ग पर्दै बिहानै घुम्‍न निस्किन्।",
					textClass: "m-0 ",
					timestamps: [
						{ start: 0 },
						{ start: 0.5 },
						{ start: 1.1 },
						{ start: 1.85 },
						{ start: 2.2 },
						{ start: 2.6 },
						{ start: 3 },
						{ start: 3.55 },
						{ start: 3.95 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./naration.ogg"),
			delay: 500,
		},
		{
			url: audios("./birds.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./blank.ogg"),
			delay: 9000,
		},
	],
}

export default content
