import React from "react"
import { CSSTransition } from "react-transition-group"

// styles
import "../stage.styles.scss"
import "./stage1.styles.scss"

import {
	StateContext,
	// , DispatchContext
} from "../../../DecodingGame"

import Intro from "./Intro/Intro"
//Phase Component
import Phase1 from "./Phase1"
import Phase2 from "./Phase2"
import Phase3 from "./Phase3"
import Phase4 from "./Phase4"
import Phase5 from "./Phase5"
import PhaseEnd from "./PhaseEnd"
import Transition from "./Transition"

const Stage1 = () => {
	// const state = useContext(StateContext)
	// const dispatch = useContext(DispatchContext)

	return (
		<StateContext.Consumer>
			{(state) => {
				if (state === undefined) {
					throw new Error("StateConsumer must be used within a StateProvider")
				}
				// return state.intro ? (
				return false ? (
					<Intro />
				) : (
					<div className="game-stage-dg" style={{ height: "91%" }}>
						<div
							style={{
								backgroundImage: "linear-gradient(white, #DEF8C5)",
								width: "100%",
								height: "100%",
							}}
							id="decoder-stage1"
							className="container-fluid"
						>
							<CSSTransition
								in={state.phase === 1}
								timeout={300}
								classNames="alert"
								unmountOnExit
								// onEnter={() => setShowButton(false)}
								// onExited={() => setShowButton(true)}
							>
								<Phase1 />
							</CSSTransition>
							<CSSTransition
								in={state.phase === 2}
								timeout={300}
								classNames="alert"
								unmountOnExit
								// onEnter={() => setShowButton(false)}
								// onExited={() => setShowButton(true)}
							>
								<Phase2 />
							</CSSTransition>

							<CSSTransition
								in={state.phase === 3 && !state.transition}
								timeout={300}
								classNames="alert"
								unmountOnExit
								// onEnter={() => setShowButton(false)}
								// onExited={() => setShowButton(true)}
							>
								<Phase3 />
							</CSSTransition>
							<CSSTransition
								in={state.phase === 4 && !state.transition}
								// in={true}
								timeout={300}
								classNames="alert"
								unmountOnExit
								// onEnter={() => setShowButton(false)}
								// onExited={() => setShowButton(true)}
							>
								<Phase4 />
							</CSSTransition>
							<CSSTransition
								in={state.phase === 5 && !state.transition}
								timeout={300}
								classNames="alert"
								unmountOnExit
								// onEnter={() => setShowButton(false)}
								// onExited={() => setShowButton(true)}
							>
								<Phase5 />
							</CSSTransition>
							{console.log(state.phase)}
							<CSSTransition
								in={state.phase === 6}
								timeout={300}
								classNames="alert"
								unmountOnExit
								// onEnter={() => setShowButton(false)}
								// onExited={() => setShowButton(true)}
							>
								<PhaseEnd />
							</CSSTransition>

							<CSSTransition
								in={state.transition && state.phase !== 6}
								timeout={300}
								classNames="alert"
								unmountOnExit
								// onEnter={() => setShowButton(false)}
								// onExited={() => setShowButton(true)}
							>
								<Transition />
							</CSSTransition>
						</div>
					</div>
				)
			}}
		</StateContext.Consumer>
	)
}

export default Stage1
