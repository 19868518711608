/* eslint-disable react/display-name */
import React from "react"

// const whyDidYouRender = require("@welldone-software/why-did-you-render");
// whyDidYouRender(React);

const CraneSVG = React.memo(({ className }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			x="0px"
			y="0px"
			width="523.557px"
			height="892.758px"
			className={className}
			viewBox="0 0 32 32"
			enableBackground="new 0 0 302.137 172.422"
			xmlSpace="preserve"
		>
			<g id="crane">
				<g id="movable">
					<g id="lineNball">
						<g id="line_1">
							<g>
								<line
									stroke="#7F4506"
									strokeWidth="6.1744"
									strokeMiterlimit="10"
									x1="77.309"
									y1="20.618"
									x2="77.309"
									y2="462.58"
								/>
								<g>
									<path
										fill="#473E41"
										d="M84.162,63.92c0,1.207-1.088,2.188-2.428,2.188h-8.854c-1.342,0-2.428-0.98-2.428-2.188l0,0
											c0-1.209,1.086-2.189,2.428-2.189h8.854C83.074,61.73,84.162,62.711,84.162,63.92L84.162,63.92z"
									/>
									<path
										fill="#473E41"
										d="M84.162,70.277c0,1.209-1.088,2.188-2.428,2.188h-8.854c-1.342,0-2.428-0.979-2.428-2.188l0,0
											c0-1.21,1.086-2.188,2.428-2.188h8.854C83.074,68.09,84.162,69.067,84.162,70.277L84.162,70.277z"
									/>
									<path
										fill="#473E41"
										d="M84.162,76.637c0,1.207-1.088,2.188-2.428,2.188h-8.854c-1.342,0-2.428-0.98-2.428-2.188l0,0
											c0-1.209,1.086-2.189,2.428-2.189h8.854C83.074,74.447,84.162,75.428,84.162,76.637L84.162,76.637z"
									/>
								</g>
							</g>
						</g>
						<g id="ball">
							<g>
								<path
									fill="#07191D"
									d="M153,557.652c-4.344,38.496-36.447,68.391-75.414,68.391c-39.551,0-72.031-30.797-75.59-70.121
										C0.689,561.548,0,567.414,0,573.439c0,42.697,34.613,77.309,77.309,77.309s77.309-34.611,77.309-77.309
										C154.617,568.029,154.059,562.75,153,557.652z"
								/>
								<path
									fill="#093139"
									stroke="#021619"
									strokeWidth="0.8821"
									strokeMiterlimit="10"
									d="M66.705,496.828v-8.953
										c0,0-0.408-10.826,10.547-10.826h0.115c10.076,0,9.873,10.826,9.873,10.826v8.92c0.693,0.09,1.387,0.188,2.076,0.297
										c0.996,0.156,1.982,0.332,2.961,0.527v-11.645c0,0-0.613-13.494-14.896-13.494h-0.143c-14.285,0-14.898,13.494-14.898,13.494
										v11.572c0.979-0.189,1.967-0.365,2.961-0.516C65.768,496.958,66.234,496.89,66.705,496.828z"
								/>
								<path
									fill="#093139"
									d="M92.277,497.619c-0.979-0.195-1.965-0.371-2.961-0.527c-0.689-0.109-1.383-0.207-2.076-0.297
										c-3.314-0.434-6.691-0.662-10.121-0.662c-3.531,0-7.01,0.238-10.414,0.695c-0.471,0.063-0.938,0.131-1.404,0.203
										c-0.994,0.15-1.982,0.326-2.961,0.516c-29.924,5.791-53.691,28.84-60.533,58.375c3.559,39.324,36.041,70.121,75.59,70.121
										c38.967,0,71.072-29.895,75.414-68.391C146.535,527.416,122.604,503.648,92.277,497.619z"
								/>
							</g>
						</g>
						<line
							id="ball-s-line"
							stroke="#7F4506"
							strokeWidth="6.1744"
							strokeLinecap="round"
							strokeMiterlimit="10"
							x1="77.309"
							y1="450.785"
							x2="77.309"
							y2="480.851"
						/>
					</g>

					<g id="beam_1">
						<g id="beam">
							<path
								fill="#C17C30"
								d="M77.273,32.257l0.383,7.368l11.75,8.564c1.959-1.011,3.693-2.104,5.26-3.246L77.273,32.257z"
							/>
							<path
								fill="#8E6025"
								d="M106.908,26.631c-8.139-5.758-12.746-9.014-12.746-9.014l-17.539,2.063l0.65,12.576l17.393,12.687
									C102.41,39.296,105.645,32.313,106.908,26.631z"
							/>
							<path
								fill="#FFB85A"
								d="M94.666,44.943c-1.566,1.143-3.301,2.235-5.26,3.246l322.209,234.83c0.281-2.147,0.723-4.121,1.273-5.963
									L94.666,44.943z"
							/>
							<path
								fill="#EAA641"
								d="M94.666,44.943l318.223,232.113c3.354-11.258,11.176-17.027,17.229-19.875
									C374.387,215.939,161.369,65.15,106.908,26.631C105.645,32.313,102.41,39.296,94.666,44.943z"
							/>
							<path
								fill="#C17C30"
								d="M411.615,283.02l10.955,7.984l7.186-1.643l-16.867-12.305C412.338,278.898,411.896,280.872,411.615,283.02
									z"
							/>
							<path
								fill="#8E6025"
								d="M442.859,266.932c-1.598-1.402-6.033-4.783-12.742-9.75c-6.053,2.848-13.875,8.617-17.229,19.875
									l16.867,12.305l16.885-3.859L442.859,266.932z"
							/>
							<g>
								<circle fill="#EAA641" cx="77.308" cy="21.346" r="32" />
								<circle
									fill="#EAA641"
									stroke="#AC6D1E"
									strokeWidth="3.5282"
									strokeMiterlimit="10"
									cx="76.745"
									cy="21.346"
									r="15"
								/>
							</g>
						</g>
						<g id="bottom_beam">
							<rect
								x="284.303"
								y="459.228"
								transform="matrix(0.0651 0.9979 -0.9979 0.0651 900.6109 -29.6426)"
								fill="#231F20"
								width="363.646"
								height="13.229"
							/>
							<path
								fill="#8E6025"
								d="M451.838,282.314c-19.281-8.626-26.322,5.615-26.322,5.615l-1.334,11.789
										c5.25,5.674,13.375,9.32,22.502,9.32c5.203,0,10.08-1.19,14.289-3.26c-0.053-2.809-0.127-4.703-0.229-5.406
										C458.738,286.475,451.838,282.314,451.838,282.314z"
							/>
							<path
								fill="#8E6025"
								d="M446.684,309.039c-9.127,0-17.252-3.646-22.502-9.32l-1.055,9.33c5.248,5.001,12.867,8.149,21.352,8.149
										c6.176,0,11.891-1.673,16.568-4.509c-0.016-2.674-0.041-5.005-0.074-6.91C456.764,307.849,451.887,309.039,446.684,309.039z"
							/>
							<g>
								<path
									fill="#FFB85A"
									d="M397.51,631.65l37.311-315.885c-4.533-1.386-8.543-3.714-11.693-6.716l-36.719,324.705
											c0,0,12.604,21.492,36.52,23.916c11.324,1.148,20.189,2.207,26.277-0.361C403.5,658.609,397.51,631.65,397.51,631.65z"
								/>
								<path
									fill="#EAA641"
									d="M458.803,633.916l1.133-80.801l0.322-134.614c0,0,0.975-75.298,0.789-105.812
											c-4.678,2.836-10.393,4.509-16.568,4.509c-3.391,0-6.639-0.511-9.658-1.434L397.51,631.65c0,0,5.99,26.959,51.695,25.658
											C455.48,654.664,458.803,648.166,458.803,633.916z"
								/>
							</g>
							<circle fill="#EAA641" cx="445.22" cy="287.286" r="21.347" />
							<circle
								fill="#EAA641"
								stroke="#AC6D1E"
								strokeWidth="3.5282"
								strokeMiterlimit="10"
								cx="444.659"
								cy="287.286"
								r="11.235"
							/>
						</g>
					</g>
				</g>

				<g id="base_1">
					<g id="base">
						<g>
							<path
								fill="#EAA641"
								d="M315.184,665.906c-52.041,0-49.689,29.402-49.689,29.402l-11.225,90.069h255.529
									c0.018-0.254,0.025-0.395,0.025-0.395V665.906C509.824,665.906,367.227,665.906,315.184,665.906z M488.361,685.361v57.578
									H267.588c-1.861-53.564,24.219-66.895,24.219-66.895h196.555V685.361z"
							/>
							<rect x="260.895" y="829.561" fill="#EAA641" width="207" height="7.932" />
							<path
								fill="#D38C32"
								d="M509.799,785.377H254.27L253,795.567h135.1v29.107h79.092v1.621
									C505.752,823.85,509.492,789.434,509.799,785.377z"
							/>
							<path
								fill="#241E20"
								d="M388.1,824.674v-29.107H253h-4.264c-1.176,33.811,15.287,34.988,15.287,34.988h203.871v-4.26v-1.621
									H388.1z"
							/>
							<path
								fill="#BD7D26"
								d="M386.883,760.96c0,1.885-1.527,3.412-3.41,3.412h-29.193c-1.883,0-3.41-1.527-3.41-3.412l0,0
									c0-1.881,1.527-3.408,3.41-3.408h29.193C385.355,757.552,386.883,759.08,386.883,760.96L386.883,760.96z"
							/>
							<rect x="411.984" y="743.171" fill="#BD7D26" width="6.002" height="81.415" />
							<path
								fill="#BD7D26"
								d="M504.477,738.314c0,2.639-2.139,4.775-4.775,4.775l0,0c-2.637,0-4.773-2.137-4.773-4.775v-57.568
									c0-2.637,2.137-4.773,4.773-4.773l0,0c2.637,0,4.775,2.137,4.775,4.773V738.314z"
							/>
							<g>
								<g>
									<path
										fill="#BA7D2D"
										d="M502.84,664.453c0,1.049-0.857,1.906-1.905,1.906H315.669c-1.048,0-1.905-0.857-1.905-1.906v-12.834
											c0-1.047,0.857-1.904,1.905-1.904h185.266c1.048,0,1.905,0.857,1.905,1.904V664.453z"
									/>
								</g>
								<rect x="323.566" y="649.714" fill="#99590E" width="4.33" height="16.482" />
								<rect x="337.467" y="649.714" fill="#99590E" width="4.33" height="16.482" />
								<rect x="351.365" y="649.714" fill="#99590E" width="4.33" height="16.482" />
								<rect x="365.266" y="649.714" fill="#99590E" width="4.328" height="16.482" />
								<rect x="379.164" y="649.714" fill="#99590E" width="4.328" height="16.482" />
								<rect x="393.064" y="649.714" fill="#99590E" width="4.328" height="16.482" />
								<rect x="406.963" y="649.714" fill="#99590E" width="4.328" height="16.482" />
								<rect x="420.861" y="649.714" fill="#99590E" width="4.33" height="16.482" />
								<rect x="434.762" y="649.714" fill="#99590E" width="4.328" height="16.482" />
								<rect x="448.66" y="649.714" fill="#99590E" width="4.33" height="16.482" />
								<rect x="462.561" y="649.714" fill="#99590E" width="4.328" height="16.482" />
								<rect x="476.459" y="649.714" fill="#99590E" width="4.328" height="16.482" />
								<rect x="490.359" y="649.714" fill="#99590E" width="4.328" height="16.482" />
							</g>
							<g>
								<circle fill="#EAA641" cx="477.924" cy="644.318" r="13.123" />
								<circle
									fill="#EAA641"
									stroke="#AC6D1E"
									strokeWidth="3.5282"
									strokeMiterlimit="10"
									cx="477.578"
									cy="644.318"
									r="6.906"
								/>
							</g>
							<g>
								<path fill="#241E20" d="M291.807,676.044c0,0-26.08,13.33-24.219,66.895h106.734l-45.625-66.895H291.807z" />
								<polygon
									fill="#241E20"
									points="425.66,676.044 471.287,742.939 488.361,742.939 488.361,685.361 488.361,676.044 				"
								/>
								<polygon fill="#393738" points="328.697,676.044 374.322,742.939 471.287,742.939 425.66,676.044 				" />
							</g>
							<g>
								<g>
									<path
										fill="#070707"
										stroke="#B5721B"
										strokeWidth="3.5282"
										strokeMiterlimit="10"
										d="M244.588,890.995
											c-9.295,0-16.828-13.033-16.828-26.83l0,0c0-13.795,7.533-24.979,16.828-24.979h260.379c9.291,0,16.826,11.184,16.826,24.979
											l0,0c0,13.797-7.535,26.83-16.826,26.83H244.588z"
									/>
								</g>
								<g>
									<g>
										<g>
											<path
												fill="#2F3026"
												d="M255.113,843.909c-11.697,0-21.182,9.484-21.182,21.182s9.484,21.182,21.182,21.182
													c11.699,0,21.182-9.484,21.182-21.182S266.813,843.909,255.113,843.909z M255.113,874.829c-5.377,0-9.738-4.361-9.738-9.738
													s4.361-9.738,9.738-9.738s9.736,4.361,9.736,9.738S260.49,874.829,255.113,874.829z"
											/>
											<circle
												fill="none"
												stroke="#D2AC47"
												strokeWidth="2.544"
												strokeMiterlimit="10"
												cx="255.113"
												cy="865.09"
												r="9.738"
											/>
										</g>
										<g>
											<path
												fill="none"
												stroke="#FFFFFF"
												strokeWidth="1.696"
												strokeLinecap="round"
												strokeMiterlimit="10"
												strokeDasharray="3.913,3.913"
												d="
													M276.295,865.088c0,11.699-9.482,21.182-21.182,21.182c-11.695,0-21.178-9.482-21.178-21.182
													c0-11.695,9.482-21.18,21.178-21.18C266.813,843.909,276.295,853.393,276.295,865.088z"
											/>
										</g>
									</g>
									<g>
										<g>
											<path
												fill="#2F3026"
												d="M302.979,843.909c-11.697,0-21.182,9.484-21.182,21.182s9.484,21.182,21.182,21.182
													c11.699,0,21.182-9.484,21.182-21.182S314.678,843.909,302.979,843.909z M302.979,874.829c-5.377,0-9.738-4.361-9.738-9.738
													s4.361-9.738,9.738-9.738s9.736,4.361,9.736,9.738S308.355,874.829,302.979,874.829z"
											/>
											<circle
												fill="none"
												stroke="#D2AC47"
												strokeWidth="2.544"
												strokeMiterlimit="10"
												cx="302.979"
												cy="865.09"
												r="9.738"
											/>
										</g>
										<g>
											<path
												fill="none"
												stroke="#FFFFFF"
												strokeWidth="1.696"
												strokeLinecap="round"
												strokeMiterlimit="10"
												strokeDasharray="3.913,3.913"
												d="
													M324.16,865.088c0,11.699-9.482,21.182-21.182,21.182c-11.695,0-21.178-9.482-21.178-21.182
													c0-11.695,9.482-21.18,21.178-21.18C314.678,843.909,324.16,853.393,324.16,865.088z"
											/>
										</g>
									</g>
									<g>
										<g>
											<path
												fill="#2F3026"
												d="M350.844,843.909c-11.697,0-21.182,9.484-21.182,21.182s9.484,21.182,21.182,21.182
													c11.699,0,21.182-9.484,21.182-21.182S362.543,843.909,350.844,843.909z M350.844,874.829c-5.377,0-9.738-4.361-9.738-9.738
													s4.361-9.738,9.738-9.738s9.736,4.361,9.736,9.738S356.221,874.829,350.844,874.829z"
											/>
											<circle
												fill="none"
												stroke="#D2AC47"
												strokeWidth="2.544"
												strokeMiterlimit="10"
												cx="350.844"
												cy="865.09"
												r="9.738"
											/>
										</g>
										<g>
											<path
												fill="none"
												stroke="#FFFFFF"
												strokeWidth="1.696"
												strokeLinecap="round"
												strokeMiterlimit="10"
												strokeDasharray="3.913,3.913"
												d="
													M372.025,865.088c0,11.699-9.482,21.182-21.182,21.182c-11.695,0-21.178-9.482-21.178-21.182
													c0-11.695,9.482-21.18,21.178-21.18C362.543,843.909,372.025,853.393,372.025,865.088z"
											/>
										</g>
									</g>
									<g>
										<g>
											<path
												fill="#2F3026"
												d="M398.709,843.909c-11.697,0-21.182,9.484-21.182,21.182s9.484,21.182,21.182,21.182
													c11.699,0,21.182-9.484,21.182-21.182S410.408,843.909,398.709,843.909z M398.709,874.829c-5.377,0-9.738-4.361-9.738-9.738
													s4.361-9.738,9.738-9.738s9.738,4.361,9.738,9.738S404.086,874.829,398.709,874.829z"
											/>
											<circle
												fill="none"
												stroke="#D2AC47"
												strokeWidth="2.544"
												strokeMiterlimit="10"
												cx="398.709"
												cy="865.09"
												r="9.738"
											/>
										</g>
										<g>
											<path
												fill="none"
												stroke="#FFFFFF"
												strokeWidth="1.696"
												strokeLinecap="round"
												strokeMiterlimit="10"
												strokeDasharray="3.913,3.913"
												d="
													M419.891,865.088c0,11.699-9.482,21.182-21.182,21.182c-11.695,0-21.178-9.482-21.178-21.182
													c0-11.695,9.482-21.18,21.178-21.18C410.408,843.909,419.891,853.393,419.891,865.088z"
											/>
										</g>
									</g>
									<g>
										<g>
											<path
												fill="#2F3026"
												d="M446.574,843.909c-11.697,0-21.182,9.484-21.182,21.182s9.484,21.182,21.182,21.182
													c11.699,0,21.182-9.484,21.182-21.182S458.273,843.909,446.574,843.909z M446.574,874.829c-5.377,0-9.738-4.361-9.738-9.738
													s4.361-9.738,9.738-9.738s9.738,4.361,9.738,9.738S451.951,874.829,446.574,874.829z"
											/>
											<circle
												fill="none"
												stroke="#D2AC47"
												strokeWidth="2.544"
												strokeMiterlimit="10"
												cx="446.574"
												cy="865.09"
												r="9.738"
											/>
										</g>
										<g>
											<path
												fill="none"
												stroke="#FFFFFF"
												strokeWidth="1.696"
												strokeLinecap="round"
												strokeMiterlimit="10"
												strokeDasharray="3.913,3.913"
												d="
													M467.756,865.088c0,11.699-9.482,21.182-21.182,21.182c-11.695,0-21.178-9.482-21.178-21.182
													c0-11.695,9.482-21.18,21.178-21.18C458.273,843.909,467.756,853.393,467.756,865.088z"
											/>
										</g>
									</g>
									<g>
										<g>
											<path
												fill="#2F3026"
												d="M494.439,843.909c-11.697,0-21.182,9.484-21.182,21.182s9.484,21.182,21.182,21.182
													c11.699,0,21.182-9.484,21.182-21.182S506.139,843.909,494.439,843.909z M494.439,874.829c-5.377,0-9.738-4.361-9.738-9.738
													s4.361-9.738,9.738-9.738s9.738,4.361,9.738,9.738S499.816,874.829,494.439,874.829z"
											/>
											<circle
												fill="none"
												stroke="#D2AC47"
												strokeWidth="2.544"
												strokeMiterlimit="10"
												cx="494.439"
												cy="865.09"
												r="9.738"
											/>
										</g>
										<g>
											<path
												fill="none"
												stroke="#FFFFFF"
												strokeWidth="1.696"
												strokeLinecap="round"
												strokeMiterlimit="10"
												strokeDasharray="3.913,3.913"
												d="
													M515.621,865.088c0,11.699-9.482,21.182-21.182,21.182c-11.695,0-21.178-9.482-21.178-21.182
													c0-11.695,9.482-21.18,21.178-21.18C506.139,843.909,515.621,853.393,515.621,865.088z"
											/>
										</g>
									</g>
								</g>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
})

// CraneSVG.whyDidYouRender = true;
// const CraneSVGMemo = React.memo(CraneSVG);
// CraneSVGMemo.whyDidYouRender = true;

export default CraneSVG
