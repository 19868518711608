import React, { PureComponent } from "react"
import { Link } from "react-router-dom"
import Modal from "react-modal"
import IconHome from "assets/icons/home.svg"
import BlastBanner from "assets/banner-on-loop.gif"
import ListenImage from "assets/listen-again.png"
import ReadImage from "assets/read-again.png"

class LastPage extends PureComponent {
	state = {
		showLastPage: false,
	}

	// componentWillMount() {
	//   const lastPageProps = this.props.onLastPageReached;
	//   lastPageProps();
	// }
	render() {
		return (
			<Modal
				isOpen={true}
				ariaHideApp={false}
				style={{
					overlay: {
						zIndex: "9999",
						backgroundColor: "rgba(0, 0, 0, 0.7)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					},
					content: {
						position: "static",
						backgroundColor: "transparent",
						border: "none",
					},
				}}
			>
				<div key="menuBtnNavBar" className="last__page__menu">
					<div className="menu__wrap">
						<div className="left-banner">
							<img className="img-fluid" src={BlastBanner} alt="banner" />
						</div>
						<div className="right-banner">
							<img className="img-fluid" src={BlastBanner} alt="banner" />
						</div>
						<div className="meta">
							<h2 className="text-center">समाप्त</h2>
						</div>
						<Link className="home__icon" exact="true" to="/">
							<img src={IconHome} className="img-fluid" alt="Home" />
						</Link>
						<ul className="menu__list">
							<li className="play-wrap">
								<div className="image-wrap">
									<img src={ListenImage} className="img-fluid" alt="Play" />
								</div>
								<button key="listenRead" className="btn btn-round purple" onClick={this.props.onListenReadBtnClick}>
									फेरि सुनौँ
								</button>
							</li>
							<li className="read-wrap">
								<div className="image-wrap">
									<img src={ReadImage} className="img-fluid" alt="Read" />
								</div>
								<button className="btn btn-round" key="read" onClick={this.props.onReadBtnClick}>
									फेरि पढौँ
								</button>
							</li>
						</ul>
					</div>
				</div>
			</Modal>
		)
	}
}

export default LastPage
