import PageContent from "./pages/all"

const images = require.context("./images", true)

const audios = require.context("./audios", true)
const content = {
	bg: "MaKoHunContainer",
	name: "म को हुँ?",
	key: "ma-ko-hun",
	label: "medium",
	coverImage: images("./cover_page.jpg"),
	credits: {
		writer: "पेमा डोल्मा लामा",
		illustrator: "अर्हन्त श्रेष्ठ",
		template: "default",
		bg: images("./cover_page.jpg"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
