const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page02.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./page02_two_lady.png"),
			class: "image-five ",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideRight text-center bg-green",
			textIndivData: [
				{
					textData: "बाटोमा उनले मान्छेहरू बोलेको सुनिन्...",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.2 }, { start: 1.95 }, { start: 2.5 }],
				},
				{
					textData: "“ऊ हेर! पासाङ दोर्जीकी नातिनी आइन्।”",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 4.2 }, { start: 4.5 }, { start: 5.3 }, { start: 5.8 }, { start: 6.45 }, { start: 7 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3.ogg"),
			delay: 1000,
		},
	],
}

export default content
