import React from "react"
import { NavLink } from "react-router-dom"
import styled from "@emotion/styled"

import Logo from "assets/ole-logo.png"
import HomeBg from "assets/homebg.jpg"
import readBtn from "assets/btns/padhau.png"
import playBtn from "assets/btns/khelau.png"
import Footer from "Components/Layout/Footer"

import ReactGA from "react-ga"

const ButtonContainer = styled.div`
	padding-top: 0%;
	display: flex;
	justify-content: space-evenly;
	@media (max-width: 575px) {
		padding-top: 20%;
		flex-direction: column;
	}
`

const HomePage = () => {
	React.useEffect(() => {
		document.title = "HRK :: Homepage"
		return () => {
			document.title = "HRK"
		}
	})
	React.useEffect(() => {
		ReactGA.initialize("UA-172822248-1")
		ReactGA.pageview(window.location.pathname + window.location.search)
	}, [])

	return (
		<div className="homepage">
			<header className="home__header">
				<div className="container">
					<div className="logo">
						<img src={Logo} className="img-fluid" alt="OLE Nepal" />
					</div>
				</div>
			</header>
			<main className="main__content" style={{ backgroundImage: `url(${HomeBg})` }}>
				<div className="temp-message">
					<p>हामी यस साइटमा अझै घेरै बालकथा र खेलहरू थप्दै जानेछौँ। नयाँ कथा र खेलहरूका लागि फेरि फेरि यहाँ आउँदै गर्नुहोला।</p>

					<p>
						साथै तपाईँका कुनै सल्‍लाह र सुझावहरू भएमा हामीलाई 
						<a href="mailto:info@olenepal.org">info@olenepal.org</a>
						 मा अवश्य पठाउनु होला।
					</p>
				</div>
				<div className="content__holder">
					<ButtonContainer className="container btn__container">
						<div className="btn__holder">
							<NavLink className="btn btn-with-text" exact to="/story">
								<img src={readBtn} alt="पढौ button" />
							</NavLink>
						</div>
						<div className="btn__holder">
							<NavLink className="btn btn-with-text pink" exact to="/play">
								<img src={playBtn} alt="खेलौ button" />
							</NavLink>
						</div>
					</ButtonContainer>
				</div>
			</main>
			<Footer />
		</div>
	)
}

export default HomePage
