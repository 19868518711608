// import { keyframes } from "@emotion/core"
import { keyframes } from "@emotion/core"
import { transformTo, appear, disappear } from "styledElements/keyframes"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended",
	imageContainerStyles: {
		background: "#FAF8DC",
		transformOrigin: "center",
		// backgroundColor: "#FAF8DC",
		animation: `${transformTo("scale(4)")} 4s 2s ease-in-out normal forwards, 
				${transformTo("scale(14)")} 4s 6s ease-in-out normal forwards, 
				${transformTo("scale(1)")} 4s 10s ease-in-out normal forwards,
				${transformTo("scale(14)")} 4s 42s ease-in-out normal forwards,
				${transformTo("scale(1)")} 4s 46s ease-in-out normal forwards 

				`,
	},
	images: [
		{
			src: images("./bg_page98.png"),
			styles: {
				animation: `${disappear} 1s 8s linear forwards`
			},
		},
		{
			src: images("./egg_plate_98.png"),
			styles: {
				top: "7%",
				left: "25%",
				width: "50%",
				animation: `${disappear} 1s 10s linear forwards`
			},
		},
		{
			src: images("./triangle.svg"),
			styles: {
				width: '32%',
				position: 'absolute',
				top: '19%',
				left: '125%',
				animation: `${transformTo("translateX(-363%)")} 4s 12s ease-out forwards,
							${keyframes({
								from:{
									transform: "translateX(-363%) rotate(5deg)"
								},
								to:{
									transform: "translateX(-363%) rotate(-5deg)"
								}
							})} 2s 35s linear infinite alternate,
							${disappear} 1s 39s linear forwards
				`
				
			},
		},
		{
			src: images("./rectangle.svg"),
			styles: {
				width: '15%',
				position: 'absolute',
				top: '-46%',
				left: '10%',
				transform: 'rotate(-90deg)',
				animation: `${transformTo("rotate(-90deg) translateY(110%)")} 4s 18s ease-out forwards,
							${keyframes({
								from:{
									transform: "rotate(-93deg) translateY(110%)"
								},
								to:{
									transform: "rotate(-87deg) translateY(110%)"
								}
							})} 2s 35s linear infinite alternate,
							${disappear} 1s 39s linear forwards
				`
			},
		},
		{
			src: images("./square2.svg"),
			styles: {
				width: '15%',
				position: 'absolute',
				top: '60%',
				left: '100%',
				animation: `${transformTo("translateX(-567%)")} 4s 24s ease-out forwards,
							${keyframes({
								from:{
									transform: "translateX(-567%) rotate(5deg)"
								},
								to:{
									transform: "translateX(-567%) rotate(-5deg) "
								}
							})} 2s 35s linear infinite alternate,
							${disappear} 1s 39s linear forwards
				`
			},
		},
		{
			src: images("./circle.svg"),
			styles: {
				width: '15%',
				position: 'absolute',
				top: '60%',	
				left: '-20%',
				animation: `${transformTo("translateX(612%)")} 4s 30s ease-out forwards,
							${keyframes({
								from:{
									transform: "translateX(612%)"
								},
								to:{
									transform: "translateX(630%) "
								}
							})} 2s 35s linear infinite alternate,
							${transformTo("translate(422%,-40%) scale(2) ")} 3s 39s ease-out forwards 

							
				`
			},
		},
		{
			src: images("./cover_page_new.png"),
			styles: {
				opacity: 0,
				animation: `${appear} 1s linear 47s forwards`,
			},
		},
	],
	texts: [],
	sounds: [
		{
			url: audios("./circle.ogg"),
			delay: 34000,
			volume: 3
		},
		{
			url: audios("./triangle.ogg"),
			delay: 16000,
			volume: 3
		},
		{
			url: audios("./rectangle.ogg"),
			delay: 22000,
			volume: 2
		},
		{
			url: audios("./square.ogg"),
			delay: 28000,
		},
		{
			url: audios("./blank.ogg"),
			delay: 52000,
		},
		{
			url: audios("./bg/se_page12.ogg"),
			volume: 0.4,
			delay: 1,
			isBg: true,
			loop: false,
		},
	],
}

export default content
