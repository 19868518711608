import React, { useState, useContext, useEffect } from "react"
// import _ from "underscore";
import gsap from "gsap"
import MotionPathPlugin from "gsap/MotionPathPlugin"
import { Howl } from "howler"

import "./Player.styles.scss"

import yahooAudio from "../../content/audios/sound_effects/minion-yahoo.ogg"

import Squirrel01 from "./assets/squirrel05.png"
import Squirrel02 from "./assets/squirrel_happy.png"
import Squirrel03 from "./assets/squirrel03.png"
import Squirrel04 from "./assets/Squirrel-walk.gif"
import Squirrel05 from "./assets/squirrel_with_nuts.png"

import { StateContext, DispatchContext } from "../../MatchTheWordGame"

const Player = (props) => {
	const assets = require.context("../Options/assets")
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)
	const [SquirrelImg, setSquirrelImg] = useState(Squirrel01)

	useEffect(() => {
		// console.log(state)
		if (state.wordComplete && !state.rewardComplete && !state.isLastScene) {
			gsap.registerPlugin(MotionPathPlugin)
			gsap.to(".squirrel", {
				delay: 1.5,
				duration: 1.5,
				motionPath: {
					path: [
						{ xPercent: -250, yPercent: -50 },
						{ xPercent: -250, yPercent: 110 },
					],
					align: "self",
				},
				onStart: function () {
					setSquirrelImg(Squirrel03)
				},
				onComplete: function () {
					setSquirrelImg(Squirrel02)

					gsap.to(".player-stone_Img, .playerFizz", {
						webkitFilter: "blur(1px)",
						duration: 1,
					})
				},
			})
		}

		if (state.isLastScene && !state.rewardComplete) {
			setSquirrelImg(Squirrel03)
			let squirrelMoveTl = new gsap.timeline()
			const yahooHowl = new Howl({
				src: yahooAudio,
				loop: false,
				volume: 0.5,
			})
			squirrelMoveTl
				.to(".squirrel", {
					delay: 1,
					duration: 1.5,
					motionPath: {
						path: [
							{ xPercent: -250, yPercent: -40 },
							{ xPercent: -250, yPercent: 110 },
						],
						align: "self",
					},
					onComplete: function () {
						setSquirrelImg(Squirrel04)
					},
				})
				.to(".squirrel", {
					duration: 3,
					yPercent: 310,
					onComplete: function () {
						setSquirrelImg(Squirrel05)
					},
				})
				.fromTo(
					".squirrel",
					{
						yPercent: 270,
					},
					{
						duration: 0.5,
						repeat: 3,
						yPercent: 230,

						onStart: function () {
							if (state.bgMusic === "happyTune") {
								yahooHowl.play()
							}
						},
						onComplete: function () {
							dispatch({ type: "REWARD_COMPLETE" })
						},
						yoyo: true,
					}
				)
		}
		if (state.wordComplete === false && state.stage > 1) {
			setSquirrelImg(Squirrel01)
			gsap.fromTo(
				".playerNstone-div",
				{
					top: "30%",
				},
				{
					top: "3%",
					duration: 0.9,
					// delay: 1,
				}
			)
		}
	}, [state, dispatch])
	return (
		<div className="playerNstone-div">
			{state.stage > 1 && (
				<div className="player-stone" id="player-stone">
					<img
						className="player-stone_Img"
						src={assets(`./stone${state.levelData[state.stage - 1].question.questionImageIdx}.png`)}
						alt="player"
					/>
					<div className="pFizzContainer">
						<div className="fizz playerFizz">
							<div className="fizzDrop"></div>
						</div>
					</div>
					{/* <h3 className={" stone-text"}>{state.levelData[state.stage-1].word}</h3> */}
				</div>
			)}
			<div className="playerSqu" id="squirrel">
				{console.log(SquirrelImg)}
				<img id="squirrel" className="squirrel" src={SquirrelImg} alt="player" />
			</div>
		</div>
	)
}

export default Player
