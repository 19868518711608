import { keyframes } from "@emotion/core"
import { appear } from "styledElements/keyframes"
const content = {
	images: [
		{
			src: "city/bg_page-19.png",
		},
		// three bird group: Start
		{
			src: "birds/bird_fly05.gif",
			// class: 'bird2-p13',
			styles: {
				width: "20%",
				top: "46%",
				left: "-30%",
				transform: "translateX(0%) rotate(45deg)",
				animation: `${keyframes({
					"0%": {
						transform: "translateX(0%) rotate(45deg)",
					},
					"49%": {
						transform: "translateX(0%) rotate(45deg)",
					},
					"100%": {
						transform: "translateX(650%) rotate(45deg)",
					},
				})} 12s linear infinite`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "birds/bird_fly05.gif",
			// class: 'bird2-p13',
			styles: {
				width: "15%",
				top: "46%",
				left: "-30%",
				transform: "translate(0%,20%) rotate(45deg)",
				animation: `${keyframes({
					"0%": {
						transform: "translate(0%,20%) rotate(45deg)",
					},
					"49%": {
						transform: "translate(0%,20%) rotate(45deg)",
					},
					"100%": {
						transform: "translate(800%, 30%) rotate(45deg)",
					},
				})} 12s linear infinite`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "birds/bird_fly05.gif",
			// class: 'bird2-p13',
			styles: {
				width: "10%",
				top: "46%",
				left: "-30%",
				transform: "translateX(0%) rotate(45deg)",
				animation: `${keyframes({
					"0%": {
						transform: "translateX(0%) rotate(45deg)",
					},
					"49%": {
						transform: "translateX(0%) rotate(45deg)",
					},
					"100%": {
						transform: "translateX(1200%) rotate(45deg)",
					},
				})} 12s linear infinite`,
			},
			imgdiv: "imageCover",
		},
		// three bird group: End

		{
			src: "birds/bird_fly11b.gif",
			// class: 'bird2-p13',
			styles: {
				width: "11%",
				top: "13%",
				left: "100%",
				transform: "translateX(0%)",
				animation: `${keyframes({
					to: {
						transform: "translateX(-1200%)",
					},
				})} 7s ease-in-out infinite`,
			},
			imgdiv: "imageCover a",
		},
		{
			src: "birds/birdOp.gif",
			// class: 'bird2-p13',
			styles: {
				width: "5%",
				top: "13%",
				left: "100%",
				transform: "translateX(0%)",
				animation: `${keyframes({
					to: {
						transform: "translateX(-1620%)",
					},
				})} 7s linear infinite`,
			},
			imgdiv: "imageCover b",
		},
		{
			src: "birds/bird_fly06.gif",
			// class: 'bird2-p13',
			styles: {
				width: "5%",
				top: "13%",
				left: "130%",
				transform: "translateX(0%)",
				animation: `${keyframes({
					to: {
						transform: "translateX(-2500%)",
					},
				})} 9s linear infinite`,
			},
			imgdiv: "imageCover c",
		},
		{
			src: "birds/bird_fly09.gif",
			// class: 'bird2-p13',
			styles: {
				width: "20%",
				top: "74%",
				left: "-16%",
				animation: `${keyframes({
					to: {
						transform: "translate(800%,-400%)",
					},
				})} 10s 2s ease-in-out infinite`,
			},
			imgdiv: "imageCover d",
		},
		{
			src: "birds/bird_fly09.gif",
			// class: 'bird2-p13',
			styles: {
				width: "20%",
				top: "80%",
				left: "-25%",
				animation: `${keyframes({
					to: {
						transform: "translate(90%,-400%)",
					},
				})} 10s 2s ease-in-out infinite`,
			},
			imgdiv: "imageCover e",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: {
				left: "auto",
				right: "0",
				padding: "15px",
				color: "white",
				animation: `${appear} 1s ease-out normal both`,
			},
			textIndivData: [
				{
					textData: "गौँथली भिरमै बस्‍न रमाए",
					textClass: "m-0 p10-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "भिर गौंथली भनि बोलाए",
					textClass: "m-0 p10-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p17.ogg",
			delay: 500,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			isBg: true,
			volume: 0.3,
		},
	],
}

export default content
