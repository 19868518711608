import { useEffect, useContext } from "react"
import { DispatchContext } from "../../../DecodingGame"
/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import { Howl } from "howler"

// images
import Sabina from "./assets/sabina_clapping.png"

const PhaseEnd = () => {
	const dispatch = useContext(DispatchContext)
	useEffect(() => {
		const soundAssets = require.context("./assets/audios")
		const PraseHowl = new Howl({
			src: [soundAssets("./aaha_timi_le.ogg")],
		})
		PraseHowl.play()

		PraseHowl.on("end", () => {
			dispatch({ type: "REWARD_COMPLETE" })
		})
	}, [dispatch])

	return (
		<div className="phaseEnd">
			<img
				src={Sabina}
				id="sabina1"
				alt="sabina"
				css={css`
					position: absolute;
					width: 16%;
					top: 12%;
					left: 72%;
				`}
			/>
		</div>
	)
}

export default PhaseEnd
