const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./25to33/page06_bg.png"),
			imgdiv: "imageCover bg-blue-sky",
		},
		{
			src: images("./25to33/img03.svg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./1to5/bush01.png"),
			imgdiv: "imageCover bush-left",
		},
		{
			src: images("./1to5/bush02.png"),
			imgdiv: "imageCover bush-right",
		},
	],
	texts: [
		{
			textDivClass: "story-text w-75 end-suspense",
			textIndivData: [
				{
					textData: "यो कथा पढेर तिमीले के सिक्यौ? छलफल गर।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.4 },
						{ start: 0.8 },
						{ start: 1.6 },
						{ start: 2.3 },
						{ start: 2.8 },
						{ start: 3.6 },
						{ start: 4.1 },
						{ start: 5.2 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p5_s8.ogg"),
			delay: 250,
		},
	],
}

export default content
