import { fileCheck } from "helpers/files"
import { keyframes } from "@emotion/core"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	imageContainerStyles:{
		left: "-1%",
		animation: `${keyframes({
			from: {
				left: "-1%"
			},
			to: {
				left: "-32%"
			}
		})} 6s linear 5s forwards`
	},
	images: [
		{
			src: images("./background.png"),
			styles:{    
				width: '135%',
				height: '100%',
				top: '-25%',
				left: 0,
			}
		},
		{
			src: images("./hill/dog_walk.gif"),
			styles: {
				width: '7%',
				top: '28%',
				left: '7%',
			},
			imgdiv: "imageCover boy-and-dog-hills-dog",
		},
		{
			src: images("./hill/boy_walk.gif"),
			styles: {
				width: '8.2%',
				top: '19%',
				left: '5%',
			},
			imgdiv: "imageCover boy-and-dog-hills-boy",
		},
		{
			src: images("./hill/dog_walk.png"),
			styles: {
				width: '7%',
				top: '28%',
				left: '7%',
				opacity: 0,
			},
			imgdiv: "imageCover boy-and-dog-hills-dog-still ",
		},
		{
			src: images("./hill/boy_walk.png"),
			styles: {
				width: '8.2%',
				top: '19%',
				left: '5%',
				opacity: 0,
			},
			imgdiv: "imageCover boy-and-dog-hills-boy-still ",
		},
		
	],
	sounds: [
		{
			url: audios("./boy-laugh.ogg"),
			delay: 50,
		},
		{
			url: audios("./dog_bark.ogg"),
			delay: 250,
		},
		{
			url: audios("./dog_bark.ogg"),
			delay: 850,
		},
		{
			url: audios("./dog_bark.ogg"),
			delay: 11000,
		},
	],
}

export default content
