const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page01.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue",
			textIndivData: [
				{
					textData: "एक दिन डोमा स्कुलबाट घर फर्कदै थिइन्।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 1000,
		},
	],
}

export default content
