// configs
import content from "../../../../EGRContent/Play/DragandDropPlay/Content"
import shuffle from "shuffle-array"

export const getLevelData = (level = "easy") => {
	let data = content.levels.filter((lvl) => lvl.label.toString().toLowerCase() === level.toString().toLowerCase())
	// console.log(shuffle(data[0].data).slice(0, 10));
	return shuffle(data[0].data).slice(0, 10) ?? null
	// return data[0].data ?? null;
}
