const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_green.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./cover_page_new01.gif"),
			class: "image-five feeding_baby",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page13Text text-center",
			textIndivData: [
				{
					textData: "कागले गुँडमा आँ–आँ गरी बसेका बचेरालाई रोटी ख्वायो।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1.2 },
						{ start: 2.1 },
						{ start: 3.5 },
						{ start: 4 },
						{ start: 5 },
						{ start: 6.1 },
						{ start: 6.8 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./13.ogg"),
			delay: 500,
		},
		{
			url: audios("./a13.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
