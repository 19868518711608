import React, { useEffect, useReducer } from "react"
import _ from "underscore"

import Stage1 from "./pages/Stage/Stage1"
//styles
import "./decoding.styles.scss"

// helpers
import {
	// getRandomInt,
	reducer,
	//   getLevelData
} from "./helpers/helpers"
import { getQueryVariable } from "../mainHelper"
import gameContent from "./content/gameContent"
//components
import CustomModal from "./components/LevelCompleteModal/CustomModal"
import Toolbar from "./components/Toolbar/Toolbar"

//images
import loaderSvg from "../../../assets/ball-triangle.svg"

export const DispatchContext = React.createContext(null)
export const StateContext = React.createContext(null)

const DecodingGame = () => {
	const settings = { audioHints: true }
	const [isToolbarAbs, setIsToolbarAbs] = React.useState(false)
	const useWindowSize = () => {
		const isClient = typeof window === "object"

		const getSize = () => {
			let width = window.innerWidth <= 1600 ? window.innerWidth : 1600
			let height = window.innerHeight
			let availableHeight = window.innerHeight - 56

			// console.log('wh: ', width, height);
			let adjustedHeight = availableHeight > (width * 9) / 16 ? (width * 9) / 16 : height - 56
			let adjustedwidth = (adjustedHeight * 16) / 9

			// console.log('awh: ', adjustedwidth, adjustedHeight);
			return {
				width: isClient ? Math.ceil(adjustedwidth) : undefined,
				height: isClient ? Math.ceil(adjustedHeight) : undefined,
			}
		}

		const windowSize = React.useRef(getSize())
		// const [windowSize, setWindowSize] = React.useState(getSize);

		// resize hook
		React.useEffect(() => {
			const isClient = typeof window === "object"
			if (!isClient) {
				return false
			}

			const handleResize = _.debounce(() => {
				windowSize.current = getSize()
				dispatch({
					type: "RESET_STAGE",
					values: { windowSize: windowSize.current },
				})
				windowSize.current.height > (windowSize.current.width * 9) / 16 || windowSize.current.width === 1600
					? setIsToolbarAbs(true)
					: setIsToolbarAbs(false)
			}, 500)
			handleResize()
			window.addEventListener("resize", handleResize)
			return () => window.removeEventListener("resize", handleResize)
			// eslint-disable-next-line
		}, [])
		// Empty array ensures that effect is only run on mount and unmount

		return windowSize.current
	}
	const [state, dispatch] = useReducer(reducer, {
		settings: settings,
		level: getQueryVariable("level") || "easy",
		stage: 0,
		phase: 1,
		transition: false,
		levelData: [],
		loadingAssets: true,
		intro: true,
		playing: false,
		phaseComplete: false,
		rewardComplete: false,
		levelComplete: false,
		windowSize: useWindowSize(),
	})

	//----------------------- NEXT Phase DEDUGGING Helper -------------------------
	var map = { 17: false, 39: false } //17: ctrl , 39: right arrow   <= keyCodes

	useEffect(() => {
		const keyDown = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = true
				if (map[17] && map[39]) {
					// FIRE EVENT
					dispatch({ type: "PHASE_COMPLETE", values: { phase: state.phase + 1, transition: true } })
				}
			}
		}
		window.addEventListener("keydown", keyDown)

		const keyUp = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = false
			}
		}
		window.addEventListener("keyup", keyUp)
		return () => {
			window.removeEventListener("keydown", keyDown)
			window.removeEventListener("keyup", keyUp)
		}
	})

	// -----------------------END NEXT ROUND -------------------------------

	useEffect(() => {
		const levelData = gameContent[state.level].slice(0, 6) ?? null

		// console.log('levelData', levelData, );

		dispatch({ type: "SET", values: { levelData: levelData } })

		// console.log(levelData, state);

		// const bgMusic = new Howl({
		// 	src: [bg_happy_place],
		// 	autoplay: false,
		// 	preload: true,
		// 	volume: 0.01,
		// 	loop: true,
		// 	onload: () => {
		// 		dispatch({ type: "LOADING_COMPLETE" })
		// 		// console.log("audioLoaded");
		// 	},
		// })

		// bgMusic.play();

		return () => {
			// bgMusic.unload()
		}
	}, [state.level])
	useEffect(() => {
		if (!localStorage.getItem("Decoder")) {
			localStorage.setItem("Decoder", JSON.stringify(state.settings))
		}

		const preloadImages = (urls, allImagesLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadImage = (url, anImageLoadedCallback) => {
				let img = new Image()
				img.onload = anImageLoadedCallback
				img.src = url
			}

			urls.forEach(function (url) {
				preloadImage(url, function () {
					loadedCounter++
					if (loadedCounter === toBeLoadedNumber) {
						allImagesLoadedCallback()
					}
				})
			})
		}

		let images = []
		// if (state.levelData[state.stage] && state.levelData[state.stage + 1]) {
		// 	images = images.concat([
		// 		state.levelData[state.stage].incentiveImage[0].src,
		// 		state.levelData[state.stage + 1].incentiveImage[0].src,
		// 	])
		// }

		//remove this later
		dispatch({ type: "LOADING_COMPLETE" })

		preloadImages(images, () => {
			// console.log("preloaded");
			dispatch({ type: "LOADING_COMPLETE" })
		})
	}, [state])

	let windowSize = useWindowSize()

	return (
		<div className="decoding-container">
			<div
				className="game-container"
				id="game-container"
				key={windowSize.width}
				style={{
					width: `${windowSize.width}px`,
					height: `${windowSize.height}px`,
				}}
			>
				{state.loadingAssets ? (
					<div className="game-loading">
						<div className="loader-inner">
							<img src={loaderSvg} className="loader" alt="loader" />
							<span>Loading...</span>
						</div>
					</div>
				) : (
					<DispatchContext.Provider value={dispatch}>
						<StateContext.Provider value={state}>
							<Stage1 key={`stage-${state.level}-${state.stage}`} />
						</StateContext.Provider>
					</DispatchContext.Provider>
				)}
				<DispatchContext.Provider value={dispatch}>
					<StateContext.Provider value={state}>
						<Toolbar
							key={`toolbar-${state.level}-${state.stage}`}
							totalPage={state.levelData.length}
							currentPage={state.stage}
							isToolbarAbs={isToolbarAbs}
						/>
					</StateContext.Provider>
				</DispatchContext.Provider>
				<CustomModal
					// isOpen={state.stageRewardComplete}
					ariaHideApp={false}
				/>
			</div>
		</div>
	)
}

export default DecodingGame
