export default [
	{
		key: "kacho",
		word: "काँचो",
		options: ["साँचो", "बाँजो", "भाँजो", "खाँचो"],
	},
	{
		key: "haanga",
		word: "हाँगा",
		options: ["टाँगा", "राँगा", "पाँगा", "छाँगा"],
	},
	{
		key: "challo",
		word: "चल्लो",
		options: ["सल्लो", "पल्लो", "खल्लो", "डल्लो"],
	},
	{
		key: "patthar",
		word: "पत्थर",
		options: ["पत्कर", "सत्कार", "अत्तर", "मत्थर"],
	},
	{
		key: "jhuppa",
		word: "झुप्पा",
		options: ["टुप्पा", "थुक्पा", "लाक्पा", "आक्पा"],
	},
	{
		key: "gagro",
		word: "गाग्रो",
		options: ["बाख्रो", "काँक्रो", "पाङ्ग्रो", "च्याङ्ग्रो"],
	},
	{
		key: "pharsi",
		word: "फर्सी",
		options: ["तर्सी", "जर्सी", "कुर्सी", "पर्सी"],
	},
	{
		key: "ghanti",
		word: "घन्टी",
		options: ["अन्टी", "जन्ती", "कन्ती", "गन्ती"],
	},
	{
		key: "ticket",
		word: "टिकट",
		options: ["निकट", "बिकट", "नाटक", "पाठक"],
	},
	{
		key: "raati",
		word: "राती",
		options: ["पाती", "छाती", "जाती", "लाती"],
	},
	{
		key: "tika",
		word: "टिका",
		options: ["ढिका", "फिका", "झिका", "सिका"],
	},
	{
		key: "lahara",
		word: "लहरा",
		options: ["पहरा", "महरा", "टहरा", "छहरा"],
	},
	{
		key: "jarayo",
		word: "जरायो",
		options: ["खरायो", "हरायो", "करायो", "डरायो"],
	},
	{
		key: "aamala",
		word: "अमला",
		options: ["कमला", "झमेला", "ठमेल", "गमला"],
	},
	{
		key: "ping",
		word: "पिङ",
		options: ["हिङ", "सिङ", "रिङ", "थिङ"],
	},
	{
		key: "taxi",
		word: "ट्याक्सी",
		options: ["म्याक्सी", "लप्सी", "पेप्सी", "खाग्सी"],
	},
]
