const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page_02_a.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideTextRight text-center",
			textIndivData: [
				{
					textData: "ग्रीष्म ऋतु",
					textClass: "fz-6 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 0.75 }],
				},
				{
					textData: "रूखमा लटरम्म फलहरू",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 1.65 }, { start: 2.55 }, { start: 3.45 }],
				},
				{
					textData: "फलेका छन्। रूखका ",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 4.2 }, { start: 4.75 }, { start: 6.15 }],
				},
				{
					textData: "हाँगामा बच्चाहरू फल",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 6.15 }, { start: 6.8 }, { start: 7.7 }, { start: 8.55 }],
				},
				{
					textData: "टिप्‍न चढेका छन्।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 8.9 }, { start: 9.35 }, { start: 9.95 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3.ogg"),
			delay: 700,
		},
		{
			url: audios("./a4.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.1,
		},
	],
}

export default content
