const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./thinking.png"),
			class: "image-12",
			imgdiv: "imageCover",
		},
		{
			src: images("./bunu_thinking.gif"),
			class: "image-12",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "साथीलाई अहिले नै देखाउँला भनेको।",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.2 }, { start: 1.7 }, { start: 1.9 }, { start: 2.6 }],
				},
				{
					textData: "होस् अब भरे खोजुँला!",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 4.1 }, { start: 4.7 }, { start: 5.1 }, { start: 5.6 }, { start: 7 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./11.ogg"),
			delay: 500,
		},
	],
}

export default content
