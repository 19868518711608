const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg01.png"),
			class: "image-five bg01",
			imgdiv: "imageCover zoomInroti",
		},
		{
			src: images("./water_wave01.png"),
			class: "image-five water_wave01",
			imgdiv: "imageCover zoomInroti",
		},
		{
			src: images("./water_wave02.png"),
			class: "image-five water_wave02",
			imgdiv: "imageCover zoomInroti",
		},
		{
			src: images("./water_wave03.png"),
			class: "image-five water_wave03",
			imgdiv: "imageCover zoomInroti",
		},
		{
			src: images("./crow_dropping_roti_river.gif"),
			class: "image-five crow_roti02 ",
			imgdiv: "imageCover zoomInroti",
		},
	],
	texts: [
		{
			textDivClass: "story-text page7Text text-center",
			textIndivData: [
				{
					textData: "कागले रोटीलाई खोलाको छेउको छिपछिपे पानीमा राख्यो।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1 },
						{ start: 2.1 },
						{ start: 2.8 },
						{ start: 3.8 },
						{ start: 4.7 },
						{ start: 5.4 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7.ogg"),
			delay: 2000,
		},
		{
			url: audios("./a6.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
