const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./box_01.png"),
			class: "image-one imgbox-sm",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./kopila_put_book_to_bag.gif"),
			class: "image-one girl-p2a",
			imgdiv: "imageCover",
		},
		{
			src: images("./kopila_put_book_to_bag.png"),
			class: "image-one girl-p2a-png",
			imgdiv: "imageCover",
		},
		{
			src: images("./girl_page03.png"),
			class: "image-one girl-p2b",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center textbox textbox-ban two-lines-texts",
			textIndivData: [
				{
					textData: "कोपिलालाई उमेर नपुग्दै स्कूल जाने रहर थियो।",
					textClass: "m-0 one-line text-p2a textStyles",
					timestamps: [
						{ start: 0 },
						{ start: 1.5 },
						{ start: 1.8 },
						{ start: 2.7 },
						{ start: 3.15 },
						{ start: 3.55 },
						{ start: 3.9 },
					],
				},
				{
					textData: "सानैदेखि झुत्रो झोलामा किताब बोक्दै हिँड्थिन्।",
					textClass: "m-0 one-line text-p2b textStyles",
					timestamps: [{ start: 5.55 }, { start: 6.5 }, { start: 7.05 }, { start: 7.9 }, { start: 8.3 }, { start: 8.9 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s2.ogg"),
			delay: 500,
		},
	],
}

export default content
