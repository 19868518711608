import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "असल साथी",
	key: "asal-sathi",
	coverImage: images("./coverpage.png"),
	content: PageContent,
	credits: {
		illustrator: "अनुश्री लामा",
		template: "default",
		bg: images("./coverpage.png"),
		titleAudio: audios("./p1_s0.ogg"),
	},
	bgColor: "white",
	defaultTextAnimation: true,
}

export default content
