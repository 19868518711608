const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_back_page_3.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./sun01.png"),
			class: "image-one sunpage4",
			imgdiv: "imageCover",
		},
		{
			src: images("./bg_front_page_3.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./play_football.gif"),
			class: "image-one img03",
			imgdiv: "imageCover",
		},
		{
			src: images("./kids_play_foot_ball_300.gif"),
			class: "image-one play",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page4Text text-center",
			textIndivData: [
				{
					textData: "सँगै सँगै खेल्छन्।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.85 }, { start: 1.25 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./4.ogg"),
			delay: 500,
		},
		{
			url: audios("./ball.ogg"),
			isBg: true,
			volume: 0.2,
		},
		{
			url: audios("./kidsplay.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
