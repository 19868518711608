import React, { useState, useContext } from "react"
import { Howl } from "howler"
import CustomModal from "../../components/LevelCompleteModal/CustomModal"
// import Modal from 'react-modal';

// assets
import "./stage.styles.scss"
import Hint from "../../components/Hint/Hint"
import Options from "../../components/Options/Options"
import Player from "../../components/Player/Player"
import Dream from "../../components/Dream/Dream"
import Toolbar from "../../components/Toolbar/Toolbar"

import popAudio from "../../content/audios/sound_effects/pop.ogg"

// import { getLevelData } from "../../helpers/helpers";

import { StateContext, DispatchContext } from "../../MatchTheWordGame"
import gsap from "gsap"

import bg_1 from "./assets/bg_01.png"
import bg_water from "./assets/bg_water.png"
import bg_buttom from "./assets/bg_buttom.png"
import DreamImg from "../../components/Dream/assests/nuts_clouds.png"

//page1
import p1_bg from "./assets/bg_new-min.jpg"
import p1_waterPatch from "./assets/water_blue.png"
import p1_stones from "./assets/stones.png"
import p1_squirrel1 from "./assets/squirrel01.png"
import p1_squirrel2 from "./assets/squirrel02.png"
import nuts3Squirrel from "./assets/squirrel_with_nuts.png"

const Stage1 = (props) => {
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)
	const [gameBgImg, setGameBgImg] = useState(bg_water)
	const [introSquImg, setIntroSquImg] = useState(p1_squirrel2)
	React.useEffect(() => {
		const popHowl = new Howl({
			src: [popAudio],
			preload: true,
			volume: 0.5,
			loop: false,
		})
		if (state.stage === 0) {
			dispatch({ type: "CHANGE_BG_AUDIO", values: { bgMusic: "happyTune" } })

			if (state.rewardComplete === false) {
				let introTl = new gsap.timeline({
					onComplete: function () {
						dispatch({ type: "REWARD_COMPLETE" })
					},
				})

				introTl
					.add(function () {
						setIntroSquImg(p1_squirrel1)
					})
					.to("#introMtw-div", {
						scale: 2,
						rotation: 0.01,
						x: "0%",
						y: "-117%",
						ease: "power1.easeInOut",
						duration: 1.5,
						delay: 3,
					})
					.to("#introMtw-div", { y: "20%", rotation: 0.01, ease: "power1.easeInOut", duration: 6 })
					.add(function () {
						setIntroSquImg(p1_squirrel2)
					})
					.to("#introMtw-div", { scale: 3, rotation: 0.01, x: "22%", y: "68%", ease: "power1.easeOut", duration: 1.5 })
					.to(
						".sm-bubbleImg",
						{
							scale: 1,
							opacity: 1,
							duration: 0.5,
							onComplete: function () {
								popHowl.play()
							},
						},
						"<0.5"
					)
					.to(
						".md-bubbleImg",
						{
							scale: 1,
							opacity: 1,
							duration: 0.8,
							onComplete: function () {
								popHowl.play()
							},
						},
						"<0.5"
					)
					.to(
						".dreamImg",
						{
							scale: 1,
							opacity: 1,
							duration: 0.6,
							onComplete: function () {
								popHowl.play()
							},
						},
						"<0.5"
					)
			}

			return
		} else if (state.stage === 1) {
			dispatch({ type: "CHANGE_BG_AUDIO", values: { bgMusic: "riverTune" } })
			setGameBgImg(bg_1)
			gsap.set(".game-div", {
				backgroundPosition: "49% 0%",
			})
		} else if (state.stage > 1) {
			gsap.to(".game-div", {
				backgroundPosition: "49% 40%",
				duration: 1,
			})
			setGameBgImg(bg_water)
			if (state.stage === 9) {
				dispatch({ type: "CHANGE_BG_AUDIO", values: { bgMusic: "riverTune" } })
			}
		}

		if (state.isLastScene) {
			setGameBgImg(bg_buttom)
			dispatch({ type: "CHANGE_BG_AUDIO", values: { bgMusic: "happyTune" } })
			gsap.to(".game-div", {
				backgroundPosition: "42% 76%",
				duration: 1,
			})
			gsap.to(".waterPatch", {
				top: "-69%",
			})
			gsap.to(".player-stone_Img, .playerFizz", {
				webkitFilter: "blur(0px)",
				duration: 1,
			})

			if (state.rewardComplete) {
				gsap.set(".squirrel", { xPercent: -260, yPercent: 270 })
			} else {
				gsap.set(".squirrel", { xPercent: 0, yPercent: 0 })
			}
		}
		// else if(state.stage === 9) {
		// gsap.to(".game-div", {
		// 		backgroundPosition: "49% 77%",
		// 		duration: 1,
		// 	})
		// 	setGameBgImg('./bg_buttom.png');
		// }
	}, [state, dispatch])

	React.useEffect(() => {
		const preloadImages = (urls, allImagesLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadImage = (url, anImageLoadedCallback) => {
				let img = new Image()
				img.src = url
				img.onload = anImageLoadedCallback
			}

			urls.forEach(function (url) {
				preloadImage(url, function () {
					loadedCounter++
					// console.log('Number of loaded images: ' + loadedCounter);
					if (loadedCounter === toBeLoadedNumber) {
						allImagesLoadedCallback()
					}
				})
			})
		}

		let images = []
		if (state.levelData[state.stage].hints && state.stage < state.levelData.length - 1) {
			images = images.concat([state.levelData[state.stage].hints.image, state.levelData[state.stage + 1].hints.image])
		}

		preloadImages(images, () => {
			// console.log("preloaded");
		})
	})

	// console.log("stage Redraw reupdate");
	return (
		<StateContext.Consumer>
			{(state) => {
				if (state === undefined) {
					throw new Error("StateConsumer must be used within a StateProvider")
				}
				let stageData = state.levelData[state.stage]
				return (
					<div className="game-stage-mtw">
						{state.stage === 0 && (
							<div id="introMtw-div">
								<img src={p1_bg} id="intro-bg" className="intro-bg" alt="game background" />
								<img src={p1_waterPatch} id="intro-waterPatch" alt="game water patch" />
								<img src={p1_stones} id="intro-stones" alt="game stones" />
								<img
									src={introSquImg}
									id="intro-squirrelF"
									style={{ transform: "translate(2%, -32%) scale(0.06)" }}
									alt="game squirrel looking at nuts"
								/>
								<div className="intro-fizz fizzStone1">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>
								<div className="intro-fizz fizzStone2">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>
								<div className="intro-fizz fizzStone3">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>
								<div className="intro-fizz fizzStone4">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>
								<div className="intro-fizz fizzStone5">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>
								<div className="intro-fizz fizzStone6">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>
								<div className="intro-fizz fizzStone7">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>
								<div className="intro-fizz fizzStone8">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>

								<Dream DreamImg={DreamImg} />
							</div>
						)}

						{state.stage !== 0 && state.playing && (
							<>
								<div
									id="game-div"
									className="game-div"
									style={{
										backgroundImage: `url(${gameBgImg})`,
									}}
								>
									{state.stage > 0 && (
										<div
											className={`waterPatch ${state.stage === 1 ? "waterPatchL2" : ""} ${
												state.isLastScene ? "waterPatchL3" : ""
											} `}
										></div>
									)}
								</div>
								<Player />
								{!state.isLastScene && (
									<>
										<Options key={`options-${state.level}-${state.stage}`} levelData={state.levelData[state.stage]} />

										<Hint
											image={stageData.incentiveImage[0].src}
											audio={stageData.audioHint}
											word={stageData.word}
											isWordCompleted={state.wordComplete}
											key={`hint-${state.level}-${state.stage}`}
										/>
									</>
								)}
								{state.isLastScene && state.rewardComplete && (
									<div className={`endMsg-modal`}>
										<div className={`msg-modal ${state.isLastScene && state.rewardComplete ? "scaleModal" : ""} `}>
											<img
												src={nuts3Squirrel}
												className={`${state.isLastScene && state.rewardComplete ? "scaleModal jumpSqu" : ""} `}
												alt="happy squirrel"
											/>
										</div>
									</div>
								)}
							</>
						)}
						<Toolbar totalPage={state.levelData.length} currentPage={state.stage} />

						<CustomModal
							isOpen={state.levelComplete}
							// isOpen={state.stageRewardComplete}
							ariaHideApp={false}
						/>
					</div>
				)
			}}
		</StateContext.Consumer>
	)
}

// Stage.whyDidYouRender = true;
// const StageMemo = React.memo(Stage);
// StageMemo.whyDidYouRender = true;

export default Stage1
