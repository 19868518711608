const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./four_monkeys.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeftText text-center",
			textIndivData: [
				{
					textData: "चारओटा बाँदर मिली",
					textClass: "fz-5 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 2 }, { start: 2.7 }],
				},
				{
					textData: "रूखमा चढाए।",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 3.6 }, { start: 4.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s4.ogg"),
			delay: 3000,
		},
	],
}

export default content
