import React from "react"

import normalIcon from "../icons/controls/next_blue.svg"
import successIcon from "../icons/controls/next_green.svg"
import disabledIcon from "../icons/controls/next_grey.svg"

const nextBtn = (props) => {
	const className = props.className || "next__btn"
	return (
		<span
			key="nxtBtn"
			onClick={
				props.disabled
					? () => {
							return
					  }
					: props.nextBtnClick
			}
			className={props.disabled ? `${className} disabled` : className}
		>
			<img className="img-fluid" src={props.success ? successIcon : props.disabled ? disabledIcon : normalIcon} alt="next" />
		</span>
	)
}

export default nextBtn
