const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./bg_monkey_fishing.png"),
			class: "image-five",
			imgdiv: "imageCover ",
		},
		{
			src: images("./monkey-fishing.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center blacktext",
			textIndivData: [
				{
					textData: "बाँदरले खाएर",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1 }, { start: 2.15 }],
				},
				{
					textData: "माछा मार्न गयो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2.56 }, { start: 3.2 }, { start: 4.1 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./41.ogg"),
			delay: 500,
		},
	],
}

export default content
