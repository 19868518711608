const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./cover2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles:{
				backgroundColor: '#C0DFE5',
			}
		},
	],
	texts: [
		
	],
	sounds: [
		{
			url: audios("./timi_k_khanchau.ogg"),
			delay: 500,
		},
	],
}

export default content
