import React from "react"
import Header from "Components/Layout/Header"
import MatchTheWord from "Containers/Play/MatchTheWord/MatchTheWordGame"

const bp = () => {
	return (
		<div className="activity__container">
			<Header storyName="शब्द बनाऊ" isGame={true} />
			<MatchTheWord />
		</div>
	)
}

export default bp
