// configs
import gameContent from "../content/gameContent"
import shuffle from "shuffle-array"

export const getLevelData = (level = "easy") => {
	let data = gameContent.levels.filter((lvl) => lvl.label.toString().toLowerCase() === level.toString().toLowerCase())
	return shuffle(data[0].data).slice(0, 10) ?? null
}

const deepCopyFunction = (inObject) => {
	let outObject, value, key

	if (typeof inObject !== "object" || inObject === null) {
		return inObject // Return the value if inObject is not an object
	}

	// Create an array or object to hold the values
	outObject = Array.isArray(inObject) ? [] : {}

	for (key in inObject) {
		value = inObject[key]

		// Recursively (deep) copy for nested objects, including arrays
		outObject[key] = deepCopyFunction(value)
	}

	return outObject
}

export const getRandomInt = (min, max) => {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max + 1 - min)) + min //The maximum and the minimum are both inclusive
}

const compareObjects = (o1, o2) => {
	for (let p in o1) {
		if (o1.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false
			}
		}
	}

	for (let p in o2) {
		if (o2.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false
			}
		}
	}
	return true
}

export const reducer = (state, action) => {
	let newState
	switch (action.type) {
		case "SET":
			newState = {
				...state,
				...action.values,
				backUpLevelData: deepCopyFunction(action.values.levelData),
			}
			break

		case "RESET":
			newState = {
				...state,
				levelData: shuffle(state.backUpLevelData),
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				levelComplete: false,
				isLastScene: false,
				stage: 0,
			}
			break

		case "RESET_STAGE":
			newState = {
				...state,
				...action.values,
				levelData: state.backUpLevelData,
				intro: false,
				playing: true,
				stageComplete: state.stageComplete,
				rewardComplete: false,
				levelComplete: false,
				wordComplete: false,
				isLastScene: false,
			}
			break

		case "LOADING_COMPLETE":
			newState = {
				...state,
				loadingAssets: false,
			}
			break
		case "START_GAME":
			newState = {
				...state,
				currentPage: "GAME_START",
			}
			break

		case "START_PLAY":
			newState = {
				...state,
				intro: false,
				playing: true,
				stageComplete: false,
			}
			break

		case "GAME_PLAY":
			newState = {
				...state,
				intro: false,
				playing: true,
				bg: "bg_01.png",
			}
			break

		case "STAGE_COMPLETE":
			newState = {
				...state,
				intro: false,
				playing: false,
				stageComplete: true,
			}
			break
		case "WORD_COMPLETE":
			newState = {
				...state,
				wordComplete: true,
			}
			break
		case "LASTSCENE":
			newState = {
				...state,
				isLastScene: true,
			}
			break

		case "WORD_COMPLETE_RESET":
			newState = {
				...state,
				wordComplete: false,
			}
			break

		case "REWARD_COMPLETE":
			newState = {
				...state,
				rewardComplete: true,
			}
			break

		case "PREV_STAGE":
			newState = {
				...state,
				levelData: state.backUpLevelData,
				intro: false,
				playing: true,
				stageComplete: false,
				rewardComplete: false,
				stage: state.stage - 1,
				wordComplete: false,
				isLastScene: false,
			}
			break

		case "NEXT_STAGE":
			// console.log("next stage", state.levelData.length, state.stage, state.levelData.length > state.stage);
			newState =
				state.levelData.length > state.stage + 1
					? {
							...state,
							intro: false,
							playing: true,
							stageComplete: false,
							rewardComplete: false,
							stage: state.stage + 1,
							wordComplete: false,
					  }
					: {
							...state,
							intro: false,
							playing: false,
							stageComplete: true,
							rewardComplete: true,
							levelComplete: true,
							wordComplete: false,
					  }
			break
		case "CHANGE_BG_AUDIO":
			newState = {
				...state,
				...action.values,
			}
			break

		default:
			newState = state
	}
	if (compareObjects(newState, state)) {
		return state
	} else {
		return newState
	}
}
