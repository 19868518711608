const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_page_06.jpg"),
			class: "image-one dawn",
			imgdiv: "imageCover",
		},
		{
			src: images("./sunset.jpg"),
			class: "image-one sunset",
			imgdiv: "imageCover",
		},
		{
			src: images("./they_are_going_home.gif"),
			class: "image-one img07",
			imgdiv: "imageCover",
		},
		{
			src: images("./yellow_sun01.png"),
			class: "image-one yellow_sun01",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page11Text text-center",
			textIndivData: [
				{
					textData: "अनि साँझमा दुबैजना फुल लिएर सँगै घरमा फर्के।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.75 },
						{ start: 1.25 },
						{ start: 2.0 },
						{ start: 2.2 },
						{ start: 2.75 },
						{ start: 3.15 },
						{ start: 3.55 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./11.ogg"),
			delay: 500,
		},
		{
			url: audios("./sunset.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
