const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./girl_standing.svg"),
			class: "image-one imgbox-md girlbag-p13",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./outline-p10-white.png"),
			class: "image-one outline-p13",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p13 textbox textbox-ban",
			textIndivData: [
				{
					textData: "जहाँ जाँदा पनि ब्याग बोक्‍न छाडिनन्।",
					textClass: "m-0 one-line text-p13a textStyles",
					timestamps: [{ start: 0 }, { start: 1 }, { start: 1.4 }, { start: 2.3 }, { start: 2.8 }, { start: 3.2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s13.ogg"),
			delay: 500,
		},
	],
}

export default content
