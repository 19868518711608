const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./horse-with-mask.gif"),
			class: "image-10",
			imgdiv: "imageCover lightbluebg",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center browntext",
			textIndivData: [
				{
					textData: "घोडाले सुनको",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.05 }],
				},
				{
					textData: "मकुन्डो लगायो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 1.95 }, { start: 2.6 }, { start: 3.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./8.ogg"),
			delay: 500,
		},
	],
}

export default content
