const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./sad-kopila-walk.png"),
			class: "image-one sadKopilaWalk",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./friend-top-p6.png"),
			class: "image-one friendTop-p6",
			imgdiv: "imageCover",
		},
		{
			src: images("./friend-bottom-p6.png"),
			class: "image-one friendBottom-p6",
			imgdiv: "imageCover",
		},
		{
			src: images("./outline-p6-white.png"),
			class: "image-one outline-p6",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p6 textbox textbox-ban" ,
			textIndivData: [
				{
					textData: "साथीहरूले कोपिलालाई जिस्काएर धपाए।",
					textClass: "m-0 one-line text-p6a textStyles",
					timestamps: [{ start: 0 }, { start: 1.75 }, { start: 2.9 }, { start: 3.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s6.ogg"),
			delay: 500,
		},
	],
}

export default content
