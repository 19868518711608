import React, {useContext} from "react"
import Scene1 from "./scene1"
import Scene2 from "./scene2"
import Scene3 from "./scene3"
import Scene4 from "./scene4"
import Scene5 from "./scene5"
import Scene6 from "./scene6"
import Scene7 from "./scene7"
import Scene8 from "./scene8"
import { StateContext } from "../../ShapeColorGame"

const SceneAll = (({stageData, assets, story, id})=>{
    const state = useContext(StateContext);
    
    return(
        <>
        {state.stage === 0 && (
            <><Scene1 
            stageData={stageData}
            assets={assets}
            story = {story}
            id = {id}
            /></>
        )}
        {state.stage === 1 && (
            <><Scene2 
            stageData={stageData}
            assets={assets}
            story = {story}
            id = {id}
            /></>
        )}  
        {state.stage === 2 && (
            <><Scene3 
            stageData={stageData}
            assets={assets}
            story = {story}
            id = {id}
            /></>
        )} 
        {state.stage === 3 && (
            <><Scene4 
            stageData={stageData}
            assets={assets}
            story = {story}
            id = {id}
            /></>
        )}   
        {state.stage === 4 && (
            <><Scene5 
            stageData={stageData}
            assets={assets}
            story = {story}
            id = {id}
            /></>
        )} 
        {state.stage === 5 && (
            <><Scene6 
            stageData={stageData}
            assets={assets}
            story = {story}
            id = {id}
            /></>
        )} 
        {state.stage === 6 && (
            <><Scene7 
            stageData={stageData}
            assets={assets}
            story = {story}
            id = {id}
            /></>
        )} 
        {state.stage === 7 && (
            <><Scene8 
            stageData={stageData}
            assets={assets}
            story = {story}
            id = {id}
            /></>
        )} 
        </>  
    )
});

export default SceneAll