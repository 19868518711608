import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./bg-04b.png"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./dad_breaking_stone.gif"),
			class: "image-five dad01",
			imgdiv: "imageCover dad_breaking_stone",
		},
		{
			src: images("./dad_breaking_stone_talk.gif"),
			class: "image-five dad01",
			imgdiv: "imageCover dad_breaking_stone_talk",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page4dad rightMidBubble text-center",
			textIndivData: [
				{
					textData: "तिमी त अब छिट्टै स्कूल जान थाल्नुपर्छ है।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.9 },
						{ start: 1.3 },
						{ start: 1.8 },
						{ start: 2.1 },
						{ start: 2.4 },
						{ start: 2.6 },
						{ start: 3.5 },
						{ start: 4.0 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3.ogg"),
			delay: 4000,
		},
		{
			url: audios("./a3.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
