import React from "react"
import WordNumber from "./WordNumber"

import "./toolbar.styles.scss"

const Toolbar = ({ totalPage, currentPage }) => {
	return (
		<div className="gameactivity__nav" style={{ zIndex: 10 }}>
			<div className="container">
				<div className="row align-items-center justify-content-center">
					<div className="col-6">
						<WordNumber totalWords={totalPage} currentWord={currentPage} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Toolbar
