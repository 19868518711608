const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bagItems.svg"),
			class: "image-one bagItems-p17",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./bag.svg"),
			class: "image-one bag-p17",
			imgdiv: "imageCover",
		},
		{
			src: images("./outline-p4-white.png"),
			class: "image-one outline-p17",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p17 textbox textbox-ban two-lines-texts",
			textIndivData: [
				{
					textData: "ब्यागमा आफूले बटुलेका सामानहरू राखिन्।",
					textClass: "m-0 one-line textStyles text-p17a",
					timestamps: [{ start: 0 }, { start: 1.4 }, { start: 1.9 }, { start: 2.48 }, { start: 3.4 }],
				},
				{
					textData: "आमाले दिनुभएको कापी कलम पनि राखिन्।",
					textClass: "m-0 one-line textStyles text-p17b",
					timestamps: [{ start: 4.88 }, { start: 5.3 }, { start: 6.6 }, { start: 7.1 }, { start: 7.5 }, { start: 7.85 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s17(combined).ogg"),
			delay: 500,
		},
	],
}

export default content
