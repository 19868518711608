import shuffle from "shuffle-array"
const audios = require.context("./audios", true)
const images = require.context("./images", true)
const content = {
	// instructionurl: audios("./s0.ogg"),
	totalPage: 74,
	textDivClass: " text-center querytext",
	textIndivData: [
		{
			textData: "दिइएको शब्दको उच्चारण गर्दै रेकर्ड गरी सुन।",
			textClass: "fz-5 m-0 one-line",
		},
	],

	wordBank: shuffle([
		"रुख",
		"रङ",
		"खाट",
		"झोला",
		"धारा",
		"फूल",
		// "फेद",
		"फल",
		"पानी",
		"पिङ",
		"पात",
		"छाना",
		// "आरा",
		"बाजा",
		"धान",
		"घुम",
		"बाटो",
		"साथी",
		"पाथी",
		"पाठी",
		"गोडा",
		"घोडा",
		"ढोका",
		"हात",
		"टीका",
		"गाउँ",
		"पैसा",
		"राजा",
		"माछा",
		"भात",
		"खसी",
		"कान",
		"पाडो",
		"गाई",
		"भेडा",
		"हात्ती",
		"टोपी",
		"छाला",
		// "गाडा",
		"सुन",
		"मुख",
		"टाउको",
		"टेबुल",
		"किताब",
		"कपाल",
		"मानिस",
		"कमिला",
		"पहाड",
		"अचार",
		"कुखुरा",
		"परेवा",
		"बिरालो",
		"रिबन",
		"कुकुर",
		"पोखरी",
		"कछुवा",
		"शरीर",
		"कागज",
		"बाँदर",
		"दाँत",
		"हाँगा",
		"ताँती",
		"बाँस",
		"हिउँ",
		"भाँडा",
		"औँठी",
		"चाँदी",
		"कैँची",
		"गैँडा",
		"साइकल",
		"हेडफोन",
		"बाख्रा",
		"उपियाँ",
		"भँगेरा",
		"पानीहाँस",
		"खानेकुरा",
		"सूर्य",
	]),
	hintImage: {
		रुख: [images("./rukh.png"), images("./rukh.png"), audios("./rukh.ogg")],
		रङ: [images("./rang.png"), images("./rang.png"), audios("./rang.ogg")],
		खाट: [images("./khat.png"), images("./khat.png"), audios("./khat.ogg")],
		झोला: [images("./jhola.png"), images("./jhola.png"), audios("./jhola.ogg")],
		धारा: [images("./dhara.png"), images("./dhara.png"), audios("./dhara.ogg")],
		फूल: [images("./ful.png"), images("./ful.png"), audios("./ful.ogg")],
		// फेद: [images("./fed.png"), images("./fed.png"), audios("./fed.ogg")],
		फल: [images("./fal.png"), images("./fal.png"), audios("./fal.ogg")],
		पानी: [images("./pani.png"), images("./pani.png"), audios("./pani.ogg")],
		पिङ: [images("./ping.png"), images("./ping.png"), audios("./ping.ogg")],
		पात: [images("./paat.png"), images("./paat.png"), audios("./paat.ogg")],
		छाना: [images("./chhana.png"), images("./chhana.png"), audios("./chhana.ogg")],
		// आरा: [images("./aara.png"), images("./aara.png"), audios("./aara.ogg")], image not ready
		बाजा: [images("./baja.png"), images("./baja.png"), audios("./baja.ogg")],
		धान: [images("./dhaan.png"), images("./dhaan.png"), audios("./dhaan.ogg")],
		घुम: [images("./ghum.png"), images("./ghum.png"), audios("./ghum.ogg")],
		बाटो: [images("./bato.png"), images("./bato.png"), audios("./bato.ogg")],
		साथी: [images("./sathi.png"), images("./sathi.png"), audios("./sathi.ogg")],
		पाथी: [images("./pathhi.png"), images("./pathhi.png"), audios("./pathhi.ogg")],
		पाठी: [images("./pathi.png"), images("./pathi.png"), audios("./pathi.ogg")],
		गोडा: [images("./goda.png"), images("./goda.png"), audios("./goda.ogg")],
		घोडा: [images("./ghoda.png"), images("./ghoda.png"), audios("./ghoda.ogg")],
		ढोका: [images("./dhoka.png"), images("./dhoka.png"), audios("./dhoka.ogg")],
		हात: [images("./haat.png"), images("./haat.png"), audios("./haat.ogg")],
		टीका: [images("./tauko.png"), images("./tauko.png"), audios("./tauko.ogg")],
		गाउँ: [images("./gaun.png"), images("./gaun.png"), audios("./gaun.ogg")],
		पैसा: [images("./paisa.png"), images("./paisa.png"), audios("./paisa.ogg")],
		राजा: [images("./raja.png"), images("./raja.png"), audios("./raja.ogg")],
		माछा: [images("./machha.png"), images("./machha.png"), audios("./machha.ogg")],
		भात: [images("./bhat.png"), images("./bhat.png"), audios("./bhat.ogg")],
		खसी: [images("./khasi.png"), images("./khasi.png"), audios("./khasi.ogg")],
		कान: [images("./kaan.png"), images("./kaan.png"), audios("./kaan.ogg")],
		पाडो: [images("./pado.png"), images("./pado.png"), audios("./pado.ogg")],
		गाई: [images("./gai.png"), images("./gai.png"), audios("./gai.ogg")],
		भेडा: [images("./veda.png"), images("./veda.png"), audios("./veda.ogg")],
		हात्ती: [images("./hatti.png"), images("./hatti.png"), audios("./hatti.ogg")],
		टोपी: [images("./topi.png"), images("./topi.png"), audios("./topi.ogg")],
		छाला: [images("./chhala.png"), images("./chhala.png"), audios("./chhala.ogg")],
		// गाडा: [images("./gada.png"), images("./gada.png"), audios("./gada.ogg")], audio
		सुन: [images("./soon.png"), images("./soon.png"), audios("./soon.ogg")],
		मुख: [images("./mukh.png"), images("./mukh.png"), audios("./mukh.ogg")],
		टाउको: [images("./tauko.png"), images("./tauko.png"), audios("./tauko.ogg")],
		टेबुल: [images("./table.png"), images("./table.png"), audios("./table.ogg")],
		किताब: [images("./kitab.png"), images("./kitab.png"), audios("./kitab.ogg")],
		कपाल: [images("./kapal.png"), images("./kapal.png"), audios("./kapal.ogg")],
		मानिस: [images("./manis.png"), images("./manis.png"), audios("./manis.ogg")],
		कमिला: [images("./kamila.png"), images("./kamila.png"), audios("./kamila.ogg")],
		पहाड: [images("./pahad.png"), images("./pahad.png"), audios("./pahad.ogg")],
		अचार: [images("./achar.png"), images("./achar.png"), audios("./achar.ogg")],
		कुखुरा: [images("./kukhura.png"), images("./kukhura.png"), audios("./kukhura.ogg")],
		परेवा: [images("./parewa.png"), images("./parewa.png"), audios("./parewa.ogg")],
		बिरालो: [images("./biralo.png"), images("./biralo.png"), audios("./biralo.ogg")],
		रिबन: [images("./ribbon.png"), images("./ribbon.png"), audios("./ribbon.ogg")],
		कुकुर: [images("./kukur.png"), images("./kukur.png"), audios("./kukur.ogg")],
		पोखरी: [images("./pokhari.png"), images("./pokhari.png"), audios("./pokhari.ogg")],
		कछुवा: [images("./kachuwa.png"), images("./kachuwa.png"), audios("./kachuwa.ogg")],
		शरीर: [images("./sarir.png"), images("./sarir.png"), audios("./sarir.ogg")],
		कागज: [images("./kagaj.png"), images("./kagaj.png"), audios("./kagaj.ogg")],
		बाँदर: [images("./badar.png"), images("./badar.png"), audios("./badar.ogg")],
		दाँत: [images("./daat.png"), images("./daat.png"), audios("./daat.ogg")],
		हाँगा: [images("./haga.png"), images("./haga.png"), audios("./haga.ogg")],
		ताँती: [images("./tati.png"), images("./tati.png"), audios("./tati.ogg")],
		बाँस: [images("./baas.png"), images("./baas.png"), audios("./baas.ogg")],
		हिउँ: [images("./hiu.png"), images("./hiu.png"), audios("./hiu.ogg")],
		भाँडा: [images("./veda.png"), images("./veda.png"), audios("./veda.ogg")],
		औँठी: [images("./authi.png"), images("./authi.png"), audios("./authi.ogg")],
		चाँदी: [images("./chadi.png"), images("./chadi.png"), audios("./chadi.ogg")],
		कैँची: [images("./kaichi.png"), images("./kaichi.png"), audios("./kaichi.ogg")],
		गैँडा: [images("./gaida.png"), images("./gaida.png"), audios("./gaida.ogg")],
		साइकल: [images("./cycle.png"), images("./cycle.png"), audios("./cycle.ogg")],
		हेडफोन: [images("./headphone.png"), images("./headphone.png"), audios("./headphone.ogg")],
		बाख्रा: [images("./bakhra.png"), images("./bakhra.png"), audios("./bakhra.ogg")],
		उपियाँ: [images("./upiya.png"), images("./upiya.png"), audios("./upiya.ogg")],
		भँगेरा: [images("./vangera.png"), images("./vangera.png"), audios("./vangera.ogg")],
		पानीहाँस: [images("./panihaas.png"), images("./panihaas.png"), audios("./panihaas.ogg")],
		खानेकुरा: [images("./khanekura.png"), images("./khanekura.png"), audios("./khanekura.ogg")],
		सूर्य: [images("./surya.png"), images("./surya.png"), audios("./surya.ogg")],
	},
	contentList: [
		//Page1
		{
			background: "bg",
			audiourl: audios("./rukh.ogg"),
			answer: "ans",
			imageList: [
				{
					src: images("./record.png"),
					class: "",
					imgdiv: "imageCover record .fz-5",
					text: "बोल",
					id: "record",
				},
				{
					src: images("./stop.png"),
					class: "",
					imgdiv: "imageCover stop .fz-5",
					text: "रोक",
					id: "stop",
				},
				{
					src: images("./play.png"),
					class: "",
					imgdiv: "imageCover play .fz-5",
					text: "सुन",
					id: "play",
				},
			],
			hintImage: [
				{
					src: images("./rukh.png"),
					class: "imageone hintImg",
					imgdiv: "imageCover hintDiv",
				},
			],
			audioIcon: [
				{
					src: images("./volume.svg"),
					imgdiv: "audioImg imageCover",
					class: "imageone",
					audiourl: audios("./rukh.ogg"),
				},
			],
		},
	],
}
export default content
