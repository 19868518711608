import words from "./words"
// import shuffle from "shuffle-array"

const audio_words = require.context("../audios/level1", true)
const images = require.context("../images/level1", true)

const getCrayonColor = (key) => {
	let keyToColor = {
		black: {
			crayon: "#403E39",
			cover: "#98916F",
		},
		yellow: {
			crayon: "#D4A811",
			cover: "#B69D20",
		},
		blue: {
			crayon: "#7381FF",
			cover: "#A3AFF7",
		},
		green: {
			crayon: "#088A19",
			cover: "#579B12",
		},
		red: {
			crayon: "#C21313",
			cover: "#EF505C",
		},
	}

	return keyToColor[key]
}

const getAllLettersDetails = (letters) => {
	return letters.map((letter, index) => {
		let AudioHint = null
		try {
			AudioHint = audio_words(`./letters/${letter.letter}.ogg`)
		} catch (error) {
			console.log(`not found: audio_Letters: ${letter.letter}.ogg`)
			AudioHint = null
		}
		let LetterImgHint
		try {
			LetterImgHint = images(`./letters/${letter.letter}.svg`)
		} catch (error) {
			console.log(`not found: LETTER SVG: ${letter.letter}.svg`)
			LetterImgHint = null
		}
		return {
			key: `${letter.letter}_${index}`,
			letter: letter.letter,
			top: letter.top,
			left: letter.left,
			letterDropped: false,
			isDragDisabled: false,
			LetterImgHint: LetterImgHint,
			LetterAudio: AudioHint,
		}
	})
}
const getAllOptionsDetails = (options) => {
	return options.map((option) => {
		let AudioHint = null
		try {
			AudioHint = audio_words(`./words/${option.key}.ogg`)
		} catch (error) {
			console.log(`not found: audio_words: ${option.key}.ogg`)
			AudioHint = null
		}
		let ImgHint
		try {
			ImgHint = images(`./hints/img/${option.key}.png`)
		} catch (error) {
			console.log(`not found: IMG: ${option.key}.png`)
			ImgHint = null
		}

		let CrayonGif
		try {
			CrayonGif = images(`./hints/gif/${option.colorKey}.gif`)
		} catch (error) {
			console.log(`not found: GIF: ${option.colorKey}.gif`)
			CrayonGif = null
		}
		return {
			key: option.key,
			word: option.word,
			audio: AudioHint,
			crayonColor: getCrayonColor(option.colorKey),
			image: ImgHint,
			crayonGif: CrayonGif,
		}
	})
}
let finalObject = words.map((levelWords, index) => {
	return levelWords.map((word) => {
		let AudioHint = null
		try {
			AudioHint = audio_words(`./words/${word.key}.ogg`)
		} catch (error) {
			console.log(`not found: audio_words: ${word.key}.ogg`)
			AudioHint = null
		}
		let wordImg
		try {
			wordImg = images(`./words/${word.key}.svg`)
		} catch (error) {
			console.log(`not found: IMG: ${word.key}.svg`)
			wordImg = null
		}
		let hintImg
		try {
			hintImg = images(`./hints/img/${word.key}.png`)
		} catch (error) {
			console.log(`not found: IMG: ${word.key}.png`)
			hintImg = null
		}
		return {
			level: index,
			key: word.key,
			word: word.word,
			wordImg: wordImg,
			hintImg: hintImg,
			audio: AudioHint,
			detailedCorrectLetters: getAllLettersDetails(word.correctLetters),
			detailedOptions: getAllOptionsDetails(word.options),
			crayonColor: getCrayonColor(word.colorKey).crayon,
			// sound:
		}
	})
})

export default finalObject
