const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page09.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideleftbottom1 text-center",
			textIndivData: [
				{
					textData: "यसरी डोमाले उसको बारेमा गाउँलेहरूले भनेको सुनिन्।",
					textClass: "fz-6 m-0 one-line",
					timestamps: [
						{ start: 0 },
						{ start: 1.75 },
						{ start: 2.45 },
						{ start: 2.85 },
						{ start: 3.7 },
						{ start: 4.55 },
						{ start: 5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./10.ogg"),
			delay: 2000,
		},
	],
}

export default content
