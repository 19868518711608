const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-blue-sky",
	images: [
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudd-1 cloudd-anim-1",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudd-2 cloudd-anim-2",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudd-3 cloudd-anim-3",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudd-4 cloudd-anim-4",
		},
		{
			src: images("./13to24/hill.svg"),
			imgdiv: "imageCover hill-anim hill",
		},
		{
			src: images("./25to33/three.svg"),
			imgdiv: "imageCover catch-anim flying-2",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "कछुवा उनीहरूको शरीरमा अडियो। पानीहाँसहरू कछुवालाई आफ्‍ना शरीरमा लिएर बिस्तारै तल पोखरीमा झरे।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.7 },
						{ start: 1.45 },
						{ start: 2.1 },
						{ start: 3 },
						{ start: 4 },
						{ start: 4.78 },
						{ start: 5.18 },
						{ start: 5.8 },
						{ start: 6.5 },
						{ start: 7.1 },
						{ start: 7.5 },
						{ start: 8.2 },
						{ start: 9 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p5_s3.ogg"),
			delay: 500,
		},
	],
}

export default content
