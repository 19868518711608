import { fileCheck } from "helpers/files"
import { appear } from "styledElements/keyframes"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page8scene",
	images: [
		{
			src: images("./bg_painter_didi_new.jpg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./nima6_new.png"),
			imgdiv: "imageCover nima_walk-p8b",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center dialogueBubble bubble-sm-thin-left-o text-bubble-p9",
			textIndivData: [
				{
					textData: "म पनि पछि कलाकार बन्छु। ",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.15 },
						{ start: 1.8 },
						{ start: 2.3 },
						{ start: 2.8 },
					],
				},
			],
			styles:{
				top: '38%',
				width: '34%',
				padding: '2% 1% 7% 2% !important',
				opacity: 0,
				animation: `${appear} 1s 5000ms forwards linear`,
			}
		},

	],
	sounds: [
		{
			url: audios("./busyMarket.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./ma_pani_pachi_kalakar.ogg"),
			delay: 5000,
			volume: 1,
		},
		{
			url: audios("./blank.ogg"),
			delay: 12000,
			isBg: false,
			volume: 0,
		},
	],
}

export default content
