import React, { Component } from "react"
import PlayConfig from "Config/PlayConfig"
class PlayContainer extends Component {
	state = {
		PlayTag: "",
	}

	componentDidMount() {
		let playTag
		PlayConfig.play.forEach((item, index) => {
			if (this.props.match.params.playKey === item.playKey) {
				this.setState({
					PlayTag: require("EGRContent/Play" + item.playPath).default,
				})

				playTag = require("EGRContent/Play" + item.playPath).default
			} else {
				if (index === PlayConfig.play.length - 1 && !playTag) {
					this.props.history.push("/play")
				}
			}
		})
	}

	render() {
		const { PlayTag } = this.state

		return PlayTag ? <PlayTag /> : null
	}
}

export default PlayContainer
