import Page1 from "./Page1"
import Page2 from "./Page2"
import Page3 from "./Page3"
import Page4 from "./Page4"
import Page5 from "./Page5"
import Page6 from "./Page6"
import Page7 from "./Page7"
import Page8 from "./Page8"
import Page9 from "./Page9"
import Page10 from "./Page10"
import Page11 from "./Page11"
import Page12 from "./Page12"
import Page13 from "./Page13"
import Page14 from "./Page14"
import Page15 from "./Page15"
import Page16 from "./Page16"
import Page17 from "./Page17"
import Page18 from "./Page18"
import Page19 from "./Page19"

let content = [
	Page1,
	Page2,
	Page3,
	Page4,
	Page5,
	Page6,
	Page7,
	Page8,
	Page9,
	Page10,
	Page11,
	Page12,
	Page13,
	Page14,
	Page15,
	Page16,
	Page17,
	Page18,
	Page19,
]

// content = [];
// for (let pageNo = 1; pageNo < 50; pageNo++) {
// 	let broken = false;
// 	import(`./Page${pageNo}`)
// 		.then((pageContent) => {
// 			if (pageContent.default) {
// 				content.push(pageContent.default);
// 			}
// 		})
// 		.catch((error) => {
// 			console.log('error: ', error);
// 			broken = true;
// 		});

// 	if (broken) {
// 		break;
// 	}
// }
// console.log('content------');
// console.log(content);

export default content
