const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background8.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./crown.png"),
			class: "image-one imageCenter element1 imgSize imgcrown",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./teeth.png"),
			class: "image-one imageCenter element2 imgSize imgteeth",
			imgdiv: "imageCenterDiv _mt-3",
		},
		{
			src: images("./tika.png"),
			class: "image-one imageCenter element3 imgSize imgtika",
			imgdiv: "imageCenterDiv h-65 _mt-1",
		},

		{
			src: images("./pomu_un.png"),
			class: "image-one imageCenter element4 imgSize imgpomu_un",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./pomegranate.gif"),
			class: "image-one imageCenter element5 imgSize imgpomegranate",
			imgdiv: "imageCenterDiv",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मेरो टाउकोमा राजाले लगाउने जस्तै श्रीपेच छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.4 },
						{ start: 1.3 },
						{ start: 2 },
						{ start: 2.6 },
						{ start: 3.2 },
						{ start: 3.8, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म हेर्दा सानो सानो दाँत जस्तै देखिन्छु।",
					textClass: "m-0",
					timestamps: [
						{ start: 13 },
						{ start: 13.3 },
						{ start: 14 },
						{ start: 14.6 },
						{ start: 15 },
						{ start: 15.4 },
						{ start: 16, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मेरो रङ टिका जस्तै रातो छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 23.5 },
						{ start: 23.7 },
						{ start: 24.2 },
						{ start: 24.9 },
						{ start: 25.4 },
						{ start: 25.7, last: true },
					],
				},
			],
		},

		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 34.6 },
						{ start: 34.8 },
						{ start: 35.1 },
						{ start: 35.7 },
						{ start: 36 },
						{ start: 36.1 },
						{ start: 36.1, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element5 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म अनार हुँ ।",
					textClass: "m-0",
					timestamps: [{ start: 45.5 }, { start: 45.6 }, { start: 46, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p8a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p8a2.wav"),
			delay: 17000,
		},
		{
			url: audios("./p8a3.wav"),
			delay: 27500,
		},
		{
			url: audios("./p8a4.wav"),
			delay: 38500,
		},
		{
			url: audios("./p8a5.wav"),
			delay: 49500,
		},
	],
}

export default content
