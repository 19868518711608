import { css, keyframes } from "@emotion/core"
import { fileCheck } from "helpers/files"
import { appear } from "styledElements/keyframes"

const images = fileCheck(require.context("../images", true))

const content = {
	images: [
		{
			src: "forest/bg_page8.png",
		},
		{
			src: "forest/tree_page8.png",
			styles: css`
				transform-origin: 50% 100%;
				animation: ${keyframes({
						from: {
							transform: "scale(1.2) skewX(3deg)",
						},
						to: {
							transform: "scale(1.2) skewX(-3deg)",
						},
					})}
					4s ease-in-out infinite alternate;
			`,
		},
		{
			src: "forest/branches_move.gif",
			class: "branch_move_p8",
			styles: {
				top: "10%",
				left: "75%",
				width: "25%",
			},
			imgdiv: "imageCover",
		},
		{
			src: "forest/bird_page9.png",
			class: "bird_a-p8",
			imgdiv: "imageCover",
		},
		{
			src: "forest/bird_siting_page9.png",
			class: "bird_b-p8",
			imgdiv: "imageCover",
		},
		{
			src: "_.png",
			styles: {
				width: "60%",
				transform: "translateX(-80%)",
				top: "30%",
				background: `url(${images("./forest/stom2.png")}) 0px center / contain repeat-x`,
				animation: `${keyframes({
					from: {
						transform: "translateX(200%)",
					},
					to: {
						transform: "translateX(-80%)",
					},
				})} 7s normal 5s linear infinite`,
			},
		},
		{
			src: "_.png",
			styles: {
				width: "50%",
				top: "70%",
				background: `url(${images("./forest/stom1.png")}) 0px center / contain repeat-x`,
				animation: `${keyframes({
					from: {
						transform: "translateX(200%)",
					},
					to: {
						transform: "translateX(-80%)",
					},
				})} 5s normal linear infinite`,
			},
		},
		{
			src: "_.png",
			styles: {
				width: "50%",
				transform: "translateX(-80%)",
				background: `url(${images("./forest/stom3.png")}) 0px center / contain repeat-x`,
				animation: `${keyframes({
					from: {
						transform: "translateX(200%)",
					},
					to: {
						transform: "translateX(-80%)",
					},
				})} 6s 3s normal linear infinite`,
			},
		},
		{
			src: "_.png",
			styles: {
				width: "80%",
				top: "-30%",
				background: `url(${images("./forest/stom2.png")}) 0px center / contain repeat-x`,
				animation: `${keyframes({
					from: {
						transform: "translateX(200%)",
					},
					to: {
						transform: "translateX(-80%)",
					},
				})} 6s normal linear infinite`,
			},
		},
	],
	texts: [
		{
			styles: {
				animation: `${appear} 1s 1.2s ease-out normal both`,
				padding: "15px",
				background: "rgba(255, 255, 255, 0.8);",
				borderRadius: "15px",
				top: "2%",
				left: "18%",
			},
			textDivClass: "story_gauthali-text",
			textIndivData: [
				{
					textData: " आँधी आयो एक्कासि",
					textClass: "m-0 p3-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "रूख हल्यो बेस्सरि",
					textClass: "m-0 p3-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p8.ogg",
			delay: 1200,
		},
		{
			url: "wind.ogg",
			delay: 0,
			volume: 0.2,
			loop: true,
			isBg: true,
		},
	],
}

export default content
