const words = [
	[
		{
			key: "kauli",
			column: 0,
			row: 2,
			isAcross: 1,
			arrow: "alu",
			letters: ["का", "उ", "ली"],
		},
		{
			key: "kerau",
			column: 1,
			row: 0,
			isAcross: 0,
			arrow: "adr",
			letters: ["के", "रा", "उ"],
		},
		{
			key: "titekarela",
			column: 3,
			row: 1,
			isAcross: 1,
			arrow: "ad",
			letters: ["ति", "ते", "क", "रे", "ला"],
		},
		{
			key: "katahar",
			column: 5,
			row: 1,
			isAcross: 0,
			arrow: "ad",
			letters: ["क", "ट", "ह", "र"],
		},
		{
			key: "mula",
			column: 7,
			row: 0,
			isAcross: 0,
			arrow: "adl",
			letters: ["मू", "ला"],
		},
		{
			key: "dhan",
			column: 8,
			row: 2,
			isAcross: 0,
			arrow: "adl",
			letters: ["धा", "न"],
		},
		{
			key: "nariwal",
			column: 8,
			row: 3,
			isAcross: 1,
			arrow: "aur",
			letters: ["न", "रि", "व", "ल"],
		},
		{
			key: "lasun",
			column: 11,
			row: 3,
			isAcross: 0,
			arrow: "adl",
			letters: ["ल", "सु", "न"],
		},
		{
			key: "sukumel",
			column: 11,
			row: 4,
			isAcross: 1,
			arrow: "aur",
			letters: ["सु", "कु", "मे", "ल"],
		},
		{
			key: "kafal",
			column: 14,
			row: 2,
			isAcross: 0,
			arrow: "ad",
			letters: ["का", "फ", "ल"],
		},
	],
]

export default words
