const audio_letters = require.context("../../audios/letters/level3", true)

const letterMap = {
	ठ: "tha",
	डे: "dey",
	उ: "wu",
	रो: "roo",
	टे: "te",
	पि: "pe",
	ङ: "naa",
	लि: "le",
	फो: "pho",
	न: "na",
	दि: "de",
	ना: "naaa",
	ला: "laa",
	ह: "haa",
	त्के: "tke",
	लो: "loo",
	जु: "juu",
	वा: "waa",
	ता: "taa",
	रा: "raa",
	तो: "toe",
	पा: "paa",
	नी: "nee",
	खु: "khu",
	री: "ree",
	बै: "bai",
	ज: "ja",
}

let letterToSound3 = {}

for (const letter of Object.keys(letterMap)) {
	if (letterMap[letter] !== null) {
		try {
			letterToSound3[letter] = audio_letters(`./${letterMap[letter]}.ogg`)
		} catch (error) {
			console.log(`not found - letterToSound3 mapping for letter: '${letter}', sound: '${letterMap[letter]}.ogg'`)
			letterToSound3[letter] = null
		}
	}
}

// console.log("letterToSound", letterToSound);

export default letterToSound3
