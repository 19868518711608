import React, { Component } from "react"
import EachDrop from "./EachDrop"

class AllDropItems extends Component {
	render() {
		const { levelData, currentPage } = this.props
		return (
			levelData &&
			levelData[currentPage].dropItems.map((eachDrop, index) => <EachDrop key={index} dropItems={eachDrop} index={index} />)
		)
	}
}

export default AllDropItems
