const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky-yellow",
	images: [
		{
			src: images("./13to24/fall.gif"),
			imgdiv: "imageCover ",
			styles: { width: "10%", top: "29%", bottom: "unset", left: "50%", transform: "translateX(-50%) scale(2.5)" },
		},
	],
	texts: [
		{
			textDivClass: "story-text w-75 end-suspense",
			textIndivData: [
				{
					textData: "ला! अब कछुवालाई के हुन्छ होला?",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.86 },
						{ start: 1.18 },
						{ start: 1.9 },
						{ start: 2.08 },
						{ start: 2.4 },
						{ start: 3 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p4_s6.ogg"),
			delay: 1500,
		},
	],
}

export default content
