import { appear } from "styledElements/keyframes"
const content = {
	images: [
		{
			src: "forest/bird_picking_sticks01.png",
			class: "sticks-p2",
			imgdiv: "imageCover",
		},
		{
			src: "hill/page16.png",
			styles: {
				width: "55%",
				position: "absolute",
				left: "45%",
				top: "0%",
			},
		},
		{
			src: "birds/bird_making_home_02.gif",
			styles: {
				width: "3%",
				top: "11%",
				left: "93%",
				height: "9%",
			},
		},
		{
			src: "birds/bird_making_home_01.gif",
			styles: {
				width: "4%",
				height: "8%",
				top: "8.5%",
				left: "96%",
			},
		},
		{
			src: "birds/bird-picking-stick.gif",
			class: "bird-p2",
			imgdiv: "imageCover",
		},
		{
			src: "forest/bird_fly.gif",
			class: "bird_gif-p2",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: {
				left: "13%",
				padding: "15px",
				animation: `${appear} 1s 1s ease-out normal both`,
			},
			textIndivData: [
				{
					textData: "टुक्रा टाक्री लगेर",
					textClass: "m-0 p10-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "बनाए गुँड तयार",
					textClass: "m-0 p10-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p14.ogg",
			delay: 2000,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			volume: 1,
			loop: true,
			isBg: true,
		},
	],
}

export default content
