const audio_letters = require.context("../../audios/letters", true)

const letterMap = {
	अ: "a",
	आ: "aaa",
	ब: "ba",
	बा: "baaa",
	बाँ: "baaa_chandrabindu",
	भा: "bhaaa",
	भाँ: "bhaaa_chandrabindu",
	भँ: "bha_chandrabindu",
	भे: "bhe",
	बि: "bi_hraswo",
	बु: "bu_hraswo",
	च: "cha",
	छा: "chaa",
	// छा: "chhaaata_chhaaa",
	चाँ: "chaaa_chandrabindu",
	औँ: "chandrabindu_au",
	चा: "chara_chaaa",
	ची: "chii_dirgha",
	द: "damaha_da",
	दाँ: "damaha_daaa_chandrabindu",
	दी: "damaha_dee_dirgha",
	दु: "damaha_hraswo_duu",
	ड: "damaru_da",
	डा: "damaru_daaa",
	डो: "damaru_do",
	धा: "dhaan_dhaaa",
	ढा: "dhakani_dhaaa",
	ढ: "dhakani_dhah",
	ढो: "dhakani_dho",
	मी: "dirgha_mi",
	पी: "dirgha_pee",
	ई: "eee_dirgha",
	इ: "ee_hraswo",
	ए: "euta_ye",
	फू: "fuu_dirgha",
	ग: "ga",
	गा: "gaaa",
	गैँ: "gai_chandrabindu",
	गे: "gey",
	घो: "gho",
	घु: "ghu",
	गो: "go",
	ह: "ha",
	हा: "haaa",
	हाँ: "haaa_chandrabindu",
	हे: "hey",
	छु: "hraswo_chu",
	हि: "hraswo_hee",
	नि: "hraswo_ni",
	नु: "hraswo_nu",
	पि: "hraswo_pi",
	शु: "hraswo_sukrabar_su",
	सु: "hraswo_sundar_su",
	// ज: "jarayo_ja",
	ज: "ja",
	जा: "jaaa",
	झो: "jhola_jho",
	क: "ka",
	का: "kaaa",
	कैँ: "kai_chandrabindu",
	ख: "kha",
	खा: "khaaa",
	ख्रा: "khra_bakhra",
	खु: "khu_hraswo",
	कि: "ki_hraswo",
	को: "ko",
	क्र: "kra",
	कु: "ku_hraswo",
	ल: "la",
	ला: "laaa",
	लो: "lo",
	म: "ma",
	मा: "maaa",
	मि: "mi_hraswo",
	श: "moto_sha",
	मु: "mu_hraswo",
	न: "na",
	ना: "naaa",
	नी: "nee_dirgha",
	ने: "ney",
	ङ: "nga",
	प: "pa",
	पा: "paaa",
	पै: "pai",
	सा: "patalo_saaa",
	सी: "patalo_see_dirgha",
	स: "patalo_sha",
	फ: "pha",
	फे: "phey",
	फो: "pho",
	र: "ra",
	रा: "raaa",
	री: "ree_dirgha",
	रे: "rey",
	रि: "ri_hraswo",
	रू: "ru",
	रु: "ru",
	त: "tali_ta",
	ता: "taa",
	// ता: "tali_taaa",
	ट: "tapari_ta",
	टा: "tapari_taaa",
	टी: "tapari_tee_dirgha",
	टे: "tapari_tey",
	टो: "tapari_to",
	ठी: "thadeuro_thee_dirgha",
	थी: "thapadi_thee_dirgha",
	त्ती: "ttee_hatti_dirgha",
	वा: "waaa",
	उ: "wu",
	उँ: "wu_chandrabindu",
	याँ: "yaaa_chandrabindu",
	पो: "po",
	ताँ: "tachandrabindu",
	ती: "ti",
}

let letterToSound = {}

for (const letter of Object.keys(letterMap)) {
	if (letterMap[letter] !== null) {
		try {
			letterToSound[letter] = audio_letters(`./${letterMap[letter]}.ogg`)
		} catch (error) {
			console.log(`not found - letterToSound mapping for letter: '${letter}', sound: '${letterMap[letter]}.ogg'`)
			letterToSound[letter] = null
		}
	}
}

// console.log("letterToSound", letterToSound);

export default letterToSound
