import React, { useContext } from "react"

import gsap from "gsap"

import { Link } from "react-router-dom"
import iconHome from "assets/icons/home.svg"
import blastBanner from "assets/banner-on-loop.gif"
import playImage from "assets/read-again.png"
import "./custommodal.styles.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faRedoAlt,
	// faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons"

import { DispatchContext } from "../../CrosswordPuzzle"
// import { StateContext, DispatchContext } from "../../CrosswordPuzzle";
import Modal from "react-modal"

const CustomModal = (props) => {
	Modal.setAppElement("#root")

	// const state = useContext(StateContext);
	const dispatch = useContext(DispatchContext)
	
	//Test for flutter communication 
	var isCallFromFlutter = false;
		isCallFromFlutter = props.isOpenInFlutter;
	//Test for flutter communication 

	React.useEffect(() => {
		let tl = gsap.timeline()
		tl.paused(true)
	})

	return (
		<Modal
			isOpen={props.isOpen}
			style={{
				overlay: {
					zIndex: "9999",
					backgroundColor: "rgba(0, 0, 0, 0.7)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				},
				content: {
					position: "static",
					backgroundColor: "transparent",
					border: "none",
					overflow: "hidden",
				},
			}}
			ariaHideApp={false}
		>
			<div key="menuBtnNavBar" className="stageEndModal">
				<div className="menu__wrap">
					<div className="left-banner">
						<img className="img-fluid" src={blastBanner} alt="banner" />
					</div>
					<div className="right-banner">
						<img className="img-fluid" src={blastBanner} alt="banner" />
					</div>
					<div className="meta">
						<h2 className="text-center">समाप्त</h2>
					</div>
					{/*
					<Link className="home__icon" to="/play">
						<img src={iconHome} className="img-fluid" alt="Home" />
					</Link>

					 Test*/}
					 {
						isCallFromFlutter?
						(<button  
							className="home__icon"
							onClick={() => window.Print.postMessage("Call for back in Flutter")}
						>
							<img src={iconHome} className="img-fluid" alt="Home" />
						</button>):
						<Link className="home__icon" to="/play">
							<img src={iconHome} className="img-fluid" alt="Home" />
						</Link>
					 }
					<ul className="menu__list">
						<li className="play-wrap">
							<div className="image-wrap">
								<img src={playImage} className="img-fluid" alt="Play" />
							</div>

							<button
								onClick={() => {
									dispatch({ type: "RESET" })
								}}
								className="btn btn-round purple"
							>
								<FontAwesomeIcon icon={faRedoAlt} />
							</button>
							{/* <button
								onClick={() => {}}
								className="btn btn-round lightgreen"
							>
								<FontAwesomeIcon icon={faChevronCircleRight} />
							</button> */}
						</li>
					</ul>
				</div>
			</div>
		</Modal>
	)
}
export default CustomModal
