import { appear, disappear } from "styledElements/keyframes";
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const slideIn = keyframes`
0% {
	transform: translateX(110%) rotate(90deg);
}
100% {
	transform: translate(0) rotate(0);
}
`

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./imgs/foodc.png"),
			class: "image-one bg blur",
			imgdiv: "imageCover",
		},
		{
			src: images("./imgs/foodo3.png"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "58%", top: "12%", left: "37%", zIndex: "11",
				transform: 'translate(110%)',
				animation: `${slideIn} 2s 8s ease-out normal forwards`,
			}
		},
		{
			src: images("./imgs/kamal.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "30%", top: "42%", left: "0%",opacity: "1", zIndex: "11",
				animation: `${disappear} 0.01s 1s ease-out normal forwards`,
			}
		},
		{
			src: images("./imgs/talkingkamal.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "30%", top: "42%", left: "0%",opacity: "0", zIndex: "11",
				animation: `${appear} 0.01s 1s ease-out normal forwards, ${disappear} 0.01s 7s ease-out normal forwards `,
			}
		},
		{
			src: images("./imgs/girl04.png"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "30%", top: "42%", left: "0%",opacity: "0", zIndex: "11",
				animation: ` ${appear} 0.01s 7s ease-out normal forwards, ${disappear} 0.01s 11s ease-out normal forwards, ${appear} 0.01s 17s ease-out normal forwards`,
			}
		},
		{
			src: images("./imgs/talking_bothhandhip.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "30%", top: "42%", left: "0%",opacity: "0", zIndex: "11",
				animation: `${appear} 0.01s 11s ease-out normal forwards, ${disappear} 0.01s 17s ease-out normal forwards `,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text",
			styles: {
				height: "100%",
				width: "32%",
				background: "rgba(0,0,0,0.5)",
				padding: "2%",
				textAlign: "center",
				color: "white",
				fontSize: "4vmin",
			},
			textIndivData: [
				{
					textData: "",
					textClass: "txt2",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 0.95 }],
				},
			],
		},
		{
			textDivClass: "story-text",
			styles: {
				height: "100%",
				width: "32%",
				background: "rgba(0,0,0,0.5)",
				padding: "2%",
				textAlign: "center",
				color: "white",
				fontSize: "4vmin",
				animation: `${disappear} 1s linear 10s forwards`

			},
			textIndivData: [
				{
					textData: "यहाँको ताजपुरिया समुदायले स्वादिलो चिया बाहेक के खाँदो रहेछन् हेरौँ त।",
					textClass: "txt2",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 0.95 }],
				},
			],
		},
		{
			textDivClass: "story-text",
			styles: {
				height: "100%",
				width: "32%",
				background: "rgba(0,0,0,0.5)",
				padding: "2%",
				textAlign: "center",
				color: "white",
				fontSize: "4vmin",
				opacity: "0",
				animation: `${appear} 1s linear 11s forwards`
			},
			textIndivData: [
				{
					textData: "चामलको पिठोले बनाएको तातो तातो भक्का जाडोमा धेरैलाई मनपर्दो रहेछ।",
					textClass: "txt2",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 0.95 }],
				},
			],
		},
		{
			textDivClass: "story-text",
			styles: {
				width: "58%",
				textAlign: "center",
				fontSize: "8vmin",
				opacity: "0",
				top: "71%",
				left: "37%",
				zIndex: 12,
				animation: `${appear} 1s linear 13s forwards`
			},
			textIndivData: [
				{
					textData: "भक्का",
					textClass: "txt2",
					timestamps: [{ start: 0 }, { start: 0.6 }, { start: 0.95 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./jhapa3.ogg"),
			delay: 50,
		},	
		{
			url: audios("./jhapa4.ogg"),
			delay: 11000,
		},
		{
			url: audios("./sound_eff/jungle.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 15000,
		},
	
	],
}

export default content
