// import { jsx } from "@emotion/core"
import _, { delay } from "underscore"
import React, {useContext, useEffect, useState} from "react"
import {Howl, Howler} from "howler"
import { StateContext, DispatchContext } from "../../ShapeColorGame"
import "./scenecss/scene1.styles.scss"
import { sleep } from "../../utils"
import gsap from "gsap"
import mainTextAnimation from "../../../Common/TextAnimations"
import { counter } from "@fortawesome/fontawesome-svg-core"
import textHighLight from "../../../Common/TextHighLightAnime"
import { faBorderNone } from "@fortawesome/free-solid-svg-icons"
// const images = require.context("../images", true)
const audios = require.context("./dailog/", true)

const Scene = (({stageData, assets, story})=>{
   const state = useContext(StateContext)                /* importing statecontext from shapecolorgame and initializiing at state */
   const dispatch = useContext(DispatchContext)         /* importing statecontext from shapecolorgame and initializiing at state */
   let girlRef = React.useRef(null);                    /* initializing girlrif as reference which directly change in file  */
        let robotRef = React.useRef(null);
        let speechboxRef = React.useRef([]);
        // console.log(story);
        const initial = {
        pageup: 0,
        audio: false,
        disable: true,
        }

        const [stageState, setStageState] = useState(initial);
//    const [data, setdata] = useState(stageData);
        var timestamp = gsap.timeline({});                           /* creating timeline for gsap animation */
        let bgMusic;
        let dailogSound;

   let content = {                                              /* all data are store in object which are used as par their requirement */
        texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-1_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 1% 13%'
                        },
                        textIndivData: [
                                {
                                        textData: "ला !! के भयो ? कसरी लड्‍यौ ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.70 }, { start: 1.00 }, { start: 1.10 }, { start: 1.40 }, { start: 1.90 }, { start: 2.00 }],
                                        delayTime: 16000,
                                        hideTimer: 21000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-2_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '2% 14% 2% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "यो ढुङ्‍गामा अल्झिएर लडेछु ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.50 }, { start: 2.00 }],
                                        delayTime: 22000,
                                        hideTimer: 26000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-3_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '2% 2% 2% 14%'
                        },
                        textIndivData: [
                                {
                                        textData: "अनि तिम्रो हात खुट्‍टाहरू खोई त ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.30 }, { start: 0.70 }, { start: 1.00 }, { start: 1.50 }, { start: 2.00 }, { start: 2.10 }],
                                        delayTime: 27000,
                                        hideTimer: 31000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-4_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '5% 14% 2% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "हेर न, हिजो राति परेको ठुलो पानीले बगाएर लग्यो ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.40 }, { start: 1.10 }, { start: 1.40 }, { start: 1.70 },{ start: 2.00 },{ start: 2.30 },{ start: 2.90 },{ start: 3.50 },{ start: 4.00 }],
                                        delayTime: 32000,
                                        hideTimer: 38000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-5_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '2% 2% 2% 14%'
                        },
                        textIndivData: [
                                {
                                        textData: "ओहो !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 39000,
                                        hideTimer: 42000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-6_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '5% 14% 2% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "मैले कत्ति खोजिसकेँ तर भेट्दै भेटिन ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.50 }, { start: 2.00 },{ start: 2.50 },{ start: 3.00 }],
                                        delayTime: 43000,
                                        hideTimer: 46000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-7_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '5% 2% 2% 14%'
                        },
                        textIndivData: [
                                {
                                        textData: "पिर नगर म पनि तिमीलाई खोज्न सहयोग गर्छु ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.80 }, { start: 1.20 }, { start: 1.60 }, { start: 2.20 }, { start: 2.60 }, { start: 3.10 }, { start: 3.60 }],
                                        delayTime: 47000,
                                        hideTimer: 53000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-8_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '5% 14% 2% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "धन्यवाद ! मेरो नाम चाहिँ फुर्बा हो । तिम्रो नि ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.80 }, { start: 1.25 }, { start: 1.60 }, { start: 1.90 }, { start: 2.40 }, { start: 2.80 }, { start: 3.00 }, { start: 3.60 }, { start: 4.10 }, { start: 4.50 }],
                                        delayTime: 54000,
                                        hideTimer: 60000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-9_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '5% 2% 2% 14%'
                        },
                        textIndivData: [
                                {
                                        textData: "मेरो नाम समा हो, अब हामी ढिला नगरी खोज्न जाऔँ है ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 0.90 }, { start: 1.30 }, { start: 2.40 }, { start: 2.80 }, { start: 3.00 }, { start: 3.10 }, { start: 3.55 }, { start: 4.00 }, { start: 4.30 }, { start: 4.50 }],
                                        delayTime: 61000,
                                        hideTimer: 68000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-10_p1',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '5% 14% 2% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "हुन्छ , जाऔँ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }, { start: 0.80 }, { start: 1.30 }],
                                        delayTime: 69000,
                                        hideTimer: 73000
                                }
                        ]
                },
        ],
        sounds: 
		[{
			url: audios("./sama1.wav"),
			delay: 16000,
		},{
                        url: audios("./furba1.wav"),
			delay: 22000,
                },{
			url: audios("./sama2.wav"),
			delay: 27000,
		},{
                        url: audios("./furba2.wav"),
			delay: 32000,
                },{
                        url: audios("./sama3.wav"),
			delay: 39000,
                },{
                        url: audios("./furba3.wav"),
			delay: 43000,
                },{
                        url: audios("./sama4.wav"),
			delay: 47000,
                },{
                        url: audios("./furba4.wav"),
			delay: 54000,
                },{
                        url: audios("./sama5.wav"),
			delay: 61000,
                },{
                        url: audios("./furba5.wav"),
			delay: 69000,
                }],

}
        // console.log(story);
   useEffect(()=>{                                          /* these effect is use for gsp animation of each and every object */
        
        if(state.playing && stageState.disable){
                timestamp.to(girlRef.current, {
                        duration: 7,
                        x: "155%",
                        ease: "power1.in",
                        delay: 1,
                        onComplete: ()=>{
                                girlRef.current.src = stageData.girlLooking
                                // robotRef.current.styles
                        }
                        })
                        .to(robotRef.current,{
                                duration : 7,
                                x: "-88%",
                                ease: "power1.in",
                                delay: -1,
                                onComplete: ()=>{
                                        robotRef.current.src = stageData.robotFallGif
                                        // speechboxRef.current.style.display = "block"
                                }
                        })
                        .to(robotRef.current,{
                                delay: 3,
                                onComplete: ()=>{
                                        console.log("completed") ;
                                        // girlRef.current.src = stageData.girlTalkGif
                                        robotRef.current.src = stageData.robotLookPng
                                }
                        })
        }       
}, [girlRef.current, robotRef.current, speechboxRef.current])

   useEffect(()=> {                                           /* this useEffect is uesd for background music play */
        if(!stageState.audio){
                        setStageState({...stageState, audio:true})
                        const bgMusic = new Howl({
                                src: [content.sounds[stageState.pageup].url],
                                autoplay: false,
                                preload: true,
                                volume: 0.07,
                                loop: true,
                                // onend: async () => {
                                //         // if (stageData.id === 10) {
                                //         //         setOptionState({ ...optionState, finalStageQ: stageData.questioner })
                                //         //         dispatch({ type: "LAST_PAGE_COMPLETE" })
                                //         // }
                                //         // await sleep(2000)
                                //         // await playAnimalSound()
                                // },
                        })
                        bgMusic.play()
                        return () => {
                                bgMusic.unload()
                        }
                }      
        },[stageState.audio, stageData.bgMusic]);

        let textList = content.texts;
        let soundList;
useEffect(()=>{                                      /* this useEffect is used for showing dailogbox highilighting text and play audio for each dailog*/
        let pagenumber  = 1;
        textList = content.texts;
        soundList = content.sounds;
        console.log(stageState.disable)
        if(state.playing && stageState.disable){
                let objCount = 1
                let timeouts = []
                let timestams = []
                let textIndivData = []
                let delaytime = []
                let hideTime = []
                let showdata;
                console.log(stageState.disable)
                if( textList?.length > 0){
                        textList.forEach((item) => {
                                textIndivData = [...textIndivData, ...item.textIndivData]
                                // console.log(textIndivData)
                                // console.log(item)
                        })
                        textIndivData.forEach((data) =>{
                                // console.log(data)
                                if(data.timestamps){
                                        timestams = [...timestams, ...data.timestamps];
                                        // textHighLight(data.textdata, data.timestamps)
                                }else{
                                        timestams = null
                                }
                                
                                if(data.delayTime){
                                        delaytime.push(
                                                setTimeout(()=>{
                                                showdata  = document.getElementsByClassName('sp-'+ objCount+'_p1');
                                                        gsap.to(showdata, {
                                                                scale: 1,
                                                                ease: "power1.in",
                                                                display: "block",
                                                                onComplete: () => {
                                                                        (objCount % 2 == 0) ? girlRef.current.src = stageData.girlTalkGif : robotRef.current.src = stageData.robotTalkGif
                                                                        // if(objCount == 5){
                                                                        //         // dispatch({type: "NEXT_STAGE"});
                                                                        //         state.rewardComplete = true;
                                                                        //         console.log(state.rewardComplete)
                                                                        // }
                                                                }      
                                                        })
                                                        let textAnimations;
                                                        import(`../../Animations`).then((customAnims) =>{
                                                                let animationObj = new customAnims.default()
                                                                textAnimations = animationObj.runTextAnimations
                                                                // textAnimations = mainTextAnimation
                                                                if(animationObj.runTextAnimations){
                                                                        animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        objCount ++ ;
                                                                        // animationObj.forEach(() =>{
                                                                        //         animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        // })
                                                                }
                                                        })
                                                        .catch((error) =>{
                                                                if(story.defaultTextAnimation){
                                                                        textAnimations = mainTextAnimation
                                                                }else{
                                                                        textAnimations = () => {}
                                                                }
                                                        })
                                                },data.delayTime)
                                        )
                                }else{
                                        delaytime = null
                                }
                                if(data.hideTimer){
                                        hideTime.push(
                                                setTimeout(() => {
                                                        showdata  = document.getElementsByClassName('sp-'+ (objCount-1)+'_p1');
                                                        gsap.to(showdata, {
                                                                scale: 0.1,
                                                                ease: "power1.in",
                                                                delay: 1,
                                                                display: "none",
                                                                onComplete: () => {
                                                                        // girlRef.current.src = stageData.girlLooking;
                                                                        // robotRef.current.src = stageData.robotLookPng;
                                                                }
                                                        })
                                                        if(objCount === 11) 
                                                        { 
                                                                console.log(objCount)
                                                                dispatch({type: "LAST_PAGE_COMPLETE"})
                                                                setStageState({...stageState, disable:false})
                                                                console.log(state.rewardComplete)
                                                        }
                                                }, data.hideTimer)
                                        )
                                }
                                
                        })
                }else{
                        timestams = null
                }
                // let audio;
                if(soundList && soundList.length){
                        soundList.forEach((sound) => {
                                // console.log(sound)
                                let audio = new Howl({
                                        src: [sound.url],
                                        preload: true,
                                        autoplay:false,
                                        loop: sound.loop ?? false,
                                        volume: sound.volume ?? 0.7,
                                })
                                if(sound.delay){
                                        timeouts.push(
                                                setTimeout(()=>{
                                                        audio.play();
                                                },sound.delay)
                                        )
                                }else{
                                        // audio.unload()
                                }
                        })
                }
                return () => {
                        timeouts.forEach((timeout) =>{
                                clearTimeout(timeout)
                                Howler.unload()
                        })
                        delaytime.forEach((timeout) => {
                                clearTimeout(timeout);
                        })
                        gsap.killTweensOf("*")
                }
        }
},[state.disable, content, textList, speechboxRef.current])

        return (
                <div className="imageContainer">
                <img src={stageData.girlMoveGif} className="girl_walk_p1" ref={girlRef} 
                />
                <img src ={stageData.robotGif} className="robot_p1" ref={robotRef} />
                        {textList.length ? (
                                textList.map((textContent, index) => {
                                        const textStyles = textContent.styles ?? {}
                                        // console.log(textStyles);
                                        const maindiv = textContent.textMainDivClass;
                                        const className = `${textContent.textDivClass} textContentDiv`
                                        return(
                                                <div key={index} className={`speechbox_p1 ${maindiv}`} ref={ref => {speechboxRef.current[index] = ref;}}> 
                                                <img src ={stageData.bubble} className="box" /> 
                                                <div className={className} style ={ textStyles }>
                                                        {textContent.textIndivData.map((text, index) => (
                                                                <p key={index} id="dailog" className={text.textClass} >
                                                                        {text.textData}
                                                                </p>
                                                        ))}
                                                </div>
                                                </div>
                                        )
                                })
                        ) : (
                                <div></div>
                        )}

                 {/* </div> */}
                </div>        
        )
        
});

export default Scene;

