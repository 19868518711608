import React from "react"
import { Draggable } from "react-beautiful-dnd"
import { Howl } from "howler"

import _ from "underscore"

// import { StateContext } from '../../MatchTheWordGame';

const Option = ({ option, index, isDraggable }) => {
	// const state = useContext(StateContext);
	const assets = require.context("./assets")

	function playHintAudio(audio) {
		const hintAudio = new Howl({
			src: [audio],
			loop: false,
			volume: 1,
		})

		hintAudio.play()
	}

	return (
		<Draggable key={`StoneDiv${index}`} draggableId={`drag-${index}`} index={index} isDragDisabled={isDraggable}>
			{(provided, snapshot) => (
				<div
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
					// isDragging={snapshot.isDragging}
					// style={getStyle(provided.draggableProps.style, snapshot)}
					className={"optionStone33 stone opt-stone"}
					id={`drag-${index}`}
					// style={{width: "25%"}}
					onClick={_.debounce(() => {
						playHintAudio(option.audioHint)
					}, 250)}
				>
					{option.isSinking ? (
						<img className="stoneGif" id={`stone-${index}`} alt="stone5" src={assets(`${option.image}.gif`)} />
					) : (
						<>
							<img className="stone-img2" id={`stone-${index}`} alt="stone5" src={assets(`${option.image}.png`)} />
							{!snapshot.isDragging && (
								<div className="fizzContainer">
									<div className="fizz">
										<div className="fizzDrop"></div>
									</div>
								</div>
							)}
						</>
					)}
					<h3 className={"stone-text "}>{option.name.letter}</h3>
				</div>
			)}
		</Draggable>
	)
}

export default Option
