const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background3.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./single_marble.png"),
			class: "image-one imageCenter  element1 imgSize imgsingle_marble",
			imgdiv: "imageCenterDiv _h-40 _mt-7",
		},
		{
			src: images("./group_marble.png"),
			class: "image-one imageCenter element2 imgSize imggroup_marble",
			imgdiv: "imageCenterDiv _mt-1",
		},
		{
			src: images("./green_grapes.png"),
			class: "image-one imageCenter element3 imgSize imggreen_grapes",
			imgdiv: "imageCenterDiv _mt-1",
		},
		{
			src: images("./black_grapes.png"),
			class: "image-one imageCenter element4 imgSize imgblack_grapes",
			imgdiv: "imageCenterDiv _mt-1",
		},
		{
			src: images("./grapes_un.png"),
			class: "image-one imageCenter element5 imgSize imggrapes_un",
			imgdiv: "imageCenterDiv h-65",
		},
		{
			src: images("./grapes.gif"),
			class: "image-one imageCenter element6 imgSize imggrapes",
			imgdiv: "imageCenterDiv h-65",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म गुच्चा जस्तै गोलो छु ।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.4 }, { start: 0.8 }, { start: 1.4 }, { start: 1.9 }, { start: 2.2, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म जहिले झुप्पामा बस्छु ।",
					textClass: "m-0",
					timestamps: [{ start: 9 }, { start: 9.4 }, { start: 10 }, { start: 10.5 }, { start: 11, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म कहिले हरियो देखिन्छु,",
					textClass: "m-0",
					timestamps: [{ start: 20 }, { start: 20.2 }, { start: 20.6 }, { start: 21.1, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: "त कहिले गाढा प्याजी रङको।",
					textClass: "m-0",
					timestamps: [{ start: 31 }, { start: 31.4 }, { start: 32 }, { start: 32.5 }, { start: 33, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element5 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 42.5 },
						{ start: 43 },
						{ start: 43.4 },
						{ start: 44 },
						{ start: 44.1 },
						{ start: 44.3 },
						{ start: 44.3, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element6 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म अंगूर हुँ ।",
					textClass: "m-0",
					timestamps: [{ start: 53 }, { start: 53.4 }, { start: 53.9, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p3a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p3a2.wav"),
			delay: 13000,
		},
		{
			url: audios("./p3a3.wav"),
			delay: 24000,
		},
		{
			url: audios("./p3a4.wav"),
			delay: 35000,
		},
		{
			url: audios("./p3a5.wav"),
			delay: 47000,
		},
		{
			url: audios("./p3a6.wav"),
			delay: 57500,
		},
	],
}

export default content
