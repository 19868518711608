const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page_04_a.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./swing.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./kite_fly.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideTextTop text-center",
			textIndivData: [
				{
					textData: "शरद् ऋतु",
					textClass: "fz-6 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 0.95 }],
				},
				{
					textData: "रूखको टुप्पामा चङ्‍गा अड्केको छ।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 1.95 }, { start: 2.85 }, { start: 3.85 }, { start: 4.35 }, { start: 4.95 }],
				},
				{
					textData: "रूखको हाँगामा पिङ बाँधिएको छ।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 6.15 }, { start: 6.85 }, { start: 7.9 }, { start: 8.5 }, { start: 9.25 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./5.ogg"),
			delay: 4000,
		},
		{
			url: audios("./a1.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
