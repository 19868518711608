const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./cover_page.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
	],
	texts: [],
	sounds: [
		{
			url: audios("./1.ogg"),
			delay: 500,
		},
	],
}

export default content
