/* playId => order of showing practice-need toImplement
   playName => Name of the play that should be shown in Playlist page
   playKey => uniquekey for each practice
   playPath => play path
   playlabel => defines easy, medium, hard
*/
const images = require.context("EGRContent/Play", true)
const playList = {
	play: [
		{
			playId: "1",
			playName: "तानेर राख",
			playKey: "drag-and-drop",
			playPath: "/DragandDropPlay/DragandDropPlay",
			label: "easy",
			playBannerBtnColor: "lightblue",
			disabled: false,
			readyLevels: {
				easy: images("./DragandDropPlay/images/DragAndDrop1.png"),
				medium: images("./DragandDropPlay/images/DragAndDrop1.png"),
				hard: images("./DragandDropPlay/images/DragAndDrop1.png"),
			},
		},
		{
			playId: "2",
			playName: "रेकर्डर",
			playKey: "pl2",
			playPath: "/RecorderPlay/RecorderPlay",
			label: "hard",
			recorder: true,
			playBannerBtnColor: "pink",
			disabled: false,
			readyLevels: {
				easy: images("./RecorderPlay/images/Recorder1.png"),
				medium: images("./RecorderPlay/images/Recorder1.png"),
				hard: images("./RecorderPlay/images/Recorder1.png"),
			},
		},
		{
			playId: "3",
			playName: "बेलुन फुटाऊ",
			playKey: "baloon-pop",
			playPath: "/BalloonPop/BalloonPop",
			label: "medium",
			playBannerBtnColor: "lightgreen",
			disabled: false,
			readyLevels: {
				easy: require("../Containers/Play/BalloonPop/content/images/ballongame1.png"),
				medium: require("../Containers/Play/BalloonPop/content/images/ballongame1.png"),
				hard: require("../Containers/Play/BalloonPop/content/images/ballongame1.png"),
			},
		},
		{
			playId: "4",
			playName: "शब्द फुटाऊ",
			playKey: "hit-the-wall",
			playPath: "/HitTheWall/HitTheWall",
			label: "hard",
			playBannerBtnColor: "lightgreen",
			disabled: false,
			readyLevels: {
				easy: require("../Containers/Play/HitTheWall/content/images/HitTheWall1.png"),
				medium: require("../Containers/Play/HitTheWall/content/images/HitTheWallBanner23.png"),
				// 'hard': require('../Containers/Play/HitTheWall/content/images/HitTheWall1.png'),
			},
		},
		{
			playId: "5",
			playName: "पज्जल",
			playKey: "crossword-puzzle",
			playPath: "/CrosswordPuzzle/CrosswordPuzzle",
			label: "hard",
			playBannerBtnColor: "lightgreen",
			disabled: false,
			readyLevels: {
				easy: require("../Containers/Play/CrosswordPuzzle/content/images/Puzzle1.png"),
				medium: require("../Containers/Play/CrosswordPuzzle/content/images/Puzzle1.png"),
				hard: require("../Containers/Play/CrosswordPuzzle/content/images/Puzzle1.png"),
			},
		},
		{
			playId: "6",
			playName: "शब्द बनाऊ",
			playKey: "match-the-word",
			playPath: "/MatchTheWord/MatchTheWord",
			label: "medium",
			playBannerBtnColor: "lightgreen",
			disabled: false,
			readyLevels: {
				easy: require("../Containers/Play/MatchTheWord/content/images/MatchTheWord1.png"),
				medium: require("../Containers/Play/MatchTheWord/content/images/MatchTheWord1.png"),
				// 'hard': require('../Containers/Play/MatchTheWord/content/images/MatchTheWord1.png'),
			},
		},
		{
			playId: "7",
			playName: "Decoding",
			playKey: "decoding",
			playPath: "/Decoding/Decoding",
			label: "easy",
			playBannerBtnColor: "lightblue",
			disabled: true,
			readyLevels: {
				easy: require("../Containers/Play/MatchTheWord/content/images/MatchTheWord1.png"),
				// 'medium' : require('../Containers/Play/MatchTheWord/content/images/MatchTheWord1.png'),
				// 'hard': require('../Containers/Play/MatchTheWord/content/images/MatchTheWord1.png'),
			},
		},
		{
			playId: "8",
			playName: "यो कसको आवाज होला ?",
			playKey: "whose-voice-is-this",
			playPath: "/WhoseVoiceIsThis/WhoseVoiceIsThis",
			label: "medium",
			playBannerBtnColor: "lightgreen",
			disabled: false,
			readyLevels: {
				easy: require("../Containers/Play/WhoseVoiceIsThis/content/images/yoawaj.png"),
			},
		},
		// {
		// 	playId: "9",
		// 	playName: "आकार र रंगहरु",
		// 	playKey: "shapes-and-color",
		// 	playPath: "/ShapeAndColors/ShapeAndColors",
		// 	label: "medium",
		// 	playBannerBtnColor: "lightgreen",
		// 	disabled: false,
		// 	readyLevels: {
		// 		easy: require("../Containers/Play/ShapeAndColors/content/images/aakara_rang.png"),
		// 	},
		// },
	],
}
export default playList
