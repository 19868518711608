const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_pointing_flower.png"),
			class: "image-10",
			imgdiv: "imageCover",
		},
		{
			src: images("./girl_pointing_flower.gif"),
			class: "image-10",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "कागज काटी फूल बनाएँ",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.78 }, { start: 1.4 }, { start: 2 }],
				},
				{
					textData: "फूल मैले कोठामा सजाएँ",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2.9 }, { start: 3.6 }, { start: 4.3 }, { start: 4.75 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7.ogg"),
			delay: 500,
		},
	],
}

export default content
