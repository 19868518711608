const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./page06.jpg"),
			class: "image-five page9",
			imgdiv: "imageCover",
		},
		{
			src: images("./peda_back_view.gif"),
			class: "image-five pedasun",
			imgdiv: "imageCover",
		},
		{
			src: images("./laddu_back_view.gif"),
			class: "image-five laddusun",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page9Text text-center",
			textIndivData: [
				{
					textData: "केहि बेरपछि पेडाले उदाउन लागेको घाम देखेछ।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.8 },
						{ start: 1.75 },
						{ start: 2.6 },
						{ start: 3.4 },
						{ start: 4.1 },
						{ start: 4.65 },
					],
				},
			],
		},
		{
			textDivClass: "story-text dialogueBubble db_textbox6 text_box-p9",
			textIndivData: [
				{
					textData: "तिमी त ऊ त्यो घाम जस्तै सुन्तले!",
					textClass: "m-0",
					timestamps: [
						{ start: 6.1 },
						{ start: 6.5 },
						{ start: 7 },
						{ start: 7.4 },
						{ start: 7.9 },
						{ start: 8.4 },
						{ start: 8.9 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./9.ogg"),
			delay: 500,
		},
		{
			url: audios("./9a.ogg"),
			delay: 6500,
		},
	],
}

export default content
