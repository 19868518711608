// // configs
import gameContent from "../content/gameContent"

export const getLevelData = (level = "easy") => {
	let data = gameContent.levels.filter((lvl) => lvl.label.toString().toLowerCase() === level.toString().toLowerCase())
	return data[0].data.slice(0, 6) ?? null
}

export const getRandomInt = (min, max) => {
	min = Math.ceil(min)
	max = Math.floor(max)
	return Math.floor(Math.random() * (max + 1 - min)) + min //The maximum and the minimum are both inclusive
}

const compareObjects = (o1, o2) => {
	for (let p in o1) {
		if (o1.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false
			}
		}
	}

	for (let p in o2) {
		if (o2.hasOwnProperty(p)) {
			if (o1[p] !== o2[p]) {
				return false
			}
		}
	}
	return true
}

export const reducer = (state, action) => {
	let newState
	switch (action.type) {
		case "SET":
			newState = {
				...state,
				...action.values,
			}
			break

		case "RESET":
			newState = {
				...state,
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				levelComplete: false,
				stage: 0,
			}
			break

		case "RESET_STAGE":
			newState = {
				...state,
				...action.values,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				levelComplete: false,
			}
			break

		case "LOADING_COMPLETE":
			newState = {
				...state,
				loadingAssets: false,
			}
			break
		case "START_PLAY":
			newState = {
				...state,
				intro: false,
				playing: true,
				stageComplete: false,
			}
			break

		case "STAGE_COMPLETE":
			newState = {
				...state,
				intro: false,
				playing: false,
				stageComplete: true,
			}
			break

		case "REWARD_COMPLETE":
			newState = {
				...state,
				rewardComplete: true,
			}
			break

		case "PREV_STAGE":
			newState = {
				...state,
				intro: true,
				playing: false,
				stageComplete: false,
				rewardComplete: false,
				stage: state.stage - 1,
				phase: 1,
			}
			break

		case "NEXT_STAGE":
			console.log("next stage", state.levelData.length, state.stage, state.levelData.length > state.stage)
			newState =
				state.levelData.length > state.stage + 1
					? {
							...state,
							playing: false,
							stageComplete: false,
							rewardComplete: false,
							stage: state.stage + 1,
							phase: 1,
					  }
					: {
							...state,
							playing: false,
							stageComplete: true,
							rewardComplete: true,
							levelComplete: true,
							phase: 1,
					  }
			break
		case "PHASE_COMPLETE":
			newState = {
				...state,
				...action.values,
			}
			break
		case "TRANSITION_COMPLETE":
			newState = {
				...state,
				transition: false,
			}
			break
		default:
			newState = state
	}
	if (compareObjects(newState, state)) {
		return state
	} else {
		return newState
	}
}
