const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./one_monkey_walk.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideTopText text-center",
			textIndivData: [
				{
					textData: "एउटा बाँदर आयो,",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.6 }, { start: 2.5 }],
				},
				{
					textData: "रूखतिर हेर्दै,",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 4.1 }, { start: 5.3 }],
				},
				{
					textData: "टाउको कन्यायो।",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 6.6 }, { start: 7.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s1.ogg"),
			delay: 1000,
		},
	],
}

export default content
