import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	images: [
		{
			src: images("./kids/bg_bridge.png"),
		},
		{
			src: images("./kids/pool.svg"),
			styles: {
				width: "100%",
				top: "auto",
				left: "0",
				bottom: "0",
			},
			toSvg: true,
			imgdiv: "imageCover bridge-wip",
		},
		{
			src: images("./kids/engineer.svg"),
			styles: {
				width: "35%",
				top: "auto",
				left: "auto",
				bottom: "0",
				right: "0",
			},
			toSvg: true,
			imgdiv: "imageCover bridge-engineers",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--bridgescene topRightBubble text-center",
			textIndivData: [
				{
					textData: "मेरी दिदी त्यहाँकी नाइके हुन्। ",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.9 }, { start: 1.6 }, { start: 2.3 }, { start: 2.8 }],
				},
				{
					textData: "पुल बनेपछि यसपालिको वर्षामा त म पनि मामाघरमा मेला हेर्न जान पाउँछु।",
					textClass: "m-0",
					timestamps: [
						{ start: 3.0 },
						{ start: 3.6 },
						{ start: 4.1 },
						{ start: 5.2 },
						{ start: 5.9 },
						{ start: 6.1 },
						{ start: 6.4 },
						{ start: 6.8 },
						{ start: 7.3 },
						{ start: 7.8 },
						{ start: 8.2 },
						{ start: 8.6 },
						{ start: 9.0 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./8.ogg"),
			delay: 500,
		},
		{
			url: audios("./a8.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
