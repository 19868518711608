import React from "react"
import normalIcon from "../icons/controls/refresh_blue.svg"
const prevBtn = (props) => {
	return (
		<span
			key="prevBtn"
			onClick={
				props.disabled
					? () => {
							return
					  }
					: props.refreshBtnClick
			}
			className={props.disabled ? "prev__btn disabled" : "prev__btn"}
		>
			<img src={normalIcon} className="img-fluid" alt="refresh" />
		</span>
	)
}

export default prevBtn
