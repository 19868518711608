const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page14.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./house_page16.png"),
			class: "image-one img10",
			imgdiv: "imageCover",
		},
		{
			src: images("./hen_searching_food_new.gif"),
			class: "image-one mother",
			imgdiv: "imageCover",
		},
		{
			src: images("./chiks_moving.gif"),
			class: "image-one chicks",
			imgdiv: "imageCover",
		},

		{
			src: images("./clouds.png"),
			class: "image-one clouds",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page14Text text-center",
			textIndivData: [
				{
					textData: "केहि दिन पछि कुखुरीले फुल कोरली।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.45 }, { start: 0.7 }, { start: 1.2 }, { start: 1.9 }, { start: 2.25 }],
				},
				{
					textData: "माऊसँगै चल्लाहरू खेल्न थाले।",
					textClass: "m-0",
					timestamps: [{ start: 3.6 }, { start: 4.4 }, { start: 5.0 }, { start: 5.4 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./14.ogg"),
			delay: 500,
		},
		{
			url: audios("./14a.ogg"),
			delay: 4000,
		},
		{
			url: audios("./hen.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.3,
		},
	],
}

export default content
