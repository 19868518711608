const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page05_and06_back_new.jpg"),
			class: "img-nine",
			imgdiv: "imageCover",
		},
		{
			src: images("./raining.gif"),
			class: "img-nine",
			imgdiv: "imageCover",
		},
		{
			src: images("./ghum.gif"),
			class: "img-nine",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-bottom-intact  text-center",
			textIndivData: [
				{
					textData: "उनले घुमलाई राम्ररी हेरिन्। ओढिन् पनि।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.9 }, { start: 1.9 }, { start: 2.7 }, { start: 4.1 }, { start: 4.6 }],
				},
				{
					textData: "तर त्यो घुम उनलाई ठूलो भयो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 6.2 }, { start: 6.5 }, { start: 6.8 }, { start: 7.1 }, { start: 8 }, { start: 8.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s8.ogg"),
			delay: 2000,
		},
	],
}

export default content
