import React from "react"
import ReactGA from "react-ga"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { withRouter } from "react-router"
// eslint-disable-next-line max-len
import {
	StoryMenu,
	PlayMenu,
	Homepage,
	StoryContainer,
	Charlie,
	PlayContainer,
	MaPaniSakchu,
	Meezan,
	AmaChamai,
	Tateypatey,
	Joon,
	LaleyRaKaley,
	YoKHo,
	Badal,
	EutaBiu,
	FhatekoJutta,
	SwacchaPaani,
	MeriBai,
	Mane,
	BabreyKoBato,
	PahaKoTel,
	ChhringLama,
	MaBajeBhoot,
	Selbon,
	RamailoHoli,
	Salak,
	SelbonPartTwo,
	TharuTika,
	MaJhumraNach,
	Petete,
	JuneliRaat,
	ShristiKoGeetPartOne,
	ShristiKoGeetPartTwo,
	YumaWeavingPartOne,
	YumaWeavingPartTwo
} from "./AllComponents"
import "bootstrap/dist/css/bootstrap.min.css"
import "./styles/all.scss"
ReactGA.initialize("UA-172822248-1")
ReactGA.pageview(window.location.pathname + window.location.search)
const Routes = withRouter(() => {
	return (
		<Switch>
			<Route exact strict path="/" component={Homepage} />
			<Route exact path="/story" component={StoryMenu} />
			<Route exact path="/play" component={PlayMenu} />
			<Route exact path="/story/charlie-chamero/start" component={Charlie} />
			<Route exact path="/story/ma-pani-sakchu/start" component={MaPaniSakchu} />
			<Route exact path="/story/meezan/start" component={Meezan} />
			<Route exact path="/story/Tateypatey/start" component={Tateypatey} />
			{/* <Route exact path="/story/Banauchu/start" component={Banauchu} /> */}
			<Route exact path="/story/ama-chamai/start" component={AmaChamai} />
			<Route exact path="/story/joon/start" component={Joon} />
			<Route exact path="/story/LaleyRaKaley/start" component={LaleyRaKaley} />
			<Route exact path="/story/YoKHo/start" component={YoKHo} />	
			<Route exact path="/story/Badal/start" component={Badal} />	
			<Route exact path="/story/EutaBiu/start" component={EutaBiu} />	
			<Route exact path="/story/FhatekoJutta/start" component={FhatekoJutta} />	
			<Route exact path="/story/SwacchaPaani/start" component={SwacchaPaani} />	
			<Route exact path="/story/MeriBai/start" component={MeriBai} />	
			<Route exact path="/story/Mane/start" component={Mane} />	
			<Route exact path="/story/BabreyKoBato/start" component={BabreyKoBato} />	
			<Route exact path="/story/PahaKoTel/start" component={PahaKoTel} />	
			<Route exact path="/story/chhring/start" component={ChhringLama} />	
			<Route exact path="/story/ma-baje-bhoot/start" component={MaBajeBhoot} />	
			<Route exact path="/story/selbon/start" component={Selbon} />
			<Route exact path="/story/ramailo-holi/start" component={RamailoHoli} />
			<Route exact path="/story/salak/start" component={Salak} />
			<Route exact path="/story/selbon-part-two/start" component={SelbonPartTwo} />
			<Route exact path="/story/tharu-tika/start" component={TharuTika} />
			<Route exact path="/story/ma-jhumra-nach-nachchu/start" component={MaJhumraNach} />
			<Route exact path="/story/petete/start" component={Petete} />
			<Route exact path="/story/juneli-raat/start" component={JuneliRaat} />
			<Route exact path="/story/shristi-ko-geet-part-one/start" component={ShristiKoGeetPartOne} />
			<Route exact path="/story/shristi-ko-geet-part-two/start" component={ShristiKoGeetPartTwo} />
			<Route exact path="/story/yuma-part-one/start" component={YumaWeavingPartOne} />
			<Route exact path="/story/yuma-part-two/start" component={YumaWeavingPartTwo} />

			<Route
				exact
				path="/story/:storyKey/:page"
				render={({ match, history }) => <StoryContainer match={match} history={history} key={match.params.page || "empty"} />}
			/>
			<Route exact path="/play/:playKey" component={PlayContainer} />
		</Switch>
	)
})

const App = () => {
	return (
		<div id="wrapper">
			<Router basename={process.env.REACT_APP_URL}>
				<Routes />
			</Router>
		</div>
	)
}

export default App
