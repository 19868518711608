import { keyframes } from "@emotion/core"

const content = {
	images: [
		{
			src: "city/clouds.png",
			styles: {
				width: "200%",
				transform: "translateX(0%)",
				animation: `${keyframes({
					to: {
						transform: "translateX(-100%)",
					},
				})} 40s normal linear infinite`,
			},
		},
		{
			src: "city/house_01.png",
			styles: {
				transform: "translate(-25%,0%) scale(0.8)",
			},
		},
		{
			src: "birds/bird_fly05.gif",
			class: "bird1-p13",
			styles: {
				width: "20%",
				top: "36%",
				left: "-18%",
				transform: "translateX(184%) rotate(45deg) scale(0.5)",
				animation: `${keyframes({
					to: {
						transform: "translateX(610%) rotate(45deg) scale(1)",
					},
				})} 4s ease-in-out forwards`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "birds/bird_fly05.gif",
			class: "bird2-p13",
			styles: {
				width: "20%",
				top: "46%",
				left: "-30%",
				transform: "translateX(233%) rotate(45deg) scale(0.5)",
				animation: `${keyframes({
					to: {
						transform: "translateX(610%) rotate(45deg) scale(1)",
					},
				})} 4s ease-in-out forwards`,
			},
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: {
				top: "auto",
				left: "auto",
				right: "0",
				bottom: "1%",
				padding: "15px",
				textAlign: "right",
				background: "rgba(255, 255, 255, 0.8);",
				borderRadius: "15px",
			},
			textIndivData: [
				{
					textData: "घर आँगन छोडेर",
					textClass: "m-0 p13-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "गौथली भागे उडेर",
					textClass: "m-0 p13-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p12.ogg",
			delay: 0,
		},
		{
			url: "birdsChirping.ogg",
			delay: 500,
			isBg: true,
			volume: 0.3,
		},
	],
}

export default content
