const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	images: [
		{
			src: images("./13to24/bg32a.png"),
			imgdiv: "imageCover w-100 position-bottom-intact",
			styles: { top: "auto", zIndex: 10 },
		},
		{
			src: images("./13to24/stars.png"),
			imgdiv: "imageCover star star-1 star-anim d-none",
		},
		{
			src: images("./13to24/sun.png"),
			imgdiv: "imageCover sun sun-anim d-none",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData:
						"त्यसैले तीनै प्राणीले बाटामा एक अर्कासँग नबोल्ने निर्णय गरे। टाढा पुग्‍नुपर्ने भएकोले अर्को दिन बिहानै हिँड्‍ने सल्‍लाह भयो।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.8 },
						{ start: 1.3 },
						{ start: 2.3 },
						{ start: 3 },
						{ start: 3.3 },
						{ start: 4 },
						{ start: 4.6 },
						{ start: 5.1 },
						{ start: 5.6 },
						{ start: 6.6 },
						{ start: 7.3 },
						{ start: 8.1 },
						{ start: 8.5 },
						{ start: 8.9 },
						{ start: 9.8 },
						{ start: 10.4 },
						{ start: 10.9 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p3_s3.ogg"),
			delay: 500,
		},
		{
			url: audios("./p3_s3a.ogg"),
			delay: 6300,
		},
	],
}

export default content
