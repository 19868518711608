/** @jsx jsx */
import { jsx } from "@emotion/core"
import { useEffect } from "react"
import { ReactSVG } from "react-svg"

let time = false

const ImageList = ({ image, currentPage, index, activityKey }) => {
	const imgClass = image.class ?? ""
	const removeDiv = image.removeDiv ?? false
	const imgContainerStyles = image.styles ?? {}
	const imgContainerClass = image.imgdiv ?? "imageCover"

	useEffect(() => {
		if ((image.gif, image.timesec)) {
			setTimeout(() => {
				time = true
			}, image.timesec)
		}
	}, [image])

	if (image.gif) {
		return removeDiv ? (
			image.toSvg ? (
				<ReactSVG src={image.src} />
			) : (
				<img
					key={currentPage + "image" + index}
					src={`${image.src}`}
					className={`${imgContainerClass} ${imgClass} ${activityKey}-images page-${currentPage} image-${index}`}
					css={{ ".activity__container &.imageCover ": imgContainerStyles }}
					alt=""
				/>
			)
		) : (
			<div
				key={currentPage + "imageDiv" + index}
				className={`${imgContainerClass} ${activityKey}-images page-${currentPage} image-${index}`}
				css={{ ".activity__container &.imageCover ": imgContainerStyles }}
			>
				{image.toSvg ? (
					<ReactSVG src={image.src} />
				) : (
					<img
						key={currentPage + "image" + index}
						src={`${time ? image.img : image.src}`}
						className={`img-fluid ${imgClass}`}
						alt=""
					/>
				)}
			</div>
		)
	} else {
		return removeDiv ? (
			image.toSvg ? (
				<ReactSVG src={image.src} />
			) : (
				<img
					key={currentPage + "image" + index}
					src={`${image.src}`}
					className={`${imgContainerClass} ${imgClass} ${activityKey}-images page-${currentPage} image-${index}`}
					css={{ ".activity__container &.imageCover ": imgContainerStyles }}
					alt=""
				/>
			)
		) : (
			<div
				key={currentPage + "imageDiv" + index}
				className={`${imgContainerClass} ${activityKey}-images page-${currentPage} image-${index}`}
				css={{ ".activity__container &.imageCover ": imgContainerStyles }}
			>
				{image.toSvg ? (
					<ReactSVG src={image.src} />
				) : (
					<img key={currentPage + "image" + index} src={`${image.src}`} className={`img-fluid ${imgClass}`} alt="" />
				)}
			</div>
		)
	}
}

export default ImageList
