export default [
	{
		key: "badar",
		letters: ["बाँ", "द", "र"],
	},
	{
		key: "tauko",
		letters: ["टा", "उ", "को"],
	},
	{
		key: "chhana",
		letters: ["छा", "ना"],
	},
	{
		key: "aara",
		letters: ["आ", "रा"],
	},
	{
		key: "rukh",
		letters: ["रु", "ख"],
	},
	{
		key: "rang",
		letters: ["र", "ङ"],
	},
	{
		key: "khat", //lvl 1
		letters: ["खा", "ट"],
	},
	{
		key: "jhola",
		letters: ["झो", "ला"],
	},
	{
		key: "dhara",
		letters: ["धा", "रा"],
	},
	{
		key: "ful",
		letters: ["फू", "ल"],
	},
	{
		key: "fed",
		letters: ["फे", "द"],
	},
	{
		key: "fal",
		letters: ["फ", "ल"],
	},
	{
		key: "pani",
		letters: ["पा", "नी"],
	},
	{
		key: "ping",
		letters: ["पि", "ङ"],
	},
	{
		key: "paat",
		letters: ["पा", "त"],
	},
	{
		key: "baja",
		letters: ["बा", "जा"],
	},
	{
		key: "dhaan",
		letters: ["धा", "न"],
	},
	{
		key: "ghum",
		letters: ["घु", "म"],
	},
	{
		key: "bato",
		letters: ["बा", "टो"],
	},
	{
		key: "sathi",
		letters: ["सा", "थी"],
	},
	{
		key: "pathhi",
		letters: ["पा", "थी"],
	},
	{
		key: "pathi",
		letters: ["पा", "ठी"],
	},
	{
		key: "goda",
		letters: ["गो", "डा"],
	},
	{
		key: "ghoda",
		letters: ["घो", "डा"],
	},
	{
		key: "dhoka",
		letters: ["ढो", "का"],
	},
	{
		key: "haat",
		letters: ["हा", "त"],
	},
	{
		key: "tika",
		letters: ["टी", "का"],
	},
	{
		key: "gaun",
		letters: ["गा", "उँ"],
	},
	{
		key: "paisa",
		letters: ["पै", "सा"],
	},
	{
		key: "raja",
		letters: ["रा", "जा"],
	},
	{
		key: "machha",
		letters: ["मा", "छा"],
	},
	{
		key: "bhat",
		letters: ["भा", "त"],
	},
	{
		key: "khasi",
		letters: ["ख", "सी"],
	},
	{
		key: "kaan",
		letters: ["का", "न"],
	},
	{
		key: "pado",
		letters: ["पा", "डो"],
	},
	{
		key: "gai",
		letters: ["गा", "ई"],
	},
	{
		key: "veda",
		letters: ["भे", "डा"],
	},
	{
		key: "hatti",
		letters: ["हा", "त्ती"],
	},
	{
		key: "topi",
		letters: ["टो", "पी"],
	},
	{
		key: "chhala",
		letters: ["छा", "ला"],
	},
	{
		key: "gaida",
		letters: ["गैँ", "डा"],
	},
	{
		key: "soon",
		letters: ["सु", "न"],
	},
	{
		key: "mukh",
		letters: ["मु", "ख"],
	},
]
