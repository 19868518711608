const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./kid03.png"),
			class: "image-five kid03",
			imgdiv: "imageCover",
		},
		{
			src: images("./kid02.png"),
			class: "image-five kid02",
			imgdiv: "imageCover",
		},
		{
			src: images("./kid01.png"),
			class: "image-five kid01",
			imgdiv: "imageCover",
		},
		{
			src: images("./aama.gif"),
			class: "image-five mum_standing",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox14 text_box-p14",
			textIndivData: [
				{
					textData: "माऊले आफ्‍नो जिउको भुत्ला उखेली।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.4 }, { start: 1.8 }, { start: 2.2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./14.ogg"),
			delay: 3500,
		},
	],
}

export default content
