import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page9scene",
	images: [
		{
			src: images("./page7_bg.jpg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./didi_playing_baja01.png"),
			imgdiv: "imageCover didi-png-p9",
		},
		{
			src: images("./didi_playing_baja_body.gif"),
			imgdiv: "imageCover didi-gif-p9",
		},
		{
			src: images("./didi_playing_baja_eye.gif"),
			imgdiv: "dd-head-gifa-p9",
		},
		{
			src: images("./didi_playing_baja_eye.gif"),
			imgdiv: "dd-head-gifb-p9",
		},

		{
			src: images("./nima_play_jhali_body01.png"),
			imgdiv: "imageCover nima-body-png-p9",
		},
		{
			src: images("./nima_play_jhali_body01.gif"),
			imgdiv: "imageCover nima_play_gif-p9",
		},
		{
			src: images("./nima_play_jhali_talking.gif"),
			imgdiv: "nima-head-gifa-p9",
		},
		{
			src: images("./nima_play_jhali_head01.gif"),
			imgdiv: "nima-head-gifb-p9",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center dialogueBubble bubble-sm-thin-left-o text-bubble-p9",
			textIndivData: [
				{
					textData: "तपाईं दमाहा बजाउनुस्, म झ्याली बजाउँछु है।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.15 },
						{ start: 2.2 },
						{ start: 2.6 },
						{ start: 3 },
						{ start: 3.5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./page10.ogg"),
			delay: 4000,
		},
		{
			url: audios("./damahaOnly.ogg"),
			delay: 10000,
			isBg: true,
			volume: 0.5,
		},
		{
			url: audios("./damahaNJayali.ogg"),
			delay: 10000,
			isBg: true,
			volume: 0.5,
			loop: true,
		},
		{
			url: audios("./blank.ogg"),
			delay: 12000,
			isBg: false,
			volume: 0,
		},
	],
}

export default content
