import wordsConfig from "./words"

// console.log("wordsConfig", wordsConfig);
const content = {
	// instructionurl: audios("./s0.ogg"),
	easy: {
		width: 15,
		height: 6,
		words: wordsConfig[0],
	},
	medium: {
		width: 9,
		height: 10,
		words: wordsConfig[1],
	},
	hard: {
		width: 9,
		height: 10,
		words: wordsConfig[2],
	},
}
export default content
