import { appear, disappear } from "styledElements/keyframes"
const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./yello_band.png"),
			class: "image-one yello_bandp2",
			imgdiv: "imageCover",
		},
		{
			src: images("./zera_move_head.gif"),
			class: "image-one zera_move_head",
			imgdiv: "imageCover",
		},
		{
			src: images("./zera_putting_recttraingle.gif"),
			class: "image-one zera_putting_rectangle",
			imgdiv: "imageCover",
		},
		{
			src: images("./zera_putting_rectangle.png"),
			class: "image-one zera_putting_rectanglepng",
			imgdiv: "imageCover",
		},
		{
			src: images("./rectangle.gif"),
			styles: {
				opacity: 0,
				top: '20%',
				left: '53%',
				width: "26%",
				transform: "scaleX(1.4)",
				animation: `${appear} 0.01s 1.5s ease-out normal forwards, ${disappear} 0.01s 10.5s ease-out normal forwards`,
			},
		},
		{
			src: images("./rectangle.png"),
			styles: {
				opacity: 0,
				top: '20%',
				left: '53%',
				width: "26%",
				transform: "scaleX(1.4)",
				animation: `${appear} 0.01s 10.5s ease-out normal both`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story-text page2Text text-center",
			textIndivData: [
				{
					textData: "",
					textClass: "m-0",
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./bg/se_page2to4.ogg"),
			volume: 0.4,
			delay: 200,
			isBg: true,
			loop: true,
		},
		{
			url: audios("./bg/insert.ogg"),
			delay: 5000,
			volume: 0.3,
		},
		{
			url: audios("./rectangle.ogg"),
			delay: 10500,
		},
	],
}

export default content
