import React from "react"
import _ from "underscore"
import shuffle from "shuffle-array"
import Balloon from "../Balloon/Balloon"
import jingleSound from "../../content/audios/sound_effects/jingle-win.ogg"
import gsap from "gsap"
import { Howl } from "howler"
import { StateContext, DispatchContext } from "../../BalloonPopGame"

import "./options.styles.scss"
import SvgDefs from "./SvgDefs"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

const audioHints = require.context("../../content/audios/words", true)
const Options = (props) => {
	const state = React.useContext(StateContext)
	const dispatch = React.useContext(DispatchContext)
	const optionsRef = React.useRef(null)

	let stageData = state.levelData[state.stage]

	let currentWord = {
		key: stageData.key,
		correct: stageData.word,
		extra: stageData.options,
	}

	const getColor = (i) => {
		let mainClr = (i + 1) * 90 - 90 * Math.random()
		return "hsl(" + mainClr.toString() + "," + (80 + 20 * Math.random()) + "%," + (45 + 15 * Math.random()) + "%)"
	}

	const jinglePlayer = new Howl({
		src: [jingleSound],
		autoplay: false,
		loop: false,
	})

	const onCorrect = (svg = null) => {
		optionsRef.current.querySelectorAll("svg").forEach((svgEl) => {
			if (svgEl !== svg) {
				gsap.killTweensOf(svgEl)
				optionsRef.current.removeChild(svgEl)
			}
		})
		let minDuration = 0.12
		let maxDuration = 3.5
		let n_confettis = 80

		gsap.to(svg, {
			delay: maxDuration - 2.25,
			duration: minDuration,
			opacity: 1,
			onStart: () => {},
			onComplete: () => {
				jinglePlayer.play()
				// props.onCorrect();
				dispatch({ type: "STAGE_COMPLETE" })
			},
		})
		for (let i = 0; i <= n_confettis; i++) {
			let confetti = document.createElementNS("http://www.w3.org/2000/svg", "use")
			confetti.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", `#confetti_${Math.ceil(Math.random() * 4)}`)
			confetti.setAttribute("style", `--color: ${getColor(i)}`)
			confetti.setAttribute("class", "confetti")

			svg.appendChild(confetti)

			gsap.set(confetti, {
				x: 150,
				y: 150,
				opacity: 1,
				scale: Math.random() * 3,
				rotation: Math.random() * 360,
				transformOrigin: "center",
			})
			let y = (i / n_confettis) * 800 - Math.random() * 800
			gsap.to(confetti, {
				duration: minDuration,
				x: 150 + Math.random() * 800 - Math.random() * 800,
				y: y - 250,
				// x:  650 + Math.random() * 1000 - 1000,
				// y: 150 +  Math.random() * (1000) - 1000,
				rotation: Math.random() * 720 - 360,
				ease: "power4.inOut",
				onComplete: () => {
					gsap.to(confetti, {
						delay: 0.12,
						duration: maxDuration + minDuration,
						y: gsap.getProperty(confetti, "y") + Math.random() * 2000 + 2000,
						opacity: 0,
						rotation: Math.random() * 720 - 360,
						ease: "power1.out",
						onComplete: () => {
							svg.removeChild(confetti)
						},
					})
				},
			})
		}
	}

	let slots = [true, true, true, true]
	let leftPadding = ["5%", "25%", "50%", "75%"]
	let firstRun = true
	const animateBalloonContainer = (svg) => {
		const getSlot = () => {
			let checkingArray = []
			slots.forEach((slot, i) => {
				if (slot) {
					checkingArray.push(i)
				}
			})
			if (optionsRef.current) {
				if (checkingArray.length < 1 || optionsRef.current.querySelectorAll("svg").length < 2) {
					checkingArray = [0, 1, 2, 3]
					slots = [true, true, true, true]
				}
			}
			const randomPick = shuffle(checkingArray)[0]
			slots[randomPick] = false
			return randomPick
		}

		const slot = getSlot()
		gsap.set(svg, {
			yPercent: 100,
			xPercent: Math.random() * 100 - 50,
			left: leftPadding[slot],
			bottom: "0%",
			scale: 1,
		})

		// const duration = 1 + 1.5 * Math.random();
		const duration = 4 + 2 * Math.random()
		const delay = firstRun ? 1.5 * Math.random() : 0
		// const randomMultiplier = 1 + Math.random();
		// let delay = Math.random();
		gsap.to(svg, {
			duration: duration,
			delay: delay,
			// bottom: `${125}%`,
			xPercent: slot === 0 ? Math.random() * 100 : Math.random() * 250 - 125,
			yPercent: -280,
			ease: "power1.in",
			onStart: () => {
				gsap.set(svg, {
					opacity: 1,
					delay: delay + 2,
					onComplete: () => {
						firstRun = false
						slots[slot] = true
					},
				})
			},
			onComplete: () => {
				animateBalloonContainer(svg)
			},
		})
	}

	const allOptions = shuffle([...currentWord.extra, { word: currentWord.correct, audio: audioHints(`./${currentWord.key}/hint.ogg`) }])
	React.useEffect(() => {
		let options = optionsRef.current
		const hintPlayers = allOptions.map((opt, idx) => {
			return new Howl({
				src: [opt.audio],
				autoplay: false,
				loop: false,
			})
		})
		// let hoverHintPlaying = false;

		const hoverListener = _.debounce(
			(event) => {
				// if (!hoverHintPlaying) {
				// 	hoverHintPlaying = true;
				hintPlayers[event.currentTarget.idx].play()
				// hintPlayers[event.currentTarget.idx].on("end", function () {
				// 	hoverHintPlaying = false;
				// });
				// }
			},
			250,
			true
		)

		options.querySelectorAll("svg").forEach((svgEl, idx) => {
			let useEl = svgEl.querySelector("use.balloon")
			useEl.idx = idx
			useEl.addEventListener("mouseover", hoverListener)
			animateBalloonContainer(svgEl)
		})

		return () => {
			// options.querySelectorAll("svg").forEach((svgEl, idx) => {
			// 	let useEl = svgEl.querySelector("use.balloon");
			// 	useEl.removeEventListener("mouseover", hoverListener);
			// });
		}
	})

	return (
		<div className="game-options m-2 w-75 h-100 ">
			<SvgDefs />
			<div className="options-list h-100 w-100" ref={optionsRef}>
				{allOptions.map((option, i) => (
					<Balloon
						color={getColor(i)}
						onCorrect={onCorrect}
						optionNo={i}
						key={`ballon-${i}`}
						value={option}
						isCorrect={option.word === currentWord.correct}
					/>
				))}
			</div>
		</div>
	)
}

Options.whyDidYouRender = true
const OptionsMemo = React.memo(Options)
OptionsMemo.whyDidYouRender = true

export default OptionsMemo

// export default Options;
