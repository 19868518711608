const content = {
	images: [
		{
			src: "forest/bird_picking_sticks01.png",
			class: "sticks-p2",
			imgdiv: "imageCover",
		},
		{
			src: "birds/bird-picking-stick.gif",
			class: "bird-p2",
			imgdiv: "imageCover",
		},
		{
			src: "forest/bird_fly.gif",
			class: "bird_gif-p2",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text",
			styles: {
				top: "5%",
				left: "16%",
				padding: "15px",
				background: "rgba(255, 255, 255, 0.8);",
				borderRadius: "15px",
			},
			textIndivData: [
				{
					textData: "हार उस्ले नखाई",
					textClass: "m-0 p2-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "टुक्रा टाक्रि बटुली",
					textClass: "m-0 p2-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p6.ogg",
			delay: 500,
		},
		{
			url: "birdsChirping.ogg",
			delay: 0,
			volume: 1,
			loop: true,
			isBg: true,
		},
	],
}

export default content
