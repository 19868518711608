import React from "react"
import Brick from "../Brick/Brick"
import { useEffect } from "react"
import { useState } from "react"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

// eslint-disable-next-line react/display-name
const Wall = ({ gridWidth, gridHeight }) => {
	const [wall, setWall] = useState([])
	useEffect(() => {
		let rowHeight = 100 / gridHeight
		let wordCount = 0
		let row = []
		let columns
		for (let i = 1; i <= gridHeight; i++) {
			columns = []
			for (let j = 1; j <= gridWidth; j++) {
				columns.push(
					<div className="col" key={`${i}-${j}`}>
						<Brick wordCount={wordCount} x={j} y={i} />
					</div>
				)
				wordCount++
			}

			row.push(
				<div className="row" key={`row-${i}`} style={{ height: `${rowHeight}%` }}>
					{columns}
				</div>
			)
		}
		setWall(row)
	}, [gridWidth, gridHeight])

	return <div className="container">{wall}</div>
}

Wall.whyDidYouRender = true
const WallMemo = React.memo(Wall)
WallMemo.whyDidYouRender = true

export default WallMemo
// export default Wall;
