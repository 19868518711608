import React from "react"
import gsap from "gsap"
// import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import burstSound from "../../content/audios/sound_effects/balloon-1.ogg"
import blastSound from "../../content/audios/sound_effects/muffled-laser-blast.ogg"
import { Howl } from "howler"
import "./balloon.styles.scss"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

const Balloon = (props) => {
	// gsap.registerPlugin(MotionPathPlugin);
	// console.log(gsap);
	let svgRef = React.useRef(null)
	const optionNo = props.optionNo

	const balloonPlayer = new Howl({
		src: [burstSound],
		autoplay: false,
		loop: false,
		sprite: {
			fail: [0, 800],
		},
	})

	const blastPlayer = new Howl({
		src: [blastSound],
		autoplay: false,
		loop: false,
	})

	React.useEffect(() => {
		const value = props.value.word
		const svg = svgRef.current

		const createBalloon = () => {
			let useEl = document.createElementNS("http://www.w3.org/2000/svg", "use")
			useEl.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "#balloon")
			useEl.setAttribute("style", `--color:${props.color}`)
			useEl.setAttribute("class", "balloon")
			return useEl
		}

		const createText = () => {
			let textEl = document.createElementNS("http://www.w3.org/2000/svg", "text")
			textEl.setAttribute("fontSize", "24px")
			textEl.setAttribute("textAnchor", "middle")
			textEl.setAttribute("fill", "black")
			textEl.textContent = value
			textEl.setAttribute("class", "balloon-text")
			return textEl
		}

		const popBalloon = (colorSettings, isCorrect) => {
			let textEl = svg.querySelector("text")
			// let balloonEl = svg.querySelector(".balloon");
			svg.removeChild(textEl)
			// svg.removeChild(balloonEl);
			dropAnimation(colorSettings, svg)
			if (!isCorrect) {
				balloonPlayer.play("fail")
			} else {
				blastPlayer.play()
				props.onCorrect(svg)
			}
		}

		const dropAnimation = (colorSettings, svg) => {
			let pop = document.createElementNS("http://www.w3.org/2000/svg", "use")
			pop.setAttributeNS("http://www.w3.org/1999/xlink", "xlink:href", "#pop")
			pop.setAttribute("style", colorSettings)
			pop.setAttribute("class", "pop")

			svg.appendChild(pop)
			gsap.set(pop, {
				scale: 0.8,
				transformOrigin: "center",
			})
			gsap.to(pop, {
				duration: 1.5,
				scale: 0.3,
				opacity: 0.2,
				y: 2000,
				ease: "power1.in",
				onComplete: () => {
					svg.remove()
					// svg.removeChild(pop);
				},
			})
		}

		const onBalloonClick = (event) => {
			let balloon = event.target
			let isCorrect = props.isCorrect
			let balloonContainer = balloon.parentElement
			gsap.killTweensOf(balloon)
			gsap.killTweensOf(balloonContainer)
			const colorSettings = balloon.getAttribute("style")
			balloon.parentElement.removeChild(balloon)
			popBalloon(colorSettings, isCorrect)
		}

		const balloonText = createText()
		const newBalloon = createBalloon()
		svg.appendChild(newBalloon)
		svg.appendChild(balloonText)
		newBalloon.addEventListener("click", onBalloonClick)

		return () => {
			balloonPlayer.unload()
			blastPlayer.unload()
			// hintPlayer.unload();
			newBalloon.removeEventListener("click", onBalloonClick)
		}
	})

	return (
		<React.Fragment>
			<svg
				// width={state.windowSize.width * 0.7 * 0.2}
				// height={state.windowSize.height * 0.3}x="0px" y="0px"
				className={`balloon-svg bs-${optionNo}`}
				viewBox="0 0 320 560"
				preserveAspectRatio="xMidYMid meet"
				ref={svgRef}
				id="svg"
				xmlns="http://www.w3.org/2000/svg"
			></svg>
		</React.Fragment>
	)
}

Balloon.whyDidYouRender = true
const BalloonMemo = React.memo(Balloon)
BalloonMemo.whyDidYouRender = true

export default Balloon

// export default Balloon;
