/** @jsx jsx */
import { jsx } from "@emotion/core"

import React, {
	useEffect,
	useRef,
	useState,
	SyntheticEvent
	//  useState
} from "react"
import ReactPlayer from "react-player"
import _, { reduce } from "underscore"
import gsap from "gsap"

import { isMobile } from "react-device-detect"

// custom components
import Header from "Components/Layout/Header"
import Footer from "Components/Layout/Footer"
import PageNumber from "Components/PageNumber"
import BackwardBtn from "./components/BackwardBtn"
import ForwardBtn from "./components/ForwardBtn"
import PrevBtn from "./components/PrevBtn"
import NextBtn from "./components/NextBtn"
import ResetBtn from "./components/ResetBtn"
import HomeBtn from "./components/HomeBtn"
import PlayPauseBtn from "./components/PlayPauseBtn"
import ProgressBar from "./components/ProgressBar"

// icons
import playButton from "./icons/start/play_video_nep.svg"
import soundOn from "./icons/controls/SoundOn.png"
import soundOff from "./icons/controls/SoundOff.png"

import { AwesomeButton } from "react-awesome-button"
import "react-awesome-button/dist/styles.css"
import "./btnStyles.css"

// helpers
import { getStoryData } from "../../ContainerHelpers"
import "./styles.scss";
import Slider from '@mui/material/Slider';

export const DispatchContext = React.createContext(null)

const format = (seconds) => {
	if(isNaN(seconds)){
		return '00:00'
	}

	const date = new Date(seconds * 1000);
	const hh = date.getUTCHours()
	const mm = date.getUTCMinutes()
	const ss = date.getUTCSeconds().toString().padStart(2,"0");

	if(hh){
		return `${hh}:${mm.toString().padStart(2, "0")}: ${ss}`;
	}
	return `${mm}:${ss}`;
}

const Container = () => {
	const [state, setState] = useState({
		played:0,
		seekingShow: false,
		volume: 1
	})
	const {played, seekingShow, volume } = state;
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)
	const [currentVideo, setCurrentVideo] = useState(0)

	const [playing, setPlaying] = useState(false)
	const [movieMode, setMovieMode] = useState(false)
	const [waitingInput, setWaitingInput] = useState(false)
	const [sound, setSound] = useState(false)
	const [started, setStarted] = useState(false)
	const [startProgress, setStartProgress] = useState(false);
	const [movieLength, setMovieLength] = useState(0);
	const [moviePlay, setMoviePlay] = useState(0);
	const [movieCurrentLength, setMovieCurrentLength] = useState(0);
	const movieHolder = useRef(null)
	const moviePlayer = useRef(null)
	const progresslineClick = useRef(null);
	const content = getStoryData("SelbonPartTwo")
	const storyname = content.name

	const [anim, setAnim] = useState(true);
	var t = gsap.timeline({});
	var paused;

var a;
	const isMobileLandscape = isMobile && window.innerWidth > window.innerHeight

	let startStyles = {
		width: `${width}px`,
	}
	let thumbnail = {
		width: `100%`,
		height: `${height}px`,
		minHeight: `${height}px`,
		background: `url(${content.coverImage})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	}
	let buttonHolder = {
		width: `100%`,
		display: "flex",
		justifyContent: "space-evenly",
		alignItems: "center",
		background: "#123858",
		padding: "10px 0px",
		"@media (max-width: 575px)": {
			flexDirection: isMobileLandscape ? "row" : "column",
			justifyContent: isMobileLandscape ? "space-around" : "center",
			"button:nth-of-type(2)": {
				marginTop: isMobileLandscape ? "0px" : "20px",
			},
		},
	}

	const startButtonStyles = {
		borderRadius: "40px",
		cursor: "pointer",
		width: isMobileLandscape ? "125px" : "200px",
		height: isMobileLandscape ? "50px" : "80px",
		padding: "0",
		img: {
			width: "200px",
		},
	}

	const StartBtn = () => {
		return (
			<div css={startStyles}>
				<div css={thumbnail}></div>
				<div css={buttonHolder}>
					<AwesomeButton
						css={startButtonStyles}
						onPress={() => {
							setStarted(true)
							setPlaying(true)
							setMovieMode(true)
							setStartProgress(true)
							// tryvideoget()
							loadandclick()
						}}
					>
						<img className="img-fluid" src={playButton} alt="play button" />
					</AwesomeButton>
					{/* <AwesomeButton
						type="secondary"
						css={startButtonStyles}
						onPress={() => {
							setStarted(true)
							setPlaying(true)
							setMovieMode(false)
							setCurrentPage(0)
						}}
					>
						<img className="img-fluid" src={readButton} alt="read button" />
					</AwesomeButton> */}
				</div>
			</div>
		)
	}

	const loadandclick = () =>{
		// if(!movieMode){
		// 	console.log(playing, started, movieMode, startProgress)
		// 	console.log(moviePlayer.current)
		// }
		
		setTimeout(()=>{
			// setMovieMode(true);
			if(!started){
				let len = moviePlayer.current.getDuration();
				let currentLength = moviePlayer.current.getCurrentTime();
				setMovieLength(len);
				setMovieCurrentLength(currentLength);
			}
		},1000)
	}
	const nextBtnClick = () => {
		if (movieMode) {
			let time = moviePlayer.current.getCurrentTime() + 5;
			if (time <= moviePlayer.current.getDuration() - 5) {
				// If current video can forward 5 seconds
				moviePlayer.current.seekTo(moviePlayer.current.getCurrentTime() + 5)
				// console.log(moviePlayer.current.getDuration());
				setMovieCurrentLength(time);
			} else {
				if (content.movies.length > 1 && currentVideo + 1 < content.movies.length) {
					// If current video cannot forward 5 seconds and if we have next video in stack, then load next video
					setCurrentVideo(currentVideo + 1)
				}
			}
		} else {
			setCurrentVideo(currentVideo + 1)
			setWaitingInput(false)
			setPlaying(true)
			setCurrentPage(currentPage + 1)
		}
	}

	const prevBtnClick = () => {
		if (movieMode) {
			let time = moviePlayer.current.getCurrentTime() - 5
			if (time >= 0) {
				// If current video can deduct  5 seconds
				moviePlayer.current.seekTo(moviePlayer.current.getCurrentTime() - 5)
				setMovieCurrentLength(time);
			} else {
				// If current video cannot deduct  5 seconds, then load previous video from stack.
				if (content.movies.length > 1 && currentVideo > 0) {
					setCurrentVideo(currentVideo - 1)
				}
			}
		} else {
			setCurrentVideo(currentVideo - 1)
			setCurrentPage(currentPage - 1)
			setWaitingInput(false)
			setPlaying(true)
		}
	}

	const toggleSound = () => {
		setSound(!sound)
	}

	const handleVolumeChange=(e, newValue) => {
		console.log(parseInt(newValue/100));
		// setState({...state, volume:parseFloat(newValue/100)})
		// setSound(newValue === 0 ? true : false);
		console.log(volume, sound);
		
	}

	const handleVolumeSeekDown= (e, newValue) =>{
		console.log("aa");
		setState({...state, volume:parseFloat(newValue/100)})
		setSound(newValue === 0 ? true : false);
		console.log(state.volume);
	};

	const playPauseBtnClick = () => {
		if (playing) {
			setPlaying(false);
		} else {
			setPlaying(true);
		}
	}
	const refreshBtnClick = () => {
		let timestamp = content.timestamps[currentPage - 1] || { totalTime: 0 }
		setCurrentPage(currentPage)
		moviePlayer.current.seekTo(timestamp.totalTime)
		setWaitingInput(false)
		setPlaying(true)
		setStartProgress(true)
		// moviePlayer.current.seekTo(moviePlayer.current.getCurrentTime())
	}
	const homeBtnClick = () => {
		setStarted(false)
		setPlaying(false)
		setCurrentPage(0)
		setCurrentVideo(0)
		setWaitingInput(false)
		setStartProgress(false)
		moviePlayer.current.seekTo(0)
	}

	const onPlaying =  () => {
		if(movieMode){
			if(started){
				const duration = moviePlayer.current.getDuration();
				const ct = moviePlayer.current.getCurrentTime();
				console.log(duration, ct);

				if(!moviePlay.seeking){
					setMoviePlay({...moviePlay, "progress": ct / duration * 100, "length": duration});
				}
			}
		}
	}

	const checkWidth = (e) => {
		let width = progresslineClick.current.clientWidth;
		const offset = e.nativeEvent.offsetX;

		const divprogress = offset / width * 100;
		
		moviePlayer.current.seekTo(divprogress / 100 * moviePlay.length);
		setMoviePlay({...moviePlay, "progress": moviePlayer.current.CurrentTime / moviePlay.length * 100})
	}

	const handleProgress = (changeState) => {
		// if(movieMode){
		// 	if(started){
				// console.log(changeState)
				if(!state.seekingShow){
					setState({...state, ...changeState});
				}
		// 	}
		// }
	};
	const handleSeekChange = (e, newValue) =>{
		console.log(parseFloat(newValue / 100))
		setState({...state, played: parseFloat(newValue / 100)});
		// moviePlayer.current.seekTo(newValue / 100);
		// setMoviePlay({...moviePlay, "progress": parseFloat(newValue / 100)})
	};

	const handleSeekMouseDown = (e, newValue) =>{
		console.log(newValue)
		// setState({...state, seekingShow:true})
	};
	const handleSeekMouseUp = (e, newValue) => {
		console.log(newValue);
		// setState({...state, seekingShow:false})
		setState({...state, played: parseFloat(newValue / 100), seekingShow:false});
		moviePlayer.current.seekTo(newValue / 100);
	};

	// setting format for time time start -------------------------------->>>>
		const currentTime = moviePlayer.current ? moviePlayer.current.getCurrentTime() : "00:00"
		const duration = moviePlayer.current ? moviePlayer.current.getDuration() : "00:00"

		const elapsedTime = format(currentTime)
		const totalDuration =  format(duration)
	// setting format for time time end -------------------------------->>>>

	function ActivityControls({ currentPage, totalPages }) {
		return (
			<div
				className="activity__nav"
				css={{
					".movie__frame &.activity__nav": {
						width: `${width}px`,
					},
				}}
			>
				{/* the progress bar added by bishal for testing purpose */}
				<div className="progressbar">
						{startProgress && (
							<div className="progressbar_controller">
								<ProgressBar
									// checkWidth={checkWidth}
									// progresslineClick={progresslineClick}
									// loadandclick={loadandclick}
									className="lines"
									// value={totalPages}
									// currentMovie={moviePlay}
									played={played}
									onSeek = {handleSeekChange}
									onSeekMouseDown = {handleSeekMouseDown}
									onSeekMouseUp = {handleSeekMouseUp}
									elapsedTime= {elapsedTime}
								/>
							</div>
						)}			
					</div>
					{/* end the progress bar added by bishal for testing purpose */}
				<div className="row below_btn_controller">
					<div className="col-4 sound_time_div">
						{!movieMode ? (
							<PageNumber totalPages={totalPages} currentPage={currentPage} key={"pn" + currentPage} />
						) : (
							<>
							<div className="sound-controller" onClick={toggleSound}>
								{sound ? (
									<img src={soundOff} className="img-fluid" alt="Sound off" />
								) : (
									<img src={soundOn} className="img-fluid" alt="Sound on" />
								)}
								
							</div>
							<div className="sound_slider">
							<Slider
									size="small"
									min={0}
									max={100}
									defaultValue={volume * 100}
									aria-label="Small"
									valueLabelDisplay="auto"
									onChange={handleVolumeChange}
									onChangeCommitted={handleVolumeSeekDown}
								/>
							</div>
							<div className="video_time">
									<span>{elapsedTime} / {totalDuration}</span>
									{/* <span>{parseFloat(moviePlay.progress).toFixed(2)} </span>
									<span>/</span>
									<span> {parseFloat(moviePlay.length / 60).toFixed(2)}</span> */}
								</div>
							</>
						)}
					</div>
					<div className="col-4 text-right">
						{movieMode && (
							<div className="pl-1 pl-md-3 d-inline-block icon-btn-group">
								<HomeBtn homeBtnClick={homeBtnClick} />
								<BackwardBtn disabled={false} prevBtnClick={prevBtnClick} />
								<PlayPauseBtn playPauseBtnClick={playPauseBtnClick} disabled={!playing} />
								<ForwardBtn
									disabled={!movieMode && (!started || currentPage === totalPages || playing)}
									success={waitingInput}
									nextBtnClick={nextBtnClick}
									className={waitingInput ? "next__btn waiting" : "next__btn"}
								/>
							</div>
						)}
						{!movieMode && (
							<div className="pl-1 pl-md-3 d-inline-block icon-btn-group">
								<HomeBtn homeBtnClick={homeBtnClick} />
								<ResetBtn refreshBtnClick={refreshBtnClick} />
								<PrevBtn disabled={currentPage < 1} prevBtnClick={prevBtnClick} />
								<NextBtn
									disabled={!movieMode && (!started || currentPage + 1 === totalPages || playing)}
									success={waitingInput}
									nextBtnClick={nextBtnClick}
									className={waitingInput ? "next__btn waiting" : "next__btn"}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		)
	}

	function ActivityControlsMobileLandscape({ currentPage, totalPages }) {
		return (
			<div
				className="activity__nav"
				css={{
					".movie__frame &.activity__nav": {
						width: "60px",
						padding: "0",
						justifyContent: "space-evenly",
						alignItems: "center",
						display: "flex",
						flexDirection: "column",
					},
				}}
			>
				{movieMode && (
					<React.Fragment>
						<HomeBtn homeBtnClick={homeBtnClick} />
						<BackwardBtn disabled={false} prevBtnClick={prevBtnClick} />
						<PlayPauseBtn playPauseBtnClick={playPauseBtnClick} disabled={!playing} />
						<ForwardBtn
							disabled={!movieMode && (!started || currentPage === totalPages || playing)}
							success={waitingInput}
							nextBtnClick={nextBtnClick}
							className={waitingInput ? "next__btn waiting" : "next__btn"}
						/>
					</React.Fragment>
				)}
				{!movieMode && (
					<React.Fragment>
						<HomeBtn homeBtnClick={homeBtnClick} />
						<ResetBtn refreshBtnClick={refreshBtnClick} />
						<PrevBtn disabled={currentPage < 1} prevBtnClick={prevBtnClick} />
						<NextBtn
							disabled={!movieMode && (!started || currentPage + 1 === totalPages || playing)}
							success={waitingInput}
							nextBtnClick={nextBtnClick}
							className={waitingInput ? "next__btn waiting" : "next__btn"}
						/>
					</React.Fragment>
				)}
			</div>
		)
	}

	useEffect(() => {
		document.title = `HRK :: stories :: ${storyname}`
		return () => (document.title = `HRK `)
	}, [storyname])

	//----------------------- NEXT ROUND DEDUGGING Helper -------------------------
	let map = { 16: false, 39: false } //16: shift , 39: right arrow   <= keyCodes

	useEffect(() => {
		const keyDown = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = true
				if (map[16] && map[39]) {
					// FIRE EVENT
					nextBtnClick()
					// dispatch({ type: 'NEXT_STAGE' });
				}
			}
		}
		window.addEventListener("keydown", keyDown)

		const keyUp = (e) => {
			if (e.keyCode in map) {
				map[e.keyCode] = false
			}
		}
		window.addEventListener("keyup", keyUp)
		return () => {
			window.removeEventListener("keydown", keyDown)
			window.removeEventListener("keyup", keyUp)
		}
	})

	useEffect(() => {
		const resizeHandler = () => {
			const excessHeight = isMobileLandscape ? 56 + 10 + 56 + 10 : 56 + 10 + 56 + 76 + 10
			let computedWidth = isMobileLandscape ? movieHolder.current.offsetWidth - 50 : movieHolder.current.offsetWidth
			let computedHeight = (computedWidth * 9) / 16
			let requiredFullHeight = computedHeight + excessHeight

			if (requiredFullHeight > window.innerHeight) {
				computedHeight = window.innerHeight - excessHeight
				computedWidth = (computedHeight * 16) / 9
			}

			console.log(computedWidth, movieHolder.current.offsetWidth, computedHeight)

			// if (isMobileLandscape) {
			// 	alert('Please use portrait mode for best experience');
			// }
			setWidth(computedWidth)
			setHeight(computedHeight)
		}

		resizeHandler()

		const handleResize = _.debounce(() => {
			resizeHandler()
		}, 500)

		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [movieHolder, isMobileLandscape])

	const videoEnded = () => {
		/**
		 * When one video ends, render next video
		 */

		if (movieMode) {
			setCurrentVideo(0)
			setStarted(false)
			setPlaying(false)
			if (moviePlayer.current) {
				moviePlayer.current.seekTo(0)
			}
		} else {
			// setStarted(false)
			setPlaying(false)
			if (moviePlayer.current) {
				moviePlayer.current.seekTo(0)
			}
			if (currentPage + 1 < content.movies.length) {
				setWaitingInput(true)
			}
		}
	}

	return (
		<div className="activity__container charlie-container">
			<Header storyName={content.name} />
			<main className="main__content justify-content-center">
				<div id="movieBox" className="movie__frame charlie-box row align-items-center charlie-chamero d-flex flex-column">
					<div
						className={`movie_holder w-100 ${content.key}`}
						ref={movieHolder}
						css={{
							maxWidth: "1200px",
							justifyContent: "center",
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							margin: "auto",
						}}
					>
						<div className="movie_wrap w-100">
							<div
								className="movieContent w-100"
								key={content.key}
								onContextMenu={(e) => {
									e.preventDefault()
								}}
								css={{ position: "relative", display: "flex", justifyContent: "center" }}
							>
								{!started && <StartBtn />}
								{started && (
									<ReactPlayer
										className="chamero-player"
										css={{
											background: "#000",
										}}
										url={movieMode ? content.movie : content.movies[currentVideo]}
										ref={moviePlayer}
										width={`${width}px`}
										height={`${height}px`}
										playing={playing}
										loop={false}
										muted={sound}
										volume={volume}
										controls={false}
										pip={true}
										style={{
											minHeight: `${height}px`,
										}}
										onProgress= {handleProgress}
										// progressInterval={5}
										onStart={() => {
											setStarted(true)
										}}
										onEnded={() => {
											videoEnded()
										}}
										// onTimeUpdate={onPlaying}
										
									/>
								)}

								{started && isMobileLandscape && (
									<ActivityControlsMobileLandscape currentPage={currentPage} totalPages={content.movies.length} />
								)}
							</div>
						</div>
						{started && (
							<React.Fragment>
								{!isMobileLandscape && <ActivityControls currentPage={currentPage} totalPages={content.movies.length} />}
							</React.Fragment>
						)}
					</div>
				</div>
			</main>
			{!started && !isMobileLandscape && <Footer donner="true" />}
		</div>
	)
}

export default Container
