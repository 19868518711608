import React from "react"

const WordNumber = (props) => {
	let totalWord = props.totalWords
	let curWord = props.currentWord + 1
	let progressPercent = (curWord / totalWord) * 100
	return (
		<div className="page__progress">
			<div className="progress__indicator" style={{ width: `${100 - progressPercent}%` }} />
			<span>
				Progress: Word {curWord} of {totalWord}
			</span>
		</div>
	)
}

export default WordNumber
