import easyWords from "./easy"
import mediumWords from "./medium"
import hardWords from "./hard"
import shuffle from "shuffle-array"
import letterToSound from "./letterbank/lettersToSound"
import letterToSound3 from "./letterbank/lettersToSound3"

const audio_words = require.context("../audios/words", true)
const images = require.context("../images", true)

let finalObject = [easyWords, mediumWords, hardWords].map((words, level) => {
	return words
		.map((level_words) => {
			let shuffledWords = shuffle(level_words)
			return shuffledWords.map((word) => {
				let word_np = word.letters.join("")
				let word_boxes = []
				let firstCoord
				let hintAndCell = {}

				const letters = word.letters.map((letter, idx) => {
					let box = [word.row + (word.isAcross ? 0 : idx), word.column + (word.isAcross ? idx : 0)]
					let coordKey = word.row + (word.isAcross ? 0 : idx) + "-" + (word.column + (word.isAcross ? idx : 0))
					word_boxes.push(box)
					hintAndCell = {
						...hintAndCell,
						[coordKey]: [word.key],
					}

					if (idx === 0) {
						firstCoord = coordKey
					}

					let audio
					if (level === 2) {
						audio = letterToSound3[letter]
					} else {
						audio = letterToSound[letter]
					}

					return {
						id: idx + 1,
						name: letter,
						box: box,
						coordKey: coordKey,
						audio: audio,
						isPlaced: false,
					}
				})

				let wordImage, arrowImage, audio
				let endWordImage = null
				try {
					wordImage = images(`./${word.key}.png`)
				} catch (error) {
					wordImage = null
					console.log(`${word.key}.png not found`)
				}
				if (level === 2) {
					try {
						endWordImage = images(`./level3CompletedImg/${word.key}_end.png`)
					} catch (error) {
						endWordImage = null
						console.log(`${word.key}_end.png not found`)
					}
				}

				try {
					arrowImage = images(`./${word.arrow}.png`)
				} catch (error) {
					arrowImage = null
					console.log(`${word.arrow}.png not found`)
				}

				try {
					audio = audio_words(`./${word.key}.ogg`)
				} catch (error) {
					audio = null
					console.log(`${word.key}.ogg not found`)
				}
				return {
					word: word_np,
					cName: word.key,
					hintAndCell: hintAndCell,
					wordImage: wordImage,
					endWordImage: endWordImage,
					arrowImage: arrowImage,
					arrowClass: word.arrow,
					audio: audio,
					isAcross: word.isAcross,
					row: word.row,
					column: word.column,
					firstCoord: firstCoord,
					letters: letters,
					boxes: word_boxes,
				}
			})
		})
		.reduce((prev = [], current) => {
			return prev.concat(current)
		})
})

export default finalObject
