const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./dost_chatting.gif"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./paathi.png"),
			class: "image-one pathi",
			imgdiv: "imageCover",
		},
		{
			src: images("./pathi_l.png"),
			class: "image-one pathi1",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "दुई जना साथी थिए",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 2 }, { start: 2.9 }],
				},
				{
					textData: "पुगीसके साठी",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 3.9 }, { start: 5.5 }],
				},
				{
					textData: "एक जना पाथी भन्दा",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 7.7 }, { start: 8.4 }, { start: 9.1 }, { start: 9.9 }],
				},
				{
					textData: "अर्को सुन्थ्यो पाठी",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 11.2 }, { start: 11.8 }, { start: 12.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./1.ogg"),
			delay: 1000,
		},
	],
}

export default content
