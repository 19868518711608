import shuffle from "shuffle-array"

const audios = require.context("../../audios/words/level2", true)
const images = require.context("../../images/level2", true)

const levelData = {
	level: "medium",
	words: [
		{
			key: "sakkar",
			npword: "सखर",
		},
		{
			key: "bhedigoth",
			npword: "भेडीगोठ",
		},
		{
			key: "chulaci",
			npword: "चुलेसी",
		},
		{
			key: "dumsi",
			npword: "दुम्सी",
		},
		{
			key: "pachis",
			npword: "पच्चिस",
		},
		{
			key: "bakulla",
			npword: "बकुल्ला",
		},
		{
			key: "karauti",
			npword: "करौँती",
		},
		{
			key: "naksha",
			npword: "नक्सा",
		},
		{
			key: "pidhi",
			npword: "पिँढी",
		},
		{
			key: "gunji",
			npword: "गन्जी",
		},
		{
			key: "ghumba",
			npword: "गुम्बा",
		},
		{
			key: "ghuchha",
			npword: "गुच्चा",
		},
		{
			key: "posak",
			npword: "वेशभूषा",
		},
		{
			key: "sayal",
			npword: "स्याल",
		},
		{
			key: "chayau",
			npword: "च्याउ",
		},
		{
			key: "whale",
			npword: "व्हेल",
		},
		{
			key: "mobile",
			npword: "मोबाइल",
		},
		{
			key: "lapsi",
			npword: "लप्सी",
		},
		{
			key: "nibuwa",
			npword: "निबुवा",
		},
		{
			key: "surya",
			npword: "सूर्य",
		},
	],

	options: [
		{
			key: "cricket",
			npword: "क्रिकेट",
		},
		{
			key: "triwedi",
			npword: "त्रिवेणी",
		},
		{
			key: "jhakri",
			npword: "झाँक्री",
		},
		{
			key: "barfi",
			npword: "बर्फी",
		},
		{
			key: "barasinge",
			npword: "बाह्रसिङे",
		},
		{
			key: "farping",
			npword: "फर्पिङ",
		},
		{
			key: "jorkhu",
			npword: "जोर्खु",
		},
		{
			key: "kaada",
			npword: "काँडा",
		},
		{
			key: "pidalu",
			npword: "पिँडालु",
		},
		// {
		//     key: "chidiyakhana",
		//     npword: "चिडीयाखाना",
		// },
		{
			key: "railgadi",
			npword: "रेलगाडी",
		},
		{
			key: "tarbuja",
			npword: "तर्बुजा",
		},
		{
			key: "sukul",
			npword: "सुकुल",
		},
		{
			key: "parkhal",
			npword: "पर्खाल",
		},
		{
			key: "kachha",
			npword: "कच्चा",
		},
		{
			key: "bharkhar",
			npword: "भर्खर",
		},
		{
			key: "suryadaya",
			npword: "सूर्योदय",
		},
		{
			key: "pawetra",
			npword: "पवित्र",
		},
		{
			key: "chadi",
			npword: "चाँदी",
		},
		{
			key: "khachhar",
			npword: "खच्चर",
		},
		{
			key: "pankha",
			npword: "पङ्खा",
		},
		{
			key: "julfi",
			npword: "जुल्फी",
		},
		{
			key: "lokharke",
			npword: "लोखर्के",
		},
		{
			key: "chamchaa",
			npword: "चम्चा",
		},
		{
			key: "galli",
			npword: "गल्ली",
		},
		{
			key: "patrika",
			npword: "पत्रिका",
		},
		{
			key: "suntala",
			npword: "सुन्तला",
		},
		{
			key: "priti",
			npword: "प्रिती",
		},
		{
			key: "jaati",
			npword: "जाँतो",
		},
		{
			key: "mugga",
			npword: "मृग",
		},
		{
			key: "rida",
			npword: "ऋण",
		},
	],
}

let levelWords = levelData.words.map((word) => {
	word = {
		...word,
		image: images(`./${word.key}.png`),
		audio: audios(`./${word.key}.ogg`),
	}
	return word
})

levelWords = shuffle(levelWords)

levelData.words = levelWords

export default levelData
