const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./cover_page.gif"),
			class: "image-one",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text title text-center title",
			textIndivData: [
				{
					textData: "बाँदरको घर",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.7 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s0.ogg"),
			delay: 500,
		},
	],
}

export default content
