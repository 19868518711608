const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./pointing_horse.png"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./boy_on_horse.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "गैँडा मैले घोडासँग साटेँ",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.5 }, { start: 2.2 }],
				},
				{
					textData: "घोडामा मैले दाजुलाई चढाएँ",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 3 }, { start: 3.7 }, { start: 4.4 }, { start: 5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./5.ogg"),
			delay: 500,
		},
	],
}

export default content
