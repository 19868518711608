import React from "react"
import { Link } from "react-router-dom"
import IconHome from "assets/icons/home.svg"
import IconMenulist from "assets/icons/back_to_list.svg"
import Logo from "assets/ole_logo.png"

const Header = (props) => {
	return (
		<header className="inner__header">
			<div className="container">
				<div className="header-content">
					<div className="logo">
						{!props.isOpenInFlutter && (<Link to="/">
							<img src={Logo} className="img-fluid" alt="OLE Nepal" />
						</Link>)}
					</div>
					{!props.isOpenInFlutter && (<div className="menuIcon">
						<Link to={props.isGame ? "/play" : "/story"}>
							<img src={IconMenulist} className="img-fluids" alt="Home" />
						</Link>
					</div>)}
					<strong className="page__heading">{props.storyName}</strong>
					<div className="menu-right">
						{!props.isOpenInFlutter && (<Link to="/" className="menu-item">
							<img src={IconHome} className="img-fluids" alt="Home" />
						</Link>)}
					</div>
				</div>
			</div>
		</header>
	)
}
export default Header


/*

	<div className="logo">
		<Link to="/">
			<img src={Logo} className="img-fluid" alt="OLE Nepal" />
		</Link>
	</div>
	<div className="menuIcon">
		<Link to={props.isGame ? "/play" : "/story"}>
			<img src={IconMenulist} className="img-fluids" alt="Home" />
		</Link>
	</div>
	<strong className="page__heading">{props.storyName}</strong>
	<div className="menu-right">
		<Link to="/" className="menu-item">
			<img src={IconHome} className="img-fluids" alt="Home" />
		</Link>
	</div>

*/