import React, {useState} from "react"
import Header from "Components/Layout/Header"
import CrosswordPuzzle from "../../../Containers/Play/CrosswordPuzzle/CrosswordPuzzle"

const Bp = () => {

//for flutter communication 
	const [isCallFromFlutter, setisCallFromFlutter] = useState(false);
	
	var callFromFlutterFunc = () => {
		console.log("puzzle invoked from flutter");
		setisCallFromFlutter(true);
	};
	window.puzzleFunc = callFromFlutterFunc;
	// console.log(window);
//for flutter communication 

	return (
		<div className="activity__container">
			<Header storyName="पज्जल" isGame={true} isOpenInFlutter={isCallFromFlutter}/>
			<CrosswordPuzzle isOpenInFlutter={isCallFromFlutter}/>
		</div>
	)
}

export default Bp
