import React from "react"

const AudioiconImage = (props) => {
	return (
		<div key={props.currentPage + "audioDiv" + props.index} className={props.imgdivClass} onClick={props.onClickIcon}>
			<img
				key={props.currentPage + "audioImage" + props.index}
				src={`${props.src}?reload=${props.activityKey}`}
				className={`img-fluid ${props.class}`}
				alt=""
			/>
		</div>
	)
}
export default AudioiconImage
