import React, { useContext, useEffect, useState } from "react"
import Modal from "react-modal"
import { Howl } from "howler"
import "./modal.styles.scss"
import { StateContext } from "../../ShapeColorGame"
import { sleep } from "../../utils"
import EndModal from "./EndModal"

const customStyles = {
	content: {
		position: "absolute",
		inset: "50% auto auto 50%",
		overflow: "unset",
		outline: "none",
		padding: "16px 0",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
		background: "#f9f9f9",
		boxShadow: "6px 5px 0px 2px #c9c9c9",
		borderRadius: "15px",
		border: "unset",
	},
}

const ModalComponent = ({ isOpen, closeModal, assets, stageData, currentPage }) => {
	Modal.setAppElement("#root")
	const state = useContext(StateContext)
	const [modalState, setModalState] = useState({
		nepaliName: false,
		englishName: false,
		audioPlaying: false,
		allowClose: false,
	})
	const englishName = () => {
		setModalState({ ...modalState, englishName: true, audioPlaying: true })
		const speak = new Howl({
			src: [stageData.animalNameEnglish],
			autoplay: false,
			loop: false,
			onend: () => setModalState({ ...modalState, englishName: false, audioPlaying: false, allowClose: true }),
		})
		if (!modalState.audioPlaying) speak.play()
	}

	const nepaliName = () => {
		setModalState({ ...modalState, nepaliName: true, audioPlaying: true })
		const speak = new Howl({
			src: [stageData.animalNameNepali],
			autoplay: false,
			loop: false,
			onend: async () => {
				setModalState({ ...modalState, nepaliName: false, audioPlaying: false })
			},
		})
		if (!modalState.audioPlaying) speak.play()
	}
	useEffect(() => {
		const animalSounds = async () => {
			await sleep(1000)
			nepaliName()
			await sleep(2000)
			englishName()
		}
		animalSounds()
	}, [])
	return (
		<>
			{currentPage === 9 ? (
				<EndModal isOpen={isOpen} />
			) : (
				<Modal isOpen={isOpen} style={customStyles} contentLabel="Animal Modal">
					{modalState.allowClose && (
						<div className="close-button">
							<div
								style={{ backgroundImage: `url(${assets(`./${state.wrong}`)})` }}
								className="button"
								onClick={closeModal}
							></div>
						</div>
					)}
					<div className="animal-modal-container">
						<img
							src={stageData.modalAnimal}
							alt="Animal"
							style={{
								height: stageData.id === 6 ? "80%" : "100%",
							}}
						/>
					</div>
					<div className="animal-name">
						<div className={`nepali-name ${modalState.nepaliName && "animal-name-pronounce"}`} onClick={nepaliName}>
							<div>{stageData.nepaliName}</div>
							<img src={assets(`./${state.mic}`)} className="mic" alt="MIC" />
						</div>
						<div className={`english-name ${modalState.englishName && "animal-name-pronounce"}`} onClick={englishName}>
							<div>{stageData.englishName}</div>
							<img src={assets(`./${state.mic}`)} className="mic" alt="MIC" />
						</div>
					</div>
				</Modal>
			)}
		</>
	)
}

export default ModalComponent
