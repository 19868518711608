import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "लड्‍डु र पेडा",
	key: "laddu-ra-peda",
	coverImage: images("./coverpage.jpg"),
	credits: {
		writer: "सविता गौतम दाहाल",
		illustrator: "अर्हन्त श्रेष्ठ",
		template: "default",
		bg: images("./coverpage.jpg"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
