const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./background_01.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./page15_mum_kids.png"),
			class: "image-five mum-kids15",
			imgdiv: "imageCover",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain9",
			imgdiv: "imageCover",
		},
		{
			src: images("./thunder_storm.gif"),
			class: "image-one thunderp15",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox15 text_box-p15",
			textIndivData: [
				{
					textData: "र सबैलाई भुत्ला ओढाई।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.4 }, { start: 1.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./15.ogg"),
			delay: 500,
		},
		{
			url: audios("./bg1.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
