export const snakeToCamel = (str) => {
	return str.replace(/([-_][a-z])/gi, ($1) => {
		return $1.toUpperCase().replace("-", "").replace("_", "")
	})
}

export const camelToSnakeCase = (field) => {
	return field
		.split(/(?=[A-Z])/)
		.map((x) => x.toLowerCase())
		.join("_")
}

export const capitalize = (s) => {
	if (typeof s !== "string") return ""
	return s.charAt(0).toUpperCase() + s.slice(1)
}

export const snakeToFullCamel = (str) => {
	return capitalize(
		str.replace(/([-_][a-z])/gi, ($1) => {
			return $1.toUpperCase().replace("-", "").replace("_", "")
		})
	)
}
