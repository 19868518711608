const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./13to24/page03bg.png"),
			imgdiv: "imageCover",
		},
		{
			src: images("./13to24/tortoise07.png"),
			imgdiv: "imageCover",
			styles: { width: "20%", top: "55%", left: "15%" },
		},
		{
			src: images("./13to24/bubble.svg"),
			imgdiv: "imageCover",
			toSvg: true,
			styles: { width: "60%", top: "3%", left: "30%" },
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "तर यसो गर्दा पनि एउटा समस्या हुने भयो। पोखरी टाढा भएकोले त्यहाँ पुग्दा लामो बाटो उड्‍नुपर्ने भयो।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.08 },
						{ start: 1.4 },
						{ start: 1.9 },
						{ start: 2.5 },
						{ start: 3 },
						{ start: 3.3 },
						{ start: 3.7 },
						{ start: 4.8 },
						{ start: 5.3 },
						{ start: 6.1 },
						{ start: 6.8 },
						{ start: 7.15 },
						{ start: 7.7 },
						{ start: 8.1 },
						{ start: 8.5 },
						{ start: 8.9 },
						{ start: 9.6 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p3_s1.ogg"),
			delay: 500,
		},
	],
}

export default content
