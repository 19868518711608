const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./background2.png"),
			class: "image-one imageCover",
			imgdiv: "imageCoverDiv",
		},
		{
			src: images("./rose.png"),
			class: "image-one imageCenter element1 imgSize imgRose",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./chocolate.png"),
			class: "image-one imageCenter element2 imgSize imgchocolate _mt-12",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./caps.png"),
			class: "image-one imageCenter element3 imgSize imgcaps",
			imgdiv: "imageCenterDiv",
		},

		{
			src: images("./apple_un.png"),
			class: "image-one imageCenter element4 imgSize imgapple_un",
			imgdiv: "imageCenterDiv",
		},
		{
			src: images("./apple.gif"),
			class: "image-one imageCenter element5 imgSize imgapple",
			imgdiv: "imageCenterDiv",
		},
	],
	texts: [
		{
			textDivClass: "story-text pageText element1 page2Text1 text-center",
			textIndivData: [
				{
					textData: "म गुलाफको फूल जस्तै रातो छु।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1 }, { start: 1.4 }, { start: 2 }, { start: 2.4 }, { start: 2.7, last: true }],
				},
			],
		},
		{
			textDivClass: "story-text pageText element2 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मिठो चकलेट जस्तै छ मेरो स्वाद।",
					textClass: "m-0",
					timestamps: [
						{ start: 9.1 },
						{ start: 9.5 },
						{ start: 9.9 },
						{ start: 10.6 },
						{ start: 11 },
						{ start: 11.2, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element3 page2Text1 text-center",
			textIndivData: [
				{
					textData: "मेरो जिउ भेडे खुर्सानी जस्तै छ।",
					textClass: "m-0",
					timestamps: [
						{ start: 19.9 },
						{ start: 20.3 },
						{ start: 20.8 },
						{ start: 21.2 },
						{ start: 21.9 },
						{ start: 22.2, last: true },
					],
				},
			],
		},

		{
			textDivClass: "story-text pageText element4 page2Text1 text-center",
			textIndivData: [
				{
					textData: "ल भन त, म को हुँ ?",
					textClass: "m-0",
					timestamps: [
						{ start: 31 },
						{ start: 31.2 },
						{ start: 31.5 },
						{ start: 32.1 },
						{ start: 32.4 },
						{ start: 32.5 },
						{ start: 32.5, last: true },
					],
				},
			],
		},
		{
			textDivClass: "story-text pageText element5 page2Text1 text-center",
			textIndivData: [
				{
					textData: " म स्याऊ हुँ।",
					textClass: "m-0",
					timestamps: [{ start: 42 }, { start: 42.5 }, { start: 43, last: true }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p2a1.wav"),
			delay: 4000,
		},
		{
			url: audios("./p2a2.wav"),
			delay: 13000,
		},
		{
			url: audios("./p2a3.wav"),
			delay: 24000,
		},
		{
			url: audios("./p2a4.wav"),
			delay: 35000,
		},
		{
			url: audios("./p2a5.wav"),
			delay: 46000,
		},
	],
}

export default content
