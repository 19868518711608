import React, { useState, useReducer, useEffect } from "react"
import _ from "underscore"

import Stage1 from "./pages/Stage/Stage1"
import Stage2 from "./pages/Stage/Stage2"

// styles
import "./game.styles.scss"

// helpers
import { reducer, getLevelData } from "./helpers/helpers"
import { getQueryVariable } from "../mainHelper"
import shuffle from "shuffle-array"

import loaderSvg from "../../../assets/ball-triangle.svg"

import inOut_music from "./content/audios/sound_effects/monkeyManhappy.ogg"
import bg_river from "./content/audios/sound_effects/riverBg.ogg"
import JungleBeats from "./content/audios/sound_effects/jungleBeats.ogg"

import { Howl } from "howler"

import bg_1 from "./pages/Stage/assets/bg_01.png"
import bg_buttom from "./pages/Stage/assets/bg_buttom.png"

import p1_bg from "./pages/Stage/assets/bg_new-min.png"
import p1_waterPatch from "./pages/Stage/assets/water_blue.png"
import p1_stones from "./pages/Stage/assets/stones.png"

//Preload stones
import stone1 from "./components/Options/assets/stone1.png"
import stone1_0g from "./components/Options/assets/stone1_0.gif"
import stone1_0 from "./components/Options/assets/stone1_0.png"
import stone1_1 from "./components/Options/assets/stone1_1.png"
import stone1_1g from "./components/Options/assets/stone1_1.gif"
import stone2 from "./components/Options/assets/stone2.png"
import stone2_0g from "./components/Options/assets/stone2_0.gif"
import stone2_0 from "./components/Options/assets/stone2_0.png"
import stone2_1 from "./components/Options/assets/stone2_1.png"
import stone2_1g from "./components/Options/assets/stone2_1.gif"
import stone3 from "./components/Options/assets/stone3.png"
import stone3_0g from "./components/Options/assets/stone3_0.gif"
import stone3_0 from "./components/Options/assets/stone3_0.png"
import stone3_1 from "./components/Options/assets/stone3_1.png"
import stone3_1g from "./components/Options/assets/stone3_1.gif"
import stone4 from "./components/Options/assets/stone4.png"
import stone4_0g from "./components/Options/assets/stone4_0.gif"
import stone4_0 from "./components/Options/assets/stone4_0.png"
import stone4_1 from "./components/Options/assets/stone4_1.png"
import stone4_1g from "./components/Options/assets/stone4_1.gif"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

export const DispatchContext = React.createContext(null)
export const StateContext = React.createContext(null)

const MatchTheWord = () => {
	const settings = { audioHints: true }

	const useWindowSize = () => {
		const isClient = typeof window === "object"

		const getSize = () => {
			let width = window.innerWidth <= 1600 ? window.innerWidth : 1600
			let height = window.innerHeight
			let availableHeight = window.innerHeight - 56

			// console.log('wh: ', width, height);
			let adjustedHeight = availableHeight > (width * 9) / 16 ? (width * 9) / 16 : height - 56
			let adjustedwidth = (adjustedHeight * 16) / 9

			// console.log('awh: ', adjustedwidth, adjustedHeight);

			return {
				width: isClient ? Math.ceil(adjustedwidth) : undefined,
				height: isClient ? Math.ceil(adjustedHeight) : undefined,
			}
		}

		const windowSize = React.useRef(getSize())
		// const [windowSize, setWindowSize] = React.useState(getSize);

		// resize hook
		React.useEffect(() => {
			const isClient = typeof window === "object"
			if (!isClient) {
				return false
			}

			const handleResize = _.debounce(() => {
				windowSize.current = getSize()
				dispatch({
					type: "RESET_STAGE",
					values: {
						windowSize: windowSize.current,
					},
				})
			}, 500)

			window.addEventListener("resize", handleResize)
			return () => window.removeEventListener("resize", handleResize)
			// eslint-disable-next-line
		}, [])
		// Empty array ensures that effect is only run on mount and unmount

		return windowSize.current
	}

	// const levelData = getLevelData("Easy");X
	const [state, dispatch] = useReducer(reducer, {
		settings: settings,
		currentPage: "GAME_START",
		level: getQueryVariable("level") || "Easy",
		stage: 0,
		levelData: [],
		loadingAssets: true,
		intro: true,
		playing: false,
		hoverHintPlaying: false,
		wordComplete: false,
		rewardComplete: false,
		levelComplete: false,
		backUpLevelData: [],
		bg: "bg_02.png",
		windowSize: useWindowSize(),
		bgMusic: "happyTune",
	})

	// console.log("state windowSize: ", state.windowSize);
	let windowSize = useWindowSize()
	const [isInOutAudioPlaying, setIsInOutAudioPlaying] = useState(false)
	const [isRiverAudioPlaying, setIsRiverAudioPlaying] = useState(false)

	const riverMusic = new Howl({
		src: [bg_river],
		preload: true,
		volume: 0.5,
		loop: true,
	})
	const BgMusic = new Howl({
		src: [inOut_music],
		preload: true,
		volume: 0.5,
		loop: true,
	})
	const lvl2BgMusic = new Howl({
		src: [JungleBeats],
		preload: true,
		volume: 0.1,
		loop: true,
	})

	React.useEffect(() => {
		if (state.level === "easy") {
			if (state.stage === 0 && isInOutAudioPlaying === false) {
				BgMusic.play()
				BgMusic.fade(0.0, 0.5, 5000)
				setIsInOutAudioPlaying(true)
			}
			if (state.stage >= 1 && isRiverAudioPlaying === false) {
				BgMusic.pause()
				setIsInOutAudioPlaying(false)
				riverMusic.play()
				setIsRiverAudioPlaying(true)
			}
			return () => {
				BgMusic.unload()
				riverMusic.unload()
			}
		} else {
			lvl2BgMusic.play()
			return () => {
				lvl2BgMusic.unload()
			}
		}
	}, [isInOutAudioPlaying, isRiverAudioPlaying, riverMusic, BgMusic, lvl2BgMusic, state.stage, state.level])

	useEffect(() => {
		const levelData = shuffle(getLevelData(state.level))

		levelData.unshift(levelData[0])

		dispatch({ type: "SET", values: { levelData: levelData } })
	}, [state.level])

	useEffect(() => {
		if (state.level === "easy") {
			const BgMusic = new Howl({
				src: [inOut_music],
				autoplay: false,
				preload: true,
				volume: 0.2,
				loop: true,
			})
			const BgRiver = new Howl({
				src: [bg_river],
				autoplay: false,
				preload: true,
				volume: 0.3,
				loop: true,
			})

			if (state.bgMusic === "happyTune") {
				BgRiver.play()
				BgRiver.fade(0.3, 0, 1000)
				BgMusic.play()
				BgMusic.fade(0, 0.2, 1000)
			} else {
				BgMusic.play()
				BgMusic.fade(0.2, 0, 3000)
				BgRiver.play()
			}
			return () => {
				BgMusic.unload()
				BgRiver.unload()
			}
		}
	}, [state.bgMusic, state.level])

	useEffect(() => {
		if (!localStorage.getItem("MatchTheWord")) {
			localStorage.setItem("MatchTheWord", JSON.stringify(state.settings))
		}

		const preloadImages = (urls, allImagesLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadImage = (url, anImageLoadedCallback) => {
				let img = new Image()
				img.onload = anImageLoadedCallback
				img.src = url
			}

			urls.forEach(function (url) {
				preloadImage(url, function () {
					loadedCounter++
					// console.log('Number of loaded images: ' + loadedCounter);
					if (loadedCounter === toBeLoadedNumber) {
						allImagesLoadedCallback()
					}
				})
			})
		}

		let images = [
			bg_1,
			bg_buttom,
			p1_bg,
			p1_waterPatch,
			p1_stones,
			stone1,
			stone1_0g,
			stone1_0,
			stone1_1,
			stone1_1g,
			stone2,
			stone2_0g,
			stone2_0,
			stone2_1,
			stone2_1g,
			stone3,
			stone3_0g,
			stone3_0,
			stone3_1,
			stone3_1g,
			stone4,
			stone4_0g,
			stone4_0,
			stone4_1,
			stone4_1g,
		]
		if (state.levelData[state.stage] && state.levelData[state.stage + 1]) {
			images = images.concat([
				state.levelData[state.stage].incentiveImage[0].src,
				state.levelData[state.stage + 1].incentiveImage[0].src,
			])
		}

		preloadImages(images, () => {
			// console.log("preloaded");
			dispatch({ type: "LOADING_COMPLETE" })
		})
	}, [state])

	let Stage
	if (state.level === "easy") {
		Stage = <Stage1 key={`stage-${state.level}-${state.stage}`} />
	} else if (state.level === "medium") {
		Stage = <Stage2 key={`stage-${state.level}-${state.stage}`} />
	} else {
		Stage = <Stage key={`stage-${state.level}-${state.stage}`} />
	}
	return (
		<div
			className="game-container"
			id="game-container"
			key={windowSize.width}
			style={{
				width: `${windowSize.width}px`,
				height: `${windowSize.height}px`,
				overflow: "hidden",
			}}
		>
			{state.loadingAssets ? (
				<div className="game-loading">
					<div className="loader-inner">
						<img src={loaderSvg} className="loader" alt="loader" />
						<span>Loading...</span>
					</div>
				</div>
			) : (
				<DispatchContext.Provider value={dispatch}>
					<StateContext.Provider value={state}>{state.levelData.length !== 0 && Stage}</StateContext.Provider>
				</DispatchContext.Provider>
			)}
		</div>
	)
}

MatchTheWord.whyDidYouRender = true
export const MatchTheWordMemo = React.memo(MatchTheWord)
MatchTheWordMemo.whyDidYouRender = true

export default MatchTheWordMemo
// export default MatchTheWord;
