const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky-yellow",
	images: [
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloud-1",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloud-2",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloud-3",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloud-4",
		},
		{
			src: images("./13to24/flying.png"),
			imgdiv: "imageCover",
			styles: { width: "75%", top: "20%", left: "50%", transform: "translateX(-50%)" },
		},
		// {
		//     src: images('./13to24/flying.svg'),
		//     imgdiv: 'imageCover',
		// },
		// {
		//     src: images('./13to24/duckwithstick.svg'),
		//     imgdiv: 'imageCover',
		// },
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "पोखरी देखेर ऊ खुसी भयो। “पोखरी! पोखरी!” भन्दै ऊ करायो।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.3 },
						{ start: 1.5 },
						{ start: 2 },
						{ start: 2.6 },
						{ start: 3.4 },
						{ start: 4.3 },
						{ start: 4.8 },
						{ start: 5.1 },
						{ start: 6 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p4_s5.ogg"),
			delay: 1500,
		},
	],
}

export default content
