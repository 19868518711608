const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./pigeon_fly.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center browntext",
			textIndivData: [
				{
					textData: "परेवाले खाएर",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 2.3 }],
				},
				{
					textData: "घुम्‍न गयो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 3 }, { start: 4 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./40.ogg"),
			delay: 500,
		},
		{
			url: audios("./a40.ogg"),
			delay: 150,
			isBg: true,
		},
	],
}

export default content
