import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "आमाको माया",
	key: "aama-ko-maya",
	coverImage: images("./cover_pagenew.jpg"),
	credits: {
		writer: "रमेश चन्द्र अधिकारी",
		illustrator: "सिमल",
		template: "default",
		bg: images("./cover_pagenew.jpg"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
