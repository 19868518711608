/** @jsx jsx */
import { jsx } from "@emotion/core"

import { useRef, useEffect, useState } from "react"
import _ from "underscore"
import ImageList from "../../../Components/ImageList"
import ActivityControls from "./ActivityControls"
import shuffle from "shuffle-array"

// styled elements
import activityWrap from "../../../styledElements/activityWrap"

// animations
import mainTextAnimation from "../Common/TextAnimations"
import PlayConfig from "../StoryScripts/bhana-ma-ko-hun/PlayConfig"

import { Howler, Howl } from "howler"
import gsap from "gsap"

const ActivityContent = ({ disabled, pageContent, story, currentPage, audioMute }) => {
	const textContainerEl = useRef(null)
	const imgContainerEl = useRef(null)
	const [disabledNextBtn, setDisabledNextBtn] = useState(!audioMute)

	const activityWrapperRef = useRef(null)
	const [height, setHeight] = useState(0)
	const [width, setWidth] = useState(0)
	const assets = require.context("../StoryScripts/bhana-ma-ko-hun/assets/background")

	// useEffect(() => {
	// 	let computedWidth = activityWrapperRef.current ? activityWrapperRef.current.offsetWidth : 0
	// 	setHeight((computedWidth * 9) / 16)

	// 	const handleResize = _.debounce(() => {
	// 		computedWidth = activityWrapperRef.current ? activityWrapperRef.current.offsetWidth : 0
	// 		setHeight((computedWidth * 9) / 16)
	// 	}, 500)

	// 	window.addEventListener("resize", handleResize)
	// 	return () => window.removeEventListener("resize", handleResize)
	// }, [activityWrapperRef])

	useEffect(() => {
		const resizeHandler = () => {
			if (activityWrapperRef.current) {
				const excessHeight = 56 + 56 + 20
				let computedWidth = activityWrapperRef.current.parentElement.offsetWidth
				let computedHeight = (computedWidth * 9) / 16
				let requiredFullHeight = computedHeight + excessHeight

				if (requiredFullHeight > window.innerHeight) {
					computedHeight = window.innerHeight - excessHeight
					computedWidth = (computedHeight * 16) / 9
				}

				console.log(computedWidth, activityWrapperRef.current.offsetWidth, computedHeight)
				setWidth(computedWidth)
				setHeight(computedHeight)
			}
		}

		resizeHandler()

		const handleResize = _.debounce(() => {
			resizeHandler()
		}, 500)

		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [activityWrapperRef])

	useEffect(() => {
		if (!audioMute) {
			setDisabledNextBtn(true)
		}

		if (!disabled) {
			let timeouts = []
			let timestamps = []
			let textIndivData = []
			if (pageContent.textList?.length > 0) {
				pageContent.textList.forEach((item) => {
					textIndivData = [...textIndivData, ...item.textIndivData]
				})

				textIndivData.forEach((data) => {
					if (data.timestamps) {
						timestamps = [...timestamps, ...data.timestamps]
					} else {
						timestamps = null
					}
				})
			} else {
				timestamps = null
			}

			// console.log(timestamps);
			let textAnimations
			// console.log(story);
			import(`../StoryScripts/${story.key}/Animations`)
				.then((customAnims) => {
					let animationObj = new customAnims.default()
					textAnimations = animationObj.runTextAnimations
					if (animationObj.runPageAnimations) {
						animationObj.runPageAnimations(currentPage, imgContainerEl.current, textContainerEl.current)
					}
					// console.log('textAnimations custom', textAnimations);
				})
				.catch((error) => {
					// console.warn(error);
					if (story.defaultTextAnimation) {
						textAnimations = mainTextAnimation
					} else {
						textAnimations = () => {}
					}
					// console.log("textAnimations main", textAnimations)
				})

			if (Array.from(textContainerEl.current.firstElementChild.classList).includes("end-suspense")) {
				textContainerEl.current.classList.add("backdrop")
			}

			if (pageContent.soundList && pageContent.soundList.length && !audioMute) {
				pageContent.soundList
					.filter((sound) => sound.isBg)
					.forEach((sound) => {
						let audio = new Howl({
							src: [sound.url],
							preload: true,
							autoplay: false,
							loop: sound.loop ?? true,
							volume: sound.volume ?? 0.7,
						})
						if (sound.delay) {
							timeouts.push(
								setTimeout(() => {
									audio.play()
								}, sound.delay)
							)
						} else {
							// audio.play()
						}
					})

				let vocals = pageContent.soundList.filter((sound) => !sound.isBg)
				if (vocals.length === 0) {
					setDisabledNextBtn(false)
				}
				vocals.forEach((sound, idx) => {
					let audio = new Howl({
						src: [sound.url],
						preload: true,
						autoplay: false,
						volume: sound.volume ?? 1,
						onplay: () => {
							if (idx === 0) {
								if (textAnimations) {
									textAnimations(
										textContainerEl.current,
										currentPage,
										timestamps,
										story.key === "bhana-ma-ko-hun" ? "#3d85c6" : "#000"
									)
								}
							}
						},
						onend: () => {
							if (idx === vocals.length - 1) {
								setDisabledNextBtn(false)
							}
						},
					})
					if (sound.delay) {
						timeouts.push(
							setTimeout(() => {
								audio.play()
							}, sound.delay)
						)
					} else {
						audio.play()
					}
				})
			} else {
				setDisabledNextBtn(false)
			}

			return () => {
				Howler.unload()
				timeouts.forEach((timeout) => {
					clearTimeout(timeout)
				})
				gsap.killTweensOf("*")
			}
		}
	}, [pageContent, currentPage, disabled, story, audioMute])
	useEffect(() => {
		let bgMusic

		if (story.key === "bhana-ma-ko-hun") {
			bgMusic = new Howl({
				src: [assets("./" + PlayConfig.play[currentPage])],
				autoplay: false,
				preload: true,
				volume: 0.07,
				loop: true,
			})
			bgMusic.play()
		}

		return () => {
			if (bgMusic) bgMusic.unload()
		}
	}, [currentPage])
	return disabled ? null : (
		<div className={`activity__holder align-items-center d-flex ${story.containerClass}`}>
			<div
				css={activityWrap}
				ref={activityWrapperRef}
				style={{
					height: `${height}px`,
					width: `${width}px`,
				}}
			>
				<div className="activityContent" key={story.key}>
					<div className="text-contents" ref={textContainerEl}>
						{pageContent.textList?.length ? (
							pageContent.textList.map((textContent, index) => {
								const textStyles = textContent.styles ?? {}
								const className = `${textContent.textDivClass} textContentDiv`
								return (
									<div key={currentPage + "test" + index} className={className} css={{ "&.textContentDiv": textStyles }}>
										{textContent.textIndivData.map((text, index) => (
											<p key={"p" + index} className={text.textClass}>
												{text.textData}
											</p>
										))}
									</div>
								)
							})
						) : (
							<div key={currentPage + "test"}></div>
						)}
					</div>
					<div
						className={
							pageContent.imageContainerClass
								? `imageContainer page-${currentPage} ${pageContent.imageContainerClass}`
								: `imageContainer page-${currentPage}`
						}
						css={pageContent.imageContainerStyles ? pageContent.imageContainerStyles : null}
						ref={imgContainerEl}
					>
						{pageContent.imageList?.map((image, index) => (
							<ImageList key={index} image={image} currentPage={currentPage} index={index} activityKey={story.key} />
						))}
					</div>
				</div>
			</div>
			<ActivityControls
				currentPage={currentPage}
				totalPages={story.totalPages}
				storyKey={story.key}
				audioMute={audioMute}
				disabledNextBtn={disabledNextBtn}
			/>
		</div>
	)
}

export default ActivityContent
