const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./13to24/page03bg.png"),
			imgdiv: "imageCover",
		},
		{
			src: images("./13to24/tortoise06.png"),
			imgdiv: "imageCover",
			styles: { width: "20%", top: "55%", left: "15%" },
		},
		{
			src: images("./13to24/bubble.svg"),
			imgdiv: "imageCover",
			toSvg: true,
			styles: { width: "60%", top: "3%", left: "30%" },
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-50  ",
			styles: { left: "68%" },
			textIndivData: [
				{
					textData:
						"कछुवाले आफ्‍नो मुखमा एउटा सानो हाँगा च्याप्‍न र दुईतिरको टुप्पालाई दुई पानीहाँसहरूले च्यापेर उड्‍ने निधो गरे।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1 },
						{ start: 1.6 },
						{ start: 2 },
						{ start: 2.5 },
						{ start: 2.8 },
						{ start: 3.3 },
						{ start: 3.9 },
						{ start: 4.4 },
						{ start: 5.2 },
						{ start: 5.8 },
						{ start: 6.3 },
						{ start: 7.4 },
						{ start: 8.1 },
						{ start: 8.48 },
						{ start: 9.1 },
						{ start: 9.5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p3_s0.ogg"),
			delay: 500,
		},
	],
}

export default content
