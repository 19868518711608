const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bunu01.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-left-center text-center font-laila",
			textIndivData: [
				{
					textData: "“मेरो चस्मा खै?”",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 0 }, { start: 0.8 }, { start: 1.3 }],
				},
				{
					textData: "बुनु एक्लै",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 2.3 }, { start: 2.8 }],
				},
				{
					textData: "भुतभुताई।",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 3.4 }, { start: 5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 1000,
		},
	],
}

export default content
