const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page03_back.png"),
			class: "image-four",
			imgdiv: "imageCover",
		},
		{
			src: images("./raining.gif"),
			class: "image-four",
			imgdiv: "imageCover",
		},
		{
			src: images("./page03_front.png"),
			class: "image-four",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-bottom-intact  text-center",
			textIndivData: [
				{
					textData: "मामाघरमा धान रोप्‍ने दिन थियो। केटाकेटीहरू खेलिरहेका थिए।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [
						{ start: 0.8 },
						{ start: 2 },
						{ start: 2.35 },
						{ start: 2.8 },
						{ start: 3.1 },
						{ start: 4.3 },
						{ start: 5.2 },
						{ start: 6 },
					],
				},
				{
					textData: "एक्कासी ठूलो पानी पर्न थाल्यो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 7.3 }, { start: 8.3 }, { start: 8.9 }, { start: 9.2 }, { start: 9.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s4.ogg"),
			delay: 500,
		},
	],
}

export default content
