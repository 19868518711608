const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_page_04.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./pointing_egg.gif"),
			class: "image-one pointing_egg",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page6Text text-center",
			textIndivData: [
				{
					textData: "“हेर त कवि! यहाँ के रहेछ?” रविले भने।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 0.8 },
						{ start: 1.7 },
						{ start: 2.0 },
						{ start: 2.2 },
						{ start: 3.2 },
						{ start: 3.65 },
					],
				},
				{
					textData: "कवि दौडेर आए। ",
					textClass: "word6",
					timestamps: [{ start: 5.0 }, { start: 5.5 }, { start: 6.0 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6.ogg"),
			delay: 500,
		},
		{
			url: audios("./6a.ogg"),
			delay: 5500,
		},
	],
}

export default content
