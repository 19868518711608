export default [
	{
		key: "saag",
		word: "साग",
		colorKey: "green",
		options: [
			{ key: "kaag", word: "काग", colorKey: "black" },
			{ key: "baag", word: "बाघ", colorKey: "red" },
			{ key: "saag", word: "साग", colorKey: "green" },
		],
		correctLetters: [
			{ letter: "sa", top: 0, left: 0 },
			{ letter: "aakar", top: 0, left: 43 },
			{ letter: "ga", top: 0, left: 60 },
		],
	},
	{
		key: "aama",
		word: "आमा",
		colorKey: "red",
		options: [
			{ key: "jama", word: "जामा", colorKey: "red" },
			{ key: "mama", word: "मामा", colorKey: "green" },
			{ key: "aama", word: "आमा", colorKey: "red" },
		],
		correctLetters: [
			{ letter: "a", top: 0, left: 0 },
			{ letter: "aakar", top: 0, left: 35 },
			{ letter: "ma", top: 0, left: 50 },
			{ letter: "aakar", top: 0, left: 81 },
		],
	},
	{
		key: "kera",
		word: "केरा",
		colorKey: "yellow",
		options: [
			{ key: "parewa", word: "परेवा", colorKey: "blue" },
			{ key: "mewa", word: "मेवा", colorKey: "green" },
			{ key: "kera", word: "केरा", colorKey: "yellow" },
		],
		correctLetters: [
			{ letter: "ka", top: 29, left: 1 },
			{ letter: "ekar", top: 0, left: 8 },
			{ letter: "ra", top: 29, left: 50 },
			{ letter: "aakar", top: 29, left: 76 },
		],
	},
	{
		key: "haat",
		word: "हात",
		colorKey: "red",
		options: [
			{ key: "bhat", word: "भात", colorKey: "yellow" },
			{ key: "paat", word: "पात", colorKey: "green" },
			{ key: "haat", word: "हात", colorKey: "red" },
		],
		correctLetters: [
			{ letter: "ha", top: 2, left: 0 },
			{ letter: "aakar", top: 2, left: 36 },
			{ letter: "taa", top: 2, left: 55 },
		],
	},
	{
		key: "kharayo",
		word: "खरायो",
		colorKey: "black",
		options: [
			{ key: "jarayo", word: "जरायो", colorKey: "red" },
			{ key: "karayo", word: "करायो", colorKey: "yellow" },
			{ key: "kharayo", word: "खरायो", colorKey: "black" },
		],
		correctLetters: [
			{ letter: "kha", top: 28, left: 0 },
			{ letter: "ra", top: 28, left: 33 },
			{ letter: "aakar", top: 28, left: 49 },
			{ letter: "yo", top: 28, left: 61 },
			{ letter: "aakar_ekar", top: 0, left: 81 },
		],
	},

	// {
	// 	key: "tara",
	// 	word: "तारा",
	// 	colorKey: "yellow",
	// 	options: [
	// 		{ key: "dhara", word: "धारा", colorKey: "blue" },
	// 		{ key: "chara", word: "चारा", colorKey: "yellow" },
	// 		{ key: "tara", word: "तारा", colorKey: "yellow" },
	// 	],
	// 	correctLetters: ["taa", "aakar", "ra", "aakar"],
	// },
	// {
	// 	key: "aalu",
	// 	word: "आलु",
	// 	options: [
	// 		{ key: "bhalu", word: "साग" },
	// 		{ key: "talu", word: "साग" },
	// 		{ key: "aalu", word: "साग" },
	// 	],
	// 	correctLetters: ["aa", "la", "oo"],
	//
	// },
	// {
	// 	key: "pana",
	// 	word: "पाना",
	// 	options: [
	// 		{ key: "nana", word: "साग" },
	// 		{ key: "gana", word: "साग" },
	// 		{ key: "pana", word: "साग" },
	// 	],
	// 	correctLetters: ["pa", "aa", "na", "aa"],
	//
	// },
	// {
	// 	key: "sayau",
	// 	word: "स्याउ",
	// 	options: ["khau", "chayau", "sayau"],
	// 	correctLetters: ["saa", "ya", "aa", "u"],
	//
	// },
	// {
	// 	key: "tamater",
	// 	word: "टमाटर",
	// 	options: ["matar", "katahar", "tamatar"],
	// 	correctLetters: ["ta", "ma", "aa", "ta", "ra"],
	//
	// },
]
