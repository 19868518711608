import React from "react"
import { Droppable } from "react-beautiful-dnd"
function EachDrop({ dropItems, index }) {
	let isDropDisabled = dropItems.enable === "disable"
	return (
		<Droppable droppableId={index.toString()} isDropDisabled={isDropDisabled}>
			{(provided) => (
				<div ref={provided.innerRef} {...provided.droppableProps}>
					<div className={dropItems.dropClass + " " + dropItems.enable}>
						<h3 className={dropItems.font}>{dropItems.name}</h3>
						{provided.placeholder}
					</div>
				</div>
			)}
		</Droppable>
	)
}

export default EachDrop
