import Page1to10 from "./Page1to10"
import Page11 from "./Page11"
import Page12 from "./Page12"
import Page13 from "./Page13"
import Page14 from "./Page14"
import Page15 from "./Page15"
import Page16 from "./Page16"
import Page17 from "./Page17"
import Page18 from "./Page18"
import Page19 from "./Page19"
import Page20 from "./Page20"
import Page21 from "./Page21"
import Page22 from "./Page22"
import Page23 from "./Page23"
import Page24 from "./Page24"
import Page25 from "./Page25"
import Page26 from "./Page26"
import Page27 from "./Page27"
import Page28 from "./Page28"
import Page29 from "./Page29"
import Page30 from "./Page30"
import Page31 from "./Page31"
import Page32 from "./Page32"
import Page33 from "./Page33"

let content = [
	...Page1to10,
	Page11,
	Page12,
	Page13,
	Page14,
	Page15,
	Page16,
	Page17,
	Page18,
	Page19,
	Page20,
	Page21,
	Page22,
	Page23,
	Page24,
	Page25,
	Page26,
	Page27,
	Page28,
	Page29,
	Page30,
	Page31,
	Page32,
	Page33,
]

export default content
