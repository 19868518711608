import shuffle from "shuffle-array"

const audios = require.context("../../audios/words/level3", true)
const images = require.context("../../images/level3", true)

const levelData = {
	level: "hard",
	words: [
		{
			key: "hansa",
			npword: "हंस",
		},
		{
			key: "galaichha",
			npword: "गलैँचा",
		},
		{
			key: "chandrabindu",
			npword: "चन्द्रबिन्दु",
		},
		{
			key: "bhangera",
			npword: "भँगेरा",
		},
		{
			key: "singha",
			npword: "सिंह",
		},
		{
			key: "truck",
			npword: "ट्रक",
		},
		{
			key: "bakhro",
			npword: "बाख्रो",
		},
		{
			key: "tukra",
			npword: "टुक्रा",
		},
		{
			key: "kakro",
			npword: "काँक्रो",
		},
		{
			key: "shishu",
			npword: "शिशु",
		},
		{
			key: "chandan",
			npword: "चन्दन",
		},
		{
			key: "camera",
			npword: "क्यामरा",
		},
		{
			key: "shichayak",
			npword: "शिक्षक",
		},
		{
			key: "farsi",
			npword: "फर्सी",
		},
		{
			key: "masayang",
			npword: "मस्याङ",
		},
		{
			key: "tribhuj",
			npword: "त्रिभुज",
		},
	],

	options: [
		{
			key: "rishi",
			npword: "ऋषि",
		},
		{
			key: "tarun",
			npword: "तरूल",
		},
		{
			key: "pakrau",
			npword: "पक्राउ",
		},
		{
			key: "sakriya",
			npword: "सक्रिय",
		},
		{
			key: "paryapta",
			npword: "पर्याप्‍त",
		},
		{
			key: "patkar",
			npword: "पत्कर",
		},
		{
			key: "jhakri",
			npword: "झाँक्री",
		},
		{
			key: "khutruke",
			npword: "खुत्रुके",
		},
		{
			key: "prahari",
			npword: "प्रहरी",
		},
		{
			key: "majhi",
			npword: "माझी",
		},
		{
			key: "gangataa",
			npword: "गँगटा",
		},
		{
			key: "kanksa",
			npword: "कंस",
		},
		{
			key: "muga",
			npword: "मृग",
		},
		{
			key: "sarpa",
			npword: "सर्प",
		},
		{
			key: "patro",
			npword: "पात्रो",
		},
		{
			key: "bagaichha",
			npword: "बगैँचा",
		},
		{
			key: "chandrama",
			npword: "चन्द्रमा",
		},
		{
			key: "krasar",
			npword: "क्रसर",
		},
		{
			key: "kricharda",
			npword: "कृष्ण",
		},
		{
			key: "kriya",
			npword: "क्रिया",
		},
		{
			key: "nangra",
			npword: "नङ्ग्रा",
		},
		{
			key: "patkar",
			npword: "पत्कर",
		},
		{
			key: "janti",
			npword: "जन्ती",
		},
		{
			key: "tatyauli",
			npword: "रत्यौली",
		},
	],
}

let levelWords = levelData.words.map((word) => {
	let audioHint
	try {
		audioHint = audios(`./${word.key}.ogg`)
	} catch (error) {
		console.log(`not found: audio_words: ${word.key}.ogg`)
		audioHint = null
	}
	let imageHint
	try {
		imageHint = images(`./${word.key}.png`)
	} catch (error) {
		console.log(`not found: image_words: ${word.key}.ogg`)
		imageHint = null
	}

	word = {
		...word,
		image: imageHint,
		audio: audioHint,
	}
	return word
})

levelWords = shuffle(levelWords)

levelData.words = levelWords

export default levelData
