const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended p-1 bg-white",
	images: [
		{
			src: images("./page18.png"),
			class: "image-one imgbox-sm girlLookBag",
			imgdiv: "imageCover",
		},
		{
			src: images("./outline-p10-white.png"),
			class: "image-one outline-p16",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p16 textbox textbox-ban",
			textIndivData: [
				{
					textData: "अनि एक्लै बसेर दङ्ग पर्दै ब्याग हेर्थिन्।",
					textClass: "fz-5 m-0 one-line textStyles text-p16a",
					timestamps: [
						{ start: 0 },
						{ start: 0.65 },
						{ start: 1.2 },
						{ start: 2.1 },
						{ start: 2.8 },
						{ start: 3.5 },
						{ start: 4 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s16.ogg"),
			delay: 500,
		},
	],
}

export default content
