import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./coverpage.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page1Text text-center",
			textIndivData: [
				{
					textData: "म किन स्कूल जाने र?",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.5 }, { start: 0.9 }, { start: 1.1 }, { start: 1.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./title.ogg"),
			delay: 1000,
		},
	],
}

export default content
