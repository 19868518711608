const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page07.jpg"),
			class: "image-10",
			imgdiv: "imageCover",
		},
		{
			src: images("./page07_man_woman.png"),
			class: "image-10 ",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideBottom text-center position-bottom-intact  bg-green",
			textIndivData: [
				{
					textData: "“ऊ हेर! लाक्पा दिदीकी भान्जी आइन्।”",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.82 }, { start: 2 }, { start: 2.75 }, { start: 3.8 }, { start: 4.35 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./8.ogg"),
			delay: 1000,
		},
	],
}

export default content
