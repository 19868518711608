const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./page03.jpg"),
			class: "image-five page6",
			imgdiv: "imageCover",
		},
		{
			src: images("./peda_under_tree.gif"),
			class: "image-five pedawithmom ",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox3a text_box-p6a",
			textIndivData: [
				{
					textData: "म कहिले आकाशमा गएको थिएँ, आमा?",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.7 }, { start: 1.1 }, { start: 1.6 }, { start: 2.0 }, { start: 2.3 }],
				},
			],
		},
		{
			textDivClass: "story-text dialogueBubble db_textbox3b text_box-p6b",
			textIndivData: [
				{
					textData: "खै हो म त सम्झन्‍नँ।",
					textClass: "m-0",
					timestamps: [{ start: 4.1 }, { start: 4.5 }, { start: 5 }, { start: 5.2 }, { start: 5.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6a.ogg"),
			delay: 500,
		},
		{
			url: audios("./6b.ogg"),
			delay: 4500,
		},
	],
}

export default content
