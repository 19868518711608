const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page07.jpg"),
			class: "image-eight",
			imgdiv: "imageCover",
		},
		{
			src: images("./raining.gif"),
			class: "image-six",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-bottom-intact text-center",
			textIndivData: [
				{
					textData: "अचम्म मान्दै उनले माइजूलाई सोधिन् – यो के हो माइजू?",
					textClass: "fz-5 m-0 one-line",
					timestamps: [
						{ start: 0.6 },
						{ start: 1.25 },
						{ start: 2.1 },
						{ start: 2.6 },
						{ start: 3.25 },
						{ start: 3.8 },
						{ start: 5 },
						{ start: 5.3 },
						{ start: 5.6 },
						{ start: 5.7 },
					],
				},
				{
					textData: "माइजूले भन्‍नुभयो – यो घुम हो। पानीबाट जोगिन ओढ्नुपर्छ।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [
						{ start: 7.7 },
						{ start: 8.3 },
						{ start: 9.1 },
						{ start: 9.6 },
						{ start: 10 },
						{ start: 10.4 },
						{ start: 11.4 },
						{ start: 12.2 },
						{ start: 13.4 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s7.ogg"),
			delay: 2000,
		},
	],
}

export default content
