import { useContext } from "react"
import SaagStory from "../../../components/Phase5Stories/SaagStory"
import AamaStory from "../../../components/Phase5Stories/AamaStory"
import KeraStory from "../../../components/Phase5Stories/KeraStory"
/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import { StateContext, DispatchContext } from "../../../DecodingGame"

const Phase5 = () => {
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)
	const NextPhase = () => {
		dispatch({ type: "PHASE_COMPLETE", values: { phase: state.phase + 1, transition: false } })
	}
	let Story
	switch (state.levelData[state.stage].key) {
		case "saag":
			Story = <SaagStory NextPhase={NextPhase} />
			break
		case "aama":
			Story = <AamaStory NextPhase={NextPhase} />
			break
		case "kera":
			Story = <KeraStory NextPhase={NextPhase} />
			break
		default:
			Story = <SaagStory NextPhase={NextPhase} />
			break
	}
	return (
		<div
			className="phase5"
			css={css`
				position: relative;
				width: 100%;
				height: 100%;
			`}
		>
			{Story}
		</div>
	)
}

export default Phase5
