import React, { useReducer, useEffect } from "react"
import _ from "underscore"

import Stage from "./pages/Stage/Stage"

// styles
import "./game.styles.scss"
import { snakeToFullCamel } from "../../../helpers/strings"

// helpers
import { reducer } from "./helpers/helpers"

import loaderSvg from "../../../assets/ball-triangle.svg"

// contents
import chapters from "./content/chapters/chapters"
import wrongAnswer from "./content/chapters/assets/common/wrong.m4a"
import rightAnswer from "./content/chapters/assets/common/right.m4a"
const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

export const DispatchContext = React.createContext(null)
export const StateContext = React.createContext(null)

export const getGameContent = (gameKey) =>{
	const gameContentLocation = `${snakeToFullCamel(gameKey)}/shapeAndColors`
	// console.log(gameContentLocation);
	let storyData = null
	storyData = require("../../../EGRContent/Play/"+ gameContentLocation).default
	// console.log(storyData);
}
const WhoseVoiceGame = () => {
	const settings = { audioHints: true }

	const useWindowSize = () => {
		const isClient = typeof window === "object"

		const getSize = () => {
			let width = window.innerWidth <= 1600 ? window.innerWidth : 1600
			let height = window.innerHeight
			let availableHeight = window.innerHeight - 56

			// console.log('wh: ', width, height);
			let adjustedHeight = availableHeight > (width * 9) / 16 ? (width * 9) / 16 : height - 56
			let adjustedwidth = (adjustedHeight * 16) / 9

			// console.log('awh: ', adjustedwidth, adjustedHeight);

			return {
				width: isClient ? Math.ceil(adjustedwidth) : undefined,
				height: isClient ? Math.ceil(adjustedHeight) : undefined,
			}
		}

		const windowSize = React.useRef(getSize())
		// const [windowSize, setWindowSize] = React.useState(getSize);

		// resize hook
		React.useEffect(() => {
			const isClient = typeof window === "object"
			if (!isClient) {
				return false
			}

			const handleResize = _.debounce(() => {
				windowSize.current = getSize()
				dispatch({
					type: "RESET_STAGE",
					values: { windowSize: windowSize.current },
				})
			}, 500)

			window.addEventListener("resize", handleResize)
			return () => window.removeEventListener("resize", handleResize)
			// eslint-disable-next-line
		}, [])
		// Empty array ensures that effect is only run on mount and unmount

		return windowSize.current
	}

	let audios = [wrongAnswer, rightAnswer]
	const [state, dispatch] = useReducer(reducer, {
		settings: settings,
		currentPage: "GAME_START",
		stage: 0,
		levelData: [],
		loadingAssets: true,
		demo: true, // req
		modalOpen: false, // req
		letsWalk: false, // req
		QuestionComponent: false,
		intro: true,
		playing: false,
		hoverHintPlaying: false,
		stageComplete: false,
		rewardComplete: false,
		levelComplete: false,
		bg: "pic.png",
		speaker: "speaker.png",
		click: "click.gif",
		wrong: "wrong-answer.png",
		mic: "mic.png",
		bubble: "bubble.png",
		windowSize: useWindowSize(),
		popUp: false, // used after modal is closed/removed
		audioPlaying: false,
		audios: audios,
		// completedStages: new Array(),
		allowAnswering: true,
	})

	useEffect(() => {
		const preloadAudios = (urls, allAudioLoadedCallback) => {
			let loadedCounter = 0
			let toBeLoadedNumber = urls.length

			const preloadAudio = (url, anAudioLoadedCallback) => {
				let audio = new Audio()
				audio.addEventListener("canplaythrough", anAudioLoadedCallback, false)
				// audio.onload = anAudioLoadedCallback
				audio.src = url
			}

			urls.forEach(function (url) {
				preloadAudio(url, function () {
					loadedCounter++
					// console.log('Number of loaded images: ' + loadedCounter);
					if (loadedCounter === toBeLoadedNumber) {
						allAudioLoadedCallback()
					}
				})
			})
		}

		preloadAudios(audios, () => {
			// console.log("preloaded");
		})
	}, [state, audios])

	let windowSize = useWindowSize()

	// Play music
	useEffect(() => {
		const levelData = chapters
		let currentStage = parseInt(localStorage.getItem("shapeSizeLevel")) || 0
		if (levelData.length <= currentStage) currentStage = 0
		dispatch({ type: "SET", values: { levelData: levelData, stage: currentStage } })
		dispatch({ type: "LOADING_COMPLETE" })
		console.log(currentStage);
	}, [state.level])
	return (
		<div
			className="game-container"

			id="game-container"
			key={windowSize.width}
			style={{
				width: `${windowSize.width}px`,
				height: `${windowSize.height}px`,
			}}
		>
			{state.loadingAssets ? (
				<div className="game-loading">
					<div className="loader-inner">
						<img src={loaderSvg} className="loader" alt="loader" />
						<span>Loading...</span>
					</div>
				</div>
			) : (
				<DispatchContext.Provider value={dispatch}>
					<StateContext.Provider value={state}>
						<Stage key={`stage-${state.stage}`} />
						{console.log(`stage-${state.level}-${state.stage}`)}
					</StateContext.Provider>
				</DispatchContext.Provider>
			)}
		</div>
	)
}

WhoseVoiceGame.whyDidYouRender = true
export const WhoseVoiceMemo = React.memo(WhoseVoiceGame)
WhoseVoiceMemo.whyDidYouRender = true

export default WhoseVoiceMemo
