import React, { useContext } from "react"

import { StateContext } from "../../HitTheWallGame"
// // assets
import "./stage.styles.scss"
import bgImg from "./assets/bg02.png"
import roofImg from "../../components/Wall/assets/wall-roof.png"
import baseImg from "../../components/Wall/assets/wall-base.png"

import Wall from "../../components/Wall/Wall"
import CraneSVG from "./assets/CraneSVG.js"
import Speaker from "../../components/Speaker/Speaker"
import CustomModal from "../../components/LevelCompleteModal/CustomModal"

const Stage1 = () => {
	const state = useContext(StateContext)

	return (
		<div
			className="game-stage"
			style={{
				backgroundImage: `url(${bgImg})`,
			}}
		>
			<div className="wallContainer">
				<img src={roofImg} alt="roof" className="roof" />
				<div className="wall">{<Wall gridWidth={state.gridWidth} gridHeight={state.gridHeight} />}</div>
				<img src={baseImg} alt="base" className="wall-base" />
			</div>

			<CraneSVG className="crane" />

			<CustomModal
				isOpen={state.levelComplete}
				// isOpen={state.stageRewardComplete}
				ariaHideApp={false}
			/>
			<Speaker />
		</div>
	)
}

Stage1.whyDidYouRender = true
const Stage1Memo = React.memo(Stage1)
Stage1Memo.whyDidYouRender = true

export default Stage1Memo
