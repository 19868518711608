import React from "react"
import Header from "Components/Layout/Header"
import ShapeAndColors from "Containers/Play/ShapeAndColors/ShapeColorGame"

const bp = () => {
	return (
		<div className="activity__container">
			<Header storyName="आकार र रंगहरु" isGame={true} />
			<ShapeAndColors />
		</div>
	)
}

export default bp
