import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 page7Scene",
	images: [
		{
			src: images("./bg_carpenter_didi.jpg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./carpenter_didi.gif"),
			imgdiv: "imageCover didi-p7",
		},
		{
			src: images("./nima8_bend_new.png"),
			imgdiv: "imageCover nima_stand-p7",
		},
		{
			src: images("./nima_talking_didi.png"),
			imgdiv: "imageCover nima_bend-p7",
		},
		{
			src: images("./nima_talking_didi.gif"),
			imgdiv: "imageCover nima_bend-gif-p7",
		},
	],

	texts: [
		{
			textDivClass: "story-text page1Text text-center dialogueBubble bubble-textBox07b text-bubble-p7",
			textIndivData: [
				{
					textData: "यो रथको लागि बनाउनु भएको हो? ",
					textClass: "m0",
					timestamps: [
						{ start: 0.2 },
						{ start: 0.8 },
						{ start: 1.2 },
						{ start: 1.6 },
						{ start: 2.2 },
						{ start: 2.4 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./yo_raat_ko_lagi.ogg"),
			delay: 3500,
		},
		{
			url: audios("./busyMarket.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./woodwork1.ogg"),
			delay: 3000,
			isBg: true,
			volume: 0.1,
			loop: true,
		},
		{
			url: audios("./blank.ogg"),
			delay: 12000,
			isBg: false,
			volume: 0,
		},
	],
}

export default content
