const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./page06.jpg"),
			class: "image-8",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text shaking text-center bg-red-light",
			textIndivData: [
				{
					textData: "“ऊ हेर! मिनाकी साथी आइन्।”",
					textClass: "fz-6 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.1 }, { start: 2 }, { start: 2.47 }, { start: 3.05 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7.ogg"),
			delay: 1000,
		},
	],
}

export default content
