const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page_03.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./ant_move.gif"),
			class: "image-five",
			imgdiv: "imageCover moveRight",
		},
		{
			src: images("./raining_new.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text shakingTextR text-center",
			textIndivData: [
				{
					textData: "वर्षा ऋतु",
					textClass: "fz-6 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 0.9 }],
				},
				{
					textData: "पानी र हावाले रूखका हाँगाहरू",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 2.3 }, { start: 2.8 }, { start: 3.1 }, { start: 3.85 }, { start: 4.45 }],
				},
				{
					textData: "भाँचिएका छन्। रूखको फेदमा",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 5.35 }, { start: 6.05 }, { start: 6.7 }, { start: 7.35 }],
				},
				{
					textData: "कमिलाका ताँती हिँडिरहेका छन्।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 8.45 }, { start: 9.2 }, { start: 9.9 }, { start: 10.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./4.ogg"),
			delay: 500,
		},
		{
			url: audios("./a2.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
