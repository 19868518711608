import React, { Component } from "react"
import { Link } from "react-router-dom"
import Logo from "assets/ole_logo.png"
import IconHome from "assets/icons/home.svg"
import ImgReadAll from "assets/top_images/kids_reading_orange.png"
import ImgReadEasy from "assets/top_images/kids_reading_blue.png"
import ImgReadMedium from "assets/top_images/kids_reading_green.png"
import ImgReadHard from "assets/top_images/kids_reading_yellow.png"
import StoryConfig from "../Config/StoryConfig"
import Footer from "Components/Layout/Footer"

class StoryMenu extends Component {
	state = {
		storyConfig: StoryConfig.activity,
		currentDifficulty: "all",
		currentPage: 1,
		headerImage: ImgReadAll,
		showDifficulty: false,
	}

	componentDidMount() {
		document.title = "HRK :: stories"

		// ReactGA.initialize('UA-172822248-1');
		// ReactGA.pageview(window.location.pathname + window.location.search);
		const filteredStory = StoryConfig.activity.filter((eachStory) => {
			return eachStory.page === 1
		})
		this.setState({
			storyConfig: filteredStory,
			currentDifficulty: "all",
			currentPage: 1,
			headerImage: ImgReadAll,
		})
	}

	handleDifficultyChange = (difficulty) => {
		const filteredStory = StoryConfig.activity.filter((eachStory) => {
			if (difficulty === "all") {
				console.log(this.state.currentPage)
				return eachStory.label && eachStory.page === this.state.currentPage
			}
			return difficulty === eachStory.label && eachStory.page && eachStory.page === this.state.currentPage
		})
		let headerImage
		switch (difficulty) {
			case "all":
				headerImage = ImgReadAll
				break

			case "easy":
				headerImage = ImgReadEasy
				break

			case "medium":
				headerImage = ImgReadMedium
				break

			case "hard":
				headerImage = ImgReadHard
				break

			default:
				headerImage = ImgReadAll
				break
		}
		this.setState({
			storyConfig: filteredStory,
			currentDifficulty: difficulty,
			headerImage: headerImage,
			showDifficulty: difficulty === "all" ? false : true,
		})
	}
	handlePage = (pageNo) => {
		window.scrollTo(0, 0)
		const filteredStory = StoryConfig.activity.filter((eachStory) => {
			if (this.state.currentDifficulty === "all") {
				return eachStory.page === pageNo
			}
			return eachStory.page === pageNo && eachStory.label === this.state.currentDifficulty
		})
		console.log("here")
		console.log(filteredStory)
		this.setState({
			storyConfig: filteredStory,
			currentPage: pageNo,
		})
	}
	render() {
		const { storyConfig, showDifficulty } = this.state
		return (
			<div className="activity__page">
				<header className="inner__header">
					<div className="container">
						<div className="header-content">
							<div className="logo">
								<Link to="/">
									<img src={Logo} className="img-fluid" alt="OLE Nepal" />
								</Link>
							</div>
							{/* <strong className="page__heading">पढौ</strong> */}
							<Link to="/">
								<img src={IconHome} className="img-fluid" alt="Home" />
							</Link>
						</div>
					</div>
				</header>
				<main className="main__content">
					<div className="container">
						<div className="activity-header">
							<div className="img-holder">
								<img src={this.state.headerImage} className="img-fluid" alt="read" />
							</div>
							<div></div>
						</div>
						<ul className="filter__list">
							{showDifficulty && (
								<li>
									<button className="btn btn-round lightorange" onClick={() => this.handleDifficultyChange("all")}>
										सबै
									</button>
								</li>
							)}
							<li>
								<button className="btn btn-round lightblue" onClick={() => this.handleDifficultyChange("easy")}>
									तह १
								</button>
							</li>
							<li>
								<button className="btn btn-round lightgreen" onClick={() => this.handleDifficultyChange("medium")}>
									तह २
								</button>
							</li>
							<li>
								<button className="btn btn-round pink" onClick={() => this.handleDifficultyChange("hard")}>
									तह ३
								</button>
							</li>
						</ul>
						<div className="read__list">
							{storyConfig.length > 0 && (
								<div className="row">
									{storyConfig.map((storyContent) => {
										if (storyContent.disabled) {
											return null
										} else {
											return (
												<div key={storyContent.activityKey} className="col-md-4">
													<div className="story__menu__item">
														<Link to={`/story/${storyContent.activityKey}/start`}>
															<div className="img__square">
																<div className="img__wrap">
																	<img
																		className="img-fluid"
																		src={storyContent.storyBanner}
																		alt={storyContent.activityName}
																	/>
																</div>
															</div>
														</Link>
														<Link
															className={`btn btn-round btn-block ${storyContent.storyBannerBtnColor}`}
															to={`/story/${storyContent.activityKey}/start`}
														>
															{storyContent.activityName}
														</Link>
													</div>
												</div>
											)
										}
									})}
								</div>
							)}
						</div>
						<div className="pagination_div">
							<div className="pagination_text">पृष्ठ</div>
							<button
								className={
									this.state.currentPage === 1
										? "buttonPagination button-one buttonPaginationActive"
										: "buttonPagination button-one"
								}
								onClick={() => {
									this.handlePage(1)
								}}
							>
								१
							</button>
							<button
								className={
									this.state.currentPage === 2
										? "buttonPagination button-two buttonPaginationActive"
										: "buttonPagination button-two"
								}
								onClick={() => {
									this.handlePage(2)
								}}
							>
								२
							</button>
						</div>
					</div>
				</main>
				<Footer />
			</div>
		)
	}
}

export default StoryMenu
