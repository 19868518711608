const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_girl_talking.png"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./girl_talking.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./horse_on_window.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "घोडा मेरो छँदै छ",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.7 }, { start: 1.5 }, { start: 2.1 }],
				},
				{
					textData: "फूल पनि छँदै छ",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 2.85 }, { start: 3.5 }, { start: 4.2 }, { start: 4.9 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./8.ogg"),
			delay: 500,
		},
	],
}

export default content
