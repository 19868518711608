import React from "react"
import Header from "Components/Layout/Header"
import HitTheWallGame from "Containers/Play/HitTheWall/HitTheWallGame"

const HitTheWall = () => {
	return (
		<div className="activity__container">
			<Header storyName="शब्द फुटाऊ" isGame={true} />
			<HitTheWallGame />
		</div>
	)
}

export default HitTheWall
