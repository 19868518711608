const assets = require.context("./assets")

const chapters = [
	{
		id: 1,
		frameHeight: "",
		animals: [
			{
				image: assets("./slide1/dog.gif"),
				height: "30%",
				top: "",
			},
			{
				image: assets("./slide1/rabbit.png"),
				height: "",
				top: "",
			},
			{
				image: assets("./slide1/deer.png"),
				height: "55%",
				top: "",
			},
		],
		questioner: assets("./questioner/slide1.gif"),
		animalSound: assets("./animal sounds/Dog.mp3"),
		animalNameEnglish: assets("./animal name/Dog.ogg"),
		animalNameNepali: assets("./animal name/Kukur.ogg"),
		questionerVoice: assets("./common/female.mp3"),
		modalAnimal: assets("./slide1/dog-modal.gif"),
		rightAnswer: 0,
		nepaliName: "कुकुर",
		englishName: "DOG",
		questionerWalk: assets("./slide1/questioner-walk.gif"),
		questionerTalk: assets("./slide1/questioner-talk.gif"),
		animalWalk: assets("./slide1/dog-correct.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( female).ogg"),
		bg: assets("./slide1/bg.png"),
		animalMeetsQuestionerX: "140",
		animalMeetsQuestionerY: "130%", //200
		bgMusic: assets("./common/background/01Jungle.m4a"),
		headerBG: "#3f98a8",
		animalWalkDuration: 12,
		questionerWalkDuration: 3,
		animalContainerWidth: "",
		oldFriends: 0,
		animalGoesAaway: "",
		positionOfOldFriends: "-25%",
		gifSize: "",
		oldFriendsHeight: "60%",
		oldFriendsMarginTop: "2.5%",
	},
	{
		id: 2,
		frameHeight: "75%",
		animals: [
			{
				image: assets("./slide2/bear.png"),
				height: "40%",
				top: "",
			},
			{
				image: assets("./slide2/elephant.gif"),
				height: "60%",
				top: "",
			},
			{
				image: assets("./slide2/buff.png"),
				height: "40%",
				top: "",
			},
		],
		questioner: assets("./questioner/slide2.gif"),
		animalSound: assets("./animal sounds/Elephant.mp3"),
		animalNameEnglish: assets("./animal name/Elephant.ogg"),
		animalNameNepali: assets("./animal name/Hatti.ogg"),
		questionerVoice: assets("./common/male.mp3"),
		modalAnimal: assets("./slide2/elephant-modal.gif"),
		rightAnswer: 1,
		nepaliName: "हात्ती",
		englishName: "Elephant",
		questionerWalk: assets("./slide2/questioner-walk.gif"),
		questionerTalk: assets("./slide2/questioner-talk.gif"),
		animalWalk: assets("./slide2/elephant walking.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( male).ogg"),
		bg: assets("./slide2/bg.png"),
		animalMeetsQuestionerX: "-100%",
		animalMeetsQuestionerY: "35%", //150
		bgMusic: assets("./common/background/Bhaisi song (1).m4a"),
		headerBG: "#e69138",
		animalWalkDuration: 30,
		questionerWalkDuration: 20,
		animalContainerWidth: "",
		oldFriends: -5,
		animalGoesAaway: "100%",
		oldFriendsComesNear: "35%",
		oldFriendsGoesTo: "600%",
		positionOfOldFriends: "-35%",
		gifSize: "",
		wait: 1,
		oldFriendsHeight: "120%",
		oldFriendsMarginTop: "-2.5%",
	},
	{
		id: 3,
		frameHeight: "75%",
		animals: [
			{
				image: assets("./slide3/lion.gif"),
				height: "40%",
				top: "",
			},
			{
				image: assets("./slide3/deer.png"),
				height: "50%",
				top: "",
			},
			{
				image: assets("./slide3/panda.png"),
				height: "25%",
				top: "",
			},
		],
		questioner: assets("./questioner/slide3.gif"),
		animalSound: assets("./animal sounds/Lion.mp3"),
		animalNameEnglish: assets("./animal name/Lion.ogg"),
		animalNameNepali: assets("./animal name/singha.m4a"),
		questionerVoice: assets("./common/female.mp3"),
		modalAnimal: assets("./slide3/lion-modal.gif"),
		rightAnswer: 0,
		nepaliName: "सिंह",
		englishName: "Lion",
		questionerWalk: assets("./slide3/questioner-walk.gif"),
		questionerTalk: assets("./slide3/questioner-talk.gif"),
		animalWalk: assets("./slide3/lion walking.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( female).ogg"),
		bg: assets("./slide3/bg.png"),
		animalMeetsQuestionerX: "80",
		animalMeetsQuestionerY: "50%", //150
		bgMusic: assets("./common/background/My Song 20.m4a"),
		headerBG: "#3f98a8",
		animalWalkDuration: 14,
		questionerWalkDuration: 6,
		animalContainerWidth: "100%",
		oldFriends: 35,
		animalGoesAaway: "",
		oldFriendsComesNear: "20%",
		oldFriendsGoesTo: "500%",
		positionOfOldFriends: "-80%",
		wait: 100,
		oldFriendsHeight: "100%",
		withOwl: assets("./slide3/lion-owl.gif"),
	},
	{
		id: 4,
		frameHeight: "",
		animals: [
			{
				image: assets("./slide4/owl.png"),
				height: "30%",
				top: "-190%",
				left: "-105%",
			},
			{
				image: assets("./slide4/duck.png"),
				height: "50%",
				top: "52%",
			},
			{
				image: assets("./slide4/rabbit.png"),
				height: "40%",
				top: "90%",
			},
		],
		questioner: assets("./questioner/slide4.gif"),
		animalSound: assets("./animal sounds/Owl.wav"),
		animalNameEnglish: assets("./animal name/Owl.ogg"),
		animalNameNepali: assets("./animal name/Latokoshero.ogg"),
		questionerVoice: assets("./common/male.mp3"),
		modalAnimal: assets("./slide4/owl-modal.gif"),
		rightAnswer: 0,
		nepaliName: "लाटोकोसेरो",
		englishName: "Owl",
		questionerWalk: assets("./slide4/questioner-walk.gif"),
		questionerTalk: assets("./slide4/questioner-talk.gif"),
		animalWalk: assets("./slide4/owl flying.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( male).ogg"),
		bg: assets("./slide4/bg.png"),
		animalMeetsQuestionerX: "0",
		animalMeetsQuestionerY: "0", //150
		bgMusic: assets("./common/background/My Song 43.m4a"),
		headerBG: "#3d85c6",
		animalWalkDuration: 11,
		questionerWalkDuration: 1,
		animalContainerWidth: "80%",
		oldFriends: 60,
		animalGoesAaway: "",
		wait: 100,
		oldFriendsComesNear: "50%",
		oldFriendsGoesTo: "500%",
		positionOfOldFriends: "-150%",
	},
	{
		id: 5,
		frameHeight: "",
		animals: [
			{
				image: assets("./slide5/frog.png"),
				height: "10%",
				top: "-90%",
			},
			{
				image: assets("./slide5/wolf.gif"),
				height: "40%",
				top: "25%",
			},
			{
				image: assets("./slide5/monkey.png"),
				height: "40%",
				top: "50%",
			},
		],
		questioner: assets("./questioner/slide5.gif"),
		animalSound: assets("./animal sounds/wolf.mp3"),
		animalNameEnglish: assets("./animal name/wolf.ogg"),
		animalNameNepali: assets("./animal name/bwaso.ogg"),
		questionerVoice: assets("./common/female.mp3"),
		modalAnimal: assets("./slide5/wolf-modal.gif"),
		rightAnswer: 1,
		nepaliName: "ब्वाँसो",
		englishName: "Wolf",
		questionerWalk: assets("./slide5/questioner-walk.gif"),
		questionerTalk: assets("./slide5/questioner-talk.gif"),
		animalWalk: assets("./slide5/wolf walking.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( female).ogg"),
		bg: assets("./slide5/bg.png"),
		animalMeetsQuestionerX: "-95%",
		animalMeetsQuestionerY: "66%", //150
		bgMusic: assets("./common/background/My Song 43.m4a"),
		headerBG: "#6aa84f",
		animalWalkDuration: 20,
		questionerWalkDuration: 11,
		animalContainerWidth: "100%",
		oldFriends: 70,
		wait: 100,
		animalGoesAaway: "",
		oldFriendsComesNear: "40%",
		oldFriendsGoesTo: "500%",
		positionOfOldFriends: "-162%",
		gifSize: "",
		oldFriendsHeight: "72%",
	},
	{
		id: 6,
		frameHeight: "",
		animals: [
			{
				image: assets("./slide6/cat.gif"),
				height: "30%",
				top: "",
			},
			{
				image: assets("./slide6/hyena.png"),
				height: "30%",
				top: "",
			},
			{
				image: assets("./slide6/leopard.png"),
				height: "30%",
				top: "",
			},
		],
		questioner: assets("./questioner/slide6.gif"),
		animalSound: assets("./animal sounds/Cat.mp3"),
		animalNameEnglish: assets("./animal name/Cat.ogg"),
		animalNameNepali: assets("./animal name/Biralo.ogg"),
		questionerVoice: assets("./common/male.mp3"),
		modalAnimal: assets("./slide6/cat-modal.gif"),
		rightAnswer: 0,
		nepaliName: "बिरालो",
		englishName: "Cat",
		questionerWalk: assets("./slide6/questioner-walk.gif"),
		questionerTalk: assets("./slide6/questioner-talk.gif"),
		animalWalk: assets("./slide6/cat walking.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( male).ogg"),
		bg: assets("./slide6/bg.png"),
		animalMeetsQuestionerX: "100",
		animalMeetsQuestionerY: "130%", //150
		bgMusic: assets("./common/background/Jungle (1).m4a"),
		headerBG: "#0b5394",
		animalWalkDuration: 11,
		questionerWalkDuration: 4,
		animalContainerWidth: "75%",
		oldFriends: 80,
		animalGoesAaway: "",
		oldFriendsComesNear: "35%",
		oldFriendsGoesTo: "500%",
		positionOfOldFriends: "-200%",
		wait: 100,
		gifSize: "",
		oldFriendsHeight: "60%",
	},
	{
		id: 7,
		frameHeight: "",
		animals: [
			{
				image: assets("./slide7/hyena.png"),
				height: "25%",
				top: "15%",
			},
			{
				image: assets("./slide7/tiger.png"),
				height: "35%",
				top: "15%",
			},
			{
				image: assets("./slide7/rhino.png"),
				height: "50%",
				top: "15%",
			},
		],
		questioner: assets("./questioner/slide7.gif"),
		animalSound: assets("./animal sounds/Rhino.mp3"),
		animalNameEnglish: assets("./animal name/rhino.ogg"),
		animalNameNepali: assets("./animal name/gaida.ogg"),
		questionerVoice: assets("./common/female.mp3"),
		modalAnimal: assets("./slide7/rhino-modal.gif"),
		rightAnswer: 2,
		nepaliName: "गैंडा",
		englishName: "Rhino",
		questionerWalk: assets("./slide7/questioner-walk.gif"),
		questionerTalk: assets("./slide7/questioner-talk.gif"),
		animalWalk: assets("./slide7/rhino walking.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( female).ogg"),
		bg: assets("./slide7/bg.png"),
		animalMeetsQuestionerX: "-200%",
		animalMeetsQuestionerY: "42%", //150
		bgMusic: assets("./common/background/01Jungle.m4a"),
		headerBG: "#8e7cc3",
		animalWalkDuration: 34,
		questionerWalkDuration: 22,
		animalContainerWidth: "90%",
		oldFriends: 65,
		animalGoesAaway: "",
		oldFriendsComesNear: "30%",
		oldFriendsGoesTo: "300%",
		positionOfOldFriends: "-250%",
		oldFriendsHeight: "120%",
		wait: 120,
	},
	{
		id: 8,
		frameHeight: "",
		animals: [
			{
				image: assets("./slide8/rabbit.png"),
				height: "40%",
				top: "",
			},
			{
				image: assets("./slide8/okapi.png"),
				height: "50%",
				top: "",
			},
			{
				image: assets("./slide8/horse.png"),
				height: "60%",
				top: "",
			},
		],
		questioner: assets("./questioner/slide8.gif"),
		animalSound: assets("./animal sounds/Horse.mp3"),
		animalNameEnglish: assets("./animal name/Horse.ogg"),
		animalNameNepali: assets("./animal name/Ghoda.ogg"),
		questionerVoice: assets("./common/male.mp3"),
		modalAnimal: assets("./slide8/horse-modal.gif"),
		rightAnswer: 2,
		nepaliName: "घोडा",
		englishName: "Horse",
		questionerWalk: assets("./slide8/questioner-walk.gif"),
		questionerTalk: assets("./slide8/questioner-talk.gif"),
		animalWalk: assets("./slide8/horse walking.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( male).ogg"),
		bg: assets("./slide8/bg.png"),
		animalMeetsQuestionerX: "-240%",
		animalMeetsQuestionerY: "50%", //150
		bgMusic: assets("./common/background/Horse song.m4a"),
		headerBG: "#93c47d",
		animalWalkDuration: 25,
		questionerWalkDuration: 16,
		animalContainerWidth: "100%",
		oldFriends: 80,
		animalGoesAaway: "",
		oldFriendsComesNear: "24%",
		oldFriendsGoesTo: "300%",
		positionOfOldFriends: "-285%",
		wait: 140,
		oldFriendsHeight: "140%",
	},
	{
		id: 9,
		frameHeight: "",
		animals: [
			{
				image: assets("./slide9/armadillo.gif"),
				height: "15%",
				top: "",
			},
			{
				image: assets("./slide9/leopard.png"),
				height: "40%",
				top: "",
			},
			{
				image: assets("./slide9/ok.png"),
				height: "50%",
				top: "",
			},
		],
		questioner: assets("./questioner/slide9.gif"),
		animalSound: assets("./animal sounds/Pangolin.mp3"),
		animalNameEnglish: assets("./animal name/Pangolin.ogg"),
		animalNameNepali: assets("./animal name/salak.ogg"),
		questionerVoice: assets("./common/female.mp3"),
		modalAnimal: assets("./slide9/armadillo-modal.gif"),
		rightAnswer: 0,
		nepaliName: "सालक",
		englishName: "Pangolin",
		questionerWalk: assets("./slide9/questioner-walk.gif"),
		questionerTalk: assets("./slide9/questioner-talk.gif"),
		animalWalk: assets("./slide9/armadillo walking.gif"),
		letsWalkTogether: assets("./common/au sangai ghumna jau ( female).ogg"),
		bg: assets("./slide9/bg.png"),
		animalMeetsQuestionerX: "150",
		animalMeetsQuestionerY: "250%", //150
		bgMusic: assets("./common/background/City music horse.m4a"),
		headerBG: "#3d85c6",
		animalWalkDuration: 15,
		questionerWalkDuration: 4,
		animalContainerWidth: "100%",
		oldFriends: 120,
		animalGoesAaway: "",
		oldFriendsComesNear: "19%",
		oldFriendsGoesTo: "300%",
		positionOfOldFriends: "-305%",
		wait: 120,
	},
	{
		id: 10,
		frameHeight: "100%",
		questionerVoice: assets("./common/kasko awaz man paryo.ogg"),
		questionerTalk: assets("./final/girl talking.gif"),
		questioner: assets("./final/girl.png"),
		bg: assets("./final/final.png"),
		animalContainerWidth: "100%",
		height: "60%",
		question: `हामीले कति धेरै<br/> जनावरहरूको आवाज सुन्यौँ।<br/> तिमीलाई कसको आवाज मनपर्‍यो ?`,
		bubble: assets("./final/bubble.png"),
		bgMusic: assets("./common/background/01Jungle.m4a"),
	},
]

export default chapters
