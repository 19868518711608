const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg.jpg"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./dost_chatting.gif"),
			class: "image-one dosthide",
			imgdiv: "imageCover",
		},
		{
			src: images("./dost_angry.gif"),
			class: "image-one dostshow",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-top-center text-center",
			textIndivData: [
				{
					textData: "सुन्दा उस्तै सुनिनाले",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 1.4 }, { start: 2.1 }],
				},
				{
					textData: "बडा गाह्रो पर्थ्यो",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 4 }, { start: 4.8 }, { start: 5.6 }],
				},
				{
					textData: "एकातिर कुरा उठे",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 7.3 }, { start: 8.8 }, { start: 9.6 }],
				},
				{
					textData: "अर्कातिर सर्थ्यो",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 10.6 }, { start: 12.2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./5.ogg"),
			delay: 1000,
		},
	],
}

export default content
