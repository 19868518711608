import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./bg_river_empty.jpg"),
			imgdiv: "imageCover empty-river",
		},
		{
			src: images("./water_wave01.png"),
			imgdiv: "imageCover river-wave river-wave1",
			styles: {
				top: "42.7%",
				left: "54%",
				width: "11%",
				transform: "rotate(5deg)",
			},
		},
		{
			src: images("./water_wave02.png"),
			imgdiv: "imageCover river-wave river-wave2",
			styles: {
				top: "46%",
				left: "34%",
				width: "8%",
				transform: "rotate(34deg)",
			},
		},
		{
			src: images("./water_wave03.png"),
			imgdiv: "imageCover river-wave river-wave3",

			styles: { top: "46%", left: "45%", width: "10%" },
		},
		{
			src: images("./boy-clay-body.gif"),
			imgdiv: "imageCover",
			styles: { top: "72%", left: "24%", width: "10%" },
		},
		{
			src: images("./boy-clay-face.png"),
			imgdiv: "imageCover boy-clay-face",
			styles: {
				top: "55%",
				left: "23%",
				width: "13%",
				transformOrigin: "50% 36%",
			},
		},
		{
			src: images("./leaf0001.gif"),
		},
		{
			src: images("./mum_cutting_grass.gif"),
			imgdiv: "imageCover mum_cutting_grass",
			styles: { top: "32%", left: "77%", width: "18%" },
		},
		{
			src: images("./mum_cutting_grass_nt.gif"),
			imgdiv: "imageCover mum_cutting_grass_nt",
			styles: { top: "32%", left: "77%", width: "18%" },
		},
		// {
		// 	src: images('./mum_cutting_grass.png'),
		// 	imgdiv: 'imageCover mum_cutting_grass_png d-none',
		// 	styles: { top: '32%', left: '77%', width: '18%' },
		// },
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page2mom topLeftBubble text-center",
			textIndivData: [
				{
					textData: "तिमी त अब छिट्टै स्कूल जान थाल्नु पर्छ है।",
					textClass: "f-mksjr m-0 ",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 0.8 },
						{ start: 1.0 },
						{ start: 1.2 },
						{ start: 1.7 },
						{ start: 2.1 },
						{ start: 2.5 },
						{ start: 2.9 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./1.ogg"),
			delay: 4000,
		},
		{
			url: audios("./a1.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
