const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page11.jpg"),
			class: "img-ten",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text position-bottom-intact  text-center",
			textIndivData: [
				{
					textData: "उनले सानो घुम ओढिन्। घुम ठिक्क भयो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [
						{ start: 0.4 },
						{ start: 0.7 },
						{ start: 1.3 },
						{ start: 1.6 },
						{ start: 2.6 },
						{ start: 2.9 },
						{ start: 3.4 },
					],
				},
				{
					textData: "शशी दङ्‍ग परिन्।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 4.3 }, { start: 4.8 }, { start: 5.4 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s11.ogg"),
			delay: 2000,
		},
	],
}

export default content
