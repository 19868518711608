import gsap from "gsap"

export default function mainAnimation(element) {
	if (element) {
		let textEl = element.querySelector("p")
		if (textEl) {
			let words = textEl.textContent.split(" ")
			let finalStr = words.reduce((accumulator, currentValue, currentIndex) => {
				return currentIndex === 1
					? `<span class='word'>${accumulator}</span> <span class='word'>${currentValue}</span>`
					: `${accumulator} <span class='word'>${currentValue}</span>`
			})
			textEl.innerHTML = finalStr
			let wordsEl = textEl.querySelectorAll(".word")

			let tl = gsap.timeline()
			wordsEl.forEach((element) => {
				tl.from(
					element,
					0.5,
					{
						// y: "3rem",
						x: "-3rem",
						scale: 0.5,
						opacity: 0,
						ease: "back.out(1.2)",
						// rotate: 180
					},
					"<+0.1"
				)
			})
		}
	}
}
