const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_01.png"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./three_monkey_P4.png"),
			class: "image-five threeMonkey_p4",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideTopCenter text-center",
			textIndivData: [
				{
					textData: "तीन ओटा बाँदर मिली",
					textClass: "fz-5 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 1.2 }, { start: 1.8 }, { start: 2.5 }],
				},
				{
					textData: "फल्याक उठाए।",
					textClass: "fz-5 m-0 one-line ",
					timestamps: [{ start: 3.6 }, { start: 4.7 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s3.ogg"),
			delay: 600,
		},
	],
}

export default content
