const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	images: [
		{
			src: images("./13to24/hill.svg"),
			imgdiv: "hill-anim hill",
			styles: { position: "absolute", bottom: 0 },
		},
		{
			src: images("./13to24/flying.gif"),
			imgdiv: "imageCover flying",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-anim-1 cloud-1",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-anim-2 cloud-2",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-anim-3 cloud-3",
		},
		{
			src: images("./13to24/clouds.png"),
			imgdiv: "imageCover cloud-anim-4 cloud-4",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "बाटो लामो थियो। रात पर्नुअगाडि पोखरीमा नपुगे बास बस्‍ने ठाउँ थिएन।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.2 },
						{ start: 1.7 },
						{ start: 2.1 },
						{ start: 3 },
						{ start: 3.7 },
						{ start: 4.5 },
						{ start: 4.9 },
						{ start: 5.3 },
						{ start: 5.7 },
						{ start: 6.5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p4_s2.ogg"),
			delay: 1500,
		},
	],
}

export default content
