import PageContent from "./pages/all"

const images = require.context("./images", true)
const movies = require.context("./movies", true)
let content = {
	name: "चार्ली चमेरो",
	key: "charlie-chamero",
	coverImage: images("./coverpage.png"),
	movie: movies("./charlie-chamero-md.mp4"),
	movies: [
		movies("./Page 1.mp4"),
		movies("./Page 2.mp4"),
		movies("./Page 3.mp4"),
		movies("./Page 4.mp4"),
		movies("./Page 5.mp4"),
		movies("./Page 6.mp4"),
		movies("./Page 7.mp4"),
	],
	timestamps: [
		{ min: 0, sec: 5 },
		{ min: 0, sec: 31.4 },
		{ min: 1, sec: 5 },
		{ min: 1, sec: 32.4 },
		{ min: 2, sec: 9 },
		{ min: 2, sec: 53.4 },
		{ min: 3, sec: 21.4 },
		{ min: 3, sec: 57.35 },
		{ min: 4, sec: 39.2 },
		{ min: 4, sec: 56 },
	],
	subtitles: [
		{ min: 0, sec: 0, text: "" },
		{ min: 0, sec: 9.5, text: "चार्ली, तिमी आज पहिलो पटक हामीसित खानाको खोजीमा जान लागेका छौ।" },
		{ min: 0, sec: 16.2, text: "बथानलाई छोडेर यताउता नजानु नि।" },
		{ min: 0, sec: 20.4, text: "अनि मानिसहरूको नजिक त जाँदै नजानु।" },
		{ min: 0, sec: 25, text: "उनीहरू आफू भन्दा भिन्‍न र नबुझेका कुराहरू खासै रुचाउँदैन्।" },
		{ min: 0, sec: 31, text: "" },
		{ min: 0, sec: 34, text: "त्यो जुनेली रातमा चमेराको बथान खाना खोज्दै उड्‍न थाले।" },
		{ min: 0, sec: 40.3, text: "केहि छिन नबित्दै चार्लीको ध्यान तल जमिनतिरको झिलिमिलि बत्तीहरूमा गयो।" },
		{ min: 0, sec: 48.1, text: "माथि आकाशमा मात्रै तारा देखेका चार्लीलाई जमिनमा पनि तारा देख्दा अचम्म लागेछ।" },
		{ min: 0, sec: 55.2, text: "अनि त्यसैमा भुल्दै ऊ बथानबाट छुट्टिएर जमिनतिर झरेछ।" },
		{ min: 1, sec: 1, text: "" },

		{ min: 1, sec: 12.3, text: "आगोको वरिपरि मानिसहरू रमाइरहेको देखेर चार्ली चकित हुन्छन्।" },
		{ min: 1, sec: 18.7, text: "तर पृथ्वी नाम गरेको एकजना केटा भने एक्लै टोलाइरहेको हुन्छ।" },
		{ min: 1, sec: 24, text: "पृथ्वी सित आँखा जुध्दा चार्ली झस्याङ्ग भएर भाग्छ।" },
		{ min: 1, sec: 30, text: "" },

		{ min: 1, sec: 33.8, text: "केहि दिन पछि खेतमा एक्लै डुलिरहेको बेलामा पृथ्वीले किरा फट्याङ्ग्राहरूले बाली खाइरहेको देख्छ।" },
		{ min: 1, sec: 42, text: "नजिक गएर हेर्दा त उसले चार्लीलाई किराहरू खाँदै गरेको पो भेट्छ।" },
		{ min: 1, sec: 50, text: "यो ठाउँका किरा फट्याङ्ग्राहरू कस्तो मिठ्ठो र रसिलो रहेछन्।" },
		{ min: 1, sec: 55, text: "पृथ्वीले अचम्म मानेर हेर्दै बस्छ र विस्तारै कुरा गर्न अगाडि बढ्दा चार्लीले देख्छ र तर्सेर भाग्छ।" },
		{ min: 2, sec: 4, text: "" },

		{ min: 2, sec: 10, text: "यसपालि त बाली एकदम राम्रो भएको छ। बारीमा किरा फट्याङ्ग्रा पनि खासै देखिएकै छैन।" },
		{ min: 2, sec: 18.5, text: "" },
		{ min: 2, sec: 28, text: "नडराऊ।" },
		{ min: 2, sec: 30, text: "तिमी अस्ति किरा खाइरहेको थियौ हैन र?" },
		{ min: 2, sec: 33, text: "किन र? खान हुन्‍न थियो?" },
		{ min: 2, sec: 36, text: `होइन, खाएर ठिक गर्‍यौ। तिमीले किराहरू खाइदिएर त हाम्रो बाली राम्रो भयो।` },
		{ min: 2, sec: 44, text: `किरा मार्ने औषधी पनि छर्न परेन। मेरो बाबा आमा पनि खुशी हुनुभयो।` },
		{ min: 2, sec: 51.5, text: "" },
		{ min: 2, sec: 57, text: "तिमी किन सधैँ एक्लै हुन्छौ?" },
		{ min: 3, sec: 1, text: "मलाई बथानमा उड्‍न मन लाग्दैन। मलाई आफ्‍नै ठाउँमा एक्लै बस्‍न मन पर्छ।" },
		{ min: 3, sec: 7, text: "मलाई पनि!" },

		{ min: 3, sec: 8, text: "म त निन्द्रामा हिँडेको बहानामा राति बाहिर निस्कन्छु।" },
		{ min: 3, sec: 13, text: "मेरा अरु पनि धेरै साथीहरू रातमा बाहिर आउँछन्।" },
		{ min: 3, sec: 20, text: "तिमी नि?" },
		{ min: 3, sec: 21, text: "" },

		{ min: 3, sec: 22, text: "म त भर्खर मात्र बाहिर निस्कन सुरू गरेको।" },
		{ min: 3, sec: 25, text: "मैले भेटेको पहिलो साथी भनेकै तिमी हौ।" },
		{ min: 3, sec: 29, text: "हुन त आमाले मलाई मानिसहरूबाट टाढा रहनु भन्‍नुभएको छ।" },
		{ min: 3, sec: 34, text: "कस्तो अचम्मको कुरा गर्‍यौ तिमीले त।" },
		{ min: 3, sec: 37.4, text: "हामी पो तिमीहरूबाट टाढा बस्न पर्छ भन्छन्।" },
		{ min: 3, sec: 42, text: "तिमीहरूले त मानिसको रगत चुस्छौ भन्‍ने सुनेको।" },
		{ min: 3, sec: 46, text: "हाम्रो जातले फलफूल र कीरा फट्याङ्ग्रा मात्र खान्छन्।" },
		{ min: 3, sec: 50.5, text: "त्यो रगत चुस्‍ने जाति त एकदम थोरै छन् र तिनीहरू यता पाइँदैनन्।" },
		{ min: 3, sec: 55.5, text: "" },

		{ min: 4, sec: 0, text: "यसरी नै चार्ली र पृथ्वी एक अर्कालाई चिन्दै जान्छन।" },
		{ min: 4, sec: 7, text: "अनि उनीहरू रमाउँदै खेलमा भुल्दछन।" },
		{ min: 4, sec: 12, text: "" },

		{ min: 4, sec: 25, text: "लौ मलाई त निन्द्रा लाग्यो। अब सुत्‍न जान्छु। भोलि पनि आउ है? बाई!" },
		{ min: 4, sec: 35, text: "हुन्छ, बाई!" },
		{ min: 4, sec: 38, text: "" },
		{ min: 4, sec: 40, text: "आमा कस्तो अचम्म, मानिसहरू त पल्टेर पो सुत्दो रहेछन्।" },
		{ min: 4, sec: 45, text: "हामी जस्तो मज्जाले झुण्डेर सुत्दैन रहेछन्।" },
		{ min: 4, sec: 49, text: "" },
	],
	content: PageContent,
}

content.timestamps = content.timestamps.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})
content.subtitles = content.subtitles.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})

export default content
