/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import { useContext, useEffect } from "react"
import { ReactSVG } from "react-svg"
import { Howl } from "howler"
import gsap from "gsap"

// Context
import { StateContext, DispatchContext } from "../../../DecodingGame"

const Phase4 = () => {
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)
	const levelData = state.levelData[state.stage]
	const options = levelData.detailedOptions
	let firstFlag = true

	useEffect(() => {
		const hintAudio = new Howl({
			src: `${levelData.audio}`,
			autoplay: false,
			loop: false,
		})
		hintAudio.play()
		gsap.from(`#${options[2].key}`, {
			repeat: -1,
			repeatDelay: 5,
			yoyo: true,
			delay: 2,
			keyframes: [
				{ scale: 1.2, duration: 1 },
				{ scale: 1, duration: 1 },
				{ scale: 1.2, duration: 1 },
				{ scale: 1, duration: 1 },
				{ scale: 1.2, duration: 1 },
				{ scale: 1, duration: 1 },
			],
		})
	}, [levelData.audio, options])

	const optionClicked = (option) => {
		if (levelData.key === option.key) {
			if (firstFlag) {
				firstFlag = false
				const hintAudio = new Howl({
					src: `${options[2].audio}`,
					autoplay: false,
					loop: false,
				})
				hintAudio.play()
				hintAudio.on("end", () => {
					gsap.to(`#${options[0].key}, #${options[1].key}`, {
						opacity: 0,
						duration: 1,
					})
					gsap.to(`#${option.key}`, {
						xPercent: -150,
						yPercent: -110,
						duration: 1.5,
					})
					gsap.to(`#t-${option.key}`, {
						xPercent: 0,
						yPercent: 225,
						duration: 1.5,
						onComplete: () => {
							setTimeout(() => {
								dispatch({ type: "PHASE_COMPLETE", values: { phase: state.phase + 1, transition: true } })
							}, 400)
						},
					})
				})
			}
		} else {
			gsap.fromTo(
				`#${option.key}`,
				{
					scale: 0.6,
				},
				{
					scale: 0.8,
					duration: 0.5,
					repeat: 4,
					yoyo: true,
				}
			)
			gsap.to(`#${option.key}`, {
				scale: 1,
				duration: 0.5,
				delay: 2,
			})
		}
	}
	return (
		<div
			className="phase4"
			css={css`
				position: relative;
				width: 100%;
				height: 100%;

				display: flex;
				flex-direction: column;
			`}
		>
			{console.log(options)}
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					flex: 1;
				`}
			>
				<ReactSVG
					src={levelData.wordImg}
					loading={() => <span>Loading</span>}
					css={css`
						width: 25%;
					`}
					id={`t-${levelData.key}`}
					beforeInjection={(svg) => {
						svg.classList.add("dropPlace-decodingL1")
						svg.setAttribute("style", `fill: ${levelData.crayonColor}`)
					}}
				/>
			</div>
			<div
				css={css`
					display: flex;
					width: 100%;
					height: 100%;
					flex: 1;
					flex-wrap: wrap;
					justify-content: space-evenly;
					align-items: center;
				`}
			>
				{options.map((option) => (
					<div
						key={option.key}
						id={option.key}
						css={css`
							width: 20%;
						`}
					>
						<img
							css={css`
								width: 100%;
								:hover {
									cursor: pointer;
								}
							`}
							src={option.image}
							alt={option.key}
							onClick={() => {
								optionClicked(option)
							}}
						/>
					</div>
				))}
			</div>
		</div>
	)
}

export default Phase4
