const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./13to24/bg_tortlelookingfromtop.svg"),
			imgdiv: "imageCover",
		},
		{
			src: images("./13to24/tortlelookingfromtop.svg"),
			imgdiv: "imageCover",
			styles: { top: "40%" },
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-top-center textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "माथिबाट कछुवाले एउटा सुन्दर पोखरी देख्यो।",
					textClass: "fz-5 m-0",
					timestamps: [{ start: 0 }, { start: 1.2 }, { start: 2 }, { start: 2.5 }, { start: 3 }, { start: 3.5 }, { start: 5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p4_s4.ogg"),
			delay: 1500,
		},
	],
}

export default content
