import { appear, disappear } from "styledElements/keyframes.js"
import { keyframes } from "@emotion/core"

const content = {
	images: [
		{
			src: "coverpage.png",
		},
		{
			src: "forest/bird_fly.gif",
			styles: {
				width: "32%",
				animation: `${keyframes({
					from: {
						top: "78%",
						left: "7%",
					},
					to: {
						top: "24%",
						left: "46%",
					},
				})} 2s ease-out forwards, ${disappear} 0.15s linear 2s forwards`,
			},
		},
		{
			src: "bird_coverpage.png",
			styles: {
				width: "20%",
				top: "25%",
				left: "52%",
				opacity: 0,
				animation: `${appear} 0.15s linear 2s forwards`,
			},
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text title",
			styles: {
				opacity: 0,
				animation: `${appear} 2s 2s ease-in-out forwards`,
			},
			textIndivData: [
				{
					textData: "भिर गौँथली",
					textClass: "m-0",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p1a.ogg",
			delay: 2500,
		},
	],
}

export default content
