import words from "./words"
import shuffle from "shuffle-array"
import letterToSound from "./letterbank/lettersToSound"

const audio_words = require.context("../audios/words", true)
const images = require.context("../images", true)

const extra_options = words
	.reduce((prev = [], current) => {
		return prev.concat(current)
	})
	.map((word) => {
		return word.letters
	})
	.reduce((prev = [], current) => {
		return prev.concat(current)
	})

let finalObject = words
	.map((level_words, index) => {
		let shuffledWords = shuffle(level_words)

		return shuffledWords.map((word) => {
			let word_np = word.letters.join("")
			const options = word.options
				? word.options
				: shuffle(extra_options.filter((letter) => !word.letters.includes(letter))).slice(0, 6 - word.letters.length)
			const dragItems = word.letters.concat(options).map((letter, idx) => {
				return {
					id: idx + 1,
					name: letter,
					dragClass: " dragFlexContent",
					match: `drop${idx + 1}`,
					url: letterToSound[letter],
					font: "content0",
				}
			})

			const dropItems = word.letters.map((letter, idx) => {
				return {
					id: idx + 1,
					match: `drop${idx + 1}`,
					name: "",
					dropClass: `dropFlexContent letter-${letter}`,
					enable: "",
					font: "content0",
				}
			})
			return {
				level: index - 1,
				word: word_np,
				background: "bg",
				dragClass: "dragFlex",
				dropClass: "dropFlex",
				incentiveImage: [
					{
						src: images(`./${word.key}.png`),
						// incentivesrc: images(`./${word.key}.gif`),
						incentivesrc: images(`./${word.key}.png`),
						class: "incentiveImg",
						imgdiv: "imageCover incentiveDiv",
						text: word_np,
						textClass: "fz-5 incentiveText",
					},
					{
						src: images("./volume.svg"),
						incentivesrc: images("./volume.svg"),
						imgdiv: "audioImg imageCover",
						text: word_np,
						textClass: "fz-5 incentiveText",
						url: audio_words(`./${word.key}.ogg`),
					},
				],
				imgClass: "dragImg",
				correctAnsAudio: audio_words(`./${word.key}.ogg`),
				dragItems: dragItems,
				dropItems: dropItems,
			}
		})
	})
	.reduce((prev = [], current) => {
		return prev.concat(current)
	})

export default finalObject
