const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page_06.jpg"),
			class: "image-8",
			imgdiv: "imageCover",
		},
		{
			src: images("./snow_falls.gif"),
			class: "image-8",
			imgdiv: "imageCover",
		},
		{
			src: images("./page_05_leaf.png"),
			class: "image-8 leaf",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text slideTextTop text-center",
			textIndivData: [
				{
					textData: "शिशिर ऋतु",
					textClass: "fz-6 m-0 one-line boldtext",
					timestamps: [{ start: 0 }, { start: 1.15 }],
				},
				{
					textData: "रूखमा एउटा पनि पात रहेनछ।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 2.25 }, { start: 3.25 }, { start: 3.65 }, { start: 4.05 }, { start: 4.5 }],
				},
				{
					textData: "हिउँले चउर सबै सेतो बनाएछ।",
					textClass: "fz-6 m-0 one-line ",
					timestamps: [{ start: 6.8 }, { start: 7.85 }, { start: 8.35 }, { start: 8.85 }, { start: 9.5 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./7.ogg"),
			delay: 3000,
		},
		{
			url: audios("./a5.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
	],
}

export default content
