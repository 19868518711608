import React, { useRef, useContext } from "react"

import gsap from "gsap"

import { Link } from "react-router-dom"
import iconHome from "assets/icons/home.svg"
import blastBanner from "assets/banner-on-loop.gif"
import playImage from "assets/read-again.png"
import starImage from "./images/star.png"
import "./custommodal.styles.scss"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons"

// import { StateContext } from "../../BalloonPopGame";
import { StateContext, DispatchContext } from "../../DecodingGame"
import Modal from "react-modal"

const CustomModal = (props) => {
	Modal.setAppElement("#root")

	const starsRef = useRef(null)
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)

	React.useEffect(() => {
		let tl = gsap.timeline()
		tl.paused(true)

		setTimeout(function () {
			// console.log(starsRef.current);
			if (starsRef.current) {
				if (state.stageRewardComplete) {
					let stars = starsRef.current.querySelectorAll("img")
					let i = 0
					starsRef.current.style.display = "flex"
					stars.forEach((star) => {
						gsap.set(star, {
							scale: 0,
						})
					})
					stars.forEach((star) => {
						tl.to(star, 0.6, {
							ease: "elastic.out(1, 0.3)",
							scale: 1,
							delay: i * 0.25,
						})
					})
					tl.play()
				}
			}
		}, 500)
	})

	return (
		<Modal
			isOpen={props.isOpen}
			style={{
				overlay: {
					zIndex: "9999",
					backgroundColor: "rgba(0, 0, 0, 0.7)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				},
				content: {
					position: "static",
					backgroundColor: "transparent",
					border: "none",
					overflow: "hidden",
				},
			}}
			ariaHideApp={false}
		>
			<div key="menuBtnNavBar" className="stageEndModal">
				<div className="menu__wrap">
					<div className="left-banner">
						<img className="img-fluid" src={blastBanner} alt="banner" />
					</div>
					<div className="right-banner">
						<img className="img-fluid" src={blastBanner} alt="banner" />
					</div>
					<div className="meta">
						<h2 className="text-center">समाप्त</h2>
					</div>
					<div ref={starsRef} className="stars" style={{ display: "none" }}>
						<img src={starImage} alt="Stars" />
						<img src={starImage} alt="Stars" />
						<img src={starImage} alt="Stars" />
					</div>
					<Link className="home__icon" to="/play">
						<img src={iconHome} className="img-fluid" alt="Home" />
					</Link>
					<ul className="menu__list">
						<li className="play-wrap">
							<div className="image-wrap">
								<img src={playImage} className="img-fluid" alt="Play" />
							</div>

							<button
								onClick={() => {
									dispatch({ type: "RESET" })
								}}
								className="btn btn-round purple"
							>
								<FontAwesomeIcon icon={faRedoAlt} />
							</button>
							{/*}
							<button onClick={() => {}} className='btn btn-round lightgreen'>
								<FontAwesomeIcon icon={faChevronCircleRight} />
							</button>
							*/}
						</li>
					</ul>
				</div>
			</div>
		</Modal>
	)
}
export default CustomModal
