import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./bg_river_empty.jpg"),
			imgdiv: "imageCover empty-river",
		},
		{
			src: images("./leaf_animation.gif"),
			imgdiv: "imageCover",
			styles: { top: "-0.5%", width: "56%", left: "-4%" },
		},
		{
			src: images("./mum_cutting_grass.png"),
			imgdiv: "imageCover",
			styles: { top: "32%", left: "77%", width: "18%" },
		},
		{
			src: images("./boy_dolls_01.png"),
			imgdiv: "imageCover",
			styles: {
				top: "69%",
				left: "20.5%",
				width: "16%",
			},
		},
		{
			src: images("./boy_dolls_02.png"),
			imgdiv: "imageCover d-none",
			styles: {
				top: "69%",
				left: "20.5%",
				width: "16%",
			},
		},
		{
			src: images("./boy_dolls_03.png"),
			imgdiv: "imageCover d-none",
			styles: {
				top: "69%",
				left: "20.5%",
				width: "16%",
			},
		},
		{
			src: images("./boy_talking_page3.png"),
			imgdiv: "imageCover",
			styles: {
				top: "58.6%",
				left: "21.6%",
				width: "13%",
			},
		},
		{
			src: images("./boy_talking_page3.gif"),
			imgdiv: "imageCover d-none",
			styles: {
				top: "58.6%",
				left: "21.6%",
				width: "13%",
			},
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page3boy rightMidBubble text-center",
			textIndivData: [
				{
					textData: "मलाई यहाँ माटोको खेलौनाहरू बनाउन कस्तो रमाइलो लाग्छ। यो बिरे र कर्णेलाई छोडेर म कहिँ जान्‍न।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.9 },
						{ start: 1.3 },
						{ start: 2.0 },
						{ start: 2.9 },
						{ start: 3.4 },
						{ start: 3.8 },
						{ start: 4.7 },
						{ start: 5.6 },
						{ start: 5.9 },
						{ start: 6.3 },
						{ start: 6.7 },
						{ start: 7.5 },
						{ start: 7.9 },
						{ start: 8.1 },
						{ start: 8.8 },
						{ start: 9.5 },
					],
				},
				{
					textData: "म किन स्कूल जाने र?",
					textClass: "m-0",
					timestamps: [{ start: 9.7 }, { start: 10.1 }, { start: 10.5 }, { start: 10.8 }, { start: 11.2 }, { start: 11.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 2000,
		},
		{
			url: audios("./a2.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
