const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_back_page_3.jpg"),
			class: "image-one light",
			imgdiv: "imageCover",
		},
		{
			src: images("./sun01.png"),
			class: "image-one sun",
			imgdiv: "imageCover",
		},
		{
			src: images("./bg_front_page_3.png"),
			class: "image-one light",
			imgdiv: "imageCover",
		},
		{
			src: images("./page3a.jpg"),
			class: "image-one dark",
			imgdiv: "imageCover",
		},
		{
			src: images("./writing.png"),
			class: "image-one img02",
			imgdiv: "imageCover",
		},
		{
			src: images("./boy_on_cycle_01.png"),
			class: "image-one cycle2",
			imgdiv: "imageCover",
		},
		{
			src: images("./cycle_rickshaw.png"),
			class: "image-one rickshaw1",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page3Text text-center",
			textIndivData: [
				{
					textData: "उनीहरू सँगै पढ्छन्।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.85 }, { start: 1.25 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./3.ogg"),
			delay: 500,
		},
		{
			url: audios("./sunrise.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.2,
		},
		{
			url: audios("./rickshaw.ogg"),
			delay: 500,
			isBg: true,
			volume: 0.1,
			loop: false,
		},
	],
}

export default content
