import { seqAnimation } from "styledElements/helpers"
import { disappear, appear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core"

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const close_eye = keyframes`
	0%, 89% {
		opacity: 0;
	}
	90%, 100% {
		opacity: 1;
	}
`
const open_eye = keyframes`
	0%, 89% {
		opacity: 1;
	}
	90%, 100% {
		opacity: 0;
	}
`
const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_page50.png"),
		},
		{
			src: images("./grand_mum_friend.svg"),
			toSvg: true,

			styles: {
				top: "16%",
				left: "-5%",
				width: "31%",

				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 3.8s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 3.8s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lips",
						numberOfFrames: 11,
						totalDuration: 1.9,
						initialDelay: 12,
						repeat: 1,
						delays: [0.01, 0.2, 0.4, 0.5, 0.7, 1.0, 1.3, 1.4, 1.5, 1.7, 1.8],
					}),
				},
			},
		},
		{
			src: images("./grandmomKhauna.svg"),
			toSvg: true,

			styles: {
				top: "18%",
				left: "23%",
				width: "39%",
				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 3s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 3s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lips",
						numberOfFrames: 3,
						totalDuration: 0.3,
						initialDelay: 3.5,
						repeat: 1,
						delays: [0.01, 0.2],
					}),
				},
			},
		},
		{
			src: images("./backLady.svg"),
			toSvg: true,

			styles: {
				top: "20%",
				left: "-39%",
				width: "72%",
			},
		},
		{
			src: images("./muna_aunt.svg"),
			toSvg: true,
			styles: {
				top: "29%",
				left: "67%",
				width: "37%",
				// animation: `${disappear} 150ms ease-in-out 5s forwards`,

				svg: {
					"#open_eyes": {
						opacity: 1,
						animation: `${open_eye} 3.5s ease-out normal both infinite`,
					},
					"#closed_eyes": {
						opacity: 0,
						animation: `${close_eye} 3.5s ease-out normal both infinite`,
					},

					...seqAnimation({
						idName: "lip",
						numberOfFrames: 8,
						totalDuration: 2.1,
						initialDelay: 5.5,
						repeat: 1,
						delays: [0.01, 0.3, 0.9, 1.0, 1.3, 1.6, 1.8, 2],
					}),
				},
			},
		},
		{
			src: images("./zeya_page75.svg"),
			toSvg: true,
			styles: {
				width: "17%",
				position: "absolute",
				top: "55%",
				left: "57%",
				animation: `${disappear} 150ms linear 6.5s forwards`,

				svg: {
					"#hand": {
						transformOrigin: "100% 50%",
						animation: `${keyframes({
							from: {
								transform: "rotate(1deg)",
							},
							to: {
								transform: "rotate(-3deg)",
							},
						})} 1s linear infinite alternate`,
					},
					"#head": {
						transformOrigin: "90% 100%",
						animation: `${keyframes({
							from: {
								transform: "rotate(2deg)",
							},
							to: {
								transform: "rotate(-1deg)",
							},
						})} 4s linear infinite alternate`,
					},
				},
			},
		},
		{
			src: images("./zeya_not_happy.png"),
			styles: {
				top: "54%",
				left: "57%",
				width: "15%",
				opacity: 0,
				animation: `${appear} 150ms linear 6.5s forwards, ${disappear} 150ms linear 8s forwards`,
			},
		},
		{
			src: images("./zeya_folding_arm01.png"),
			styles: {
				top: "52%",
				left: "59%",
				width: "15%",
				opacity: 0,
				animation: `${appear} 150ms linear 8s forwards,
				${keyframes({
					from: {
						transform: "rotateZ(2deg)",
					},
					to: {
						transform: "rotateZ(-1deg)",
					},
				})} 1s linear 5s infinite alternate`,
			},
		},
		{
			src: images("./drawing_copy.png"),
			class: "image-one",
			styles: {
				width: '13%',
				top: '82%',
				left: '49%',
			},
			imgDiv: "imageCover",
		},
		{
			src: images("./egg_plate.png"),
			class: "image-one",
			styles: {
				width: "11%",
				top: "86%",
				left: "56%",
			},
			imgDiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page5Text text-center dialogueBubble textbox2",
			styles: {
				top: '22%',
				left: '49%',
				width: '17%',
				opacity: 0,
				padding: '2% 0% 2% 3% !important',
				animation: `${appear} 500ms linear 3500ms forwards`
			},
			textIndivData: [
				{
					textData: "खाऊ न। ",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.4 },
					],
				},
			],
		},
		{
			textDivClass: "story-text page5Text text-center dialogueBubble textbox2",
			styles: {
			    top: '20%',
				left: '77.5%',
				width: '22%',
				opacity: 0,
				padding: '2% 2% 2% 4% !important',
				animation: `${appear} 500ms linear 5.5s forwards`
			},
			textIndivData: [
				{
					textData: "किन नखाएको? खाई हाल त नानी। ",
					textClass: "m-0",
					timestamps: [
						{ start: 1 },
						{ start: 2.35 },
						{ start: 2.95 },
						{ start: 3.35 },
						{ start: 3.65 },
						{ start: 3.95 },
						{ start: 4.1 },
						{ start: 4.5 },
					],
				},
			],
		},
		{
			textDivClass: "story-text page5Text text-center dialogueBubble textbox2",
			styles: {
				top: '16%',
    			left: '14%',
				padding: '2% 2% 2% 4% !important',
				width: '21%',
				opacity: 0,
				animation: `${appear} 500ms linear 12s forwards`
			},
			textIndivData: [
				{
					textData: "कस्तो अटेरी बच्चा रहेछ।",
					textClass: "m-0",
					timestamps: [
						{ start: 6 },
						{ start: 6.4 },
						{ start: 6.9 },
						{ start: 7.2 },
						{ start: 7.4 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./15a.ogg"),
			delay: 3500,
		},
		{
			url: audios("./bg/se_page9.ogg"),
			volume: 0.1,
			delay: 1,
			loop: true,
			isBg: true,
		},
		{
			url: audios("./kinaNakhayekoKhaiHalataNani.ogg"),
			delay: 5500,
		},
		{
			url: audios("./15c.ogg"),
			delay: 12000,
		},
	],
}

export default content
