const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./25to33/page06_bg.png"),
			imgdiv: "imageCover bg-blue-sky bg-anim bg-end",
		},
		{
			src: images("./25to33/img03.svg"),
			imgdiv: "imageCover bg-anim endc-3",
		},
		{
			src: images("./1to5/bush01.png"),
			imgdiv: "imageCover bush-left bush2-anim-1 bl-1",
		},
		{
			src: images("./1to5/bush02.png"),
			imgdiv: "imageCover bush-right bush-anim-1 br-1",
		},
		{
			src: images("./1to5/bush01.png"),
			imgdiv: "imageCover bush-left bush2-anim-2 bl-2",
		},
		{
			src: images("./1to5/bush02.png"),
			imgdiv: "imageCover bush-right bush-anim-2 br-2",
		},
		{
			src: images("./1to5/bush01.png"),
			imgdiv: "imageCover bush-left bush2-anim-3 bl-3",
		},
		{
			src: images("./1to5/bush02.png"),
			imgdiv: "imageCover bush-right bush-anim-3 br-3",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "नयाँ पोखरीमा प्रशस्त खानेकुराहरू थिए। तीन साथी त्यहाँ खुसीसाथ बस्‍न थाले।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.6 },
						{ start: 1.6 },
						{ start: 2.2 },
						{ start: 3.28 },
						{ start: 4.2 },
						{ start: 4.48 },
						{ start: 5.3 },
						{ start: 5.75 },
						{ start: 6.5 },
						{ start: 6.9 },
						{ start: 8 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p5_s7.ogg"),
			delay: 250,
		},
	],
}

export default content
