import { keyframes } from "@emotion/core"
import { appear } from "styledElements/keyframes"

const content = {
	images: [
		{
			src: "forest/bg_page8.png",
		},
		{
			src: "forest/tree_page8.png",
		},
		{
			src: "forest/branches_move.png",
			styles: {
				width: "23%",
				left: "77%",
				top: "29%",
			},
		},
		{
			src: "forest/bird_fly08.gif",
			styles: {
				width: "12%",
				transform: "translate(-65%, 30%)",
				animation: `${keyframes({
					to: {
						transform: "translate(555%, 9%)",
					},
				})} 4s ease-out forwards, disappear 150ms ease-out 4s forwards`,
			},
			imgdiv: "imageCover",
		},
		{
			src: "forest/bird_fly_page3.png",
			class: "bird_a-p7",
			imgdiv: "imageCover",
		},
		{
			src: "forest/bird_siting_page8.png",
			class: "bird_b-p7",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story_gauthali-text text-center",
			styles: {
				top: "5%",
				left: "16%",
				padding: "15px",
				background: "rgba(255, 255, 255, 0.8);",
				borderRadius: "15px",
				animation: `${appear} 3s 1.2s ease-out normal both`,
			},
			textIndivData: [
				{
					textData: "साँझ विहान लागेर",
					textClass: "m-0 p3-text_a",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
				{
					textData: "फेरि गुँड बनाए",
					textClass: "m-0 p3-text_b",
					// timestamps: [{ start: 1 }, { start: 2 }],
				},
			],
		},
	],
	sounds: [
		{
			url: "p7.ogg",
			delay: 3000,
		},
		{
			url: "birdsChirping.ogg",
			delay: 0,
			volume: 0.3,
			loop: true,
			isBg: true,
		},
		{
			url: "cricket.ogg",
			delay: 0,
			volume: 0.1,
			loop: true,
			isBg: true,
		},
	],
}

export default content
