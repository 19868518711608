import PageContent from "./pages/all"
import { fileCheck, injectFiles } from "helpers/files"

const images = fileCheck(require.context("./images", true))
const audios = fileCheck(require.context("./audios", true))

const content = {
	name: "भिर गौँथली",
	key: "bhir-gauthali",
	coverImage: "coverpage.png",
	credits: {
		writer: "इन्दिरा भट्टराई",
		illustrator: "सिमल",
		template: "default",
		bg: "coverpage.png",
		titleAudio: "p1a.ogg",
	},
	bgColor: "white",
	content: PageContent,
}

export default injectFiles(images, audios, content)
