const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_green.png"),
			class: "image-one bg01",
			imgdiv: "imageCover",
		},
		{
			src: images("./crow_fly_to_river.gif"),
			class: "image-one crow_fly_to_river",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page5Text text-center",
			textIndivData: [
				{
					textData: "कागले रोटी चुच्चोमा लियो।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1.3 }, { start: 1.9 }, { start: 2.6 }],
				},
				{
					textData: "ऊ भुर्र उड्यो।",
					textClass: "m-0",
					timestamps: [{ start: 3.5 }, { start: 4 }, { start: 4.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./5.ogg"),
			delay: 500,
		},
	],
}

export default content
