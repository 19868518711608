const images = require.context("./images", true)
const movies = require.context("./movies", true)

let content = {
	name: "बादल",
	key: "Badal",
	coverImage: images("./coverimage.png"),
	movie: movies("./Badal.mp4"),
	movies: [],
	timestamps: [],
	subtitles: [],
	content: "",
}

content.timestamps = content.timestamps.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})
content.subtitles = content.subtitles.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})
//
export default content
