const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 zoomoutpage10",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./background_02.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./page10_mum_kids.png"),
			class: "image-five mum-kids04",
			imgdiv: "imageCover",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain10",
			imgdiv: "imageCover",
		},
		{
			src: images("./thunder_storm.gif"),
			class: "image-one thunderp10",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox2 text_box-p10",
			textIndivData: [
				{
					textData: "“आमा! आमा! फेरि जाडो भयो। उनीहरूले फेरि भने।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.9 },
						{ start: 1.6 },
						{ start: 2.0 },
						{ start: 2.4 },
						{ start: 3.7 },
						{ start: 4.6 },
						{ start: 4.9 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./10.ogg"),
			delay: 3500,
		},
		{
			url: audios("./bg1.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
