import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const planeToDolpa = keyframes`
0% {
	transform: rotate(-7deg) translateX(0%);
}
100% {
	transform: rotate(-7deg) translateX(235%);
}
`
const horseToDolpa = keyframes`
0% {
	transform: rotate(-11deg) translateX(0%);
}
100% {
	transform: rotate(-6deg) translateX(160%);
}
`
const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(3) translate(20%,27%)",
					animation: `${transformTo("scale(7) translate(7%,21%)")} 3s 48s forwards ease-out  `
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "5%", 
				top: '60%',
				left: '24%',
				transform: "scaleX(-1)",
				animation: ` ${disappear} 500ms 11s ease-out normal forwards				 
				`,
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '5%',
				top: '54%',
				left: '58%',
				opacity: 0,
				zIndex:2,
				animation: ` ${appear} 1s 20s ease-out normal forwards, ${disappear} 500ms 35s ease-out normal forwards				 
				`,
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '5%',
				top: '45%',
				left: '85%',
				zIndex: 2,
				opacity:0,
				animation: ` ${appear} 1s 40s ease-out normal forwards,
				${disappear} 500ms 47s ease-out normal forwards
				`,
			}
		},
		{
			src: images("./imgs/plane.svg"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '15%',
				top: '67%',
				left: '20%',
				opacity: "0",
				transform: "rotate(-7deg)",
				zIndex: 2,
				animation: `${appear} 1s 12s ease-out normal forwards,
				${planeToDolpa} 6s 13s ease-out normal forwards,
				${disappear} 1s 19s ease-out normal forwards
				`,
			}
		},
		{
			src: images("./imgs/horse.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: "10%", 
				top: '58%',
				left: '63%',
				opacity: "0",
				transform: "rotate(-11deg)",
				zIndex: 2,
				animation: `${appear} 1s 35s ease-out normal forwards,
				${horseToDolpa} 4s 36s ease-out normal forwards,
				${disappear} 1s 40s ease-out normal forwards
				`,
			}
		},
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble1 page2Buble",
			textIndivData: [
				{
					textData: "यहाँबाट हिमाली भेगमा रहेको डोल्पोसम्म पुग्‍न चाहिँ हवाईजहाजमा उडेर जाऔँ। ",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				
				top: '26%',
				left: '25%',
				transformOrigin: "0% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 2000ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 10s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble4 page2Buble",
			textIndivData: [
				{
					textData: "डोल्पोको यो जुफाल विमानस्थलबाट शे-फोक्सुण्डो ताल सम्मको बाटो साँघुरो र अप्ठ्यारो छ भनेर सुनेको थिएँ। त्यसैले मैले त घोडा चढेर जाने बिचार गरेँ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '27%',
				left: '19%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 20s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 35s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble4 page2Buble",
			textIndivData: [
				{
					textData: "आहा! कस्तो शान्त यहाँको शे-फोक्सुण्डो ताल।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '35%',
				left: '50%',
				width: '34%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 41s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 46s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromAirportToDol",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '17%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '67%',
				position: 'absolute',
				left: '66%',
				transform: "rotate(-6deg) scaleX(0)",
				transformOrigin: "left",
				animation: `${transformTo(["rotate(-6deg) scaleX(1)"])} 4s 35s linear forwards, ${disappear} 1s 46s linear forwards`
			}
		},
		{
			textDivClass: "lineFromDailToAirport",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '32%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '75%',
				position: 'absolute',
				left: '30%',
				transform: "rotate(-9deg) scaleX(0)",
				transformOrigin: "left",
				animation: `${transformTo(["rotate(-9deg) scaleX(1)"])} 4s 13s linear forwards, ${disappear} 1s 21s linear forwards`
			}
		},
	],
	sounds: [
		{
			url: audios("./dolpa1.ogg"),
			delay: 2000,
		},
		{
			url: audios("./dolpa2.ogg"),
			delay: 21000,
		},
		{
			url: audios("./dolpa3.ogg"),
			delay: 42000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 55000,
		},
	],
}

export default content
