import { useState, useEffect, useContext } from "react"
import { ReactSVG } from "react-svg"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import gsap from "gsap"
import { Howl } from "howler"

// styles
import "./Phase1.styles.scss"

// Context
import { StateContext, DispatchContext } from "../../../DecodingGame"

const Phase2 = () => {
	const state = useContext(StateContext)
	const dispatch = useContext(DispatchContext)
	const levelData = state.levelData
	const wordAudio = levelData[state.stage].audio
	const wordKey = levelData[state.stage].key
	const crayonColor = levelData[state.stage].crayonColor
	const [lvlData, setLvlData] = useState({
		dropCount: 0,
		dropLetterIdx: -1,
		phaseData: JSON.parse(JSON.stringify(levelData[state.stage].detailedCorrectLetters)),
	})
	const { dropCount, dropLetterIdx, phaseData } = lvlData

	let isHoverHintAudioPlaying = false
	let HintAudioPlayer
	useEffect(() => {
		let initialAnimation = new gsap.timeline()
		initialAnimation
			.add(() => {
				const hintAudio = new Howl({
					src: [wordAudio],
					autoplay: false,
					loop: false,
				})
				hintAudio.play()
			})
			.to(`.hintImage-decoding-p1`, {
				yPercent: 38,
				duration: 0.8,
				delay: 1,
			})
			.to(`.hintImage-decoding-p1`, {
				yPercent: 0,
				duration: 1,
			})
			.add(() => {
				let rotationalDeg
				let dragLetterCount = document.getElementById("dp-1_dragDropParent").children.length - 1
				let letterSpacing = 380
				for (let letterIdx = 0; letterIdx < dragLetterCount; letterIdx++) {
					rotationalDeg = Math.floor(Math.random() * (1 + 45 + 45)) - 45
					gsap.to(`.dragZone-${letterIdx}`, {
						top: "160%",
						left: `${(letterIdx * letterSpacing) / dragLetterCount - 100}% `,
						duration: 2,
					})
					let Element = document.getElementsByClassName(`dragItem-${letterIdx}`)[0].childNodes[0]
					gsap.to(Element, {
						rotate: `${rotationalDeg}deg`,
						duration: 2,
					})
				}
				let scaleGrowElement = document.getElementsByClassName(`dragItem-0`)[0].childNodes[0]

				gsap.from(scaleGrowElement, {
					repeat: -1,
					repeatDelay: 5,
					yoyo: true,
					delay: 2,
					keyframes: [
						{ scale: 1.2, duration: 1 },
						{ scale: 1, duration: 1 },
						{ scale: 1.2, duration: 1 },
						{ scale: 1, duration: 1 },
						{ scale: 1.2, duration: 1 },
						{ scale: 1, duration: 1 },
					],
				})
			}, ">-1")
	}, [wordAudio])
	useEffect(() => {
		const soundAssets = require.context("../../../content/audios/level1/sound")
		if (dropCount > 0) {
			const hintAudio = new Howl({
				src: [soundAssets(`./${wordKey}/${dropCount}.ogg`)],
				autoplay: false,
				loop: false,
			})
			hintAudio.play()

			let Element
			try {
				Element = document.getElementsByClassName(`dragItem-${dropCount}`)[0].childNodes[0]
			} catch (error) {
				console.log(`Word Completed, Nice job!`)
				Element = null
			}
			if (Element) {
				gsap.from(Element, {
					repeat: -1,
					repeatDelay: 5,
					yoyo: true,
					delay: 2,
					keyframes: [
						{ scale: 1.2, duration: 1 },
						{ scale: 1, duration: 1 },
						{ scale: 1.2, duration: 1 },
						{ scale: 1, duration: 1 },
						{ scale: 1.2, duration: 1 },
						{ scale: 1, duration: 1 },
					],
				})
			}
			let endAnimation = new gsap.timeline()

			hintAudio.on("end", () => {
				if (dropCount === phaseData.length) {
					endAnimation
						.to("#dp-1_dragDropParent", {
							scale: 1.3,
							duration: 1,
							delay: 0.3,
						})
						.add(function () {
							const audio = new Howl({
								src: [soundAssets(`./${wordKey}/${dropCount}.ogg`)],
								autoplay: false,
								loop: false,
							})
							audio.play()
						})
						.add(function () {
							dispatch({ type: "PHASE_COMPLETE", values: { phase: state.phase + 1, transition: true } })
						}, ">1")
				}
			})
		}
	}, [dropCount, wordKey, phaseData, state.phase, phaseData.length, dispatch])
	const onDragEnd = (result) => {
		const { destination, source } = result
		if (!destination) {
			return
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return
		}
		if (destination.droppableId === "dropBox") {
			const newPhaseData = JSON.parse(JSON.stringify(phaseData))
			if (newPhaseData[dropCount].key === source.droppableId) {
				newPhaseData[dropCount].animateLetterIndex = newPhaseData[dropCount].svgLetterIndex
				newPhaseData[dropCount].letterDropped = true
				newPhaseData[dropCount].isDragDisabled = true

				setLvlData({ dropCount: dropCount + 1, dropLetterIdx: dropLetterIdx + 2, phaseData: newPhaseData })
			}
		}
	}
	return (
		<div
			className="phase2"
			css={css`
				width: 100%;
				height: 100%;
				margin: 0;
				display: flex;
				flex-direction: column;
			`}
		>
			<div
				css={css`
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					flex: 1;
					max-height: 45%;
				`}
				className={"hintImage-decoding-p1"}
			>
				<div
					css={css`
						justify-content: center;
						display: flex;
						width: 16%;
						max-height: 100%;
					`}
				>
					<img
						id="DP1_img"
						src={levelData[state.stage].hintImg}
						alt={levelData[state.stage].key}
						css={css`
							width: 100%;
							max-width: 165px;
							max-height: 165px;
							object-fit: contain;
						`}
					/>
				</div>
			</div>
			<DragDropContext onDragEnd={onDragEnd}>
				<div
					css={css`
						display: flex;
						justify-content: center;
						align-items: center;
						flex: 1;
					`}
				>
					<div
						id="dp-1_dragDropParent"
						className={`dp-1_dragDropParent dp-1_dragDropParent-${levelData[state.stage].key}`}
						css={css`
							position: relative;
						`}
					>
						<Droppable droppableId="dropBox" direction="horizontal">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									<ReactSVG
										src={levelData[state.stage].wordImg}
										loading={() => <span>Loading</span>}
										beforeInjection={(svg) => {
											svg.classList.add("dropPlace-decodingL1")
											svg.setAttribute("style", "fill:#D9D9D9")
											for (let i = 1; i < svg.childNodes.length; i += 2) {
												svg.childNodes[i].classList.add(`dropLetter-${i}`)
												if (i <= dropLetterIdx) {
													svg.childNodes[i].setAttribute("style", `fill:${crayonColor}`)
												}
											}
										}}
									/>
								</div>
							)}
						</Droppable>
						{phaseData.map((letter, idx) => {
							return (
								<div
									className={`dragZone-${idx} ${levelData[state.stage].key}-${letter.letter}`}
									css={css`
										position: absolute;
										top: ${letter.top}%;
										left: ${letter.left}%;
									`}
									key={`${letter.key}-${idx}`}
								>
									<Droppable droppableId={letter.key} direction="horizontal">
										{(provided) => (
											<div {...provided.droppableProps} ref={provided.innerRef}>
												<Draggable draggableId={letter.key} index={idx} isDragDisabled={letter.isDragDisabled}>
													{(provided, snapshot) => (
														<div
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															ref={provided.innerRef}
															className={`dragItem-${idx}`}
															// isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
															// style={getStyle(provided.draggableProps.style, snapshot)}
															// className={dragItems.dragClass}
															// onClick={() => selectAudio(dragItems.url)}
															onMouseEnter={() => {
																let Element = document.getElementsByClassName(`dragItem-${idx}`)[0]
																	.childNodes[0]
																gsap.to(Element, {
																	rotate: `0deg`,
																	duration: 0.5,
																})

																if (!letter.letterDropped) {
																	HintAudioPlayer = function () {
																		console.log(gsap.getProperty(Element, "rotate"))
																		let letterRotateValue = gsap.getProperty(Element, "rotate")
																		if (letterRotateValue === 0) {
																			if (isHoverHintAudioPlaying === false) {
																				isHoverHintAudioPlaying = true

																				const hintAudio = new Howl({
																					src: [letter.LetterAudio],
																					autoplay: false,
																					loop: false,
																				})
																				hintAudio.play()
																				hintAudio.on("end", function () {
																					isHoverHintAudioPlaying = false
																				})
																			}
																			gsap.ticker.remove(HintAudioPlayer)
																		}
																	}
																	gsap.ticker.add(HintAudioPlayer)
																}
															}}
															onMouseLeave={() => {
																gsap.ticker.remove(HintAudioPlayer)

																let Element = document.getElementsByClassName(`dragItem-${idx}`)[0]
																	.childNodes[0]
																gsap.to(Element, {
																	rotate: `${Math.floor(Math.random() * (1 + 45 + 45)) - 45}`,
																	duration: 0.5,
																})
															}}
														>
															<ReactSVG
																src={letter.LetterImgHint}
																loading={() => <span>Loading</span>}
																beforeInjection={(svg) => {
																	svg.classList.add("dragItem-decodingL1")
																	svg.setAttribute("style", "overflow: visible;")
																	svg.id = `svg-${letter.key}`
																	if (letter.letterDropped) {
																		svg.childNodes[1].setAttribute("style", `display:none;`)
																	} else {
																		svg.childNodes[1].id = `svg-letter-${idx}`
																		svg.childNodes[1].setAttribute("style", `fill:${crayonColor};`)
																	}
																}}
															/>
														</div>
													)}
												</Draggable>
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</div>
							)
						})}
					</div>
				</div>
				<div
					className="dragCountry-decodingL1"
					css={css`
						display: flex;
						flex: 1;
					`}
				></div>
			</DragDropContext>
		</div>
	)
}

export default Phase2
