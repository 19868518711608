export default [
	{
		key: "daat",
		letters: ["दाँ", "त"],
	},
	{
		key: "haga",
		letters: ["हाँ", "गा"],
	},
	{
		key: "taati",
		letters: ["ताँ", "ती"],
	},
	{
		key: "baas",
		letters: ["बाँ", "स"],
	},
	{
		key: "hiu",
		letters: ["हि", "उँ"],
	},
	{
		key: "vada",
		letters: ["भाँ", "डा"],
	},
	// {
	// 	key: "wemal",
	// 	letters: ["वि", "मे", "ल"]
	// },
	{
		key: "cycle",
		letters: ["सा", "इ", "क", "ल"],
	},
	{
		key: "headphone",
		letters: ["हे", "ड", "फो", "न"],
	},
	{
		key: "bakhra",
		letters: ["बा", "ख्रा"],
	},
	// {
	//     key: "upiya",
	//     letters: ["उ", "पि", "याँ"]
	// },
	{
		key: "vangera",
		letters: ["भँ", "गे", "रा"],
	},
	{
		key: "panihaas",
		letters: ["पा", "नी", "हाँ", "स"],
	},
	{
		key: "khanekura",
		letters: ["खा", "ने", "कु", "रा"],
	},
	// {
	//     key: "surya",
	//     letters: ["सू", "र्य"]
	// }
]
