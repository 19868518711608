// import { jsx } from "@emotion/core"
// import _, { delay, object } from "underscore"
import React, {useContext, useEffect, useState} from "react"
import {Howl, Howler} from "howler"
import { StateContext, DispatchContext } from "../../ShapeColorGame"
import "./scenecss/scene2.styles.scss"
// import { sleep } from "../../utils"
import Options from "../Options/Options"
import OptionsSecond from "../Options/OptionsSecond"
import gsap from "gsap"
import mainTextAnimation from "../../../Common/TextAnimations"
// import { counter } from "@fortawesome/fontawesome-svg-core"
// import textHighLight from "../../../Common/TextHighLightAnime"
// import { faBorderNone } from "@fortawesome/free-solid-svg-icons"
// const images = require.context("../images", true)
const audios = require.context("./dailog/", true)

const Scene = (({stageData, assets, story})=>{
        const state = useContext(StateContext)                /* importing statecontext from shapecolorgame and initializiing at state */
   const dispatch = useContext(DispatchContext)         /* importing statecontext from shapecolorgame and initializiing at state */
        let girlRef = React.useRef(null);                    /* initializing girlrif as reference which directly change in file  */
        let robotRef = React.useRef(null);
        let sisRef = React.useRef(null);
        let speechboxdivRef = React.useRef(null);
        let speechboxRef = React.useRef([]);
        // console.log(story);
const initial = {
        pageup: 0,
        audio: false,
        //    QuestionShow: 0,
        QnAnsShow: false,
        secQNAnsShow: false,
        dailogCounter: 0,
        disable: true,
        disableSec: false,
        disableThird: true
}

const [stageState, setStageState] = useState(initial);
//    const [data, setdata] = useState(stageData);
   var timestamp = gsap.timeline({});                           /* creating timeline for gsap animation */
//    let bgMusic;
//    let dailogSound;
//    console.log(stageState)
   let content = {                                              /* all data are store in object which are used as par their requirement */
        texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-1_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 2% 8% 2%'
                        },
                        textIndivData: [
                                {
                                        textData: "समा, हिजो यहि ठाउँमा मेरो टोपी हराएको थियो ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.80 }, { start: 1.10 }, { start: 1.45 }, { start: 1.90 }, { start: 2.10 }, { start: 2.50 }, { start: 3.00 },{ start: 3.10 }],
                                        delayTime: 12000,
                                        hideTimer: 17000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-2_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हो ? त्यसो भए हामी यहीँ खोजौँ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.70 }, { start: 1.45 }, { start: 1.70 }, { start: 2.15 }, { start: 2.50 }, { start: 2.90 }, { start: 3.00 }],
                                        delayTime: 18000,
                                        hideTimer: 23000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-3_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 12% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हुन्छ",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }],
                                        delayTime: 24000,
                                        hideTimer: 26000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-4_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '2% 3% 10% 3%'
                        },
                        textIndivData: [
                                {
                                        textData: "फुर्बा हेर त । त्यहाँ एकजना दिदि हुनुहुँदो रहेछ । आऊ उहाँलाई सोधौँ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.55 }, { start: 0.90 }, { start: 1.00 }, { start: 2.30 },{ start: 2.60 },{ start: 2.80 },{ start: 3.25 },{ start: 3.50 },{ start: 3.90 },{ start: 4.50 },{ start: 5.20 },{ start: 5.55 },{ start: 6.15 },{ start: 6.25 }],
                                        delayTime: 27000,
                                        hideTimer: 36000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-5_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 2% 12% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "दिदि !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 46000,
                                        hideTimer: 48000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-6_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 12% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हजुर !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 49000,
                                        hideTimer: 51000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-7_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हिजो यहाँ मेरो टोपी हराएको थियो । तपाईंले देख्नुभएको छ ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.80 }, { start: 1.20 }, { start: 1.60 }, { start: 2.20 }, { start: 2.60 }, { start: 3.10 }, { start: 3.60 }, { start: 4.00 }, { start: 4.50 }],
                                        delayTime: 52000,
                                        hideTimer: 56000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-8_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "टोपी ? कस्तो आकारको थियो भाइ ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.25 }, { start: 1.60 }, { start: 1.90 }, { start: 2.40 }, { start: 2.80 }],
                                        delayTime: 57000,
                                        hideTimer: 62000
                                }
                        ]
                },
                // dailog after quention option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-9_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "फुर्बा, तिम्रो टोपि त त्रिभुज आकारको पो रहेछ ।",  
                                        textClass: "m-0",
                                        timestamps: [],
                                        delayTime: 6600000,
                                        hideTimer: 6100000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-10_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "त्यहि त, मलाई पनि थाहै थिएन ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.25 }, { start: 1.60 }, { start: 1.90 }, { start: 2.40 }, { start: 2.80 }],
                                        delayTime: 6600000,
                                        hideTimer: 6800000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-11_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "त्यसोभए त्रिभुज आकारको अरू केके हुन्छ, हेरौँ है ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.25 }, { start: 1.60 }, { start: 1.90 }, { start: 2.40 }, { start: 2.80 }],
                                        delayTime: 6800000,
                                        hideTimer: 6900000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-12_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "हुन्छ दिदि ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.25 }, { start: 1.60 }, { start: 1.90 }, { start: 2.40 }, { start: 2.80 }],
                                        delayTime: 6900000,
                                        hideTimer: 7000000
                                }
                        ]
                },
                // dailog after second option click
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-13_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "हो, यो त्रिभुज आकारको हो । ",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.25 }, { start: 1.60 }, { start: 1.90 }, { start: 2.40 }, { start: 2.80 }],
                                        delayTime: 7100000,
                                        hideTimer: 7200000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-14_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "भाइ, तिम्रो टोपी त म सँग पो रहेछ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }],
                                        delayTime: 7100000,
                                        hideTimer: 7400000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-15_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "ल लिऊ तिम्रो त्रिभुज आकारको टोपी ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }],
                                        delayTime: 7500000,
                                        hideTimer: 7600000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-16_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "धन्यवाद दिदि ! अब बल्ल राम्रो देखिने भएँ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.50 }, { start: 2.90 }],
                                        delayTime: 7700000,
                                        hideTimer: 7800000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-17_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "अब हामी तिम्रो हात खोज्न जाऔँ है ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.50 }, { start: 2.90 }],
                                        delayTime: 7900000,
                                        hideTimer: 8000000,
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-18_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 8% 1%'
                        },
                        textIndivData: [
                                {
                                        textData: "हुन्छ !",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }],
                                        delayTime: 8100000,
                                        hideTimer: 8200000,
                                }
                        ]
                },
                
        ],
        sounds: 
		[{
			url: audios("./page2_furba1.wav"),
			delay: 12000,
		},{
                        url: audios("./sama6.wav"),
			delay: 18000,
                },{
			url: audios("./page2_furba2.wav"),
			delay: 24000,
		},{
                        url: audios("./sama7.wav"),
			delay: 27000,
                },{
                        url: audios("./page2_furba3.wav"),
			delay: 46000,
                },{
                        url: audios("./didi1.wav"),
			delay: 49000,
                },{
                        url: audios("./page2_furba4.wav"),
			delay: 52000,
                },{
                        url: audios("./didi2.wav"),
			delay: 57000,
                }],
}
        let content1 = {
                texts: [
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-9_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 9,
                                        textData: "फुर्बा, तिम्रो टोपि त त्रिभुज आकारको पो रहेछ ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.60 }, { start: 1.20 }, { start: 1.60 }, { start: 1.90 }, { start: 2.50 }, { start: 3.10 }, { start: 3.40 }, { start: 3.70 }],
                                        delayTime: 1000,
                                        hideTimer: 6000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-10_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 10,
                                        textData: "त्यहि त, मलाई पनि थाहै थिएन ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.40 }, { start: 0.70 }, { start: 1.20 }, { start: 1.60 }, { start: 2.00 }, { start: 2.50 }],
                                        delayTime: 7000,
                                        hideTimer: 11000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-11_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 11,
                                        textData: "त्यसोभए त्रिभुज आकारको अरू केके हुन्छ, हेरौँ है ?",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.70 }, { start: 1.15 }, { start: 1.80 }, { start: 2.00 }, { start: 2.30 }, { start: 2.70 }, { start: 3.40 }, { start: 3.70 }],
                                        delayTime: 12000,
                                        hideTimer: 18000
                                }
                        ]
                },
                {
                        textDivClass: "story-text",
                        textMainDivClass: 'sp-12_p2',
                        styles :{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                                left: 0,
                                top:0,
                                padding: '1% 1% 9% 1%'
                        },
                        textIndivData: [
                                {
                                        id: 12,
                                        textData: "हुन्छ दिदि ।",  
                                        textClass: "m-0",
                                        timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }],
                                        delayTime: 19000,
                                        hideTimer: 24000
                                }
                        ]
                },
                ],
                sounds:[
                        {
                                url: audios("./sama8.wav"),
			        delay: 1000,
                        },
                        {
                                url: audios("./page2_furba5.wav"),
			        delay: 7000,
                        },
                        {
                                url: audios("./didi3.wav"),
			        delay: 12000,
                        },
                        {
                                url: audios("./Huncha diid.wav"),
			        delay: 19000,
                        },
                ]
        }

        let content2 = {
                texts: [
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-13_p2',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "हो, यो त्रिभुज आकारको हो । ",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }],
                                                delayTime: 1000,
                                                hideTimer: 5000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-14_p2',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "भाइ, तिम्रो टोपी त म सँग पो रहेछ ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.10 }, { start: 1.50 }, { start: 1.70 }, { start: 1.95 }, { start: 2.25 }, { start: 2.45 }, { start: 2.90 }, { start: 3.00 }],
                                                delayTime: 6000,
                                                hideTimer: 11000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-15_p2',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "ल लिऊ तिम्रो त्रिभुज आकारको टोपी ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.25 }, { start: 0.60 }, { start: 1.10 }, { start: 1.60 }, { start: 2.20 }, { start: 2.30 }],
                                                delayTime: 12000,
                                                hideTimer: 16000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-16_p2',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "धन्यवाद दिदि! अब बल्ल राम्रो देखिने भएँ ।",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.60 }, { start: 1.10 }, { start: 1.20 }, { start: 1.60 }, { start: 2.20 }, { start: 2.65 }, { start: 2.90 }, { start: 3.00 }],
                                                delayTime: 29000,
                                                hideTimer: 34000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-17_p2',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "अब हामी तिम्रो हात खोज्न जाऔँ है ?",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.50 }, { start: 1.00 }, { start: 1.65 }, { start: 1.90 }, { start: 2.10 }, { start: 2.50 }, { start: 2.60 }],
                                                delayTime: 35000,
                                                hideTimer: 39000,
                                        }
                                ]
                        },
                        {
                                textDivClass: "story-text",
                                textMainDivClass: 'sp-18_p2',
                                styles :{
                                        position: 'absolute',
                                        height: '100%',
                                        width: '100%',
                                        left: 0,
                                        top:0,
                                        padding: '1% 1% 8% 1%'
                                },
                                textIndivData: [
                                        {
                                                textData: "हुन्छ !",  
                                                textClass: "m-0",
                                                timestamps: [{ start: 0 }, { start: 0.70 }],
                                                delayTime: 40000,
                                                hideTimer: 43000,
                                        }
                                ]
                        },
                ],
                sounds:[
                        {
                                url: audios("./sama9.wav"),
			        delay: 1000,
                        },
                        {
                                url: audios("./didi4.wav"),
			        delay: 6000,
                        },
                        {
                                url: audios("./didi5.wav"),
			        delay: 12000,
                        },
                        {
                                url: audios("./page2_furba7.wav"),
			        delay: 29000,
                        },
                        {
                                url: audios("./sama10.wav"),
			        delay: 35000,
                        },
                        {
                                url: audios("./page2_furba8.wav"),
			        delay: 40000,
                        },
                ]
        }
        // console.log(story);
   useEffect(()=>{                                          /* these effect is use for gsp animation of each and every object */
//    console.log(girlRef, girlRef.current.children);
        if(stageState.dailogCounter !== 9 && stageState.dailogCounter !== 13){
                console.log(stageState.dailogCounter + " " + stageState.dailogCounter)
                timestamp.to(girlRef.current, {
                        duration: 9,
                        x: "160%",
                        ease: "power1.in",
                        delay: 1,
                        onComplete: ()=>{
                                girlRef.current.src = stageData.girlLooking
                                
                        }
                        })
                        .to(robotRef.current, {
                                duration : 7,
                                // delay: 1,
                                x: "80%",
                                ease: "power1.in",
                                onComplete: ()=>{
                                        console.log("completed") ;
                                        girlRef.current.style.transform = "translate(160%, 0%) rotateY(180deg)"
                                        robotRef.current.src = stageData.robotLookPng
                                }
                        }, "-=5")
                        .to(girlRef.current,{
                                delay: 26,
                                onComplete: ()=>{
                                        girlRef.current.style.transform = "translate(130%, 0%) rotateY(0deg)"
                                        girlRef.current.src = stageData.girlMoveGif
                                        robotRef.current.src = stageData.robotGif
                                }
                        })
                        .to(girlRef.current,{
                                duration: 7,
                                x: "245%",
                                onComplete: ()=>{
                                        // girlRef.current.style.transform = "translate(220%, 0%) rotateY(0deg)"
                                        girlRef.current.src = stageData.girlLooking
                                }
                        })
                        .to(robotRef.current,{
                                duration: 7,
                                x: "140%",
                                onComplete: ()=>{
                                        // girlRef.current.style.transform = "translate(220%, 0%) rotateY(0deg)"
                                        robotRef.current.src = stageData.robotLookPng
                                }
                        },"-=5")
        }else{

        }                
}, [girlRef.current, robotRef.current, speechboxRef.current])

   useEffect(()=> {                                           /* this useEffect is uesd for background music play */
        if(!stageState.audio){
                        setStageState({...stageState, audio:true})
                        const bgMusic = new Howl({
                                src: [content.sounds[stageState.pageup].url],
                                autoplay: false,
                                preload: true,
                                volume: 0.07,
                                loop: true,
                                // onend: async () => {
                                //         // if (stageData.id === 10) {
                                //         //         setOptionState({ ...optionState, finalStageQ: stageData.questioner })
                                //         //         dispatch({ type: "LAST_PAGE_COMPLETE" })
                                //         // }
                                //         // await sleep(2000)
                                //         // await playAnimalSound()
                                // },
                        })
                        // console.log(content.sounds[stageState.pageup].url)
                        // bgMusic.play()
                        return () => {
                                bgMusic.unload()
                                Howler.unload()
                        }
                }      
        },[stageState.audio, stageData.bgMusic, content.sounds]);

        let textList = content.texts;
        let textListB;
        let soundList;
        let textListC;
        let soundListC;
useEffect(()=>{                                      /* this useEffect is used for showing dailogbox highilighting text and play audio for each dailog*/
        let pagenumber  = 1;
        textList = content.texts;
        soundList = content.sounds;
        // console.log(stageData.disable)
        if(state.playing && stageState.disable){
                let objCount = 1   
                let timeouts = []
                let timestams = []
                let textIndivData = []
                let delaytime = []
                let hideTime = []   
                let showdata;
                //    console.log(textList);
                if( textList?.length > 0){
                        textList.forEach((item) => {
                                textIndivData = [...textIndivData, ...item.textIndivData]
                        })
                        textIndivData.forEach((data) =>{
                                // console.log(data)
                                
                                if(data.timestamps){
                                        timestams = [...timestams, ...data.timestamps];
                                        // textHighLight(data.textdata, data.timestamps)
                                }else{
                                        timestams = null
                                }
                                
                                if(data.delayTime){
                                        delaytime.push(
                                                setTimeout(()=>{
                                                showdata  = document.getElementsByClassName('sp-'+ objCount + '_p2');
                                                        // console.log(delaytime);
                                                        gsap.to(showdata, {
                                                                scale: 1,
                                                                ease: "power1.in",
                                                                display: "block",
                                                                onComplete: () => {
                                                                        // (objCount % 2 == 0) ? robotRef.current.src = stageData.robotTalkGif : girlRef.current.src = stageData.girlTalkGif 
                                                                        // console.log(objCount);
                                                                        if(objCount === 2 || objCount === 4){
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                robotRef.current.src = stageData.robotTalkGif;
                                                                        }
                                                                        if(objCount === 3){
                                                                                girlRef.current.src = stageData.girlTalkGif;
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                        }
                                                                        // if(objCount === 4){
                                                                        //         girlRef.current.src = stageData.girlLooking;
                                                                        //         robotRef.current.src = stageData.robotTalkGif;
                                                                        // }
                                                                        if(objCount === 5 || objCount === 6){
                                                                                girlRef.current.src = stageData.girlTalkGif;
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                        }
                                                                        if(objCount === 7 || objCount === 9){
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                robotRef.current.src = stageData.robotLookPng;
                                                                                sisRef.current.src = stageData.didiTalk;
                                                                        }
                                                                        if(objCount === 8){
                                                                                girlRef.current.src = stageData.girlLooking;
                                                                                robotRef.current.src = stageData.robotTalkGif;
                                                                                sisRef.current.src = stageData.didiPng;
                                                                        }
                                                                        // if(objCount === 3){
                                                                        //         // dispatch({type: "NEXT_STAGE"});
                                                                        //         state.rewardComplete = true;
                                                                        //         // console.log(delaytime[""])
                                                                        // }
                                                                }      
                                                        })
                                                
                                                        
                                                        let textAnimations;
                                                        import(`../../Animations`).then((customAnims) =>{
                                                                let animationObj = new customAnims.default()
                                                                textAnimations = animationObj.runTextAnimations
                                                                // textAnimations = mainTextAnimation
                                                                if(animationObj.runTextAnimations){
                                                                        animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        objCount ++ ;
                                                                        // animationObj.forEach(() =>{
                                                                        //         animationObj.runTextAnimations(speechboxRef.current[objCount-1], timestams);
                                                                        // })
                                                                }
                                                                // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                        })
                                                        .catch((error) =>{
                                                                if(story.defaultTextAnimation){
                                                                        textAnimations = mainTextAnimation
                                                                }else{
                                                                        textAnimations = () => {}
                                                                }
                                                        })
                                                },data.delayTime)
                                        )
                                }else{
                                        delaytime = null
                                }
                                if(data.hideTimer){
                                        hideTime.push(
                                                setTimeout(() => {
                                                        showdata  = document.getElementsByClassName('sp-'+ (objCount-1)+ '_p2');
                                                        // showdata[0].style.display = 'none';
                                                        gsap.to(showdata, {
                                                                scale: 0.1,
                                                                ease: "power1.in",
                                                                delay: 1,
                                                                display: "none",
                                                                onComplete: () => {
                                                                                // robotRef.current.src = stageData.robotLookPng;
                                                                                // girlRef.current.src = stageData.girlLooking;
                                                                        
                                                                }
                                                        })
                                                        if(objCount === 9){
                                                                        setStageState({...stageState, disable:false, secQNAnsShow:false, QnAnsShow:true});   
                                                                        // textIndivData = [textIndivData];
                                                                        // console.log(textIndivData);

                                                        }
                                                }, data.hideTimer)
                                        )
                                }
                        })
                }else{
                        timestams = null
                }
                if(soundList && soundList.length){
                        soundList.forEach((sound) => {
                                // console.log(sound)
                                let audio = new Howl({
                                        src: [sound.url],
                                        preload: true,
                                        autoplay:false,
                                        loop: sound.loop ?? false,
                                        volume: sound.volume ?? 0.7,
                                })
                                if(sound.delay){
                                        timeouts.push(
                                                setTimeout(()=>{
                                                        audio.play();
                                                        // content.texts.textMainDivClass.style.display = 'block'
                                                },sound.delay)
                                        )
                                }else{
                                        // audio.unload()
                                }
                        })
                }
                return () => {
                        // if(audio) audio.unload()
                        // Howler.unload()
                        timeouts.forEach((timeout) =>{
                                clearTimeout(timeout)
                                // Howler.unload()
                        })
                        delaytime.forEach((timeout) => {
                                clearTimeout(timeout);
                        })
                        gsap.killTweensOf("*")
                        // console.log("last return")
                }
        }
},[stageData.disable, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData])

useEffect(()=> {            /* this useeffect is used when user click the right answer*/
        if(stageState.dailogCounter === 9){
                if(stageState.disableSec){
                        textListB = content1.texts;
                        soundList = content1.sounds;
                        let textIndivData = []
                        let timestamsSec = []
                        let timeouts = []
                        let speechCount = 9;
                        let delaytime = []
                        let hideTime = []
                        let showdata;
                        // console.log(stageState.disable)
                        robotRef.current.classList.add("robot_afterAnsCorrect_p2");
                        girlRef.current.classList.add("girl_afterAnsCorrect_p2");
                        if(robotRef.current){
                                robotRef.current.src = stageData.robotLookPng  
                        }
                        if(girlRef.current){
                                girlRef.current.src = stageData.girlTalkGif
                        }
                        if( textListB?.length > 0){
                                textListB.forEach((item) => {
                                        textIndivData = [...textIndivData, ...item.textIndivData]
                                })
                                textIndivData.forEach((data) =>{
                                        
                                        if(data.timestamps){
                                                timestamsSec = [...timestamsSec, ...data.timestamps];
                                        }else{
                                                timestamsSec = null
                                        }
                                        
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('sp-'+ speechCount+'_p2');
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCount === 10){
                                                                                        girlRef.current.src = stageData.girlTalkGif;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 11){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotTalkGif;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 12){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        sisRef.current.src = stageData.didiTalk;
                                                                                        console.log(speechCount);
                                                                                }
                                                                                else if(speechCount === 13){
                                                                                        girlRef.current.src = stageData.girlTalkGif;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        sisRef.current.src = stageData.didiPng;
                                                                                        console.log(speechCount);
                                                                                }  
                                                                        }      
                                                                });
                                                                let textAnimations1;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations1 = animationObj.runTextAnimations
                                                                        if(animationObj.runTextAnimations){
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCount-1], timestamsSec)
                                                                                speechCount ++ ;
                                                                        }
                                                                        // textHighLight(textIndivData.textData, textIndivData.timestamps);
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations1 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations1 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('sp-'+ (speechCount-1)+'_p2');
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {                                
                                                                        }
                                                                })
                                                                if(speechCount == 13){
                                                                        setStageState({...stageState, dailogCounter:13, secQnAnsShow:true});
                                                                        speechboxdivRef.current.style.display = "none";
                                                                        showdata[0].style.display = 'none';
                                                                } 
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsSec = null;
                        }
                        if(soundList && soundList.length){
                                soundList.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                        return () => {
                                // if(audio) audio.unload()
                                // Howler.unload()
                                timeouts.forEach((timeout) =>{
                                        clearTimeout(timeout)
                                        // Howler.unload()
                                })
                                delaytime.forEach((timeout) => {
                                        clearTimeout(timeout);
                                })
                                gsap.killTweensOf("*")
                                // console.log("last return")
                        }
                }
        }
        if(stageState.dailogCounter === 14){
                if(stageState.disableThird){
                        speechboxdivRef.current.style.display = "block";
                        textListC = content2.texts;
                        soundListC = content2.sounds;
                        let textIndivDataC = [];
                        let timestamsThird = [];
                        let timeouts = [];
                        let speechCountThird = 13;
                        let delaytime = [];
                        let hideTime= [];
                        let showdata;
                        if( textListC?.length > 0){
                                textListC.forEach((item) => {
                                        textIndivDataC = [...textIndivDataC, ...item.textIndivData]
                                        // console.log(textIndivDataC)
                                })
                                textIndivDataC.forEach((data) =>{
                                        if(data.timestamps){
                                                timestamsThird = [...timestamsThird, ...data.timestamps];
                                        }else{
                                                timestamsThird = null
                                        }
                                        if(data.delayTime){
                                                delaytime.push(
                                                        setTimeout(()=>{
                                                        showdata  = document.getElementsByClassName('sp-'+ (speechCountThird)+'_p2');
                                                                gsap.to(showdata, {
                                                                        scale: 1,
                                                                        ease: "power1.in",
                                                                        display: "block",
                                                                        onComplete: () => {
                                                                                if(speechCountThird === 14) {
                                                                                        robotRef.current.src = stageData.robotTalkGif;
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                }
                                                                                if(speechCountThird === 15){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotLookPng;
                                                                                        sisRef.current.src = stageData.didiTalk;
                                                                                }
                                                                                if(speechCountThird === 16) {
                                                                                        robotRef.current.src = stageData.robotGif;
                                                                                        sisRef.current.src = stageData.didiPng;
                                                                                        timestamp.to(robotRef.current, {
                                                                                                duration: 7,
                                                                                                x:"130%",
                                                                                                delay: 2,
                                                                                                ease: "none",
                                                                                                onComplete: ()=>{
                                                                                                        robotRef.current.src = stageData.robotLookPng; 
                                                                                                        sisRef.current.src = stageData.didiGive;
                                                                                                }
                                                                                        })
                                                                                        .to(robotRef.current, {
                                                                                                // scaleX: -1,
                                                                                                delay: 0.8,
                                                                                                // left: "72%",
                                                                                                onComplete: () => {
                                                                                                        robotRef.current.src = stageData.robotTopiWalkingPng;
                                                                                                        sisRef.current.src = stageData.didiPng; 
                                                                                                        robotRef.current.style.transform = "translate(100%) rotateY(0deg)"
                                                                                                }
                                                                                        })
                                                                                        // .to(robotRef.current,{
                                                                                        //         delay:0.1,
                                                                                        //         onComplete: () => {
                                                                                        //         console.log("aa");
                                                                                        //         robotRef.current.style.transform = "translate(100%) rotateY(0deg)";
                                                                                        //         }   
                                                                                        // })
                                                                                        .to(robotRef.current,{
                                                                                                duration: 5,
                                                                                                left: '-8%',
                                                                                                // scaleX: 1,
                                                                                                ease:"none",
                                                                                                onComplete: () => {
                                                                                                        // robotRef.current.style.transform = "translate(0% 0%)";
                                                                                                        // robotRef.current.src = stageData.robotTopiGif;
                                                                                                }
                                                                                        })
                                                                                        .to(robotRef.current,{
                                                                                                delay:0.1,
                                                                                                onComplete: () => {
                                                                                                robotRef.current.style.transform = "translate(135%) rotateY(180deg)";
                                                                                                }   
                                                                                        })
                                                                                }
                                                                                if(speechCountThird === 17){
                                                                                        // robotRef.current.style.transform = "translate(0% 0%) rotateY(180deg)";
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotTopiGif; 
                                                                                        
                                                                                }
                                                                                if(speechCountThird === 18){
                                                                                        girlRef.current.src = stageData.girlTalkGif;
                                                                                        robotRef.current.src = stageData.robotTopiPng;
                                                                                }
                                                                                if(speechCountThird === 19){
                                                                                        girlRef.current.src = stageData.girlLooking;
                                                                                        robotRef.current.src = stageData.robotTopiGif;
                                                                                        console.log(speechCountThird);
                                                                                        setStageState({...stageState, disableThird:false})
                                                                                        // robotRef.current.style.transform = "translate(0% 0%) rotateY(180deg)";
                                                                                        console.log(stageState.disableThird)
                                                                                }
                                                                                
                                                                        }      
                                                                });

                                                                let textAnimations2;
                                                                import(`../../Animations`).then((customAnims) =>{
                                                                        let animationObj = new customAnims.default()
                                                                        textAnimations2 = animationObj.runTextAnimations
                                                                        
                                                                        if(animationObj.runTextAnimations){
                                                                                // console.log(speechCountThird)
                                                                                animationObj.runTextAnimations(speechboxRef.current[speechCountThird-1], timestamsThird)
                                                                                speechCountThird ++ ;
                                                                                // console.log(speechCountThird)
                                                                        }
                                                                })
                                                                .catch((error) =>{
                                                                        if(story.defaultTextAnimation){
                                                                                textAnimations2 = mainTextAnimation
                                                                        }else{
                                                                                textAnimations2 = () => {}
                                                                        }
                                                                })
                                                        },data.delayTime)
                                                )
                                        }else{
                                                delaytime = null
                                        }
                                        if(data.hideTimer){
                                                hideTime.push(
                                                        setTimeout(() => {
                                                                showdata  = document.getElementsByClassName('sp-'+ (speechCountThird-1)+'_p2');
                                                                // showdata[0].style.display = 'none';
                                                                gsap.to(showdata, {
                                                                        scale: 0.1,
                                                                        ease: "power1.in",
                                                                        delay: 1,
                                                                        display: "none",
                                                                        onComplete: () => {
                                                                                // if(speechCountThird === 17) {
                                                                                //         robotRef.current.style.transform = "translate(0% 0%) rotateY(180deg)";
                                                                                // }
                                                                                
                                                                        }
                                                                })
                                                                if(speechCountThird === 19){
                                                                        dispatch({type: "LAST_PAGE_COMPLETE"})
                                                                        setStageState({...stageState, disableThird:false})
                                                                        console.log(state.rewardComplete)
                                                                        console.log(stageState.disable+" "+stageState.disableSec+" "+ stageState.disableThird)
                                                                }
                                                        }, data.hideTimer)
                                                )
                                        }
                                })
                        }
                        else{
                                timestamsThird = null;
                        }
                        if(soundListC && soundListC.length){
                                soundListC.forEach((sound) => {
                                        // console.log(sound)
                                        let audio = new Howl({
                                                src: [sound.url],
                                                preload: true,
                                                autoplay:false,
                                                loop: sound.loop ?? false,
                                                volume: sound.volume ?? 0.7,
                                        })
                                        if(sound.delay){
                                                timeouts.push(
                                                        setTimeout(()=>{
                                                                audio.play();
                                                                // content.texts.textMainDivClass.style.display = 'block'
                                                        },sound.delay)
                                                )
                                        }else{
                                                // audio.unload()
                                        }
                                })
                        }
                }
                
        }
},[stageData.disable,stageData.disableSec, content, textList, girlRef.current, robotRef.current, speechboxRef.current, stageState, stageData, speechboxdivRef.current]);

	const answerSound = (isRight = false) => {
		new Howl({
			src: [isRight ? state.audios[1] : state.audios[0]],
			autoplay: true,
			loop: false,
			volume: 0.03,
		})
	}

const answerClick = (e, index) => {
        const check = e.target.className.split(" ")[1];
        if(e.target.id === "rightAns"){
                answerSound(true);
                var parentDiv = e.target.parentNode;
                var previous = e.target.previousSibling;
                var next = e.target.nextSibling;   
                //    console.log(e.target);
                if(next){
                        // next.parentNode.removeChild(next);
                        next.style.opacity = 0
                }
                if(parentDiv.lastElementChild){
                        // parentDiv.removeChild(parentDiv.lastChild);
                        parentDiv.lastChild.style.opacity = 0
                }
                
                e.target.style.backgroundColor = "#c1ebb3";
                gsap.to("#"+e.target.id,3,{
                        x:"160%",
                        onComplete:() => {
                                setStageState({...stageState, dailogCounter:9, QnAnsShow:false, disableSec: true});
                        }
                })
        }
        else{
                e.target.style.backgroundColor = "#f4cccc";
                answerSound(false)
        }
        //    console.log(!parentDiv.firstChild)
}

const SecondanswerClick = (e, index) => {
        const check = e.target.className.split(" ")[2];
        if(check === "correct"){
                answerSound(true);
                var previous = e.target.previousSibling;
                var parentDiv = e.target.parentNode;
                var siblingDiv = parentDiv.nextSibling.id;
                // console.log(siblingDiv);
                if(previous) previous.style.opacity = 0
                if(parentDiv.firstElementChild) parentDiv.firstChild.style.opacity = 0
                e.target.style.backgroundColor = "#c1ebb3";
                gsap.to("."+check,3,{
                        x:"-132%",
                        onComplete:() => {
                                // check.pointerEvents = "none";
                        //      setStageState({...stageState, dailogCounter:9, QnAnsShow:false});
                        }
                })
                gsap.to('#'+siblingDiv, 3, {
                        delay: 3,
                        // display: "block",
                        css:{opacity:1},
                        onComplete: () => {
                                setStageState({...stageState, dailogCounter:14, secQnAnsShow:false});
                        }
                })
        }
        else{
                e.target.style.backgroundColor = "#f4cccc";
                answerSound(false);
        }
}

        return (
                <div className="imageContainer">
                {
                        // <Options
			// answerClick={answerClick}
			// assets={assets}
			// stageData={stageData}
			// />
                        stageState.QnAnsShow === true ? (
			<Options
			answerClick={answerClick}
			assets={assets}
			stageData={stageData}
			/>
			):  
                        <div className="speechContainer" ref={speechboxdivRef}>
                        <img src={stageData.girlMoveGif} className="girl_walk_p2" ref={girlRef} 
                        />
                        <img src ={stageData.robotGif} className="robot_p2" ref={robotRef} />
                        <img src ={stageData.didiPng} className="didi_p2" ref={sisRef} />
                        <img src ={stageData.cycle} className="cycle" />

                                {textList.length ? (
                                        textList.map((textContent, index) => {
                                                const textStyles = textContent.styles ?? {}
                                                // console.log(textStyles);
                                                const maindiv = textContent.textMainDivClass;
                                                const className = `${textContent.textDivClass} textContentDiv`
                                                return(
                                                        <div key={index} className={`speechbox_p2 ${maindiv}`} ref={ref => {speechboxRef.current[index] = ref;}}> 
                                                        <img src ={stageData.bubble} className="box" /> 
                                                        <div className={className} style ={ textStyles }>
                                                                {textContent.textIndivData.map((text, index) => (
                                                                        <p key={index} id="dailog" className={text.textClass} >
                                                                                {text.textData}
                                                                        </p>
                                                                ))}
                                                        </div>
                                                        </div>
                                                )
                                        })
                                ) : ( 
                                        <div></div>
                                )
                        }

                        </div>
                }
                {stageState.dailogCounter === 13 && (
                        <OptionsSecond 
                        assets={assets}
			stageData={stageData}
                        SecondanswerClick={SecondanswerClick}
                        />
                        )
                        }
                </div>        
        )
        
});

export default Scene;

