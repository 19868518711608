import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const planeToTaplayjung = keyframes`
0% {
	transform: rotate(-6deg) translateY(0%);
}
100% {
	transform:rotate(-6deg) translateY(-123%);
}
`
const content = { 
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(3) translate(-44%,-18%)",
					animation: `${transformTo("scale(7) translate(-39%,-11%)")} 4s 23s forwards ease-out`
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '6%',
				top: '56%',
				left: '43%',
				opacity: 1,
				transform: 'scaleX(-1)',
				animation: `${disappear} 500ms 8s linear forwards				 
				`,
			}
		},
		{
			src: images("./imgs/talking_bothhandhip.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
			    width: '7%',
				top: '11%',
				left: '41%',
				opacity: 0,
				zIndex:2,
				transform: 'scaleX(-1)',
				animation: ` ${appear} 1s 16s ease-out normal forwards,
				${disappear} 1s 22s ease-out normal forwards			 
				`,
			}
		},
		{
			src: images("./imgs/aeroplane_top.png"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '13%',
				top: '59%',
				left: '44%',
				opacity: 0, 
				zIndex: 2,
				animation: `${appear} 1s 9s ease-out normal forwards,
				${planeToTaplayjung} 5s 9s ease-out normal forwards,
				${disappear} 1s 15s ease-out normal forwards
				`,
			}
		},
		
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble3 page2Buble",
			textIndivData: [
				{
					textData: "अब हामी हवाईजहाज चढेर ताप्लेजुङ जाऔँ है।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '46%',
				left: '47%',
				transformOrigin: "0% 80%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 3000ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 8s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble3 page2Buble",
			textIndivData: [
				{
					textData: "यहाँको पाथिभरा मन्दिर कति उचाईमा रहेछ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{    
				top: '2%',
				left: '50%',
				transformOrigin: "0% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 16s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 22s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromSaptariToJhapa",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '30%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '75%',
				position: 'absolute',
				left: '50%',
				transform: "rotate(-95deg) scaleX(0)",
				transformOrigin: "left",
				animation: `${transformTo(["rotate(-95deg) scaleX(1)"])} 4s 9s linear forwards, ${disappear} 1s 16s linear forwards`
			}
		},
		
		
	],
	sounds: [
		{
			url: audios("./tapleyjung1.ogg"),
			delay: 3000,
		},
		{
			url: audios("./tapleyjung2.ogg"),
			delay: 16000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 25000,
		},
	],
}

export default content
