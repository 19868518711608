import React, { useState, useContext } from "react"
import _ from "underscore"
import "./brick.styles.scss"
import BrickSVG from "./assets/brickSvg.js"
import brokenBrickPieces from "./assets/broken_blocks.svg"
import { DispatchContext } from "../../HitTheWallGame"
import { StateContext } from "../../HitTheWallGame"
import jingleSound from "../../content/audios/sound_effects/jingle-win.ogg"
import boomSound from "../../content/audios/sound_effects/boom.ogg"
import buzzSound from "../../content/audios/sound_effects/buzz.ogg"
import { gsap } from "gsap"
import { Howl } from "howler"

import Boom from "./assets/star.png"

const whyDidYouRender = require("@welldone-software/why-did-you-render")
whyDidYouRender(React)

// const Brick=React.memo(({ wordCount, x,y }) => {
const Brick = ({ wordCount, x, y }) => {
	const [isDemolished, setIsDemolished] = useState(false)
	const [isFirstCorrectClick, setIsFirstCorrectClick] = useState(true)
	const dispatch = useContext(DispatchContext)
	const state = useContext(StateContext)
	const levelData = state.levelData[wordCount]

	const brickRef = React.createRef(null)

	const hintAudio = new Howl({
		src: [levelData.audio],
		autoplay: false,
		loop: false,
	})

	const choosenWordAudio = new Howl({
		src: [state.choosenWord.audio],
		autoplay: false,
		loop: false,
	})

	const jinglePlayer = new Howl({
		src: [jingleSound],
		autoplay: false,
		loop: false,
	})

	const buzzPlayer = new Howl({
		src: [buzzSound],
		autoplay: false,
		loop: false,
	})

	const boomPlayer = new Howl({
		src: [boomSound],
		autoplay: false,
		loop: false,
	})

	const getCraneAnimationValues = () => {
		const animationValue = {
			c11: {
				movableRotate: 5,
				lineNBallRotate: 50,
				drive: -500,
			},
			c12: {
				movableRotate: 0,
				lineNBallRotate: 45,
				drive: -500,
			},
			c13: {
				movableRotate: -15,
				lineNBallRotate: 40,
				drive: -500,
			},
			c14: {
				movableRotate: -25,
				lineNBallRotate: 40,
				drive: -500,
			},
			c21: {
				movableRotate: 20,
				lineNBallRotate: 20,
				drive: -500,
			},
			c22: {
				movableRotate: 10,
				lineNBallRotate: 20,
				drive: -500,
			},
			c23: {
				movableRotate: 0,
				lineNBallRotate: 15,
				drive: -500,
			},
			c24: {
				movableRotate: -20,
				lineNBallRotate: 20,
				drive: -500,
			},
			c31: {
				movableRotate: 5,
				lineNBallRotate: 50,
				drive: 0,
			},
			c32: {
				movableRotate: 0,
				lineNBallRotate: 45,
				drive: 0,
			},
			c33: {
				movableRotate: -15,
				lineNBallRotate: 40,
				drive: 0,
			},
			c34: {
				movableRotate: -25,
				lineNBallRotate: 40,
				drive: 0,
			},
			c41: {
				movableRotate: 20,
				lineNBallRotate: 20,
				drive: 0,
			},
			c42: {
				movableRotate: 10,
				lineNBallRotate: 20,
				drive: 0,
			},
			c43: {
				movableRotate: 0,
				lineNBallRotate: 15,
				drive: 0,
			},
			c44: {
				movableRotate: -20,
				lineNBallRotate: 20,
				drive: 0,
			},
		}
		let coord = `c${x}${y}`
		let value = animationValue[coord]
		return value
	}

	const breakTheWall = () => {
		gsap.set("#movable", { transformOrigin: "90% 100%" })
		gsap.set("#lineNball", { transformOrigin: "50% 0%" })

		const animateValue = getCraneAnimationValues()

		let tl = gsap.timeline()
		tl.to("#crane", { x: animateValue.drive })
			.to("#movable", { rotate: 25 }) //beam, line and ball move backwards
			.to("#lineNball", { rotate: -10 }, "-=0.5") // line and ball move slightly more backwards
			.to("#movable", { rotate: animateValue.movableRotate })
			.to("#lineNball", { rotate: animateValue.lineNBallRotate, z: 50 })
			.add(function () {
				gsap.from(`#boom_${x}${y}`, { display: "block", scale: -1 })
				setIsDemolished(true)
				boomPlayer.play()
				boomPlayer.on("end", function () {
					hintAudio.play()
					hintAudio.on("end", function () {})
				})
			})
			.to(`#brokenPieces_${x}${y}`, { opacity: 1, y: 60 })
			.to(`#brokenPieces_${x}${y}`, { opacity: 0 })
			.fromTo(`#hintImg_${x}${y}`, { opacity: 0, y: 60 }, { opacity: 1, y: 0 })
			.to("#movable", { rotate: 0 }, "-=1")
			.to("#lineNball", { rotate: 0 }, "-=0.5")
			.to("#crane", { x: 0 })
			.add(function () {
				dispatch({ type: "NEXT_STAGE" })
			})
	}

	const dontBreakTheWall = () => {
		let tl = gsap.timeline()

		gsap.to(`#brick_${x}${y}`, { fill: "#de5246", color: "white", duration: 0.25 })
		//-------------move the crane back and forth
		tl.to("#crane", { x: 20 })
			.to("#crane", { x: -20 })
			.to("#crane", { x: 20 })
			.add(function () {
				buzzPlayer.play()
				buzzPlayer.on("end", function () {
					gsap.to(`#brick_${x}${y}`, { fill: "#FC9F60" })
					choosenWordAudio.play()
				})
			})
			.to("#crane", { x: 0 })

		// gsap.to(`#brick_${x}${y}`, {fill: "#FC9F60", duration: 1});
	}

	const onWallClicked = _.debounce(() => {
		//1. Compare if the correct wall is clicked
		if (isFirstCorrectClick && state.choosenWord.npword === levelData.npword) {
			setIsFirstCorrectClick(false)
			jinglePlayer.play()
			gsap.to(`#brick_${x}${y}`, { fill: "#8dff57", duration: 1 })

			jinglePlayer.on("end", () => {
				breakTheWall()
			})
		} else {
			dontBreakTheWall()
			hintAudio.play()
		}
	}, 250)

	return (
		<div ref={brickRef} onClick={onWallClicked} className="col brick block">
			<img src={Boom} alt="boom" width="100%" height="100%" id={`boom_${x}${y}`} style={{ position: "absolute", display: "none" }} />
			<BrickSVG width="100%" height="100%" isDemolished={isDemolished} idKey={`${x}${y}`} />
			{!isDemolished && <span className="brick-text">{levelData.npword}</span>}
			<img
				id={`brokenPieces_${x}${y}`}
				src={brokenBrickPieces}
				width="100%"
				height="100%"
				style={{ position: "absolute", opacity: 0 }}
				alt="broken brick pieces"
			/>
			<img
				id={`hintImg_${x}${y}`}
				src={levelData.image}
				width="100%"
				height="100%"
				className="hint_img"
				style={{ opacity: isDemolished ? "1" : "0" }}
				alt={levelData.key}
			/>
		</div>
	)
}

Brick.whyDidYouRender = true
const BrickMemo = React.memo(Brick)
BrickMemo.whyDidYouRender = true

export default Brick
