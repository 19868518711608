const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./bg_Page09.jpg"),
			class: "image-five page11",
			imgdiv: "imageCover",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain11",
			imgdiv: "imageCover",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain11a",
			imgdiv: "imageCover",
		},
		{
			src: images("./thunder_storm.gif"),
			class: "image-one thunderp11",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox11 text_box-p11",
			textIndivData: [
				{
					textData: "“पख!  पख! तातो बुबु खाऊ। न्यानो हुन्छ।” आमाले भनी। उसले पालैपालो सबैलाई दुध चुसाई।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 0.5 },
						{ start: 0.9 },
						{ start: 1.4 },
						{ start: 2.0 },
						{ start: 2.4 },
						{ start: 2.8 },
						{ start: 3.6 },
						{ start: 4.2 },
						{ start: 5.0 },
						{ start: 5.8 },
						{ start: 6.4 },
						{ start: 7.0 },
						{ start: 7.8 },
						{ start: 8.2 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./11.ogg"),
			delay: 500,
		},
		{
			url: audios("./bg1.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
