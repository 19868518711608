/** @jsx jsx */
import { jsx } from "@emotion/core"
import styled from "@emotion/styled"

import { useContext, useRef, useEffect, useState } from "react"
import { DispatchContext } from "../StoryContainer"
import { useHistory } from "react-router-dom"

import _ from "underscore"

// styled elements
import activityWrap from "../../../styledElements/activityWrap"

const CoverHolder = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
`

export default function CoverPage({ disabled, story, isLoaded }) {
	const history = useHistory()
	const dispatch = useContext(DispatchContext)

	const activityWrapperRef = useRef(null)
	const [height, setHeight] = useState(0)

	useEffect(() => {
		let computedWidth = activityWrapperRef.current ? activityWrapperRef.current.offsetWidth : 0
		setHeight((computedWidth * 9) / 16)

		const handleResize = _.debounce(() => {
			computedWidth = activityWrapperRef.current ? activityWrapperRef.current.offsetWidth : 0
			setHeight((computedWidth * 9) / 16)
		}, 500)

		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [activityWrapperRef])

	// console.log(dispatch, DispatchContext);
	const onListenBtnClick = () => {
		dispatch({ type: "START_LISTEN" })
		localStorage.setItem("audioMute", false)
		history.push(`/story/${story.key}/1`)
	}

	const onReadBtnClick = () => {
		dispatch({ type: "START_READ" })
		localStorage.setItem("audioMute", true)
		history.push(`/story/${story.key}/1`)
	}

	return disabled ? null : (
		<div
			className={`activity__holder align-items-center d-flex ${story.containerClass}`}
			style={{ top: `${story.containerClass === "BhanaMaKoHunContainer" && story.key === "bhana-ma-ko-hun" ? "1.6rem" : ""}` }}
		>
			<div
				css={activityWrap}
				ref={activityWrapperRef}
				style={{
					height: `${height}px`,
				}}
			>
				<div className="activityContent" key={story.key}>
					<div className={`story-cover ${story.containerClass}`}>
						<CoverHolder className="cover-img-holder">
							<img src={story.coverImage} className="img-fluid img-cover" alt="story-cover" />
						</CoverHolder>
						<div className="btn-wrap text-center">
							<div className="btn-frame">
								{isLoaded ? (
									<button className="btn btn-round lightblue" onClick={onListenBtnClick}>
										कथा सुन
									</button>
								) : (
									<button className="btn btn-round bg-secondary">कथा सुन</button>
								)}
							</div>
							<div className="btn-frame">
								{isLoaded ? (
									<button className="btn btn-round lightblue" onClick={onReadBtnClick}>
										कथा पढ
									</button>
								) : (
									<button className="btn btn-round bg-secondary">कथा पढ</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
