const images = require.context("./images", true)
const movies = require.context("./movies", true)

let content = {
	name: "रमाइलो होली",
	key: "ramailo-holi",
	coverImage: images("./coverimage.jpg"),
	movie: movies("./Ramailo Holi8.mp4"),
	movies: [],
	timestamps: [],
	subtitles: [],
	content: "",
}

content.timestamps = content.timestamps.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})
content.subtitles = content.subtitles.map((time) => {
	return { ...time, totalTime: Number(time.min * 60) + Number(time.sec) }
})
//
export default content
