const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-blue-sky",
	images: [
		{
			src: images("./25to33/goingdown01.svg"),
			imgdiv: "imageCover dive-1",
		},
		{
			src: images("./25to33/goingdown02.svg"),
			imgdiv: "imageCover dive-2",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudb-3 cloudb-anim-3",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudb-4 cloudb-anim-4",
		},
		{
			src: images("./13to24/clouds.svg"),
			imgdiv: "imageCover cloudb-5 cloudb-anim-5",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center bg-success h-auto position-bottom-intact textContentDiv w-75  ",
			textIndivData: [
				{
					textData: "पानीहाँसहरू बुद्धिमान थिए। उनीहरू जोडले तल झरे।",
					textClass: "fz-5 m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1 },
						{ start: 1.7 },
						{ start: 2.2 },
						{ start: 3 },
						{ start: 3.7 },
						{ start: 4.1 },
						{ start: 5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./p5_s1.ogg"),
			delay: 500,
		},
	],
}

export default content
