const words = [
	[
		{
			key: "thadeuro",
			column: 0,
			row: 0,
			isAcross: 1,
			arrow: "aur",
			letters: ["ठ", "डे", "उ", "रो"],
		},
		{
			key: "rotiping",
			column: 3,
			row: 0,
			isAcross: 0,
			arrow: "adl",
			letters: ["रो", "टे", "पि", "ङ"],
		},
		{
			key: "telephone",
			column: 3,
			row: 1,
			isAcross: 1,
			arrow: "aur",
			letters: ["टे", "लि", "फो", "न"],
		},
		{
			key: "nadinala",
			column: 6,
			row: 1,
			isAcross: 0,
			arrow: "adl",
			letters: ["न", "दि", "ना", "ला"],
		},
		{
			key: "hatkela",
			column: 4,
			row: 4,
			isAcross: 1,
			arrow: "ar",
			letters: ["ह", "त्के", "ला"],
		},
		{
			key: "halojuwa",
			column: 4,
			row: 4,
			isAcross: 0,
			arrow: "adl",
			letters: ["ह", "लो", "जु", "वा"],
		},
		{
			key: "junatara",
			column: 4,
			row: 6,
			isAcross: 1,
			arrow: "ar",
			letters: ["जु", "न", "ता", "रा"],
		},
		{
			key: "tatopani",
			column: 6,
			row: 6,
			isAcross: 0,
			arrow: "adl",
			letters: ["ता", "तो", "पा", "नी"],
		},
		{
			key: "pakhuri",
			column: 6,
			row: 8,
			isAcross: 1,
			arrow: "ar",
			letters: ["पा", "खु", "री"],
		},
		{
			key: "baijani",
			column: 4,
			row: 9,
			isAcross: 1,
			arrow: "ar",
			letters: ["बै", "ज", "नी"],
		},
	],
]

export default words
