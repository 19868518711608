const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover zoomoutpeda",
		},
		{
			src: images("./Page01.jpg"),
			class: "image-one page2",
			imgdiv: "imageCover zoomoutpeda",
		},
		{
			src: images("./jumping_peda1.gif"),
			class: "image-five peda_page02",
			imgdiv: "imageCover zoomoutpeda",
		},
		{
			src: images("./jumping_laddu1.gif"),
			class: "image-five laddu_page02",
			imgdiv: "imageCover zoomoutpeda",
		},
	],
	texts: [
		{
			textDivClass: "story-text page2text",
			textIndivData: [
				{
					textData: "लड्‍डु र पेडाको बाटोमा भेट भएछ।",
					textClass: "m-0",
					timestamps: [
						{ start: 0 },
						{ start: 1.2 },
						{ start: 1.6 },
						{ start: 2.4 },
						{ start: 2.9 },
						{ start: 3.2 },
						{ start: 3.5 },
					],
				},
			],
		},
		{
			textDivClass: "story-text dialogueBubble db_textbox1 text_box-p2",
			textIndivData: [
				{
					textData: "ओहो! तिमी त सुन्तला जस्तो!",
					textClass: "m-0",
					timestamps: [{ start: 5.5 }, { start: 5.8 }, { start: 6 }, { start: 6.5 }],
				},
			],
		},
		{
			textDivClass: "story-text dialogueBubble db_textbox1a text_box-p2a",
			textIndivData: [
				{
					textData: "तिमि पनि कति सेतो",
					textClass: "m-0",
					timestamps: [{ start: 8.0 }, { start: 8.3 }, { start: 8.8 }, { start: 9.3 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./2.ogg"),
			delay: 2000,
		},
		{
			url: audios("./2a.ogg"),
			delay: 6200,
		},
		{
			url: audios("./2b.ogg"),
			delay: 10000,
		},
	],
}

export default content
