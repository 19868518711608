import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "ल भन त, म हो हुँ?",
	key: "bhana-ma-ko-hun",
	coverImage: images("./cover_page_ma_ko_hun.png"),
	credits: {
		writerANDillustrator: "साझा शिक्षा ई-पाटी",
		template: "default",
		bg: images("./last_cover_page_ma_ko_hun.png"),
		titleAudio: audios("./p2a4.wav"),
	},
	bgColor: "white",
	content: PageContent,
	containerClass: "font-cambay bhana-ma-ko-hun",
}

export default content
