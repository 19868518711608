import React, { useEffect } from "react"
/**@jsx jsx */ import { jsx, css } from "@emotion/core"
import { Howl } from "howler"
import gsap from "gsap"

// image assets
import SaagImg from "../../content/images/level1/story/saag01.png"
import SpoonImg from "../../content/images/level1/story/spoon1.png"
import BhaatImg from "../../content/images/level1/story/plate_bhaat1.png"
import BaagGif from "../../content/images/level1/story/baag1.gif"
import KaagGif from "../../content/images/level1/story/kaag1.gif"
import ParewaGif from "../../content/images/level1/story/parewa.gif"
import MewaImg from "../../content/images/level1/story/mewa.png"
import KeraImg from "../../content/images/level1/story/kera.png"

const KeraStory = ({ NextPhase }) => {
	useEffect(() => {
		const soundAsset = require.context("./")
		const hintHowl = new Howl({
			src: [soundAsset("./keraStory.ogg")],
		})
		gsap.to("#DP4-kaag", {
			onStart: () => {
				hintHowl.play()
			},
		})
		hintHowl.on("play", () => {
			gsap.to("#DP4-parewa", {
				delay: 1,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-parewa", {
				delay: 1.45,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-kera", {
				delay: 2.7,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-kera", {
				delay: 3.0,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-mewa", {
				delay: 3.6,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-mewa", {
				delay: 3.9,
				duration: 0.8,
				scale: 1,
			})
			gsap.to("#DP4-parewa", {
				delay: 5.61,
				scale: 1.2,
				duration: 0.8,
			})
			gsap.to("#DP4-parewa", {
				delay: 6,
				duration: 0.8,
				scale: 1,
			})
		})

		hintHowl.on("end", () => {
			NextPhase()
		})
	}, [NextPhase])

	return (
		<React.Fragment>
			<img
				src={KaagGif}
				id="DP4-kaag"
				css={css`
					position: absolute;
					width: 18%;
					left: 40%;
					top: 57%;
				`}
				alt="kaag moving its head"
			/>
			<img
				src={BaagGif}
				id="DP4-baag"
				css={css`
					width: 24%;
					position: absolute;
					top: 45%;
					left: 8%;
				`}
				alt="baag moving its head"
			/>
			<img
				src={BhaatImg}
				css={css`
					width: 22%;
					position: absolute;
					top: 85%;
					left: 25%;
				`}
				alt="saag"
			/>
			<img
				src={SpoonImg}
				css={css`
					width: 12%;
					position: absolute;
					top: 89%;
					left: 21%;
				`}
				alt="Spoon"
			/>
			<img
				src={SaagImg}
				css={css`
					width: 13%;
					position: absolute;
					top: 78%;
					left: 36%;
				`}
				alt="Saag"
			/>
			<img
				src={ParewaGif}
				id="DP4-parewa"
				css={css`
					width: 15%;
					position: absolute;
					top: 61%;
					left: 72%;
				`}
				alt="Saag"
			/>
			<img
				src={MewaImg}
				id="DP4-mewa"
				css={css`
					width: 11%;
					position: absolute;
					top: 67%;
					left: 80%;
				`}
				alt="Saag"
			/>
			<img
				src={KeraImg}
				id="DP4-kera"
				css={css`
					width: 10%;
					position: absolute;
					top: 79%;
					left: 79%;
				`}
				alt="Saag"
			/>
		</React.Fragment>
	)
}

export default KeraStory
