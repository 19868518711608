import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "पर्फेक्ट सर्कल",
	key: "perfect-circle",
	coverImage: images("./cover_page_new.png"),
	credits: {
		writer: "साझा शिक्षा ई-पाटी",
		illustrator: "अनुश्री लामा",
		music: "रेगन अवाले",
		template: "default",
		bg: images("./cover_page_new.png"),
		titleAudio: audios("./1.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
