const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover zoomoutcave",
		},
		{
			src: images("./bg_Page05.jpg"),
			class: "image-five page5",
			imgdiv: "imageCover zoomoutcave",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain5",
			imgdiv: "imageCover zoomoutcave",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain5a",
			imgdiv: "imageCover zoomoutcave",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain5b",
			imgdiv: "imageCover zoomoutcave",
		},
		{
			src: images("./thunder_storm.gif"),
			class: "image-one thunderp5",
			imgdiv: "imageCover zoomoutcave",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox2 text_box-p5",
			textIndivData: [
				{
					textData: "गुँडमा झासझुस ल्याएर बच्चाहरुलाई ओढाई।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.9 }, { start: 1.4 }, { start: 2.0 }, { start: 2.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./5.ogg"),
			delay: 6500,
		},
		{
			url: audios("./bg1.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
