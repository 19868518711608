const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page05_and06_back.png"),
			class: "image-six",
			imgdiv: "imageCover",
		},
		{
			src: images("./raining.gif"),
			class: "image-six",
			imgdiv: "imageCover",
		},
		{
			src: images("./page06_front.gif"),
			class: "image-seven",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center slideleftbottom",
			textIndivData: [
				{
					textData: "तर यस्तो ओढ्ने चिज भने देखेकी थिइनन्।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [{ start: 0 }, { start: 0.5 }, { start: 1 }, { start: 1.4 }, { start: 1.75 }, { start: 2.4 }, { start: 3 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s6_1.ogg"),
			delay: 2000,
		},
	],
}

export default content
