import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const bycycleToJhapa = keyframes`
0% {
	transform: rotate(0deg) translateX(0%);
}
100% {
	transform: rotate(3deg) translateX(500%);
}
`
const content = { 
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(4) translate(-35%,-26%)",
					animation: `${transformTo("scale(7) translate(-41%,-30%)")} 4s 23s forwards ease-out`
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{    
				width: '6%',
				top: '26%',
				left: '19%',
				opacity: 1,
				transform: 'scaleX(-1)',
				animation: `${disappear} 500ms 8s linear forwards				 
				`,
			}
		},
		{
			src: images("./imgs/talking_bothhandhip.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
			    width: '7%',
				top: '32%',
				left: '76%',
				opacity: 0,
				zIndex:2,
				transform: 'scaleX(-1)',
				animation: ` ${appear} 1s 17s ease-out normal forwards,
				${disappear} 1s 22s ease-out normal forwards			 
				`,
			}
		},
		{
			src: images("./imgs/cycling.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '12%',
				top: '32%',
				left: '17%',
				opacity:0,
				transform: "rotate(0deg)",
				zIndex: 2,
				animation: `${appear} 1s 9s ease-out normal forwards,
				${bycycleToJhapa} 6s 9s ease-out normal forwards,
				${disappear} 1s 15s ease-out normal forwards
				`,
			}
		},
		
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble3 page2Buble",
			textIndivData: [
				{
					textData: "अब हामी झापा सम्मको बाटो साइकल चढेर जाऔँ है।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '16%',
				left: '25%',
				transformOrigin: "0% 80%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 3000ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 8s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble4 page2Buble",
			textIndivData: [
				{
					textData: "यहाँ कति राम्रा चिया बगानहरू रहेछन्।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{    
				top: '21%',
				left: '35%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 17s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 22s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromSaptariToJhapa",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '64%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '47%',
				position: 'absolute',
				left: '21%',
				transform: "rotate(3deg) scaleX(0)",
				transformOrigin: "left",
				animation: `${transformTo(["rotate(3deg) scaleX(1)"])} 5s 9s linear forwards, ${disappear} 1s 16s linear forwards`
			}
		},
		
		
	],
	sounds: [
		{
			url: audios("./jhapa1.ogg"),
			delay: 3000,
		},
		{
			url: audios("./jhapa2.ogg"),
			delay: 16000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 25000,
		},
	],
}

export default content
