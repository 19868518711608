const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./hugging/heart.png"),
			class: "image-one imgbox-md heart-p12 heart_1-p12",
			imgdiv: "imageCover bg-white",
		},
		{
			src: images("./hugging/heart.png"),
			class: "image-one imgbox-md heart-p12 heart_2-p12",
			imgdiv: "imageCover",
		},
		{
			src: images("./hugging/heart.png"),
			class: "image-one imgbox-md heart-p12 heart_3-p12",
			imgdiv: "imageCover",
		},
		{
			src: images("./hugging/heart.png"),
			class: "image-one imgbox-md heart-p12 heart_4-p12",
			imgdiv: "imageCover",
		},
		{
			src: images("./hugging/heart.png"),
			class: "image-one imgbox-md heart-p12 heart_5-p12",
			imgdiv: "imageCover",
		},
		{
			src: images("./hugging/Body.svg"),
			class: "image-one imgbox-md body-p12",
			imgdiv: "imageCover",
		},
		{
			src: images("./hugging/mumHead.svg"),
			class: "image-one imgbox-md mumHead-p12",
			imgdiv: "imageCover",
		},
		{
			src: images("./hugging/kopilaHead.svg"),
			class: "image-one imgbox-md kopilaHead-p12",
			imgdiv: "imageCover",
		},
		{
			src: images("./outline-p10-white.png"),
			class: "image-one outline-12",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center text-p12 textbox textbox-ban",
			textIndivData: [
				{
					textData: "खुशी भएर उफ्रँदै आमालाई अँगालो मारिन्।",
					textClass: "m-0 one-line text-p12a textStyles",
					timestamps: [{ start: 0 }, { start: 0.9 }, { start: 1.6 }, { start: 2.5 }, { start: 3.3 }, { start: 3.8 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s12.ogg"),
			delay: 500,
		},
	],
}

export default content
