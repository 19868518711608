import React from "react"
import NextIcon from "assets/icons/next.svg"

const nextBtn = (props) => {
	const className = props.className || "next__btn"
	return (
		<span
			key="nxtBtn"
			onClick={
				props.disabled
					? () => {
							return
					  }
					: props.nextBtnClick
			}
			className={props.disabled ? `${className} disabled` : className}
		>
			<img className="img-fluid" src={NextIcon} alt="next" />
		</span>
	)
}

export default nextBtn
