import { fileCheck } from "helpers/files"
const images = fileCheck(require.context("../images", true))
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-sky",
	imageContainerStyles: {
		transformOrigin: "2% 62%",
		transform: "scale(4.5)",
	},
	images: [
		{
			src: images("./kids/bg_page8.png"),
		},
		{
			src: images("./flag_long.gif"),
			styles: {
				width: "100%",
				top: "0%",
				left: "0%",
			},
		},
		{
			src: images("./boy_with_dog.gif"),
			class: "image-10 boywithdog",
			imgdiv: "imageCover",
		},
		{
			src: images("./kids/page-8-girl01.svg"),
			toSvg: true,
			styles: {
				top: "41%",
				left: "21%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl1",
		},
		{
			src: images("./kids/page-8-boy.svg"),
			toSvg: true,
			styles: {
				top: "39.2%",
				left: "42%",
				width: "22%",
			},
			imgdiv: "imageCover page8boy",
		},

		{
			src: images("./kids/page-8-girl02.svg"),
			toSvg: true,
			styles: {
				top: "42%",
				left: "62.5%",
				width: "22%",
			},
			imgdiv: "imageCover page8girl2",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble dialogueBubble--page8girl topLeftBubble",
			textIndivData: [
				{
					textData: "आहा! तिम्रो हवाईजहाज कस्तो राम्रो।",
					timestamps: [{ start: 0 }, { start: 0.9 }, { start: 1.3 }, { start: 2.0 }, { start: 2.5 }],
				},
				{
					textData: "मेरो पुतली हेर त!",
					timestamps: [{ start: 4.0 }, { start: 4.3 }, { start: 4.8 }, { start: 5.1 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./g1.ogg"),
			delay: 3500,
		},
	],
}

export default content
