import { transformTo, appear, disappear } from "styledElements/keyframes"
import { keyframes } from "@emotion/core";

const images = require.context("../images", true)
const audios = require.context("../audios", true)

const boatToSaptari = keyframes`
0% {
	transform: rotate(-58deg) translateX(0%);
}
100% {
	transform: rotate(-58deg) translateX(-120%);
}
`
const content = {
	imageContainerClass: "bg-extended pos-1 bg-bluish",
	images: [
		{
			src: images("./imgs/map2.png"),
			class: "image-one bg",
			imgdiv: "imageCover",
			styles: {
					transformOrigin: "center",
					transform: "scale(4) translate(-30%,-20%)",
					animation: `${transformTo("scale(7) translate(-29%,-29%)")} 4s 23s forwards ease-out`
			}
		},
		{
			src: images("./imgs/talkingkamalhandup.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '6%',
				top: '23%',
				left: '54%',
				opacity: 1,
				animation: `${disappear} 500ms 8s linear forwards				 
				`,
			}
		},
		{
			src: images("./imgs/talking_bothhandhip.gif"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
			    width: '7%',
				top: '58%',
				left: '53%',
				opacity: 0,
				zIndex:2,
				transform: 'scaleX(-1)',
				animation: ` ${appear} 1s 17s ease-out normal forwards,
				${disappear} 1s 22s ease-out normal forwards			 
				`,
			}
		},
		{
			src: images("./imgs/boats.svg"),
			class: "image-five",
			imgdiv: "imageCover",
			styles:{
				width: '20%',
				top: '-5%',
				left: '64%',
				opacity:0,
				transform: "rotate(-58deg)",
				zIndex: 2,
				animation: `${appear} 1s 9s ease-out normal forwards,
				${boatToSaptari} 6s 9s ease-out normal forwards,
				${disappear} 1s 15s ease-out normal forwards
				`,
			}
		},
		
	],
	texts: [
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble4 page2Buble",
			textIndivData: [
				{
					textData: "खोटाङबाट हामी दुधकोशी नदीमा डुंगा चढेर सप्तरी तिर लागौँ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '12%',
				left: '13%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 1000ms forwards, 
				${transformTo(["scale(0)"])} linear 500ms 8s forwards
				`,
			}
		},
		{
			textDivClass: "story-text slideLeft text-center bg-blue dialogueBubble Bubble2 page2Buble",
			textIndivData: [
				{
					textData: "हेर त हामी कोशी टप्पु बन्यजन्तु आरक्षण क्षेत्र पुगेछौँ।",
					textClass: "txt1",
					timestamps: [
						{ start: 0 },
						{ start: 0.95 },
						{ start: 1.25 },
						{ start: 1.65 },
						{ start: 2.45 },
						{ start: 2.8 },
						{ start: 3.45 },
					],
				},
			],
			styles:{
				top: '30%',
				left: '13%',
				transformOrigin: "100% 100%",
				animation: `${transformTo(["scale(1)"])} linear 500ms 17s forwards, 
				${transformTo(["scale(0)"])} linear 500ms 22s forwards
				`,
			}
		},
		{
			textDivClass: "lineFromKhotagToSaptari",
			textIndivData: [
				{
					textData: " ",
					textClass: "",
					
				},
			],
			styles:{
				width: '27.5%',
				background: '#A7A7A7',
				height: 10,
				zIndex: 1,
				top: '28%',
				position: 'absolute',
				left: '55%',
				transform: "rotate(122deg) scaleX(0)",
				transformOrigin: "left",
				animation: `${transformTo(["rotate(122deg) scaleX(1)"])} 5s 9s linear forwards, ${disappear} 1s 16s linear forwards`
			}
		},
		
		
	],
	sounds: [
		{
			url: audios("./saptari1.ogg"),
			delay: 1000,
		},
		{
			url: audios("./saptari2.ogg"),
			delay: 16000,
		},
		{
			url: audios("./sound_eff/citymusic.ogg"),
			delay: 0,
			isBg: true,
			volume: 0.1,
		},
		{
			url: audios("./sound_eff/blank.ogg"),
			delay: 25000,
		},
	],
}

export default content
