const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg01.png"),
			class: "image-five bg01",
			imgdiv: "imageCover",
		},
		{
			src: images("./water_wave01.png"),
			class: "image-five water_wave01",
			imgdiv: "imageCover",
		},
		{
			src: images("./water_wave02.png"),
			class: "image-five water_wave02",
			imgdiv: "imageCover",
		},
		{
			src: images("./water_wave03.png"),
			class: "image-five water_wave03",
			imgdiv: "imageCover",
		},
		{
			src: images("./crow_going_to_river.gif"),
			class: "image-five crow_roti01",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text page6Text text-center",
			textIndivData: [
				{
					textData: "काग रोटी लिएर नजिकैको खोलाछेउ पुग्यो।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 1 }, { start: 1.5 }, { start: 2.5 }, { start: 3.7 }, { start: 4.7 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./6.ogg"),
			delay: 1500,
		},
		{
			url: audios("./a6.ogg"),
			delay: 500,
			isBg: true,
		},
	],
}

export default content
