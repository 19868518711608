const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1 zoominpage13",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover",
		},
		{
			src: images("./background_01.jpg"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./mum-kids06.png"),
			class: "image-five mum-kids06",
			imgdiv: "imageCover",
		},
		{
			src: images("./rain_new.gif"),
			class: "image-one rain13",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text dialogueBubble db_textbox13 text_box-p13",
			textIndivData: [
				{
					textData: "“अब के गर्ने होला?” माऊले सोची।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.9 }, { start: 1.4 }, { start: 1.8 }, { start: 2.4 }, { start: 3.0 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./13.ogg"),
			delay: 2000,
		},
		{
			url: audios("./rain.ogg"),
			delay: 500,
			isBg: true,
			loop: true,
		},
	],
}

export default content
