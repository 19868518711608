import PageContent from "./pages/all"

const images = require.context("./images", true)
const audios = require.context("./audios", true)

const content = {
	name: "म किन स्कूल जाने र?",
	key: "ma-kina-school-jane-ra",
	coverImage: images("./coverpage.jpg"),
	credits: {
		writer: "साझा शिक्षा ई-पाटी",
		illustrator: "अनुश्री लामा",
		template: "default",
		bg: images("./coverpage.jpg"),
		titleAudio: audios("./title.ogg"),
	},
	bgColor: "white",
	content: PageContent,
}

export default content
