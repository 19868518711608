const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	imageContainerClass: "bg-extended pos-1",
	images: [
		{
			src: images("./bg_cover01.png"),
			class: "image-one",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./Page02_jerry_new.jpg"),
			class: "image-one page5",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./peda_under_tree.gif"),
			class: "image-five page3peda",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./laddu_under_tree.gif"),
			class: "image-five page3laddu",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerrya",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jerry1",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jerryb",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry1.png"),
			class: "image-five jerryc",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry2.png"),
			class: "image-five jerry2",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry3",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry4",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry5",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry6",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry7",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry8",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry9",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry10",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry11",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry12",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry13",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry14",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry15",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry16",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry17",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry18",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry19",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry20",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry21",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry22",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry23",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry24",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry25",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry26",
			imgdiv: "imageCover zoominduitai",
		},
		{
			src: images("./jerry.png"),
			class: "image-five jerry27",
			imgdiv: "imageCover zoominduitai",
		},
	],
	texts: [
		{
			textDivClass: "story-text page5text",
			textIndivData: [
				{
					textData: "लड्‍डु र पेडाले जेरीको कुरा बुझेनछन्।",
					textClass: "m-0",
					timestamps: [{ start: 0 }, { start: 0.9 }, { start: 1.5 }, { start: 2.3 }, { start: 3.0 }, { start: 3.6 }],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./5.ogg"),
			delay: 3000,
		},
	],
}

export default content
