const images = require.context("../images", true)
const audios = require.context("../audios", true)

const content = {
	images: [
		{
			src: images("./page04_back.png"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./raining.gif"),
			class: "image-five",
			imgdiv: "imageCover",
		},
		{
			src: images("./page04_front.png"),
			class: "image-five",
			imgdiv: "imageCover",
		},
	],
	texts: [
		{
			textDivClass: "story-text text-center slideleftbottom",
			textIndivData: [
				{
					textData: "माइजूले पानी छेक्‍ने चिज लिएर खेत तिर लाग्‍नुभयो।",
					textClass: "fz-5 m-0 one-line",
					timestamps: [
						{ start: 0.6 },
						{ start: 1.8 },
						{ start: 2.2 },
						{ start: 2.7 },
						{ start: 3.1 },
						{ start: 3.9 },
						{ start: 4.3 },
						{ start: 4.5 },
					],
				},
			],
		},
	],
	sounds: [
		{
			url: audios("./s5.ogg"),
			delay: 2000,
		},
	],
}

export default content
