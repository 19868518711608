import gsap from "gsap"
import { delay } from "underscore";

import mainAnimation from "./TextAnimations"

export default function textHighLight( element = null, timestamps = null, textColor = "#000") {
	if (timestamps && element) {
		let textElement_p = element.querySelectorAll("p")
		// console.log(textElement_p);
		if (textElement_p) {
			textElement_p.forEach((textEl) => {
				let words = textEl.textContent.split(" ")
				if (words.length === 1) {
					words.push("")
				}
				let finalStr = words.reduce((accumulator, currentValue, currentIndex) => {
					return currentIndex === 1
						? `<span class='word unread'>${accumulator}</span> <span class='word unread'>${currentValue}</span>`
						: `${accumulator} <span class='word unread'>${currentValue}</span>`
				})
				textEl.innerHTML = finalStr
				// console.log(finalStr);

				let wordsEl = textEl.querySelectorAll(".word")

				// console.log(words.length, wordsEl.length);
				// console.log(wordsEl)
				let delay = 0
				let duration = 0.5
				let index = 0
				wordsEl.forEach((element, idx) => {
					index = idx
					try {
						delay = timestamps[idx] ? timestamps[idx].start : timestamps[timestamps.length - 1].start
						duration = timestamps[idx + 1]
							? timestamps[idx].last
								? 0.8
								: timestamps[idx + 1].start - timestamps[idx].start
							: timestamps[timestamps.length - 1].start - timestamps[timestamps.length - 2].start
						// console.log('delay', delay);
						gsap.to(element, duration - 0.2, {
							// background: 'rgba(106, 216, 121,1)',
							// scale: 1.2,
							opacity: 1,
							textShadow: "0 0 4px #84ff82",
							delay: delay,
							color: textColor,
							ease: "none",
							yoyo: false,
							// repeat: 1,
							onStart: () => {
								if (textColor === "#000") element.style.fontWeight = "600"
							},
							onComplete: () => {
								element.style.opacity = 1
								element.style.color = textColor
								element.classList.add("read")
								element.classList.remove("unread")
								element.style.textShadow = "none"
								element.style.fontWeight = textColor === "#000" && "600"
							},
						})
					} catch (error) {
						console.log("Error: " + error)
					}
				})
				timestamps.splice(0, index + 1)
			})
		}
	} else {
		mainAnimation(element)
	}
}
